import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../DataTable/DataTable'
import { MainCell } from '../../DataTable/cells/MainCell'
import { DEFAULT_FILTERS } from '../../../constructs/Filters/constants'
import TaskStatus from '../../Statuses/TaskStatus'

const TASK_TYPES: any = {
  one_to_one: 'One-to-One',
  group_meeting: 'Group Task',
}

export const TodoTemplatesDataTable = (props: any) => {
  const { to, onClick, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Title',
        model: 'title',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return (
            <MainCell
              id={data.id}
              as={NavLink}
              to={to ? to(data) : undefined}
              onClick={onClick ? () => onClick(data) : undefined}
              value={value}
            />
          )
        },
      },
      {
        title: 'Client Tracks',
        model: 'client_tracks',
        type: 'record_client_tracks',
        featureFlag: 'client_tracks',
        editPermission: 'client_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Organization Tracks',
        model: 'organization_tracks',
        type: 'record_organization_tracks',
        featureFlag: 'organization_tracks',
        editPermission: 'organization_tracks.edit',
        disableSort: true,
      },
      {
        title: 'Type',
        model: 'meeting_type',
        formatValue: ({ value }: any) => {
          return TASK_TYPES[value]
        },
      },
      {
        title: 'Meeting Place',
        model: 'meeting_place',
        type: 'title',
      },
      {
        title: 'Permissions',
        model: 'sharing_type',
        formatValue: ({ value }: any) => <TaskStatus status={value} />,
      },
      {
        title: 'Public Description',
        model: 'public_description',
        type: 'rich_text',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="To-Do Templates"
      testKey="todo_templates_data_table"
      icon="tasks"
      columns={columns}
      filtersConfig={FILTERS_CONFIG}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  title: {
    label: 'Title',
    type: 'string',
  },
  client_tracks: DEFAULT_FILTERS.client_tracks,
  organization_tracks: DEFAULT_FILTERS.organization_tracks,
  meeting_type: {
    label: 'Type',
    type: 'multi_select',
    options: [
      { label: 'One-to-One', value: 'one_to_one' },
      { label: 'Group Task', value: 'group_meeting' },
    ],
  },
  meeting_place: {
    label: 'Meeting Place',
    type: 'multi_select',
    options: [
      { label: 'None', value: 'none' },
      { label: 'Online', value: 'online' },
      { label: 'Location', value: 'property' },
      { label: 'Organization', value: 'organization' },
      { label: 'Other', value: 'other' },
    ],
  },
  sharing_type: {
    label: 'Permissions',
    type: 'multi_select',
    options: [
      { label: 'Staff Only', value: 'internal' },
      { label: 'Client Portal', value: 'client' },
    ],
  },
  public_description: {
    label: 'Public Description',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
