import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { DEFAULT_FILTERS } from '../../constructs/Filters/constants'

export const PermissionTemplatesDataTable = (props: any) => {
  const { to, timezone, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell id={data.id} as={NavLink} to={to ? to(data) : undefined} value={value} avatar={data.avatar} />
        ),
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return <DataTable asCard title="Permission Templates" icon="permissions" columns={columns} filtersConfig={FILTERS_CONFIG} {...rest} />
}

const FILTERS_CONFIG = {
  name: {
    label: 'Name',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
