import React from 'react'
import size from 'lodash/size'

import { AllClientsTable } from '../../../components/Forms/Selectors/tables/AllClientsTable'
import { countWord } from '../../../utils/functions'

import Alert from '../../../components/Alert'
import Button from '../../../components/Button'
import Flex from '../../../components/Flex'
import Overlay from '../../../components/Overlay'

export const QuickAddClientsOverlay = (props) => {
  const { onClose, onAdd, addedClientIds = [] } = props

  const [selected, setSelected] = React.useState<any>([])

  const handleAdd = (isAttending) => {
    onClose()
    onAdd?.(selected, isAttending)
  }

  const selectedCount = size(selected)
  const didSelect = selectedCount > 0

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={90} position="center" onClose={onClose}>
      <Overlay.Header title="Quick Add Clients" icon="clients" />

      <Overlay.Content className="py-2 px-5">
        <AllClientsTable
          asCard
          canBatchSelect
          useMainCellSelect
          defaultTab="current_clients"
          tabsListClassName="!ml-0 mb-3"
          onRowSelectionUpdate={setSelected}
          getCanSelect={(data: any) =>
            addedClientIds.includes(data.id)
              ? { canSelect: false, message: 'This client is already added to the group note' }
              : { canSelect: true }
          }
        />
      </Overlay.Content>

      <Overlay.Footer withGradient={false} className="!px-5 !pt-2">
        <div>
          {!didSelect ? (
            <Alert contrast glyph="info">
              Select clients to add to the group note
            </Alert>
          ) : (
            <Flex alignItems="center" gap="1rem">
              <Button
                label={`Add ${countWord('Clients', selectedCount)} as Present`}
                onClick={() => handleAdd(true)}
                color={'green'}
                glyph={'add'}
                type="primary"
                className="flex-[1_1_auto]"
              />

              <div className="flex-[0_0_auto] font-[600] italic text-text-strongly-muted text-[0.9rem]">OR</div>

              <Button
                label={`Add ${countWord('Clients', selectedCount)} as Absent`}
                onClick={() => handleAdd(false)}
                color={'red'}
                glyph={'add'}
                type="default"
                className="flex-[1_1_auto]"
              />
            </Flex>
          )}
        </div>
      </Overlay.Footer>
    </Overlay>
  )
}
