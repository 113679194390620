import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { persist } from 'zustand/middleware'
import { useParams, useRouteMatch } from 'react-router-dom'
import create from 'zustand'
import size from 'lodash/size'

import { APP_URLS } from '@behavehealth/constructs/MyAccount/utils'
import { invalidateQueries, useGet } from '@behavehealth/hooks/useNewAPI'
import { sleep } from '@behavehealth/utils/functions'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import {
  Alert,
  Button,
  Card,
  Dropdown,
  DropdownItem,
  Flex,
  Glyph,
  Grid,
  HelpTagIframe,
  Icon,
  Link,
  Page,
  PageLayout,
  PageSection,
  Status,
  SummonOverlay,
  TipAlert,
} from '@behavehealth/components'

import Transactions from '@behavehealth/components/Elements/transactions/Transactions'
import ClientPaymentMethodsSection from '@behavehealth/constructs/Stripe/ClientPaymentMethodsSection'
import OnlinePaymentsCollectionCards from '@behavehealth/constructs/Financials/OnlinePaymentsCollectionCards'
import FinancialPlanCard from '@behavehealth/constructs/Financials/FinancialPlanCard'

import ChargeStatus from '@behavehealth/components/Statuses/ChargeStatus'
import Notifications from '@behavehealth/modules/notifications'
import StripePaymentOverlay from '@behavehealth/components/Stripe/StripePaymentOverlay'
import useStore from '@behavehealth/modules/store'
import ButtonGroup from '@behavehealth/components/ButtonGroup'
import SmartStatus from '@behavehealth/components/SmartStatus'

import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { InvoiceStatus } from '@behavehealth/constructs/InvoiceElements/InvoiceStatus'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import CardsGrid from '@behavehealth/components/CardsGrid'
import FinancialCard from '@behavehealth/components/Cards/FinancialCard'
import Tabs from '@behavehealth/components/Tabs'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { InsuranceInvoicesDataTable } from '@behavehealth/constructs/RCM/InsuranceInvoicesDataTable'

const INVOICE_STATUSES = {
  draft: {
    label: 'Draft',
    color: 'blue',
  },
  paid: {
    label: 'Paid',
    color: 'green',
  },
  due: {
    label: 'Due',
    color: 'orange',
  },
  overdue: {
    label: 'Overdue',
    color: 'red',
  },
}

const pageConfig = {
  feature: 'financials',
  help: <HelpTagIframe id="client_financials" />,
  marketingID: 'client_financials',
}

type Props = {
  canCreate: boolean
}

const useFinancialsTabs = create((set: any, get: any) => ({
  activeTab: 'ledger',
  setActiveTab: (tab: string) => set({ activeTab: tab }),
}))

const Financials: React.FC<Props> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { url } = match

  const activeTab = useFinancialsTabs((state) => state.activeTab)
  const setActiveTab = useFinancialsTabs((state) => state.setActiveTab)

  const tenant = useStore((state) => state.tenant)
  const isBehave = useStore((state) => state.isBehave)

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const invoicePaymentLink = null // `${APP_URLS.mini_apps}/client-invoices/${tenant.subdomain}/pay/${client?.external_id}`
  const invoicesLink = `${APP_URLS.mini_apps}/invoices/${tenant.subdomain}/client/${client?.external_id}`

  const { copy: copyInvoiceLink, didCopy: invoiceLinkCopied } = useCopyToClipboard({
    text: invoicesLink,
    notification: 'Online Invoices Link copied!',
  })

  const onSuccess = async () => {
    Notifications.send('Refreshing…', 'positive')
    await sleep(5000)

    invalidateQueries(['client', client.id, 'financial_transactions'])
  }

  const invoicesColumns = React.useMemo(
    () => [
      {
        title: 'Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={`${match.url}/invoices/${data.id}`} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) => <InvoiceStatus small status={value} />,
      },
      {
        title: 'Invoice Date',
        model: 'invoiced_at',
        type: 'date_time',
      },
      {
        title: 'Due Date',
        model: 'due_at',
        type: 'date_time',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
    ],
    [],
  )

  const recurringInvoicesColumns = React.useMemo(
    () => [
      {
        title: 'Invoice #',
        model: 'invoice_number',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={`${match.url}/recurring-invoices/${data.id}`} value={value} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        formatValue: ({ value }: any) =>
          value === 'active' ? <Status small color="green" label="Active" /> : <Status small color="red" label="Stopped" />,
      },
      {
        title: 'Next Invoice Date',
        model: 'next_invoiced_at',
        type: 'date_time',
      },
      {
        title: 'Next Due Date',
        model: 'next_due_at',
        type: 'date_time',
      },
      {
        title: 'Generated',
        model: 'created_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <Page {...pageConfig}>
      <PageLayout>
        {/* Financial Plan Details */}
        {/* <PageSection.Header graphic={<Icon icon="reports" size={20} />} className="!m-0 !-mb-3">
          <PageSection.Title title="Financial Stats" />
        </PageSection.Header>

        <CardsGrid>
          <FinancialCard title="Client Total Collected" value={parseFloat('4000.00')} />
          <FinancialCard title="Client Total Due" value={parseFloat('750.00')} valueColor="red" />
          <FinancialCard title="Ledger Total Collected" value={parseFloat('3000.00')} />
          <FinancialCard title="Ledger Total Due" value={parseFloat('500.00')} valueColor="red" />
          <FinancialCard title="Invoices Total Collected" value={parseFloat('1000.00')} />
          <FinancialCard title="Invoices Total Due" value={parseFloat('250.00')} valueColor="red" />
        </CardsGrid> */}

        <PageSection.Header graphic={<Icon icon="checklist" size={20} />} className="!m-0 !-mb-3">
          <PageSection.Title title="Financial Plan Details" />
        </PageSection.Header>

        <FinancialPlanCard client={client} />

        {tenant?.is_stripe_connected && client && <ClientPaymentMethodsSection title="Payment Methods" client={client} tenant={tenant} />}

        {/* Stripe Connection */}
        {!tenant?.is_stripe_connected && (
          <Alert type="warning" glyph="info">
            Set up <Link to="/settings/online-payments">Online Payments</Link> and share the Ledger Link with {client?.name} or family to
            automatically collect payments
          </Alert>
        )}

        <TipAlert contrast type="warning" localStorageKey="financials_changed">
          <b>Please note:</b> Based on feedback received from all of you, we made the next changes to Financials:
          <ol>
            <li>We use one Online Ledger Link per Client</li>
            <li>We added Online Payments to collect Payments without viewing transactions</li>
            <li>
              We replaced <strong>Payers</strong> with <strong>Payment Methods</strong>. It makes Payments and reconciliation easier
            </li>
            <li>Payments can be reconciled against Payment Methods</li>
            <li>
              <strong>Automatic Collection</strong> can be updated anytime, as long as the Status of the Charge is{' '}
              <ChargeStatus status="future" css={{ display: 'inline-block', lineHeight: '1rem' }} />
            </li>
            <li>
              We simplified the Email / Phone Number verification for accessing the Online Ledger, and made it more clear who's Ledger it is
            </li>
            <li>
              We added an Access List, viewable under "Who can access?". Update this by adding new Contacts to this Client, or set
              Organizations as Payers
            </li>
          </ol>
        </TipAlert>

        {isBehave && (
          <div className="flex justify-center">
            <ButtonGroup>
              <Button
                label="Ledger Transactions"
                icon="cashbook_green"
                size={300}
                type={activeTab === 'ledger' ? 'primary' : 'default'}
                color={activeTab === 'ledger' ? 'text' : 'text'}
                onClick={() => setActiveTab('ledger')}
              />

              <Button
                label="Invoices & Statements"
                icon="invoices"
                size={300}
                type={activeTab === 'invoices' ? 'primary' : 'default'}
                color={activeTab === 'invoices' ? 'text' : 'text'}
                onClick={() => setActiveTab('invoices')}
              />
            </ButtonGroup>
          </div>
        )}

        {/* Ledger */}
        {activeTab === 'ledger' && (
          <PageSection>
            <PageSection.Header
              graphic={<Icon icon="cashbook_green" size={22} />}
              after={
                <Flex centerY gap="0.75rem">
                  <SummonOverlay
                    overlay={
                      <StripePaymentOverlay
                        stripeConnectID={tenant?.stripe_account_id}
                        passFees={tenant?.financial_prefs?.pass_fees_to_payers}
                        customer={client}
                        source="EHR"
                        onSuccess={onSuccess}
                      />
                    }
                  >
                    <Button
                      label="Collect a Payment"
                      type="primary"
                      glyph="dollar"
                      color="green"
                      size={200}
                      isDisabled={false}
                      isLoading={false}
                      permission="payment_methods.actions.collect_a_payment"
                    />
                  </SummonOverlay>
                  <Dropdown
                    label="Add New…"
                    testKey="add_new_transaction_dropdown"
                    glyph="add"
                    buttonType="primary"
                    buttonSize={200}
                    permission="ledger.create"
                  >
                    <DropdownItem
                      icon="financials"
                      color="green"
                      label="Charges"
                      link={{
                        pathname: `${url}/charge-builder`,
                        parent: match,
                      }}
                    />
                    <DropdownItem
                      icon="financials"
                      color="green"
                      label="Payment"
                      link={{
                        pathname: `${url}/payments/new`,
                        parent: match,
                      }}
                    />
                    <DropdownItem
                      icon="financials"
                      color="green"
                      label="Credit"
                      link={{
                        pathname: `${url}/credits/new`,
                        parent: match,
                      }}
                    />
                    <DropdownItem
                      icon="financials"
                      color="green"
                      label="Refund"
                      link={{
                        pathname: `${url}/refunds/new`,
                        parent: match,
                      }}
                    />
                    <DropdownItem
                      icon="financials"
                      color="green"
                      label="Write-Off"
                      link={{
                        pathname: `${url}/write-offs/new`,
                        parent: match,
                      }}
                    />
                  </Dropdown>
                </Flex>
              }
            >
              <PageSection.Title title="Ledger Transactions" />
            </PageSection.Header>

            <PageSection.Content>
              <div className="mb-4">
                <OnlinePaymentsCollectionCards client={client} />
              </div>

              <Transactions
                allowDuplicate
                showReportLinks
                name={['client', resource_id, 'financial-transactions']}
                url={`/residents/${resource_id}/financial_transactions`}
              />
            </PageSection.Content>
          </PageSection>
        )}

        {/* Invoices */}
        {activeTab === 'invoices' && (
          <PageSection>
            <PageSection.Header
              graphic={<Icon icon="invoices" size={22} />}
              after={
                <Dropdown label="Add New…" glyph="add" buttonType="primary" buttonSize={200} permission="ledger.create">
                  <DropdownItem
                    icon="invoices"
                    color="green"
                    label="Single Invoice"
                    link={{
                      pathname: `${url}/invoices/new`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    label="Multiple Invoices"
                    glyph="stack"
                    color="green"
                    size={200}
                    link={{
                      pathname: `${url}/invoices/batch`,
                      parent: match,
                    }}
                  />
                  <DropdownItem
                    label="Recurring Invoices"
                    glyph="reset"
                    color="green"
                    size={200}
                    link={{
                      pathname: `${url}/recurring-invoices/new`,
                      parent: match,
                    }}
                  />
                </Dropdown>
              }
            >
              <PageSection.Title title="Invoices & Statements" />
            </PageSection.Header>

            <Tabs defaultTab="invoices">
              <Tabs.List className="-mt-3 mb-5">
                <Tabs.Item label="Invoices" name="invoices" />
                <Tabs.Item label="Statements" name="statements" />
                <Tabs.Divider />
                <Tabs.Item label="Recurring Invoices" name="recurring" />
              </Tabs.List>

              <Tabs.Panels>
                <Tabs.Panel name="invoices">
                  <PageSection.Content>
                    <div className="mb-4">
                      <div className="grid gap-4 grid-cols-1">
                        <Card className="px-3 py-2">
                          <Grid gap="1rem">
                            <Flex gap="1rem" justifyContent="space-between">
                              <h3 className="text-[1.1rem]">
                                <Flex gap="0.5rem" alignItems="center">
                                  <Glyph glyph="ledger" size={24} className="" />
                                  <span>Online Invoices</span>
                                </Flex>
                              </h3>
                            </Flex>

                            <div className="pl-[32px] -mt-[12px] -mb-1.5 text-[0.95rem]">
                              Use the <strong>Online Invoices</strong> to securely <strong>share</strong> some of the invoices details and
                              collect Payments.
                            </div>

                            <Flex gap="0.25rem" className="pl-[32px]">
                              <Button
                                size={100}
                                glyph={invoiceLinkCopied ? 'check' : 'copy'}
                                label={invoiceLinkCopied ? 'Link Copied!' : 'Copy Invoice Link'}
                                onClick={copyInvoiceLink}
                              />
                              <Button size={100} type="link" label="Open in New Tab" target="_blank" href={invoicesLink} />
                            </Flex>
                          </Grid>
                        </Card>
                      </div>
                    </div>

                    <CardsGrid className="mb-4">
                      <FinancialCard title="Invoices Balance" value={parseFloat('0')} />
                      <FinancialCard title="Amount Invoiced" value={parseFloat('10000')} valueColor="red" />
                      <FinancialCard title="Amount Paid" value={parseFloat('10000')} prefix="+" />
                      <FinancialCard title="Amount Due" value={parseFloat('0')} valueColor="gray" />
                    </CardsGrid>

                    <DataTable
                      asCard
                      title="Invoices"
                      icon="invoices_blue"
                      data={INVOICES}
                      columns={invoicesColumns}
                      meta={{ count: size(INVOICES) }}
                    />
                  </PageSection.Content>
                </Tabs.Panel>

                <Tabs.Panel name="statements">
                  <div className="grid">
                    <InsuranceInvoices />
                  </div>
                </Tabs.Panel>

                <Tabs.Panel name="recurring">
                  <PageSection.Content>
                    <div className="mb-4">
                      <div className="grid gap-4 grid-cols-1">
                        <Alert small contrast glyph="info">
                          <h3 className="text-[1rem]">About Recurring Invoices</h3>

                          <ul className="m-0 mt-1 pl-5">
                            <li>
                              Use this feature to <b>automatically generate Invoices</b> for your Clients on a regular basis
                            </li>
                            <li>
                              Invoices will be generated on the <b>next due date</b> based on the recurrence settings you choose
                            </li>
                            <li>
                              You can set Recurring Invoices to <b>stop generating</b> after a certain number of <b>occurrences</b>, on a{' '}
                              <b>specific date</b>, or on the client's <b>discharge date</b>
                            </li>
                          </ul>
                        </Alert>
                      </div>
                    </div>

                    <DataTable
                      asCard
                      title="Recurring Invoices"
                      glyph="reset"
                      data={RECURRING_INVOICES}
                      columns={recurringInvoicesColumns}
                      meta={{ count: size(INVOICES) }}
                    />
                  </PageSection.Content>
                </Tabs.Panel>
              </Tabs.Panels>
            </Tabs>
          </PageSection>
        )}
      </PageLayout>
    </Page>
  )
}

const InsuranceInvoices = () => {
  const match = useRouteMatch()

  const clientId = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['client', clientId, 'invoices'],
    endpoint: `/residents/${clientId}/invoices`,
    params: { category: 'insurance' },
    localStorageKey: 'client_insurance_invoices_v1',
  })

  return <InsuranceInvoicesDataTable asCard to={(data) => `${match.url}/client-statements/${data.id}`} {...tableProps} />
}

const INVOICES = [
  {
    id: '1',
    invoice_number: 'DEMO_INV_1234',
    external_id: '142a7c1f-6e4d-4be9-94db-05334f368553',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'paid',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '2',
    invoice_number: 'DEMO_INV_1235',
    external_id: '6ea4dca9-dcf0-43cf-90af-cff07d17183f',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'paid',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '3',
    invoice_number: 'DEMO_INV_1236',
    external_id: '60abb8e2-35a7-48a6-bbca-2f95af34e505',
    invoiced_at: '2021-07-06T06:30:42.474-04:00',
    due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    updated_at: '2021-07-06T06:30:42.474-04:00',
    status: 'cancelled',
    payment_method: 'Visa **** 5172',
    url: 'https://behavehealth.com',
  },
]

const RECURRING_INVOICES = [
  {
    id: '1',
    invoice_number: 'REC_INV_1234',
    external_id: '142a7c1f-6e4d-4be9-94db-05334f368553',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'active',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
  {
    id: '2',
    invoice_number: 'REC_INV_1235',
    external_id: '6ea4dca9-dcf0-43cf-90af-cff07d17183f',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'active',
    payment_method: 'Visa **** 5172',
    url: 'https://behavehealth.com',
  },
  {
    id: '3',
    invoice_number: 'REC_INV_1236',
    external_id: '60abb8e2-35a7-48a6-bbca-2f95af34e505',
    next_invoiced_at: '2021-07-06T06:30:42.474-04:00',
    next_due_at: '2021-07-06T06:30:42.474-04:00',
    created_at: '2021-07-06T06:30:42.474-04:00',
    status: 'stopped',
    payment_method: null,
    url: 'https://behavehealth.com',
  },
]

export default withPageError(withMarketing(Financials, pageConfig))
