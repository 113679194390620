import React from 'react'
import { connect } from 'react-redux'

import { apiCreate } from '../../../modules/api'
import { ICONS } from '../../../theme'

import Alert from '../../Alert'
import Button from '../../Button'
import ContextShow from '../../Forms/ContextShow'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Label from '../../Label'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import Overlay from '../../Overlay'
import Section from '../../Section'
import Status from '../../Status'
import Textarea from '../../Forms/Textarea'
import Text from '../../Typography/Text'

import { OverlayBase, defaultMapStateToProps, defaultMapDispatchToProps } from './OverlayBase'
import { withOverlayError } from '../../../hocs/withOverlayError'

class ClientsMessageBlastOverlay extends OverlayBase {
  save = async () => {
    const { clients_applicants, clients_accepted, clients_declined, clients_current, clients_alumni }: any = this.state

    let model = this.form.current.getFormValue()

    const applicantsSlugs = clients_applicants ? clients_applicants?.map((o) => o.slug) : []
    const acceptedSlugs = clients_accepted ? clients_accepted?.map((o) => o.slug) : []
    const declinedSlugs = clients_declined ? clients_declined?.map((o) => o.slug) : []
    const currentSlugs = clients_current ? clients_current?.map((o) => o.slug) : []
    const alumniSlugs = clients_alumni ? clients_alumni?.map((o) => o.slug) : []

    const clients_slugs = [...applicantsSlugs, ...acceptedSlugs, ...declinedSlugs, ...currentSlugs, ...alumniSlugs].filter((n) => n)

    const linked = {
      chat_type: 'client',
      clients_slugs: clients_slugs,
      dynamic_slugs: {
        program_ids: model?.program_ids,
        property_ids: model?.property_ids,
      },
      clients: {
        applicants: clients_applicants,
        accepted: clients_accepted,
        declined: clients_declined,
        current: clients_current,
        alumni: clients_alumni,
      },
    }

    await apiCreate({
      name: 'chat_message_blasts',
      url: `/chat_message_blasts/clients`,
      params: {
        ...model,
        ...linked,
      },
    })

    this.close()
  }

  renderHeader = () => <Overlay.Header icon="clients_chat" title="Message Blast" />

  renderContent = () => {
    const { $new, $editable } = this.state
    const { record, timezone } = this.props

    return (
      <Overlay.Content>
        <Form
          getForm={this.form}
          timezone={timezone}
          isEditable={$editable}
          onValidationUpdate={this.onValidationUpdate}
          initialModel={record}
        >
          <Section>
            <FormSection layout="vertical">
              {$new && (
                <Alert glyph="info">
                  Send a message to all clients from a program or property. The message will be sent individually to every client.
                </Alert>
              )}

              {/* <Select label="Message Type" model="content" defaultValue="text">
                <Option label="Text" value="text" />
                <Option label="Poll" value="poll" />
              </Select> */}

              <ContextShow when="content" is="poll">
                {$new && (
                  <Alert glyph="info" type="neutral">
                    Poll message – get quick <Status inline label="Yes" color="green" /> / <Status inline label="No" color="red" /> answers
                    to your question. All results will be displayed in this same overlay.
                  </Alert>
                )}
              </ContextShow>

              <Textarea
                label="Message"
                model="message"
                validations={{
                  presence: {
                    message: 'Please enter a message',
                  },
                }}
              />
            </FormSection>
          </Section>

          {$new && (
            <>
              <Section headingType="h3" title="Clients">
                <FormSection layout="vertical">
                  <MultiOverlaySelector
                    model="clients.applicants"
                    label="Select from Applicants"
                    type="clients_by_status.applicants"
                    icon={ICONS.admissions}
                    options={{ endpoint: '/chat/channels/clients_by_category', params: { category: 'lead' } }}
                    onUpdate={({ object }) => {
                      this.setState({ clients_applicants: object })
                    }}
                  />

                  <MultiOverlaySelector
                    model="clients.accepted"
                    label="Select from Accepted Clients"
                    type="clients_by_status.accepted"
                    icon={ICONS.admissions}
                    options={{ endpoint: '/chat/channels/clients_by_category', params: { category: 'intake' } }}
                    onUpdate={({ object }) => {
                      this.setState({ clients_accepted: object })
                    }}
                  />

                  <MultiOverlaySelector
                    model="clients.declined"
                    label="Select from Declined Clients"
                    type="clients_by_status.declined"
                    icon={ICONS.admissions}
                    options={{ endpoint: '/chat/channels/clients_by_category', params: { category: 'declined' } }}
                    onUpdate={({ object }) => {
                      this.setState({ clients_declined: object })
                    }}
                  />

                  <MultiOverlaySelector
                    model="clients.current"
                    label="Select from Current Clients"
                    type="clients_by_status.current"
                    icon={ICONS.clients}
                    options={{ endpoint: '/chat/channels/clients_by_category', params: { category: 'current' } }}
                    onUpdate={({ object }) => {
                      this.setState({ clients_current: object })
                    }}
                  />

                  <MultiOverlaySelector
                    model="clients.alumni"
                    label="Select from Past Client"
                    type="clients_by_status.alumni"
                    icon={ICONS.alumni_relations}
                    options={{ endpoint: '/chat/channels/clients_by_category', params: { category: 'alumni' } }}
                    onUpdate={({ object }) => {
                      this.setState({ clients_alumni: object })
                    }}
                  />
                </FormSection>
              </Section>

              <Divider />

              <Section headingType="h3" title="Properties & Programs">
                <FormSection layout="vertical">
                  <MultiOverlaySelector model="properties" label="Locations" type="properties.all" icon={ICONS.locations} />
                  <MultiOverlaySelector model="programs" label="Programs" type="programs.all" icon={ICONS.checklist} />
                </FormSection>
              </Section>
            </>
          )}

          {!$new && (
            <Section>
              <div>
                <Label label="Sent to" />
                {record?.channels?.map((channel: any, idx: any) => (
                  <div key={idx}>
                    <Text description={`${channel?.client_name} (${channel?.name})`} />
                  </div>
                ))}
              </div>
            </Section>
          )}

          {/* {!$new && (
            <Section
              headingType="h2"
              title="Poll Results"
              description={
                <>
                  <b>YES:</b> 80% / <b>No:</b> 20%
                </>
              }
            >
              <ContextShow when="content" is="poll">
                {[...tempData.clients].splice(0, 4).map((client) => (
                  <Flex gap={8}>
                    <Status color="green" label="Yes" />
                    <span>{client.name}</span>
                  </Flex>
                ))}

                {[...tempData.clients].splice(3, 1).map((client) => (
                  <Flex gap={8}>
                    <Status color="red" label="No" />
                    <span>{client.name}</span>
                  </Flex>
                ))}
              </ContextShow>
            </Section>
          )} */}
        </Form>
      </Overlay.Content>
    )
  }

  renderFooter = () => {
    if (!this.state.$new) return null

    return (
      <Overlay.Footer>
        {this.state.$new ? (
          <Button
            glyph="check"
            label="Send"
            type="primary"
            color="green"
            isLoading={this.props.loading}
            onClick={this.save}
            isDisabled={this.state.isInvalid}
          />
        ) : (
          <Button label="Hide Message for Everyone" glyph="eye" color="gray" />
        )}
      </Overlay.Footer>
    )
  }
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch)
const mapStateToProps = (state, props) => defaultMapStateToProps(state, props.match, 'chat_message_blasts')

export default connect(mapStateToProps, mapDispatchToProps)(withOverlayError(ClientsMessageBlastOverlay))
