import React from 'react'
import { keyframes } from '@emotion/react'
import { transparentize, tint } from 'polished'
import classNames from 'classnames'
import isUndefined from 'lodash/isUndefined'

import { COLORS, SHADOW } from '../theme'
import { Icon, Glyph as GlyphType } from '../declarations/types'
import withPermissions from '../hocs/withPermissions'
import withSettings from '../hocs/withSettings'

import Card from './Card'
import CardHeader from './CardHeader'
import CardContent from './CardContent'
import Flex from './Flex'
import Glyph from './Glyph'
import Graphic from './Graphic'

import { AccordionsContext } from './AccordionsContext'

type Props = {
  aside?: React.ReactNode
  baseline?: string | number
  children?: React.ReactNode
  className?: any
  testKey?: any
  description: string
  glyph?: GlyphType
  graphic?: string
  help?: string
  icon?: Icon
  initialModel?: any
  isOpen?: boolean
  title: string
  toggleOpen?: boolean
}

const AccordionCard: React.FC<Props> = (props) => {
  const { id, identifier, className, aside, baseline, children, testKey, description, glyph, graphic, icon, isOpen, title, toggleOpen } =
    props

  const [open, setOpen] = React.useState(isOpen)
  const context: any = React.useContext(AccordionsContext)

  React.useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  React.useEffect(() => {
    if (!context) return
    context.register(identifier || title)
  }, [])

  const onToggle = () => {
    if (props.onToggle) {
      props.onToggle()
      return
    }

    setOpen((c) => !c)
  }

  React.useEffect(() => {
    if (isUndefined(toggleOpen)) return

    if (!open) setOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleOpen])

  const classes = classNames('accordion', {
    'is-open': open,
    'is-not-open': !open,
  })

  return (
    <Card id={id} baseline={baseline} className={className}>
      <CardHeader
        css={styles.header}
        onClick={onToggle}
        onDoubleClick={onToggle}
        after={
          <Flex center gap={8} className="!ml-auto !items-center">
            {aside}
            <AccordionIcon isOpen={open} />
          </Flex>
        }
        graphic={<Graphic icon={icon} glyph={glyph} graphic={graphic} size={18} css={{ width: baseline }} />}
      >
        <div css={{ paddingLeft: !(icon || glyph || graphic) && '0.5rem', flex: '999 1 auto' }}>
          <Flex horizontal>
            <h3 data-test={testKey} className="card-title">
              {title}
            </h3>
          </Flex>

          <span className="opacity-75">{description}</span>
        </div>
      </CardHeader>

      <CardContent className={classes} css={styles.content}>
        {children}
      </CardContent>
    </Card>
  )
}

const accordionIconStyles = {
  width: 20,
  height: 20,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  borderRadius: '50%',

  '.icon': {
    transform: 'scale3d(1,1,1)',
    fill: COLORS.blue,
    transition: 'all 160ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  '&.is-open': {
    opacity: 0.6,
    background: COLORS.text,
    transform: 'scale3d(0.85,0.85,0.85) rotateZ(135deg)',

    '.icon': {
      fill: 'white',
    },
  },
}

const AccordionIcon = ({ isOpen }) => (
  <div className={isOpen ? 'is-open' : ''} css={accordionIconStyles}>
    <Glyph className="icon" glyph="add" color="red" size={16} />
  </div>
)

const contentAnimation = keyframes`
  0% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
`

const styles = {
  header: {
    cursor: 'pointer',
  },
  content: {
    height: '0',
    overflow: 'hidden',
    display: 'none',
    padding: '1rem',
    position: 'relative',
    zIndex: 0,

    '&.is-open': {
      height: 'auto',
      display: 'block',
      animation: `${contentAnimation} 200ms cubic-bezier(0.175, 0.885, 0.32, 1.275)`,
    },
  },
}

AccordionCard.defaultProps = {
  baseline: 48,
  isOpen: false,
}

export default withSettings(withPermissions(AccordionCard))
