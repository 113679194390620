import React from 'react'

import { isDefined } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'

import { DataTable } from '../../components/DataTable/DataTable'
import { EmptyCell } from '../../components/DataTable/cells/EmptyCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import AdmissionQuickView from '../../components/Overlays/quick/AdmissionQuickView'
import Button from '../../components/Button'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import Form from '../../components/Forms/Form'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'

import { FILTERS } from '../Filters/config'
import { HighRiskAlertStatus } from '../HighRiskAlert/HighRiskAlertStatus'
import { ClientTrackSelector } from '../ClientTracks/ClientTrackSelector'

export const DeclinedDataTable = () => {
  const tableProps = useDataTable({
    name: 'clients',
    endpoint: '/residents',
    params: { status: 'declined' },
    localStorageKey: 'declined_intakes_v1',
  })

  const [client, setClient]: any = React.useState(null)
  const [referralNotesData, setReferralNotesData]: any = React.useState(null)
  const [referralOutNotesData, setReferralOutNotesData]: any = React.useState(null)
  const [isReferralNotesOverlayOpen, setIsReferralNotesOverlayOpen] = React.useState(false)
  const [isReferralOutNotesOverlayOpen, setIsReferralOutNotesOverlayOpen] = React.useState(false)

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            to={`/admissions/${data.id}`}
            quickViewOverlay={<AdmissionQuickView client={data} />}
          />
        ),
      },
      {
        title: 'High Risk Alert',
        model: 'high_risk_title',
        width: 300,
        formatValue: ({ data }: any) => {
          if (!data.high_risk_title) return null

          return <HighRiskAlertStatus title={data.high_risk_title} notes={data.high_risk_notes} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'declined',
      },
      {
        title: 'Client Track',
        model: 'client_track',
        featureFlag: 'client_tracks',
        formatValue: ({ value, data }) => <ClientTrackSelector clientId={data.id} activeTrack={value} buttonLabel={null} />,
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Last Application Date',
        id: 'last_application_date',
        model: 'current_intake_application.created_at',
        type: 'date_time',
      },
      {
        title: 'Cell Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Referral In',
        id: 'referral_source',
        model: 'referral_source',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral In Notes',
        model: 'referral_notes',
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <ReferralNotesCell
            value={value}
            client={data}
            testId="referral_in_notes_cell"
            onEdit={() => {
              setClient(data)
              setReferralNotesData(value)
              setIsReferralNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Referral Out',
        id: 'referral_out',
        model: 'referral_out',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral Out Notes',
        model: 'referral_out_notes',
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <ReferralNotesCell
            value={value}
            client={data}
            testId="referral_out_notes_cell"
            onEdit={() => {
              setClient(data)
              setReferralOutNotesData(value)
              setIsReferralOutNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Preferred Name',
        model: 'preferred_name',
        width: 180,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [],
  )

  return (
    <>
      <div className="grid grid-cols-[100%]">
        <DataTable
          {...tableProps}
          testKey="declined_data_table"
          title="Declined Applicants"
          icon="admissions"
          columns={columns}
          canSelect={false}
          filtersConfig={FILTERS.declined_intakes}
          onRowSelect={() => {}}
          onDataInitialized={() => {}}
          updateKey="client"
          updateDeleteEndpoint="/residents"
          invalidate={['clients', { status: 'declined' }]}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/declined',
            },
          ]}
        />
      </div>

      <SummonOverlay
        isOpen={isReferralNotesOverlayOpen}
        onClose={() => {
          setClient(null)
          setReferralNotesData(null)
          setIsReferralNotesOverlayOpen(false)
        }}
        overlay={<ReferralNotesOverlay value={referralNotesData} client={client} />}
      />

      <SummonOverlay
        isOpen={isReferralOutNotesOverlayOpen}
        onClose={() => {
          setClient(null)
          setReferralOutNotesData(null)
          setIsReferralOutNotesOverlayOpen(false)
        }}
        overlay={<ReferralOutNotesOverlay value={referralOutNotesData} client={client} />}
      />
    </>
  )
}

const formatRichTextValue = (value: any) => {
  if (!isDefined(value)) return <EmptyCell />

  return value.replace(/<[^>]*>/g, ' ')
}

const ReferralNotesCell = ({ client, value, onEdit, testId }: any) => {
  if (!client) return null

  return (
    <div className={editCellClasses}>
      <div className={editValueClasses}>{formatRichTextValue(value)}</div>

      <Button
        hideLabel
        testKey={`edit_${testId}`}
        glyph="edit"
        size={100}
        type="minimal"
        className={editButtonClasses}
        permission="clients.general_info.referral_details.edit"
        onClick={onEdit}
      />
    </div>
  )
}

const ReferralNotesOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['clients', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Referral In Notes" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ referral_notes: value || '' }}>
            <MiniRichTextEditor label="Referral In Notes" model="referral_notes" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const ReferralOutNotesOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['clients', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Referral Out Notes" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ referral_out_notes: value || '' }}>
            <MiniRichTextEditor label="Referral Out Notes" model="referral_out_notes" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const editCellClasses = 'flex flex-nowrap items-center w-full'
const editValueClasses = 'flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis'
const editButtonClasses = 'svg:mr-0'
