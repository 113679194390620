import React from 'react'
import { keyframes } from '@emotion/react'
import * as Popover from '@radix-ui/react-popover'
import clsx from 'clsx'
import compact from 'lodash/compact'
import groupBy from 'lodash/groupBy'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, SHADOW } from '../../../theme'
import { mapToArray } from '../../../utils/functions'
import { useFuse } from '../../../hooks/useFuse'
import { useGet, useUpdate, getPrefix } from '../../../hooks/useNewAPI'
import { usePortal } from '../../../hooks/usePortal'

import { PopoverMenuSearch } from '../../PopoverMenu'
import { Tag } from '../../Tag'
import Button from '../../Button'
import Card from '../../Card'
import Glyph from '../../Glyph'
import Grid from '../../Grid'
import Loader from '../../Loader'
import State from '../../State'
import Tooltip from '../../Tooltip'

import { DataTableCell } from '../DataTableCell'
import { EmptyCell } from './EmptyCell'
import { useStore } from '../useStore'

export const TagsCell = (props: any) => {
  const { canEdit, invalidate, invalidateKeys, queryKey, record, tagSmartCategories, value, column } = props

  const updateId = record?.id

  const updateKey: any = useStore((state: any) => state.updateKey)
  const updateDeleteEndpoint: any = useStore((state: any) => state.updateDeleteEndpoint)

  const portalContainer = usePortal('default')

  const [isOpen, setIsOpen] = React.useState(false)
  const [didChange, setDidChange] = React.useState(false)
  const [menuTags, setMenuTags]: any = React.useState(value || [])
  const [searchTerm, setSearchTerm]: any = React.useState('')

  const menuTagIds = menuTags?.map?.((o) => o.id)

  const { data: tagGroups, isLoading: isLoadingTagGroups }: any = useGet({
    name: compact(['tag-groups', tagSmartCategories]),
    url: `/tag_groups`,
    params: {
      smart_categories: tagSmartCategories,
    },
  })

  const tagGroupsByCategory = React.useMemo(() => {
    return groupBy(tagGroups || [], 'category')
  }, [tagGroups])

  const flatTags = React.useMemo(() => {
    if (!tagGroups || size(tagGroups) === 0) return []

    const result = []

    for (const tagGroup of tagGroups) {
      if (!tagGroup?.tags) continue

      for (const tag of tagGroup.tags) {
        result.push({
          ...tag,
          tag_group_id: tagGroup.id,
        })
      }
    }

    return result
  }, [tagGroups])

  const searchData = useFuse(flatTags, searchTerm, { keys: ['name', 'description'] })

  const searchIds = React.useMemo(() => {
    const result: any = {
      tags: [],
      groups: [],
    }

    for (const tag of searchData) {
      const includesTagId = result.tags.includes(tag?.id)
      const includesTagGroupId = result.groups.includes(tag?.tag_group_id)

      if (!includesTagId) result.tags.push(tag?.id)
      if (!includesTagGroupId) result.groups.push(tag?.tag_group_id)
    }

    return result
  }, [searchData])

  const isSearchActive = size(searchTerm) > 1
  const isSearchEmpty = isSearchActive && size(searchData) === 0

  const { mutateAsync, isLoading: isUpdating }: any = useUpdate({
    name: [updateKey, updateId].flat(),
    url: `${updateDeleteEndpoint}/${updateId}`,
    invalidate: column?.invalidate || invalidate,
    invalidateKeys: ['tag-live-reports', queryKey, invalidateKeys],
    onSuccess: (_data: any, variables: any, queryClient: any) => {
      if (!queryKey || !updateId || !variables.tags) return

      const prefix = getPrefix()

      // update tags in queryKey cache
      queryClient.setQueryData([prefix, queryKey].flat(), (oldData: any) => {
        if (!oldData?.data) return

        const index = oldData.data.findIndex((o) => o.id === updateId)
        if (index === -1) return

        const newData = produce(oldData, (draft: any) => {
          draft.data[index].tags = variables.tags
        })

        return newData
      })
    },
  })

  const isTagGroupsEmpty = size(tagGroups) === 0
  const isCellTagsEmpty = size(value) === 0

  const rootClasses = clsx(canEdit && isOpen && 'is-open')

  const clearSearch = () => {
    setSearchTerm('')
  }

  const toggleOpen = (event) => {
    event.stopPropagation()

    setIsOpen((c) => !c)
  }

  React.useEffect(() => {
    if (!value) return
    setMenuTags(value)
  }, [value])

  const addTag = (tag: any) => {
    setDidChange(true)

    const newTags = produce(menuTags, (draft: any) => {
      draft.push(tag)
    })
    setMenuTags(newTags)
  }

  const removeMenuTag = (id: number) => {
    setDidChange(true)

    const newTags: any = produce(menuTags, (draft: any) => {
      const index = draft.findIndex((o) => o.id === id)
      draft.splice(index, 1)
    })
    setMenuTags(newTags)
  }

  const handleSave = () => {
    if (didChange && mutateAsync) {
      mutateAsync({
        tag_ids: menuTags.map((o: any) => o.id),
        tags: menuTags, // used in the optimistic update
      })
    }
  }

  return (
    <DataTableCell {...props} className={rootClasses} css={STYLES.cellRoot} onClick={() => setIsOpen(true)}>
      {isUpdating && (
        <div style={{ marginRight: '0.5rem' }}>
          <Loader size={18} color="blue" />
        </div>
      )}

      {!isCellTagsEmpty ? (
        <div className="tags-wrapper">
          <div className="tags-inner">
            {value?.map((tag) => (
              <Tag key={tag.id} data={tag} />
            ))}
          </div>
        </div>
      ) : (
        <EmptyCell />
      )}

      {canEdit && (
        <Popover.Root asChild open={isOpen} onOpenChange={setIsOpen}>
          <div css={STYLES.root}>
            <Popover.Trigger asChild>
              <button type="button" onClick={toggleOpen} className="edit-button">
                <Glyph glyph="more_vertical" size={12} />
              </button>
            </Popover.Trigger>

            <Popover.Portal container={portalContainer}>
              <Popover.Content asChild onInteractOutside={handleSave} align="start" side="right">
                <div css={STYLES.menu}>
                  <Card css={STYLES.card}>
                    <PopoverMenuSearch value={searchTerm} onChange={setSearchTerm} onClear={clearSearch} />

                    {isTagGroupsEmpty || isLoadingTagGroups ? (
                      <State
                        title="Tags Manager"
                        minHeight={150}
                        emptyDescription="No tags have been created yet"
                        emptyActions={
                          <Button label="Manage Global Tags →" size={200} type="link" glyph="settings" link="/settings/tags-manager" />
                        }
                        glyph="tag"
                        isEmpty={isTagGroupsEmpty}
                        isLoading={isLoadingTagGroups}
                      />
                    ) : isSearchEmpty ? (
                      <State isEmpty minHeight={150} emptyDescription="No tags found matching your search" glyph="search" />
                    ) : (
                      <>
                        <div css={STYLES.content}>
                          {['smart', 'general'].map((category) => {
                            return (
                              <React.Fragment key={category}>
                                {tagGroupsByCategory?.[category]?.map?.((group: any) => {
                                  if (isSearchActive && !searchIds?.groups?.includes(group.id)) return null

                                  return (
                                    <React.Fragment key={group.id}>
                                      <div css={STYLES.groupHeader}>
                                        <div>{group.name}</div>

                                        <Tooltip content={group.description} position="right" />
                                      </div>

                                      <Grid>
                                        {size(group.tags) === 0 && (
                                          <div className="text-[0.9em] text-text-strongly-muted pt-1 pb-2 px-3">
                                            There are no tags in this group
                                          </div>
                                        )}

                                        {group.tags.map((tag: any) => {
                                          const isAdded = menuTagIds.includes(tag.id)
                                          const isSearched = searchIds?.tags?.includes(tag.id)

                                          if (isSearchActive && !isSearched) return null

                                          return (
                                            <Tooltip key={tag.id} content={tag.description} position="left">
                                              <div
                                                css={STYLES.groupRow}
                                                className={clsx(isAdded && 'is-added')}
                                                style={{ '--color': tag.color }}
                                                onClick={(event) => {
                                                  event.stopPropagation()
                                                  event.nativeEvent.stopImmediatePropagation()

                                                  if (isAdded) removeMenuTag(tag.id)
                                                  else addTag(tag)
                                                }}
                                              >
                                                <Glyph glyph={tag.glyph} size={14} color={tag.color} css={STYLES.glyph} />

                                                <div>{tag.name}</div>

                                                {isAdded && (
                                                  <div className="close-button">
                                                    <Glyph glyph="cross" size={10} color={COLORS.textMuted} />
                                                  </div>
                                                )}
                                              </div>
                                            </Tooltip>
                                          )
                                        })}
                                      </Grid>
                                    </React.Fragment>
                                  )
                                })}
                              </React.Fragment>
                            )
                          })}
                        </div>
                      </>
                    )}
                  </Card>
                </div>
              </Popover.Content>
            </Popover.Portal>
          </div>
        </Popover.Root>
      )}
    </DataTableCell>
  )
}

const animation = keyframes`
  0% {
    opacity: 0;
    transform: scale3d(0.98, 0.98, 0.98) translateY(-12px);
  }
  100% {
    opacity: 1;
    transform: scale3d(1, 1, 1) translateY(0);
  }
`

const STYLES = {
  cellRoot: {
    paddingRight: 0,

    '& > .DataTableCell-value': {
      display: 'flex',
      alignItems: 'center',
    },

    '&.can-edit': {
      '&:hover, &.is-open': {
        cursor: 'pointer',
        boxShadow: `inset 0 0 0 1px ${COLORS.divider}`,
        background: COLORS.white,
        overflow: 'visible',
        zIndex: 10,

        '.tags-wrapper': {
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: 'fit-content',
          padding: '0.25rem var(--padding-x)',
          background: COLORS.white,
          boxShadow: `0 0 0 1px ${COLORS.divider}, ${SHADOW(3)}`,
          borderRadius: 4,
        },

        '.tags-inner': {
          flexWrap: 'wrap',
        },

        '.edit-button': {
          display: 'flex',
        },
      },
    },

    '.tags-inner': {
      display: 'flex',
      flexWrap: 'nowrap',
      paddingRight: 1,
      margin: '-0.2rem',

      '& > *': { margin: '0.2rem' },
    },

    '.edit-button': {
      display: 'none',
      alignItems: 'center',
      justifyContent: 'center',
      width: '1.5rem',
      position: 'absolute',
      border: 'none',
      background: 'white',
      right: 0,
      top: 1,
      bottom: 1,
      zIndex: 1,
    },
  },

  root: {
    whiteSpace: 'nowrap',
  },

  popover: {
    background: 'white',
    width: '100%',
    maxWidth: 300,
    outline: 'none',
    borderRadius: 5,
    boxShadow: SHADOW(3),
    border: `1px solid ${COLORS.divider}`,

    fontSize: '0.96rem',
    fontVariant: 'tabular-nums',
    fontFeatureSettings: 'tnum',
    padding: '0.4em 0.75em',
    zIndex: 0,
  },

  menu: {
    padding: '0 0.25rem',
    animation: `${animation} 100ms cubic-bezier(0.39, 0.575, 0.565, 1) forwards`,
    outline: 'none',
  },

  card: {
    minWidth: '15rem',
    maxWidth: '20rem',
    maxHeight: 400,
    display: 'grid',
    gridTemplateRows: 'min-content 1fr',
    overflow: 'auto !important',
  },

  content: {
    overflowY: 'auto',
  },

  groupHeader: {
    padding: '0.3rem 0.75rem',
    fontWeight: 600,
    fontSize: '0.92rem',
    lineHeight: 'normal',
    marginTop: '0.5rem',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    borderTop: `1px solid ${COLORS.divider}`,

    '&:first-of-type': {
      borderTop: 'none',
      marginTop: '0.25rem',
    },
  },

  groupRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.25rem 0.75rem',
    cursor: 'pointer',
    fontWeight: 400,
    fontSize: '0.95rem',
    width: '100%',

    '&:hover': {
      background: COLORS.hover,
    },

    '&.is-added': {
      fontWeight: 500,

      '.row-swatch': {
        boxShadow: `
          0 0 0 2px ${COLORS.white},
          0 0 0 4px var(--color)
        `,
      },

      '.close-button': {
        marginLeft: 'auto',
      },

      '&:hover .close-button svg': {
        transform: 'scale3d(1.25, 1.25, 1.25)',
        fill: COLORS.red,
      },
    },
  },

  glyph: {
    marginRight: '0.25rem',
  },
}
