import React from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useCreate, useGet } from '../../../hooks/useNewAPI'
import { useSettings } from '../../../hooks/useSettings'

import Button from '../../Button'
import Card from '../../Card'
import Overlay from '../../Overlay'
import Section from '../../Section'
import SummonOverlay from '../../SummonOverlay'
import Tab from '../../Tab'
import TabList from '../../TabList'
import Tabs from '../../Tabs'

import TemplateLegalTooltip from '../TemplateLegalTooltip'
import EventInternalTemplatesTable from '../tables/internal/EventInternalTemplatesTable'
import EventTemplateOverlay from './EventTemplateOverlay'
import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'

const HIDDEN_COLUMNS = ['global_tags', 'smart_flags']

const EventTemplatesImportOverlay = () => {
  const { isBehave } = useSettings()
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const filters = {
    category: 'event',
    status: 'active',
    level: selectedType,
  }

  const { data: templates, isLoading: isLoadingTemplates }: any = useGet({
    name: ['internal-event-templates', filters],
    url: `/internal_templates`,
    params: filters,
    options: { enabled: !!selectedType },
  })

  const { mutate, isLoading: isImporting } = useCreate({
    name: 'import-templates',
    url: '/internal_templates/import',
    invalidate: 'event-templates',
    onSuccess: () => {
      history.goBack()
    },
  })

  const importTemplates = () => {
    const ids = selectedRows.map((o: any) => o.original.id)
    mutate({ ids: ids, category: 'event' })
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Event Templates" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<EventTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          <ImportTemplatesTabs onChange={setSelectedType} />

          <Card className="!mt-4">
            <EventInternalTemplatesTable
              title="Event Templates"
              data={templates}
              isLoading={isLoadingTemplates}
              onSelect={setSelectedRows}
              onClick={setPreview}
              hiddenColumns={!isBehave && HIDDEN_COLUMNS}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isImporting}
          type="primary"
          color="green"
          onClick={importTemplates}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default EventTemplatesImportOverlay
