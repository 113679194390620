import React from 'react'
import clsx from 'clsx'
import size from 'lodash/size'

import BHOption from '../../../../components/Forms/Option'
import BHRadio from '../../../../components/Forms/Radio'
import BHRadioGroup from '../../../../components/Forms/RadioGroup'
import BHSelect from '../../../../components/Forms/Select'

import { getFormElementProps } from '../../utils/functions'
import { isURL } from '../../../../utils/functions'

export const SingleSelectInput: React.FC<any> = (props) => {
  const { children, element, className, hoverElement, useParsedConfig, environment } = props

  const single_select_input_values = React.useMemo(() => {
    if (!element?.config?.single_select_input_values) return []

    const { parsed, single_select_input_values } = element.config

    if (useParsedConfig && size(parsed?.single_select_input_values) === size(single_select_input_values)) {
      return parsed.single_select_input_values || []
    }

    return single_select_input_values || []
  }, [element, useParsedConfig])

  if (!element || !element.config) return null

  const rootClasses = clsx('SINGLE_SELECT_INPUT', className)

  const { is_full_width, options_layout = 'vertical-dense', single_select_input_type = 'radio' } = element.config

  const inputProps = getFormElementProps(element, { useParsedConfig, environment })

  return (
    <div className={rootClasses}>
      {hoverElement}
      {children}

      {single_select_input_type === 'radio' && (
        <BHRadioGroup {...inputProps} layout={options_layout} maxWidth={is_full_width ? '100%' : undefined}>
          {single_select_input_values?.map((option: any) => {
            const descriptionElement = isURL(option.description) ? (
              <a
                href={option.description}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:underline"
                onClick={(e) => {
                  e.stopPropagation()
                }}
              >
                {option.description}
              </a>
            ) : (
              option.description
            )

            return <BHRadio key={option._id} label={option.label} description={descriptionElement} value={option.value} />
          })}
        </BHRadioGroup>
      )}

      {single_select_input_type === 'select' && (
        <BHSelect allowEmpty {...inputProps} asNumber={element.category === 'single_select_input_number'}>
          {single_select_input_values?.map((option: any, index: number) => {
            const prevOption = single_select_input_values[index - 1]
            const group = option.group
            const showGroup = group && (!prevOption || prevOption.group !== group)

            return (
              <React.Fragment key={option._id}>
                {showGroup && (
                  <>
                    {index > 0 && <option disabled></option>}
                    <option disabled>— {group}</option>
                  </>
                )}
                <BHOption label={option.label} description={option.description} value={option.value} />
              </React.Fragment>
            )
          })}
        </BHSelect>
      )}
    </div>
  )
}
