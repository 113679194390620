import React from 'react'

import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import AgreementSections from '../../Elements/AgreementSections'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import DeleteDialog from '../../Dialogs/DeleteDialog'
import Dialog from '../../Dialog'
import Divider from '../../Divider'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Section from '../../Section'
import Textarea from '../../Forms/Textarea'

import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'

const RootLegalAgreementTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'agreements',
    endpoint: '/agreements',
    invalidate: 'agreements',
    options: props,
  })

  const { timezone, isBehave } = useSettings()

  if (isOverlayLoading) {
    return <OverlayLoader position="right" maxWidth={82} />
  }

  return (
    <Overlay
      onClose={close}
      showBackdrop={isEditable}
      maxWidth={82}
      closeWrapper={(element) => (
        <Dialog
          glyph="delete"
          title="Close without saving?"
          message="All changes will be lost. This action cannot be undone."
          yesColor="red"
          yesLabel="Yes, Close Without Saving"
          onYes={close}
          skip={!isEditable}
        >
          {element}
        </Dialog>
      )}
    >
      <Overlay.Header title={data?.name || 'New Legal Agreement Template'} icon="legal_agreement_alt" />

      <Overlay.Content>
        <Form
          useFullModel
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={{
            template_type: 'custom',
            variant: 'legal',
          }}
        >
          <Section title="Agreement Details" headingType="h2">
            <FormSection maxWidth="100%">
              <Input
                label="Agreement Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a template name',
                  },
                }}
                className="!grow-[2]"
              />

              <Textarea useQuickText label="Notes" model="notes" />
            </FormSection>
          </Section>

          <Divider />

          <AgreementSections
            model="sections"
            validations={{
              presence: {
                message: 'Please add at least one section',
              },
            }}
          />

          <Section
            title="Company Signature"
            headingType="h2"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox label="Require Company Signature" model="request_cosigner_signature" />
              </CheckboxGroup>
            }
          />

          <Divider />

          <Section
            title="Behave Health Signature"
            headingType="h2"
            aside={
              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="linethrough">
                <Checkbox label="Require Behave Health Signature" model="should_sign" />
              </CheckboxGroup>
            }
          />
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <>
            <Button
              glyph="check"
              label="Save Agreement"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="100 1 240px"
            />

            {!isNew && <Button glyph="cross" label="Cancel" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && isBehave && (
          <>
            <Button
              label="Edit"
              glyph="edit"
              type="default"
              isDisabled={isLoading}
              onClick={edit}
              flex="100 1 auto"
              permission="settings.agreements.edit"
            />

            <DeleteDialog
              title="Delete Agreement?"
              message="Are you sure you want to delete this agreement? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                label="Delete"
                type="default"
                glyph="delete"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="settings.agreements.edit"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const LegalAgreementTemplateOverlay = withOverlayError(RootLegalAgreementTemplateOverlay)
