import React from 'react'

import Select from '../Select'
import Option from '../Option'

const MedicationDescriptionSelect = ({ label, model, validations, testKey }) => (
  <Select fullWidth allowEmpty testKey={testKey} label={label} model={model} validations={validations}>
    <Option label="Medical" value="medical" />
    <Option label="Psychiatric" value="psychiatric" />
    <Option label="Taper" value="taper" />
    <Option label="Palliatives" value="palliatives" />
    <Option label="Over the Counter (OTC)" value="otc" />
    <Option label="MAT (Vivitrol, Suboxone, etc)" value="mat" />
    <Option label="Vaccine" value="vaccine" />
    <Option label="Other" value="other" />
  </Select>
)

MedicationDescriptionSelect.defaultProps = {
  label: 'Medication Description',
}

export default MedicationDescriptionSelect
