import * as Sentry from '@sentry/react'

class SentryTracking {
  constructor() {
    if (SentryTracking.instance instanceof SentryTracking) {
      return SentryTracking.instance
    }

    Object.freeze(this)
    SentryTracking.instance = this
  }

  init = (dsn: string, version: string) => {
    if (process.env.NODE_ENV === 'development') return null

    Sentry.init({
      dsn: dsn,
      release: version,
      environment: process.env.NODE_ENV,
      attachStacktrace: true,
      maxBreadcrumbs: 50,
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      ],
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.browserProfilingIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Tracing
      tracesSampleRate: 1.0,
      tracePropagationTargets: ['localhost', /^https:\/\/api\.behavehealth\.com/],
      beforeSend(event) {
        if (event?.exception?.values[0].mechanism?.type === 'onunhandledrejection') return null
        else return event
      },
      // Set profilesSampleRate to 1.0 to profile every transaction.
      // Since profilesSampleRate is relative to tracesSampleRate,
      // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
      // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
      // results in 25% of transactions being profiled (0.5*0.5=0.25)
      profilesSampleRate: 1.0,
      // Session Replay
      replaysSessionSampleRate: 0.5, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      debug: false,
    })
  }

  identify = (params: any) => {
    if (process.env.NODE_ENV === 'development') return null
    Sentry.setUser(params)
  }

  client = () => Sentry
}

export default new SentryTracking()
