import { DateTime } from 'luxon'

import { RECURRING_DAYS, RECURRING_FREQUENCIES, getScheduleRrule } from '../../utils/recurrence'

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

export const buildRecurrence = (startDate, endIn, service) => {
  if (!startDate || !endIn) return null
  if (!service || !service.is_recurring) return { recurringDates: null, recurringText: null }

  const frequencyValue = FREQUENCIES[service.recurring_frequency]
  const recurringOptions = {
    frequency: service.recurring_frequency,
    dueDay: service[frequencyValue] || null,
    startDate: startDate,
    count: endIn,
    countPeriod: 'months',
    endDate: null,
  }

  const fullRrule = getScheduleRrule(recurringOptions)
  const recurringDates = fullRrule.all().map((date) => DateTime.fromJSDate(date).toISO())
  const recurringText = fullRrule.toText()

  return { recurringDates, recurringText }
}

export const niceOccurrenceText = (isRecurring, frequency, dueDay) => {
  // One-time
  if (!isRecurring) return 'One-time'
  if (!frequency) return '–'

  // Daily
  if (frequency === 'daily') return RECURRING_FREQUENCIES.daily.label

  // Weekly, Biweekly, Monthly
  if (frequency === 'monthly') dueDay -= 1

  const label = RECURRING_FREQUENCIES[frequency].label
  const day = RECURRING_DAYS[frequency][dueDay]

  if (label && day) return `${RECURRING_FREQUENCIES[frequency].label} on ${RECURRING_DAYS[frequency][dueDay]}`
  else return ''
}
