import React from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'

import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'

import TemplateLegalTooltip from '../TemplateLegalTooltip'
import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'
import { AgreementInternalTemplatesDataTable } from './internal/AgreementInternalTemplatesDataTable'

const EmployeeAgreementTemplatesImportOverlay = () => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const tableProps = useDataTable({
    name: ['internal_employee_agreement_templates'],
    endpoint: '/internal_templates',
    params: {
      category: 'agreement',
      subcategory: 'staff',
      level: selectedType,
      status: 'active',
    },
    localStorageKey: 'internal_employee_agreement_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-agreement'],
    url: '/internal_templates/import',
    invalidate: 'agreement_templates',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'agreement',
        subcategory: 'employee',
      })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Staff Agreement Templates" icon="legal_agreement_alt" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <ImportTemplatesTabs onChange={setSelectedType} />

          <AgreementInternalTemplatesDataTable {...tableProps} canBatchSelect onRowSelectionUpdate={setSelectedRows} className="!mt-4" />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default EmployeeAgreementTemplatesImportOverlay
