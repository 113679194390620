import React from 'react'
import { darken } from 'polished'
import { DateTime } from 'luxon'
import { useRouteMatch } from 'react-router-dom'

import { COLORS } from '../../../theme'
import { useDataTable } from '../../DataTable/useDataTable'

import Card from '../../Card'
import Tabs from '../../Tabs'
import Tooltip from '../../Tooltip'
import TransactionStats from './TransactionStats'

import { AutoRepeatingChargesDataTable } from '../../../constructs/Financials/AutoRepeatingChargesDataTable'
import { ChargesDataTable } from '../../../constructs/Financials/ChargesDataTable'
import { CreditsDataTable } from '../../../constructs/Financials/CreditsDataTable'
import { PaymentsDataTable } from '../../../constructs/Financials/PaymentsDataTable'
import { RefundsDataTable } from '../../../constructs/Financials/RefundsDataTable'
import { TransactionsDataTable } from '../../../constructs/Financials/TransactionsDataTable'
import { WriteOffsDataTable } from '../../../constructs/Financials/WriteOffsDataTable'
import { setDuplicateColumn } from '../../DataTable/functions'

const Transactions = (props: any) => {
  const { hiddenColumns, isSelectable, name, showRepeatingCharges, showReportLinks, url, useStats, allowDuplicate } = props

  const match = useRouteMatch()

  const estTime = DateTime.local().setZone('America/New_York')
  const leftTime = estTime.set({ hour: 8, minute: 0 }).toLocal().toFormat('ha')
  const rightTime = estTime.set({ hour: 10, minute: 0 }).toLocal().toFormat('ha')
  const offsetName = DateTime.local().toFormat('ZZZZZ')

  return (
    <>
      {useStats && <TransactionStats showRepeatingCharges={showRepeatingCharges} name={name} url={url} />}

      <Card testKey="transaction_table" className="!mt-4">
        <Tabs defaultTab="all_transactions">
          <Tabs.List caps className="!pl-4">
            <Tabs.Item label="All Transactions" name="all_transactions" />

            <Tabs.Divider />

            <Tabs.Item label="Charges" name="charges" />
            <Tabs.Item label="Payments" name="payments" />
            <Tabs.Item label="Credits" name="credits" />
            <Tabs.Item label="Refunds" name="refunds" />
            <Tabs.Item label="Write Off's" name="write_offs" />

            {showRepeatingCharges && (
              <>
                <Tabs.Divider />
                <Tabs.Item label="Auto-Repeating Charges" name="auto_repeating_charges" />
              </>
            )}

            <Tooltip
              color={darken(0.05, COLORS.orange)}
              glyph="question"
              position="top"
              content={
                <span>
                  <i>Future Planned Charges</i> and <i>Auto-Repeating Charges</i> get processed daily between <b>{leftTime}</b> and{' '}
                  <b>{rightTime}</b>, {offsetName}
                </span>
              }
              css={{ marginLeft: 'auto', marginRight: '0.85rem' }}
            />
          </Tabs.List>

          <Tabs.Panels>
            <Tabs.Panel name="all_transactions">
              <AllTransactions
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id, category }: any) => ({
                  pathname: `${match?.url}/${category}/${id}`,
                  parent: match,
                })}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: (record: any) => {
                      if (record.category === 'charge') {
                        return `${match.url}/charges/new`
                      }

                      if (record.category === 'payment') {
                        return `${match.url}/payments/new`
                      }

                      if (record.category === 'credit') {
                        return `${match.url}/credits/new`
                      }

                      if (record.category === 'refund') {
                        return `${match.url}/refunds/new`
                      }

                      if (record.category === 'write_off') {
                        return `${match.url}/write-offs/new`
                      }

                      return null
                    },
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="charges">
              <Charges
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/charges/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/charges' }] : undefined}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: `${match.url}/charges/new`,
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="payments">
              <Payments
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/payments/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/payments' }] : undefined}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: `${match.url}/payments/new`,
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="credits">
              <Credits
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/credits/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/credits' }] : undefined}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: `${match.url}/credits/new`,
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="refunds">
              <Refunds
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/refunds/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/refunds' }] : undefined}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: `${match.url}/refunds/new`,
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="write_offs">
              <WriteOffs
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/write-offs/${id}`,
                  parent: match,
                })}
                headerLinksConfig={showReportLinks ? [{ type: 'report', to: '/reports/write-offs' }] : undefined}
                duplicateColumn={
                  allowDuplicate &&
                  setDuplicateColumn({
                    link: `${match.url}/write-offs/new`,
                    parent: match,
                    permission: 'ledger.create',
                  })
                }
              />
            </Tabs.Panel>

            <Tabs.Panel name="auto_repeating_charges">
              <AutoRepeatingCharges
                name={name}
                url={url}
                isSelectable={isSelectable}
                hiddenColumnIds={hiddenColumns}
                to={({ id }: any) => ({
                  pathname: `${match?.url}/auto-repeating-charges/${id}`,
                  parent: match,
                })}
              />
            </Tabs.Panel>
          </Tabs.Panels>
        </Tabs>
      </Card>
    </>
  )
}

const AllTransactions = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'charge,payment,credit,refund,write_off' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_all_v1`,
  })

  return <TransactionsDataTable {...tableProps} {...rest} />
}

const Charges = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'charge' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_charge_v1`,
  })

  return <ChargesDataTable {...tableProps} {...rest} />
}

const Payments = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'payment' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_payment_v1`,
  })

  return <PaymentsDataTable {...tableProps} {...rest} />
}

const Credits = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'credit' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_credit_v1`,
  })

  return <CreditsDataTable {...tableProps} {...rest} />
}

const Refunds = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'refund' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_refund_v1`,
  })

  return <RefundsDataTable {...tableProps} {...rest} />
}

const WriteOffs = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'write_off' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_write_off_v1`,
  })

  return <WriteOffsDataTable {...tableProps} {...rest} />
}

const AutoRepeatingCharges = ({ name, url, ...rest }: any) => {
  const tableProps = useDataTable({
    name,
    endpoint: url,
    params: { category: 'recurring' },
    staleTime: 0,
    keepPreviousData: false,
    localStorageKey: `financial_transactions_recurring_v1`,
  })

  return <AutoRepeatingChargesDataTable {...tableProps} {...rest} />
}

Transactions.defaultProps = {
  name: 'financial_transactions',
  useStats: true,
  isSelectable: true,
  showRepeatingCharges: true,
}

export default Transactions
