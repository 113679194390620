import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Page, Grid, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { TreatmentEpisodesDataTable } from '@behavehealth/constructs/TreatmentEpisodes/TreatmentEpisodesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const TreatmentEpisodes = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'treatment-episodes'],
    endpoint: `/residents/${resource_id}/admissions`,
    enabled: !!resource_id,
    localStorageKey: 'client_treatment_episodes_v1',
  })

  return (
    <Page
      feature="treatment_episodes"
      plan="lite"
      emptyDescription="There are no Treatment Episodes yet"
      help={<HelpTagIframe id="treatment_episodes" />}
      actions={
        <Button label="Add Treatment Episode" link={`${match.url}/new`} glyph="add" type="primary" permission="treatment_episodes.create" />
      }
    >
      <Grid>
        <TreatmentEpisodesDataTable
          to={(row: any) => `${match.url}/${row.id}`}
          {...tableProps}
          duplicateColumn={setDuplicateColumn({
            link: `${match.url}/new`,
            parent: match,
            permission: 'treatment_episodes.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(TreatmentEpisodes)
