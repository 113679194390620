import React from 'react'
import { useNavigate } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'

import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'
import TemplateLegalTooltip from '../TemplateLegalTooltip'

import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'
import { CustomNoteInternalTemplatesDataTable } from './internal/CustomNoteInternalTemplatesDataTable'

const OrganizationCustomNoteTemplatesImportOverlay = () => {
  const navigate = useNavigate()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const tableProps = useDataTable({
    name: ['internal_organization_custom_note_templates'],
    endpoint: '/internal_templates',
    params: {
      category: 'custom_note',
      subcategory: 'organization',
      level: selectedType,
      status: 'active',
    },
    localStorageKey: 'internal_organization_custom_note_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-custom-note'],
    url: '/internal_templates/import',
    invalidate: 'custom_note_templates',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'custom_note',
        subcategory: 'organization',
      })

      navigate(-1)
    } catch (errors) {
      console.debug(errors)
    }
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={64}>
      <Overlay.Header title="Import Organization Custom Note Templates" icon="custom_notes" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section margin="0 0 1.5rem 0">
          <ImportTemplatesTabs onChange={setSelectedType} />

          <CustomNoteInternalTemplatesDataTable {...tableProps} canBatchSelect onRowSelectionUpdate={setSelectedRows} className="!mt-4" />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default OrganizationCustomNoteTemplatesImportOverlay
