import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'
import clsx from 'clsx'
import size from 'lodash/size'

import { countWord } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { AccessListDataTable } from '@behavehealth/constructs/AccessList/AccessListDataTable'
import { Alert, Button, Form, Grid, HelpTagIframe, Page, Radio, RadioGroup } from '@behavehealth/components'
import { StaffAccessListAuditDataTable } from '@behavehealth/constructs/AccessList/StaffAccessListAuditDataTable'
import { AccessListAuditFixOverlay } from '@behavehealth/constructs/AccessList/AccessListAuditFixOverlay'

import Glyph from '@behavehealth/components/Glyph'
import Status from '@behavehealth/components/Status'
import SummonOverlay from '@behavehealth/components/SummonOverlay'
import Tabs from '@behavehealth/components/Tabs'

const pageConfig = {
  feature: 'staff_clients_access',
  title: 'Staff Access',
  help: <HelpTagIframe id="staff_access_lists" />,
}

const HOUSES = ['Lakewood House', 'Meadowridge House', 'Oregon House', 'Star Rock House', 'Westridge House', 'White House']

const PROGRAMS = ['Mental Health Wellness', 'Physical Wellness', 'Yoga', 'Art Therapy', 'Music Therapy']

const AccessList: React.FC = () => {
  const { resource_id }: any = useParams()
  const { url }: any = useRouteMatch()

  const form = React.useRef()
  const { isBehave }: any = useSettings()

  const { data: employee, isLoading: isLoadingEmployee }: any = useGet({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', resource_id],
    url: `/employees/${resource_id}`,
    invalidate: ['employees'],
  })

  const tableProps = useDataTable({
    name: ['employee', resource_id, 'access_controls'],
    endpoint: `/employees/${resource_id}/access_controls`,
    localStorageKey: 'employee_access_controls_v2',
  })

  const auditTableProps = useDataTable({
    name: ['clients'],
    endpoint: `/residents`,
    params: { status: 'current' },
    // localStorageKey: 'employee_access_controls_audit_v1',
    keepPreviousData: false,
  })

  if (!employee) return <Page isLoading {...pageConfig} />

  const hasFullAccess = employee.access_level === 'full'
  const isOwner = employee.position === 'owner'

  const auditData = auditTableProps.data
    ?.map?.((o) => {
      const in_access_list = Math.random() > 0.5
      const in_staff_programs_or_locations = Math.random() > 0.5

      return {
        ...o,
        in_access_list,
        in_staff_programs_or_locations,
        programs: in_staff_programs_or_locations
          ? PROGRAMS.filter(() => Math.random() > 0.5).map((name) => ({ name, avatar: '', id: Math.random() }))
          : [],
        house: in_staff_programs_or_locations
          ? {
              name: HOUSES[Math.floor(Math.random() * HOUSES.length)],
              id: Math.random(),
              avatar: '',
            }
          : null,
      }
    })
    .filter((o) => o.in_access_list || o.in_staff_programs_or_locations)

  return (
    <Page
      actions={
        !hasFullAccess && (
          <Button
            label="Bulk Assign Clients"
            type="primary"
            glyph="add"
            link={`${url}/import`}
            permission="access_controls.actions.import"
          />
        )
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        {isOwner && <Alert glyph="info">Owners have full access to all clients by default. This setting cannot be changed.</Alert>}

        {!isOwner && (
          <>
            <Form getForm={form} initialModel={{ access_level: employee.access_level }}>
              <RadioGroup
                label="Client Access Level"
                layout="vertical-dense"
                model="access_level"
                isDisabled={isLoadingEmployee}
                onChange={async ({ value }: any) => await updateEmployee({ access_level: value })}
              >
                <Radio label="Full access to All Clients" value="full" />
                <Radio label="Restricted access to selected Clients only" value="restricted" />
              </RadioGroup>
            </Form>

            {!hasFullAccess && (
              <div className="grid grid-cols-[100%]">
                <Tabs defaultTab="clients_access_list">
                  <Tabs.List className={clsx(isBehave ? '-mt-2 mb-4' : '!hidden')}>
                    <Tabs.Item label="Clients Access List" name="clients_access_list" />
                    <Tabs.Item
                      label={
                        <div className="flex flex-nowrap items-center">
                          <span className="mr-1.5">Audit Report</span>

                          <Status
                            small
                            label={
                              <span className="flex items-center flex-nowrap">
                                <Glyph glyph="circle_error" className="!fill-[red] mr-1.5" size={14} />
                                {countWord('Issues', size(auditData))}
                              </span>
                            }
                            color="red"
                          />
                        </div>
                      }
                      name="audit_report"
                    />
                  </Tabs.List>

                  <Tabs.Panel name="clients_access_list">
                    <div className="grid gap-4">
                      <Alert contrast glyph="warning" type="warning">
                        <b>Please note:</b> when restricting access to Clients, Staff members who have permissions for features such as Live
                        Reports, Generated Reports, Programs, Bed Management and more will still be able to view all clients. To restrict
                        access to these features, please update the Staff member's permissions.
                      </Alert>

                      <AccessListDataTable {...tableProps} to={(row: any) => `/clients/${row?.resource?.id}`} />
                    </div>
                  </Tabs.Panel>

                  <Tabs.Panel name="audit_report">
                    <div className="grid gap-4">
                      <Alert contrast glyph="circle_error" type="negative">
                        <div className="flex flex-nowrap items-center">
                          <div className="grow">
                            We found {countWord('issue', size(auditData))} with the current Staff Access List. Please review the audit
                            report below and follow the recommendations to update the Staff Access List.
                          </div>

                          <SummonOverlay overlay={<AccessListAuditFixOverlay employee={employee} auditData={auditData} />}>
                            <Button label="Fix All Issues…" glyph="tick_circle" type="primary" color="green" size={200} />
                          </SummonOverlay>
                        </div>
                      </Alert>

                      <StaffAccessListAuditDataTable {...auditTableProps} data={auditData} meta={{ count: size(auditData) }} />
                    </div>
                  </Tabs.Panel>
                </Tabs>
              </div>
            )}
          </>
        )}
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(AccessList, pageConfig))
