import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { AGREEMENTS } from '../../../../utils/constants'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import Status from '../../../Status'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
  sales_template: 'Sales',
}

export const AgreementInternalTemplatesDataTable = (props: any) => {
  const { to, onClick, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'data.name',
        id: 'name',
        width: 240,
        disableHide: true,
        formatValue: ({ data, value }: any) => (
          <MainCell
            id={data.id}
            as={NavLink}
            to={to ? to(data) : undefined}
            onClick={onClick ? () => onClick(data) : undefined}
            value={value}
          />
        ),
      },
      {
        title: 'Status',
        model: 'status',
        width: 160,
        formatValue: ({ value }: any) => STATUSES?.[value] || '–',
      },
      {
        title: 'Tags',
        model: 'global_tags',
        type: 'global_tags',
        editPermission: false,
      },
      {
        title: 'Smart Flags',
        model: 'smart_flags',
        type: 'smart_flags',
        editPermission: false,
      },
      {
        title: 'Access',
        model: 'level',
        formatValue: ({ value }: any) => LEVELS?.[value] || '–',
      },
      {
        title: 'Category',
        model: 'data.category',
        formatValue: ({ value }: any) => AGREEMENTS?.[value] || '–',
      },
      {
        title: 'Signature',
        model: 'data.should_sign',
        formatValue: ({ value }: any) => {
          if (value === true) return <Status label="Required" color="green" />
          return <Status label="Not Required" color="gray" />
        },
      },
    ],
    [to, onClick],
  )

  return (
    <DataTable
      asCard
      title="Agreement Templates"
      icon="legal_agreement_alt"
      columns={columns}
      emptyDescription="No agreement templates have been added yet"
      {...rest}
    />
  )
}
