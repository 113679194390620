import { desaturate, opacify, tint, transparentize } from 'polished'

import { FONT_FAMILY } from './fonts'
import { SHADOW } from './shadow'
import COLORS from './colors'

export const GLOBAL_STYLES = {
  html: {
    '--field-background-disabled': tint(0.85, COLORS.gray),
    '--field-background-error': tint(0.85, COLORS.red),
    '--field-background-hover': transparentize(0.95, COLORS.gray),
    '--field-label-width': '120px',
    '--field-max-width': '520px',

    '--input-border-color-focus': COLORS.blue,
    '--input-border-color-hover': opacify(0.1, COLORS.divider),
    '--input-border-color': opacify(0.08, COLORS.divider),
    '--input-border-radius': '5px',
    '--input-min-height': '40px',
    '--input-font-size': '1rem',

    '--input-prefix-background': COLORS.lightBackground,
    '--input-prefix-border-color': COLORS.divider,
  },

  'html, body': {
    fontFamily: FONT_FAMILY,
    fontSize: 14,
    lineHeight: 1.4,
    color: COLORS.text,
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
    background: COLORS.lightBackground,
    wordBreak: 'normal',

    '@media (min-width: 600px)': {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
  },

  '[type="button"]': {
    WebkitAppearance: 'none',
  },

  'svg:not([fill])': {
    fill: 'auto',
  },

  '*, *:before, *:after': {
    boxSizing: 'inherit',
  },

  '#app': {
    '@media (min-width: 600px)': {
      width: '100%',
      height: '100%',
      overflow: 'hidden',
    },
  },

  'ul, ol': {
    margin: 0,
  },

  a: {
    color: COLORS.blue,
    textDecoration: 'none',

    '&:hover': {
      cursor: 'pointer',
    },

    '&:focus': {
      outline: 'none',
    },
  },

  button: {
    fontFamily: FONT_FAMILY,
  },

  'h1, h2, h3, h4, h5, h6': {
    margin: '0',
  },

  'input, textarea, select': {
    fontFamily: FONT_FAMILY,
    fontSize: 15,
    lineHeight: 1.15,
  },

  'audio, canvas, iframe, img, svg, video': {
    verticalAlign: 'inherit',
  },

  '.is-hidden': {
    display: 'none !important',
  },

  '.portals': {
    top: 0,
    left: 0,
    zIndex: 100,
    position: 'absolute',

    width: '100%',
    height: '100%',

    pointerEvents: 'none',

    '& > *': {
      position: 'relative',
    },
  },

  '#portal-sheet': {
    zIndex: 80,
    pointerEvents: 'all',
  },

  '#portal-dropdown': {
    zIndex: 80,
    position: 'static',
    pointerEvents: 'all',

    '> *': {
      zIndex: 101,
    },
  },

  '#portal-z80': {
    zIndex: 80,
    pointerEvents: 'all',
  },

  '#portal-z90': {
    zIndex: 90,
    pointerEvents: 'all',
  },

  '#portal-z100': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-z120': {
    zIndex: 120,
    pointerEvents: 'all',
  },

  '#portal-default': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-overlay': {
    zIndex: 100,
    pointerEvents: 'all',
  },

  '#portal-selector': {
    zIndex: 101,
    pointerEvents: 'all',
  },

  '#portal-dialog': {
    zIndex: 101,
    pointerEvents: 'all',
  },

  '#portal-iframe': {
    zIndex: 110,
    pointerEvents: 'all',
  },

  '#portal-tooltip': {
    zIndex: 110,
    pointerEvents: 'all',
  },

  '#portal-avatar': {
    zIndex: 120,
    pointerEvents: 'none',
  },

  '#portal-telehealth': {
    zIndex: 130,
    pointerEvents: 'none',
  },

  '#portal-radix': {
    zIndex: 9000,
    pointerEvents: 'all',
  },
}

export const INPUT_FOCUS_STYLES = {
  borderColor: 'var(--input-border-color-focus)',
  boxShadow: `
    ${SHADOW(5, transparentize(0.95, COLORS.blue))},
    0 0 0 2px ${transparentize(0.75, COLORS.blue)}
  `,
}

export const INPUT_STYLES = {
  width: '100%',
  minHeight: 'var(--input-min-height)',
  padding: '0.5em 0.75em',
  overflow: 'hidden',
  fontSize: 'var(--input-font-size)',

  outline: 0,
  borderRadius: 'var(--input-border-radius)',
  background: 'white',
  boxShadow: SHADOW(1),
  border: '1px solid var(--input-border-color)',

  transition: `
    border-color 160ms ease,
    box-shadow 160ms ease
  `,

  '&:hover': {
    boxShadow: SHADOW(5),
    borderColor: 'var(--input-border-color-hover)',
  },

  '&:focus': {
    ...INPUT_FOCUS_STYLES,
  },

  '&::placeholder': {
    fontStyle: 'italic',
    color: COLORS.textMuted,
  },
}

export const RICH_TEXT_STYLES = {
  '&.is-full-screen': {
    height: '100%',
    display: 'grid',
    gridTemplateRows: 'min-content auto 1fr',

    '.EditorContent, .ProseMirror': {
      height: '100%',
    },
  },

  '&.is-text-selection-disabled': {
    userSelect: 'none !important',
  },

  '.ProseMirror': {
    lineHeight: 1.44,
    outline: 'none !important',

    '> * + *': {
      marginTop: '0.75rem',
    },

    p: {
      'br + br': {
        display: 'none',
      },
    },

    'li > p': {
      margin: 0,
    },

    h1: {
      fontSize: '1.7rem',
      margin: '0.6em 0 0.4em',
    },

    h2: {
      fontSize: '1.5rem',
      margin: '0.8em 0 0.45em',
    },

    h3: {
      fontSize: '1.35rem',
      margin: '1em 0 0.5em',
    },

    h4: {
      fontSize: '1.2rem',
      margin: '1.2em 0 0.6em',
    },

    img: {
      maxWidth: '100%',
      height: 'auto',
    },

    blockquote: {
      marginLeft: '1rem',
      paddingLeft: '0.8em',
      color: COLORS.text,
      borderLeft: `2px solid ${COLORS.textMuted}`,
      fontStyle: 'italic',
    },

    hr: {
      margin: '1.75rem 0',
      border: 'none',
      borderBottom: `1px solid ${COLORS.divider}`,
    },

    '& > *:first-child': {
      marginTop: '0 !important',
    },

    '& > *:last-child': {
      marginBottom: '0 !important',
    },

    '.tableWrapper': {
      overflowX: 'auto',
    },

    table: {
      borderSpacing: 0,
      borderCollapse: 'collapse',
      fontVariant: 'tabular-nums',
      fontFeatureSettings: 'tnum',
      border: `1px solid ${COLORS.text}`,
      minWidth: 200,
      margin: 0,
    },

    'td, th': {
      padding: '0.5rem',
      border: `1px solid ${COLORS.text}`,

      '& > *': {
        textAlign: 'left',
      },

      '&.selectedCell': {
        background: transparentize(0.85, COLORS.blue),
      },

      p: {
        margin: 0,
      },
    },

    th: {
      background: desaturate(0.1, tint(0.82, COLORS.vividBlue)),
    },

    'ul[data-type="taskList"]': {
      listStyle: 'none',
      padding: 0,

      p: {
        margin: 0,
      },

      li: {
        display: 'flex',
        alignItems: 'center',

        '& > label': {
          flex: '0 0 auto',
          marginRight: '0.5rem',
          userSelect: 'none',
        },

        '& > div': {
          flex: '1 1 auto',
        },
      },

      'input[type="checkbox"]': {
        cursor: 'pointer',
      },
    },
  },

  '.is-readonly .ProseMirror': {
    'ul[data-type="taskList"]': {
      'li > label': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none !important',
      },

      'input[type="checkbox"]': {
        display: 'none',

        '& + span': {
          display: 'flex',
        },

        '& + span::after': {
          content: '""',
          width: 18,
          height: 18,
          display: 'inline-block',
          backgroundRepeat: 'none',
          backgroundPosition: 'center',
          backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTMuNzMzNDUgMi4xMzI5NkMzLjU5Njk4IDIuMTMyOTYgMy40NjAzNyAyLjE4NDk1IDMuMzU2MzcgMi4yODkyMUwyLjI4OTcgMy4zNTU4OEMyLjA4MTE3IDMuNTY0NDEgMi4wODExNyAzLjkwMjA1IDIuMjg5NyA0LjExMDA1TDYuMTc5MjggNy45OTk2M0wyLjI4OTcgMTEuODg5MkMyLjA4MTE3IDEyLjA5NzcgMi4wODExNyAxMi40MzU0IDIuMjg5NyAxMi42NDM0TDMuMzU2MzcgMTMuNzFDMy41NjQ5IDEzLjkxODYgMy45MDI1MyAxMy45MTg2IDQuMTEwNTMgMTMuNzFMOC4wMDAxMiA5LjgyMDQ2TDExLjg4OTcgMTMuNzFDMTIuMDk3NyAxMy45MTg2IDEyLjQzNTkgMTMuOTE4NiAxMi42NDM5IDEzLjcxTDEzLjcxMDUgMTIuNjQzNEMxMy45MTkxIDEyLjQzNDggMTMuOTE5MSAxMi4wOTcyIDEzLjcxMDUgMTEuODg5Mkw5LjgyMDk1IDcuOTk5NjNMMTMuNzEwNSA0LjExMDA1QzEzLjkxOTEgMy45MDIwNSAxMy45MTkxIDMuNTYzODggMTMuNzEwNSAzLjM1NTg4TDEyLjY0MzkgMi4yODkyMUMxMi40MzUzIDIuMDgwNjggMTIuMDk3NyAyLjA4MDY4IDExLjg4OTcgMi4yODkyMUw4LjAwMDEyIDYuMTc4OEw0LjExMDUzIDIuMjg5MjFDNC4wMDYyNyAyLjE4NDk1IDMuODY5OTIgMi4xMzI5NiAzLjczMzQ1IDIuMTMyOTZaIiBmaWxsPSIjQ0NEMERCIi8+Cjwvc3ZnPgo=")`,
        },

        '&:checked + span::after': {
          backgroundImage: `url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEzLjg4NzEgMi45MTEwOUwxMi43ODM4IDIuMTYxMDlDMTIuNDc4NCAxLjk1NDM2IDEyLjA2MDIgMi4wMzM2OSAxMS44NTU5IDIuMzM2NTdMNi40NDcxNyAxMC4zMTI1TDMuOTYxNjEgNy44MjY5NEMzLjcwMiA3LjU2NzMyIDMuMjc4OTMgNy41NjczMiAzLjAxOTMxIDcuODI2OTRMMi4wNzQ1OSA4Ljc3MTY5QzEuODE0OTggOS4wMzEyNiAxLjgxNDk4IDkuNDU0MzYgMi4wNzQ1OSA5LjcxNjM4TDUuODk2NzIgMTMuNTM4NUM2LjExMDY0IDEzLjc1MjQgNi40NDcxNyAxMy45MTU5IDYuNzUwMDUgMTMuOTE1OUM3LjA1Mjk4IDEzLjkxNTkgNy4zNTgyNiAxMy43MjYgNy41NTUzOCAxMy40NEwxNC4wNjUgMy44MzY1N0MxNC4yNzE3IDMuNTMzNjkgMTQuMTkyNCAzLjExNzgyIDEzLjg4NzEgMi45MTEwOVoiIGZpbGw9IiMwRjk5MTMiLz4KPC9zdmc+Cg==")`,
        },
      },
    },
  },
}
