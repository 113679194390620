import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { Button, Page, Grid } from '@behavehealth/components'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { BedOccupanciesDataTable } from '@behavehealth/constructs/BedManagement/BedOccupanciesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const Occupancies = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'occupancies'],
    endpoint: `/residents/${resource_id}/occupancies`,
    enabled: !!resource_id,
    params: { category: 'bed_management' },
    localStorageKey: 'client_bed_occupancies_v1',
  })

  return (
    <Page
      feature="occupancies"
      actions={
        <Button
          label="Add Bed Assignment"
          link={{
            pathname: `${match.url}/new`,
            data: { reference: client },
          }}
          glyph="add"
          type="primary"
          permission="bed_management.create"
        />
      }
    >
      <Grid>
        <BedOccupanciesDataTable
          {...tableProps}
          to={(row: any) => `${match.url}/${row.id}`}
          duplicateColumn={setDuplicateColumn({
            link: `${match.url}/new`,
            parent: match,
            permission: 'bed_management.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(Occupancies)
