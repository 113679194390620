import React from 'react'

import { COLORS } from '../../theme'
import { isDefined } from '../../utils/functions'
import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'
import { useUpdate } from '../../hooks/useNewAPI'

import { DataTable } from '../../components/DataTable/DataTable'
import { DropdownMenu, DropdownMenuItem } from '../../components/DropdownMenu'
import { EmptyCell } from '../../components/DataTable/cells/EmptyCell'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'

import AdmissionQuickView from '../../components/Overlays/quick/AdmissionQuickView'
import Button from '../../components/Button'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import ClientProfileHeader from '../../components/ClientProfileHeader'
import Form from '../../components/Forms/Form'
import Glyph from '../../components/Glyph'
import Input from '../../components/Forms/Input'
import IntakeStatus from '../../components/Statuses/IntakeStatus'
import Loader from '../../components/Loader'
import MiniRichTextEditor from '../../components/Forms/MiniRichTextEditor'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Textarea from '../../components/Forms/Textarea'

import { FILTERS } from '../Filters/config'
import { HighRiskAlertStatus } from '../HighRiskAlert/HighRiskAlertStatus'
import { ClientTrackSelector } from '../ClientTracks/ClientTrackSelector'

export const IntakesDataTable = () => {
  const { tenant } = useSettings()

  const tableProps = useDataTable({
    name: 'clients',
    endpoint: '/residents',
    params: { status: 'intake' },
    localStorageKey: 'intakes_v2',
  })

  const { allowed: canEditTreatmentEpisodes } = usePermissions('treatment_episodes.edit')

  const showERXFeatures = tenant?.feature_flags?.erx === true

  const [client, setClient]: any = React.useState(null)
  const [notesData, setNotesData]: any = React.useState(null)
  const [isNotesOverlayOpen, setIsNotesOverlayOpen] = React.useState(false)

  const [priorityData, setPriorityData]: any = React.useState(null)
  const [referralNotesData, setReferralNotesData]: any = React.useState(null)
  const [referralOutNotesData, setReferralOutNotesData]: any = React.useState(null)

  const [isPriorityOverlayOpen, setIsPriorityOverlayOpen] = React.useState(false)
  const [isReferralNotesOverlayOpen, setIsReferralNotesOverlayOpen] = React.useState(false)
  const [isReferralOutNotesOverlayOpen, setIsReferralOutNotesOverlayOpen] = React.useState(false)

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            to={`/admissions/${data.id}`}
            quickViewOverlay={<AdmissionQuickView client={data} />}
          />
        ),
      },
      {
        title: 'High Risk Alert',
        model: 'high_risk_title',
        width: 300,
        formatValue: ({ data }: any) => {
          if (!data.high_risk_title) return null

          return <HighRiskAlertStatus title={data.high_risk_title} notes={data.high_risk_notes} />
        },
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'accepted',
      },
      {
        title: 'Client Track',
        model: 'client_track',
        featureFlag: 'client_tracks',
        formatValue: ({ value, data }) => <ClientTrackSelector clientId={data.id} activeTrack={value} buttonLabel={null} />,
      },
      {
        title: 'Priority Number',
        model: 'priority',
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <PriorityCell
            value={value}
            onEdit={() => {
              setClient(data)
              setPriorityData(value)
              setIsPriorityOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      {
        title: 'Location',
        id: 'house',
        model: 'current_bed_occupancy.place.house',
        type: 'profile',
      },
      {
        title: 'Room',
        id: 'room',
        model: 'current_bed_occupancy.place.room',
        type: 'profile',
      },
      {
        title: 'Bed',
        id: 'bed',
        model: 'current_bed_occupancy.place.name',
        type: 'profile',
      },
      {
        title: 'Last Application Date',
        id: 'last_application_date',
        model: 'current_intake_application.created_at',
        type: 'date_time',
      },
      {
        title: 'When would you like to move in',
        id: 'when_would_you_like_to_move_in',
        model: 'current_intake_application.data.admissions.target_move_in_date',
        type: 'date',
      },
      {
        title: 'Est. Arrival Date',
        id: 'admission_estimated_arrival',
        model: 'current_admission.estimated_arrival',
        type: 'date_time',
      },
      {
        title: 'Treatment Episode Admission Status',
        id: 'admission_status',
        model: 'current_admission_status',
        disableSort: true,
        formatValue: ({ value, data }: any) => {
          if (!value) return null

          const admissionId = data?.current_admission_id

          if (!!admissionId && canEditTreatmentEpisodes) {
            return <AdmissionStatusSelect value={value} admissionId={admissionId} isRefetching={tableProps.isRefetching} />
          }

          return <IntakeStatus status={value} />
        },
      },
      {
        title: 'Admission Notes',
        model: 'current_admission_notes',
        disableSort: true,
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <AdmissionNotesCell
            value={value}
            client={data}
            onEdit={() => {
              setClient(data)
              setNotesData(value)
              setIsNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Cell Phone',
        type: 'phone',
        model: 'phone_no',
        canEdit: true,
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
        canEdit: true,
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Synced with eRX',
        model: 'erx_status',
        permission: showERXFeatures,
        formatValue: ({ data }: any) => {
          const status = data.erx_status

          if (!status) return null

          if (status === 'synced') return 'Yes'
          else if (status === 'not_synced') return 'No'
        },
      },
      {
        title: 'Referral In',
        id: 'referral_source',
        model: 'referral_source',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral In Notes',
        model: 'referral_notes',
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <ReferralNotesCell
            value={value}
            client={data}
            testId="referral_in_notes_cell"
            onEdit={() => {
              setClient(data)
              setReferralNotesData(value)
              setIsReferralNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Referral Out',
        id: 'referral_out',
        model: 'referral_out',
        type: 'profile',
        disableSort: true,
      },
      {
        title: 'Referral Out Notes',
        model: 'referral_out_notes',
        hoverExpand: true,
        formatValue: ({ value, data }) => (
          <ReferralNotesCell
            value={value}
            client={data}
            testId="referral_out_notes_cell"
            onEdit={() => {
              setClient(data)
              setReferralOutNotesData(value)
              setIsReferralOutNotesOverlayOpen(true)
            }}
          />
        ),
      },
      {
        title: 'Preferred Name',
        model: 'preferred_name',
        width: 180,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
        canEdit: true,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [showERXFeatures, canEditTreatmentEpisodes, tableProps.isRefetching],
  )

  return (
    <>
      <div className="grid grid-cols-[100%]">
        <DataTable
          {...tableProps}
          testKey="accepted_applicants_data_table"
          title="Accepted Applicants"
          icon="admissions"
          columns={columns}
          canSelect={false}
          filtersConfig={FILTERS.intakes}
          onRowSelect={() => {}}
          onDataInitialized={() => {}}
          updateKey="client"
          updateDeleteEndpoint="/residents"
          invalidate={['clients', { status: 'intakes' }]}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/accepted',
            },
          ]}
        />
      </div>

      <SummonOverlay
        isOpen={isPriorityOverlayOpen}
        onClose={() => {
          setClient(null)
          setPriorityData(null)
          setIsPriorityOverlayOpen(false)
        }}
        overlay={<PriorityOverlay value={priorityData} client={client} />}
      />

      <SummonOverlay
        isOpen={isNotesOverlayOpen}
        onClose={() => {
          setClient(null)
          setNotesData(null)
          setIsNotesOverlayOpen(false)
        }}
        overlay={<AdmissionNotesOverlay value={notesData} client={client} />}
      />

      <SummonOverlay
        isOpen={isReferralNotesOverlayOpen}
        onClose={() => {
          setClient(null)
          setReferralNotesData(null)
          setIsReferralNotesOverlayOpen(false)
        }}
        overlay={<ReferralNotesOverlay value={referralNotesData} client={client} />}
      />

      <SummonOverlay
        isOpen={isReferralOutNotesOverlayOpen}
        onClose={() => {
          setClient(null)
          setReferralOutNotesData(null)
          setIsReferralOutNotesOverlayOpen(false)
        }}
        overlay={<ReferralOutNotesOverlay value={referralOutNotesData} client={client} />}
      />
    </>
  )
}

const ADMISSION_STATUSES = [
  { label: 'Pending', value: 'pending' },
  { label: 'Incoming', value: 'incoming' },
  { label: 'Waitlisted', value: 'waitlist' },
  { label: 'Planned', value: 'planned' },
  { label: 'No Show', value: 'no_show' },
  { label: 'Declined', value: 'declined' },
  { label: 'Admitted', value: 'admitted' },
  { label: 'Discharged', value: 'discharged' },
  { label: 'Closed', value: 'closed' },
]

const AdmissionStatusSelect = ({ admissionId, value, isRefetching }: any) => {
  const [wasLoading, setWasLoading] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const { mutateAsync: updateAdmission }: any = useUpdate({
    name: ['treatment-episodes', admissionId],
    url: `/admissions/${admissionId}`,
    invalidateKeys: ['clients', 'treatment-episodes'],
  })

  const handleUpdate = async (status: any) => {
    setIsLoading(true)

    try {
      await updateAdmission({ status })
    } catch (error) {
      console.log(error)
    } finally {
      setWasLoading(true)
    }
  }

  React.useEffect(() => {
    if (wasLoading) {
      setIsLoading(isRefetching)
      setWasLoading(isRefetching)
    }
  }, [wasLoading, isRefetching])

  if (isLoading) return <Loader size={18} />

  return (
    <DropdownMenu
      trigger={
        <div className="cursor-pointer">
          <IntakeStatus status={value} after={<Glyph glyph="triangle_down" size={10} color={COLORS.text} className="-ml-1 mr-1.5" />} />
        </div>
      }
    >
      {ADMISSION_STATUSES.map((status) => (
        <DropdownMenuItem
          key={status.value}
          label={status.label}
          onClick={() => handleUpdate(status.value)}
          isActive={value === status.value}
        />
      ))}
    </DropdownMenu>
  )
}

const PriorityCell = ({ value, onEdit }: any) => {
  return (
    <div className={editCellClasses}>
      <div className={editValueClasses}>{value || <EmptyCell />}</div>

      <Button
        hideLabel
        testKey="edit_priority_button"
        glyph="edit"
        size={100}
        type="minimal"
        className={editButtonClasses}
        onClick={onEdit}
      />
    </div>
  )
}

const PriorityOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['clients', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose} maxWidth={32}>
      <Overlay.Header glyph="edit" title="Edit Priority Number" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ priority: value || '' }} className="flex justify-center">
            <Input withHover={false} type="number" label="Priority Number" model="priority" size={6} />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const AdmissionNotesCell = ({ client, value, onEdit }: any) => {
  if (!client) return null

  return (
    <div className={editCellClasses}>
      <div className={editValueClasses}>{value || <EmptyCell />}</div>

      <Button
        hideLabel
        testKey="edit_admission_notes_button"
        glyph="edit"
        size={100}
        type="minimal"
        className={editButtonClasses}
        permission="treatment_episodes.edit"
        onClick={onEdit}
      />
    </div>
  )
}

const AdmissionNotesOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['admissions', client?.current_admission_id],
    url: `/admissions/${client.current_admission_id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Admission Notes" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ notes: value || '' }}>
            <Textarea label="Admission Notes" model="notes" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const formatRichTextValue = (value: any) => {
  if (!isDefined(value)) return <EmptyCell />

  return value.replace(/<[^>]*>/g, ' ')
}

const ReferralNotesCell = ({ client, value, onEdit, testId }: any) => {
  if (!client) return null

  return (
    <div className={editCellClasses}>
      <div className={editValueClasses}>{formatRichTextValue(value)}</div>

      <Button
        hideLabel
        testKey={`edit_${testId}`}
        glyph="edit"
        size={100}
        type="minimal"
        className={editButtonClasses}
        permission="clients.general_info.referral_details.edit"
        onClick={onEdit}
      />
    </div>
  )
}

const ReferralNotesOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['clients', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Referral In Notes" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ referral_notes: value || '' }}>
            <MiniRichTextEditor label="Referral In Notes" model="referral_notes" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const ReferralOutNotesOverlay = ({ value, onClose, client }: any) => {
  const form = React.useRef()

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['clients', client?.id],
    url: `/residents/${client?.id}`,
    invalidate: ['clients'],
  })

  const handleSave = async () => {
    const formData = form.current.getFormValue()

    await mutateAsync(formData)

    if (onClose) onClose()
  }

  return (
    <Overlay showBackdrop position="center" onClose={onClose}>
      <Overlay.Header glyph="edit" title="Edit Referral Out Notes" description={<ClientProfileHeader client={client} />} />

      <Overlay.Content>
        <Section>
          <Form getForm={form} initialModel={{ referral_out_notes: value || '' }}>
            <MiniRichTextEditor label="Referral Out Notes" model="referral_out_notes" />
          </Form>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Save" color="green" type="primary" glyph="check" onClick={handleSave} isLoading={isLoading} />
      </Overlay.Footer>
    </Overlay>
  )
}

const editCellClasses = 'flex flex-nowrap items-center w-full'
const editValueClasses = 'flex-1 overflow-hidden whitespace-nowrap overflow-ellipsis'
const editButtonClasses = 'svg:mr-0'
