import React from 'react'
import { Switch, Redirect, Route, useRouteMatch, useLocation } from 'react-router-dom'
import sortBy from 'lodash/sortBy'

import { withPageError } from '@behavehealth/hocs/withPageError'

import { mapToArray } from '@behavehealth/utils/functions'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { COLORS } from '@behavehealth/theme'
import snakeCase from 'lodash/snakeCase'

import {
  Button,
  Divider,
  Dropdown,
  DropdownItem,
  Glyph,
  HelpTagIframe,
  Page,
  PageLayout,
  PageSection,
  Tabs,
  State,
} from '@behavehealth/components'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { AgreementsDataTable } from '@behavehealth/constructs/Agreements/AgreementsDataTable'
import { AgreementSigneesDataTable } from '@behavehealth/constructs/Agreements/AgreementSigneesDataTable'

import { ClientTrackTabs } from '@behavehealth/constructs/ClientTracks/ClientTrackTabs'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const AddDropdown = ({ client }: any) => {
  const match = useRouteMatch()
  const { pathname } = useLocation()

  const { isBehave, isManagement } = useSettings()
  const [clientTrackId, setClientTrackId] = React.useState(client?.client_track?.id)

  const params = {
    variant: 'client',
    client_track_id: clientTrackId,
    ...(isBehave || isManagement ? { include_admin: true } : {}),
  }

  const { data, isLoading }: any = useGet({
    name: ['agreement_templates', params],
    url: `/agreement_templates`,
    params,
  })

  const templates = filterAgreementType(data, 'general')
  const adminTemplates = filterAgreementType(data, 'admin')

  React.useEffect(() => {
    if (!client?.client_track?.id) return

    setClientTrackId(client.client_track.id)
  }, [client?.client_track?.id])

  return (
    <Dropdown label="Add Agreement…" glyph="add" buttonType="primary" buttonSize={300} permission="agreements.create">
      <DropdownItem
        label="Empty Agreement"
        glyph="add"
        color="paleBlue"
        link={{
          pathname: `${pathname}/new`,
          parent: match,
          data: {
            name: 'New Agreement',
          },
        }}
      />

      <Divider />

      <DropdownItem
        label="Import Multiple Agreements…"
        glyph="documents"
        color="blue"
        link={{
          pathname: `${pathname}/import`,
          parent: match,
        }}
      />

      <Divider />

      <ClientTrackTabs clientTrack={client?.client_track} activeId={clientTrackId} setActiveId={setClientTrackId} />

      {isLoading ? (
        <State isLoading minHeight={100} />
      ) : (
        <>
          {sortBy(mapToArray(templates), 'order', 'created_at').map((template) => (
            <DropdownItem
              testKey={`${snakeCase(template.name)}_dropdown_item`}
              key={template.id}
              label={template.name}
              icon="legal_agreement_alt"
              color="paleBlue"
              link={{
                pathname: `${pathname}/new`,
                parent: match,
                data: template,
              }}
            />
          ))}

          {(isBehave || isManagement) && (
            <>
              <Divider />

              {sortBy(mapToArray(adminTemplates), 'order', 'created_at').map((template) => (
                <DropdownItem
                  testKey={`${snakeCase(template.name)}_dropdown_item`}
                  key={template.id}
                  label={template.name}
                  icon="legal_agreement_alt"
                  color="paleBlue"
                  link={{
                    pathname: `${pathname}/new`,
                    parent: match,
                    data: { ...template, agreement_type: 'admin' },
                  }}
                />
              ))}
            </>
          )}
        </>
      )}
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'client_agreements',
  help: <HelpTagIframe id="agreements" />,
  marketingID: 'agreements',
}

type Props = {
  canCreate: boolean
}

const filterAgreementType = (obj, agreement_type = 'general') => {
  const result = {}
  for (const key in obj) {
    if (obj[key].agreement_type === agreement_type) {
      result[key] = obj[key]
    }
  }
  return result
}

const Agreements: React.FC<Props> = ({ canCreate = true }) => {
  const { isBehave, isManagement } = useSettings()

  const match = useRouteMatch()
  const clientId = match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client', match.params?.resource_id],
    url: `/residents/${match.params?.resource_id}`,
  })

  const signeesTableProps = useDataTable({
    name: ['client', clientId, 'signees'],
    endpoint: `/residents/${clientId}/signees`,
    enabled: !!clientId,
    localStorageKey: 'client_signees_v1',
  })

  return (
    <Page
      feature="agreements"
      plan="basic"
      emptyDescription="No agreements have been added yet"
      help={<HelpTagIframe id="agreements" />}
      actions={
        client && (
          <>
            <Button
              size={300}
              glyph="settings"
              type="minimal"
              label="Manage Templates"
              link="/settings/agreement-templates/clients"
              permission="settings.agreement_templates.edit"
            />

            <AddDropdown client={client} />
          </>
        )
      }
    >
      <PageLayout>
        <PageSection>
          <PageSection.Header
            graphic={<Glyph glyph="user_group" color={COLORS.blue} />}
            after={
              canCreate && (
                <Button
                  label="Notify Signees"
                  glyph="signature"
                  type="default"
                  link={{
                    pathname: `${match.url}/send-agreements`,
                    parent: match,
                  }}
                  size={300}
                />
              )
            }
          >
            <PageSection.Title title="Signees" />
          </PageSection.Header>

          <PageSection.Content>
            <AgreementSigneesDataTable {...signeesTableProps} />
          </PageSection.Content>
        </PageSection>

        <PageSection>
          <PageSection.Header graphic={<Glyph glyph="legal_history" color={COLORS.orange} />}>
            <PageSection.Title title="Agreements" />
          </PageSection.Header>

          <Tabs defaultTab="support_main_plan">
            <Tabs.List className="-mt-4 mb-3">
              <Tabs.Item label="Agreements" icon="legal_agreement_alt" to={`${match.url}/agreements`} />

              {(isBehave || isManagement) && (
                <Tabs.Item label="Admin Agreements" icon="legal_agreement_alt" to={`${match.url}/admin-agreements`} />
              )}
            </Tabs.List>
          </Tabs>

          <Switch>
            <Route path={`${match.path}/agreements`} component={AgreementsPage} />
            <Route path={`${match.path}/admin-agreements`} component={isBehave || isManagement ? AdminAgreementsPage : null} />

            <Redirect exact from={match.url} to={`${match.url}/agreements`} />
          </Switch>

          <PageSection.Content></PageSection.Content>
        </PageSection>
      </PageLayout>
    </Page>
  )
}

const AgreementsPage = () => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['client', match.params?.resource_id, 'agreements'],
    endpoint: `/residents/${match.params?.resource_id}/agreements`,
    updateDeleteEndpoint: '/agreements',
    params: { variant: 'client' },
    localStorageKey: 'clients_agreements',
  })

  const to = React.useCallback(
    (data: any) => ({
      pathname: `${match.url}/${data.id}`,
      parent: match,
    }),
    [match],
  )

  return (
    <>
      <AgreementsDataTable
        {...tableProps}
        to={to}
        batchActionsConfig={[
          {
            type: 'delete',
            permission: 'agreements.delete',
            action: async ({ ids }: any) => {
              await tableProps.deleteRecords(ids.join(','))
            },
          },
        ]}
        duplicateColumn={setDuplicateColumn({
          link: `${match.url}/new`,
          parent: match,
          permission: 'agreements.create',
        })}
      />
    </>
  )
}

const AdminAgreementsPage = () => {
  const match = useRouteMatch()
  const { isBehave, isManagement } = useSettings()

  const tableProps: any = useDataTable({
    name: ['client', match.params?.resource_id, 'agreements'],
    endpoint: `/residents/${match.params?.resource_id}/agreements`,
    updateDeleteEndpoint: '/agreements',
    params: { variant: 'client', agreement_type: 'admin' },
    localStorageKey: 'clients_admin_agreements',
  })

  const to = React.useCallback(
    (data: any) => ({
      pathname: `${match.url}/${data.id}`,
      parent: match,
    }),
    [match],
  )

  return (
    <AgreementsDataTable
      {...tableProps}
      to={to}
      title="Admin Agreements"
      batchActionsConfig={[
        {
          type: 'delete',
          permission: 'agreements.delete',
          action: async ({ ids }: any) => {
            await tableProps.deleteRecords(ids.join(','))
          },
        },
      ]}
      duplicateColumn={setDuplicateColumn({
        link: `${match.url}/new`,
        parent: match,
        permission: (isBehave || isManagement) && 'agreements.create',
      })}
    />
  )
}

export default withPageError(withMarketing(Agreements, pageConfig))
