import React from 'react'
import { Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { DataFileOverlay } from '@behavehealth/constructs/OnboardingFiles/DataFileOverlay'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, Grid, Uploader, DnDUploader, Card, CardContent, TipAlert } from '@behavehealth/components'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { Tenant } from '@behavehealth/declarations'

const pageConfig = {
  feature: 'files',
  icon: 'web_form',
  title: 'Onboarding CSV/Excel for Imports',
}

const CsvExcelImports = () => {
  return (
    <>
      <CsvExcelImportsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<DataFileOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const CsvExcelImportsIndex: React.FC = () => {
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)

  const { isBehave, isManagement } = useSettings()

  const tableProps = useDataTable({
    name: ['facilities', tenant.id, 'files'],
    endpoint: `/facilities/${tenant.id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!tenant.id,
    params: {
      category: 'onboarding_data',
    },
    localStorageKey: 'files_v1',
  })

  return (
    <Page
      breakpoint="0"
      actions={
        <Uploader category="onboarding_data" resource={tenant} queryKey={tableProps.queryKey}>
          <Button type="primary" glyph="upload" label="Upload Files" />
        </Uploader>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <TipAlert contrast type="warning" localStorageKey="settings_onboarding_data_files">
          <div className="grid gap-2 py-1">
            <div>Behave offers data imports for a variety of common software platforms.</div>
            <div>This is typically done with a CSV file exported from your prior system.</div>
          </div>
        </TipAlert>

        <Card>
          <FilesDataTable
            {...tableProps}
            title="Import Files"
            batchActionsConfig={[
              {
                type: 'delete',
                permission: isBehave || isManagement,
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          <CardContent>
            <DnDUploader isEditable category="onboarding_data" resource={tenant} isDisabled={false} queryKey={tableProps.queryKey} />
          </CardContent>
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CsvExcelImports, pageConfig))
