import React from 'react'

import { countWord, daysBetween, titleCase } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'

import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

export const DischargesDataTable = (props: any) => {
  const { to, duplicateColumn } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value || titleCase(data.subcategory)} to={to?.(data)} />,
      },
      {
        width: 220,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus small status={value} />,
      },
      {
        width: 150,
        id: 'admitted_at',
        model: 'data.general.admitted_at',
        title: 'Admission Date',
        type: 'date',
        disableSort: false,
      },
      {
        width: 150,
        id: 'discharged_at',
        model: 'data.general.discharged_at',
        title: 'Discharge Date',
        type: 'date',
        disableSort: false,
      },
      {
        width: 150,
        id: 'length_of_stay',
        title: 'Length of Stay',
        disableSort: true,
        formatValue: ({ data }: any) => {
          if (!data?.data?.general?.admitted_at || !data?.data?.general?.discharged_at) return null

          return countWord('days', daysBetween(data.data.general.admitted_at, data.data.general.discharged_at, timezone))
        },
      },
      {
        width: 200,
        id: 'updated_at',
        model: 'updated_at',
        title: 'Last Edited',
        type: 'date_time',
      },
      {
        width: 180,
        id: 'author',
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Discharges"
      testKey="discharges_data_table"
      icon="discharge_forms"
      columns={columns}
      filtersConfig={FILTERS.discharges}
      {...props}
    />
  )
}
