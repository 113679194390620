import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'
import pluralize from 'pluralize'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { SERVICE_CATEGORIES } from '../../utils/constants'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'

import { titleCase, niceAmount, usDate, isPast, DT } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'
import Glyph from '../../components/Glyph'
import Link from '../../components/Link'
import Label from '../../components/Label'

import AmountInput from '../../components/Forms/AmountInput'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

import { niceOccurrenceText } from './utils'
import PreviewRecurringChargesSection from './components/PreviewRecurringChargesSection'

import { PAYMENT_METHODS_LIST } from '../../utils/constants'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

const RootChargeBuilderOverlay = (props: any) => {
  const { close, form, initialModel, isInvalid, isSaving, onValidationUpdate, isOverlayLoading, saveWithData } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions/charge_builder',
    invalidate: 'financial-transactions',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [service, setService] = React.useState(null)
  const [useCustomFrequency, setUseCustomFrequency] = React.useState(false)
  const [recurringDates, setRecurringDates] = React.useState([])

  const { timezone, tenant } = useSettings()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const employees = service?.employees
  const organizations = service?.organizations
  const isRecurring = service?.is_recurring
  const defaultExpectedPayment = service?.expected_payment
  const hasNoCharges = recurringDates?.length === 0

  const hasEmployees = size(employees) > 0
  const hasOrganizations = size(organizations) > 0

  const lastDayOfRecurrence =
    isRecurring &&
    formData?.transactioned_at &&
    formData?.end_in_months &&
    DT(formData?.transactioned_at)
      .plus({ months: parseInt(formData?.end_in_months) })
      .minus({ days: 1 })

  const dayOfNextRecurrence =
    isRecurring &&
    formData?.transactioned_at &&
    formData?.end_in_months &&
    DT(formData?.transactioned_at).plus({ months: parseInt(formData?.end_in_months) })

  if (isOverlayLoading || size(client) === 0) return <OverlayLoader />

  const save = () => {
    saveWithData({ ...formData, payed_with: formData.payed_with?.model })
  }

  return (
    <Overlay onClose={close} showBackdrop isDirty>
      <Overlay.Header icon="financials" title="Add Charges" />

      <Overlay.Content>
        <Chotomate ready name="charge_builder_overlay" />
        {client?.financial_plan ? (
          <Form
            useFullModel
            getForm={form}
            timezone={timezone}
            initialModel={initialModel}
            onValidationUpdate={onValidationUpdate}
            onUpdate={setFormData}
            linked={{
              category: 'charge',
              financial_plan_id: client?.financial_plan?.id,
              end_in_months: 1,
            }}
          >
            {/* General */}
            <Section>
              <FormSection>
                <ObjectSelector
                  model="service"
                  label="Linked Service"
                  type="client_services"
                  sortBy={['order', 'created_at']}
                  icon={ICONS.financials}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => (
                    <Flex gap={6} alignItems="center">
                      {SERVICE_CATEGORIES[data.category] && <Status label={SERVICE_CATEGORIES[data.category].label} color="green" />}
                      <span>
                        {niceAmount(data.amount)}{' '}
                        {niceOccurrenceText(data?.is_recurring, data?.recurring_frequency, data?.[FREQUENCIES[data?.recurring_frequency]])}
                        {data?.use_custom_collection ? ` (Collection ${niceAmount(data.custom_collection_amount)})` : ''}
                      </span>
                    </Flex>
                  )}
                  validations={{
                    presence: {
                      message: 'Please select a service',
                    },
                  }}
                  emptyActions={
                    <Button
                      label="Manage Services Book"
                      link="/settings/client-services"
                      glyph="settings"
                      featureFlag="services_book"
                      permission="settings.services_book.view"
                    />
                  }
                  onUpdate={(model: any) => {
                    setService(model.object)
                  }}
                />

                {!service && <Alert glyph="info">Select a Service above to start</Alert>}

                {service && (
                  <>
                    {formData?.is_custom_amount && (
                      <Flex gap="1rem">
                        <AmountInput
                          debounce={300}
                          label="Custom Amount"
                          model="amount"
                          suffix={niceOccurrenceText(
                            service?.is_recurring,
                            useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                            useCustomFrequency
                              ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                              : service?.[FREQUENCIES[service?.recurring_frequency]],
                          )}
                          defaultValue={service?.amount}
                          validations={{
                            presence: {
                              message: 'Please enter an amount',
                            },
                            numericality: {
                              greaterThan: 0,
                              message: 'Please enter an amount',
                            },
                          }}
                        />

                        {formData?.is_custom_collection && (
                          <AmountInput
                            debounce={300}
                            label="Collection Amount"
                            model="collection_amount"
                            tooltip="This is the amount that will be Collected from the Client if using 'Automatic Online Payments'"
                            defaultValue={service?.custom_collection_amount}
                            validations={{
                              presence: {
                                message: 'Please enter an amount',
                              },
                              numericality: {
                                greaterThan: 0,
                                message: 'Please enter an amount',
                              },
                            }}
                          />
                        )}
                      </Flex>
                    )}

                    {!formData?.is_custom_amount && (
                      <AmountInput
                        isEditable={false}
                        label="Service Amount"
                        suffix={`${niceOccurrenceText(
                          service?.is_recurring,
                          useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                          useCustomFrequency
                            ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                            : service?.[FREQUENCIES[service?.recurring_frequency]],
                        )} ${service?.use_custom_collection ? ` (Collection ${niceAmount(service.custom_collection_amount)})` : ''}`}
                        value={service?.amount}
                      />
                    )}

                    <Flex gap="1rem" stretchChildrenX>
                      <Checkbox label="Set Custom Amount" model="is_custom_amount" />

                      {formData?.is_custom_amount && <Checkbox label="Set Custom Collection" model="is_custom_collection" />}

                      {isRecurring && (
                        <Checkbox
                          label="Set Custom Frequency"
                          model="use_custom_frequency"
                          onUpdate={(model: any) => {
                            setUseCustomFrequency(model.value)
                          }}
                        />
                      )}
                    </Flex>

                    {useCustomFrequency && (
                      <>
                        <RadioGroup
                          label="Repeating Frequency"
                          model="recurring_frequency"
                          layout="vertical-dense"
                          defaultValue={service?.recurring_frequency || 'monthly'}
                          validations={{
                            presence: {
                              message: 'Please select a billing frequency',
                            },
                          }}
                        >
                          {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                            <Radio testKey={`frequency_selector_${key}`} key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                          ))}
                        </RadioGroup>

                        <ContextShow when="recurring_frequency" is="weekly">
                          <Select
                            asNumber
                            label="Weekly Due Day"
                            model="recurring_weekly_due_day"
                            defaultValue={service?.recurring_weekly_due_day || 0}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.weekly.map((key, idx) => (
                              <Option key={idx} label={`Every ${key}`} value={idx} />
                            ))}
                          </Select>
                        </ContextShow>

                        <ContextShow when="recurring_frequency" is="biweekly">
                          <Select
                            asNumber
                            label="Biweekly Due Day"
                            model="recurring_biweekly_due_day"
                            defaultValue={service?.recurring_biweekly_due_day || 0}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.biweekly.map((key, idx) => (
                              <Option key={idx} label={`Every other ${key}`} value={idx} />
                            ))}
                          </Select>
                        </ContextShow>

                        <ContextShow when="recurring_frequency" is="monthly">
                          <Select
                            asNumber
                            label="Monthly Due Day"
                            model="recurring_monthly_due_day"
                            defaultValue={service?.recurring_monthly_due_day || 1}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.monthly.map((key, idx) => (
                              <Option key={idx} label={key} value={idx + 1} />
                            ))}
                          </Select>
                        </ContextShow>
                      </>
                    )}

                    {!isRecurring ? (
                      <>
                        <DateInput label="Charge Date" model="transactioned_at" defaultToNow />
                      </>
                    ) : (
                      <FormSection>
                        <DateInput
                          defaultToNow
                          label="Starting From"
                          model="transactioned_at"
                          validations={{
                            presence: {
                              message: 'Please add a start date',
                            },
                          }}
                        />
                      </FormSection>
                    )}

                    {hasEmployees && (
                      <OverlaySelector
                        model="employees"
                        label="Staff Member"
                        type="employees.active"
                        apiData={employees}
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.position)}
                      />
                    )}

                    {hasOrganizations && (
                      <ObjectSelector
                        model="organizations"
                        label="Organization"
                        type="organizations"
                        apiData={organizations}
                        icon={ICONS.organizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={() => null}
                      />
                    )}

                    <ObjectSelector
                      isRelation={false}
                      className="!flex-auto"
                      model="payed_with"
                      label="Expected Payment"
                      defaultValue={defaultExpectedPayment}
                      apiData={PAYMENT_METHODS_LIST}
                      showAvatars={false}
                      selectTitle={(data: any) => (
                        <>
                          <Glyph glyph={data.glyph} /> {data.name}
                        </>
                      )}
                      selectDescription={(data: any) => data.description}
                      validations={{
                        presence: {
                          message: 'Please select an option',
                        },
                      }}
                    />
                  </>
                )}
              </FormSection>
            </Section>

            {service && (
              <>
                <Divider />

                {formData?.payed_with?.model === 'online' && (
                  <>
                    <Section title="Automatic Collection">
                      <FormSection maxWidth="100%">
                        {tenant?.is_stripe_connected ? (
                          <Grid gap={8}>
                            <RadioGroup
                              layout="vertical-dense"
                              trueIcon="check"
                              falseIcon="cross"
                              falseStyle="faded"
                              model="should_auto_charge"
                              defaultValue={true}
                            >
                              <Radio label="Don't use Automatic Payment Collection" value={false} />
                              <Radio label="Attempt to use Automatic Payment Collection" value={true} />
                            </RadioGroup>

                            {formData?.should_auto_charge && (
                              <>
                                {isRecurring ? (
                                  <>
                                    {isPast(formData?.transactioned_at, timezone) ? (
                                      <Alert type="negative" glyph="info">
                                        We'll attempt to capture all Back-Dated Charges on Save
                                      </Alert>
                                    ) : (
                                      <Alert type="default" glyph="info">
                                        We'll attempt to capture Planned Charges starting from{' '}
                                        <b>{usDate(formData?.transactioned_at, timezone)}</b>
                                      </Alert>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {isPast(formData?.transactioned_at, timezone) ? (
                                      <Alert type="negative" glyph="info">
                                        <strong>Charge Date</strong> is Today or in the Past. We'll attempt to capture Payment on Save
                                      </Alert>
                                    ) : (
                                      <Alert type="default" glyph="info">
                                        We'll attempt to capture Payment on <b>{usDate(formData?.transactioned_at, timezone)}</b>
                                      </Alert>
                                    )}
                                  </>
                                )}
                              </>
                            )}

                            <ContextShow when="should_auto_charge" is={true}>
                              <ObjectSelector
                                icon="billing"
                                type="client.payment_methods"
                                label="Payment Method to Charge"
                                model="payment_method"
                                dependentValue={client?.id}
                                selectTitle={(data: any) => data?.name}
                                selectDescription={(data: any) => data?.reference?.name}
                                validations={{
                                  presence: {
                                    message: 'Please select a Payment Method to use',
                                  },
                                }}
                              />

                              <Alert type="warning" glyph="info">
                                Any captured payments must be refunded through Stripe
                              </Alert>

                              <Alert type="warning" glyph="info">
                                Go <Link onClick={close}>back</Link> and add a Payment Method
                              </Alert>
                            </ContextShow>
                          </Grid>
                        ) : (
                          <Alert type="warning" glyph="info">
                            Set up <Link to="/settings/online-payments">Online Payments</Link> in Settings for{' '}
                            <strong>Automatic Payments Collection</strong>
                          </Alert>
                        )}
                      </FormSection>
                    </Section>

                    <Divider />
                  </>
                )}

                {formData?.payed_with?.model !== 'online' && (
                  <>
                    <Section title="Payer">
                      <FormSection maxWidth="100%">
                        <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                          <Radio label="Client" value="client" />
                          <Radio label="Contact" value="contact" />
                          <Radio label="Organization" value="organization" />
                        </RadioGroup>

                        <ContextShow when="reference_category" is="client">
                          <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" value={client} />
                        </ContextShow>

                        <ContextShow when="reference_category" is="contact">
                          <ObjectSelector
                            isPolymorphic
                            icon="contacts"
                            label="Payer"
                            model="reference"
                            type="client.contacts"
                            description={
                              <>
                                Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                              </>
                            }
                            dependentValue={client?.id}
                            selectTitle={(data: any) => data?.name}
                            selectDescription={(data: any) => titleCase(data?.relationship)}
                          />
                        </ContextShow>

                        <ContextShow when="reference_category" is="organization">
                          <ObjectSelector
                            isPolymorphic
                            icon="organizations"
                            label="Payer"
                            model="reference"
                            type="organizations"
                            dependentValue={client?.id}
                            selectTitle={(data: any) => data?.name}
                            selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                            description={
                              <>
                                Can't find the Organization you're looking for? Go to{' '}
                                <Link to="/community/organizations">Organizations</Link> to add it.
                              </>
                            }
                          />
                        </ContextShow>
                      </FormSection>
                    </Section>

                    <Divider />
                  </>
                )}

                {/* Auto-repeat */}
                {isRecurring && (
                  <>
                    <Section title="Recurring Charges">
                      <Checkbox
                        defaultChecked
                        model="repeat"
                        label={`Auto-Repeat "${service?.name}" Charges (Recurring Charges)`}
                        description={
                          <>
                            If this client is not discharged on or before <b>{usDate(lastDayOfRecurrence)}</b> the next batch of charges for{' '}
                            <b>
                              {formData?.end_in_months} {pluralize('month', parseInt(formData?.end_in_months))}
                            </b>{' '}
                            will be created on <b>{usDate(dayOfNextRecurrence)}</b>
                          </>
                        }
                      />
                    </Section>

                    <Divider />

                    <PreviewRecurringChargesSection
                      usingCustomFrequency={useCustomFrequency}
                      from={formData?.transactioned_at}
                      endIn={formData?.end_in_months}
                      serviceID={service?.id}
                      timezone={timezone}
                      amount={formData?.is_custom_amount ? formData?.amount : service?.amount}
                      onUpdate={(dates) => setRecurringDates(dates)}
                      onClear={() => setRecurringDates([])}
                    />

                    <Divider />
                  </>
                )}

                <Section title="Send Notifications">
                  <Grid gap={8}>
                    <Alert glyph="notification">Send a notification alert via SMS / Email to the Payer(s) above</Alert>

                    <CheckboxGroup layout="vertical-dense">
                      <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                      <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                    </CheckboxGroup>
                  </Grid>
                </Section>

                <Divider />

                <Section>
                  <Grid gap={8}>
                    <Textarea useQuickText label="Notes" model="notes" />
                  </Grid>
                </Section>
              </>
            )}
          </Form>
        ) : (
          <Alert type="negative" className="!m-4">
            The data required to create <strong>Charges</strong> could not be loaded from our servers. To try again, please close this
            Overlay and refresh. If this problem persists, please get in touch with Support.
          </Alert>
        )}
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Charges"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={save}
          isLoading={isSaving}
          isDisabled={isInvalid || (isRecurring && hasNoCharges)}
          permission="ledger.create"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const ChargeBuilderOverlay = withOverlayError(RootChargeBuilderOverlay)
