import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'

import { Page, Tabs, HelpTagIframe } from '@behavehealth/components'
import { withPageError } from '@behavehealth/hocs/withPageError'

import FullApplicationForm from './pages/full_application_form'
import BedsAvailability from './pages/beds_availability'
import QuickContactForm from './pages/quick_contact_form'
import { EVOBApplicationFormPage } from './pages/evob_application_form'
import { LoginButtons } from './pages/login_buttons'

const Widgets = () => {
  return (
    <Page feature="embeds" breakpoint={4} help={<HelpTagIframe id="settings_widgets" />}>
      <Tabs defaultTab="support_main_plan">
        <Tabs.List css={styles.tabsList}>
          <Tabs.Item as={NavLink} label="Quick Contact Form" icon="communications" to="quick-contact-form" featureFlagV2="admissions" />
          <Tabs.Item
            as={NavLink}
            label="Insurance Verification Form"
            icon="insurance"
            to="evob-application-form"
            featureFlagV2="admissions"
          />
          <Tabs.Item as={NavLink} label="Full Application Form" icon="admissions" to="full-application-form" featureFlagV2="admissions" />
          <Tabs.Item as={NavLink} label="Beds Availability" icon="bed_management" to="beds-availability" featureFlagV2="bed_management" />
          <Tabs.Item as={NavLink} label="Login Buttons" icon="permissions" to="login-buttons" />
        </Tabs.List>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to="quick-contact-form" replace />} />

        <Route path="full-application-form" element={<FullApplicationForm />} />
        <Route path="evob-application-form" element={<EVOBApplicationFormPage />} />
        <Route path="beds-availability" element={<BedsAvailability />} />
        <Route path="quick-contact-form" element={<QuickContactForm />} />
        <Route path="login-buttons" element={<LoginButtons />} />
      </Routes>
    </Page>
  )
}

const styles = {
  tabsList: {
    marginTop: '-0.75rem',
    marginBottom: '1.25rem',
  },
}

export default withPageError(Widgets)
