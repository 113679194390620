import { EChart } from '@kbox-labs/react-echarts'

import { GREEN, ORANGE, RED } from '../constants'
import { tint } from 'polished'
import { COLORS } from '../../../theme'

export const BarChart = (props: any) => {
  const { labels, label, values, isVertical, min = 0, max = 100, minHeight = 280, showTooltip = true, right } = props

  return (
    <EChart
      renderer={'svg'}
      grid={{
        left: isVertical ? 40 : 5,
        right: right ? right : isVertical ? 40 : 140,
        top: 20,
        bottom: 50,
      }}
      style={{
        width: '100%',
        height: '100%',
        minHeight,
      }}
      tooltip={
        showTooltip && {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        }
      }
      xAxis={{
        type: isVertical ? 'category' : 'value',
        data: isVertical ? labels : undefined,
        offset: isVertical ? undefined : 12,
        position: isVertical ? 'bottom' : 'right',
        max: isVertical ? undefined : max,
        min: isVertical ? undefined : min,
      }}
      yAxis={{
        type: isVertical ? 'value' : 'category',
        position: isVertical ? 'bottom' : 'right',
        data: isVertical ? undefined : labels,
        max: isVertical ? max : undefined,
        min: isVertical ? min : undefined,
      }}
      series={[
        {
          name: 'Score',
          stack: 'Total',
          type: 'bar',
          data: values.map((value, index) => {
            if (typeof value === 'number') {
              const percent = (value * 100) / max
              const color = percent < 33 ? RED : percent < 66 ? ORANGE : GREEN

              return {
                value,
                itemStyle: { color },
              }
            }

            return value
          }),
          barWidth: 24,
          label,
        },
      ]}
    />
  )
}

export const LineChart = (props: any) => {
  const { labels, values, min = 0, max = 100, minHeight = 280, showTooltip = true } = props

  return (
    <EChart
      animation
      renderer={'svg'}
      grid={{
        left: 30,
        right: 30,
        top: 20,
        bottom: 50,
      }}
      style={{
        width: '100%',
        height: '100%',
        minHeight,
      }}
      tooltip={
        showTooltip && {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow',
          },
        }
      }
      xAxis={{
        data: labels,
        offset: 16,
        position: 'bottom',
      }}
      yAxis={{
        type: 'value',
        max,
        min,
      }}
      series={[
        {
          name: 'Score',
          stack: 'Total',
          type: 'line',
          data: values,
          areaStyle: {
            color: tint(0.25, COLORS.vividBlue),
            opacity: 0.3,
          },
          lineStyle: {
            color: tint(0.25, COLORS.vividBlue),
          },
          itemStyle: {
            color: tint(0.25, COLORS.vividBlue),
          },
        },
      ]}
    />
  )
}

export const PieChart = (props: any) => {}
