import React from 'react'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { address } from '../../../utils/functions'
import { ICONS } from '../../../theme'
import { RECURRING_DAYS } from '../../../utils/recurrence'
import { useOverlay } from '../../../hooks/useOverlay'
import { useSettings } from '../../../hooks/useSettings'
import { withOverlayError } from '../../../hocs/withOverlayError'

import Accordions from '../../Accordions'
import Alert from '../../Alert'
import Button from '../../Button'
import Checkbox from '../../Forms/Checkbox'
import CheckboxGroup from '../../Forms/CheckboxGroup'
import ColorSelector from '../../Forms/ColorSelector'
import ContextShow from '../../Forms/ContextShow'

import DeleteDialog from '../../Dialogs/DeleteDialog'
import Flex from '../../Flex'
import Form from '../../Forms/Form'
import FormSection from '../../Forms/FormSection'
import Input from '../../Forms/Input'
import Label from '../../Label'
import Links from '../../Forms/Links'
import MiniRichTextEditor from '../../Forms/MiniRichTextEditor'
import MultiObjectSelector from '../../Forms/Selectors/MultiObject/MultiObjectSelector'
import MultiOverlaySelector from '../../Forms/Selectors/MultiOverlaySelector/MultiOverlaySelector'
import ObjectSelector from '../../Forms/Selectors/Object/ObjectSelector'
import Option from '../../Forms/Option'
import Overlay from '../../Overlay'
import OverlayLoader from '../../OverlayLoader'
import Radio from '../../Forms/Radio'
import RadioGroup from '../../Forms/RadioGroup'
import Section from '../../Section'
import Select from '../../Forms/Select'
import Subtasks from '../../Forms/Subtasks'
import Textarea from '../../Forms/Textarea'
import TimeInputSelector from '../../Forms/TimeInputSelector/TimeInputSelector'
import URLInput from '../../Forms/URLInput'

import { ClientTracksMultiObjectSelector } from '../../Forms/elements/ClientTracksMultiObjectSelector'
import { OrganizationTracksMultiObjectSelector } from '../../Forms/elements/OrganizationTracksMultiObjectSelector'
import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'

const TodoTemplateOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialData,
    isDeleting,
    isEditable,
    isEmpty,
    isInvalid,
    isLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'todo-template',
    endpoint: '/event_templates',
    options: props,
  })

  const { allowEditing = true } = props
  const { timezone, isPortal } = useSettings()

  const [recurringFrequency, setRecurringFrequency]: any = React.useState('never')
  const [startedAtDate, setStartedAtDate]: any = React.useState()
  const [repeatFromDate, setRepeatFromDate]: any = React.useState()
  const [formData, setFormData] = React.useState(initialData)

  const hasOrganizations = size(formData?.organization_ids) > 0

  if (isLoading || isEmpty) {
    return <OverlayLoader position={props.position || 'right'} showBackdrop={props.showBackdrop} />
  }

  return (
    <Overlay showBackdrop={isEditable} maxWidth={37} onClose={close}>
      <Overlay.Header icon="tasks" title="To-Do Template" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={{
            ...initialData,
            ...data,
            recurringFrequency: 'never',
          }}
          onValidationUpdate={onValidationUpdate}
          timezone={timezone}
          isEditable={isEditable}
          linked={{
            event_type: 'task',
          }}
          onUpdate={setFormData}
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical" maxWidth="100%">
              <Input
                autoFocus
                label="Title"
                model="title"
                validations={{
                  presence: {
                    message: 'Please enter a task title',
                  },
                }}
              />

              <ClientTracksMultiObjectSelector />
              <OrganizationTracksMultiObjectSelector />

              <Flex gap="1rem">
                <div className="!flex-auto">
                  <Select model="priority" label="Priority" fullWidth defaultValue="normal">
                    <Option label="Low" value="low" />
                    <Option label="Normal" value="normal" />
                    <Option label="High" value="high" />
                  </Select>
                </div>

                <ColorSelector label="Color" model="color" />
              </Flex>

              <MiniRichTextEditor useQuickText label="Public Description" model="public_description" />
            </FormSection>
          </Section>

          <Section>
            <Accordions>
              <Accordions.Item isOpen title="Date & Time">
                <FormSection>
                  <Select
                    label="Repeat"
                    model="recurring_frequency"
                    defaultValue="never"
                    onUpdate={(model: any) => {
                      setRecurringFrequency(model.value)
                    }}
                  >
                    <Option label="Never" value="never" />
                    <Option label="Daily" value="daily" />
                    <Option label="Weekly" value="weekly" />
                    <Option label="Bi-Weekly" value="biweekly" />
                    <Option label="Monthly" value="monthly" />
                    <Option label="Quarterly" value="quarterly" />
                    <Option label="Yearly" value="yearly" />
                  </Select>

                  <ContextShow when="recurring_frequency" within={['weekly', 'biweekly']}>
                    <div>
                      <Label label="Repeat On" />
                      <Flex nowrap>
                        {RECURRING_DAYS.weekly.map((day: string) => (
                          <Checkbox
                            key={day}
                            label={day.charAt(0)}
                            model={`weekly_repeat_days.${day?.toLowerCase()}`}
                            css={styles.dayCheckbox}
                          />
                        ))}
                      </Flex>
                    </div>
                  </ContextShow>

                  {/* <ContextShow when="is_all_day" is={false}>
                    <TimeInputSelector
                      defaultToNow
                      label="Due By"
                      model="start_time"
                      validations={{
                        presence: {
                          message: 'Please select when the todo starts',
                        },
                      }}
                    />
                  </ContextShow> */}

                  <Checkbox label="All Day Task" model="is_all_day" defaultValue={false} />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item isOpen title="Tasks">
                <Subtasks endpoint="events" model="subtasks" taskId={data?.id} />
              </Accordions.Item>

              <Accordions.Item isOpen title="Staff">
                <FormSection>
                  <MultiObjectSelector
                    model="employees"
                    type="employees"
                    icon={ICONS.employees}
                    selectTitle={(data: any) => data?.name}
                    selectDescription={(data: any) => startCase(data?.position)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Clients">
                <FormSection maxWidth="100%">
                  <ContextShow when="sharing_type" is="internal">
                    <Alert glyph="info">This To-Do will be visible only to Staff</Alert>
                  </ContextShow>

                  <ContextShow when="sharing_type" is="client">
                    <Alert glyph="info">This To-Do will be visible to the Client in their Portal</Alert>
                  </ContextShow>

                  <FormSection horizontal maxWidth="100%">
                    <div css={{ display: isPortal ? 'none' : 'block' }}>
                      <Select fullWidth label="Sharing Permissions" model="sharing_type" defaultValue="internal">
                        <Option label="Staff Only" value="internal" />
                        <Option label="Client Portal" value="client" />
                      </Select>
                    </div>

                    <ContextShow when="sharing_type" is="internal">
                      <Select label="Task Type" model="meeting_type" defaultValue="one_to_one" className="!flex-1">
                        <Option label="One-to-One" value="one_to_one" />
                        <Option label="Group Task" value="group_meeting" />
                      </Select>
                    </ContextShow>
                  </FormSection>

                  <ContextShow when="meeting_type" is="group_meeting">
                    <RadioGroup label="Attendees Import" model="clients_import_type" layout="vertical-dense" defaultValue="dynamic">
                      <Radio label="Automatic from Locations / Programs" value="dynamic" />
                      <Radio label="Manual from clients list" value="manual" />
                    </RadioGroup>

                    <ContextShow when="clients_import_type" is="dynamic">
                      <MultiObjectSelector
                        label="Import from Locations"
                        model="imported_houses"
                        type="properties"
                        icon={ICONS.properties}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => address(data.address)}
                      />

                      <RadioGroup
                        label="Programs Import Type"
                        model="programs_import_type"
                        layout="horizontal-dense"
                        defaultValue="programs"
                      >
                        <Radio label="Programs" value="programs" />
                        <Radio label="Program Lists" value="program_lists" />
                      </RadioGroup>

                      <ContextShow when="programs_import_type" is="programs">
                        <MultiObjectSelector
                          label="Import from Programs"
                          type="programs"
                          model="imported_programs"
                          icon={ICONS.checklist}
                          selectTitle={(data: any) => data.name}
                        />
                      </ContextShow>

                      <ContextShow when="programs_import_type" is="program_lists">
                        <MultiObjectSelector
                          label="Import from Program Lists"
                          type="program_lists"
                          model="imported_phases"
                          icon={ICONS.checklist}
                          selectTitle={(data: any) => data.name}
                          selectDescription={(data: any) => data?.program?.name || '–'}
                        />
                      </ContextShow>

                      <Alert glyph="info">
                        <strong>Clients</strong> from the above <i>Locations</i>, <i>Programs</i> or <i>Program Lists</i> will be imported
                        here
                      </Alert>
                    </ContextShow>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Organizations">
                <FormSection>
                  <MultiOverlaySelector
                    label="Organizations"
                    model="organizations"
                    type="organizations.all"
                    icon={ICONS.organizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />

                  <MultiOverlaySelector
                    label="Organization Contacts"
                    description="Select Contacts from the Organizations selected above"
                    model="organization_contacts"
                    type="all_organization_contacts"
                    dependentValue={formData?.organization_ids}
                    icon={ICONS.organizations}
                    isDisabled={!hasOrganizations}
                    selectTitle={(data: any) => data.name}
                    selectDescription={(data: any) => startCase(data.status)}
                  />
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Notifications">
                <FormSection>
                  <Alert glyph="notification">Send a notification alert via SMS / Email to the people above</Alert>

                  <CheckboxGroup layout="vertical-dense">
                    <Checkbox defaultChecked label="Send SMS Notification" model="should_sms" />
                    <Checkbox defaultChecked label="Send Email Notification" model="should_email" />
                  </CheckboxGroup>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Reminders">
                <FormSection>
                  <Checkbox label="Send SMS and Email Reminders" model="should_send_reminders" />

                  <ContextShow when="should_send_reminders" is={true}>
                    <div className="!pl-6">
                      <CheckboxGroup
                        label="Send Reminders Options"
                        layout="vertical-dense"
                        validations={{
                          presence: {
                            message: 'Please select at least one option',
                          },
                        }}
                      >
                        <Checkbox label="Three days before the todo" model="send_reminders.three_days" />
                        <Checkbox label="Two days before the todo" model="send_reminders.two_days" />
                        <Checkbox label="One day before the todo" model="send_reminders.one_day" />
                        <Checkbox label="Three hours before the todo" model="send_reminders.three_hours" />
                        <Checkbox label="Two hours before the todo" model="send_reminders.two_hours" />
                        <Checkbox label="One hour before the todo" model="send_reminders.one_hour" />
                        <Checkbox label="15 min before todo" model="send_reminders.15_minutes" />
                      </CheckboxGroup>
                    </div>
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Place">
                <FormSection>
                  <Select label="Meeting Place" model="meeting_place" defaultValue="none" className="!flex-1">
                    <Option label="None" value="none" />
                    <Option label="Online" value="online" />
                    <Option label="Housing" value="property" />
                    <Option label="Office" value="office" />
                    <Option label="Organization" value="organization" />
                    <Option label="Other" value="other" />
                  </Select>

                  {formData?.meeting_place === 'online' && (
                    <>
                      <Checkbox
                        defaultChecked
                        falseIcon="cross"
                        falseStyle="hidden"
                        label=" "
                        description="I acknowledge it is my responsibility to secure this external meeting room service that is outside the control of Behave Health Corp. This includes any requirements related to HIPAA. If you have any questions email us at contact@behavehealth.com"
                        model="responsibility_acknowledgement"
                      />

                      <URLInput
                        label="Meeting Link"
                        model="meeting_url"
                        isDisabled={formData?.responsibility_acknowledgement !== true}
                        validations={{
                          presence: {
                            message: 'Please enter a meeting link',
                          },
                        }}
                      />
                    </>
                  )}

                  <ContextShow when="meeting_place" is="property">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Housing"
                      type="properties"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="office">
                    <ObjectSelector
                      icon={ICONS.properties}
                      label="Office"
                      type="offices"
                      model="house"
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                      validations={{
                        presence: {
                          message: 'Please select a location',
                        },
                      }}
                    />

                    <ObjectSelector
                      icon={ICONS.rooms}
                      label="Room (optional)"
                      type="property.rooms"
                      model="room"
                      dependent="house_id"
                      disableUnless="house_id"
                      selectTitle={(data: any) => data.name}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" is="organization">
                    <ObjectSelector
                      label="Organization"
                      model="organization"
                      type="organizations"
                      icon={ICONS.organizations}
                      selectTitle={(data: any) => data.name}
                      selectDescription={(data: any) => address(data.address)}
                    />
                  </ContextShow>

                  <ContextShow when="meeting_place" not="none">
                    <MiniRichTextEditor useQuickText label="More Details" model="place_details" />
                  </ContextShow>
                </FormSection>
              </Accordions.Item>

              <Accordions.Item title="Links">
                <Links model="urls" />
              </Accordions.Item>

              <Accordions.Item title="Notes">
                <FormSection>
                  <MiniRichTextEditor useQuickText label="Internal Notes" model="internal_notes" />
                </FormSection>
              </Accordions.Item>
            </Accordions>
          </Section>
        </Form>
      </Overlay.Content>

      {allowEditing && (
        <Overlay.Footer>
          {isEditable && (
            <>
              <Button
                label="Save"
                glyph="check"
                type="primary"
                color="green"
                flex="100 1 auto"
                onClick={save}
                isLoading={isSaving}
                isDisabled={isInvalid}
                permission="todo_templates.create"
              />
              <Button label="Cancel" glyph="cross" onClick={cancel} isDisabled={isSaving} />
            </>
          )}

          {!isEditable && (
            <>
              <Button label="Edit" glyph="edit" onClick={edit} flex="100 1 auto" permission="todo_templates.edit" />

              <DeleteDialog
                title="Delete Event Template?"
                message="Are you sure you want to delete this event template? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button label="Delete" glyph="delete" color="red" isLoading={isDeleting} permission="todo_templates.delete" />
              </DeleteDialog>
            </>
          )}
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const styles = {
  dayCheckbox: {
    width: 'auto !important',
    flex: '1 1 auto !important',
    marginLeft: 4,
    marginRight: 4,

    '@media (max-width: 550px)': {
      marginLeft: 2,
      marginRight: 2,
    },

    '&:first-of-type': { marginLeft: 0 },
    '&:last-of-type': { marginRight: 0 },

    label: {
      fontSize: '0.9rem',

      '@media (max-width: 550px)': {
        padding: '0.5em 0.4em',
        flexDirection: 'column',
      },
    },

    '.check-element': {
      width: 18,
      height: 18,

      '@media (max-width: 550px)': {
        margin: '0 0 0.4rem 0',
      },
    },
  },
}

export default withOverlayError(TodoTemplateOverlay)
