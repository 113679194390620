import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { countWord, daysBetween } from '../../utils/functions'
import { FINANCIAL_STATUS } from '../../utils/constants'
import { LOC } from '../../utils/constants'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { useDataTable } from '../../components/DataTable/useDataTable'
import AlumniQuickView from '../../components/Overlays/quick/AlumniQuickView'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'

import { FILTERS } from '../Filters/config'
import { HighRiskAlertStatus } from '../HighRiskAlert/HighRiskAlertStatus'
import { ClientTrackSelector } from '../ClientTracks/ClientTrackSelector'

export const AlumniDataTable = (props) => {
  const { testKey, localStorageKey = 'alumni_v1' } = props
  const match = useRouteMatch()

  const { tenant, isBehave } = useSettings()

  const tableProps = useDataTable({
    name: 'clients',
    endpoint: '/residents',
    params: { status: 'alumni' },
    localStorageKey,
  })

  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'
  const showERXFeatures = tenant?.feature_flags?.erx === true

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        // disableSort: true,
        formatValue: ({ data }: any) => {
          if (props.renderMainCell) {
            return props.renderMainCell(data)
          }

          return (
            <MainCell
              testKey="main_cell"
              id={data.id}
              value={data.name}
              avatar={data.avatar}
              to={`${match.url}/${data.id}`}
              quickViewOverlay={<AlumniQuickView client={data} />}
            />
          )
        },
      },
      {
        title: 'High Risk Alert',
        model: 'high_risk_title',
        width: 300,
        formatValue: ({ data }: any) => {
          if (!data.high_risk_title) return null

          return <HighRiskAlertStatus title={data.high_risk_title} notes={data.high_risk_notes} />
        },
      },
      {
        title: 'Billed',
        model: 'is_billed_by_behave',
        type: 'boolean',
        permission: isBehave,
      },
      {
        title: 'Tags',
        model: 'tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'past_clients',
      },
      {
        title: 'Client Track',
        model: 'client_track',
        featureFlag: 'client_tracks',
        formatValue: ({ value, data }) => <ClientTrackSelector clientId={data.id} activeTrack={value} buttonLabel={null} />,
      },
      {
        title: 'Color',
        model: 'color_code',
        width: 70,
        centerX: true,
        formatValue: ({ data }: any) => <ClientColorPicker clientID={data.id} color={data.color_code} />,
      },
      {
        title: 'Financial Status',
        model: 'financial_status',
        width: 150,
        formatValue: ({ value }: any) => FINANCIAL_STATUS[value],
      },
      {
        title: 'Client ID',
        model: 'behave_id',
        width: 180,
        formatValue: ({ data }: any) => `#${data.behave_id}`,
      },
      {
        title: 'Admission Date',
        model: 'current_admission.admitted_at',
        id: 'admission_admitted_at',
        type: 'date_time',
      },
      {
        title: 'Discharge Date',
        model: 'current_admission.discharged_at',
        id: 'admission_discharged_at',
        type: 'date_time',
      },
      {
        title: 'Length of Stay',
        id: 'length_of_stay',
        disableSort: true,
        width: 140,
        formatValue: ({ data, timezone }: any) => {
          const admitDate: any = data?.current_admission?.admitted_at
          const dischargeDate: any = data?.current_admission?.discharged_at

          if (!admitDate || !dischargeDate) return null

          return countWord('days', daysBetween(admitDate, dischargeDate, timezone))
        },
      },
      {
        title: 'Cell Phone',
        type: 'phone',
        model: 'phone_no',
      },
      {
        title: 'Email',
        type: 'email',
        model: 'email',
      },
      {
        title: 'Sex',
        model: 'sex',
        type: 'title',
        width: 140,
      },
      // {
      //   title: 'Level of Care',
      //   model: 'level_of_care',
      //   permission: isEHR,
      //   formatValue: ({ data }: any) => {
      //     if (!data.level_of_care) return null

      //     return LOC[data.level_of_care]
      //   },
      // },
      {
        title: 'Level of Care',
        id: 'level_of_care',
        model: 'active_level_of_care',
        permission: isEHR,
        formatValue: ({ data }: any) => {
          if (!data.active_level_of_care) return null

          return data.active_level_of_care?.name
        },
      },
      {
        title: 'Synced with eRX',
        model: 'erx_status',
        permission: showERXFeatures,
        formatValue: ({ data }: any) => {
          const status = data.erx_status

          if (!status) return null

          if (status === 'synced') return 'Yes'
          else if (status === 'not_synced') return 'No'
        },
      },
      {
        title: 'Using Portal',
        model: 'client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      {
        title: 'Preferred Name',
        model: 'preferred_name',
        width: 180,
      },
      {
        title: 'Last Name',
        model: 'last_name',
        width: 180,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
    ],
    [showERXFeatures, isEHR, isBehave],
  )

  return (
    <>
      <DataTable
        {...tableProps}
        testKey={testKey}
        title="Past Clients"
        icon="alumni_relations"
        columns={columns}
        canSelect={false}
        filtersConfig={FILTERS.alumni}
        onRowSelect={() => {}}
        onDataInitialized={() => {}}
        updateKey="client"
        updateDeleteEndpoint="/residents"
        invalidate={['clients', { status: 'alumni' }]}
        emptyDescription="Past Clients will show up here after discharge"
        headerLinksConfig={[
          {
            type: 'report',
            to: '/reports/alumni',
          },
        ]}
        {...props}
      />
    </>
  )
}
