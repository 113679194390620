import React from 'react'

import ContextShow from '../../components/Forms/ContextShow'
import Divider from '../../components/Divider'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Textarea from '../../components/Forms/Textarea'

import { DataFormOverlay } from '../DataFormOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

const RootCLSOverlay = (props: any) => {
  return (
    <DataFormOverlay
      minimizeEnabled
      signoffWithoutSupervisor
      enableTreatmentPlanSelector
      title="Casey Life Skills (CLS)"
      category="clinical_measurement"
      subcategory="cls"
      icon="clinical_measurements"
      requestClientSignature={false}
      requestAuthorSignature={false}
      requestSupervisorSignature={false}
      requireSupervisor={false}
      {...props}
    >
      <Section
        title="Instructions"
        headingType="h2"
        scrollview={{
          id: 'instructions',
          name: 'Instructions',
        }}
      >
        <p>
          The Casey Life Skills (CLS) is a multiple-choice questionnaire that measures independent living skills in a number of functional
          areas for young people. The CLS standard form has 126 questions that assesses 9 functional areas, (1) daily living, (2) self-care,
          (3) relationships and communication, (4) housing, money management, and transportation, (5) work and study life, (6) career and
          education planning, (7) civic engagement, (8) navigating the child welfare system, and (9) looking forward. This assessment is for
          youth ages 14-21 years. It can be completed by youth and supportive adults (e.g., caregivers, service providers, case workers,
          etc.) to understand the youth's skills.
        </p>
      </Section>

      <Divider />

      <Section
        title="Demographic Information"
        headingType="h2"
        scrollview={{
          id: 'demographic_information',
          name: 'Demographic Information',
        }}
        commentsModel="data.demographic_information"
      >
        <Grid gap="1rem">
          <RadioGroup label="Gender Identity" model="data.demographic_information.gender_identity" layout="vertical-dense">
            <Radio label="Female" value="female" />
            <Radio label="Male" value="male" />
            <Radio label="Female to Male" value="female_to_male" />
            <Radio label="Male to Female" value="male_to_female" />
            <Radio label="Non-binary" value="non_binary" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.gender_identity" is="other">
            <Textarea label="Please specify" model="data.demographic_information.gender_identity_other" />
          </ContextShow>

          <RadioGroup label="Sexual Orientation" model="data.demographic_information.sexual_orientation" layout="vertical-dense">
            <Radio label="Gay/Lesbian" value="gay_lesbian" />
            <Radio label="Queer" value="queer" />
            <Radio label="Bisexual" value="bisexual" />
            <Radio label="Questioning" value="questioning" />
            <Radio label="Homosexual" value="homosexual" />
            <Radio label="Same Gender Loving" value="same_gender_loving" />
            <Radio label="Heterosexual" value="heterosexual" />
            <Radio label="Prefer not to answer" value="prefer_not_to_say" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.sexual_orientation" is="other">
            <Textarea label="Please specify" model="data.demographic_information.sexual_orientation_other" />
          </ContextShow>

          <RadioGroup label="Preferred Pronoun" model="data.demographic_information.preferred_pronoun" layout="vertical-dense">
            <Radio label="She, Her, Hers" value="she_her_hers" />
            <Radio label="He, Him, His" value="he_him_his" />
            <Radio label="Ze, Hir" value="ze_hir" />
            <Radio label="They, Them, Theirs" value="they_them_theirs" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.preferred_pronoun" is="other">
            <Textarea label="Please specify" model="data.demographic_information.preferred_pronoun_other" />
          </ContextShow>

          <RadioGroup label="Race" model="data.demographic_information.race" layout="vertical-dense">
            <Radio label="African American/Black" value="african_american_black" />
            <Radio label="American Indian/ Alaska Native" value="american_indian_alaska_native" />
            <Radio label="Asian Indian" value="asian_indian" />
            <Radio label="Biracial" value="biracial" />
            <Radio label="Chinese" value="chinese" />
            <Radio label="Filipino" value="filipino" />
            <Radio label="Guamanian or Chamorro" value="guamanian_or_chamorro" />
            <Radio label="Japanese" value="japanese" />
            <Radio label="Korean" value="korean" />
            <Radio label="Multiracial" value="multiracial" />
            <Radio label="Native Hawaiian" value="native_hawaiian" />
            <Radio label="Other Asian" value="other_asian" />
            <Radio label="Other Pacific Islander" value="other_pacific_islander" />
            <Radio label="Samoan" value="samoan" />
            <Radio label="Vietnamese" value="vietnamese" />
            <Radio label="White" value="white" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.race" is="other">
            <Textarea label="Please specify" model="data.demographic_information.race_other" />
          </ContextShow>

          <RadioGroup label="Latinx/Hispanic" model="data.demographic_information.latinx_hispanic" layout="vertical-dense">
            <Radio label="No, Not Hispanic, Latinx" value="no_not_hispanic_latinx" />
            <Radio label="Yes, Mexican, Mexican American, Chicano" value="yes_mexican_mexican_american_chicano" />
            <Radio label="Yes, Puerto Rican" value="yes_puerto_rican" />
            <Radio label="Yes, Cuban" value="yes_cuban" />
            <Radio label="Yes, Salvadoran" value="yes_salvadoran" />
            <Radio label="Yes, Dominican" value="yes_dominican" />
            <Radio label="Yes, Guatemalan" value="yes_guatemalan" />
            <Radio label="Yes, Colombians" value="yes_colombians" />
            <Radio label="Yes, Honduran" value="yes_honduran" />
            <Radio label="Yes, Ecuadorian" value="yes_ecuadorian" />
            <Radio label="Yes, Peruvian" value="yes_peruvian" />
            <Radio label="Yes, Other Hispanic Latino" value="yes_other_hispanic_latino" />
          </RadioGroup>

          <RadioGroup
            label="Religious/ Spiritual Affiliation"
            model="data.demographic_information.religious_spiritual_affiliation"
            layout="vertical-dense"
          >
            <Radio label="Christian" value="christian" />
            <Radio label="Jewish" value="jewish" />
            <Radio label="Muslim" value="muslim" />
            <Radio label="Buddhist" value="buddhist" />
            <Radio label="Hindu" value="hindu" />
            <Radio label="Baha’i" value="baha_i" />
            <Radio label="Atheist" value="atheist" />
            <Radio label="Agnostic" value="agnostic" />
            <Radio label="No religious/ Spiritual affiliation" value="no_religious_spiritual_affiliation" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.religious_spiritual_affiliation" is="other">
            <Textarea label="Please specify" model="data.demographic_information.religious_spiritual_affiliation_other" />
          </ContextShow>

          <RadioGroup label="Primary Language" model="data.demographic_information.primary_language" layout="vertical-dense">
            <Radio label="English" value="english" />
            <Radio label="Spanish" value="spanish" />
            <Radio label="French" value="french" />
            <Radio label="Chinese" value="chinese" />
            <Radio label="Japanese" value="japanese" />
            <Radio label="Russian" value="russian" />
            <Radio label="Sign Language" value="sign_language" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.primary_language" is="other">
            <Textarea label="Please specify" model="data.demographic_information.primary_language_other" />
          </ContextShow>

          <RadioGroup label="Secondary Language" model="data.demographic_information.secondary_language" layout="vertical-dense">
            <Radio label="English" value="english" />
            <Radio label="Spanish" value="spanish" />
            <Radio label="French" value="french" />
            <Radio label="Chinese" value="chinese" />
            <Radio label="Japanese" value="japanese" />
            <Radio label="Russian" value="russian" />
            <Radio label="Sign Language" value="sign_language" />
            <Radio label="Other" value="other" />
          </RadioGroup>

          <ContextShow when="data.demographic_information.secondary_language" is="other">
            <Textarea label="Please specify" model="data.demographic_information.secondary_language_other" />
          </ContextShow>

          <RadioGroup
            label="Do you have a documented disability?"
            model="data.demographic_information.has_documented_disability"
            layout="vertical-dense"
          >
            <Radio label="Yes" value="yes" />
            <Radio label="No" value="no" />
            <Radio label="Prefer not to answer" value="prefer_not_to_say" />
          </RadioGroup>
        </Grid>
      </Section>

      {SECTIONS.map((section) => (
        <React.Fragment key={section.model}>
          <Divider />

          <Section
            title={section.title}
            headingType="h2"
            scrollview={{
              id: section.model,
              name: section.title,
            }}
            commentsModel={`data.${section.model}`}
          >
            <FormSection key={section.model}>
              <div className="text-[1.1rem] text-text-muted">Are the following statements like me?</div>

              {section.questions.map((question, qIndex) => (
                <RadioGroup
                  key={question.model}
                  label={`${qIndex + 1}. ${question.label}`}
                  model={`data.${section.model}.${question.model}`}
                  layout="vertical-dense"
                >
                  <Radio label="Yes" value="yes" />
                  <Radio label="Mostly yes" value="mostly_yes" />
                  <Radio label="Somewhat" value="somewhat" />
                  <Radio label="Mostly no" value="mostly_no" />
                  <Radio label="No" value="no" />
                </RadioGroup>
              ))}
            </FormSection>
          </Section>
        </React.Fragment>
      ))}

      <Divider />

      <Section title="Sources" headingType="h2">
        <p>
          The Casey Life Skills Toolkit, which includes the full suite of CLS Assessments, Practitioners Guide and Resources to Inspire
          Guide, is hosted at:
        </p>

        <p>
          <a href="https://www.casey.org/casey-life-skills" target="_blank">
            www.casey.org/casey-life-skills
          </a>
        </p>

        <p>
          Casey Family Programs owns all rights, titles, and interests including copyrights and other intellectual property rights in and to
          this assessment. Your use of this assessment is subject to the CLS Licensing Agreement. As detailed in the Agreement, you may not
          (i) use this assessment for any commercial purpose, (ii) modify it, or (iii) transfer or sublicense it for any purpose other than
          administering the assessment to youth and their families.
        </p>
      </Section>
    </DataFormOverlay>
  )
}

const SECTIONS = [
  {
    title: 'Daily Living Skills',
    model: 'daily_living_skills',
    questions: [
      {
        label: 'I know how to create, save, and print documents on a computer.',
        model: 'knows_how_to_create_save_and_print_documents_on_a_computer',
      },
      {
        label: 'I know how to open an email account and use it.',
        model: 'knows_how_to_open_an_email_account_and_use_it',
      },
      {
        label: 'I know how to access information on the internet safely.',
        model: 'knows_how_to_access_information_on_the_internet_safely',
      },
      {
        label: 'I can recognize phishing via emails, texts, and other social media platforms.',
        model: 'can_recognize_phishing_via_emails_texts_and_other_social_media_platforms',
      },
      {
        label: 'I know the risks of meeting someone in person that I met online.',
        model: 'knows_the_risks_of_meeting_someone_in_person_that_met_online',
      },
      {
        label: 'I know the risk of sharing private information (e.g., pictures, credit card, address) with someone I have met online.',
        model: 'knows_the_risk_of_sharing_private_information_online',
      },
      {
        label: 'I think of ways pictures or messages may affect other people before I post them.',
        model: 'thinks_of_impact_of_pictures_or_messages_before_posting',
      },
      {
        label: 'If someone sent me a message online that made me feel upset or scared, I would know who to reach out to or what to do.',
        model: 'knows_how_to_respond_to_upsetting_or_scary_online_messages',
      },
      {
        label: 'When I shop, I make a list and compare prices.',
        model: 'makes_list_and_compares_prices_when_shopping',
      },
      {
        label: 'I know how to make healthy meals with or without using a recipe.',
        model: 'knows_how_to_make_healthy_meals_with_or_without_recipe',
      },
      {
        label: 'I know how to reheat leftover food using a microwave or stove safely.',
        model: 'knows_how_to_reheat_leftover_food_safely',
      },
      {
        label: 'I understand the importance of creating a healthy meal.',
        model: 'understands_importance_of_creating_healthy_meal',
      },
      {
        label: 'I understand how to read food labels to see calories, serving size, fat, sugar, and salt.',
        model: 'understands_how_to_read_food_labels_for_nutritional_info',
      },
      {
        label: 'I understand how to read food labels to see manufactured and expiration dates.',
        model: 'understands_how_to_read_food_labels_for_dates',
      },
      {
        label: 'I know how to use a washer, dryer, and detergent to clean my clothes.',
        model: 'knows_how_to_use_washer_dryer_and_detergent',
      },
      {
        label: 'I know how to keep my living space tidy and organized.',
        model: 'knows_how_to_keep_living_space_tidy_and_organized',
      },
      {
        label: 'I know the appropriate products to use when cleaning my space.',
        model: 'knows_appropriate_cleaning_products_for_space',
      },
      {
        label: 'I know how to use a fire extinguisher.',
        model: 'knows_how_to_use_fire_extinguisher',
      },
    ],
  },
  {
    title: 'Self Care Skills',
    model: 'self_care_skills',
    questions: [
      {
        label: 'I know how to practice personal hygiene (e.g., bathing, brushing, flossing).',
        model: 'knows_how_to_practice_personal_hygiene',
      },
      {
        label: 'I know how to take care of myself when I am on my menstrual cycle.',
        model: 'knows_how_to_take_care_during_menstrual_cycle',
      },
      {
        label: 'I know how to take care of minor cuts, burns, and flu-like symptoms.',
        model: 'knows_how_to_take_care_of_minor_cuts_burns_flu',
      },
      {
        label: 'I understand the importance of scheduling regular physical, dental, and eye care appointments with my provider.',
        model: 'understands_importance_of_scheduling_health_appointments',
      },
      {
        label: 'I know how to make my medical and dental appointments.',
        model: 'knows_how_to_make_medical_dental_appointments',
      },
      {
        label: 'I know when I should go to my primary provider, urgent care, or emergency room.',
        model: 'knows_when_to_go_to_primary_provider_urgent_emergency',
      },
      {
        label: 'I know how to access telehealth when I need it.',
        model: 'knows_how_to_access_telehealth',
      },
      {
        label:
          'I know how to understand if I am eligible for benefits such as health insurance, Social Security, Medicaid, Temporary Assistance for Needy Families (TANF), Supplemental Nutrition Assistance program (SNAP), Women, Infants, and Child program (WIC), Education and Training Vouchers (ETV), etc.',
        model: 'knows_eligibility_for_health_social_benefits',
      },
      {
        label: 'I know how to positively manage my mental health when I am having a difficult day.',
        model: 'knows_how_to_manage_mental_health_on_difficult_days',
      },
      {
        label: 'I know how to step away from situations if they are too stressful or cause me discomfort.',
        model: 'knows_how_to_step_away_from_stressful_situations',
      },
      {
        label: 'I understand when I am having a mental health crisis.',
        model: 'understands_when_experiencing_mental_health_crisis',
      },
      {
        label: 'I know how to access a mental health professional.',
        model: 'knows_how_to_access_mental_health_professional',
      },
      {
        label: 'I know how to practice safe sex (e.g., using condoms).',
        model: 'knows_how_to_practice_safe_sex',
      },
      {
        label: 'I know how to prevent getting pregnant or getting someone else pregnant.',
        model: 'knows_how_to_prevent_pregnancy',
      },
      {
        label: 'I know where to get reliable information about safe sex and pregnancy.',
        model: 'knows_where_to_get_information_safe_sex_pregnancy',
      },
      {
        label: 'I know how to protect myself from sexually transmitted diseases (STDs).',
        model: 'knows_how_to_protect_against_stds',
      },
      {
        label: 'I understand that if I am sexually active, I should be getting tested for sexually transmitted diseases (STDS).',
        model: 'understands_need_for_std_testing_if_sexually_active',
      },
      {
        label: "I know who to ask to get access to my family's medical history.",
        model: 'knows_who_to_ask_for_family_medical_history',
      },
    ],
  },
  {
    title: 'Relationship and Communication',
    model: 'relationship_and_communication',
    questions: [
      {
        label: 'I feel confident speaking up for myself when I need to.',
        model: 'feels_confident_speaking_up_for_self',
      },
      {
        label: 'I think about my choices and the consequences that come with them.',
        model: 'thinks_about_choices_and_consequences',
      },
      {
        label: 'I can cope with my frustration, hurt, and anger without breaking things or retaliating.',
        model: 'can_cope_with_frustration_hurt_anger',
      },
      {
        label: 'I know how to behave around friends, peers, and classmates.',
        model: 'knows_how_to_behave_around_friends_peers_classmates',
      },
      {
        label: 'I know how to behave at work and in other professional settings.',
        model: 'knows_how_to_behave_at_work_professional_settings',
      },
      {
        label: 'I know how to respect people with different beliefs, opinions, and cultures.',
        model: 'knows_how_to_respect_people_with_different_beliefs',
      },
      {
        label: 'I am aware of my racial and ethnic identity.',
        model: 'aware_of_racial_ethnic_identity',
      },
      {
        label: 'I feel comfortable exploring my gender identity and sexual orientation.',
        model: 'comfortable_exploring_gender_identity_sexual_orientation',
      },
      {
        label: 'I have relationships that make me feel valued and worthwhile.',
        model: 'has_relationships_that_make_feel_valued_worthwhile',
      },
      {
        label: 'I know the importance of obtaining and giving consent in a relationship.',
        model: 'knows_importance_of_consent_in_relationship',
      },
      {
        label: 'I know how to identify if my relationships are free from any emotional, physical, and mental abuse.',
        model: 'knows_how_to_identify_abuse_free_relationships',
      },
      {
        label: 'I have information about family members with whom I could possibly live.',
        model: 'has_information_about_family_members_for_living',
      },
      {
        label: 'I am comfortable showing affection to the people I care about.',
        model: 'comfortable_showing_affection',
      },
      {
        label: 'I am comfortable building new relationships.',
        model: 'comfortable_building_new_relationships',
      },
    ],
  },
  {
    title: 'Housing, Money Management and Transportation',
    model: 'housing_money_management_and_transportation',
    questions: [
      {
        label: 'I know how to create a daily living budget.',
        model: 'knows_how_to_create_daily_living_budget',
      },
      {
        label: 'I understand the importance of making payments for my credit card purchases.',
        model: 'understands_importance_of_making_credit_card_payments',
      },
      {
        label: 'I know the importance of having a good credit score.',
        model: 'knows_importance_of_having_good_credit_score',
      },
      {
        label: 'I know how to get my credit score.',
        model: 'knows_how_to_get_credit_score',
      },
      {
        label: 'I know how to manage my bank account.',
        model: 'knows_how_to_manage_bank_account',
      },
      {
        label: 'I know the importance of saving money regularly from my paycheck.',
        model: 'knows_importance_of_saving_money_from_paycheck',
      },
      {
        label: 'I understand how to use online banking to keep track of my money.',
        model: 'understands_how_to_use_online_banking',
      },
      {
        label: 'I understand how to safely use online money transfer apps (e.g., PayPal, Venmo).',
        model: 'understands_how_to_use_online_money_transfer_apps',
      },
      {
        label: 'I know the risks of using check cashing or payday loan stores.',
        model: 'knows_risks_of_using_check_cashing_or_payday_loan_stores',
      },
      {
        label: 'I know how to look for safe and affordable housing.',
        model: 'knows_how_to_look_for_safe_affordable_housing',
      },
      {
        label: 'I know what things to look for before signing a lease to ensure everything is working and is a safe environment.',
        model: 'knows_what_to_look_for_before_signing_lease',
      },
      {
        label: 'I understand how to plan for moving costs such as deposits, rents, utilities, and furniture.',
        model: 'understands_how_to_plan_for_moving_costs',
      },
      {
        label: 'I know how to fill out an apartment rental application.',
        model: 'knows_how_to_fill_out_apartment_rental_application',
      },
      {
        label: 'I know where to find resources to help with water, gas, and electricity bills.',
        model: 'knows_where_to_find_resources_for_utility_bills',
      },
      {
        label: "I understand the importance of having renters or homeowners' insurance.",
        model: 'understands_importance_of_renters_or_homeowners_insurance',
      },
      {
        label:
          'I plan for bills and other expenses that I must pay for regularly each month (e.g., car and/or health insurance, cell phone, eating out, rent, video games).',
        model: 'plans_for_regular_monthly_expenses',
      },
      {
        label: 'I understand that I need a valid driver’s license and insurance to drive a car.',
        model: 'understands_need_for_license_and_insurance_to_drive',
      },
      {
        label: 'I know where to go to obtain my driver’s license or State ID.',
        model: 'knows_where_to_obtain_drivers_license_or_state_id',
      },
      {
        label: 'I understand that I need to budget for registration, repairs, insurance, and gas if I own a car.',
        model: 'understands_budgeting_for_car_ownership',
      },
      {
        label: 'I know how to use public transportation (or rideshare services such as Uber/LYFT) to get to where I need to go.',
        model: 'knows_how_to_use_public_transportation_or_rideshare',
      },
    ],
  },
  {
    title: 'Work and Study Life',
    model: 'work_and_study_life',
    questions: [
      {
        label: 'I know how to develop a resume for a job that I am applying for.',
        model: 'knows_how_to_develop_resume_for_job',
      },
      {
        label: 'I know how to fill out an online and paper job application.',
        model: 'knows_how_to_fill_out_online_and_paper_job_application',
      },
      {
        label: 'I know how to prepare and dress for a job interview.',
        model: 'knows_how_to_prepare_and_dress_for_job_interview',
      },
      {
        label: 'I know people who can help me find a job.',
        model: 'knows_people_who_can_help_find_job',
      },
      {
        label: 'I know what the information on my paystub or paycheck deposit means.',
        model: 'knows_what_information_on_paystub_means',
      },
      {
        label:
          'I know who to ask to get documents I need for work (e.g., social security card, birth certificate, state ID, or work permit).',
        model: 'knows_who_to_ask_for_work_documents',
      },
      {
        label: 'I know how to fill out a W-4 payroll exemption form when I get a job.',
        model: 'knows_how_to_fill_out_w4_form',
      },
      {
        label: 'I know what benefits are covered by my employer (e.g., social security, healthcare).',
        model: 'knows_benefits_covered_by_employer',
      },
      {
        label: 'I know where I can get help to fill out my taxes.',
        model: 'knows_where_to_get_help_filling_taxes',
      },
      {
        label: 'I know what workplace sexual harassment is.',
        model: 'knows_what_workplace_sexual_harassment_is',
      },
      {
        label: 'I know what workplace discrimination is.',
        model: 'knows_what_workplace_discrimination_is',
      },
      {
        label: "I know who to get help from regarding my school's counseling services.",
        model: 'knows_who_to_get_help_from_school_counseling',
      },
      {
        label: 'I can take feedback and guidance at school and/or work to improve my work.',
        model: 'can_take_feedback_to_improve_work',
      },
      {
        label: 'I know how to prepare for course exams and presentations.',
        model: 'knows_how_to_prepare_for_exams_and_presentations',
      },
      {
        label: 'I know where I can get tutoring or other help with schoolwork.',
        model: 'knows_where_to_get_tutoring_or_help_with_schoolwork',
      },
      {
        label: 'I understand that I need to take careful steps to review my work before submitting it.',
        model: 'understands_need_to_review_work_before_submitting',
      },
      {
        label: 'I get my work done and turn it in on time.',
        model: 'gets_work_done_and_turns_in_on_time',
      },
      {
        label: 'I know how to use a planner, calendar, or phone to plan out and keep track of important dates and assignments.',
        model: 'knows_how_to_use_planner_calendar_for_tracking',
      },
      {
        label: 'I know my legal rights as a part-time or full-time employee.',
        model: 'knows_legal_rights_as_employee',
      },
    ],
  },
  {
    title: 'Career and Education Planning',
    model: 'career_and_education_planning',
    questions: [
      {
        label: 'I know where to find work-related internships.',
        model: 'knows_where_to_find_work_related_internships',
      },
      {
        label: 'I know how to use reliable online platforms to find information about job training opportunities.',
        model: 'knows_how_to_use_online_platforms_for_job_training_info',
      },
      {
        label: 'I know how to use online resources that can help me with filling out a job application.',
        model: 'knows_how_to_use_online_resources_for_job_application',
      },
      {
        label: 'I understand the benefits of doing volunteer work.',
        model: 'understands_benefits_of_doing_volunteer_work',
      },
      {
        label: 'I know the importance of having a mentor to guide me in a career that I would like.',
        model: 'knows_importance_of_having_career_mentor',
      },
      {
        label: 'I know how to find an education mentor.',
        model: 'knows_how_to_find_education_mentor',
      },
      {
        label: 'I am familiar with the FAFSA application.',
        model: 'familiar_with_fafsa_application',
      },
      {
        label: 'I have talked about my education plans with an education advisor.',
        model: 'talked_about_education_plans_with_advisor',
      },
      {
        label: 'I know where to get support to understand my financial obligations for my education loan.',
        model: 'knows_where_to_get_support_for_education_loan_obligations',
      },
    ],
  },
  {
    title: 'Civic Engagement',
    model: 'civic_engagement',
    questions: [
      {
        label: 'I belong to a group at school, church, or community.',
        model: 'belongs_to_group_at_school_church_community',
      },
      {
        label: 'I volunteer in my free time or for a school requirement.',
        model: 'volunteers_in_free_time_or_for_school',
      },
      {
        label: 'I make decisions based on my social, political, and/or religious beliefs (e.g., where I buy products, who I support).',
        model: 'makes_decisions_based_on_social_political_religious_beliefs',
      },
      {
        label: 'I work with neighbors or other people to do something positive for the community.',
        model: 'works_with_others_for_community',
      },
      {
        label: 'I provide support to my neighbors and friends when they need it.',
        model: 'provides_support_to_neighbors_friends',
      },
      {
        label: 'I actively participate in advocating for social and political issues that are important.',
        model: 'actively_participates_in_advocating_for_social_political_issues',
      },
      {
        label: 'I know how to share my views on news and politics responsibly.',
        model: 'knows_how_to_share_views_on_news_politics_responsibly',
      },
      {
        label: 'I have taken steps to express my opinion to a public official.',
        model: 'has_taken_steps_to_express_opinion_to_public_official',
      },
      {
        label: 'I am registered or know how to register to vote.',
        model: 'is_registered_or_knows_how_to_register_to_vote',
      },
      {
        label: 'I know how to research information to improve my own understanding of a topic.',
        model: 'knows_how_to_research_information_to_improve_understanding',
      },
    ],
  },
  {
    title: 'Navigating the Child Welfare System',
    model: 'navigating_the_child_welfare_system',
    questions: [
      {
        label: 'I know my permanency goal (e.g. adoption, guardianship, independence, and return to home).',
        model: 'knows_permanency_goal',
      },
      {
        label: 'I know how and when I can see my child welfare or juvenile justice records.',
        model: 'knows_how_to_access_child_welfare_juvenile_justice_records',
      },
      {
        label: 'I know how to contact the State Foster Care Ombudsperson/Inspector General.',
        model: 'knows_how_to_contact_state_foster_care_ombudsperson_inspector_general',
      },
      {
        label: 'I know that I have a right to a lawyer while in foster care.',
        model: 'knows_right_to_lawyer_in_foster_care',
      },
      {
        label: 'I know how to get notified of my court hearings.',
        model: 'knows_how_to_get_notified_of_court_hearings',
      },
      {
        label: 'I know how to participate in court and share my opinion with my caseworker or probation officer.',
        model: 'knows_how_to_participate_in_court',
      },
      {
        label: 'I understand the legal and professional terms used in court and by my social worker or probation officer.',
        model: 'understands_legal_professional_terms_used_in_court',
      },
      {
        label: 'I have shared with a judge what my plans are after I have aged out of foster care.',
        model: 'shared_plans_with_judge_post_foster_care',
      },
      {
        label: 'I understand what a Court-appointed special advocate (CASA) or guardian ad litem (GAL) is.',
        model: 'understands_what_casa_gal_is',
      },
      {
        label: 'I understand if I am eligible for extended foster care in my state after I am 18 years.',
        model: 'understands_eligibility_for_extended_foster_care',
      },
    ],
  },
  {
    title: 'Looking Forward',
    model: 'looking_forward',
    questions: [
      {
        label: 'I have a positive outlook for my future.',
        model: 'has_positive_outlook_for_future',
      },
      {
        label: 'I can see myself being successful.',
        model: 'can_see_self_being_successful',
      },
      {
        label: 'I want to use my experience to mentor other youth with similar experiences.',
        model: 'wants_to_mentor_youth_with_similar_experiences',
      },
      {
        label: 'I believe I have a support system that will help me succeed.',
        model: 'believes_in_support_system_for_success',
      },
      {
        label: 'Most days I feel confident about how my life will turn out.',
        model: 'feels_confident_about_life_outcome',
      },
      {
        label: 'Most days I feel proud of the way I am living my life.',
        model: 'feels_proud_of_current_life',
      },
      {
        label: 'I am excited for the next phase of my life.',
        model: 'excited_for_next_life_phase',
      },
    ],
  },
  {
    title: 'Support Systems',
    model: 'support_systems',
    questions: [
      {
        label: 'I have supportive people in my life that I can spend time with on special occasions.',
        model: 'has_supportive_people_for_special_occasions',
      },
      {
        label: 'I know an adult who could be a grandparent, aunt, or uncle to my children now or my future children.',
        model: 'knows_adult_as_potential_family_figure',
      },
      {
        label: 'I know at least one person I can reach out to if I am not feeling well and need support.',
        model: 'knows_person_for_support_when_unwell',
      },
      {
        label: 'I know at least one trusting adult who is my medical emergency support.',
        model: 'knows_trusting_adult_for_medical_emergency_support',
      },
      {
        label: 'I know at least one trusting adult who would be legally allowed to make medical decisions for me if I am unable to.',
        model: 'knows_adult_for_legal_medical_decisions',
      },
      {
        label: 'I have at least one trusted adult other than my worker who I can contact in a time of an emergency.',
        model: 'has_trusted_adult_for_emergencies_besides_worker',
      },
      {
        label: 'I know a reliable person who I could live with for a few days or weeks if needed.',
        model: 'knows_reliable_person_for_temporary_housing',
      },
      {
        label: 'I know a reliable adult who will help me change schools if necessary.',
        model: 'knows_reliable_adult_for_school_change_assistance',
      },
      {
        label: 'I know someone knowledgeable who I can go to for financial advice.',
        model: 'knows_person_for_financial_advice',
      },
      {
        label: 'I know where I can get tutoring or other help with school work.',
        model: 'knows_where_to_get_tutoring_or_school_help',
      },
      {
        label: 'I know someone who will help me with my resume and job application when applying for a job.',
        model: 'knows_person_to_help_with_resume_and_job_application',
      },
      {
        label: 'I know where to find work-related internships.',
        model: 'knows_where_to_find_work_related_internships',
      },
      {
        label: 'I know where to get support to understand my financial obligations for my education loan.',
        model: 'knows_where_to_get_support_for_education_loan',
      },
      {
        label: 'I know people who can help me find a job.',
        model: 'knows_people_who_can_help_find_job',
      },
      {
        label: 'I know where I can get help to fill out my taxes.',
        model: 'knows_where_to_get_help_filling_taxes',
      },
      {
        label:
          'I know who to ask to get documents I need for work (e.g., social security card, birth certificate, state ID, or work permit).',
        model: 'knows_who_to_ask_for_work_documents',
      },
      {
        label: 'I have someone to go to if I have a financial emergency.',
        model: 'has_person_for_financial_emergency',
      },
    ],
  },
] as const

export const CLSOverlay = withOverlayError(RootCLSOverlay)
