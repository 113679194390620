import React from 'react'

import { FINANCIAL_STATUS, LOC } from '../../utils/constants'
import { titleCase } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import AlumniQuickView from '../../components/Overlays/quick/AlumniQuickView'
import ClientColorPicker from '../../components/ColorPickers/ClientColorPicker'
import Flex from '../../components/Flex'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const AlumniReportDataTable = (props: any) => {
  const { to } = props
  const { timezone, tenant, isBehave } = useSettings()

  const isEHR = tenant?.category === 'healthcare_facility' || tenant?.category === 'healthcare_practice'

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'name',
        model: 'data.name',
        width: 260,
        disableHide: true,
        // disableSort: true,
        disableSelect: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.data.id}
            value={data.data.name}
            avatar={data.data.avatar}
            to={to?.(data.data)}
            quickViewOverlay={<AlumniQuickView client={data.data} />}
          />
        ),
      },
      {
        title: 'Billed',
        id: 'is_billed_by_behave',
        model: 'data.is_billed_by_behave',
        type: 'boolean',
        permission: isBehave,
      },
      {
        title: 'Tags',
        id: 'tags',
        model: 'data.tags',
        type: 'tags',
        featureFlag: 'tags',
        editPermission: 'tags.edit',
        tagSmartCategories: 'past_clients',
      },
      {
        title: 'Color',
        id: 'color_code',
        model: 'data.color_code',
        formatValue: ({ data }: any) => {
          if (!data?.data?.color_code) return null

          return (
            <Flex centerX>
              <ClientColorPicker isDisabled clientID={data?.data?.id} color={data.data?.color_code} />
            </Flex>
          )
        },
      },
      {
        title: 'Location',
        id: 'current_house',
        model: 'processedCurrentOccupancy.house.name',
        type: 'profile',
        // config: {
        //   setLink: ({ rowData }: any) => rowData.house?.id && `/bed-management/today/${rowData?.house?.id}`,
        // },
      },
      {
        title: 'Room',
        id: 'current_room',
        model: 'processedCurrentOccupancy.room.name',
        disableSort: false,
      },
      {
        title: 'Client ID',
        id: 'behave_id',
        model: 'data.behave_id',
        formatValue: ({ value }: any) => (value ? `#${value}` : null),
      },
      {
        title: 'Total Charges',
        id: 'financial_charges_balance',
        model: 'data.financial.charges_balance',
        type: 'amount',
        width: 140,
        config: {
          valueColor: 'red',
          prefix: '-',
        },
      },
      {
        title: 'Total Payments',
        id: 'financial_total_payments',
        model: 'data.financial.total_payments',
        type: 'amount',
        width: 140,
        config: {
          valueColor: 'green',
          prefix: '+',
        },
      },
      {
        title: 'Balance',
        id: 'financial_balance',
        model: 'data.financial.balance',
        type: 'amount',
        width: 140,
        config: {
          positiveColor: 'green',
          negativeColor: 'red',
        },
      },
      {
        title: 'Last Payment',
        id: 'financial_last_payment_amount',
        model: 'data.financial.last_payment_amount',
        type: 'amount',
        width: 140,
        config: {
          valueColor: 'green',
          prefix: '+',
        },
      },
      {
        title: 'Last Payment On',
        id: 'financial_last_payment_transactioned_at',
        model: 'data.financial.last_payment_transactioned_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Last Charge On',
        id: 'financial_last_charge_transactioned_at',
        model: 'data.financial.last_charge_transactioned_at',
        type: 'date',
        width: 160,
      },
      {
        title: 'Financial Status',
        id: 'financial_status',
        model: 'data.financial_status',
        formatValue: ({ value }: any) => FINANCIAL_STATUS?.[value] || null,
      },
      {
        title: 'Financial Data Filter',
        id: 'financial_data_filter',
        model: 'data.financial_data_filter',
      },
      {
        title: 'Has Auto-Repeating Charges',
        id: 'has_auto_repeating_charges',
        model: 'data.financial.has_auto_repeating_charges',
        type: 'boolean',
      },
      {
        title: 'Cell Phone',
        id: 'phone_no',
        model: 'data.phone_no',
        type: 'phone',
      },
      {
        title: 'Email',
        id: 'email',
        model: 'data.email',
        type: 'email',
      },
      {
        title: 'Sex',
        id: 'sex',
        model: 'data.sex',
        width: 100,
        formatValue: ({ value }: any) => (value ? titleCase(value) : null),
      },
      {
        title: 'Date of Birth',
        id: 'dob',
        model: 'data.dob',
        type: 'date',
        width: 140,
      },
      {
        title: 'Level of Care',
        id: 'level_of_care',
        model: 'data.level_of_care',
        formatValue: ({ value }: any) => LOC?.[value] || null,
        permission: isEHR,
      },
      {
        title: 'Referral In',
        id: 'referral_source',
        // model: 'data.current_admission.referral_source',
        model: 'data.referral_source',
        type: 'profile',
      },
      {
        title: 'Referral In Contact',
        id: 'referral_in_contact',
        // model: 'data.current_admission.referral_in_contact',
        model: 'data.referral_in_contact',
        type: 'profile',
      },
      {
        title: 'Referral In Notes',
        id: 'referral_notes',
        // model: 'data.current_admission.referral_notes',
        model: 'data.referral_notes',
        type: 'rich_text',
      },
      {
        title: 'Referral Out',
        id: 'referral_out',
        // model: 'data.current_admission.referral_out',
        model: 'data.referral_out',
        type: 'profile',
      },
      {
        title: 'Referral Out Contact',
        id: 'referral_out_contact',
        // model: 'data.current_admission.referral_out_contact',
        model: 'data.referral_out_contact',
        type: 'profile',
      },
      {
        title: 'Referral Out Notes',
        id: 'referral_out_notes',
        // model: 'data.current_admission.referral_out_notes',
        model: 'data.referral_out_notes',
        type: 'rich_text',
      },
      {
        title: 'First Contact On',
        id: 'first_contact_at',
        model: 'data.first_contact_at',
        type: 'date_time',
      },
      {
        title: 'Last Contacted On',
        id: 'last_contact_at',
        model: 'data.last_contact_at',
        type: 'date_time',
      },
      {
        title: 'Last Contacted By',
        id: 'last_contact_by',
        model: 'data.last_contact_by',
        type: 'profile',
      },
      {
        title: 'Applied On',
        id: 'intake_application_applied_at',
        model: 'data.current_intake_application.applied_at',
        type: 'date_time',
      },
      {
        title: 'Accepted On',
        id: 'admission_accepted_at',
        model: 'data.current_admission.accepted_at',
        type: 'date_time',
      },
      {
        title: 'Admitted On',
        id: 'admission_admitted_at',
        model: 'data.current_admission.admitted_at',
        type: 'date_time',
      },
      {
        title: 'Length of Stay',
        id: 'length_of_stay_days',
        model: 'data.length_of_stay_days',
        formatValue: ({ value }: any) => (value ? `${value} days` : null),
        disableSort: true,
      },
      {
        title: 'Est. Length of Stay',
        id: 'estimated_length_of_stay_days',
        model: 'data.estimated_length_of_stay_days',
        formatValue: ({ value }: any) => (value ? `${value} days` : null),
        disableSort: true,
      },
      {
        title: 'Admitted By',
        id: 'admission_admitted_by',
        model: 'data.current_admission.admitted_by',
        type: 'profile',
      },
      {
        title: 'Discharge Date',
        id: 'admission_discharged_at',
        model: 'data.current_admission.discharged_at',
        type: 'date_time',
      },
      {
        title: 'Discharge Type',
        id: 'admission_discharge_type',
        model: 'data.current_admission.discharge_type',
        formatValue: ({ value }: any) => (value ? titleCase(value) : null),
      },
      {
        title: 'Aftercare Plan',
        id: 'aftercare_plan',
        model: 'data.aftercare_plan',
      },
      {
        title: 'Return Eligibility',
        id: 'admission_return_eligibility',
        model: 'data.current_admission.return_eligibility',
        formatValue: ({ value }: any) => (value ? titleCase(value) : null),
      },
      {
        title: 'Discharge Notes',
        id: 'admission_discharge_notes',
        model: 'data.current_admission.discharge_notes',
        formatValue: ({ value }: any) => (value ? titleCase(value) : null),
      },
      {
        title: 'Sobriety Date',
        id: 'sobriety_date',
        model: 'data.sobriety_date',
        type: 'date',
        width: 140,
      },
      {
        title: 'Sober For',
        id: 'sober_for_days',
        model: 'data.sober_for_days',
        formatValue: ({ value }: any) => (value ? `${value} days` : null),
        width: 120,
        disableSort: false,
      },
      {
        title: 'Using Portal',
        id: 'client_portal_status',
        model: 'data.client_portal_status',
        width: 120,
        formatValue: ({ data }: any) => {
          const value = data.data.client_portal_status

          if (value === 'access') return 'Yes'
          else if (value === 'invited') return 'Invited'
          else return 'No'
        },
      },
      {
        title: 'Last Name',
        id: 'last_name',
        model: 'data.last_name',
      },
      {
        title: 'Last UA Test At',
        id: 'last_ua_test_at',
        model: 'data.last_ua_test_at',
        type: 'date_time',
      },
    ]
  }, [to, isEHR, isBehave])

  const processRow = React.useCallback(
    (row) => {
      return produce(row, (draft) => {
        const now = DateTime.local().setZone(timezone)
        const occupanciesCount = size(row.data.ongoing_bed_occupancies)

        if (occupanciesCount === 0) return
        if (occupanciesCount === 1) row.data.ongoing_bed_occupancies[0]

        for (const occupancy of row.data.ongoing_bed_occupancies) {
          const startDT = DT(occupancy.started_at, timezone)
          const endDT = DT(occupancy.ended_at, timezone)

          const diffFromStart = now.diff(startDT, 'seconds').seconds
          const diffFromEnd = now.diff(endDT, 'seconds').seconds

          if (diffFromStart > 0 && diffFromEnd < 0) {
            draft.processedCurrentOccupancy = occupancy
            break
          }
        }
      })
    },
    [timezone],
  )

  return (
    <DataTable
      testKey="past_clients_report_data_table"
      asCard
      title="Past Clients"
      icon="alumni_relations"
      columns={columns}
      filtersConfig={FILTERS.live_reports.past_clients}
      {...props}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="alumni"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=alumni'}
        />
      }
    />
  )
}
