import React from 'react'
import { darken, tint, transparentize, saturate } from 'polished'
import clsx from 'clsx'

import { COLORS, SHADOW, GLYPHS } from '../theme'

import Avatar from './Avatar'
import Emoji from './Emoji'
import Glyph from './Glyph'
import Icon from './Icon'
import Loader from './Loader'
import Link from './Link'
import Flex from './Flex'

import withPermissions from '../hocs/withPermissions'
import { Icon as IconType, Color, Glyph as GlyphType } from '../declarations/types'

type Props = {
  after: React.ReactNode
  avatar: string
  avatarSize: number | string
  avatarInitials: string
  before: React.ReactNode
  buttonType: string
  checkEditable: boolean
  color: Color
  defaultTextColor: Color
  download: string
  emoji: string
  emojiSize: string | number
  form: any
  glyph: GlyphType
  glyphColor: Color
  glyphSize: string | number
  hideLabel: boolean
  href: string
  icon: IconType
  iconSize: string | number
  is: string
  isDisabled: boolean
  onDisabledClick: Function
  isEditable: boolean
  isLoading: boolean
  label: string
  link: string
  nowrap: boolean
  onClick: Function
  size: string | number
  target: string
  type: string
  useGlyphForTarget: boolean
  borderRadius: number
  testKey: string
} & React.HTMLAttributes<HTMLButtonElement>

export const Button: React.FC<Props> = (props) => {
  const {
    is = 'div',
    avatarSize = 20,
    buttonType = 'button',
    emojiSize = 18,
    color = 'blue',
    glyphSize = 16,
    hideLabel = false,
    iconSize = 20,
    isEditable = true,
    nowrap = true,
    size = 300,
    type = 'default',
    useGlyphForTarget = true,
    after,
    avatar,
    avatarInitials,
    before,
    checkEditable,
    className,
    testKey,
    defaultTextColor,
    download,
    emoji,
    form,
    glyph,
    glyphColor,
    href,
    icon,
    isDisabled,
    onDisabledClick,
    isLoading,
    label,
    link,
    onClick,
    target,
    rounded,
    state,
    onDoubleClick,
  } = props

  if (checkEditable && !isEditable) return null

  const buttonClick = async (event) => {
    if (isDisabled) {
      event.preventDefault()
      if (onDisabledClick) onDisabledClick()
      return
    }
    if (isLoading) return event.preventDefault()
    else if (onClick) await onClick(event)
  }

  const accentColor = COLORS[color]
  const textColor = defaultTextColor ? COLORS.text : darken(0.03, accentColor)
  const showLabel = !hideLabel

  const classNames = clsx({
    'is-editable': isEditable,
    'is-loading': isLoading,
    'is-disabled': isDisabled,
    'is-rounded': rounded,
    'hide-label': hideLabel,
    [className]: className,
  })

  let Tag = props.as || (href ? 'a' : link ? Link : is)

  return (
    <Tag
      {...(Tag.prototype?.isReactComponent ? { testKey: testKey } : { 'data-test': testKey })}
      onClick={buttonClick}
      onDoubleClick={onDoubleClick}
      to={isDisabled ? undefined : link}
      href={isDisabled ? undefined : href}
      target={target}
      download={download}
      className={classNames}
      state={state}
      {...(is === 'button' && { type: buttonType })}
      css={[styles, dynamicStyles(props, textColor), buttonStyles({ accentColor, glyphColor })[type], sizeStyles[size]]}
    >
      {isLoading && (
        <div css={loaderStyles}>
          <Loader color={type === 'primary' ? 'white' : accentColor} />
        </div>
      )}

      <Flex horizontal centerX centerY gap={8} nowrap={nowrap}>
        {before}
        {avatar !== undefined && <Avatar src={avatar} initials={avatarInitials || label} size={avatarSize} magnify />}
        {glyph && <Glyph glyph={GLYPHS[glyph]} color={glyphColor} size={glyphSize} />}
        {emoji && <Emoji emoji={emoji} size={emojiSize} />}
        {icon && <Icon icon={icon} size={iconSize} />}
        {showLabel && <span className="button-label grow">{label}</span>}
        {target === '_blank' && useGlyphForTarget && <Glyph glyph="external_link" size={15} />}
        {after}
      </Flex>
    </Tag>
  )
}

const dynamicStyles = (props, textColor) => ({
  flex: props.flex && `${props.flex} !important`,

  alignSelf: props.alignSelf,
  display: props.display,

  color: textColor,
  justifyContent: props.justifyContent,

  width: props.fullWidth && '100%',
  marginRight: props.offsetRight && '-1.2rem',

  whiteSpace: props.nowrap ? 'nowrap' : 'normal',
  borderRadius: props.borderRadius,
})

const buttonStyles = ({ accentColor, glyphColor }) => ({
  // DEFAULT
  default: {
    background: 'white',
    boxShadow: `
			inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
			${SHADOW(1, transparentize(0.95, accentColor))}
		`,

    '&:hover': {
      boxShadow: `
				inset 0 0 0 1px ${tint(0.85, accentColor)},
				${SHADOW(3, transparentize(0.97, accentColor))}
			`,
      ...hoverStyles,

      svg: {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },

    svg: {
      fill: glyphColor || accentColor,
      ...svgTransition,
    },
  },

  // LINK
  link: {
    '&:hover': {
      textDecoration: 'underline',
      ...hoverStyles,

      svg: {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },

    svg: {
      fill: glyphColor || accentColor,
      ...svgTransition,
    },
  },

  // PRIMARY
  primary: {
    color: 'white',
    background: accentColor,
    boxShadow: `
			inset 0 0 0 1px ${darken(0.02, accentColor)},
			${SHADOW(2, transparentize(0.85, accentColor))}
		`,
    textShadow: `0 1px 3px ${darken(0.05, accentColor)}`,

    '&:hover': {
      background: tint(0.05, accentColor),
      boxShadow: `
				inset 0 0 0 1px ${darken(0.02, accentColor)},
				${SHADOW(3, transparentize(0.9, accentColor))}
			`,
      ...hoverStyles,

      svg: {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },

    svg: {
      fill: 'white !important',
      ...svgTransition,
    },
  },

  // MINIMAL
  minimal: {
    transform: 'none !important',

    '&:hover': {
      background: saturate(0.2, tint(0.99, accentColor)),
      boxShadow: `
				inset 0 0 0 1px ${transparentize(0.86, accentColor)},
				${SHADOW(2, transparentize(0.94, accentColor))}
			`,
      ...hoverStyles,

      svg: {
        transform: 'scale3d(1.1, 1.1, 1.1)',
      },
    },

    svg: {
      fill: glyphColor || accentColor,
      ...svgTransition,
    },
  },
})

const styles = {
  alignItems: 'center',
  textDecoration: 'none !important',
  userSelect: 'none',
  position: 'relative',
  padding: '0.5em 1.2em',
  transition: `
    box-shadow 120ms cubic-bezier(0.39, 0.575, 0.565, 1),
    background 60ms cubic-bezier(0.39, 0.575, 0.565, 1),
    transform 120ms cubic-bezier(0.39, 0.575, 0.565, 1)
  `,

  // '&:active': {
  //   transform: 'translateY(0)',
  // },

  '&:focus': {
    outline: 'none',
  },

  '&.is-rounded': {
    borderRadius: 100,
  },

  '&.is-loading': {
    color: `${COLORS.transparent} !important`,
    textShadow: `none !important`,
    cursor: `default !important`,
    opacity: `0.75 !important`,

    'svg, img': {
      opacity: 0,
    },
  },

  '&.is-disabled': {
    opacity: 0.4,
    cursor: 'not-allowed !important',
    transform: 'none !important',
  },

  '&.is-compact, &.is-compact:hover': {
    boxShadow: 'none',
    border: 0,
    padding: 0,
    minWidth: 0,
    minHeight: 25,
  },
}

const loaderStyles = {
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate3d(-50%,-50%,0)',
}

const sizeStyles = {
  100: {
    fontSize: '0.85rem',
    fontWeight: 500,
    padding: '0.3em 0.75em',
    minWidth: 22,
    minHeight: 22,

    'svg, img': {
      maxWidth: '12px !important',
      maxHeight: '12px !important',
      marginRight: '0 !important',
    },

    '&.hide-label': {
      'svg, img': {
        margin: '0 !important',
      },
    },

    // increase hit area
    '&::before': {
      content: '""',
      width: '100%',
      height: 40,
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
  },
  200: {
    fontSize: '0.9rem',
    fontWeight: 600,
    padding: '0.3em 0.75em',
    minWidth: 28,
    minHeight: 28,

    '&.hide-label': {
      'svg, img': {
        margin: '0 !important',
      },
    },

    // increase hit area
    '&::before': {
      content: '""',
      width: '100%',
      height: 40,
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translateY(-50%)',
    },
  },
  300: {
    fontSize: '0.96rem',
    fontWeight: 600,
    padding: '0.4em 1em',
    minWidth: 36,
    minHeight: 36,
  },
  400: {
    fontSize: '1.04rem',
    fontWeight: 600,
    minWidth: 40,
    minHeight: 40,
  },
  500: {
    fontSize: '1.08rem',
    fontWeight: 600,
    minWidth: 44,
    minHeight: 44,
  },
}

const svgTransition = {
  transition: 'transform 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
}

const hoverStyles = {
  cursor: 'pointer',
  // transform: 'translateY(-1px)',
}

Button.defaultProps = {
  borderRadius: 5,
  justifyContent: 'center',
  alignSelf: 'auto',
  display: 'flex',
}

export default withPermissions(Button)
