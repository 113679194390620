import React from 'react'
import { useMedia } from 'use-media'

import { COLORS, SHADOW, BREAKPOINT } from '../theme'

import { Client } from '../declarations/Client'
import { Tenant } from '../declarations/Tenant'

import Card from './Card'
import CardContent from './CardContent'
import CardHeader from './CardHeader'
import CardTitle from './CardTitle'
import Dropdown from './Dropdown'
import DropdownItem from './DropdownItem'
import Flex from './Flex'
import Graphic from './Graphic'

import { ClientFlowStep } from './Cards'

const NAMES = {
  admission: {
    name: 'Admission',
    icon: 'applications',
  },
  current: {
    name: 'Client',
    icon: 'clients',
  },
  alumni: {
    name: 'Past Client',
    icon: 'alumni_relations',
  },
}

type Props = {
  client: Client
  tenant: Tenant
}

const ClientFlow: React.FC<Props> = ({ client, tenant }) => {
  let defaultTab = 'admission'

  if (client?.status === 'current') defaultTab = 'current'
  if (client?.status === 'alumni') defaultTab = 'alumni'

  const [activeTab, setActiveTab] = React.useState(defaultTab)
  // const [activeDescription, setActiveDescription] = React.useState(null)
  const isSL = tenant?.category === 'support_facility'
  const mq = useMedia({ minWidth: BREAKPOINT[2] })

  return (
    <Card>
      <CardHeader>
        <CardTitle title="Client Journey" />
      </CardHeader>

      <CardContent className="!p-0">
        <div css={styles}>
          {mq ? (
            <Flex horizontal alignItems="stretch" className="Tabs">
              <div className={`Tab ${activeTab === 'admission' && 'active'}`} onClick={() => setActiveTab('admission')}>
                <Graphic icon="applications" className="Graphic" />
                <span>Admission</span>
                {(client?.status === 'current' || client?.status === 'alumni') && <Graphic glyph="circle_tick" className="Graphic" />}
              </div>
              <div className={`Tab ${activeTab === 'current' && 'active'}`} onClick={() => setActiveTab('current')}>
                <Graphic icon="clients" className="Graphic" />
                <span>Client</span>
                {client?.status === 'alumni' && <Graphic glyph="circle_tick" className="Graphic" />}
              </div>
              <div className={`Tab ${activeTab === 'alumni' && 'active'}`} onClick={() => setActiveTab('alumni')}>
                <Graphic icon="alumni_relations" className="Graphic" />
                <span>Past Client</span>
              </div>
            </Flex>
          ) : (
            <div css={{ margin: 10 }}>
              <Dropdown label={NAMES[activeTab].name} icon={NAMES[activeTab].icon} position="bottom" css={{ margin: 10 }}>
                <DropdownItem label="Admission" icon="applications" onClick={() => setActiveTab('admission')} />
                <DropdownItem label="Client" icon="clients" onClick={() => setActiveTab('current')} />
                <DropdownItem label="Past Client" icon="alumni_relations" onClick={() => setActiveTab('alumni')} />
              </Dropdown>
            </div>
          )}

          <div className="Content">
            <div className={`Section ${activeTab === 'admission' && 'active'}`}>
              <ClientFlowStep
                to="applications"
                step={{
                  status: client?.dashboard?.admission?.application,
                  title: 'Application',
                  description: `${client?.first_name} submitted an application`,
                }}
              />

              {/* {!isSL && (
              <ClientFlowStep
                to="vob"
                step={{
                  title: 'VOB',
                  status: 'dev',
                  description: 'Behave Health to obtain the VOB (feature in development)',
                }}
              />
            )} */}

              {/* {!isSL && (
              <ClientFlowStep
                to="treatment-episodes"
                step={{
                  status: client?.dashboard?.admission?.authorization,
                  title: 'Initial Authorization',
                  description: 'Behave Health to obtain the pre-authorization',
                }}
              />
            )} */}

              <ClientFlowStep
                step={{
                  title: 'Accept or Decline',
                  status: client?.dashboard?.admission?.intake,
                  description: `Accept or Decline ${client?.first_name}`,
                }}
              />

              {/* <ClientFlowStep
                to="treatment-episodes"
                step={{
                  title: 'Transportation & Logistics',
                  status: client?.dashboard?.admission?.logistics,
                }}
              />
              <ClientFlowStep
                step={{ title: 'Administration', status: client?.dashboard?.admission?.admin }}
              /> */}

              <ClientFlowStep
                to="agreements"
                step={{
                  title: 'Agreements',
                  status: client?.dashboard?.admission?.agreements,
                  description: 'Complete the Intake Forms',
                }}
              />

              {!isSL && (
                <ClientFlowStep
                  to="clinical-assessments"
                  step={{
                    title: 'Clinical Documentation',
                    status: client?.dashboard?.admission?.documentation,
                    description: 'Complete the initial Documentation required for Intake',
                  }}
                />
              )}

              <ClientFlowStep
                step={{
                  title: 'Admit or Close',
                  status: client?.dashboard?.admission?.move_in,
                  description: 'Admit Client or close the started Treatment Episode',
                }}
              />
            </div>

            <div className={`Section ${activeTab === 'current' && 'active'}`}>
              {!isSL && (
                <ClientFlowStep
                  to="treatment-episodes"
                  step={{
                    title: 'Authorizations',
                    status: client?.dashboard?.client?.authorizations,
                    description: `Recurring Authorizations for ${client?.first_name}`,
                  }}
                />
              )}

              <ClientFlowStep
                to="treatment-episodes"
                step={{
                  title: 'Discharge',
                  status: client?.dashboard?.client?.discharge,
                  description: `Discharge ${client?.first_name}`,
                }}
              />
            </div>

            <div className={`Section ${activeTab === 'alumni' && 'active'}`}>
              <ClientFlowStep
                step={{
                  title: 'Outcome Tracking',
                  status: 'dev',
                  description: `Track the outcome results for ${client?.first_name}`,
                }}
              />
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

const styles: Record<string, any> = {
  '.Tabs': {
    overflow: 'hidden',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  '.Tab': {
    flex: '1 1 auto',
    textAlign: 'center',
    padding: '1em',
    position: 'relative',
    // background: transparentize(0.15, COLORS.divider),
    background: '#f7f8fa',
    fontWeight: 500,
    cursor: 'pointer',

    '&:hover': {
      background: 'white',

      '&::before': {
        background: 'white',
      },

      '&::after': {
        background: 'white',
      },
    },

    '&.active': {
      background: 'white',
      fontWeight: 600,

      '&::before': {
        background: 'white',
      },

      '&::after': {
        background: 'white',
      },
    },

    '&::before': {
      content: '""',
      background: '#f7f8fa',
      display: 'block',
      position: 'absolute',
      top: -11,
      left: -7,
      width: 50,
      height: '55%',

      borderLeft: `1px solid ${COLORS.divider}`,

      transform: 'rotate(-25deg)',
    },

    '&::after': {
      content: '""',
      background: '#f7f8fa',
      display: 'block',
      position: 'absolute',
      bottom: -12,
      left: -7,
      width: 50,
      height: '55%',

      borderLeft: `1px solid ${COLORS.divider}`,

      transform: 'rotate(25deg)',
    },
    '&:first-child': {
      '&::before': { display: 'none' },
      '&::after': { display: 'none' },
    },

    '.Graphic': {
      display: 'inline-block',
      marginLeft: 5,
    },

    span: {
      marginLeft: '0.5em',
      verticalAlign: 'middle',
    },
  },

  '.Content': {
    // marginTop: 1,
    // padding: '0 1em 1em 1em',
  },

  '.Section': {
    display: 'none',

    '&.active': {
      display: 'block',
    },

    '.Descriptions': {
      paddingTop: '1em',
    },

    '.Description': {
      display: 'none',

      '&.active': {
        display: 'block',
      },
    },
  },

  '.Step': {
    textAlign: 'center',
    position: 'relative',
    marginRight: '1em',
    marginTop: '1em',
    border: `1px solid ${COLORS.divider}`,
    borderRadius: 7,
    minWidth: 200,
    padding: '0.75em',

    boxShadow: SHADOW(2),

    ':hover': {
      background: COLORS.hover,
    },

    '&.connect-right::after': {
      content: '""',
      background: COLORS.divider,
      display: 'block',
      position: 'absolute',
      top: '50%',
      marginTop: -1,
      right: -9,
      width: 9,
      height: 1,
    },

    '&.connect-left::before': {
      content: '""',
      background: COLORS.divider,
      display: 'block',
      position: 'absolute',
      top: '50%',
      marginTop: -1,
      left: -8,
      width: 8,
      height: 1,
    },

    '&.completed': {
      background: COLORS.mintGreen,
      border: `1px solid ${COLORS.green}`,
      color: COLORS.white,
    },

    '.Status': {
      background: COLORS.hover,
      display: 'block',
      textAlign: 'center',
      width: 32,
      height: 32,
      borderRadius: '50%',
    },
  },
}

ClientFlow.defaultProps = {
  size: '20px',
  fontSize: '14px',
  background: '#006AFF',
  color: '#fff',
}

export default ClientFlow
