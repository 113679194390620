import React from 'react'
import size from 'lodash/size'
import { useRouteMatch } from 'react-router-dom'

import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { ClientCustomNotesImportOverlay } from '@behavehealth/constructs/CustomNotes/ClientCustomNotesImportOverlay'
import { ClientTrackTabs } from '@behavehealth/constructs/ClientTracks/ClientTrackTabs'
import { ClientCustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/ClientCustomNotesDataTable'
import { State, Button, Grid, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import Divider from '@behavehealth/components/Divider'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

const AddDropdown = ({ client, clientTrack }: any) => {
  const match = useRouteMatch()

  const [clientTrackId, setClientTrackId] = React.useState(clientTrack?.id)
  const [importOverlayOpen, setImportOverlayOpen] = React.useState(false)

  const params = { variant: 'client', client_track_id: clientTrackId }

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', params],
    url: `/custom_note_templates`,
    params: params,
  })

  React.useEffect(() => {
    if (!clientTrack?.id) return

    setClientTrackId(clientTrack?.id)
  }, [clientTrack?.id])

  return (
    <>
      <Dropdown label="Add Custom Note…" glyph="add" buttonType="primary" buttonSize={300} permission="client_custom_notes.create">
        <DropdownItem
          label="Empty Custom Note"
          glyph="add"
          color="paleBlue"
          link={{
            pathname: `${match.url}/new`,
            parent: match,
            data: {
              name: 'New Custom Note',
            },
          }}
        />

        {size(templates) > 0 && (
          <DropdownItem glyph="documents" label="Import Multiple Custom Notes…" onClick={() => setImportOverlayOpen(true)} color="blue" />
        )}

        <Divider />

        <ClientTrackTabs clientTrack={clientTrack} activeId={clientTrackId} setActiveId={setClientTrackId} />

        {isLoadingTemplates ? (
          <State isLoading minHeight={100} />
        ) : (
          size(templates) > 0 && (
            <>
              {mapToArray(templates).map((template) => (
                <DropdownItem
                  key={template.id}
                  label={template.name}
                  icon="custom_notes"
                  color="paleBlue"
                  link={{
                    pathname: `${match.url}/new`,
                    parent: match,
                    data: template,
                  }}
                />
              ))}

              <Divider />
            </>
          )
        )}
      </Dropdown>

      {client?.id && (
        <SummonOverlay
          isOpen={importOverlayOpen}
          onClose={() => setImportOverlayOpen(false)}
          overlay={<ClientCustomNotesImportOverlay referenceId={client.id} referenceType={client.type} />}
        />
      )}
    </>
  )
}

const pageConfig = {
  feature: 'client_custom_notes',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const CustomNotes: React.FC<Props> = () => {
  const match = useRouteMatch()
  const id = match.params?.resource_id

  const tableProps = useDataTable({
    name: ['client', id, 'custom_notes'],
    endpoint: `/residents/${id}/custom_notes`,
    params: { variant: 'client' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'client_custom_notes',
  })

  const { data: client }: any = useGet({
    name: ['client', id],
    url: `/residents/${id}`,
  })

  // const isTemplatesEmpty = size(templates) === 0

  // if (!isLoadingTemplates && isTemplatesEmpty) {
  //   return (
  //     <>
  //       <Page
  //         isEmpty={true}
  //         emptyDescription="No custom note templates have been added yet. Go to Settings to create the first template."
  //         actions={
  //           <Button
  //             size={300}
  //             label="Manage Templates"
  //             glyph="settings"
  //             type="primary"
  //             link="/settings/custom-note-templates/clients"
  //             permission="settings.client_custom_note_templates.view"
  //           />
  //         }
  //         {...pageConfig}
  //       />
  //     </>
  //   )
  // }

  return (
    <Page
      plan="basic"
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/custom-note-templates/clients"
            permission="settings.client_custom_note_templates.view"
          />

          <AddDropdown client={client} clientTrack={client?.client_track} />
        </>
      }
      {...pageConfig}
    >
      <Grid>
        <ClientCustomNotesDataTable
          {...tableProps}
          to={(data: any) => ({
            pathname: `${match.url}/${data.id}`,
            parent: match,
          })}
          duplicatePermission="client_custom_notes.create"
          duplicateLink={(record: any) => {
            const {
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              portal_settings,
              house,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            } = record

            return {
              pathname: `${match.url}/new`,
              parent: match,
              data: {
                status: 'draft',
                client,
                employees,
                ended_at,
                name,
                notes,
                office,
                organization,
                organization_id: organization?.id,
                portal_settings,
                house,
                house_id: house?.id,
                reference,
                sections,
                settings,
                started_at,
                supervisor,
                tags,
                variant,
              },
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'client_custom_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CustomNotes, pageConfig))
