import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'

export const ClientCommunicationsDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Contact Name',
        model: 'contact.name',
        width: 260,
        disableSort: true,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value} to={to?.(data)} />,
      },
      {
        title: 'Contacted By',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Contact Method',
        model: 'category',
        type: 'title',
      },
      {
        title: 'Notes',
        model: 'reason',
        width: 280,
      },
      {
        title: 'Date Contacted',
        model: 'contacted_at',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="Communications"
      icon="communications"
      testKey="client_communications_data_table"
      columns={columns}
      filtersConfig={FILTERS.client_communications}
      {...rest}
    />
  )
}
