import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import EmployeeStatus from '../../../Statuses/EmployeeStatus'

import { FILTERS } from '../../../../constructs/Filters/config'

export const EmployeesTable = ({ onSelect, dependentValue, includeColumns, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-clients-current'],
    endpoint: '/employees',
    params: {
      status: 'active',
    },
    ...(dependentValue && {
      headers: {
        'X-Tenant': dependentValue,
      },
    }),
    localStorageKey: 'selector_employees_active_v1',
  })

  const defaultColumns = React.useMemo(
    () => [
      {
        title: 'Name',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            testKey="main_cell"
            id={data.id}
            value={data.name}
            avatar={data.avatar}
            onClick={onSelect ? () => onSelect(data) : undefined}
          />
        ),
      },
      {
        width: 160,
        id: 'status',
        model: 'status',
        title: 'Status',
        formatValue: ({ data, value }: any) => <EmployeeStatus status={value} />,
      },
      {
        width: 160,
        id: 'position',
        model: 'position',
        title: 'Role',
        formatValue: ({ data, value }: any) => startCase(value),
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'phone_no',
        title: 'Cell Phone',
      },
      {
        width: 160,
        id: 'email',
        model: 'email',
        title: 'Email',
      },
      {
        width: 180,
        id: 'is_shared_with_portal',
        model: 'is_shared_with_portal',
        title: 'Client Portal Sharing',
      },
      {
        width: 150,
        id: 'is_blocked',
        model: 'is_blocked',
        title: 'Is Blocked',
      },
      {
        width: 190,
        id: 'last_logged_in_at',
        model: 'last_logged_in_at',
        title: 'Last Logged in At',
        type: 'date_time',
      },
      {
        width: 140,
        id: 'last_name',
        model: 'last_name',
        title: 'Last Name',
      },
    ],
    [],
  )

  const columns = React.useMemo(() => {
    if (!includeColumns) return defaultColumns

    return defaultColumns.filter((column) => {
      return includeColumns.includes(column.id) || includeColumns.includes(column.model)
    })
  }, [includeColumns])

  return (
    <DataTable
      {...tableProps}
      {...rest}
      icon="employees"
      title="Staff Members"
      columns={columns}
      filtersConfig={FILTERS.employees}
      searchBarFilterKey="name"
    />
  )
}
