import React from 'react'

import { MEDICATION_TIMES } from '../../utils/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Button from '../../components/Button'
import Flex from '../../components/Flex'
import MedicationStatus from '../../components/Statuses/MedicationStatus'

import { FILTERS } from '../Filters/config'

const SOURCE: any = {
  external_during: 'External Provider (during treatment)',
  external_prior: 'External Provider (prior to treatment)',
  internal: 'Current Facility',
  private_provider: 'Private Providers',
}

export const MedicationsDataTable = (props: any) => {
  const { to, onClick, logLink, logDestructionLink } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Medication',
        id: 'name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell
            as={props.mainLinkAs}
            id={data.id}
            value={data.name}
            to={to?.(data)}
            onClick={onClick ? () => onClick(data) : undefined}
          />
        ),
      },
      {
        title: 'Status',
        id: 'status',
        model: 'status',
        formatValue: ({ value }: any) => <MedicationStatus status={value} />,
      },
      {
        title: 'Start Date',
        id: 'started_at',
        model: 'started_at',
        type: 'date',
      },
      {
        title: 'End Date',
        id: 'ended_at',
        model: 'ended_at',
        type: 'date',
      },
      {
        title: 'Client',
        id: 'resident',
        model: 'resident',
        type: 'profile',
      },
      {
        title: 'Medication Time',
        id: 'medication_time',
        model: 'medication_time',
        formatValue: ({ value }: any) => {
          if (!value) return null

          const checkedResults = []

          for (const key in value) {
            if (value[key]) {
              checkedResults.push(MEDICATION_TIMES[key])
            }
          }

          return checkedResults.join(', ')
        },
      },
      {
        title: 'Dosage',
        id: 'dosage',
        model: 'dosage',
        width: 120,
      },
      {
        title: 'Unit',
        id: 'unit',
        model: 'unit',
        width: 120,
      },
      {
        title: 'Quantity',
        id: 'quantity',
        model: 'quantity',
        width: 120,
      },
      {
        title: 'Standard Dose Quantity',
        model: 'default_quantity',
        width: 190,
      },
      {
        title: 'Source',
        id: 'source',
        model: 'source',
        formatValue: ({ value }: any) => SOURCE?.[value],
      },
      {
        title: 'Prescription #',
        model: 'prescription_no',
        width: 140,
      },
      {
        title: 'Prescribing Physician',
        model: 'prescription_by',
        width: 180,
      },
      {
        title: 'Instructions / Notes',
        id: 'notes',
        model: 'notes',
      },
      {
        title: 'Refill Notes',
        id: 'refill',
        model: 'refill',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
      {
        title: 'Created At',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated At',
        model: 'updated_at',
        type: 'date_time',
      },
      ...(logLink || logDestructionLink
        ? [
            {
              width: 340,
              model: 'id',
              id: 'table_actions',
              title: 'Log Medication',
              formatValue: ({ data }) => {
                return (
                  <Flex gap="0.5rem">
                    <Button label="Log Medication" glyph="add" size={100} link={logLink(data)} permission="medication_logs.create" />

                    <Button
                      label="Log Destruction"
                      glyph="add"
                      size={100}
                      link={logDestructionLink(data)}
                      // permission="medication_destruction_logs.create"
                    />
                  </Flex>
                )
              },
            },
          ]
        : []),
    ]
  }, [to, logLink, logDestructionLink])

  return <DataTable asCard title="Medications" icon="medications" columns={columns} filtersConfig={FILTERS.meds} {...props} />
}
