import React from 'react'

import CheckboxGroup from '../CheckboxGroup'
import Checkbox from '../Checkbox'

const EducationCheckboxGroup = ({
  label = 'Education',
  model = 'education',
  isRequired,
  validateOn = 'blur-change',
  trueIcon = 'check',
  falseStyle = 'hidden',
  falseIcon = 'cross',
}: any) => (
  <CheckboxGroup
    allowEmpty
    label={label}
    layout="vertical-dense"
    trueIcon={trueIcon}
    falseStyle={falseStyle}
    falseIcon={falseIcon}
    validations={
      isRequired && {
        presence: {
          message: 'Please select your completed Education Levels',
        },
      }
    }
  >
    <Checkbox label="Never attended school or only attended kindergarten" model={`${model}.kindergarten_or_not_attended`} />
    <Checkbox label="Grades 1 through 4 (Primary)" model={`${model}.primary_school`} />
    <Checkbox label="Grades 5 through 8 (Middle school)" model={`${model}.middle_school`} />
    <Checkbox label="Grades 9 through 11 (Some high school)" model={`${model}.some_high_school`} />
    <Checkbox label="Grade 12 or GED (High school graduate)" model={`${model}.high_school_graduate`} />
    <Checkbox
      label="1 to 3 years after high school (Some college, Associate's degree, or technical school)"
      model={`${model}.some_college`}
    />
    <Checkbox label="College 4 years or more (College graduate)" model={`${model}.college_graduate`} />
    <Checkbox label="Advanced degree (Master's, Doctorate, etc.)" model={`${model}.advanced_degree`} />
    <Checkbox label="Prefer Not to Answer" model={`${model}.prefer_not_to_say`} />
  </CheckboxGroup>
)

export default EducationCheckboxGroup
