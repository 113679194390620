import React from 'react'

import { daysBetween, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { Heading } from '../../components/Typography'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextHide from '../../components/Forms/ContextHide'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import FormSection from '../../components/Forms/FormSection'
import Grid from '../../components/Grid'
import LevelOfCareSelect from '../../components/Forms/LevelOfCareSelect'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Section from '../../components/Section'
import Switch from '../../components/Forms/Switch'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'
import Input from '../../components/Forms/Input'
import AmountInput from '../../components/Forms/AmountInput'
import FormCard from '../../components/Forms/FormCard'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'
import YesNoRadioGroupWithTextarea from '../../components/Forms/elements/YesNoRadioGroupWithTextarea'
import ContactInputs from '../../components/Forms/elements/ContactInputs'

import Contacts from '../../components/SmartContent/Contacts'
import Medications from '../../components/SmartContent/Medications'
import SNAP from '../../components/Overlays/pages/DataForms/common/SNAP'

import { DataFormOverlay } from '../DataFormOverlay'

const RootDischargeSummaryOverlay = (props: any) => {
  const { timezone }: any = useSettings()

  return (
    <DataFormOverlay
      {...props}
      minimizeEnabled
      title="Discharge Summary"
      category="discharge"
      subcategory="discharge_summary"
      icon="discharge_forms"
    >
      {({ isEditable, client }: any) => (
        <>
          <Section>
            <FormSection layout="vertical">
              <Flex horizontal gap={16}>
                <DateInput label="Admission Date" model="data.general.admitted_at" value={client?.current_admission?.admitted_at} />
                <DateInput
                  label="Discharge Date"
                  model="data.general.discharged_at"
                  smartDescription={(date, model) => {
                    if (model?.data?.general?.admitted_at) {
                      const length: any = daysBetween(model.data.general.admitted_at, date, timezone)
                      return `Length of Stay: ${countWord('days', length)}`
                    } else {
                      return `Select an Admission and Discharge Date to calculate the stay`
                    }
                  }}
                />
              </Flex>

              <RadioGroup label="Relapse Status" model="data.general.relapse_status" layout="vertical-dense">
                <Radio label="Relapsed" value="relapsed" />
                <Radio label="Did not Relapse" value="not_relapsed" />
                <Radio label="Unknown" value="unknown" />
              </RadioGroup>

              <RadioGroup label="Discharge Type" model="data.general.discharge_type" layout="vertical-dense">
                <Radio label="Successful program completion" value="successfully_completed" />
                <Radio label="Successful discharge" value="successful_discharge" />
                <Radio label="Unsuccessful discharge with option to return" value="unsuccessful_discharge_with_option_to_return" />
                <Radio label="Unsuccessful discharge without option to return" value="unsuccessful_discharge_without_option_to_return" />
                <Radio label="Therapeutic Discharge" value="therapeutic_discharge" />
                <Radio label="AMA" value="ama" />
                <Radio label="MIA" value="mia" />
                <Radio label="Administrative Discharge" value="administrative_discharge" />
                <Radio label="Other" value="other" />
              </RadioGroup>

              <ContextShow when="data.general.discharge_type" is="other">
                <SmartTextarea useDictation label="Other:" model="data.general.discharge_type_other" />
              </ContextShow>

              <AmountInput label="Balance at Time of Discharge" model="data.general.balance_amount_at_discharge" />

              <SmartTextarea useDictation label="Discharge Summary" model="data.general.discharge_summary" />

              <SmartTextarea useDictation label="Recommendations / Resources" model="data.general.recommendations_resources" />

              <YesNoRadioGroup label="Has the client collected all belongings?" model="data.general.did_collect_belongings" />

              <ContextShow when="data.general.did_collect_belongings" is={false}>
                <DateInput
                  label="What is the last date the client can come to receive their belongings?"
                  model="data.general.belongings_last_collection_date"
                />
              </ContextShow>
            </FormSection>
          </Section>

          <Divider />

          <Section
            title="Medications at Time of Discharge"
            aside={<Switch horizontal inverse model="data.medications.not_applicable" />}
            scrollview={{
              id: 'medications',
              name: 'Medications',
            }}
            commentsModel="data.medications"
          >
            <ContextHide when="data.medications.not_applicable" is={true} declineUndefined>
              <Medications
                model="data.medications.meds"
                isEditable={isEditable}
                client={client}
                icon="medications"
                title="Medications"
                emptyDescription="No medications added yet"
              />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Contacts"
            aside={<Switch horizontal inverse model="data.contacts.not_applicable" />}
            scrollview={{
              id: 'contacts',
              name: 'Contacts',
            }}
            commentsModel="data.contacts"
          >
            <ContextHide when="data.contacts.not_applicable" is={true} declineUndefined>
              <Contacts
                model="data.contacts.contacts"
                isEditable={isEditable}
                client={client}
                icon="contacts"
                title="contacts"
                emptyDescription="No contacts added yet"
              />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Strengths, Needs, Abilities, Preferences"
            aside={<Switch horizontal inverse model="data.snap.not_applicable" />}
            scrollview={{
              id: 'snap',
              name: 'S.N.A.P.',
            }}
            commentsModel="data.snap"
          >
            <ContextHide when="data.snap.not_applicable" is={true}>
              <SNAP />
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Summary of Provided Services"
            aside={<Switch horizontal inverse model="data.provided_services.not_applicable" />}
            scrollview={{
              id: 'summary',
              name: 'Summary of Services',
            }}
            commentsModel="data.summary"
          >
            <ContextHide when="data.provided_services.not_applicable" is={true}>
              <FormSection layout="vertical">
                <CheckboxGroup label="Summary of provided services:" layout="vertical-dense">
                  <Checkbox label="Supportive Housing" model="data.provided_services.supportive_housing" />
                  <Checkbox label="Alternative Support Groups" model="data.provided_services.alternative_support_groups" />
                  <Checkbox label="Holistic Services" model="data.provided_services.holistic_services" />

                  <Checkbox label="Assessment and evaluation" model="data.provided_services.assessment_and_evaluation" />
                  <Checkbox label="Psychiatric assessment" model="data.provided_services.psychiatric_assessment" />
                  <Checkbox label="Medication management" model="data.provided_services.medication_management" />
                  <Checkbox label="Group psychotherapy" model="data.provided_services.group_psychotherapy" />
                  <Checkbox label="Co-occurring disorder education" model="data.provided_services.co_occurring_disorder_education" />
                  <Checkbox label="Life skills" model="data.provided_services.life_skills" />
                  <Checkbox label="Nutrition education" model="data.provided_services.nutrition_education" />
                  <Checkbox label="Family therapy" model="data.provided_services.family_therapy" />
                  <Checkbox label="Individual counseling" model="data.provided_services.individual_counseling" />
                  <Checkbox label="Trauma focused therapy" model="data.provided_services.trauma_focused_therapy" />
                  <Checkbox label="12 step meetings" model="data.provided_services.12_step_meetings" />
                  <Checkbox label="Other" model="data.provided_services.other" />
                </CheckboxGroup>

                <ContextShow when="data.provided_services.other" is={true}>
                  <SmartTextarea useDictation label="Other:" model="data.provided_services.other_extra" />
                </ContextShow>

                <SmartTextarea
                  useDictation
                  label="Summary (include any special needs or concerns):"
                  model="data.provided_services.clinical_summary"
                  maxWidth="500px"
                />

                <RadioGroup
                  label="Does client have any unresolved issues that could hinder continued recovery?"
                  model="data.provided_services.has_unresolved_issues"
                  layout="horizontal"
                >
                  <Radio label="Yes" value={true} />
                  <Radio label="No" value={false} />
                </RadioGroup>

                <ContextShow when="data.provided_services.has_unresolved_issues" is={true}>
                  <SmartTextarea useDictation label="Please explain:" model="data.provided_services.has_unresolved_issues_extra" />
                </ContextShow>

                <RadioGroup
                  label="Please rate the client's program compliance:"
                  model="data.provided_services.client_overall_compliance"
                  layout="horizontal-wrap"
                >
                  <Radio label="Poor" value="poor" />
                  <Radio label="Fair" value="fair" />
                  <Radio label="Good" value="good" />
                  <Radio label="Excellent" value="excellent" />
                </RadioGroup>

                <RadioGroup
                  label="Please rate the client's prognosis for motivation:"
                  model="data.provided_services.client_motivation"
                  layout="horizontal-wrap"
                >
                  <Radio label="Poor" value="poor" />
                  <Radio label="Fair" value="fair" />
                  <Radio label="Good" value="good" />
                  <Radio label="Excellent" value="excellent" />
                </RadioGroup>

                <RadioGroup
                  label="Please rate the client's prognosis for medication compliance:"
                  model="data.provided_services.client_medication_compliance"
                  layout="horizontal-wrap"
                >
                  <Radio label="Poor" value="poor" />
                  <Radio label="Fair" value="fair" />
                  <Radio label="Good" value="good" />
                  <Radio label="Excellent" value="excellent" />
                </RadioGroup>

                <RadioGroup
                  label="Please rate the client's prognosis for continued recovery:"
                  model="data.provided_services.client_continued_recovery"
                  layout="horizontal-wrap"
                >
                  <Radio label="Poor" value="poor" />
                  <Radio label="Fair" value="fair" />
                  <Radio label="Good" value="good" />
                  <Radio label="Excellent" value="excellent" />
                </RadioGroup>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Aftercare Plan"
            aside={<Switch horizontal inverse model="data.aftercare_plan.not_applicable" />}
            scrollview={{
              id: 'aftercare_plan',
              name: 'Aftercare Plan',
            }}
            commentsModel="data.aftercare_plan"
          >
            <ContextHide when="data.aftercare_plan.not_applicable" is={true}>
              <FormSection layout="vertical">
                <CheckboxGroup label="Please select all that apply:" layout="vertical-dense">
                  <Checkbox label="Transfer to another facility" model="data.aftercare_plan.transfer_to_another_facility" />
                  <Checkbox label="Supportive Housing" model="data.aftercare_plan.supportive_housing" />
                  <Checkbox label="Alternative Support Groups" model="data.aftercare_plan.alternative_support_groups" />
                  <Checkbox label="Holistic Services" model="data.aftercare_plan.holistic_services" />
                  <Checkbox label="Other Outpatient" model="data.aftercare_plan.other_outpatient" />
                  <Checkbox label="Past Client Program Participation" model="data.aftercare_plan.alumni_program_participation" />
                  <Checkbox label="Independent residence" model="data.aftercare_plan.independent_residence" />
                  <Checkbox label="Involvement in 12 step meetings" model="data.aftercare_plan.involvement_in_12_step_meetings" />
                  <Checkbox label="Outpatient psychiatric" model="data.aftercare_plan.outpatient_psychiatric" />
                  <Checkbox label="Outpatient medical provider" model="data.aftercare_plan.outpatient_medical_provider" />
                  <Checkbox label="Outpatient therapist" model="data.aftercare_plan.outpatient_therapist" />
                  <Checkbox label="Other" model="data.aftercare_plan.other" />
                </CheckboxGroup>

                <ContextShow when="data.aftercare_plan.other" is={true}>
                  <SmartTextarea useDictation label="Other:" model="data.aftercare_plan.other_extra" />
                </ContextShow>

                <SmartTextarea
                  useDictation
                  label="Goals achieved while in the program:"
                  model="data.aftercare_plan.goals_achieved_while_in_program"
                />

                <SmartTextarea
                  useDictation
                  label="Goals for continuing care and recovery:"
                  model="data.aftercare_plan.continuing_care_and_recovery_goals"
                />

                <SmartTextarea
                  useDictation
                  label="Please list any other referrals that have been given:"
                  model="data.aftercare_plan.referrals_given"
                />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Relapse Prevention Plan"
            aside={<Switch horizontal inverse model="data.relapse_prevention_plan.not_applicable" />}
            scrollview={{
              id: 'relapse_prevention_plan',
              name: 'Relapse Prevention Plan',
            }}
            commentsModel="data.relapse_prevention_plan"
          >
            <ContextHide when="data.relapse_prevention_plan.not_applicable" is={true}>
              <FormSection layout="vertical">
                <SmartTextarea useDictation useQuickText label="Custom Plan:" model="data.relapse_prevention_plan.custom_plan" />
                <SmartTextarea
                  useDictation
                  label="Five warning signs that I might use:"
                  model="data.relapse_prevention_plan.five_warning_signs"
                />
                <SmartTextarea
                  useDictation
                  label="Five people who I can call to help me get through a craving:"
                  model="data.relapse_prevention_plan.five_people_i_can_call"
                />
                <SmartTextarea
                  useDictation
                  label="Five things I can do to get my mind off of using:"
                  model="data.relapse_prevention_plan.five_things_i_can_do"
                />
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Education & Employment"
            aside={<Switch horizontal inverse model="data.education_and_employment.not_applicable" />}
            scrollview={{
              id: 'education_and_employment',
              name: 'Education & Employment',
            }}
            commentsModel="data.education_and_employment"
          >
            <ContextHide when="data.education_and_employment.not_applicable" is={true}>
              <FormSection layout="vertical">
                <Select
                  allowEmpty
                  label="What is the client's highest level of education completed?"
                  model="data.education_and_employment.education_level"
                >
                  <Option label="Elementary or High school, no diploma" value="elementary_or_high_school_no_diploma" />
                  <Option label="Elementary or High school, GED" value="elementary_or_high_school_ged" />
                  <Option label="High school diploma" value="high_school_diploma" />
                  <Option label="College, no degree" value="college_no_degree" />
                  <Option label="Associate's degree, vocational" value="associates_degree_vocational" />
                  <Option label="Associate's degree, academic" value="associates_degree_academic" />
                  <Option label="Bachelor's degree" value="bachelors_degree" />
                  <Option label="Master's degree" value="masters_degree" />
                  <Option label="Professional degree" value="professional_degree" />
                  <Option label="Doctorate degree" value="doctorate_degree" />
                  <Option label="Other" value="other" />
                </Select>

                <ContextShow when="data.education_and_employment.education_level" is="other">
                  <Input label="Please add your highest level of education" model="data.education_and_employment.education_level_other" />
                </ContextShow>

                <YesNoRadioGroup label="Is the client currently employed?" model="data.education_and_employment.is_employed" />

                <ContextShow when="data.education_and_employment.is_employed" is={true}>
                  <Input label="What is the company's name?" model="data.education_and_employment.employment.company_name" />

                  <FormCard title="Employer Contact Details">
                    <ContactInputs model="data.education_and_employment.employment.employer_contact" relationship="employer" />
                  </FormCard>

                  <RadioGroup
                    label="Is this permanent, temporary, or seasonal work?"
                    model="data.education_and_employment.employment.work_type"
                    layout="vertical-dense"
                  >
                    <Radio label="Permanent" value="permanent" />
                    <Radio label="Temporary" value="temporary" />
                    <Radio label="Seasonal" value="seasonal" />
                  </RadioGroup>

                  <Input
                    label="How long has the client been employed there?"
                    model="data.education_and_employment.employment.employment_length"
                  />

                  <Input
                    label="How many hours does the client typically work per week?"
                    model="data.education_and_employment.employment.weekly_work_hours"
                    type="number"
                    suffix="/ week"
                    size={4}
                  />

                  <SmartTextarea
                    useDictation
                    label="What is the client's work schedule?"
                    model="data.education_and_employment.employment.work_schedule"
                  />

                  <YesNoRadioGroup
                    label="Does the client have an Employee Assistance Program (EAP)?"
                    model="data.education_and_employment.employment.has_eap"
                  />

                  <ContextShow when="data.education_and_employment.employment.has_eap" is={true}>
                    <FormCard title="EAP Contact">
                      <ContactInputs model="data.education_and_employment.employment.eap_contact" relationship="eap_contact" />
                    </FormCard>
                  </ContextShow>

                  <YesNoRadioGroup
                    label="Is the client a member of a union?"
                    model="data.education_and_employment.employment.is_union_member"
                  />

                  <ContextShow when="data.education_and_employment.employment.is_union_member" is={true}>
                    <Input label="Union Name" model="data.education_and_employment.employment.union_name" />
                    <FormCard title="Union Representative">
                      <ContactInputs
                        model="data.education_and_employment.employment.union_representative"
                        relationship="union_representative"
                      />
                    </FormCard>
                  </ContextShow>

                  <YesNoRadioGroup
                    label="Does the client's employer know that they are in treatment?"
                    model="data.education_and_employment.employment.is_employer_aware_of_treatment"
                  />
                </ContextShow>

                <ContextShow when="data.education_and_employment.is_employed" is={false}>
                  <YesNoRadioGroup
                    label="Is the client currently seeking employment?"
                    model="data.education_and_employment.employment.is_seeking_employment"
                  />

                  <YesNoRadioGroup
                    label="Is the client willing to complete a minimum of two job applications a day?"
                    model="data.education_and_employment.employment.is_willing_to_complete_two_job_applications_per_day"
                  />

                  <YesNoRadioGroupWithTextarea
                    label="Does the client have any skills or industry experience that would assist them in finding a job?"
                    model="data.education_and_employment.employment.has_industry_skills"
                  />
                </ContextShow>

                <ContextShow when="data.education_and_employment.is_employed" is={true}>
                  <AmountInput
                    label="Current weekly income"
                    model="data.education_and_employment.employment.weekly_income"
                    suffix="/ week"
                  />
                </ContextShow>

                <ContextShow when="data.education_and_employment.is_employed" is={false}>
                  <AmountInput
                    label="Anticipated weekly income"
                    model="data.education_and_employment.employment.anticipated_weekly_income"
                    suffix="/ week"
                  />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            title="Courts & Criminal Justice"
            aside={<Switch horizontal inverse model="data.courts_criminal_justice.not_applicable" />}
            scrollview={{
              id: 'courts_criminal_justice',
              name: 'Courts & Criminal Justice',
            }}
            commentsModel="data.courts_criminal_justice"
          >
            <ContextHide when="data.courts_criminal_justice.not_applicable" is={true}>
              <FormSection layout="vertical">
                <YesNoRadioGroup label="Is client on Probation/Parole?" model="data.courts_criminal_justice.is_on_probation_parole" />

                <ContextShow when="data.courts_criminal_justice.is_on_probation_parole" is={true}>
                  <Input label="Jurisdiction DOC Number" model="data.courts_criminal_justice.jurisdiction_doc_number" />
                  <Input label="Probation/Parole Officer Name" model="data.courts_criminal_justice.probation_parole_officer_name" />
                </ContextShow>
              </FormSection>
            </ContextHide>
          </Section>

          <Divider />

          <Section
            scrollview={{
              id: 'client_review',
              name: 'Client Review',
            }}
            commentsModel="data.client_review"
          >
            <Grid gap={16}>
              <Heading is="h2">Client Confirmation of Reviewed Material</Heading>

              <Heading is="h3" size={600}>
                I understand and agree with the above recommendations. All personal items and valuables have been returned to me. Medication
                risks, benefits and potential side effects have been explained to me.
              </Heading>

              <Heading is="h3" size={600}>
                I have received the medications listed in this summary and understand that any controlled substances prescribed to me and
                not released will be destroyed within 72 hours of discharge.
              </Heading>
            </Grid>
          </Section>
        </>
      )}
    </DataFormOverlay>
  )
}

export const DischargeSummaryOverlay = withOverlayError(RootDischargeSummaryOverlay)
