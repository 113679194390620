import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'
import { useMedia } from 'use-media'
import clsx from 'clsx'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'

import { Alert } from '@behavehealth/components'
import { MedicationLogsDataTable } from '@behavehealth/constructs/Medications/MedicationLogsDataTable'
import { MedicationLogWorksheetOverlay } from '@behavehealth/constructs/Medications/MedicationLogWorksheetOverlay'
import { MedicationsDataTable } from '@behavehealth/constructs/Medications/MedicationsDataTable'

import Button from '@behavehealth/components/Button'
import ButtonGroup from '@behavehealth/components/ButtonGroup'
import Card from '@behavehealth/components/Card'
import CardContent from '@behavehealth/components/CardContent'
import CardHeader from '@behavehealth/components/CardHeader'
import CardTitle from '@behavehealth/components/CardTitle'
import Icon from '@behavehealth/components/Icon'
import PageHeader from '@behavehealth/components/PageHeader'
import SummonOverlay from '@behavehealth/components/SummonOverlay'

const DESKTOP_MIN_WIDTH = 1000
const DESKTOP_MIN_HEIGHT = 700
const DESKTOP_MQ = `@media (min-width: ${DESKTOP_MIN_WIDTH}px) and (min-height: ${DESKTOP_MIN_HEIGHT}px)`

export const MedsPass = () => {
  const isDesktop = useMedia({ minWidth: DESKTOP_MIN_WIDTH, minHeight: DESKTOP_MIN_HEIGHT })

  const { isBehave } = useSettings()

  const [layout, setLayout] = React.useState('horizontal')
  const [medLogsData, setMedLogsData] = React.useState<any>(null)

  const medsTableProps = useDataTable({
    name: ['meds'],
    endpoint: `/meds?only_current_clients=true`,
    localStorageKey: 'meds_v1',
  })

  const medLogsTableProps = useDataTable({
    name: ['medication-logs'],
    endpoint: `/medication_logs?only_current_clients=true`,
    localStorageKey: 'medication_logs_v1',
  })

  const medsTo = React.useMemo(() => (rowData: any) => `meds/${rowData.id}`, [])
  const medLogsTo = React.useMemo(() => (rowData: any) => `med-logs/${rowData.id}`, [])

  const contentClasses = clsx(`layout-${layout}`, 'relative z-[0]')

  return (
    <>
      <div css={STYLES.root}>
        <PageHeader isSticky title="Meds Pass" icon="med_pass">
          {isDesktop && (
            <div className="flex justify-center mx-auto flex-[1000_1_auto]">
              <ButtonGroup>
                <Button
                  size={100}
                  label="Vertical"
                  color="gray"
                  type={layout === 'vertical' ? 'primary' : 'default'}
                  onClick={() => setLayout('vertical')}
                  className="!min-w-[80px]"
                />
                <Button
                  size={100}
                  label="Horizontal"
                  color="gray"
                  type={layout === 'horizontal' ? 'primary' : 'default'}
                  onClick={() => setLayout('horizontal')}
                  className="!min-w-[80px]"
                />
              </ButtonGroup>
            </div>
          )}
        </PageHeader>

        <div className="grid gap-3 px-4 py-3">
          <Alert contrast glyph="info">
            Select medications to log and click the <strong>"Log Medications"</strong> button. Use the table filters to get all the
            medications you need to log.
          </Alert>
        </div>

        <div className={contentClasses} css={STYLES.content}>
          <Card className="grid grid-rows-[auto_1fr] grid-cols-[100%] overflow-hidden">
            <CardHeader graphic={<Icon icon="medications" size={20} />}>
              <CardTitle title="Medication List" />
            </CardHeader>

            <CardContent className="!p-0 grid grid-rows-[100%] grid-cols-[100%] overflow-hidden">
              <MedicationsDataTable
                {...medsTableProps}
                mainLinkAs={NavLink}
                to={medsTo}
                asCard={false}
                batchActionsConfig={[
                  {
                    label: (selectedCount: number) => `Log Medications${selectedCount ? ` (${selectedCount})` : ''}`,
                    permission: isBehave ? true : 'med_pass.create',
                    keepSelected: true,
                    glyph: 'add',
                    buttonType: 'primary',
                    action: ({ selectedRows }: any) => {
                      const rowsData = selectedRows.map((med: any) => ({
                        resident: med.resident,
                        med: med,
                        unit: med.unit,
                        dosage: med.dosage,
                        latest_remaining_units: med.latest_remaining_units,
                        latest_administered_at: med.latest_administered_at,
                        default_quantity: med.default_quantity,
                      }))

                      setMedLogsData(rowsData)
                    },
                  },
                ]}
              />
            </CardContent>
          </Card>

          <Card className="grid grid-rows-[auto_1fr] grid-cols-[100%] overflow-hidden">
            <CardHeader graphic={<Icon icon="med_pass" size={20} />}>
              <CardTitle title="Medication Log Records" />
            </CardHeader>

            <CardContent className="!p-0 grid grid-rows-[100%] grid-cols-[100%] overflow-hidden">
              <MedicationLogsDataTable
                {...medLogsTableProps}
                mainLinkAs={NavLink}
                to={medLogsTo}
                asCard={false}
                batchActionsConfig={[
                  {
                    type: 'delete',
                    permission: isBehave ? true : 'medication_logs.delete',
                    action: async ({ ids }: any) => {
                      await medLogsTableProps.deleteRecords(ids.join(','))
                    },
                  },
                ]}
              />
            </CardContent>
          </Card>
        </div>
      </div>

      <SummonOverlay
        isOpen={!!medLogsData}
        onClose={() => setMedLogsData(null)}
        overlay={<MedicationLogWorksheetOverlay initialData={medLogsData} invalidateKeys={['meds']} />}
      />
    </>
  )
}

const STYLES = {
  root: {
    display: 'grid',

    [DESKTOP_MQ]: {
      gridTemplateRows: 'auto auto 1fr',
    },
  },

  content: {
    display: 'grid',
    gridGap: '1rem',
    padding: '0 1rem 1rem',

    [DESKTOP_MQ]: {
      '&.layout-vertical': { gridTemplateRows: '1fr 1fr' },
      '&.layout-horizontal': { gridTemplateColumns: '1fr 1fr', overflow: 'hidden' },
    },
  },
}
