import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import Tabs from '../../components/Tabs'

export const ImportTemplatesTabs = ({ onChange }) => {
  const { isBehave, isSalesApp } = useSettings()

  if (!isBehave) return null

  if (isSalesApp) {
    return (
      <Tabs onChange={onChange} defaultTab="public_template">
        <Tabs.List>
          <Tabs.Item label="Public Templates" name="public_template" />
          <Tabs.Item label="Sales Templates" name="sales_template" glyph="behave_health" />
        </Tabs.List>
      </Tabs>
    )
  }

  return (
    <Tabs onChange={onChange} defaultTab="public_template">
      <Tabs.List>
        <Tabs.Item label="Public Templates" name="public_template" />
        <Tabs.Item label="Behave Templates" name="behave_template" glyph="behave_health" />
        <Tabs.Item label="Sales Templates" name="sales_template" glyph="behave_health" />
      </Tabs.List>
    </Tabs>
  )
}
