import React from 'react'

import ContextShow from '../ContextShow'
import Input from '../Input'
import Checkbox from '../Checkbox'
import CheckboxGroup from '../CheckboxGroup'

const SexualOrientationCheckboxGroup = ({ label = 'Sexual Orientation', model = 'sexual_orientation', isRequired }: any) => (
  <>
    <CheckboxGroup
      allowEmpty
      label={label}
      trueIcon="check"
      falseStyle="hidden"
      layout="vertical-dense"
      validations={
        isRequired && {
          presence: {
            message: 'Please select a sexual orientation',
          },
        }
      }
    >
      <Checkbox label="Heterosexual" model={`${model}.heterosexual`} />
      <Checkbox label="Homosexual" model={`${model}.homosexual`} />
      <Checkbox label="Bisexual" model={`${model}.bisexual`} />
      <Checkbox label="Pansexual" model={`${model}.pansexual`} />
      <Checkbox label="Asexual" model={`${model}.asexual`} />
      <Checkbox label="Queer" model={`${model}.queer`} />
      <Checkbox label="Demisexual" model={`${model}.demisexual`} />
      <Checkbox label="Polysexual" model={`${model}.polysexual`} />
      <Checkbox label="Other" model={`${model}.other`} />
    </CheckboxGroup>

    <ContextShow when={`${model}.other`} is={true}>
      <Input label="Other Sexual Orientation" model={`${model}_other`} />
    </ContextShow>
  </>
)

export default SexualOrientationCheckboxGroup
