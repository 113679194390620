import React from 'react'

import { beautifulAmount } from '../../utils/functions'

import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'

type Props = {
  actions?: any
  testKey?: any
  sign?: any
  prefix?: any
  title: any
  value?: any
  valueColor?: any
  renderValue?: any
  className?: string
  animationDelay?: number
}

const CapitalCard = ({ prefix, className, value, valueColor, actions, title, testKey, sign, renderValue, animationDelay }: Props) => {
  let color = valueColor

  return (
    <Card testKey={testKey} className={className} style={{ animationDelay: `${animationDelay}ms` }}>
      <CardHeader actions={actions}>
        <CardTitle title={renderValue ? renderValue() : value} color={color} className="!text-[1.2rem] !font-[700]" />
        <CardSubtitle subtitle={title} className="!text-[1rem] !font-[600]" />
      </CardHeader>
    </Card>
  )
}

export default CapitalCard
