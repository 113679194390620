import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

import { CLINICAL_ASSESSMENTS_TITLES } from '../../utils/constants'
import { FILTERS } from '../Filters/config'

export const ClinicalAssessmentsDataTable = (props: any) => {
  const { actionsColumn, duplicateColumn } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        formatValue: ({ data, value }: any) => (
          <MainCell to={props.to?.(data)} value={value || CLINICAL_ASSESSMENTS_TITLES[data?.subcategory]} />
        ),
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/clinical-assessments`} />
        },
      },
      {
        title: 'Supervisor',
        model: 'supervisor',
        type: 'profile',
      },
      {
        title: 'Type',
        model: 'subcategory',
        width: 200,
        formatValue: ({ value }: any) => CLINICAL_ASSESSMENTS_TITLES?.[value] || null,
      },
      {
        title: 'Status',
        model: 'status',
        width: 200,
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        title: 'Updates Requested',
        model: 'requested_updates',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Edited',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
      ...(actionsColumn ? [actionsColumn] : []),
    ],
    [props.to],
  )

  return (
    <DataTable
      {...props}
      icon="clinical_assessments"
      title="Clinical Assessments"
      columns={columns}
      filtersConfig={FILTERS.clinical_assessments}
    />
  )
}
