import { useParams } from 'react-router-dom'

import { titleCase } from '../../utils/functions'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Button from '../../components/Button'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import ContextShow from '../../components/Forms/ContextShow'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import Option from '../../components/Forms/Option'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

import { TreatmentEpisodeAuthorizationsTable } from './TreatmentEpisodeAuthorizationsTable'

const RootTreatmentEpisodeOverlay = (props: any) => {
  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
  } = useOverlay({
    name: 'treatment-episode',
    endpoint: '/admissions',
    invalidate: 'treatment-episodes',
    options: props,
  })

  if (isOverlayLoading) return <OverlayLoader position="right" />

  return (
    <Overlay onClose={close}>
      <Overlay.Header icon="treatment_episodes" title="Treatment Episode" />

      <Overlay.Content>
        <Form
          getForm={form}
          timezone={timezone}
          isEditable={isEditable}
          initialModel={initialModel}
          onValidationUpdate={onValidationUpdate}
          linked={
            isNew && {
              customer_id: resource_id,
              customer_type: 'Resident',
            }
          }
          key={`updated-${data?.updated_at}`}
        >
          <Section>
            <FormSection layout="vertical">
              <Input label="Title" model="title" />

              {/* Status */}
              <Select label="Status" model="status" defaultValue="pending">
                <Option label="Pending" value="pending" />
                <Option label="Incoming" value="incoming" />
                <Option label="Waitlisted" value="waitlist" />
                <Option label="Planned" value="planned" />
                <Option label="No Show" value="no_show" />
                <Option label="Declined" value="declined" />
                <Option label="Admitted" value="admitted" />
                <Option label="Discharged" value="discharged" />
                <Option label="Closed" value="closed" />
              </Select>

              {/* Dates */}
              <ContextShow when="status" within={['pending', 'incoming', 'waitlist']}>
                <DateTimeInput
                  label="Est. Arrival Date"
                  model="estimated_arrival"
                  description="Make sure to add in a time to the Est. Arrival Date"
                />
              </ContextShow>

              <ContextShow when="status" within={['admitted', 'discharged']}>
                <DateTimeInput label="Admit Date" model="admitted_at" description="Make sure to add in a time to the Admit Date" />
              </ContextShow>

              <ContextShow when="status" within={['pending', 'incoming', 'waitlist', 'admitted']}>
                <DateTimeInput
                  label="Est. Discharge Date"
                  model="estimated_discharge"
                  description="Make sure to add in a time to the Est. Discharge Date"
                />
              </ContextShow>

              <ContextShow when="status" is="discharged">
                <DateTimeInput
                  label="Discharge Date"
                  model="discharged_at"
                  description="Make sure to add in a time to the Discharge Date"
                />
              </ContextShow>

              <Select allowEmpty label="Discharge Type" model="discharge_type" layout="vertical-dense">
                <Option label="Successfully Completed" value="successfully_completed" />
                <Option label="Therapeutic Discharge" value="therapeutic_discharge" />
                <Option label="AMA" value="ama" />
                <Option label="MIA" value="mia" />
                <Option label="Administrative Discharge" value="administrative_discharge" />
                <Option label="Other" value="other" />
              </Select>

              <ContextShow when="discharge_type" is="other">
                <Textarea useQuickText label="Other Discharge Type" model="other_discharge_type" />
              </ContextShow>

              <Select allowEmpty label="Return Eligibility" model="return_eligibility" layout="vertical-dense">
                <Option label="Eligible" value="eligible" />
                <Option label="Not eligible" value="not_eligible" />
                <Option label="Pending decision" value="pending_decision" />
              </Select>

              {/* Notes */}
              <ContextShow when="status" is="incoming">
                <Textarea useQuickText label="Incoming Notes" model="incoming_notes" />
              </ContextShow>

              <ContextShow when="status" is="waitlist">
                <Textarea useQuickText label="Waitlist Notes" model="waitlist_notes" />
              </ContextShow>

              <ContextShow when="status" is="no_show">
                <Textarea useQuickText label="No-Show Notes" model="no_show_notes" />
              </ContextShow>

              <ContextShow when="status" is="declined">
                <Textarea useQuickText label="Decline Reason" model="decline_reason" />
              </ContextShow>

              <ContextShow when="status" is="discharged">
                <Textarea useQuickText label="Discharge Notes" model="discharge_notes" />
              </ContextShow>

              {/* <ObjectSelector
                label="Referral In"
                model="referral_in"
                type="organizations"
                icon="organizations"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
              />

              <ObjectSelector
                model="referral_in_contact"
                label="Referral In Contact"
                type="organization.contacts"
                dependent="referral_in_id"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => data.role}
              />

              <Textarea useQuickText  label="Referral In Notes" model="referral_in_notes" />

              <ObjectSelector
                label="Referral Out"
                model="referral_out"
                type="organizations"
                icon="organizations"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => `${titleCase(data.category)} - ${titleCase(data.subcategory?.name)}`}
              />

              <ObjectSelector
                model="referral_out_contact"
                label="Referral Out Contact"
                type="organization.contacts"
                dependent="referral_out_id"
                selectTitle={(data: any) => data.name}
                selectDescription={(data: any) => data.role}
              />

              <Textarea useQuickText  label="Referral Out Notes" model="referral_out_notes" /> */}

              <Textarea useQuickText label="Additional Information" model="notes" />

              {!isNew && data && (
                <Card>
                  <TreatmentEpisodeAuthorizationsTable data={data.insurance_authorizations} />
                </Card>
              )}
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable && (
          <Flex stretchChildrenX gap={8}>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              isDisabled={isInvalid}
              flex="3 1 auto"
              permission="treatment_episodes.create"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} flex="0 1 auto" />}
          </Flex>
        )}

        {!isEditable && (
          <Flex stretchChildrenX gap={8}>
            <Button
              glyph="edit"
              label="Edit"
              type="default"
              isDisabled={isSaving}
              onClick={edit}
              flex="2 1 auto"
              permission="treatment_episodes.edit"
            />

            <DeleteDialog
              title="Delete Treatment Episode?"
              message="Are you sure you want to delete this Treatment Episode? This action cannot be undone."
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                permission="treatment_episodes.delete"
              />
            </DeleteDialog>
          </Flex>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const TreatmentEpisodeOverlay = withOverlayError(RootTreatmentEpisodeOverlay)
