import React from 'react'
import { useParams } from 'react-router-dom'
import produce from 'immer'

import { useGet } from '../../hooks/useNewAPI'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { niceAmount, titleCase } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Glyph from '../../components/Glyph'
import Link from '../../components/Link'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'

import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import RadioGroup from '../../components/Forms/RadioGroup'
import Radio from '../../components/Forms/Radio'
import ContextShow from '../../components/Forms/ContextShow'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Textarea from '../../components/Forms/Textarea'

import ChargePayersSection from './components/ChargePayersSection'
import { PAYMENT_METHODS_LIST } from '../../utils/constants'

const ORGANIZATION_TYPES = {
  vendor: 'Vendor',
  resource: 'Community Resource',
  provider: 'Provider',
}

const RootChargeOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    updateAsync,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const service = initialModel?.service

  const employees = initialModel?.service?.employees
  const hasEmployees = size(employees) > 0

  const organizations = initialModel?.service?.organizations
  const hasOrganizations = size(organizations) > 0

  const isCharged = initialModel.status === 'charged'
  const hasPayers = size(initialModel.subcharges) > 0

  const allowToUpdateAmount =
    initialModel.status === 'future' ? !initialModel?.is_auto_charged : initialModel.source === 'app' && !initialModel?.is_auto_charged
  const isCustomAmount = initialModel?.is_custom_amount || formData?.is_custom_amount
  const isCustomCollection = formData?.is_custom_collection

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  if (isOverlayLoading || !client) {
    return <OverlayLoader position="right" />
  }

  const toggleCancel = async () => {
    await updateAsync({ status: initialModel.status === 'charged' ? 'cancelled' : 'charged' })
    close()
  }

  const save = () => {
    const saveData = produce(formData, (draft) => {
      if (draft.reference_category === 'client') {
        draft.reference_id = client.id
        draft.reference_type = client.type
        return
      }

      if (draft.reference_category === 'contact' && draft.contact_reference_id) {
        draft.reference_id = draft.contact_reference_id
        draft.reference_type = draft.contact_reference_type
        return
      }

      if (draft.reference_category === 'organization' && draft.organization_reference_id) {
        draft.reference_id = draft.organization_reference_id
        draft.reference_type = draft.organization_reference_type
        return
      }

      draft.reference_id = null
      draft.reference_type = null
    })

    saveWithData({ ...saveData, payed_with: formData.payed_with?.model })
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="financials" title="Charge" />

      <Overlay.Content>
        <Form
          key={`updated-${data?.updated_at}`}
          getForm={form}
          initialModel={produce(initialModel, (draft) => {
            if (draft.reference_category === 'client' && draft.reference?.type === 'resident') {
              draft.client_reference = draft.client
              return
            }

            if (draft.reference_category === 'contact' && draft.reference?.type === 'contact') {
              draft.contact_reference = draft.reference
              return
            }

            if (draft.reference_category === 'organization' && draft.reference?.type === 'organization') {
              draft.organization_reference = draft.reference
              return
            }
          })}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
          linked={isNew ? { category: 'charge', financial_plan_id: client?.financial_plan?.id } : undefined}
        >
          <Section>
            <FormSection>
              <ObjectSelector
                isEditable={false}
                model="service"
                label="Linked Service"
                type="client_services"
                sortBy={['order', 'created_at']}
                icon={ICONS.financials}
                selectTitle={(data: any) => `${data.name} (${niceAmount(data.amount)})`}
              />

              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              {allowToUpdateAmount ? (
                <>
                  <Checkbox
                    skipDeregister
                    className="!flex-auto"
                    isEditable={isEditable && !isCharged}
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle={isCharged ? 'hidden' : 'none'}
                    label="Use the Amount from the Linked Service above"
                    model="is_custom_amount"
                    true_value={false}
                    false_value={true}
                  />

                  <Flex gap="1rem" alignItems="flex-end">
                    {isCustomAmount && (
                      <AmountInput
                        label="Custom Amount"
                        model="amount"
                        validations={{
                          presence: {
                            message: 'Please enter an amount',
                          },
                          numericality: {
                            greaterThan: 0,
                            message: 'Please enter an amount',
                          },
                        }}
                      />
                    )}

                    {isCustomCollection && (
                      <AmountInput
                        label="Collection Amount"
                        model="collection_amount"
                        validations={{
                          presence: {
                            message: 'Please enter an amount',
                          },
                          numericality: {
                            greaterThan: 0,
                            message: 'Please enter an amount',
                          },
                        }}
                      />
                    )}
                  </Flex>

                  <Checkbox
                    trueIcon="check"
                    falseIcon="cross"
                    falseStyle="hidden"
                    label="Set Collection Amount"
                    model="is_custom_collection"
                  />

                  {!isCustomAmount && <AmountInput label="Service Amount" value={service?.amount} />}
                </>
              ) : (
                <>
                  <AmountInput isEditable={false} label="Amount" model="amount" />

                  <Alert glyph="warning" type="warning">
                    The Amount cannot be changed because this Charge was collected using the Automatic Payment Collection
                  </Alert>
                </>
              )}

              <DateInput
                label="Due Date"
                model="transactioned_at"
                validations={{
                  presence: {
                    message: 'Please enter a due date',
                  },
                }}
              />

              {hasEmployees && (
                <OverlaySelector
                  model="employees"
                  label="Staff Member"
                  type="employees.active"
                  apiData={employees}
                  icon={ICONS.employees}
                  selectTitle={(data: any) => data.name}
                  selectDescription={(data: any) => startCase(data.position)}
                />
              )}

              {hasOrganizations && (
                <ObjectSelector
                  model="organizations"
                  label="Organization"
                  type="organizations"
                  apiData={organizations}
                  icon={ICONS.organizations}
                  selectTitle={(data: any) => data.name}
                  selectDescription={() => null}
                />
              )}

              <ObjectSelector
                isRelation={false}
                className="!flex-auto"
                model="payed_with"
                label="Pay With"
                apiData={PAYMENT_METHODS_LIST}
                showAvatars={false}
                selectTitle={(data: any) => (
                  <>
                    <Glyph glyph={data.glyph} /> {data.name}
                  </>
                )}
                selectDescription={(data: any) => data.description}
                validations={{
                  presence: {
                    message: 'Please select an option',
                  },
                }}
              />
            </FormSection>
          </Section>

          <Divider />

          {formData?.payed_with?.model === 'online' && (
            <>
              <Section title="Automatic Collection">
                <FormSection maxWidth="100%">
                  <CheckboxGroup isEditable={isCharged ? false : isEditable} trueIcon="check" falseIcon="cross" falseStyle="faded">
                    <Checkbox
                      isEditable={isCharged ? false : isEditable}
                      label={isCharged ? 'Used Automatic Payment Collection' : 'Use Automatic Payment Collection'}
                      model="should_auto_charge"
                      description="Attempt to collect from the Payment Method selected below"
                    />
                  </CheckboxGroup>

                  {formData?.should_auto_charge && (
                    <ObjectSelector
                      isEditable={isCharged ? false : isEditable}
                      icon="billing"
                      type="client.payment_methods"
                      label={isCharged ? 'Charged Payment Method' : 'Payment Method to Charge'}
                      model="payment_method"
                      dependentValue={client?.id}
                      selectTitle={(data: any) => data?.name}
                      selectDescription={(data: any) => data?.reference?.name}
                      validations={{
                        presence: {
                          message: 'Please select a Payment Method to use',
                        },
                      }}
                    />
                  )}
                </FormSection>
              </Section>

              <Divider />
            </>
          )}

          {formData?.payed_with?.model !== 'online' && (
            <>
              <Section title="Payer">
                <FormSection maxWidth="100%">
                  <RadioGroup label="Payer Type" model="reference_category" layout="horizontal-dense" defaultValue="client">
                    <Radio label="Client" value="client" />
                    <Radio label="Contact" value="contact" />
                    <Radio label="Organization" value="organization" />
                  </RadioGroup>

                  {!isEditable ? (
                    <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="reference" />
                  ) : (
                    <>
                      <ContextShow when="reference_category" is="client">
                        <ObjectSelector isEditable={false} isPolymorphic label="Payer" model="client_reference" value={client} />
                      </ContextShow>

                      <ContextShow when="reference_category" is="contact">
                        <ObjectSelector
                          isPolymorphic
                          icon="contacts"
                          label="Payer"
                          model="contact_reference"
                          type="client.contacts"
                          description={
                            <>
                              Can't find the Contact you're looking for? Go to <Link to="contacts">Contacts</Link> to add them.
                            </>
                          }
                          dependentValue={client?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => titleCase(data?.relationship)}
                        />
                      </ContextShow>

                      <ContextShow when="reference_category" is="organization">
                        <ObjectSelector
                          isPolymorphic
                          icon="organizations"
                          label="Payer"
                          model="organization_reference"
                          type="organizations"
                          dependentValue={client?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => ORGANIZATION_TYPES[data?.category]}
                          description={
                            <>
                              Can't find the Organization you're looking for? Go to <Link to="/community/organizations">Organizations</Link>{' '}
                              to add it.
                            </>
                          }
                        />
                      </ContextShow>
                    </>
                  )}
                </FormSection>
              </Section>

              <Divider />
            </>
          )}

          {hasPayers && (
            <>
              <ChargePayersSection subcharges={initialModel.subcharges} />
              <Divider />
            </>
          )}

          <Section headingType="h2" title="Attachments" description="Upload files related to this charge">
            <FormSection layout="vertical">
              <Attachments model="documents" label="Attachments" labelAlign="top" labelJustify="top" />
            </FormSection>
          </Section>

          {/* {!$new && (
            <>
              <Divider />

              <Section headingType="h2" title="Timeline">
                <Timeline isLoadingRecord={loading} recordID={initialModel.id} recordType={initialModel.type} />
              </Section>
            </>
          )} */}

          <Divider />

          <Section>
            <FormSection layout="vertical">
              <Textarea useQuickText label="Notes" model="notes" />

              <Flex gap="1rem">
                <DateTimeInput isEditable={false} label="Date Created" model="created_at" />
                <DateTimeInput isEditable={false} label="Date Updated" model="updated_at" />
              </Flex>
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
              permission="ledger.create"
            />
            <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />
          </>
        ) : (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={edit} flex="100 1 auto" permission="ledger.edit" />

            {initialModel.status === 'cancelled' && (
              <Button
                label="Re-apply Charge"
                glyph="circle_add"
                type="default"
                color="green"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {initialModel.status === 'charged' && (
              <Button
                testKey="cancel_charge_button"
                label="Cancel Charge"
                glyph="circle_error"
                type="default"
                color="red"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {(initialModel.status === 'future' || initialModel.status === 'cancelled') && (
              <DeleteDialog
                title="Delete Charge?"
                message="Are you sure you want to delete this charge? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  testKey="delete_charge_button"
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  fullWidth
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const ChargeOverlay = withOverlayError(RootChargeOverlay)
