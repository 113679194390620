import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'
import compact from 'lodash/compact'

import { getVersionSlug } from '@behavehealth/utils/functions.js'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ProgressReviewsDataTable } from '@behavehealth/constructs/ProgressReviews/ProgressReviewsDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'
import { DATA_FORM_SETTINGS } from '@behavehealth/constructs/DataFormsBuilder/constants'

const pageConfig = {
  feature: 'progress_reviews',
  help: <HelpTagIframe id="progress_reviews" />,
  marketingID: 'progress_reviews',
}

type Props = {
  canCreate: boolean
}

const ProgressReviews: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id: clientId }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', clientId],
    url: `/residents/${clientId}`,
  })

  const tableProps = useDataTable({
    name: ['client', clientId, 'data_forms'],
    endpoint: `/residents/${clientId}/data_forms`,
    params: { category: 'progress_review' },
    updateDeleteEndpoint: '/data_forms',
    enabled: !!clientId,
    localStorageKey: 'client_progress_reviews_v1',
  })

  const actions = <DataFormAddDropdown category="progress_review" clientTrack={client?.client_track} />

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <ProgressReviewsDataTable
          {...tableProps}
          hiddenColumnIds={['client']}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/progress-reviews',
            },
          ]}
          to={(record) => {
            const { id, version } = record

            const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

            return {
              pathname: `${match.url}${version === '3.0' ? '/progress-review' : ''}/${id}${formattedVersion}`,
              parent: match,
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'progress_reviews.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          duplicateColumn={setDuplicateColumn({
            link: (record) => {
              const { data_form_setting }: any = record

              if (!data_form_setting?.id) return null

              const recordKey = compact([data_form_setting.subcategory, data_form_setting.variant]).join('_')
              const formConfig = DATA_FORM_SETTINGS[data_form_setting.category]?.[recordKey]

              if (!formConfig) return null

              return `${match.url}/${formConfig.slug}/new-30-${data_form_setting.id}`
            },
            parent: match,
            permission: 'progress_reviews.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ProgressReviews, pageConfig))
