import React from 'react'
import { useRouteMatch, useParams } from 'react-router-dom'

import { useGet, useDelete } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Card, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { SafetyChecksDataTable } from '@behavehealth/constructs/SafetyChecks/SafetyChecksDataTable'

import Dropdown from '@behavehealth/components/Dropdown'
import DropdownItem from '@behavehealth/components/DropdownItem'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable.js'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'safety_checks',
  help: <HelpTagIframe id="safety_checks" />,
  marketingID: 'safety_checks',
}

const SafetyChecks = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps: any = useDataTable({
    name: ['client', resource_id, 'safety_checks'],
    endpoint: `/residents/${resource_id}/safety_checks`,
  })

  const { mutate: deleteSafetyChecks } = useDelete({
    name: ['client', resource_id, 'safety_checks'],
    url: '/safety_checks',
    invalidate: 'safety_checks',
  })

  const actions = (
    <>
      <Dropdown label="Add Safety Checks…" glyph="add" buttonType="primary" permission="safety_checks.create">
        <DropdownItem
          label="Add Safety Check"
          icon="safety_checks"
          color="blue"
          link={{
            pathname: `${match.url}/new`,
            data: {
              resident: client,
            },
          }}
        />
        <DropdownItem
          label="Add Many Safety Checks…"
          glyph="stack"
          color="blue"
          link={{
            pathname: `${match.url}/new-batch`,
            data: [{ resident: client }, { resident: client }, { resident: client }],
          }}
          featureFlagV2="bulk_add"
        />
      </Dropdown>
    </>
  )

  return (
    <Page actions={canCreate && actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <Card>
          <SafetyChecksDataTable
            {...tableProps}
            emptyActions={actions}
            localStorageKey="client_safety_checks"
            to={(rowData: any) => ({
              pathname: `${match.url}/${rowData.id}`,
              parent: match,
            })}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'safety_checks.delete',
                action: async ({ ids }: any) => {
                  await deleteSafetyChecks(ids.join(','))
                },
              },
            ]}
            duplicateColumn={setDuplicateColumn({
              link: `${match.url}/new`,
              parent: match,
              permission: 'safety_checks.create',
            })}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(SafetyChecks, pageConfig))
