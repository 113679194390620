export const CLIENT_COLUMNS = [
  { name: 'name', label: 'Name' },
  { name: 'preferred', label: 'Preferred Name' },
  { name: 'behave_id', label: 'Behave ID' },
  { name: 'date_of_birth', label: 'Date of Birth' },
  { name: 'sex', label: 'Sex' },
  { name: 'gender_identity', label: 'Gender Identity' },
  { name: 'Phone Number', label: 'Phone Number' },
  { name: 'landline_number', label: 'Landline Number' },
  { name: 'email', label: 'Email' },
  { name: 'property', label: 'Property' },
  { name: 'floor', label: 'Floor' },
  { name: 'room', label: 'Room' },
  { name: 'bed', label: 'Bed' },
  { name: 'tags', label: 'Tags' },
  { name: 'level_of_care', label: 'Level of Care' },
  { name: 'billed', label: 'Billed' },
  { name: 'charges', label: 'Charges' },
  { name: 'payments', label: 'Payments' },
  { name: 'credits', label: 'Credits' },
  { name: 'refunds', label: 'Refunds' },
  { name: 'Write-Offs', label: 'Write-Offs' },
  { name: 'total_balance', label: 'Total Balance' },
  { name: 'future_charges', label: 'Planned Charges' },
  { name: 'financial_status', label: 'Financial Status' },
  { name: 'financial_data_filter', label: 'Financial Data Filter' },
  { name: 'financial_notes', label: 'Financial Notes' },
  { name: 'referral_in', label: 'Referral In' },
  { name: 'referral_in_contact', label: 'Referral In Contact' },
  { name: 'referral_in_notes', label: 'Referral In Notes' },
  { name: 'referral_out', label: 'Referral Out' },
  { name: 'referral_out_contact', label: 'Referral Out Contact' },
  { name: 'referral_out_notes', label: 'Referral Out Notes' },
  { name: 'first_contact_on', label: 'First Contact On' },
  { name: 'last_contact_on', label: 'Last Contact On' },
  { name: 'last_contact_by', label: 'Last Contact By' },
  { name: 'current_admission_status', label: 'Current Admission Status' },
  { name: 'current_admission_admitted_at', label: 'Current Admission Admitted At' },
  { name: 'current_admission_discharged_at', label: 'Current Admission Discharged At' },
  { name: 'applied_on', label: 'Applied On' },
  { name: 'accepted_on', label: 'Accepted On' },
  { name: 'admitted_on', label: 'Admitted On' },
  { name: 'admitted_by', label: 'Admitted By' },
  { name: 'est_discharge_date', label: 'Est. Discharge Date' },
  { name: 'length_of_stay', label: 'Length of Stay' },
  { name: 'est_length_of_stay', label: 'Est. Length of Stay' },
  { name: 'sobriety_date', label: 'Sobriety Date' },
  { name: 'sober_for', label: 'Sober For' },
  { name: 'using_portal', label: 'Using Portal' },
  { name: 'last_ua_test_at', label: 'Last UA Test At' },
  { name: 'last_logged_in_at', label: 'Last Logged-in At' },
]

export const DSU_RECOVERY_RESIDENCE_REPORTING_COLUMNS = [
  { name: 'behave_id', label: 'Behave ID' },
  { name: 'gender_identity', label: 'Gender Identity' },
  { name: 'property', label: 'Property' },
  { name: 'referral_in_contact', label: 'Referral In Contact' },
  { name: 'referral_in_notes', label: 'Referral In Notes' },
  { name: 'referral_out_notes', label: 'Referral Out Notes' },
  { name: 'current_admission_admitted_at', label: 'Current Admission Admitted At' },
  { name: 'current_admission_discharged_at', label: 'Current Admission Discharged At' },
  { name: 'length_of_stay', label: 'Length of Stay' },
]

export const CARE_TEAM_COLUMNS = [
  { name: 'employee_name', label: 'Employee Name' },
  { name: 'client_name', label: 'Client Name' },
  { name: 'category', label: 'Category' },
  { name: 'relation_type', label: 'Relation Type' },
  { name: 'notes', label: 'Notes' },
  { name: 'date_added', label: 'Date Added' },
  { name: 'last_updated', label: 'Last Updated' },
]

export const ALUMNI_COLUMNS = [
  { name: 'name', label: 'Name' },
  { name: 'preferred_name', label: 'Preferred Name' },
  { name: 'behave_id', label: 'Behave ID' },
  { name: 'date_of_birth', label: 'Date of Birth' },
  { name: 'discharged_at', label: 'Discharged At' },
  { name: 'discharge_notes', label: 'Discharge Notes' },
  { name: 'discharge_type', label: 'Discharge Type' },
  { name: 'aftercare_plan', label: 'Aftercare Plan' },
  { name: 'phone_number', label: 'Phone Number' },
  { name: 'landline_number', label: 'Landline Number' },
  { name: 'email', label: 'Email' },
  { name: 'tags', label: 'Tags' },
  { name: 'charges', label: 'Charges' },
  { name: 'last_logged_at', label: 'Last Logged-in At' },
  { name: 'last_charge_at', label: 'Last Charge At' },
  { name: 'payments', label: 'Payments' },
  { name: 'last_payment', label: 'Last Payment' },
  { name: 'last_payment_at', label: 'Last Payment At' },
  { name: 'credits', label: 'Credits' },
  { name: 'refunds', label: 'Refunds' },
  { name: 'write_offs', label: 'Write-Offs' },
  { name: 'total_balance', label: 'Total Balance' },
  { name: 'future_charges', label: 'Future Charges' },
  { name: 'payment_mthods_used', label: 'Payment Methods Used' },
  { name: 'financial_notes', label: 'Financial Notes' },
  { name: 'financial_data_filter', label: 'Financial Data Filter' },
  { name: '', label: 'Drugs of Choice' },
  { name: '', label: 'Sobriety Date' },
  { name: '', label: 'Using Portal' },
  { name: '', label: 'Ethnicity' },
  { name: '', label: 'Age' },
  { name: '', label: 'Sex' },
  { name: '', label: 'Gender Identity' },
]

export const CURRENT_CLIENT_COLUMNS = [
  { name: 'name', label: 'Name' },
  { name: 'preferred', label: 'Preferred Name' },
  { name: 'behave_id', label: 'Behave ID' },
  { name: 'date_of_birth', label: 'Date of Birth' },
  { name: 'sex', label: 'Sex' },
  { name: 'gender_identity', label: 'Gender Identity' },
  { name: 'Phone Number', label: 'Phone Number' },
  { name: 'landline_number', label: 'Landline Number' },
  { name: 'email', label: 'Email' },
  { name: 'property', label: 'Property' },
  { name: 'floor', label: 'Floor' },
  { name: 'room', label: 'Room' },
  { name: 'bed', label: 'Bed' },
  { name: 'tags', label: 'Tags' },
  { name: 'level_of_care', label: 'Level of Care' },
  { name: 'billed', label: 'Billed' },
  { name: 'charges', label: 'Charges' },
  { name: 'payments', label: 'Payments' },
  { name: 'credits', label: 'Credits' },
  { name: 'refunds', label: 'Refunds' },
  { name: 'Write-Offs', label: 'Write-Offs' },
  { name: 'total_balance', label: 'Total Balance' },
  { name: 'last_payment_date', label: 'Last Payment Date' },
  { name: 'future_charges', label: 'Future Planned Charges' },
  { name: 'financial_status', label: 'Financial Status' },
  { name: 'financial_data_filter', label: 'Financial Data Filter' },
  { name: 'financial_notes', label: 'Financial Notes' },
  { name: 'referral_in', label: 'Referral In' },
  { name: 'referral_in_contact', label: 'Referral In Contact' },
  { name: 'referral_in_notes', label: 'Referral In Notes' },
  { name: 'referral_out', label: 'Referral Out' },
  { name: 'referral_out_contact', label: 'Referral Out Contact' },
  { name: 'referral_out_notes', label: 'Referral Out Notes' },
  { name: 'first_contact_on', label: 'First Contact On' },
  { name: 'last_contact_on', label: 'Last Contact On' },
  { name: 'last_contact_by', label: 'Last Contact By' },
  { name: 'current_admission_status', label: 'Current Admission Status' },
  { name: 'current_admission_admitted_at', label: 'Current Admission Admitted At' },
  { name: 'current_admission_discharged_at', label: 'Current Admission Discharged At' },
  { name: 'applied_on', label: 'Applied On' },
  { name: 'accepted_on', label: 'Accepted On' },
  { name: 'admitted_on', label: 'Admitted On' },
  { name: 'admitted_by', label: 'Admitted By' },
  { name: 'est_discharge_date', label: 'Est. Discharge Date' },
  { name: 'length_of_stay', label: 'Length of Stay' },
  { name: 'est_length_of_stay', label: 'Est. Length of Stay' },
  { name: 'sobriety_date', label: 'Sobriety Date' },
  { name: 'sober_for', label: 'Sober For' },
  { name: 'using_portal', label: 'Using Portal' },
  { name: 'last_ua_test_at', label: 'Last UA Test At' },
  { name: 'last_logged_in_at', label: 'Last Logged-in At' },
]

export const ANONYMIZED_OUTCOMES = [
  { model: 'discharge_type', label: 'Discharge Type' },
  { model: 'relapsed_or_not', label: 'Relapsed or Not' },
  { model: 'diagnoses', label: 'Diagnoses' },
  { model: 'sex', label: 'Sex' },
  { model: 'gender_identity', label: 'Gender Identity' },
  { model: 'ethnicity', label: 'Ethnicity' },
  { model: 'religious_preference', label: 'Religious Preference' },
  { model: 'primary_language', label: 'Primary Language' },
  { model: 'veteran_status', label: 'Veteran Status' },
  { model: 'marital_status', label: 'Marital Status' },
  { model: 'have_children', label: 'Do you have children?' },
  { model: 'age', label: 'Age' },
  { model: 'address_city', label: 'Address City' },
  { model: 'drugs_of_choice', label: 'Drugs of Choice' },
  { model: 'years_of_substance_use', label: 'For how many years have you been using alcohol and/or drugs?' },
  { model: 'use_tobacco', label: 'Do you use tobacco?' },
  { model: 'quit_tobacco', label: 'Would you like to quit using tobacco?' },
  { model: 'help_with_quitting', label: 'Do you need help with quitting?' },
  { model: 'length_of_stay', label: 'Length of Stay' },
  { model: 'level_of_care_at_discharge', label: 'Level of Care at Time of Discharge' },
  { model: 'living_situation', label: 'What best describes your current living situation?' },
  { model: 'provided_services_summary', label: 'Summary of provided services:' },
  { model: 'unresolved_issues', label: 'Does client have any unresolved issues that could hinder continued recovery?' },
  { model: 'compliance_rating', label: "Please rate the client's overall compliance:" },
  { model: 'motivation_prognosis', label: "Please rate the client's prognosis for motivation:" },
  { model: 'medication_compliance_prognosis', label: "Please rate the client's prognosis for medication compliance:" },
  { model: 'recovery_prognosis', label: "Please rate the client's prognosis for continued recovery:" },
  { model: 'aftercare_plan', label: 'Aftercare Plan' },
]

export const EXTENDED_ANONYMIZED_OUTCOMES = [
  { model: 'discharge_type', label: 'Discharge Type' },
  { model: 'relapsed_or_not', label: 'Relapsed or not' },
  { model: 'diagnoses', label: 'Diagnoses' },
  { model: 'sex', label: 'Sex' },
  { model: 'gender_identity', label: 'Gender Identity' },
  { model: 'ethnicity', label: 'Ethnicity' },
  { model: 'religious_preference', label: 'Religious Preference' },
  { model: 'primary_language', label: 'Primary Language' },
  { model: 'veteran_status', label: 'Veteran Status' },
  { model: 'payment_method', label: 'How will you pay for the program?' },
  { model: 'marital_status', label: 'Marital Status' },
  { model: 'domestic_violence_fleeing', label: 'Are you fleeing a domestic violence situation?' },
  { model: 'have_children', label: 'Do you have children?' },
  { model: 'age', label: 'Age' },
  { model: 'address_city', label: 'Address City' },
  { model: 'drugs_of_choice', label: 'Drugs of Choice' },
  { model: 'years_of_substance_use', label: 'For how many years have you been using alcohol and/or drugs?' },
  { model: 'use_tobacco', label: 'Do you use tobacco?' },
  { model: 'quit_tobacco', label: 'Would you like to quit using tobacco?' },
  { model: 'help_with_quitting', label: 'Do you need help with quitting?' },
  { model: 'length_of_stay', label: 'Length of stay' },
  { model: 'level_of_care_at_discharge', label: 'Level of Care at Time of Discharge' },
  { model: 'living_situation', label: 'What best describes your current living situation?' },
  { model: 'provided_services_summary', label: 'Summary of provided services:' },
  { model: 'unresolved_issues', label: 'Does client have any unresolved issues that could hinder continued recovery?' },
  { model: 'compliance_rating', label: "Please rate the client's overall compliance:" },
  { model: 'motivation_prognosis', label: "Please rate the client's prognosis for motivation:" },
  { model: 'medication_compliance_prognosis', label: "Please rate the client's prognosis for medication compliance:" },
  { model: 'recovery_prognosis', label: "Please rate the client's prognosis for continued recovery:" },
  { model: 'aftercare_plan', label: 'Aftercare Plan' },
]

export const BREATHALYZER_TESTS = [
  { model: 'name', label: 'Name' },
  { model: 'behave_id', label: 'Behave ID' },
  { model: 'author', label: 'Author' },
  { model: 'collected_at', label: 'Collected At' },
  { model: 'result', label: 'Result' },
  { model: 'result_value', label: 'Result Value' },
]

export const UA_TESTS = [
  { model: 'name', label: 'Name' },
  { model: 'behave_id', label: 'Behave ID' },
  { model: 'author', label: 'Author' },
  { model: 'status', label: 'Status' },
  { model: 'date_collected', label: 'Date Collected' },
  { model: 'test_number', label: 'Test #' },
  { model: 'result', label: 'Result' },
  { model: 'tested_positive_for', label: 'Tested Positive For' },
  { model: 'other_positive_results', label: 'Other Positive Results' },
  { model: 'positive_due_medication', label: 'Positive due to prescription' },
  { model: 'additional_info', label: 'Additional Info' },
]

export const VITAL_SIGNS = [
  { model: 'name', label: 'Name' },
  { model: 'behave_id', label: 'Behave ID' },
  { model: 'author', label: 'Author' },
  { model: 'recorded_at', label: 'Recorded At' },
  { model: 'blood_pressure_systolic', label: 'Blood Pressure Systolic' },
  { model: 'blood_pressure_diastolic', label: 'Blood Pressure Diastolic' },
  { model: 'temperature', label: 'Temperature' },
  { model: 'oxygen_saturation', label: 'Oxygen Saturation' },
  { model: 'pulse', label: 'Pulse' },
  { model: 'respiration', label: 'Respiration' },
  { model: 'pain_scale', label: 'Pain Scale' },
  { model: 'weight', label: 'Weight' },
  { model: 'height_ft', label: 'Height ft' },
  { model: 'height_in', label: 'Height in' },
  { model: 'notes', label: 'Notes' },
]

export const COMMUNICABLE_DISEASE_TESTS = [
  { model: 'name', label: 'Name' },
  { model: 'behave_id', label: 'Behave ID' },
  { model: 'author', label: 'Author' },
  { model: 'recorded_at', label: 'Recorded At' },
  { model: 'category', label: 'Category' },
  { model: 'result', label: 'Result' },
  { model: 'quantitative_measure', label: 'Quantitative Measure' },
  { model: 'notes', label: 'Notes' },
]

export const CLIENT_FINANCIAL_SUMMARY = [
  { model: 'client', label: 'Client' },
  { model: 'charges', label: 'Charges' },
  { model: 'payments', label: 'Payments' },
  { model: 'write_offs', label: 'Write-Offs' },
  { model: 'credits', label: 'Credits' },
  { model: 'refunds', label: 'Refunds' },
]

export const CLIENT_FINANCIAL_TRANSACTIONS = [
  { model: 'name', label: 'Name' },
  { model: 'author', label: 'Author' },
  { model: 'status', label: 'Status' },
  { model: 'category', label: 'Category' },
  { model: 'amount', label: 'Amount' },
  { model: 'notes', label: 'Notes' },
  { model: 'data_filter', label: 'Data Filter' },
  { model: 'transactioned_at', label: 'Transactioned At' },
  { model: 'created_at', label: 'Date Created' },
  { model: 'updated_at', label: 'Date Updated' },
  { model: 'transaction_id', label: 'Transaction ID' },
  { model: 'percentage', label: 'Percentage' },
  { model: 'client', label: 'Client' },
  { model: 'payer', label: 'Payer' },
  { model: 'client_location_at_transaction', label: 'Client Location at Transaction' },
  { model: 'property', label: 'Property' },
  { model: 'floor', label: 'Floor' },
  { model: 'room', label: 'Room' },
  { model: 'bed', label: 'Bed' },
  { model: 'service', label: 'Service' },
  { model: 'charge_category', label: 'Charge Category' },
  { model: 'expected_payment', label: 'Expected Payment' },
  { model: 'actual_payment', label: 'Actual Payment' },
  { model: 'online_payments', label: 'Online Payments' },
  { model: 'payment_reference', label: 'Payment Reference' },
  { model: 'total_paid', label: 'Total Paid' },
]

export const CLIENT_FINANCIALS_HEALTH = [
  { model: 'week_starting', label: 'Week Starting' },
  { model: 'total_new_charges_last_7_days', label: 'Total New Charges in last 7 days' },
  { model: 'total_balances_due_at_7_day_start', label: 'Total Balances Due at 7 day start' },
  { model: 'total_current_balance', label: 'Total Current Balance' },
  { model: 'total_new_payments_last_7_days', label: 'Total New Payments last 7 days' },
  { model: 'new_balance', label: 'New Balance' },
  { model: 'percent_paid_vs_total_current_balance', label: '% Paid vs Total Current Balance' },
  { model: 'percent_paid_vs_total_new_weekly_charges', label: '% Paid vs Total New Weekly Charges' },
  { model: 'total_new_refunds_last_7_days', label: 'Total New Refunds last 7 days' },
  { model: 'total_new_write_offs_last_7_days', label: 'Total New Write-Offs last 7 days' },
]

export const ORGANIZATION_FINANCIAL_SUMMARY = [
  { model: 'organization_name', label: 'Organization Name' },
  { model: 'charges', label: 'Charges' },
  { model: 'payments', label: 'Payments' },
  { model: 'write_offs', label: 'Write-Offs' },
  { model: 'credits', label: 'Credits' },
  { model: 'refunds', label: 'Refunds' },
]

export const ORGANIZATION_FINANCIAL_TRANSACTIONS = [
  { model: 'name', label: 'Name' },
  { model: 'author', label: 'Author' },
  { model: 'status', label: 'Status' },
  { model: 'category', label: 'Category' },
  { model: 'amount', label: 'Amount' },
  { model: 'notes', label: 'Notes' },
  { model: 'data_filter', label: 'Data Filter' },
  { model: 'transactioned_at', label: 'Transactioned At' },
  { model: 'created_at', label: 'Date Created' },
  { model: 'updated_at', label: 'Date Updated' },
  { model: 'transaction_id', label: 'Transaction ID' },
  { model: 'percentage', label: 'Percentage' },
  { model: 'organization', label: 'Organization' },
  { model: 'payer', label: 'Payer' },
  { model: 'service', label: 'Service' },
  { model: 'charge_category', label: 'Charge Category' },
  { model: 'expected_payment', label: 'Expected Payment' },
  { model: 'actual_payment', label: 'Actual Payment' },
  { model: 'online_payments', label: 'Online Payments' },
  { model: 'payment_reference', label: 'Payment Reference' },
  { model: 'total_paid', label: 'Total Paid' },
]

export const ORGANIZATION_FINANCIALS_HEALTH = [
  { model: 'week_starting', label: 'Week Starting' },
  { model: 'total_new_charges_last_7_days', label: 'Total New Charges in last 7 days' },
  { model: 'total_balances_due_at_7_day_start', label: 'Total Balances Due at 7 day start' },
  { model: 'total_current_balance', label: 'Total Current Balance' },
  { model: 'total_new_payments_last_7_days', label: 'Total New Payments last 7 days' },
  { model: 'new_balance', label: 'New Balance' },
  { model: 'percent_paid_vs_total_current_balance', label: '% Paid vs Total Current Balance' },
  { model: 'percent_paid_vs_total_new_weekly_charges', label: '% Paid vs Total New Weekly Charges' },
  { model: 'total_new_refunds_last_7_days', label: 'Total New Refunds last 7 days' },
  { model: 'total_new_write_offs_last_7_days', label: 'Total New Write-Offs last 7 days' },
]

export const ADMIT_SURVEY = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'status', label: 'Status' },
  { model: 'author', label: 'Author' },
  { model: 'date_and_time', label: 'Date and Time' },
  { model: 'session_end_time', label: 'Session End Time' },
  { model: 'to_what_level_of_care_is_this_client_admitting', label: 'To What level of care is this client admitting?' },
  {
    model: 'what_was_the_clients_employment_status_at_the_time_of_admission',
    label: "What was the client's employment status at the time of admission?",
  },
  { model: 'housing_status_at_the_time_of_admission', label: 'Housing Status at the time of admission?' },
  { model: 'alcohol_days', label: 'Alcohol (Days)' },
  { model: 'cannabis_days', label: 'Cannabis (Days)' },
  { model: 'hallucinogens_days', label: 'Hallucinogens (Days)' },
  { model: 'inhalants_days', label: 'Inhalants (Days)' },
  { model: 'opioids_days', label: 'Opioids (Days)' },
  { model: 'sedatives_hypnotics_or_anxiolytics_days', label: 'Sedatives, hypnotics, or anxiolytics (Days)' },
  { model: 'stimulants_days', label: 'Stimulants (Days)' },
  { model: 'tobacco_days', label: 'Tobacco (Days)' },
  { model: 'other_days', label: 'Other (Days)' },
  { model: 'history_of_iv_drug_use', label: 'History of IV drug use?' },
  { model: 'number_of_past_alcohol_drug_overdoses', label: 'Number of past alcohol/drug overdoses?' },
  { model: 'number_of_previous_sud_treatments', label: 'Number of previous SUD treatments' },
]

export const DEMOGRAPHIC_INFORMATION_FORM = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'status', label: 'Status' },
  { model: 'author', label: 'Author' },
  { model: 'date_and_time', label: 'Date and Time' },
  { model: 'session_end_time', label: 'Session End Time' },
  { model: 'age_at_admission', label: 'Age at Admission' },
  { model: 'gender', label: 'Gender' },
  { model: 'race_ethnicity', label: 'Race/Ethnicity' },
]

export const PROGRESS_MONITORING_SURVEY = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'status', label: 'Status' },
  { model: 'author', label: 'Author' },
  { model: 'date_and_time', label: 'Date and Time' },
  { model: 'session_end_time', label: 'Session End Time' },
  {
    model: 'over_the_last_7_days_how_many_days_have_you_used_alcohol_or_other_drugs',
    label: 'Over the last 7 days, how many days have you used alcohol or other drugs?',
  },
  {
    model: 'over_the_last_7_days_how_much_were_you_bothered_by_cravings_or_urges_to_drink_alcohol_or_use_drugs',
    label: 'Over the last 7 days, how much were you bothered by cravings or urges to drink alcohol or use drugs?',
  },
  {
    model: 'how_confident_are_you_that_you_will_be_able_to_stay_in_recovery_for_the_next_7_days_or_one_week',
    label: 'How confident are you that you will be able to stay in recovery for the next 7 days or one week?',
  },
  {
    model: 'how_much_do_you_feel_understood_and_supported_by_your_counselor_or_primary_caregiver',
    label: 'How much do you feel understood and supported by your counselor or primary caregiver?',
  },
  {
    model: 'to_what_extent_do_you_feel_that_physical_pain_prevents_you_from_doing_what_you_need_to_do',
    label: 'To what extent do you feel that physical pain prevents you from doing what you need to do?',
  },
  {
    model: 'over_the_last_7_days_how_much_have_you_been_troubled_or_bothered_by_psychological_or_emotional_problems',
    label: 'Over the last 7 days, how much have you been troubled or bothered by psychological or emotional problems?',
  },
]

export const OUTCOMES_SURVEY = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'status', label: 'Status' },
  { model: 'author', label: 'Author' },
  { model: 'date_and_time', label: 'Date and Time' },
  { model: 'session_end_time', label: 'Session End Time' },
  { model: 'goals', label: 'Linked Goals, Objectives, Interventions' },
  { model: 'prior_month_services', label: 'Over the last 30 days, have you used any of the following services?' },
  {
    model: 'prior_month_recovery_support_meetings',
    label: 'Over the last 30 days, how many recovery support meetings (e.g., AA, NA, or other peer support groups) have you attended?',
  },
  { model: 'prior_month_substance_use_days', label: 'Over the last 30 days, how many total days have you used alcohol and/or drugs?' },
  {
    model: 'current_medication',
    label: 'Which of the following medications are you currently taking in support of your recovery? (select all that apply)',
  },
  {
    model: 'prior_month_hospital_visits',
    label: 'Over the last 30 days, how many days did you visit the hospital or ER, related to your substance use or recovery?',
  },
  { model: 'prior_month_law_enforcement_incidents', label: 'Over the last 30 days, how many incidents have you had with law enforcement?' },
  { model: 'current_employment_status', label: 'What is your current employment status?' },
  { model: 'relapse_status', label: 'Have you experienced a relapse since discharging from treatment?' },
  { model: 'quality_of_life_rating', label: 'How would you rate your quality of life?' },
  { model: 'nicotine_use_frequency', label: 'How frequently are you using nicotine (smoking, vaping, or dipping)?' },
]

export const RECOVERY_CAPITAL = [
  { label: 'Name' },
  { label: 'Client Name' },
  { label: 'Status' },
  { label: 'Author' },
  { label: 'Date and Time' },
  { label: 'Session End Time' },

  { label: 'I have the financial resources to provide for myself and my family.', model: 'q1' },
  { label: 'I have personal transportation or access to public transportation.', model: 'q2' },
  { label: 'I live in a home and neighborhood that is safe and secure.', model: 'q3' },
  { label: 'I live in an environment free from alcohol and other drugs.', model: 'q4' },
  { label: 'I have an intimate partner supportive of my recovery process.', model: 'q5' },
  { label: 'I have family members who are supportive of my recovery process.', model: 'q6' },
  { label: 'I have friends who are supportive of my recovery process.', model: 'q7' },
  { label: 'I have people close to me (intimate partner, family members, or friends) who are also in recovery.', model: 'q8' },
  { label: 'I have a stable job that I enjoy and that provides for my basic necessities.', model: 'q9' },
  { label: 'I have an education or work environment that is conducive to my long-term recovery.', model: 'q10' },
  {
    label:
      'I continue to participate in a continuing care program of an addiction treatment program, (e.g., groups, alumni association meetings, etc.)',
    model: 'q11',
  },
  { label: 'I have a professional assistance program that is monitoring and supporting my recovery process.', model: 'q12' },
  { label: 'I have a primary care physician who attends to my health problems.', model: 'q13' },
  { label: 'I am now in reasonably good health.', model: 'q14' },
  { label: 'I have an active plan to manage any lingering or potential health problems.', model: 'q15' },
  { label: 'I am on prescribed medication that minimizes my cravings for alcohol and other drugs.', model: 'q16' },
  { label: 'I have insurance that will allow me to receive help for major health problems.', model: 'q17' },
  { label: 'I have access to regular, nutritious meals.', model: 'q18' },
  { label: 'I have clothes that are comfortable, clean and conducive to my recovery activities.', model: 'q19' },
  { label: 'I have access to recovery support groups in my local community.', model: 'q20' },
  { label: 'I have established close affiliation with a local recovery support group.', model: 'q21' },
  { label: 'I have a sponsor (or equivalent) who serves as a special mentor related to my recovery.', model: 'q22' },
  { label: 'I have access to Online recovery support groups.', model: 'q23' },
  { label: 'I have completed or am complying with all legal requirements related to my past.', model: 'q24' },
  { label: 'There are other people who rely on me to support their own recoveries.', model: 'q25' },
  { label: 'My immediate physical environment contains literature, tokens, posters or', model: 'q26' },
  { label: 'I have recovery rituals that are now part of my daily life.', model: 'q27' },
  { label: 'I had a profound experience that marked the beginning or deepening of my commitment to recovery.', model: 'q28' },
  { label: 'I now have goals and great hopes for my future.', model: 'q29' },
  { label: 'I have problem solving skills and resources that I lacked during my years', model: 'q30' },
  { label: 'I feel like I have meaningful, positive participation in my family and', model: 'q31' },
  { label: 'Today I have a clear sense of who I am.', model: 'q32' },
  { label: 'I know that my life has a purpose.', model: 'q33' },
  { label: 'Service to others is now an important part of my life.', model: 'q34' },
  { label: 'My personal values and sense of right and wrong have become clearer and stronger in recent years.', model: 'q35' },
  { label: 'My Score', model: 'score' },
  { label: 'Item numbers on which I scored lowest', model: 'lowest_score' },
  { label: 'Item numbers on which I scored highest', model: 'highest_score' },
]

export const MIRC = [
  { label: 'Name' },
  { label: 'Client Name' },
  { label: 'Status' },
  { label: 'Author' },
  { label: 'Date and Time' },
  { label: 'Session End Time' },

  { label: '1. I actively support other people who are in recovery' },
  { label: '2. My family makes my recovery more difficult' },
  { label: '3. I have at least one friend who supports my recovery' },
  { label: '4. My family supports my recovery' },
  { label: "5. Some people in my life do not think I'll make it in my recovery" },
  { label: '6. I feel alone' },
  { label: "7. I feel like I'm part of a recovery community" },
  { label: '8. My housing situation is helpful for my recovery' },
  { label: '9. I have difficulty getting transportation' },
  { label: '10. My housing situation is unstable' },
  { label: '11. I have enough money every week to buy the basic things I need' },
  { label: '12. Not having enough money makes my recovery more difficult' },
  { label: '13. I can afford the care I need for my health, mental health, and recovery' },
  { label: '14. I have reliable access to a phone and the internet' },
  { label: '15. I find it hard to have fun' },
  { label: '16. I feel physically healthy most days' },
  { label: '17. I am struggling with guilt or shame' },
  { label: '18. I am experiencing a lot of stress' },
  { label: "19. My education and training have prepared me to handle life's challenges" },
  { label: '20. I have problems with my mental health' },
  { label: '21. I feel my life has purpose and meaning' },
  { label: "22. It's hard for me to trust others" },
  { label: '23. I have opportunities to participate in fun activities that do not involve drugs and alcohol' },
  { label: '24. I feel disconnected from my culture or not part of any culture' },
  { label: '25. I feel like an outcast' },
  { label: '26. There are helpful services and resources accessible to me' },
  { label: "27. It's hard to let go of the part of my identity that was linked to my drinking or drug use" },
  { label: '28. My neighborhood or town feels safe' },
  { label: 'Total Score' },
  { label: 'Social Capital' },
  { label: 'Physical Capital' },
  { label: 'Human Capital' },
  { label: 'Cultural Capital' },
]

export const DISCHARGE_SURVEY = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'status', label: 'Status' },
  { model: 'author', label: 'Author' },
  { model: 'date_and_time', label: 'Date and Time' },
  { model: 'session_end_time', label: 'Session End Time' },
  { model: 'from_what_level_of_care_is_this_client_discharging', label: 'From what level of care is this client discharging?' },
  { model: 'discharge_type', label: 'Discharge type' },
  { model: 'inpatient_hospital_or_medical_detox', label: 'Inpatient Hospital or Medical Detox' },
  { model: 'residential', label: 'Residential' },
  { model: 'partial_hospitalization', label: 'Partial Hospitalization' },
  { model: 'intensive_outpatient', label: 'Intensive Outpatient' },
  { model: 'outpatient', label: 'Outpatient' },
  { model: 'sober_living_recovery_residence', label: 'Sober Living/Recovery Residence' },
  { model: 'recovery_support', label: 'Recovery Support' },
  { model: 'other_type_of_program', label: 'Other type of program' },
  { model: 'payment_type', label: 'Payment type' },
  {
    model: 'what_is_the_primary_diagnostic_substance_use_disorder_category',
    label: 'What is the primary diagnostic Substance Use Disorder category?',
  },
  { model: 'mat_medications_received', label: 'MAT medications received' },
  { model: 'mental_health_diagnosis', label: 'Mental Health Diagnosis' },
  {
    model: 'was_any_portion_of_the_clients_care_delivered_remotely_through_telehealth',
    label: "Was any portion of the client's care delivered remotely/through telehealth?",
  },
]

export const INSURANCE_POLICIES = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'date_of_birth', label: 'Date of Birth' },
  { model: 'admit_date', label: 'Admit Date' },
  { model: 'discharge_date', label: 'Discharge Date' },
  { model: 'insurance_type', label: 'Insurance Type' },
  { model: 'insurance_payer', label: 'Insurance Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'insurance_group_id', label: 'Insurance Group ID' },
  { model: 'insurance_member_id', label: 'Insurance Member ID' },
  { model: 'insurance_phone_number', label: 'Insurance Phone Number' },
  { model: 'plan_type', label: 'Plan Type' },
  { model: 'policy_effective_date', label: 'Policy Effective Date' },
  { model: 'policy_reset_date', label: 'Policy Reset Date' },
  { model: 'policy_termination_date', label: 'Policy Termination Date' },
  { model: 'notes', label: 'Notes' },
  { model: 'first_name', label: 'First Name' },
  { model: 'last_name', label: 'Last Name' },
  { model: 'date_of_birth', label: 'Date Of Birth' },
  { model: 'sex', label: 'Sex' },
  { model: 'relationship_to_client', label: 'Relationship To Client' },
  { model: 'email', label: 'Email' },
  { model: 'phone_no', label: 'Phone No' },
  { model: 'address_line_1', label: 'Address Line 1' },
  { model: 'address_line_2', label: 'Address Line 2' },
  { model: 'city', label: 'City' },
  { model: 'state', label: 'State' },
  { model: 'zip_code', label: 'Zip Code' },
]

export const INSURANCE_BILLING = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'client', label: 'Client' },
  { model: 'date_of_service', label: 'Date of Service' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'description', label: 'Description' },
  { model: 'amount', label: 'Amount' },
  { model: 'billed_date', label: 'Billed Date' },
]

export const INSURANCE_PAYMENTS_BY_PAYMENT_DATE = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'client', label: 'Client' },
  { model: 'date_of_service', label: 'Date of Service' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'charge_description', label: 'Charge Description' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'allowed_amount', label: 'Allowed Amount' },
  { model: 'paid_amount', label: 'Paid Amount' },
  { model: 'deductible_amount', label: 'Deductible Amount' },
  { model: 'copay_amount', label: 'Copay Amount' },
  { model: 'coinsurance_amount', label: 'Coinsurance Amount' },
  { model: 'not_payable_amount', label: 'Not Payable Amount' },
  { model: 'adjusted_amount', label: 'Adjusted Amount' },
  { model: 'balance_due_patient', label: 'Balance Due Patient' },
  { model: 'payment_ref', label: 'Payment Ref #' },
  { model: 'payment_date', label: 'Payment Date' },
  { model: 'posted_date', label: 'Posted Date' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'date_of_service_to_posted', label: 'Date of Service to Posted' },
  { model: 'date_of_service_to_paid', label: 'Date of Service to Paid' },
  { model: 'billed_to_paid', label: 'Billed to Paid' },
]

export const INSURANCE_PAYMENTS_BY_POSTED_DATE = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'client', label: 'Client' },
  { model: 'date_of_service', label: 'Date of Service' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'charge_description', label: 'Charge Description' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'allowed_amount', label: 'Allowed Amount' },
  { model: 'paid_amount', label: 'Paid Amount' },
  { model: 'deductible_amount', label: 'Deductible Amount' },
  { model: 'copay_amount', label: 'Copay Amount' },
  { model: 'coinsurance_amount', label: 'Coinsurance Amount' },
  { model: 'not_payable_amount', label: 'Not Payable Amount' },
  { model: 'adjusted_amount', label: 'Adjusted Amount' },
  { model: 'balance_due_patient', label: 'Balance Due Patient' },
  { model: 'payment_ref', label: 'Payment Ref #' },
  { model: 'payment_date', label: 'Payment Date' },
  { model: 'posted_date', label: 'Posted Date' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'date_of_service_to_posted', label: 'Date of Service to Posted' },
  { model: 'date_of_service_to_paid', label: 'Date of Service to Paid' },
  { model: 'billed_to_paid', label: 'Billed to Paid' },
]

export const INSURANCE_AGING = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'client', label: 'Client' },
  { model: 'claim_from_date', label: 'Claim From Date' },
  { model: 'claim_to_date', label: 'Claim To Date' },
  { model: 'services_billed', label: 'Services Billed' },
  { model: 'internal_status', label: 'Internal Status' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'billed_date', label: 'Billed Date' },
  { model: 'days_in_aging', label: 'Days in Aging' },
  { model: '0_30_days', label: '0-30 Days' },
  { model: '31_60_days', label: '31-60 Days' },
  { model: '61_90_days', label: '61-90 Days' },
  { model: '91_days', label: '91+ Days' },
]

export const NEW_INSURANCE_PAYMENTS_BY_PAYMENT_DATE = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'client_name', label: 'Client Name' },
  { model: 'service_start_date', label: 'Service Start Date' },
  { model: 'service_end_date', label: 'Service End Date' },
  { model: 'service_line_status', label: 'Service Line Status' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'service_name', label: 'Service Name' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'allowed_amount', label: 'Allowed Amount' },
  { model: 'insurance_paid', label: 'Insurance Paid' },
  { model: 'deductible', label: 'Deductible' },
  { model: 'co_pay', label: 'Co-Pay' },
  { model: 'coinsurance', label: 'Coinsurance' },
  { model: 'claim_adjustment_group_code', label: 'Claim Adjustment Group Code' },
  { model: 'adjustment_reason_code', label: 'Adjustment Reason Code' },
  { model: 'adjustment_amount', label: 'Adjustment Amount' },
  { model: 'balance_due_client', label: 'Balance Due Client' },
  { model: 'balance_billed_amount', label: 'Balance Billed Amount' },
  { model: 'payment_reference', label: 'Payment Reference' },
  { model: 'total_payment_amount', label: 'Total Payment Amount' },
  { model: 'payment_date', label: 'Payment Date' },
  { model: 'posted_date', label: 'Posted Date' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'claim_id', label: 'Claim Id' },
  { model: 'service_line_id', label: 'Service Line Id' },
  { model: 'payment_service_line_id', label: 'Payment Service Line Id' },
  { model: 'connected_eobs', label: 'Connected EOBs' },
  { model: 'connected_eras', label: 'Connected ERAs' },
]

export const NEW_INSURANCE_PAYMENTS_BY_POSTED_DATE = [
  { model: 'payer', label: 'Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'client_name', label: 'Client Name' },
  { model: 'service_start_date', label: 'Service Start Date' },
  { model: 'service_end_date', label: 'Service End Date' },
  { model: 'service_line_status', label: 'Service Line Status' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'service_name', label: 'Service Name' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'allowed_amount', label: 'Allowed Amount' },
  { model: 'insurance_paid', label: 'Insurance Paid' },
  { model: 'deductible', label: 'Deductible' },
  { model: 'co_pay', label: 'Co-Pay' },
  { model: 'coinsurance', label: 'Coinsurance' },
  { model: 'claim_adjustment_group_code', label: 'Claim Adjustment Group Code' },
  { model: 'adjustment_reason_code', label: 'Adjustment Reason Code' },
  { model: 'adjustment_amount', label: 'Adjustment Amount' },
  { model: 'balance_due_client', label: 'Balance Due Client' },
  { model: 'balance_billed_amount', label: 'Balance Billed Amount' },
  { model: 'payment_reference', label: 'Payment Reference' },
  { model: 'total_payment_amount', label: 'Total Payment Amount' },
  { model: 'payment_date', label: 'Payment Date' },
  { model: 'posted_date', label: 'Posted Date' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'claim_id', label: 'Claim Id' },
  { model: 'service_line_id', label: 'Service Line Id' },
  { model: 'payment_service_line_id', label: 'Payment Service Line Id' },
  { model: 'connected_eobs', label: 'Connected EOBs' },
  { model: 'connected_eras', label: 'Connected ERAs' },
]

export const NEW_INSURANCE_AGING = [
  { model: 'insurance_payer', label: 'Insurance Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'client_name', label: 'Client Name' },
  { model: 'service_start_date', label: 'Service Start Date' },
  { model: 'service_end_date', label: 'Service End Date' },
  { model: 'service_names', label: 'Service Names' },
  { model: 'procedure_codes', label: 'Procedure Codes' },
  { model: 'revenue_codes', label: 'Revenue Codes' },
  { model: 'internal_status', label: 'Internal Status' },
  { model: 'flag_status', label: 'Flag Status' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'billed_date', label: 'Billed Date' },
  { model: 'days_in_aging', label: 'Days In Aging' },
  { model: '0_30_days', label: '0-30 Days' },
  { model: '31_60_days', label: '31-60 Days' },
  { model: '61_90_days', label: '61-90 Days' },
  { model: '91_days', label: '91+ Days' },
  { model: 'last_updated_date', label: 'Last Updated Date' },
  { model: 'most_recent_claim_note', label: 'Most Recent Claim Note' },
  { model: 'note_author', label: 'Note Author' },
  { model: 'note_date', label: 'Note Date' },
]

export const NEW_INSURANCE_BILLING_BY_SERVICE_DATE = [
  { model: 'insurance_payer', label: 'Insurance Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'client_name', label: 'Client Name' },
  { model: 'service_start_date', label: 'Service Start Date' },
  { model: 'service_end_date', label: 'Service End Date' },
  { model: 'service_name', label: 'Service Name' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'revenue_code', label: 'Revenue Code' },
  { model: 'modifier_code', label: 'Modifier Code' },
  { model: 'units', label: 'Units' },
  { model: 'unit_type', label: 'Unit Type' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'last_bill_date', label: 'Last Bill Date' },
  { model: 'billing_provider_name', label: 'Billing Provider Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'service_line_id', label: 'Service Line ID' },
  { model: 'claim_created_by', label: 'Claim Created By' },
]

export const NEW_INSURANCE_BILLING_BY_BILL_DATE = [
  { model: 'insurance_payer', label: 'Insurance Payer' },
  { model: 'plan_name', label: 'Plan Name' },
  { model: 'client_name', label: 'Client Name' },
  { model: 'service_start_date', label: 'Service Start Date' },
  { model: 'service_end_date', label: 'Service End Date' },
  { model: 'service_name', label: 'Service Name' },
  { model: 'procedure_code', label: 'Procedure Code' },
  { model: 'revenue_code', label: 'Revenue Code' },
  { model: 'modifier_code', label: 'Modifier Code' },
  { model: 'units', label: 'Units' },
  { model: 'unit_type', label: 'Unit Type' },
  { model: 'billed_amount', label: 'Billed Amount' },
  { model: 'initial_bill_date', label: 'Initial Bill Date' },
  { model: 'last_bill_date', label: 'Last Bill Date' },
  { model: 'billing_provider_name', label: 'Billing Provider Name' },
  { model: 'claim_id', label: 'Claim ID' },
  { model: 'service_line_id', label: 'Service Line ID' },
  { model: 'claim_created_by', label: 'Claim Created By' },
]

export const DOCUMENTATION_STATUS = [
  { model: 'name', label: 'Name' },
  { model: 'client_status', label: 'Client Status' },
  { model: 'applicant_create_date', label: 'Applicant Create Date' },
  { model: '1st_evob_date', label: '1st eVOB Date' },
  { model: '1st_vob_call_date', label: '1st VOB Call Date' },
  { model: 'accepted_date', label: 'Accepted Date' },
  { model: 'admitted_date', label: 'Admitted Date' },
  { model: '1st_clinical_assessment_date', label: '1st Clinical Assessment Date' },
  { model: '1st_medical_assessment_date', label: '1st Medical Assessment Date' },
  { model: '1st_clinical_record_date', label: '1st Clinical Record Date' },
  { model: '1st_medical_record_date', label: '1st Medical Record Date' },
  { model: 'last_clinical_record_date', label: 'Last Clinical Record Date' },
  { model: 'last_medical_record_date', label: 'Last Medical Record Date' },
]

export const DOCUMENTATION_TIME = [
  { model: 'author', label: 'Author' },
  { model: 'supervisor', label: 'Supervisor' },
  { model: 'status', label: 'Status' },
  { model: 'session_start', label: 'Session Start' },
  { model: 'session_end', label: 'Session End' },
  { model: 'author_create_date', label: 'Author Create Date' },
  { model: 'time_between_start_create', label: 'Time Between Start & Create' },
  { model: 'signed_off_date', label: 'Signed Off Date' },
  { model: 'author_signed_off_date', label: 'Author Signed Off Date' },
  { model: 'author_submit_date', label: 'Author Submit Date' },
  { model: 'time_between_create_submit', label: 'Time Between Create & Submit' },
  { model: 'time_between_submit_sign_off', label: 'Time Between Submit & Sign Off' },
]

export const ALL_REFERRALS = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'client_status', label: 'Client Status' },
  { model: 'admission_status', label: 'Admission Status' },
  { model: 'referral_in', label: 'Referral In' },
  { model: 'referral_in_contact', label: 'Referral In Contact' },
  { model: 'referral_in_notes', label: 'Referral In Notes' },
  { model: 'referral_out', label: 'Referral Out' },
  { model: 'referral_out_contact', label: 'Referral Out Contact' },
  { model: 'referral_out_notes', label: 'Referral Out Notes' },
  { model: 'sex', label: 'Sex' },
  { model: 'gender_identity', label: 'Gender Identity' },
  { model: 'date_added', label: 'Date Added' },
  { model: 'est_arrival_date', label: 'Est Arrival Date' },
  { model: 'admit_date', label: 'Admit Date' },
  { model: 'est_discharge_date', label: 'Est Discharge Date' },
  { model: 'discharge_date', label: 'Discharge Date' },
]

export const ALL_CLIENT_CONTACTS = [
  { model: 'first_name', label: 'First Name' },
  { model: 'middle_name', label: 'Middle Name' },
  { model: 'last_name', label: 'Last Name' },
  { model: 'sex', label: 'Sex' },
  { model: 'status', label: 'Status' },
  { model: 'date_of_birth', label: 'Date of Birth' },
  { model: 'relationship', label: 'Relationship' },
  { model: 'role', label: 'Role' },
  { model: 'phone', label: 'Phone' },
  { model: 'phone_extension', label: 'Phone Extension' },
  { model: 'email', label: 'Email' },
  { model: 'address', label: 'Address' },
  { model: 'primary_insurer', label: 'Primary Insurer' },
  { model: 'roi', label: 'ROI' },
  { model: 'is_primary_payer', label: 'Is Primary Payer' },
  { model: 'is_emergency', label: 'Is Emergency' },
  { model: 'preferred_contact_method', label: 'Preferred Contact Method' },
  { model: 'is_shared_with_portal', label: 'Is Shared With Portal' },
  { model: 'portal_notes', label: 'Portal Notes' },
  { model: 'whatsapp', label: 'Whatsapp' },
  { model: 'facebook_id', label: 'Facebook ID' },
  { model: 'skype_id', label: 'Skype ID' },
  { model: 'zoom_url', label: 'Zoom URL' },
  { model: 'google_meet_url', label: 'Google Meet URL' },
  { model: 'preferred_method_of_communication', label: 'Preferred Method of Communication' },
  { model: 'shared_with_client_portal', label: 'Shared with Client Portal' },
  { model: 'notes', label: 'Notes' },
  { model: 'created_at', label: 'Created At' },
]

export const AGREEMENTS = [
  { model: 'name', label: 'Name' },
  { model: 'reference_name', label: 'Client Name' },
  { model: 'cosigner_name', label: 'Staff Signee' },
  { model: 'status', label: 'Status' },
  { model: 'contract_count', label: 'Signees' },
  { model: 'author_name', label: 'Added By' },
  { model: 'review_cycle', label: 'Review Cycle' },
  { model: 'dated_at', label: 'Effective Date' },
  { model: 'expired_at', label: 'Expired At' },
  { model: 'next_review_at', label: 'next Review Date' },
  { model: 'reviewed_at', label: 'Last Review Date' },
  { model: 'updated_at', label: 'Last Updated' },
]

export const BED_ASSIGNMENTS = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'started_at', label: 'Started At' },
  { model: 'ended_at', label: 'Ended At' },
  { model: 'status', label: 'Status' },
  { model: 'location', label: 'Location' },
  { model: 'floor', label: 'Floor' },
  { model: 'room', label: 'Room' },
  { model: 'bed', label: 'Bed' },
]

export const PROGRAM_ASSIGNMENTS = [
  { model: 'name', label: 'Name' },
  { model: 'started_at', label: 'Started At' },
  { model: 'ended_at', label: 'Ended At' },
  { model: 'status', label: 'Status' },
  { model: 'program', label: 'Program' },
  { model: 'phase', label: 'Phase' },
  { model: 'seat', label: 'Seat' },
]

export const DISCHARGES = [
  { model: 'name', label: 'Name' },
  { model: 'client', label: 'Client' },
  { model: 'status', label: 'Status' },
  { model: 'relapse_status', label: 'Relapse Status' },
  { model: 'discharge_type', label: 'Discharge Type' },
  { model: 'level_of_care', label: 'Level Of Care' },
  { model: 'reason_for_discharge', label: 'Reason for discharge' },
  { model: 'reason_for_placement', label: 'Reason for placement' },
  { model: 'admission_date', label: 'Admission Date' },
  { model: 'discharge_date', label: 'Discharge Date' },
  { model: 'length_of_stay', label: 'Length of Stay' },
  { model: 'last_edited', label: 'Last Edited' },
  { model: 'added_by', label: 'Added By' },
]

export const TREATMENT_EPISODES = [
  { model: 'title', label: 'Title' },
  { model: 'client', label: 'Client' },
  { model: 'status', label: 'Status' },
  { model: 'authorizations', label: 'Authorizations' },
  { model: 'est_arrival_date', label: 'Est. Arrival Date' },
  { model: 'admit_date', label: 'Admit Date' },
  { model: 'est_discharge_date', label: 'Est. Discharge Date' },
  { model: 'discharge_date', label: 'Discharge Date' },
  { model: 'decline_date', label: 'Decline Date' },
  { model: 'incoming_notes', label: 'Incoming Notes' },
  { model: 'waitlist_notes', label: 'Waitlist Notes' },
  { model: 'no_show_notes', label: 'No Show Notes' },
  { model: 'discharge_notes', label: 'Discharge Notes' },
  { model: 'discharge_type', label: 'Discharge Type' },
  { model: 'additional_information', label: 'Additional Information' },
  { model: 'accepted_by', label: 'Accepted By' },
  { model: 'admitted_by', label: 'Admitted By' },
  { model: 'discharged_by', label: 'Discharged By' },
  { model: 'declined_by', label: 'Declined By' },
  { model: 'date_added', label: 'Date Added' },
  { model: 'last_updated', label: 'Last Updated' },
  { model: 'client_created', label: 'Client Created' },
  { model: 'client_applied', label: 'Client Applied' },
  { model: 'client_accepted', label: 'Client Accepted' },
  { model: 'client_declined', label: 'Client Declined' },
  { model: 'client_admitted', label: 'Client Admitted' },
  { model: 'client_discharged', label: 'Client Discharged' },
  { model: 'days_between_applied_and_declined', label: 'Days Between Applied and Declined' },
  { model: 'days_between_applied_and_accepted', label: 'Days Between Applied and Accepted' },
  { model: 'days_between_applied_and_admitted', label: 'Days Between Applied and Admitted' },
]

export const CLINICAL_NOTES = [
  { model: 'name', label: 'Name' },
  { model: 'client', label: 'Client' },
  { model: 'supervisor', label: 'Supervisor' },
  { model: 'signed_by', label: 'Signed By Staff' },
  { model: 'status', label: 'Status' },
  { model: 'duration', label: 'Duration' },
  { model: 'session_date', label: 'Session Date' },
  { model: 'date_added', label: 'Date Added' },
  { model: 'added_by', label: 'Added By' },
  { model: 'tags', label: 'Tags' },
  { model: 'subjective', label: 'Subjective' },
  { model: 'objective', label: 'Objective' },
  { model: 'data', label: 'Data' },
  { model: 'assessment', label: 'Assessment' },
  { model: 'goals', label: 'Goals' },
  { model: 'intervention', label: 'Intervention' },
  { model: 'response', label: 'Response' },
  { model: 'plan', label: 'Plan' },
  { model: 'individual_assessment', label: 'Individual Assessment' },
  { model: 'notes', label: 'Notes' },
  { model: 'supervisor_signed_at', label: 'Supervisor Signed At' },
  { model: 'submitted_at', label: 'Submitted At' },
  { model: 'service_to_submit', label: 'Service to Submit' },
  { model: 'submit_to_signed', label: 'Submit to Signed' },
]

export const SHIFT_NOTES = [
  { model: 'category', label: 'Category' },
  { model: 'dated_at', label: 'Dated At' },
  { model: 'description', label: 'Description' },
  { model: 'residents', label: 'Residents' },
  { model: 'employees', label: 'Employees' },
  { model: 'house', label: 'House' },
  { model: 'organization', label: 'Organization' },
  { model: 'author', label: 'Author' },
]

export const SAFETY_CHECKS = [
  { model: 'notes', label: 'Notes' },
  { model: 'client', label: 'Client' },
  { model: 'dated_at', label: 'Dated At' },
  { model: 'employees', label: 'Employees' },
  { model: 'house', label: 'House' },
  { model: 'organization', label: 'Organization' },
  { model: 'author', label: 'Author' },
]

export const SERVICE_EPISODES = [
  { model: 'client', label: 'Client' },
  { model: 'name', label: 'Name' },
  { model: 'status', label: 'Status' },
  { model: 'type', label: 'Type' },
  { model: 'category', label: 'Category' },
  { model: 'subcategory', label: 'Subcategory' },
  { model: 'started_at', label: 'Start Date' },
  { model: 'end_at', label: 'End Date' },
  { model: 'updated_at', label: 'Last Edited' },
  { model: 'notes', label: 'Notes' },
  { model: 'author', label: 'Author' },
]

export const ROUNDS = [
  { model: 'round', label: 'Round' },
  { model: 'status', label: 'Status' },
  { model: 'signature_status', label: 'Signature Status' },
  { model: 'started_at', label: 'Start Date & Time' },
  { model: 'ended_at', label: 'End Date & Time' },
  { model: 'staff', label: 'Staff' },
  { model: 'supervisor', label: 'Supervisor' },
  { model: 'created_at', label: 'Date Added' },
  { model: 'updated_at', label: 'Date Updated' },
  { model: 'author', label: 'Author' },
]

export const PEER_NOTES = [
  { model: 'name', label: 'Name' },
  { model: 'client', label: 'Client' },
  { model: 'status', label: 'Status' },
  { model: 'duration', label: 'Duration' },
  { model: 'session_date', label: 'Session Date' },
  { model: 'date_added', label: 'Date Added' },
  { model: 'added_by', label: 'Added By' },
  { model: 'tags', label: 'Tags' },
  { model: 'subjective', label: 'Subjective' },
  { model: 'objective', label: 'Objective' },
  { model: 'data', label: 'Data' },
  { model: 'assessment', label: 'Assessment' },
  { model: 'goals', label: 'Goals' },
  { model: 'intervention', label: 'Intervention' },
  { model: 'response', label: 'Response' },
  { model: 'plan', label: 'Plan' },
  { model: 'individual_assessment', label: 'Individual Assessment' },
  // { model: 'goals', label: 'Goals' },
  // { model: 'objectives', label: 'Objectives' },
  // { model: 'interventions', label: 'Interventions' },
  { model: 'supervisor_signed_at', label: 'Supervisor Signed At' },
  { model: 'submitted_at', label: 'Submitted At' },
  { model: 'service_to_submit', label: 'Service to Submit' },
  { model: 'submit_to_signed', label: 'Submit to Signed' },
]

export const DAILY_NOTES = [
  { model: 'name', label: 'Name' },
  { model: 'client', label: 'Client' },
  { model: 'status', label: 'Status' },
  { model: 'duration', label: 'Duration' },
  { model: 'session_date', label: 'Session Date' },
  { model: 'date_added', label: 'Date Added' },
  { model: 'added_by', label: 'Added By' },
  { model: 'tags', label: 'Tags' },
  { model: 'subjective', label: 'Subjective' },
  { model: 'objective', label: 'Objective' },
  { model: 'data', label: 'Data' },
  { model: 'assessment', label: 'Assessment' },
  { model: 'goals', label: 'Goals' },
  { model: 'intervention', label: 'Intervention' },
  { model: 'response', label: 'Response' },
  { model: 'plan', label: 'Plan' },
  { model: 'individual_assessment', label: 'Individual Assessment' },
  // { model: 'goals', label: 'Goals' },
  // { model: 'objectives', label: 'Objectives' },
  // { model: 'interventions', label: 'Interventions' },
  { model: 'supervisor_signed_at', label: 'Supervisor Signed At' },
  { model: 'submitted_at', label: 'Submitted At' },
  { model: 'service_to_submit', label: 'Service to Submit' },
  { model: 'submit_to_signed', label: 'Submit to Signed' },
]

export const EVENTS = [
  { model: 'title', label: 'Title' },
  { model: 'category', label: 'Category' },
  { model: 'status', label: 'Status' },
  { model: 'description', label: 'Description' },
  { model: 'author', label: 'Author' },
  { model: 'started_at', label: 'Started At' },
  { model: 'ended_at', label: 'Ended At' },
  { model: 'all_day_event', label: 'All Day Event' },
  { model: 'event_repeats', label: 'Event Repeats' },
  { model: 'event_repeats_until', label: 'Event Repeats Until' },
  { model: 'meeting_type', label: 'Meeting Type' },
  { model: 'meeting_place', label: 'Meeting Place' },
  { model: 'house', label: 'House' },
  { model: 'office', label: 'Office' },
  { model: 'organization', label: 'Organization' },
  { model: 'room', label: 'Room' },
  { model: 'place_details', label: 'Place Details' },
  { model: 'send_reminder_notifications', label: 'Send Reminder Notifications' },
  { model: 'resident_attendees', label: 'Resident Attendees' },
  { model: 'lead_employee_attendees', label: 'Lead Employee Attendees' },
  { model: 'employee_attendees', label: 'Employee Attendees' },
  { model: 'internal_notes', label: 'Internal Notes' },
  { model: 'is_after_session_completed', label: 'Is After Session Completed' },
  { model: 'after_session_completed_at', label: 'After Session Completed At' },
]

export const FORM_SUBMISSIONS = [
  { model: 'title', label: 'Form Submission columns are generated programatically, based on the inputs in each Form.' },
]

export const COMMUNICATIONS = [
  { model: 'contact_source', label: 'Contact Source' },
  { model: 'contact_source_status', label: 'Contact Source Status' },
  { model: 'contact_name', label: 'Contact Name' },
  { model: 'contacted_at', label: 'Contacted At' },
  { model: 'employee_name', label: 'Employee Name' },
  { model: 'category', label: 'Category' },
  { model: 'reason', label: 'Notes' },
]

export const ORGANIZATIONS = [
  { model: 'organization_name', label: 'Organization Name' },
  { model: 'category', label: 'Category' },
  { model: 'subcategory', label: 'Subcategory' },
  { model: 'email', label: 'Email' },
  { model: 'phone', label: 'Phone' },
  { model: 'website', label: 'Website' },
  { model: 'address', label: 'Address' },
  { model: 'additional_info', label: 'Additional Info' },
  { model: 'npi', label: 'NPI' },
  { model: 'whatsapp', label: 'Whatsapp' },
  { model: 'facebook', label: 'Facebook' },
  { model: 'skype', label: 'Skype' },
  { model: 'zoom_url', label: 'Zoom Url' },
  { model: 'google_meet_url', label: 'Google Meet Url' },
  { model: 'created_at', label: 'Created At' },
]

export const ORGANIZATION_CONTACTS = [
  { model: 'first_name', label: 'First Name' },
  { model: 'middle_name', label: 'Middle Name' },
  { model: 'last_name', label: 'Last Name' },
  { model: 'organization', label: 'Organization' },
  { model: 'status', label: 'Status' },
  { model: 'sex', label: 'Sex' },
  { model: 'role', label: 'Role' },
  { model: 'npi', label: 'NPI' },
  { model: 'phone', label: 'Phone' },
  { model: 'phone_extension', label: 'Phone Extension' },
  { model: 'email', label: 'Email' },
  { model: 'organization_address', label: 'Organization Address' },
  { model: 'whatsapp', label: 'Whatsapp' },
  { model: 'facebook_id', label: 'Facebook ID' },
  { model: 'skype_id', label: 'Skype ID' },
  { model: 'zoom_url', label: 'Zoom URL' },
  { model: 'google_meet_url', label: 'Google Meet URL' },
  { model: 'preferred_method_of_communication', label: 'Preferred Method of Communication' },
  { model: 'shared_with_client_portal', label: 'Shared with Client Portal' },
  { model: 'portal_notes', label: 'Portal Notes' },
  { model: 'created_at', label: 'Created At' },
]

export const EMPLOYEES = [
  { model: 'title', label: 'Title' },
  { model: 'name', label: 'Name' },
  { model: 'position', label: 'Position' },
  { model: 'date_of_birth', label: 'Date of Birth' },
  { model: 'phone_number', label: 'Phone Number' },
  { model: 'email', label: 'Email' },
  { model: 'sex', label: 'Sex' },
  { model: 'ssn', label: 'SSN' },
  { model: 'address', label: 'Address' },
  { model: 'additional_info', label: 'Additional Info' },
  { model: 'is_supervisor', label: 'Is Supervisor' },
  { model: 'added_by', label: 'Added By' },
  { model: 'is_shared_with_portal', label: 'Is Shared With Portal' },
  { model: 'portal_notes', label: 'Portal Notes' },
  { model: 'last_logged_in_at', label: 'Last Logged in At' },
  { model: 'npi', label: 'NPI' },
  { model: 'whatsapp', label: 'WhatsApp' },
  { model: 'facebook', label: 'Facebook' },
  { model: 'skype', label: 'Skype' },
  { model: 'zoom', label: 'Zoom' },
  { model: 'google_meet', label: 'Google Meet' },
  { model: 'active_to_do', label: '# Active To-Do' },
  { model: 'files', label: '# Files' },
  { model: 'shift_notes', label: '# Shift Notes' },
  { model: 'ua_tests', label: '# UA Tests' },
  { model: 'breathalyzer_tests', label: '# Breathalyzer Tests' },
  { model: 'vital_signs', label: '# Vital Signs' },
  { model: 'communicable_disease_tests', label: '# Communicable Disease Tests' },
]

export const STAFF_PRODUCTIVITY = [
  { model: 'name', label: 'Name' },
  { model: 'logins', label: '# Logins' },
  { model: 'created_to_do', label: '# Created To-Do' },
  { model: 'completed_to_do', label: '# Completed To-Do' },
  { model: 'created_files', label: '# Created Files' },
  { model: 'created_shift_notes', label: '# Created Shift Notes' },
  { model: 'created_location_check_ins', label: '# Created Location CheckIns' },
  { model: 'created_applications', label: '# Created Applications' },
  { model: 'created_agreements', label: '# Created Agreements' },
  { model: 'admissions_accepted', label: '# Admissions Accepted' },
  { model: 'admissions_admitted', label: '# Admissions Admitted' },
  { model: 'admissions_discharged', label: '# Admissions Discharged' },
  { model: 'created_contacts', label: '# Created Contacts' },
  { model: 'created_clinical_notes', label: '# Created Clinical Notes' },
  { model: 'created_ua_tests', label: '# Created UA Tests' },
  { model: 'created_breathalyzer_tests', label: '# Created Breathalyzer Tests' },
  { model: 'created_vital_signs', label: '# Created Vital Signs' },
  { model: 'created_communicable_disease_tests', label: '# Created Communicable Disease Tests' },
  { model: 'created_medications', label: '# Created Medications' },
  { model: 'created_medication_logs', label: '# Created Medication Logs' },
  { model: 'created_communications', label: '# Created Communications' },
  { model: 'created_message_board_post', label: '# Created Message Board Post' },
  { model: 'created_events', label: '# Created Events' },
  { model: 'chat_messages_sent', label: '# Chat Messages Sent' },
  { model: 'created_clinical_assessments', label: '# Created Clinical Assessments' },
  { model: 'created_progress_reviews', label: '# Created Progress Reviews' },
  { model: 'created_discharges', label: '# Created Discharges' },
  { model: 'created_medical_assessments', label: '# Created Medical Assessments' },
  { model: 'created_nursing', label: '# Created Nursing' },
  { model: 'created_treatment_plans', label: '# Created Treatment Plans' },
  { model: 'created_insurance_authorizations', label: '# Created Insurance Authorizations' },
  { model: 'created_recovery_coachings', label: '# Created Recovery Coachings' },
  { model: 'created_physician_orders', label: '# Created Physician Orders' },
  { model: 'created_evobs', label: '# Created EVOBs' },
]

export const NOTIFICATIONS_BLACKLIST = [
  { model: 'name', label: 'Name' },
  { model: 'blocked_email', label: 'Blocked Email' },
  { model: 'blocked_phone_number', label: 'Blocked Phone Number' },
  { model: 'created_at', label: 'Created At' },
]

export const ROSTER = [
  { model: 'client', label: 'Client' },
  { model: 'service_date', label: 'Service Date' },
  { model: 'insurance', label: 'Insurance' },
  { model: 'start', label: 'Start' },
  { model: 'end', label: 'End' },
  { model: 'sum_of_time', label: 'Sum of Time' },
  { model: 'documentation_type', label: 'Documentation Type' },
  { model: 'presence', label: 'Presence' },
  { model: 'author', label: 'Author' },
  { model: 'author_create_date_time', label: 'Author Create Date/time' },
  { model: 'author_submit_date_time', label: 'Author Submit Date/time' },
  { model: 'author_signed_off_date_time', label: 'Author Signed off Date/time' },
  { model: 'supervisor', label: 'Supervisor' },
  { model: 'signed_off_date_time', label: 'Signed off Date/time' },
  { model: 'status', label: 'Status' },
  { model: 'diagnosis_codes', label: 'Diagnosis Codes' },
  { model: 'authorization_within_service_time', label: 'Authorization within Service time' },
  { model: 'authorization_level_of_care', label: 'Authorization Level of Care' },
  { model: 'client_level_of_care', label: 'Client Level of Care' },
  { model: 'sessions_authorized', label: 'Sessions Authorized' },
  { model: 'authorization', label: 'Authorization #' },
  { model: 'documentation_link', label: 'Documentation Link' },
  { model: 'programs', label: 'Programs' },
  { model: 'tags', label: 'Tags' },
]

export const LOCATION_CHECKS = [
  { model: 'client_name', label: 'Client Name' },
  { model: 'category', label: 'Category' },
  { model: 'location', label: 'Location' },
  { model: 'checked_in_at', label: 'Checked-In At' },
  { model: 'checked_out_at', label: 'Checked-Out At' },
  { model: 'duration', label: 'Duration' },
  { model: 'device_distance_from_location', label: 'Device Distance From Location' },
  { model: 'notes', label: 'Notes' },
]
