import React from 'react'
import { darken, transparentize } from 'polished'
import { Route, Switch, Redirect, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import size from 'lodash/size'

import { COLORS, MEDIA_QUERY, SHADOW } from '@behavehealth/theme'
import { useAPIwithNoAbort } from '@behavehealth/hooks/useAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Nav, NavGroup, NavItem, Select, Option, Page, HelpTagIframe, PageLoader } from '@behavehealth/components'

import Channel from './channel'
import MessageBlasts from './message_blasts'

const pageConfig = {
  feature: 'clients_chat',
  help: <HelpTagIframe id="clients_chat" />,
  marketingID: 'clients_chat',
}

const ClientsChat: React.FC = () => {
  const [selection, setSelection] = React.useState('all')
  const { url } = useRouteMatch()

  let channels = useSelector((state: any) => state.data?.channels?.data)
  const isLoading = useSelector((state: any) => state.data?.channels?.loading)

  useAPIwithNoAbort('channels', `/chat/channels/clients`)

  if (isLoading) return <PageLoader />

  // const isEmpty = size(channels?.all) === 0
  // if (isLoading || isEmpty) {
  //   return <Page title="Clients Chat" isLoading={isLoading} emptyDescription="There are no client chats to display" {...pageConfig} />
  // }

  return (
    <div css={styles.root}>
      <Nav
        breakpoint={4}
        headingSize={300}
        top="0"
        icon="clients_chat"
        title="Client Channels"
        menuLabel="All Channels"
        menuGlyph="chat"
        {...pageConfig}
      >
        <NavItem
          icon="clients_chat"
          label="Message Blasts"
          description="Send message to many clients"
          to={`${url}/message-blasts`}
          css={styles.messageBlasts}
          labelStyles={styles.navItemLabel}
          permission="clients_chat_message_blasts.view"
          featureFlagV2="clients_message_blasts"
        />

        <div className="!my-3 !mx-0">
          <Select value={selection} onUpdate={(state: any) => setSelection(state.value)}>
            <Option label="All Channels" value="all" />
            <Option label="Applicants" value="applicants" />
            <Option label="Accepted" value="accepted" />
            <Option label="Clients" value="clients" />
            <Option label="Past Client" value="alumni" />
            <Option label="Declined" value="declined" />
            {/* {hasProperties && <Option label="Filter by Location" value="properties" />}
              {hasProgramsEmpty && <Option label="Filter by Program" value="programs" />} */}
          </Select>

          {/* {selection === 'properties' && (
              <Select defaultValue={firstPropertyId} onUpdate={(state: any) => setCategoryID(state.value)} className="!mt-2">
                {mapToArray(properties).map?.((property) => (
                  <Option key={property.id} label={property.name} value={property.id} />
                ))}
              </Select>
            )}

            {selection === 'programs' && (
              <Select defaultValue={firstProgramId} onUpdate={(state: any) => setCategoryID(state.value)} className="!mt-2">
                {mapToArray(programs).map?.((program) => (
                  <Option key={program.id} label={program.name} value={program.id} />
                ))}
              </Select>
            )} */}
        </div>

        {selection === 'all' && (
          <>
            <NavGroup label="Applicants">
              {channels?.applicants?.map?.((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel?.id}
                  avatar={channel?.avatar}
                  label={channel?.name}
                  to={`${url}/clients/${channel?.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>

            <NavGroup label="Accepted">
              {channels?.accepted?.map?.((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel?.id}
                  avatar={channel?.avatar}
                  label={channel?.name}
                  to={`${url}/clients/${channel?.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>

            <NavGroup label="Current Clients">
              {channels?.clients?.map?.((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel?.id}
                  avatar={channel?.avatar}
                  label={channel?.name}
                  to={`${url}/clients/${channel?.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>

            <NavGroup label="Declined">
              {channels?.declined?.map?.((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel?.id}
                  avatar={channel?.avatar}
                  label={channel?.name}
                  to={`${url}/clients/${channel?.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>

            <NavGroup label="Past Client">
              {channels?.alumni?.map?.((channel: any) => (
                <NavItem
                  showAvatarInitials
                  key={channel?.id}
                  avatar={channel?.avatar}
                  label={channel?.name}
                  to={`${url}/clients/${channel?.id}`}
                  labelStyles={styles.navItemLabel}
                />
              ))}
            </NavGroup>
          </>
        )}

        {selection === 'applicants' && (
          <NavGroup label="Applicants">
            {channels?.applicants?.map?.((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel?.id}
                avatar={channel?.avatar}
                label={channel?.name}
                to={`${url}/clients/${channel?.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}

        {selection === 'accepted' && (
          <NavGroup label="Accepted">
            {channels?.accepted?.map?.((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel?.id}
                avatar={channel?.avatar}
                label={channel?.name}
                to={`${url}/clients/${channel?.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}

        {selection === 'clients' && (
          <NavGroup label="Clients">
            {channels?.clients?.map?.((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel?.id}
                avatar={channel?.avatar}
                label={channel?.name}
                to={`${url}/clients/${channel?.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}

        {selection === 'declined' && (
          <NavGroup label="Declined">
            {channels?.declined?.map?.((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel?.id}
                avatar={channel?.avatar}
                label={channel?.name}
                to={`${url}/clients/${channel?.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}

        {selection === 'alumni' && (
          <NavGroup label="Past Client">
            {channels?.alumni?.map?.((channel: any) => (
              <NavItem
                showAvatarInitials
                key={channel?.id}
                avatar={channel?.avatar}
                label={channel?.name}
                to={`${url}/clients/${channel?.id}`}
                labelStyles={styles.navItemLabel}
              />
            ))}
          </NavGroup>
        )}
      </Nav>

      <main css={styles.main}>
        <Switch>
          <Route path={`${url}/message-blasts`} component={MessageBlasts} />
          <Route path={`${url}/clients/:id`} component={Channel} />

          {channels?.all?.[0]?.id && <Redirect exact from={`${url}`} to={`${url}/clients/${channels.all[0].id}`} />}
        </Switch>
      </main>
    </div>
  )
}

const styles = {
  root: {
    display: 'grid',
    width: '100%',
    height: '100%',

    overflow: 'hidden',
    gridTemplateRows: 'min-content 1fr',
    gridTemplateColumns: '1fr',

    position: 'relative',
    zIndex: 0,
    paddingBottom: '100px',

    [`${MEDIA_QUERY[1]}`]: {
      paddingBottom: 0,
    },

    [`${MEDIA_QUERY[4]}`]: {
      gridTemplateRows: '100%',
      gridTemplateColumns: '320px 1fr',
    },
  },

  main: {
    display: 'grid',
    gridTemplateRows: '100%',
    gridTemplateColumns: '100%',
    overflow: 'hidden',
  },

  messageBlasts: {
    background: COLORS.white,
    boxShadow: `
			inset 0 0 0 1px ${transparentize(0.85, COLORS.gray)},
			${SHADOW(1, transparentize(0.95, COLORS.blue))}
    `,
    margin: 0,
  },

  navItemLabel: {
    color: darken(0.03, COLORS.link),
    '.is-active &': { color: COLORS.black },
  },
}

export default withPageError(withMarketing(ClientsChat, pageConfig))
