import React from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import { darken, opacify, tint, transparentize } from 'polished'

import { mergeAttributes } from '@tiptap/react'
import { Color } from '@tiptap/extension-color'
import { useEditor, EditorContent } from '@tiptap/react'
import Highlight from '@tiptap/extension-highlight'
import Image from '@tiptap/extension-image'
import Link from '@tiptap/extension-link'
import StarterKit from '@tiptap/starter-kit'
import Strike from '@tiptap/extension-strike'
import Table from '@tiptap/extension-table'
import TableCell from '@tiptap/extension-table-cell'
import TableHeader from '@tiptap/extension-table-header'
import TableRow from '@tiptap/extension-table-row'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import TextAlign from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import Underline from '@tiptap/extension-underline'

import { COLORS, SHADOW } from '../../theme'
import { RICH_TEXT_STYLES } from '../../theme/defs/styles'

import { isDefined, formatURL, isURL } from '../../utils/functions'
import { validate } from './validators'
import { withFormContext } from './context'

import Button from '../Button'
import Card from '../Card'
import Dropdown from '../Dropdown'
import FieldBase from './FieldBase'
import Flex from '../Flex'
import Glyph from '../Glyph'
import Permission from '../Permission'
import Switch from '../Forms/Switch'
import Tooltip from '../Tooltip'
import URLInput from './URLInput'
import Portal from '../Portal'

import { QuickTextSelector } from '../../constructs/QuickText/QuickTextSelector'

import { SlashCommands, suggestion } from '../TipTap/SlashCommands'
import { snakeCase } from 'lodash'

const DEFAULT_EMPTY_VALUE = ''

const TEXT_COLORS = [COLORS.red, COLORS.orange, COLORS.gold, COLORS.green, COLORS.vividBlue, COLORS.blue, COLORS.purple, COLORS.text]

const DEFAULT_ACTIONS = ['alignment', 'block', 'color', 'inline', 'list', 'other', 'undo']

export const MenuButton: React.FC<any> = ({ children, className, glyph, isActive, isDisabled, onClick, tooltip, testKey }: any) => {
  const classNames = clsx({
    'is-active': isActive,
    'is-disabled': isDisabled,
    [className]: className,
  })

  return (
    <Tooltip content={tooltip} position="top">
      <button data-test={testKey} type="button" onClick={onClick} className={classNames} css={menuStyles.button} disabled={isDisabled}>
        {glyph && <Glyph glyph={glyph} size={16} color={COLORS.text} />}
        {children}
      </button>
    </Tooltip>
  )
}

export const MenuDivider = () => <div css={menuStyles.divider} />

const EditorMenu = (props: any) => {
  const { actions, editor, actionsAfter, actionsBefore, isFullScreen, toggleFullScreen } = props

  const [showTableMenu, setShowTableMenu] = React.useState(false)
  const [showLinkMenu, setShowLinkMenu] = React.useState(false)

  const alignmentEnabled = actions.includes('alignment')
  const blockEnabled = actions.includes('block')
  const colorEnabled = actions.includes('color')
  const inlineEnabled = actions.includes('inline')
  const listEnabled = actions.includes('list')
  const otherEnabled = actions.includes('other')
  const undoEnabled = actions.includes('undo')

  React.useEffect(() => {
    if (isFullScreen) editor.commands.focus('end')
  }, [isFullScreen])

  if (!editor) return null

  return (
    <div css={menuStyles.root}>
      <div css={menuStyles.bar} className="toolbar">
        {actionsBefore}

        {blockEnabled && (
          <>
            <MenuButton
              tooltip="Heading 1"
              glyph="heading_1"
              onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
              isActive={editor.isActive('heading', { level: 1 })}
            />
            <MenuButton
              tooltip="Heading 2"
              glyph="heading_2"
              onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
              isActive={editor.isActive('heading', { level: 2 })}
            />
            <MenuButton
              tooltip="Heading 3"
              glyph="heading_3"
              onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
              isActive={editor.isActive('heading', { level: 3 })}
            />
            <MenuButton
              tooltip="Paragraph"
              glyph="paragraph"
              onClick={() => editor.chain().focus().setParagraph().run()}
              isActive={editor.isActive('paragraph')}
            />
          </>
        )}

        {inlineEnabled && (
          <>
            {blockEnabled && <MenuDivider />}

            <MenuButton
              tooltip="Bold"
              glyph="bold"
              onClick={() => editor.chain().focus().toggleBold().run()}
              isActive={editor.isActive('bold')}
            />
            <MenuButton
              tooltip="Italic"
              glyph="italic"
              onClick={() => editor.chain().focus().toggleItalic().run()}
              isActive={editor.isActive('italic')}
            />
            <MenuButton
              tooltip="Strikethrough"
              glyph="strikethrough"
              onClick={() => editor.chain().focus().toggleStrike().run()}
              isActive={editor.isActive('strike')}
            />
            <MenuButton
              tooltip="Underline"
              glyph="underline"
              onClick={() => editor.chain().focus().toggleUnderline().run()}
              isActive={editor.isActive('underline')}
            />
            <MenuButton tooltip="Link" glyph="link" onClick={() => setShowLinkMenu((c) => !c)} isActive={showLinkMenu} />
          </>
        )}

        {colorEnabled && (
          <>
            <MenuDivider />

            <Dropdown
              minWidth={190}
              maxWidth={190}
              triggerStyles={{ display: 'inline-flex' }}
              trigger={<MenuButton tooltip="Color Picker" glyph="color_picker" />}
              portal="radix"
            >
              <div css={menuStyles.pickerMenu}>
                {TEXT_COLORS.map((color) => (
                  <MenuButton
                    key={color}
                    onClick={() => editor.chain().focus().setColor(color).run()}
                    isActive={editor.isActive('textStyle', { color })}
                    children={<div css={[menuStyles.swatch, { background: color }]} />}
                    className="!m-0"
                  />
                ))}
              </div>
            </Dropdown>
            <MenuButton
              tooltip="Highlight"
              glyph="highlight"
              onClick={() => editor.chain().focus().toggleHighlight().run()}
              isActive={editor.isActive('highlight')}
            />
          </>
        )}

        {otherEnabled && (
          <>
            <MenuDivider />

            <MenuButton
              tooltip="Quote"
              glyph="quote"
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              isActive={editor.isActive('blockquote')}
            />
            <MenuButton
              tooltip="Horizontal Line"
              glyph="horizontal_line"
              onClick={() => editor.chain().focus().setHorizontalRule().run()}
            />
            <MenuButton tooltip="Table Options" glyph="table" onClick={() => setShowTableMenu((c) => !c)} isActive={showTableMenu} />
          </>
        )}
        <MenuDivider />

        {listEnabled && (
          <>
            <MenuButton
              tooltip="Bullet List"
              glyph="bullet_list"
              onClick={() => editor.chain().focus().toggleBulletList().run()}
              isActive={editor.isActive('bulletList')}
            />
            <MenuButton
              tooltip="Ordered List"
              glyph="ordered_list"
              onClick={() => editor.chain().focus().toggleOrderedList().run()}
              isActive={editor.isActive('orderedList')}
            />
            <MenuButton
              tooltip="Tasks List"
              glyph="tasks_list"
              onClick={() => editor.chain().focus().toggleTaskList().run()}
              isActive={editor.isActive('taskList')}
            />
          </>
        )}

        {alignmentEnabled && (
          <>
            <MenuDivider />

            <MenuButton
              tooltip="Align Left"
              glyph="text_align_left"
              onClick={() => editor.chain().focus().setTextAlign('left').run()}
              isActive={editor.isActive({ textAlign: 'left' })}
            />
            <MenuButton
              tooltip="Center"
              glyph="text_align_center"
              onClick={() => editor.chain().focus().setTextAlign('center').run()}
              isActive={editor.isActive({ textAlign: 'center' })}
            />
            <MenuButton
              tooltip="Align Right"
              glyph="text_align_right"
              onClick={() => editor.chain().focus().setTextAlign('right').run()}
              isActive={editor.isActive({ textAlign: 'right' })}
            />
            <MenuButton
              tooltip="Justify"
              glyph="text_align_justify"
              onClick={() => editor.chain().focus().setTextAlign('justify').run()}
              isActive={editor.isActive({ textAlign: 'justify' })}
            />
          </>
        )}

        {/* <MenuDivider />
        <MenuButton
          tooltip="Paste HTML"
          glyph="embed"
          onClick={async () => {
            try {
              const text = await navigator.clipboard.readText()

              editor.chain().focus().insertContent(text).run()
            } catch (error) {
              console.error('Failed to read clipboard contents: ', error)
            }
          }}
        /> */}

        {undoEnabled && (
          <>
            <MenuDivider />

            <MenuButton tooltip="Undo" glyph="undo" onClick={() => editor.chain().focus().undo().run()} />
            <MenuButton tooltip="Redo" glyph="redo" onClick={() => editor.chain().focus().redo().run()} />
          </>
        )}

        {actionsAfter}

        <div className="flex-auto" />

        {/* {!isFullScreen && <MenuButton tooltip="Editor Settings" glyph="settings" />} */}

        {!isFullScreen && (
          <MenuButton
            tooltip="Toggle Full Screen"
            glyph={isFullScreen ? 'compress' : 'expand'}
            onClick={() => {
              toggleFullScreen()
            }}
            css={menuStyles.fullScreenButton}
            isActive={isFullScreen}
          />
        )}
      </div>

      {showLinkMenu && (
        <div css={menuStyles.bar} className="toolbar">
          <div css={{ padding: '0.25rem', flex: '1 1 auto' }}>
            <LinkForm editor={editor} />
          </div>
        </div>
      )}

      {showTableMenu && (
        <div css={menuStyles.bar} className="toolbar">
          <MenuButton
            tooltip="Add Table"
            glyph="table_add"
            onClick={() => editor.chain().focus().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()}
          />
          <MenuButton
            tooltip="Insert Column Before"
            glyph="table_insert_column_before"
            onClick={() => editor.chain().focus().addColumnBefore().run()}
            isDisabled={!editor.can().addColumnBefore()}
          />
          <MenuButton
            tooltip="Insert Column After"
            glyph="table_insert_column_after"
            onClick={() => editor.chain().focus().addColumnAfter().run()}
            isDisabled={!editor.can().addColumnAfter()}
          />
          <MenuButton
            tooltip="Insert Row Before"
            glyph="table_insert_row_before"
            onClick={() => editor.chain().focus().addRowBefore().run()}
            isDisabled={!editor.can().addRowBefore()}
          />
          <MenuButton
            tooltip="Insert Row After"
            glyph="table_insert_row_after"
            onClick={() => editor.chain().focus().addRowAfter().run()}
            isDisabled={!editor.can().addRowAfter()}
          />
          <MenuButton
            tooltip="Merge Cells"
            glyph="table_merge_cells"
            onClick={() => editor.chain().focus().mergeCells().run()}
            isDisabled={!editor.can().mergeCells()}
          />
          <MenuButton
            tooltip="Split Cells"
            glyph="table_split_cells"
            onClick={() => editor.chain().focus().splitCell().run()}
            isDisabled={!editor.can().splitCell()}
          />
          <MenuButton
            tooltip="Delete Column"
            glyph="table_delete_column"
            onClick={() => editor.chain().focus().deleteColumn().run()}
            isDisabled={!editor.can().deleteColumn()}
          />
          <MenuButton
            tooltip="Delete Row"
            glyph="table_delete_row"
            onClick={() => editor.chain().focus().deleteRow().run()}
            isDisabled={!editor.can().deleteRow()}
          />

          <MenuButton
            tooltip="Delete Table"
            glyph="table_delete"
            onClick={() => editor.chain().focus().deleteTable().run()}
            isDisabled={!editor.can().deleteTable()}
          />
        </div>
      )}

      {/* <button css={menuStyles.toggleButton} onClick={toggleOpen}>
        {isOpen ? 'Hide' : 'Show Tools'}
      </button> */}
    </div>
  )
}

const LinkForm = ({ editor }: any) => {
  const [value, setValue] = React.useState(editor.getAttributes('link').href || '')

  const isEmpty = !editor.getAttributes('link').href
  const didUpdateLink = value && formatURL(value) !== editor.getAttributes('link').href

  const setLink = React.useCallback(
    (link) => {
      // cancelled
      if (link === null) return

      // empty
      if (link === '') {
        editor.chain().focus().extendMarkRange('link').unsetLink().run()

        return
      }

      // update link
      editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: formatURL(link), target: isEmpty ? '_blank' : undefined })
        .run()
    },
    [editor],
  )

  React.useEffect(() => {
    const newValue = editor.getAttributes('link').href?.startsWith?.('//')
      ? editor.getAttributes('link').href?.replace?.('//', '')
      : editor.getAttributes('link').href || ''

    setValue(newValue)
  }, [editor.getAttributes('link').href])

  return (
    <>
      <Flex centerY gap="0.5rem" flex="1 1 auto">
        <URLInput
          key={editor.getAttributes('link').href || 'no-link'}
          placeholder="Add link…"
          value={value}
          onUpdate={({ value: newValue }: any) => {
            setValue(newValue)
          }}
          css={{ input: { height: 28, minWidth: 550, minHeight: 'auto', fontSize: '0.9rem' } }}
          model="link"
        />

        {isEmpty && (
          <Button
            label={'Set Link'}
            size={200}
            onClick={() => {
              setLink(value)
            }}
            isDisabled={!isURL(value)}
          />
        )}

        {!isEmpty && didUpdateLink && (
          <Button
            label={'Update Link'}
            size={200}
            onClick={() => {
              setLink(value)
            }}
            isDisabled={!isURL(value)}
          />
        )}

        {editor.getAttributes('link').href && (
          <>
            <label className="flex items-center cursor-pointer">
              <Switch
                key={editor.getAttributes('link').href || 'no-link'}
                withHover={false}
                label={null}
                size={100}
                value={editor.getAttributes('link').target === '_blank'}
                onUpdate={({ value }: any) => {
                  if (!editor.getAttributes('link').href) return

                  editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .setLink({ target: value ? '_blank' : null })
                    .run()
                }}
              />
              <div className="font-medium ml-1.5">Open in new tab</div>
            </label>

            <Button
              label="Remove Link"
              color="red"
              size={200}
              onClick={() => editor.chain().focus().unsetLink().run()}
              className="!ml-auto"
            />
          </>
        )}
      </Flex>
    </>
  )
}

const Tiptap = (props: any) => {
  const {
    actions,
    actionsAfter,
    actionsBefore,
    className,
    content,
    testKey,
    getEditor,
    isDisabled = false,
    isEditable,
    onBlur = () => {},
    onFocus = () => {},
    onUpdate = () => {},
    textSelectionDisabled = false,
    useQuickText = false,
    maxRows,
    isFullScreen,
    toggleFullScreen,
    label,
  } = props

  const editor = useEditor({
    content,
    editable: isEditable && !isDisabled,
    extensions: [
      Highlight.configure({ multicolor: true }),
      Image.configure({ inline: true }),
      Link.configure({
        protocols: ['tel', 'mailto'],
        linkOnPaste: true,
        openOnClick: false,
        HTMLAttributes: {
          target: undefined, // required to manage target on individual links
          rel: 'noopener noreferrer',
        },
      }),
      StarterKit,
      Strike,
      Table.configure({ resizable: true }),
      TableCell.extend({
        renderHTML({ HTMLAttributes }) {
          const attrs = mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)

          if (attrs?.colwidth) {
            attrs.style = `width: ${attrs.colwidth}px`
          }

          return ['td', attrs, 0]
        },
      }),
      TableHeader,
      TableRow,
      TextAlign,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      Color,
      TextStyle,
      Underline,
      TaskList,
      TaskItem,
      SlashCommands.configure({
        suggestion,
      }),
    ],
    onBlur,
    onFocus,
    onUpdate,
  })

  React.useEffect(() => {
    if (!editor || !getEditor) return

    getEditor(editor)
  }, [editor, getEditor])

  if (!editor) return null

  const classNames = clsx({
    'is-full-screen': isFullScreen,
    'is-text-selection-disabled': textSelectionDisabled,
    [className]: className,
  })

  return (
    <div css={RICH_TEXT_STYLES} className={classNames}>
      {isFullScreen && (
        <header className="flex items-center flex-nowrap justify-between pl-3 pr-1 py-0 border-b border-0 border-solid border-divider font-[600] overflow-hidden">
          <div className="grow-1 truncate">{label || 'Edit'}</div>

          <MenuButton
            glyph="compress"
            onClick={() => {
              toggleFullScreen()
            }}
          />
        </header>
      )}

      {isEditable && (
        <>
          <EditorMenu
            editor={editor}
            actions={actions}
            actionsAfter={actionsAfter}
            actionsBefore={actionsBefore}
            isFullScreen={isFullScreen}
            toggleFullScreen={toggleFullScreen}
          />
        </>
      )}

      <div
        data-test={testKey}
        css={contentWrapperStyles}
        className={isEditable ? 'is-editable' : 'is-readonly'}
        style={
          {
            '--editor-max-height': maxRows && !isFullScreen ? `${maxRows * 15}px` : 'auto',
            height: isFullScreen ? '100%' : 'auto',
          } as any
        }
      >
        <EditorContent editor={editor} className={clsx('EditorContent', isEditable ? 'is-editable' : 'is-readonly')} />
      </div>

      {useQuickText && (
        <Permission featureFlagV2="quick_text">
          <Flex justifyContent="flex-end">
            <QuickTextSelector
              onSelect={(text: any) => {
                editor.commands.insertContent(text.rich)
              }}
            />
          </Flex>
        </Permission>
      )}

      {isFullScreen && (
        <div className="px-3 py-2">
          <Button label="Save & Exit Full-Screen" onClick={toggleFullScreen} type="primary" glyph="compress" color="blue" />
        </div>
      )}
    </div>
  )
}

const FullScreenPortal = ({ children, onBackdropClick }: any) => {
  return (
    <Portal type="tooltip">
      <div className="fixed overflow-hidden top-0 bottom-0 left-0 right-0 p-8 flex items-center justify-center">
        <div className="relative grid grid-rows-[100%] grid-cols-[100%] z-10 w-full h-full max-w-[1400px] overflow-hidden">{children}</div>

        <div
          css={{
            background: COLORS.backdrop,
            position: 'fixed',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            zIndex: 0,
          }}
          onClick={onBackdropClick}
        />
      </div>
    </Portal>
  )
}

class RichTextEditor extends FieldBase {
  constructor(props) {
    super(props)

    let errors = []
    const vs = { ...props.defaultValidations, ...props.validations }
    let value = props.value

    if (!value && props.model) {
      const modelVal = props.form?.getField(props.model)
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)

      value = modelVal || initialModelVal || DEFAULT_EMPTY_VALUE
    }

    if (!value) {
      if (isDefined(props.defaultValue)) value = props.defaultValue
      else value = DEFAULT_EMPTY_VALUE
    }

    if (vs) errors = validate(value, vs)

    this.state = {
      type: 'RICHTEXTINPUT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      value: value,
      isNested: props.isNested || false,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isBlur: false,
      isRequired: vs?.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      isFullScreen: !!this.props.isFullScreen,
    }

    this.initialData = {
      value: value,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }
    this.updateType = 'DATA'
  }

  toggleFullScreen = () => {
    const next = !this.state.isFullScreen
    this.setState({ isFullScreen: next })

    if (this.props.onToggleFullScreen) this.props.onToggleFullScreen(next)
  }

  editRender = () => {
    const { value, isFullScreen }: any = this.state

    const {
      actions = DEFAULT_ACTIONS,
      actionsAfter,
      actionsBefore,
      autoHideToolbar,
      className,
      isDisabled,
      useQuickText,
      getEditor,
      maxRows,
    }: any = this.props

    const classNames = clsx('RichTextEditor', {
      'has-focus': this.state.hasFocus,
      'is-disabled': isDisabled,
      'auto-hide-toolbar': autoHideToolbar,
      [className]: className,
    })

    const WrapperTag: any = isFullScreen ? FullScreenPortal : React.Fragment

    return (
      <WrapperTag {...(isFullScreen && { onBackdropClick: this.toggleFullScreen })}>
        <div css={editWrapperStyles} className={classNames}>
          <Tiptap
            isEditable
            useQuickText={useQuickText}
            actions={actions}
            actionsAfter={actionsAfter}
            actionsBefore={actionsBefore}
            isDisabled={isDisabled}
            content={value || DEFAULT_EMPTY_VALUE}
            getEditor={getEditor}
            maxRows={maxRows}
            label={this.props.label}
            onUpdate={({ editor }: any) => {
              const html = editor.getHTML()
              if (html === '<p></p>') this.changeValue(DEFAULT_EMPTY_VALUE)
              else this.changeValue(html)
            }}
            onFocus={() => {
              this.onFocus()
              this.setState({ hasFocus: true })
            }}
            onBlur={() => {
              this.onBlur()
              this.setState({ hasFocus: false })
            }}
            isFullScreen={isFullScreen}
            toggleFullScreen={this.toggleFullScreen}
          />
        </div>
      </WrapperTag>
    )
  }

  readOnlyRender = () => {
    return (
      <Tiptap
        isEditable={false}
        testKey={this.props.testKey || `${snakeCase(this.props.label)}_value`}
        content={this.state.value || '–'}
        textSelectionDisabled={this.props.textSelectionDisabled}
      />
    )
  }
}

const editWrapperStyles = {
  '--background-color': COLORS.white,

  borderRadius: 5,
  // overflow: 'hidden',
  border: `1px solid ${COLORS.divider}`,
  minHeight: '50px',
  backgroundColor: 'var(--background-color)',
  transition: `
    border-color 160ms ease,
    box-shadow 160ms ease
  `,

  '&:hover': {
    boxShadow: SHADOW(5),
    borderColor: opacify(0.1, COLORS.divider),
  },

  '&.has-focus': {
    borderColor: COLORS.blue,
    boxShadow: `
      ${SHADOW(5, transparentize(0.95, COLORS.blue))},
      0 0 0 2px ${transparentize(0.75, COLORS.blue)}
    `,
  },

  '&.is-disabled': {
    transform: 'none !important',
    cursor: 'not-allowed !important',

    '.ProseMirror': {
      cursor: 'not-allowed !important',
    },
  },

  '&.auto-hide-toolbar': {
    '.toolbar': { display: 'none' },

    '&.has-focus, &:focus-within': {
      '.toolbar': { display: 'flex' },
    },
  },

  '.ProseMirror': {
    padding: '1rem',
    cursor: 'text',
    minHeight: 100,

    '&.resize-cursor': {
      cursor: 'col-resize',
    },

    'td, th': {
      position: 'relative',
      minWidth: '1rem',

      '& > *': {
        textAlign: 'left',
      },
    },

    '.column-resize-handle': {
      position: 'absolute',
      right: -2,
      top: 0,
      bottom: -2,
      width: 4,
      backgroundColor: COLORS.vividBlue,
      pointerEvents: 'none',
      zIndex: 1,
    },
  },
}

const contentWrapperStyles = {
  overflow: 'auto',
  maxHeight: 'var(--editor-max-height, auto)',
}

const menuStyles = {
  root: {
    position: 'sticky',
    top: 0,
    background: 'var(--background-color)',
    zIndex: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  toggleButton: {
    marginLeft: 'auto',
    position: 'absolute',
    right: '0.5rem',
    border: 'none',
    borderRadius: '0 0 4px 4px',
    background: COLORS.divider,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    letterSpacing: 0.5,
    color: COLORS.gray,
    fontWeight: 500,
    padding: '0.2em 0.4em',

    '&:hover': {
      cursor: 'pointer',
      color: COLORS.text,
      background: darken(0.4, COLORS.divider),
    },
  },

  bar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.divider}`,
  },

  button: {
    margin: '0.3rem',
    padding: '0.35rem',
    border: 'none',
    background: 'transparent',
    borderRadius: 3,
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.hover,
      svg: { fill: COLORS.blue },
    },

    '&.is-active': {
      background: COLORS.divider,
      svg: { fill: COLORS.text },
    },
  },

  fullScreenButton: {
    '&.is-active': {
      background: COLORS.gray,
      svg: { fill: COLORS.white },
    },
  },

  divider: {
    width: 1,
    height: 20,
    background: COLORS.divider,
    margin: '0 0.5rem',
  },

  swatch: {
    display: 'inline-block',
    width: 24,
    height: 24,
    borderRadius: '50%',
    border: 'none',
    cursor: 'pointer',
    transition: 'all 100ms cubic-bezier(0.39, 0.575, 0.565, 1)',
  },

  pickerMenu: {
    padding: '0.5rem',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
}

RichTextEditor.defaultProps = {
  isEditable: true,
  minRows: 3,
  maxWidth: '100%',
  defaultValidations: null,
  validateOn: 'blur-change',
}

export default withFormContext(RichTextEditor)
