import React from 'react'
import { Link, Route } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, HelpTagIframe, Grid } from '@behavehealth/components'
import { CompanyCredentialOverlay } from '@behavehealth/constructs/CompanyCredentials/CompanyCredentialOverlay'
import { CompanyCredentialsDataTable } from '@behavehealth/constructs/CompanyCredentials/CompanyCredentialsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'company_credentials',
  help: <HelpTagIframe id="settings_company_credentials" />,
  title: 'Credentials',
}

const CompanyCredentials = () => {
  return (
    <div>
      <CompanyCredentialsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<CompanyCredentialOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const CompanyCredentialsIndex: React.FC = () => {
  const { tenant } = useSettings()

  const tableProps = useDataTable({
    name: ['facilities', tenant.id, 'credentials'],
    endpoint: `/facilities/${tenant.id}/credentials`,
    params: { category: 'custom' },
    updateDeleteEndpoint: `/credentials`,
    localStorageKey: 'company_credentials_v1',
  })

  const actions = <Button as={Link} label="Add Credential" type="primary" glyph="add" link="new" permission="settings.credentials.create" />

  return (
    <Page actions={actions} {...pageConfig}>
      <Grid gap="1rem">
        <CompanyCredentialsDataTable
          {...tableProps}
          to={(row: any) => row.id}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'settings.credentials.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          duplicateColumn={setDuplicateColumnV6({
            link: 'new',
            permission: 'settings.credentials.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CompanyCredentials, pageConfig))
