import React from 'react'
import size from 'lodash/size'
import { v4 as uuid } from 'uuid'
import { default as RCSlider, Range as RCRange } from 'rc-slider'
import { darken, desaturate } from 'polished'

import { isDefined } from '../../utils/functions'
import { COLORS, INPUT_STYLES } from '../../theme'
import { withFormContext } from './context'

import Value from '../Value'
import FieldBase from './FieldBase'
import BaseInput from './BaseInput'
import Flex from '../Flex'

import 'rc-slider/assets/index.css'

const { createSliderWithTooltip } = RCSlider

const RANGE_LEFT_VALUE = 'leftValue'
const RANGE_RIGHT_VALUE = 'rightValue'

class Slider extends FieldBase {
  constructor(props) {
    super(props)

    let value = props.value
    if (props.form) {
      const initialModelVal = props.form?.getInitialInputFieldValue(props.model)
      if (isDefined(initialModelVal)) value = initialModelVal
    }

    this.state = {
      id: `${props.model}-${uuid()}`,
      model: props.model,
      label: props.label,
      value: value,
      active: false,
      isDisabled: props.isDisabled,
      isValidations: null,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValid: true,
      isInvalid: false,
      errors: [],
      validate: this.onValidate,
      reset: this.onReset,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
    }

    this.initialData = {
      value: value,
      isValid: true,
      isInvalid: false,
    }

    this.updateType = 'DATA'
  }

  // We use these two, so when inputs are focused, even though mouse can be
  // out of that area, component will still be active, stylewise.
  onInputFocus = (e) => this.setState({ active: true })
  onInputBlur = (e) => this.setState({ active: false })

  editRender = () => {
    const { value } = this.state

    // We here decide if we render a RangeSlider or a DefaultSlider,
    // but component signature is pretty much the same
    return this.props.range ? (
      <RangeSlider
        {...this.props}
        value={value}
        active={this.state.active}
        onChange={this.changeValue}
        onInputChange={this.changeValue}
        onInputFocus={this.onInputFocus}
        onInputBlur={this.onInputBlur}
      />
    ) : (
      <DefaultSlider
        {...this.props}
        value={value}
        active={this.state.active}
        onChange={this.changeValue}
        onInputChange={this.changeValue}
        onInputFocus={this.onInputFocus}
        onInputBlur={this.onInputBlur}
      />
    )
  }

  readOnlyRender = () => {
    return <Value value={this.state.value} />
  }
}

Slider.defaultProps = {
  // Initial value, if Slider is part of a form, it gets it from there
  value: 0,
  // Define min and max values for both simple and ranged sliders
  min: 0,
  max: 100,
  // Amount of units to be added/removed each time the slider moves
  step: 1,
  range: false,
  styles: {},
  withInputs: false,
  withTooltip: false,
  // If this is false, we'll use the handleColor for borderColor
  handleWithBorders: true,
  trackColor: COLORS.blue,
  railColor: COLORS.lightGray,
  handleColor: COLORS.white,
  inputTextSize: 2,
  isEditable: true,
  isDisabled: false,
  layout: null,
}

// Abstraction that holds a default slider implementation
class DefaultSlider extends React.Component {
  onInputChange = (e) => this.props?.onInputChange(e.target.value)

  render() {
    const { isEditable, isDisabled, inputTextSize, active, withInputs, withTooltip, value, ...rest } = this.props

    // We here wrap our component with the Tooltip HOC, which is not working properly now
    const SliderComponent = withTooltip ? createSliderWithTooltip(RCSlider) : RCSlider

    return (
      <Flex css={styles.root(this.props)} className={active ? 'is-active' : ''} centerX centerY horizontal>
        <div className="-ml-2 pr-3 mr-3">
          <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px]">MIN</div>
          <div className="text-text-muted text-[0.85rem] font-[600] tabular-nums">{this.props.min}</div>
        </div>

        <SliderComponent {...rest} value={value} onChange={this.props.onChange} disabled={isDisabled} />

        {withInputs ? (
          <BaseInput
            type="number"
            className="input"
            max={this.props.max}
            min={this.props.min}
            step={this.props.step}
            value={this.props.value}
            onChange={this.onInputChange}
            onFocus={this.props.onInputFocus}
            onBlur={this.props.onInputBlur}
            size={inputTextSize}
            css={styles.field}
            readOnly={isDisabled || !isEditable}
          />
        ) : typeof this.props.value === 'number' ? (
          <div className="pl-4">{`${this.props.value}`}</div>
        ) : (
          <div className="pl-4">{this.props.value}</div>
        )}

        <div className="mr-1 pl-3 ml-3 text-right">
          <div className="text-text-muted opacity-80 text-[0.8rem] uppercase font-[600] tracking-[0.5px]">MAX</div>
          <div className="text-text-muted text-[0.85rem] font-[600] tabular-nums">{this.props.max}</div>
        </div>
      </Flex>
    )
  }
}

// Abstraction that holds a ranged slider implementation

class RangeSlider extends React.Component {
  onInputChange = (type, value) => {
    let newValue

    switch (type) {
      case RANGE_LEFT_VALUE:
        newValue = [value, this.props.value[1]]
        break
      case RANGE_RIGHT_VALUE:
        newValue = [this.props.value[0], value]
        break
      default:
        newValue = this.props.value
        break
    }

    if (this.props.onInputChange) this.props.onInputChange(newValue)
  }

  render() {
    const { isEditable, isDisabled, inputTextSize, onInputFocus, onInputBlur, withInputs, withTooltip, active, value, ...rest } = this.props

    // We here wrap our component with the Tooltip HOC, which is not working properly now
    const SliderComponent = withTooltip ? createSliderWithTooltip(RCRange) : RCRange

    return (
      <Flex css={styles.root(this.props)} className={active ? 'is-active' : ''} centerX centerY horizontal>
        {withInputs && (
          <BaseInput
            type="number"
            className="input"
            value={this.props.value[0]}
            onChange={(e) => this.onInputChange(RANGE_LEFT_VALUE, Number(e.target.value))}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            size={inputTextSize}
            css={{ ...styles.field, textAlign: 'left' }}
            readOnly={isDisabled || !isEditable}
            max={this.props.value[1]}
          />
        )}
        <SliderComponent {...rest} value={this.props.value} onChange={this.props.onChange} disabled={isDisabled || !isEditable} />
        {withInputs && (
          <BaseInput
            type="number"
            className="input"
            value={this.props.value[1]}
            onChange={(e) => this.onInputChange(RANGE_RIGHT_VALUE, Number(e.target.value))}
            onFocus={onInputFocus}
            onBlur={onInputBlur}
            size={inputTextSize}
            min={this.props.value[0]}
            css={styles.field}
            readOnly={isDisabled || !isEditable}
          />
        )}
      </Flex>
    )
  }
}

const styles = {
  root: ({ styles, range, handleWithBorders, withInputs, railColor, trackColor, handleColor, marks }) => ({
    ...INPUT_STYLES,
    // paddingTop: range ? 7 : 11,
    paddingLeft: 20,
    paddingRight: withInputs ? 10 : 20,
    overflow: 'visible',

    ...(size(marks) > 0 ? { paddingBottom: 20 } : {}),

    '& .rc-slider': {
      flexGrow: 1,
      width: 'auto',

      '& .rc-slider-rail': {
        backgroundColor: railColor,
      },

      '& .rc-slider-track': {
        transition: 'background-color .1s',
        backgroundColor: darken(0.25, desaturate(0.2, railColor)),
      },

      '& .rc-slider-handle': {
        width: 22,
        height: 22,
        marginTop: -9,
        border: `2px solid ${handleWithBorders ? darken(0.25, desaturate(0.2, railColor)) : handleColor}`,
        backgroundColor: handleColor,
        boxShadow: 'none',
        transition: 'border-color .1s',
      },

      '& .rc-slider-tooltip': {
        top: 0,
      },

      '& .rc-slider-mark .rc-slider-mark-text': {
        whiteSpace: 'nowrap',
        margin: '0 0.75em',
        padding: '0.25em 0',
      },
    },

    '&:hover, &:active, &.is-active': {
      '& .rc-slider': {
        '& .rc-slider-handle': {
          borderColor: handleWithBorders ? trackColor : handleColor,
        },

        '& .rc-slider-track': {
          backgroundColor: trackColor,
        },

        '&.rc-slider-disabled': {
          '& .rc-slider-handle': {
            borderColor: darken(0.2, railColor),
          },
          '& .rc-slider-track': {
            backgroundColor: darken(0.2, railColor),
          },
        },
      },
    },
    ...styles,
  }),

  field: {
    border: 'none',
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'right',
    minHeight: 'auto',
    padding: 0,

    '&:focus, &:hover': {
      boxShadow: 'none',
    },
  },
}

export default withFormContext(Slider)
