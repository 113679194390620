import React from 'react'

import size from 'lodash/size'

import { usDate, countWord } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import IntakeStatus from '../../components/Statuses/IntakeStatus'

import { FILTERS } from '../Filters/config'

import { DISCHARGE_TYPES } from '../../utils/constants'

export const TreatmentEpisodesDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const { timezone, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Title',
        width: 180,
        id: 'title',
        model: 'title',
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} value={value || 'Treatment Episode'} to={to?.(data)} />,
      },
      {
        title: 'Status',
        width: 140,
        id: 'status',
        model: 'status',
        formatValue: ({ value }: any) => (value ? <IntakeStatus small status={value} /> : null),
      },
      {
        title: '# Authorizations',
        width: 200,
        id: 'insurance_authorization_count',
        model: 'insurance_authorizations',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return countWord('Authorizations', size(value))
        },
      },
      {
        title: 'Accept Date',
        width: 200,
        id: 'accepted_at',
        model: 'accepted_at',
        type: 'date_time',
      },
      {
        title: 'Est. Arrival Date',
        width: 200,
        id: 'estimated_arrival',
        model: 'estimated_arrival',
        type: 'date_time',
      },
      {
        title: 'Admit Date',
        width: 200,
        id: 'admitted_at',
        model: 'admitted_at',
        type: 'date_time',
      },
      {
        title: 'Est. Discharge Date',
        width: 200,
        id: 'estimated_discharge',
        model: 'estimated_discharge',
        type: 'date_time',
      },
      {
        title: 'Decline Date',
        width: 200,
        id: 'declined_at',
        model: 'declined_at',
        type: 'date_time',
      },
      {
        title: 'Discharge Date',
        width: 200,
        id: 'discharged_at',
        model: 'discharged_at',
        type: 'date_time',
      },
      {
        title: 'Incoming Notes',
        width: 200,
        id: 'incoming_notes',
        model: 'incoming_notes',
      },
      {
        title: 'Waitlist Notes',
        width: 200,
        id: 'waitlist_notes',
        model: 'waitlist_notes',
      },
      {
        title: 'No Show Notes',
        width: 200,
        id: 'no_show_notes',
        model: 'no_show_notes',
      },
      {
        title: 'Discharge Notes',
        width: 200,
        id: 'discharge_notes',
        model: 'discharge_notes',
      },
      {
        title: 'Discharge Type',
        width: 200,
        id: 'discharge_type',
        model: 'discharge_type',
        formatValue: ({ value }: any) => (value ? DISCHARGE_TYPES[value] : '-'),
      },
      {
        title: 'Additional Information',
        width: 200,
        id: 'notes',
        model: 'notes',
      },
      {
        title: 'Accepted By',
        width: 180,
        id: 'accepted_by',
        model: 'accepted_by',
        type: 'profile',
      },
      {
        title: 'Admitted By',
        width: 180,
        id: 'admitted_by',
        model: 'admitted_by',
        type: 'profile',
      },
      {
        title: 'Declined By',
        width: 180,
        id: 'declined_by',
        model: 'declined_by',
        type: 'profile',
      },
      {
        title: 'Discharged By',
        width: 180,
        id: 'discharged_by',
        model: 'discharged_by',
        type: 'profile',
      },
      {
        title: 'Date Added',
        width: 200,
        id: 'created_at',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        width: 220,
        id: 'updated_at',
        model: 'updated_at',
        type: 'date_time',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ]
  }, [to, timezone])

  return (
    <DataTable
      asCard
      title="Treatment Episodes"
      icon="treatment_episodes"
      columns={columns}
      filtersConfig={FILTERS.treatment_episodes}
      {...rest}
    />
  )
}
