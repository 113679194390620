import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const CareTeamConnectionsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Client Name',
        id: 'reference',
        model: 'data.reference.name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => (
          <MainCell id={data.data.id} value={data.data.reference?.name} avatar={data.data.reference?.avatar} to={to?.(data.data)} />
        ),
      },
      {
        width: 260,
        isSticky: true,
        canToggleVisible: false,
        id: 'to',
        model: 'data.to.name',
        title: 'Connection Name',
        type: 'profile',
      },
      {
        width: 160,
        id: 'category',
        model: 'data.category',
        title: 'Category',
        formatValue: ({ data, value }: any) => {
          if (value === 'care_team') return <Status label="Care Team" color="orange" />
          if (value === 'organization') return <Status label="Organization" color="purple" />

          return '-'
        },
        // filterOptions: [
        //   {
        //     value: 'care_team',
        //     label: 'Care Team',
        //   },
        //   {
        //     value: 'organization',
        //     label: 'Organization',
        //   },
        // ],
      },
      {
        width: 200,
        id: 'subcategory',
        model: 'data.subcategory',
        title: 'Relation Type',
        formatValue: ({ value }: any) => startCase(value),
        // filterOptions: [
        //   {
        //     value: 'care_physician',
        //     label: 'Care Physician',
        //   },
        //   {
        //     value: 'psychiatrist',
        //     label: 'Psychiatrist',
        //   },
        //   {
        //     value: 'therapist',
        //     label: 'Therapist',
        //   },
        //   {
        //     value: 'recovery_coach',
        //     label: 'Recovery Coach',
        //   },
        //   {
        //     value: 'treatment_center',
        //     label: 'Treatment Center',
        //   },
        //   {
        //     value: 'sober_living_home',
        //     label: 'Sober Living Home',
        //   },
        //   {
        //     value: 'employer',
        //     label: 'Employer',
        //   },
        //   {
        //     value: 'school',
        //     label: 'School',
        //   },
        // ],
      },
      {
        width: 200,
        id: 'notes',
        model: 'data.notes',
        title: 'Notes',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Care Team Connections"
      icon="connections"
      columns={columns}
      filtersConfig={FILTERS.live_reports.client_connections}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="care_team_connections"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=care_team_connections'}
        />
      }
      {...props}
    />
  )
}
