import React from 'react'
import compact from 'lodash/compact'

import { useSettings } from '../../../../hooks/useSettings'
import withSettings from '../../../../hocs/withSettings'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import SmartFlags from '../../../Table/Cells/SmartFlags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
  sales_template: 'Sales',
}

const columns = (to, onClick, timezone, mainLinkAs, isHQApp) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'data.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={value} />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 240,
    Header: 'Smart Flags',
    accessor: 'smart_flags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <SmartFlags
        // queryKey={queryKey}
        value={row.original.smart_flags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: compact([
      { label: 'Public', value: 'public_template' },
      { label: 'Sales', value: 'sales_template' },
      isHQApp && { label: 'Behave', value: 'behave_template' },
    ]),
  },
  // {
  //   width: 120,
  //   accessor: 'category',
  //   Header: 'Visibility',
  //   Cell: ({ value }: any) => <QuickTextCategoryStatus status={value} />,
  // },
  {
    width: 200,
    accessor: 'data.subcategory',
    Header: 'Category',
  },
  {
    width: 260,
    accessor: 'data.notes',
    Header: 'Quick Text',
    Cell: ({ value }: any) => <TableCell.Markup value={value} />,
  },
  {
    width: 200,
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    width: 200,
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => <TableCell.UsDateTime value={value} timezone={timezone} />,
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const QuickTextInternalTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, emptyActions, emptyDescription, hiddenColumns, icon, localStorageKey, onClick, title, to, ...rest } =
    props

  const { timezone, isHQApp } = useSettings()

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, onClick, timezone, props.mainLinkAs, isHQApp)}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

QuickTextInternalTemplatesTable.defaultProps = {
  title: 'Quick Text Templates',
  icon: 'quick_text',
  emptyDescription: 'No Quick Text Templates to display',
  localStorageKey: 'quick_text_internal_templates',
}

export default withSettings(QuickTextInternalTemplatesTable)
