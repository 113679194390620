import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import NotificationStatus from '../../components/Statuses/NotificationStatus'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const NotificationsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        id: 'template',
        model: 'data.template',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.data.id} value={TEMPLATES?.[value]} />,
      },
      {
        width: 130,
        id: 'status',
        model: 'data.status',
        title: 'Status',
        formatValue: ({ data, value }: any) => <NotificationStatus status={value} />,
        // filterOptions: [
        //   {
        //     value: 'queued',
        //     label: 'Queued',
        //   },
        //   {
        //     value: 'completed',
        //     label: 'Sent',
        //   },
        // ],
      },
      {
        width: 160,
        id: 'phone_no',
        model: 'data.phone_no',
        title: 'Cell Phone',
      },
      {
        width: 300,
        id: 'email',
        model: 'data.email',
        title: 'Email',
        type: 'email',
      },
      {
        width: 100,
        id: 'send_sms',
        model: 'data.send_sms',
        title: 'Send SMS',
        type: 'boolean',
      },
      {
        width: 190,
        id: 'sent_sms_at',
        model: 'data.sent_sms_at',
        title: 'Sent SMS At',
        type: 'date_time',
      },
      {
        width: 100,
        id: 'send_email',
        model: 'data.send_email',
        title: 'Send Email',
        type: 'boolean',
      },
      {
        width: 190,
        id: 'sent_email_at',
        model: 'data.sent_email_at',
        title: 'Sent Email At',
        type: 'date_time',
      },
      {
        width: 500,
        id: 'sms_message',
        model: 'data.sms_message',
        title: 'Message',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Notifications"
      icon="notifications"
      columns={columns}
      filtersConfig={FILTERS.live_reports.notifications}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="notifications"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=notifications'}
        />
      }
      {...props}
    />
  )
}

const TEMPLATES = {
  account_expires_in: 'Account Expires In',
  agreements_notification: 'Agreements Notification',
  auth0_account_reactivation: 'Auth0 Account Reactivation',
  bps_completed: 'Bps Completed',
  client_login_credentials_updated: 'Client Login Credentials Updated',
  client_portal_reinvite: 'Client Portal Reinvite',
  confirmation_of_password_reset: 'Confirmation Of Password Reset',
  employee_login_credentials_updated: 'Employee Login Credentials Updated',
  generic_magic_link: 'Generic Magic Link',
  insufficient_funds_facility: 'Insufficient Funds Facility',
  insufficient_funds_payer: 'Insufficient Funds Payer',
  lead_magic_link: 'Lead Magic Link',
  level_of_care_conflict: 'Level Of Care Conflict',
  level_of_care_update: 'Level Of Care Update',
  new_chat_messages: 'New Chat Messages',
  new_client_accepted: 'New Client Accepted',
  new_client_application: 'New Client Application',
  new_event: 'New Event',
  new_evob: 'New Evob',
  new_insurance_vob_request: 'New Insurance Vob Request',
  new_todo: 'New Todo',
  notification_ledger_link: 'Notification Ledger Link',
  notification_ledger_update: 'Notification Ledger Update',
  notification_patient_discharge: 'Notification Patient Discharge',
  notification_pending_review: 'Notification Pending Review',
  notification_single_case_agreement: 'Notification Single Case Agreement',
  notify_authorization_update: 'Notify Authorization Update',
  nursing_assessment_completed: 'Nursing Assessment Completed',
  nursing_reassessment_completed: 'Nursing Reassessment Completed',
  progress_review_completed: 'Progress Review Completed',
  progress_review_incomplete: 'Progress Review Incomplete',
  registration_employee: 'Registration Employee',
  registration_owner: 'Registration Owner',
  registration_owner_reminder: 'Registration Owner Reminder',
  registration_resident: 'Registration Resident',
  reminder_1_progress_review: 'Reminder 1 Progress Review',
  reminder_2_progress_review: 'Reminder 2 Progress Review',
  reminder_3_progress_review: 'Reminder 3 Progress Review',
  reminder_4_progress_review: 'Reminder 4 Progress Review',
  reset_password: 'Reset Password',
  signup_end_of_trial: 'Signup End Of Trial',
  stripe_subscription_created: 'Stripe Subscription Created',
  stripe_subscription_updated: 'Stripe Subscription Updated',
  unauthorized_credentials: 'Unauthorized Credentials',
  upcoming_event: 'Upcoming Event',
  upcoming_todo: 'Upcoming Todo',
  user_duplication_detection: 'User Duplication Detection',
  vob_completed: 'Vob Completed',
  welcome_employee: 'Welcome Employee',
  welcome_owner: 'Welcome Owner',
  welcome_resident: 'Welcome Resident',
}
