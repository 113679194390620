import React from 'react'
import { Route, useLocation } from 'react-router-dom-v5-compat'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, HelpTagIframe } from '@behavehealth/components'
import { EventTemplatesDataTable } from '@behavehealth/components/Templates/tables/EventTemplatesDataTable'
import { ExportCSVButton } from '@behavehealth/components/Buttons/ExportCSVButton'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { withPageError } from '@behavehealth/hocs/withPageError'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

import useStore from '@behavehealth/modules/store'
import withMarketing from '@behavehealth/hocs/withMarketing'

import EventTemplatesImportOverlay from '@behavehealth/components/Templates/overlays/EventTemplatesImportOverlay'
import EventTemplateOverlay from '@behavehealth/components/Templates/overlays/EventTemplateOverlay'

const pageConfig = {
  feature: 'calendar_templates',
  title: 'Calendar Templates',
  help: <HelpTagIframe id="settings_calendar_templates" />,
}

const CalendarTemplates = () => {
  return (
    <div>
      <CalendarTemplatesIndex />

      <AnimatedRoutes>
        <Route path="import" element={<EventTemplatesImportOverlay useV6Router />} />
        <Route path=":id" element={<EventTemplateOverlay useV6Router />} />
      </AnimatedRoutes>
    </div>
  )
}

const CalendarTemplatesIndex = () => {
  const { pathname } = useLocation()
  const isBehave = useStore((state) => state?.isBehave)

  const tableProps = useDataTable({
    name: ['event-templates'],
    endpoint: `/event_templates`,
    params: { event_type: 'event' },
    localStorageKey: 'event_templates_v1',
  })

  return (
    <Page
      breakpoint={3}
      actions={
        <>
          {isBehave && (
            <Button
              label="Import Behave Templates"
              type="default"
              glyph="add"
              link={`${pathname}/import`}
              permission="calendar_templates.create"
            />
          )}
          <Button label="Add Event Template" type="primary" glyph="add" link={`${pathname}/new`} permission="calendar_templates.create" />
        </>
      }
      {...pageConfig}
    >
      <EventTemplatesDataTable
        to={(row: any) => row.id}
        headerAfter={<ExportCSVButton url={`/event_templates/csv`} params={{ event_type: 'event' }} />}
        {...tableProps}
        duplicateColumn={setDuplicateColumnV6({
          link: 'new',
          permission: 'calendar_templates.create',
        })}
      />
    </Page>
  )
}

export default withPageError(withMarketing(CalendarTemplates, pageConfig))
