import React from 'react'
import { Link, NavLink, Navigate, Route, Routes, useNavigate, useParams, useLocation } from 'react-router-dom-v5-compat'
import size from 'lodash/size'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { mapToArray } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Divider, Grid, Dropdown, DropdownItem, Page, HelpTagIframe } from '@behavehealth/components'
import { CustomNotesDataTable } from '@behavehealth/constructs/CustomNotes/CustomNotesDataTable'
import { ProgramCustomNoteOverlay } from '@behavehealth/constructs/CustomNotes/ProgramCustomNoteOverlay'

const AddDropdown = ({ templates }: any) => {
  return (
    <Dropdown label="Add Custom Note…" glyph="add" buttonType="primary" buttonSize={300} permission="program_custom_notes.create">
      {mapToArray(templates).map((template) => (
        <DropdownItem
          as={Link}
          key={template.id}
          label={template.name}
          glyph="documents"
          color="paleBlue"
          link="new"
          state={{
            data: template,
          }}
        />
      ))}

      <Divider />

      <DropdownItem
        as={Link}
        label="Empty Custom Note"
        glyph="add"
        color="paleBlue"
        link="new"
        state={{
          data: { name: 'New Custom Note' },
        }}
      />
    </Dropdown>
  )
}

const pageConfig = {
  feature: 'custom_notes',
  marketingID: 'client_financials',
  help: <HelpTagIframe id="custom_notes" />,
}

type Props = {
  canCreate: boolean
}

const CustomNotes = () => {
  const { resource_id } = useParams()

  return (
    <>
      <CustomNotesIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<ProgramCustomNoteOverlay programId={resource_id} useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const CustomNotesIndex: React.FC<Props> = () => {
  const { resource_id: id } = useParams()

  const tableProps = useDataTable({
    name: ['program', id, 'custom_notes'],
    endpoint: `/programs/${id}/custom_notes`,
    params: { variant: 'program' },
    updateDeleteEndpoint: '/custom_notes',
    localStorageKey: 'program_custom_notes',
  })

  const { data: templates, isLoading: isLoadingTemplates } = useGet({
    name: ['custom_note_templates', { variant: 'program' }],
    url: `/custom_note_templates`,
    params: { variant: 'program' },
  })

  const isTemplatesEmpty = size(templates) === 0

  if (!isLoadingTemplates && isTemplatesEmpty) {
    return (
      <>
        <Page
          isEmpty={true}
          emptyDescription="No custom note templates have been added yet. Go to Settings to create the first template."
          actions={
            <Button
              size={300}
              label="Manage Custom Note Templates"
              glyph="settings"
              type="primary"
              link="/settings/custom-note-templates/program"
              permission="settings.program_custom_note_templates.view"
            />
          }
          {...pageConfig}
        />
      </>
    )
  }

  return (
    <Page
      plan="basic"
      actions={
        <>
          <Button
            size={300}
            glyph="settings"
            type="default"
            label="Manage Templates"
            link="/settings/custom-note-templates/program"
            permission="settings.program_custom_note_templates.view"
          />

          <AddDropdown templates={templates} />
        </>
      }
      {...pageConfig}
    >
      <Grid>
        <CustomNotesDataTable
          mainLinkAs={NavLink}
          {...tableProps}
          to={(data: any) => data.id}
          duplicatePermission="program_custom_notes.create"
          duplicateLinkPropsV6={(record) => {
            const {
              client,
              employees,
              ended_at,
              name,
              notes,
              office,
              organization,
              portal_settings,
              property,
              reference,
              sections,
              settings,
              started_at,
              supervisor,
              tags,
              variant,
            } = record

            return {
              link: 'new',
              state: {
                data: {
                  status: 'draft',
                  client,
                  employees,
                  ended_at,
                  name,
                  notes,
                  office,
                  organization,
                  portal_settings,
                  property,
                  reference,
                  sections,
                  settings,
                  started_at,
                  supervisor,
                  tags,
                  variant,
                },
              },
            }
          }}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'program_custom_notes.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(CustomNotes, pageConfig))
