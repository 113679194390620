import React from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'
import { useCreate, useGet } from '../../hooks/useNewAPI'
import { useSettings } from '../../hooks/useSettings'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import Section from '../../components/Section'
import SummonOverlay from '../../components/SummonOverlay'
import Tabs from '../../components/Tabs'
import TemplateLegalTooltip from '../../components/Templates/TemplateLegalTooltip'

import { TagGroupInternalTemplateOverlay } from './TagGroupInternalTemplateOverlay'
import { TagGroupTemplatesImportTable } from './TagGroupTemplatesImportTable'
import { ImportTemplatesTabs } from '../Templates/ImportTemplatesTabs'

export const TagGroupTemplatesImportOverlay = () => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])
  const [preview, setPreview]: any = React.useState(null)
  const [selectedType, setSelectedType]: any = React.useState('public_template')

  const { isBehave } = useSettings()

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const filters = {
    category: 'tag_group',
    status: 'active',
    level: selectedType,
  }

  const { data: templates, isLoading: isLoadingTemplates }: any = useGet({
    name: ['internal-templates', filters],
    url: `/internal_templates`,
    params: filters,
    options: { enabled: !!selectedType },
  })

  const { mutate, isLoading: isImporting } = useCreate({
    name: 'import-tag-groups',
    url: '/internal_templates/import',
    invalidate: 'tag-groups',
    onSuccess: () => {
      history.goBack()
    },
  })

  const importTemplates = () => {
    const ids = selectedRows.map((o: any) => o.original.id)
    mutate({ ids: ids, category: 'tag_group' })
  }

  return (
    <Overlay showBackdrop position="center" maxWidth={120}>
      <Overlay.Header title="Import Tags" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        {preview && (
          <SummonOverlay
            isOpen={!!preview}
            onClose={() => setPreview(null)}
            overlay={<TagGroupInternalTemplateOverlay allowEditing={false} dataID={preview.id} initialData={preview.data} />}
          />
        )}

        <Section margin="0 0 1.5rem 0">
          <ImportTemplatesTabs onChange={setSelectedType} />

          <Card className="!mt-4">
            <TagGroupTemplatesImportTable
              title="Tag Templates"
              data={templates}
              isLoading={isLoadingTemplates}
              onSelect={setSelectedRows}
              onClick={setPreview}
            />
          </Card>
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Templates', selectedRowsCount)}` : 'Select Templates to Import'}
          isDisabled={!hasSelectedRows}
          isLoading={isImporting}
          type="primary"
          color="green"
          onClick={importTemplates}
        />
      </Overlay.Footer>
    </Overlay>
  )
}
