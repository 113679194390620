import React from 'react'
import compact from 'lodash/compact'

import { usDate } from '../../../../utils/functions'
import { useSettings } from '../../../../hooks/useSettings'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import withSettings from '../../../../hocs/withSettings'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
  sales_template: 'Sales',
}

const columns = (to: any, timezone: any, mainLinkAs, isHQApp) => [
  {
    width: 240,
    isSticky: true,
    canToggleVisible: false,
    accessor: 'data.name',
    Header: 'Name',
    Cell: ({ value, row }: any) => (
      <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} label={value} avatar={row.original.avatar} />
    ),
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: compact([
      { label: 'Public', value: 'public_template' },
      { label: 'Sales', value: 'sales_template' },
      isHQApp && { label: 'Behave', value: 'behave_template' },
    ]),
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    accessor: 'created_at',
    Header: 'Date Added',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
  {
    accessor: 'updated_at',
    Header: 'Date Updated',
    Cell: ({ value }: any) => usDate(value, timezone),
    Filter: TableFilter.Date,
    filter: 'date',
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  emptyActions?: React.ReactNode
  emptyDescription: string
  hiddenColumns?: string[]
  icon: string
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  timezone: string
  title: string
  to?: Function
}

const PermissionInternalTemplatesTable = (props: Props) => {
  const {
    batchActionsConfig,
    data,
    emptyActions,
    emptyDescription,
    hiddenColumns,
    icon,
    isLoading,
    localStorageKey,
    onClick,
    timezone,
    title,
    to,
    ...rest
  } = props

  const { isHQApp } = useSettings()

  return (
    <Table
      title={title}
      icon={icon}
      data={data}
      columns={columns(to, timezone, props.mainLinkAs, isHQApp)}
      isLoading={isLoading}
      emptyActions={emptyActions}
      emptyDescription={emptyDescription}
      batchActionsConfig={batchActionsConfig}
      hiddenColumns={hiddenColumns}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

PermissionInternalTemplatesTable.defaultProps = {
  title: 'Permission Templates',
  icon: 'permissions',
  emptyDescription: 'No permission templates to display',
  localStorageKey: 'permission_internal_templates',
}

export default withSettings(PermissionInternalTemplatesTable)
