import React from 'react'

import { useSettings } from '../../hooks/useSettings'

import Select from '../../components/Forms/Select'
import Option from '../../components/Forms/Option'

export const TemplateLevelSelect = (props) => {
  const { isSalesApp } = useSettings()

  if (isSalesApp) {
    return (
      <Select allowEmpty model="level" label="Level" fullWidth {...props} defaultValue="sales_template">
        <Option label="Sales Template" value="sales_template" />
        {!props.hidePublic && <Option label="Public Template" value="public_template" />}
      </Select>
    )
  }

  return (
    <>
      <Select allowEmpty model="level" label="Level" fullWidth defaultValue="behave_template" {...props}>
        <Option label="Behave Template" value="behave_template" />
        {!props.hidePublic && <Option label="Public Template" value="public_template" />}
        <Option label="Sales Template" value="sales_template" />
      </Select>
    </>
  )
}
