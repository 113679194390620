import React from 'react'
import size from 'lodash/size'

import { countWord } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'

import Card from '../../components/Card'
import Overlay from '../../components/Overlay'
import StaffProfileHeader from '../../components/StaffProfileHeader'

export const AccessListAuditFixOverlay = (props: any) => {
  const { employee, auditData = [], onClose } = props

  const add = auditData.filter((o) => !o.in_access_list && o.in_staff_programs_or_locations)
  const remove = auditData.filter((o) => o.in_access_list && !o.in_staff_programs_or_locations)

  return (
    <Overlay onClose={onClose}>
      <Overlay.Header glyph="tick_circle" title="Fix Staff Access List Issues" subtitle={<StaffProfileHeader employee={employee} />} />

      <Overlay.Content className="grid gap-4 p-4 content-start">
        {/* <Card className="px-3 py-3 grid gap-3">
          <StaffProfileHeader employee={employee} />
        </Card> */}

        <Card className="px-3 py-3 grid gap-3">
          <Alert contrast small glyph="tick_circle" type="positive">
            The following {countWord('client', size(add))} will be added to the Staff Access List.
          </Alert>

          <Alert contrast small glyph="info">
            <b>Reason:</b> the Clients below are part of the Staff member's assigned Programs or Locations, but are not included in the
            Staff Access List.
          </Alert>

          {add.map((o) => {
            return <ProfileCellInner value={o} key={o.id} />
          })}
        </Card>

        <Card className="px-3 py-3 grid gap-3">
          <Alert contrast small glyph="circle_error" type="negative">
            The following {countWord('client', size(remove))} will be removed from the Staff Access List.
          </Alert>

          <Alert contrast small glyph="info">
            <b>Reason:</b> the Clients below are included in the Staff Access List, but are not part of the Staff member's assigned Programs
            or Locations
          </Alert>

          {remove.map((o) => {
            return <ProfileCellInner value={o} key={o.id} />
          })}
        </Card>
      </Overlay.Content>

      <Overlay.Footer>
        <Button label="Accept & Apply Changes" type="primary" color="green" glyph="tick_circle" />
      </Overlay.Footer>
    </Overlay>
  )
}
