import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { usDateTime, isPast } from '../../utils/functions'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'
import { FILTERS } from '../Filters/config'

export const BedOccupanciesDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'From',
        model: 'started_at',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={to(data)} value={usDateTime(value, timezone)} />
        },
      },
      {
        title: 'Until',
        model: 'ended_at',
        width: 200,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          if (data.period_type === 'indefinite') return 'Discharged'

          return usDateTime(value, timezone)
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 140,
        formatValue: ({ data, value }: any) => {
          if (data.period_type === 'indefinite') return <Status small color="green" label="Active" />
          if (isPast(data.ended_at)) return <Status small color="gray" label="Ended" />

          return <Status small color="green" label="Active" />
        },
      },
      {
        id: 'house',
        title: 'Location',
        model: 'place.house',
        type: 'profile',
        width: 160,
      },
      {
        id: 'floor',
        title: 'Floor',
        model: 'place.floor.name',
        width: 160,
      },
      {
        id: 'room',
        title: 'Room',
        model: 'place.room.name',
        width: 160,
      },
      {
        id: 'bed',
        title: 'Bed',
        model: 'place.name',
        width: 160,
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return (
    <DataTable
      asCard
      title="Bed Assignments"
      icon="beds"
      testKey="bed_assignments_data_table"
      columns={columns}
      filtersConfig={FILTERS.bed_occupancies}
      {...rest}
    />
  )
}
