import React from 'react'
import clsx from 'clsx'

import { isDefined, beautifulFloat } from '../../../utils/functions'

import { EmptyCell } from './EmptyCell'
import { DataTableCell } from '../DataTableCell'

export const FloatNumberCell: React.FC = (props: any) => {
  const { value } = props

  const finalValue = parseFloat(value)
  const hasNoValue = !value || finalValue === 0
  const rootClasses = clsx(!hasNoValue && 'has-value', props.className)

  return (
    <DataTableCell {...props} hoverExpand className={rootClasses} css={STYLES.root} align="right">
      {hasNoValue ? <EmptyCell defaultEmptyValue={0} /> : beautifulFloat(finalValue)}
    </DataTableCell>
  )
}

const STYLES = {
  root: {
    textAlign: 'right',
    justifyContent: 'flex-end',

    '&.has-value': {
      fontWeight: 600,
    },
  },
}
