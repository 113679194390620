import React from 'react'
import clsx from 'clsx'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS } from '../../theme'
import { arrayToMapWithKey, daysToWords, nicePercentage, singularOrPlural, titleCase } from '../../utils/functions'
import { PlainSwitch } from '../../components/Forms/Switch'
import { useCreate, useDelete, useGet, useUpdate } from '../../hooks/useNewAPI'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import Badge from '../../components/Badge'
import DonutChart from '../../components/DonutChart'
import Card from '../../components/Card'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Dropdown from '../../components/Dropdown'
import DropdownItem from '../../components/DropdownItem'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import Glyph from '../../components/Glyph'
import Label from '../../components/Label'
import Nav from '../../components/Nav'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import PageGrid from '../../components/PageGrid'
import ScrollMenu from '../../components/ScrollMenu'
import ScrollView from '../../components/ScrollView'
import Section from '../../components/Section'
import SmartStatus from '../../components/SmartStatus'
import SmartTextarea from '../../components/Forms/SmartTextarea'
import YesNoRadioGroup from '../../components/Forms/elements/YesNoRadioGroup'

import { AUTHORITY_FORMS, CATEGORIES, SUBCATEGORIES } from './forms'
import { AuthorityFormAssignmentSelector } from './AuthorityFormAssignmentSelector'
import { AuthorityFormGeneralSection } from './AuthorityFormGeneralSection'
import { AuthorityFormStatusSelect } from './AuthorityFormStatusSelect'
import { COMMENT_STATUSES, FORM_STATUSES } from './constants'
import { CustomQuestion } from './components/CustomQuestion'
import { QuestionRenderer } from './components/QuestionRenderer'
import { QUESTIONS } from './questions'
import { getFormSchema } from './schemas'

const QUESTIONS_MAP = arrayToMapWithKey(QUESTIONS, 'identifier')

const RootAuthorityFormSubmission = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    id,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isLoading,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    saveWithData,
    updateAsync,
    initialData,
    params,
    refetch,
  } = useOverlay({
    name: 'authority_form_submissions',
    endpoint: '/authority_form_submissions',
    invalidate: 'authority_form_submissions',
    options: props,
    disableParentRequest: true,
  })

  const formId = props?.formId || initialData?.authority_form_id || params?.formId

  const [formData, setFormData] = React.useState(data)
  const [status, setStatus] = React.useState(data?.status)
  const [assignee, setAssignee] = React.useState(data?.assignee)

  const { data: apiAuthorityForm, isLoading: isLoadingAuthorityForm }: any = useGet({
    name: ['authority_forms', formId],
    url: `/authority_forms/${formId}`,
    options: { enabled: isNew && !!formId },
  })

  const authorityForm = isNew
    ? {
        ...apiAuthorityForm,
        ...initialData,
      }
    : data

  const [mode, setMode] = React.useState('complete')

  const { isBehave, timezone } = useSettings()

  const [isCommentsVisible, setIsCommentsVisible] = React.useState(!isNew)
  const [isInfoVisible, setIsInfoVisible] = React.useState(false)

  const handleCommentsChange = () => setIsCommentsVisible((prev) => !prev)
  const handleInfoChange = () => setIsInfoVisible((prev) => !prev)

  const save = async () => {
    const formData = produce(form.current?.getFormValue(), (draft) => {
      if (mode === 'evaluate') {
        delete draft.data
      }

      if (mode === 'complete') {
        delete draft.evaluator_data
      }
    })

    await saveWithData({
      ...formData,
      status,
      assignee_id: assignee?.id,
      assignee_type: assignee?.type,
    })
  }

  const sharedCommentsByQuestionId = React.useMemo(() => {
    const res: any = {}

    if (data?.shared_authority_data_comments) {
      for (const comment of data?.shared_authority_data_comments) {
        if (!res[comment.identifier]) {
          res[comment.identifier] = []
        }

        res[comment.identifier].push(comment)
      }
    }

    return res
  }, [data])

  const internalCommentsByQuestionId = React.useMemo(() => {
    const res: any = {}

    if (data?.internal_authority_data_comments) {
      for (const comment of data?.internal_authority_data_comments) {
        if (!res[comment.identifier]) {
          res[comment.identifier] = []
        }

        res[comment.identifier].push(comment)
      }
    }

    return res
  }, [data])

  const formSchema = React.useMemo(() => {
    return authorityForm?.schema
  }, [authorityForm])

  const localSchema = React.useMemo(() => {
    if (!authorityForm?.subcategory) return null

    return getFormSchema(authorityForm?.subcategory)
  }, [authorityForm])

  React.useEffect(() => {
    if (data?.status !== status) {
      setStatus(data?.status)
    }

    if (data?.assignee?.id !== assignee?.id) {
      setAssignee(data?.assignee)
    }
  }, [data])

  if (isOverlayLoading || (isNew && isLoadingAuthorityForm)) {
    return <OverlayLoader position="right" maxWidth={140} />
  }

  const CONFIG = AUTHORITY_FORMS[authorityForm?.subcategory]

  if (!CONFIG) {
    console.error(`Form config not found for ${authorityForm?.subcategory}`)

    return null
  }

  const { name, icon, isEvaluatorOnly } = CONFIG

  if (!formSchema) {
    console.error(`Form schema not found for ${authorityForm?.subcategory}`)

    return null
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} maxWidth={140}>
      <Overlay.Header icon={icon} title={name} />

      <Overlay.SubHeader className="flex items-center justify-between flex-nowrap px-2 !py-1.5">
        <Flex alignItems="center" gap="1rem">
          <AuthorityFormStatusSelect
            status={status || 'draft'}
            statuses={FORM_STATUSES}
            onSelect={(status) => {
              setStatus(status)

              if (!isEditable) updateAsync({ status: status })
            }}
          />

          <AuthorityFormAssignmentSelector
            onUpdate={(value) => {
              setAssignee(value)

              if (!isEditable) updateAsync({ assignee_id: value?.id, assignee_type: value?.type })
            }}
          />
        </Flex>

        {isBehave && (
          <div className="flex flex-nowrap items-center ml-auto mr-5">
            <Label label="Show Info (BH)" onClick={handleInfoChange} className="!flex-[0_0_auto] cursor-pointer" />
            <PlainSwitch size={200} isChecked={isInfoVisible} onCheckedChange={handleInfoChange} />
          </div>
        )}

        {!isNew && (
          <div className="flex flex-nowrap items-center">
            <Label label="Show Comments" onClick={handleCommentsChange} className="!flex-[0_0_auto] cursor-pointer" />
            <PlainSwitch size={200} isChecked={isCommentsVisible} onCheckedChange={handleCommentsChange} />
          </div>
        )}
      </Overlay.SubHeader>

      <ScrollView>
        <PageGrid scroll breakpoint={3} className="overlay-page-grid flex-[1_1_auto]">
          <Nav
            top="0"
            breakpoint={3}
            title={' '}
            headingSize={300}
            desktopProps={{ title: null }}
            className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
          >
            <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
            <ScrollMenu />
          </Nav>

          <Overlay.Content>
            <Form
              getForm={form}
              initialModel={initialModel}
              isEditable={isEditable}
              timezone={timezone}
              onValidationUpdate={onValidationUpdate}
              key={`updated-${data?.updated_at}`}
              onUpdate={setFormData}
              linked={
                isNew
                  ? {
                      organization_id: params.resource_id,
                      authority_form_id: authorityForm?.id,
                      schema: apiAuthorityForm?.schema,
                      category: apiAuthorityForm?.category,
                      subcategory: apiAuthorityForm?.subcategory,
                    }
                  : undefined
              }
            >
              <AuthorityFormGeneralSection defaultName={name} isNew={isNew} />

              <Divider />

              {Object.keys(localSchema).map((categoryId: any, index) => {
                const section = formSchema[categoryId]
                const category = CATEGORIES[categoryId]

                let isAllHidden = true

                for (const subcategoryId of Object.keys(section)) {
                  const subsection = authorityForm?.schema?.[categoryId]?.[subcategoryId]

                  if (!subsection) continue

                  if (size(subsection.custom_questions_before) > 0 || size(subsection.custom_questions_after) > 0) {
                    isAllHidden = false
                    break
                  }

                  isAllHidden = Object.keys(subsection.fields).every((questionId: any) => !subsection.fields[questionId].show)

                  if (!isAllHidden) break
                }

                if (isAllHidden) return null

                const questionIds = Object.keys(section)
                  .map((subcategoryId: any) => {
                    const subsection = authorityForm?.schema?.[categoryId]?.[subcategoryId]

                    if (!subsection) return []

                    return Object.keys(subsection.fields).filter((questionId: any) => subsection.fields[questionId].show)
                  })
                  .flat()

                const answeredQuestionIds = questionIds.filter((questionId: any) => {
                  const value = formData?.data?.[questionId]

                  const isEmpty =
                    value === undefined ||
                    value === null ||
                    value === '' ||
                    value === 0 ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'object' && Object.keys(value).length === 0)

                  return !isEmpty
                })

                const evaluatedQuestionIds = questionIds.filter((questionId: any) => {
                  const value = formData?.evaluator_data?.[questionId]

                  const isEmpty =
                    value === undefined ||
                    value === null ||
                    value === '' ||
                    value === 0 ||
                    (Array.isArray(value) && value.length === 0) ||
                    (typeof value === 'object' && Object.keys(value).length === 0)

                  return !isEmpty
                })

                const positiveQuestionIds = questionIds.filter((questionId: any) => {
                  const value = formData?.evaluator_data?.[questionId]
                  return value === true
                })

                const answeredPercentage = (answeredQuestionIds.length / questionIds.length) * 100
                const evaluatedPercentage = (evaluatedQuestionIds.length / questionIds.length) * 100
                const positivePercentage = (positiveQuestionIds.length / questionIds.length) * 100

                return (
                  <React.Fragment key={categoryId}>
                    {index > 0 && <Divider />}

                    <Section>
                      <header className="sticky py-2 -mx-2 px-2 top-[-1px] bg-[#F7F8FB] z-[1] flex items-center justify-between">
                        <h2>{category?.name || 'No Category'}</h2>

                        <Flex gap="1rem">
                          <Card className="flex flex-nowrap items-center opacity-80 text-[0.85rem] uppercase tracking-[0.5px] px-2 py-1">
                            <DonutChart
                              value={answeredPercentage}
                              size={16}
                              strokeWidth={3}
                              background={COLORS.divider}
                              labelSize="0px"
                              className="mr-1.5"
                            />
                            <span className="text-text text-[1.1em] font-[700] mr-0.5">
                              {answeredPercentage ? nicePercentage(answeredPercentage.toFixed(0)) : 0}%
                            </span>
                            <span className="text-text-muted font-[400]">Answered</span>
                          </Card>

                          <Card className="flex flex-nowrap items-center opacity-80 text-[0.85rem] uppercase tracking-[0.5px] px-2 py-1">
                            <DonutChart
                              value={evaluatedPercentage}
                              size={16}
                              strokeWidth={3}
                              background={COLORS.divider}
                              labelSize="0px"
                              className="mr-1.5"
                            />
                            <span className="text-text text-[1.1em] font-[700] mr-0.5">
                              {evaluatedPercentage ? nicePercentage(evaluatedPercentage.toFixed(0)) : 0}%
                            </span>
                            <span className="text-text-muted font-[400]">Evaluated</span>
                          </Card>

                          <Card className="flex flex-nowrap items-center opacity-80 text-[0.85rem] uppercase tracking-[0.5px] px-2 py-1">
                            <DonutChart
                              value={positivePercentage}
                              size={16}
                              strokeWidth={3}
                              background={COLORS.divider}
                              labelSize="0px"
                              className="mr-1.5"
                            />
                            <span className="text-text text-[1.1em] font-[700] mr-0.5">
                              {positivePercentage ? nicePercentage(positivePercentage.toFixed(0)) : 0}%
                            </span>
                            <span className="text-text-muted font-[400]">Evaluation Score</span>
                          </Card>
                        </Flex>
                      </header>

                      <div className="grid gap-5 grid-cols-1 relative z-0">
                        {Object.keys(section).map((subcategoryId: any) => {
                          const subcategory = SUBCATEGORIES[subcategoryId]

                          const subsection = authorityForm?.schema?.[categoryId]?.[subcategoryId]
                          const isVisible = subsection?.show
                          const fields = subsection?.fields

                          const isAllHidden =
                            Object.keys(fields).every((questionId: any) => !fields[questionId].show) &&
                            size(subsection.custom_questions_before) === 0 &&
                            size(subsection.custom_questions_after) === 0

                          if (!isVisible || isAllHidden) return null

                          return (
                            <React.Fragment key={subcategoryId}>
                              <Section
                                title={subcategory?.name || 'No Subcategory'}
                                headingType="h3"
                                className="!m-0 !mt-4 !p-0"
                                scrollview={{
                                  id: subcategoryId,
                                  name: subcategory?.name || 'No Subcategory',
                                  parent: {
                                    id: categoryId,
                                    name: category?.name || 'No Category',
                                  },
                                }}
                              >
                                <div className="grid gap-4">
                                  {/* {subsection?.custom_questions_before?.map?.((question: any) => {
                                    return (
                                      <CustomQuestion
                                        key={question._id}
                                        question={question}
                                        sectionModel={`${categoryId}.${subcategoryId}`}
                                      />
                                    )
                                  })} */}

                                  {localSchema?.[categoryId]?.[subcategoryId].map(({ identifier: questionId }: any) => {
                                    const field = fields[questionId]

                                    if (!field.show) return null

                                    const question = QUESTIONS_MAP[questionId]
                                    const isQuestionEditable = isEditable && mode === 'complete'
                                    const isEvaluationEditable = isEditable && mode === 'evaluate'

                                    if (!question) return null

                                    return (
                                      <React.Fragment key={question?.identifier}>
                                        <Question
                                          isNew={isNew}
                                          data={data}
                                          question={question}
                                          isQuestionEditable={isQuestionEditable}
                                          isEvaluationEditable={isEvaluationEditable}
                                          isCommentsVisible={isCommentsVisible}
                                          setIsCommentsVisible={setIsCommentsVisible}
                                          isEvaluatorOnly={isEvaluatorOnly}
                                          isInfoVisible={isInfoVisible}
                                          canAddComments={!isEditable}
                                          sharedComments={sharedCommentsByQuestionId[question?.identifier]}
                                          internalComments={internalCommentsByQuestionId[question?.identifier]}
                                          refetch={refetch}
                                        />
                                      </React.Fragment>
                                    )
                                  })}

                                  {/* {subsection?.custom_questions_after?.map?.((question: any) => {
                                    return (
                                      <CustomQuestion
                                        key={question._id}
                                        question={question}
                                        sectionModel={`${categoryId}.${subcategoryId}`}
                                      />
                                    )
                                  })} */}
                                </div>
                              </Section>
                            </React.Fragment>
                          )
                        })}
                      </div>
                    </Section>
                  </React.Fragment>
                )
              })}
            </Form>
          </Overlay.Content>
        </PageGrid>
      </ScrollView>

      <Overlay.Footer withGradient={false}>
        {isEditable && (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              isLoading={isSaving}
              onClick={save}
              // isDisabled={isInvalid}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            {!isNew && <Button label="Cancel" glyph="cross" type="default" isDisabled={isSaving} onClick={cancel} />}
          </>
        )}

        {!isEditable && (
          <>
            <Button
              glyph="edit"
              label={`Edit ${name}`}
              type="default"
              isDisabled={isLoading}
              onClick={() => {
                isEvaluatorOnly ? setMode('evaluate') : setMode('complete')
                edit()
              }}
              flex="100 1 auto"
              // permission="__PERMISSION__.edit"
            />

            {!isEvaluatorOnly && (
              <Button
                glyph="view"
                label={`Evaluate ${name}`}
                type="primary"
                isDisabled={isLoading}
                onClick={() => {
                  setMode('evaluate')
                  edit()
                }}
                flex="100 1 auto"
                // permission="__PERMISSION__.edit"
              />
            )}

            <DeleteDialog
              title={`Delete ${name}?`}
              message={`Are you sure you want to delete this ${name}? This action cannot be undone.`}
              onYes={deleteRecord}
            >
              <Button
                glyph="delete"
                label="Delete"
                type="default"
                color="red"
                isLoading={isDeleting}
                fullWidth
                // permission="__PERMISSION__.delete"
              />
            </DeleteDialog>
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

const Question = (props: any) => {
  const {
    isNew,
    data,
    question,
    isInfoVisible,
    isQuestionEditable,
    isEvaluationEditable,
    isEvaluatorOnly,
    isCommentsVisible,
    setIsCommentsVisible,
    canAddComments,
    refetch,
  } = props

  const { tenant, user } = useSettings()

  const authorityName = props.authorityName || tenant?.name

  const [sharedComments, setSharedComments]: any = React.useState(props.sharedComments || [])
  const [internalComments, setInternalComments]: any = React.useState(props.internalComments || [])

  React.useEffect(() => {
    setSharedComments(props.sharedComments || [])
    setInternalComments(props.internalComments || [])
  }, [props.sharedComments, props.internalComments])

  const Wrapper = isCommentsVisible ? React.Fragment : 'div'

  return (
    <Card
      className={clsx(
        'grid',
        !isEvaluatorOnly && (isCommentsVisible ? 'mq1200:grid-cols-[2fr_1fr_280px_280px]' : 'grid-cols-[2fr_1fr_auto]'),
        isEvaluatorOnly && (isCommentsVisible ? 'mq1200:grid-cols-[1fr_280px_280px]' : 'grid-cols-[1fr_auto]'),
      )}
    >
      {!isEvaluatorOnly && (
        <div className="px-4 py-2 border-r border-0 border-solid border-divider">
          <QuestionRenderer
            isInfoVisible={isInfoVisible}
            authorityName={authorityName}
            question={{
              ...question,
              model: `data.${question.identifier}`,
            }}
            isEditable={isQuestionEditable}
            statusesClassName="mb-2"
          />
        </div>
      )}

      <div className="px-4 pt-2 pb-4 border-r border-0 border-solid border-divider">
        <YesNoRadioGroup
          withHover={false}
          isEditable={isEvaluationEditable}
          label={question.evaluatorCriteria || 'The provided documentation meets the requirement(s)'}
          model={`evaluator_data.${question.identifier}`}
        />
      </div>

      {!isNew && (
        <Wrapper>
          <Comments
            visibility="internal"
            comments={internalComments}
            canAddComments={canAddComments}
            refetch={refetch}
            setIsCommentsVisible={setIsCommentsVisible}
            isCommentsVisible={isCommentsVisible}
            question={question}
            data={data}
            className="border-r border-0 border-solid border-divider"
          />

          <Comments
            visibility="shared"
            comments={sharedComments}
            canAddComments={canAddComments}
            refetch={refetch}
            setIsCommentsVisible={setIsCommentsVisible}
            isCommentsVisible={isCommentsVisible}
            question={question}
            data={data}
          />
        </Wrapper>
      )}
    </Card>
  )
}

const Comments = (props: any) => {
  const { visibility = 'internal', canAddComments, className, refetch, setIsCommentsVisible, isCommentsVisible, question, data } = props

  const classes = clsx('px-4 py-2', className)

  const [comments, setComments]: any = React.useState(props.comments || [])
  const commentsCount = size(comments)
  const hasComments = commentsCount > 0

  const [showNewComment, setShowNewComment] = React.useState(false)
  const [newMessage, setNewMessage] = React.useState('')
  const [newStatus, setNewStatus] = React.useState('draft')

  const { mutateAsync, isLoading: isSavingComment } = useCreate({
    name: ['authority_data_comments'],
    url: '/authority_data_comments',
    invalidate: 'authority_data_comments',
  })

  const commentType = titleCase(visibility)

  React.useEffect(() => {
    setComments(props.comments || [])
  }, [props.comments])

  return (
    <div className={classes}>
      {isCommentsVisible ? (
        <>
          <header className="flex items-center flex-nowrap justify-between">
            <div className="flex items-center flex-wrap text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px] pr-2">
              {hasComments && <Badge className="mr-1">{commentsCount}</Badge>}
              {commentsCount === 1 ? `${commentType} Comment` : `${commentType} Comments`}
            </div>

            {!hasComments && canAddComments && (
              <Button
                label={`${commentType} Comment`}
                type="default"
                size={100}
                color="blue"
                glyph="add"
                display="inline-flex"
                className="!whitespace-nowrap !shrink-0"
                onClick={async () => {
                  setIsCommentsVisible(true)
                  setShowNewComment(true)
                }}
              />
            )}
          </header>

          {hasComments && (
            <div className="grid gap-2 mt-2">
              {comments.map((comment) => (
                <Comment key={comment.id} comment={comment} refetch={refetch} canAddComments={canAddComments} />
              ))}

              {canAddComments && !showNewComment && (
                <div>
                  <Button
                    label={`${commentType} Comment`}
                    type="default"
                    size={100}
                    color="blue"
                    glyph="add"
                    display="inline-flex"
                    className="whitespace-nowrap"
                    onClick={async () => {
                      setIsCommentsVisible(true)
                      setShowNewComment(true)
                    }}
                  />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        <>
          <div className="flex flex-col items-center flex-nowrap">
            {canAddComments && (
              <Button
                hideLabel
                size={200}
                type="link"
                color={hasComments ? 'blue' : 'textMuted'}
                glyph="comment"
                display="inline-flex"
                className=""
                onClick={() => {
                  setIsCommentsVisible(true)
                  // setShowNewComment(true)
                }}
              />
            )}

            {hasComments && <Badge className="mt-1">{commentsCount}</Badge>}
          </div>
        </>
      )}

      {showNewComment && (
        <div className="pt-3">
          <div className="flex items-center justify-between mb-1.5">
            <div className="flex items-center flex-nowrap text-text-muted opacity-80 text-[0.85rem] uppercase font-[700] tracking-[0.5px]">
              New {commentType} Comment
            </div>

            <CommentStatus
              isEditable
              value={newStatus}
              onUpdate={(value) => {
                setNewStatus(value)
              }}
            />
          </div>

          <SmartTextarea
            isEditable
            autoFocus
            useQuickText={false}
            useDictation
            placeholder="Your comment"
            value={newMessage}
            onUpdate={({ value }) => setNewMessage(value)}
            withHover={false}
            key={`comment_${commentsCount}`}
          />

          <div className="mt-2">
            <Flex gap="0.5rem">
              <Button
                label={`Save ${commentType} Comment`}
                type="primary"
                size={100}
                color="green"
                glyph="tick_circle"
                display="inline-flex"
                isDisabled={!newMessage}
                isLoading={isSavingComment}
                onClick={async () => {
                  await mutateAsync({
                    message: newMessage,
                    status: newStatus,
                    identifier: question.identifier,
                    reference_id: data.id,
                    reference_type: data.type,
                    visibility,
                  })

                  await refetch()

                  setShowNewComment(false)
                  setNewMessage('')
                }}
              />

              <Button
                label="Cancel"
                size={100}
                type="default"
                onClick={() => {
                  setShowNewComment(false)
                  setNewMessage('')
                }}
              />
            </Flex>
          </div>
        </div>
      )}
    </div>
  )
}

const Comment = (props: any) => {
  const { comment, refetch, canAddComments } = props

  const [message, setMessage] = React.useState(comment?.message || '')
  const [status, setStatus] = React.useState(comment?.status || 'draft')

  const [isEditable, setIsEditable] = React.useState(false)
  const [isSaving, setIsSaving] = React.useState(false)

  const { tenant, user: currentUser } = useSettings()

  const updateQuery = useUpdate({
    name: ['authority_data_comments'],
    url: `/authority_data_comments/${comment.id}`,
    invalidate: 'authority_data_comments',
  })

  const deleteQuery = useDelete({
    name: ['authority_data_comments'],
    url: `/authority_data_comments/${comment.id}`,
    invalidate: 'authority_data_comments',
  })

  if (!comment) return null

  const { facility, user: commentUser, updated_at } = comment

  const isAuthor = commentUser.id === currentUser.id && tenant.id === facility.id

  return (
    <div className="pt-2 pb-3 px-4 border-b border-solid border-0 border-divider -mx-4">
      <div className="flex flex-nowrap">
        <div className="w-10 h-10 flex justify-start items-start justify-start">
          <Avatar src="" initials={facility?.name} size={26} />
        </div>

        <div className="-mt-0.5">
          <div className="font-[600]">{facility?.name}</div>
          <div className="text-[0.92em] text-text-muted">
            {commentUser?.name} ({daysToWords(updated_at)})
          </div>
        </div>

        <div className="ml-auto pl-2 -mt-1">
          <CommentStatus
            value={status}
            isEditable={isEditable}
            onUpdate={(value) => {
              setStatus(value)
            }}
          />
        </div>
      </div>

      <div className="pl-10 mt-1.5">
        {isEditable ? (
          <React.Fragment key="edit-mode">
            <SmartTextarea
              isEditable
              autoFocus
              useQuickText={false}
              useDictation
              placeholder="Your comment"
              value={message}
              onUpdate={({ value }) => setMessage(value)}
              withHover={false}
            />

            <div className="mt-2">
              <Flex gap="0.75rem">
                <Button
                  label="Save"
                  type="primary"
                  size={100}
                  color="green"
                  glyph="tick_circle"
                  display="inline-flex"
                  isLoading={isSaving}
                  onClick={async () => {
                    setIsSaving(true)

                    try {
                      await updateQuery.mutateAsync({
                        id: comment.id,
                        message,
                        status,
                      })

                      await refetch()

                      setIsEditable(false)
                    } catch (error) {
                      console.error(error)
                    } finally {
                      setIsSaving(false)
                    }
                  }}
                  isDisabled={!message}
                />

                <Button
                  label="Cancel"
                  size={100}
                  type="default"
                  onClick={() => {
                    setMessage(comment.message)
                    setIsEditable(false)
                  }}
                />
              </Flex>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment key="readonly-mode">
            <div className="mb-3">{message}</div>

            {isAuthor && canAddComments && (
              <div className="flex items-center justify-between">
                <Button
                  label="Edit"
                  glyph="edit"
                  type="minimal"
                  size={100}
                  display="inline-flex"
                  // className="!min-w-[80px]"
                  onClick={() => setIsEditable(true)}
                />

                <DeleteDialog
                  title="Delete Comment?"
                  message="Are you sure you want to delete this comment? This action cannot be undone."
                  onYes={async () => {
                    await deleteQuery.mutateAsync()
                    await refetch()
                  }}
                >
                  <Button
                    hideLabel
                    glyph="delete"
                    type="minimal"
                    color="red"
                    size={100}
                    display="inline-flex"
                    isLoading={deleteQuery.isLoading}
                  />
                </DeleteDialog>
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const CommentStatus = ({ value, statuses = COMMENT_STATUSES, onUpdate, isEditable }) => {
  if (!isEditable) return <SmartStatus inline small status={value} statuses={statuses} />

  return (
    <Dropdown
      position="bottom-start"
      minWidth={200}
      maxWidth={250}
      trigger={
        <div>
          <SmartStatus
            inline
            small
            status={value}
            statuses={statuses}
            after={<Glyph glyph="triangle_down" color="text" size={10} css={styles.glyph} />}
          />
        </div>
      }
    >
      {Object.keys(statuses).map((statusKey) => {
        return (
          <DropdownItem
            key={statusKey}
            label={statuses[statusKey]?.label}
            isActive={value === statusKey}
            css={styles.dropdownItem}
            onClick={() => onUpdate(statusKey)}
            glyphSize={16}
            className="w-full [&>*]:flex-[1_1_auto] [&.is-disabled_.button-label]!opacity-80"
          />
        )
      })}
    </Dropdown>
  )
}

const styles = {
  triggerLabel: {
    fontWeight: 600,
    marginRight: '0.5rem',
    display: 'inline-flex',
    whiteSpace: 'nowrap',
  },

  glyph: {
    marginRight: '0.5rem',
  },

  dropdownItem: {
    minHeight: 32,
  },

  loader: {
    marginLeft: '0.3rem',
  },
}

export const AuthorityFormSubmission = withOverlayError(RootAuthorityFormSubmission)
