import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import compact from 'lodash/compact'
import produce from 'immer'

import { getVersionSlug } from '@behavehealth/utils/functions'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Flex, Chotomate, Card, Page, Grid, HelpTagIframe } from '@behavehealth/components'

import { CLINICAL_ASSESSMENTS_SLUGS } from '@behavehealth/utils/constants'
import { ClinicalAssessmentsDataTable } from '@behavehealth/constructs/ClinicalAssessments/ClinicalAssessmentsDataTable'
import { DATA_FORM_SETTINGS } from '@behavehealth/constructs/DataFormsBuilder/constants'
import { DataFormAddDropdown } from '@behavehealth/constructs/DataFormsBuilder/DataFormAddDropdown'
import { DuplicateButton } from '@behavehealth/components/Buttons/DuplicateButton'

const pageConfig = {
  feature: 'clinical_assessments',
  help: <HelpTagIframe id="clinical_assessments" />,
  marketingID: 'clinical_assessments',
}

type Props = {
  canCreate: boolean
}

const ClinicalAssessments: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const resourceID = match.params?.resource_id

  const { data: client }: any = useGet({
    name: ['client', resourceID],
    url: `/residents/${resourceID}`,
  })

  const tableProps = useDataTable({
    name: ['client', resourceID, 'data_forms'],
    endpoint: `/residents/${resourceID}/data_forms`,
    params: { category: 'clinical_assessment' },
    updateDeleteEndpoint: '/data_forms',
    localStorageKey: 'client_clinical_assessments_v1',
  })

  const dataFormSettings = useDataTable({
    name: ['data_form_settings'],
    endpoint: `/data_form_settings`,
    params: { category: 'clinical_assessment' },
    keepPreviousData: false,
  })

  const to = React.useMemo(
    () => (record: any) => {
      const { version, subcategory, id } = record

      const formattedVersion = !version || version === '1.0' ? '' : `-${getVersionSlug(version)}`

      return {
        pathname: `${match.url}/${CLINICAL_ASSESSMENTS_SLUGS[subcategory]}/${id}${formattedVersion}`,
        parent: match,
      }
    },
    [match.url],
  )

  const formsDropdown = (
    <>
      <DataFormAddDropdown category="clinical_assessment" clientTrack={client?.client_track} />

      {/* <Dropdown
        label="Add New…"
        buttonType="primary"
        glyph="add"
        position="bottom"
        permission="clinical_assessments.create"
      >
        <DropdownItem
          label="Pre-Screen"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/pre-screen/new-20`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Mental Health Pre-Screen"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/mental-health-pre-screen/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Biopsychosocial Assessment"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/biopsychosocial-assessment/new-20`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Suicide Assessment"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/suicide-assessment/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Mental Status Exam"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/mental-status-exam/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="ASAM Criteria"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/asam-criteria/new`,
            parent: match,
          }}
        />
        <DropdownItem
          label="Eating Disorder Biopsychosocial Assessment"
          icon="clinical_assessments"
          link={{
            pathname: `${match.url}/eating-disorder-bps/new`,
            parent: match,
          }}
        />
      </Dropdown> */}
    </>
  )

  return (
    <Page actions={canCreate && formsDropdown} {...pageConfig}>
      <Grid gap="1rem">
        <Chotomate name="clinical_assessments" ready={!tableProps.isLoading} />

        <Card>
          <ClinicalAssessmentsDataTable
            testKey="clinical_assessments_table"
            {...tableProps}
            to={to}
            hiddenColumnIds={['client']}
            headerLinksConfig={[
              {
                type: 'report',
                to: '/reports/clinical-assessments',
              },
            ]}
            batchActionsConfig={[
              {
                type: 'delete',
                permission: 'clinical_assessments.delete',
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
            actionsColumn={{
              width: 260,
              title: 'Actions',
              id: '_actions',
              model: 'id',
              hoverExpand: false,
              disableSort: true,
              formatValue: (row: any) => {
                const { subcategory, variant, data_form_setting }: any = row.data

                let duplicateLink = ''
                let createBpsLink = ''

                // handle duplicate link
                if (data_form_setting?.id) {
                  const recordKey = compact([data_form_setting.subcategory, data_form_setting.variant]).join('_')
                  const formConfig = DATA_FORM_SETTINGS[data_form_setting.category]?.[recordKey]

                  if (formConfig) duplicateLink = `${match.url}/${formConfig.slug}/new-30-${data_form_setting.id}`
                }

                // handle prescreen to bps link
                if (subcategory === 'prescreen') {
                  const bpsSettingRecord = dataFormSettings?.data?.find(
                    (o) => o.subcategory === 'biopsychosocial' && o.variant === data_form_setting?.variant,
                  )

                  if (bpsSettingRecord) createBpsLink = `${match.url}/biopsychosocial-assessment/new-30-${bpsSettingRecord.id}`
                }

                return (
                  <Flex centerY gap="0.5rem">
                    {duplicateLink && (
                      <DuplicateButton
                        label="Duplicate"
                        glyph="add"
                        size={100}
                        permission="clinical_assessments.create"
                        link={{
                          pathname: duplicateLink,
                          data: produce(row.data, (draft: any) => {
                            if (draft?.id) delete draft.id
                            if (draft?.status) delete draft.status
                          }),
                          parent,
                        }}
                      />
                    )}

                    {createBpsLink && (
                      <DuplicateButton
                        label="Create BPS"
                        glyph="add"
                        size={100}
                        permission="clinical_assessments.create"
                        link={{
                          pathname: createBpsLink,
                          data: produce(row.data, (draft: any) => {
                            delete draft.id
                            delete draft.status
                            delete draft.name
                            delete draft.data_form_setting
                            delete draft.schema
                            delete draft.settings

                            draft.subcategory = 'biopsychosocial'
                          }),
                          parent,
                        }}
                      />
                    )}
                  </Flex>
                )
              },
            }}
          />
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(ClinicalAssessments, pageConfig))
