import React from 'react'

import Button from '../../components/Button'
import Card from '../../components/Card'
import Flex from '../../components/Flex'
import Icon from '../../components/Icon'

import PageSection from '../../components/PageSection/PageSection'
import State from '../../components/State'

import SummonOverlay from '../../components/SummonOverlay'

import { PaymentMethodCard } from './PaymentMethodCard'
import AddPaymentMethodOverlay from './AddPaymentMethodOverlay'

import { invalidateQueries, useDelete } from '../../hooks/useNewAPI'

const QuotePaymentMethod = ({ record, paymentMethod, onSuccess }: any) => {
  const details = React.useMemo(() => {
    return {
      company_name: record.billed_to_company_name,
      name: record.billed_to_name,
      email: record.billed_to_email,
      phone_no: record.billed_to_phone_no,
    }
  }, [record])

  const tenant = React.useMemo(() => {
    return record.facility?.subdomain
  }, [record])

  const facilityId = React.useMemo(() => {
    return record.facility?.id
  }, [record])

  const stripeId = React.useMemo(() => {
    return record.billed_to_stripe_customer_id
  }, [record])

  const name = React.useMemo(() => {
    return record.billed_to_company_name || record.facility?.company_name || 'Company Name'
  }, [record])

  const recordId = React.useMemo(() => {
    return record.id
  }, [record])

  const recordType = React.useMemo(() => {
    return record.type
  }, [record])

  const activePaymentMethodTypes = React.useMemo(() => {
    let types = []

    if (record.active_payment_method_types?.card === true) types.push('card')
    if (record.active_payment_method_types?.us_bank_account === true) types.push('us_bank_account')

    return types
  }, [record.active_payment_method_types])

  const actions = (
    <Flex gap={8}>
      <SummonOverlay
        overlay={
          <AddPaymentMethodOverlay
            category="quote"
            tenant={tenant}
            name={name}
            facilityId={facilityId}
            serviceCustomerId={stripeId}
            recordId={recordId}
            recordType={recordType}
            data={details}
            activePaymentMethodTypes={activePaymentMethodTypes}
            onSuccess={() => {
              invalidateQueries(['quotes', record?.id])
              onSuccess?.()
            }}
          />
        }
      >
        <Button label="Add Payment Method" glyph="add" size={200} />
      </SummonOverlay>
    </Flex>
  )

  const isEmpty = !paymentMethod
  const hasConfigurationError = !stripeId

  const { mutate: deletePaymentMethod, isLoading: isDeleting } = useDelete({
    name: ['quotes', record?.id, 'payment_methods'],
    url: `/global_payment_methods/remove`,
    invalidate: ['quotes', record?.id],
    onSuccess: () => {
      onSuccess?.()
    },
  })

  if (hasConfigurationError) {
    return (
      <PageSection>
        <PageSection.Header
          graphic={
            <div className="flex items-center justify-center w-6 h-6">
              <Icon icon="billing" size={20} />
            </div>
          }
        >
          <PageSection.Title title="Payment Methods" />
        </PageSection.Header>

        <PageSection.Content>
          <Card>
            <State
              title="Payment Methods"
              emptyDescription="Please make sure there's a Company Name and an Email set up on the Quote."
              isEmpty={isEmpty}
              minHeight={100}
            />
          </Card>
        </PageSection.Content>
      </PageSection>
    )
  }

  return (
    <PageSection>
      <PageSection.Header
        graphic={
          <div className="flex items-center justify-center w-6 h-6">
            <Icon icon="billing" size={20} />
          </div>
        }
        after={!paymentMethod && actions}
      >
        <PageSection.Title title="Payment Method" />
      </PageSection.Header>

      <PageSection.Content>
        {isEmpty ? (
          <Card>
            <State title="Payment Method" emptyDescription="No payment method added yet" isEmpty={isEmpty} minHeight={100} />
          </Card>
        ) : (
          <PaymentMethodCard paymentMethod={paymentMethod} deletePaymentMethod={deletePaymentMethod} isDeleting={isDeleting} />
        )}
      </PageSection.Content>
    </PageSection>
  )
}

export default QuotePaymentMethod
