import React from 'react'
import compact from 'lodash/compact'

import GlobalTags from '../../../Table/Cells/GlobalTags'
import Table from '../../../Table/Table'
import TableCell from '../../../Table/TableCell'
import TableFilter from '../../../Table/TableFilter'

import withSettings from '../../../../hocs/withSettings'
import { useSettings } from '../../../../hooks/useSettings'

const STATUSES: any = {
  active: 'Active',
  archived: 'Archived',
}

const LEVELS: any = {
  public_template: 'Public',
  behave_template: 'Behave',
  sales_template: 'Sales',
}

const columns = (to, onClick, mainLinkAs, isHQApp) => [
  {
    id: 'name',
    accessor: 'data.name',
    Header: 'Name',
    width: 240,
    Cell: ({ cell, row }: any) => {
      return <TableCell.MainLink as={mainLinkAs} to={to?.(row.original.id)} onClick={() => onClick?.(row.original)} label={cell?.value} />
    },
  },
  {
    width: 110,
    accessor: 'status',
    Header: 'Status',
    Cell: ({ value }: any) => <TableCell value={STATUSES[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: [
      { label: 'active', value: 'active' },
      { label: 'archived', value: 'archived' },
    ],
  },
  {
    width: 240,
    Header: 'Tags',
    accessor: 'global_tags',
    disableFilters: true,
    Cell: ({ row }: any) => (
      <GlobalTags
        // queryKey={queryKey} // TODO
        tags={row.original.global_tags}
        updateId={row.original.id}
        updateKey="internal-template"
        updateEndpoint="/internal_templates"
        invalidate="internal-templates"
      />
    ),
  },
  {
    width: 140,
    accessor: 'level',
    Header: 'Access',
    Cell: ({ value }: any) => <TableCell value={LEVELS[value]} />,
    Filter: TableFilter.Select,
    filter: 'equals',
    filterOptions: compact([
      { label: 'Public', value: 'public_template' },
      { label: 'Sales', value: 'sales_template' },
      isHQApp && { label: 'Behave', value: 'behave_template' },
    ]),
  },
  {
    id: 'author',
    width: 180,
    accessor: 'author',
    Header: 'Added By',
    Cell: ({ row }: any) => <TableCell.Profile avatar={row.original.author?.avatar} name={row.original.author?.name} />,
  },
]

type Props = {
  batchActionsConfig?: Object
  data: Object
  isLoading: boolean
  localStorageKey: string
  onClick?: Function
  title?: string
  titleAfter?: React.ReactNode
  to?: Function
}

const LegalAgreementInternalTemplatesTable = (props: Props) => {
  const { batchActionsConfig, data, isLoading, localStorageKey, onClick, title, titleAfter, to, ...rest } = props

  const { timezone, isHQApp } = useSettings()

  return (
    <Table
      data={data}
      columns={columns(to, onClick, props.mainLinkAs, isHQApp)}
      title={title}
      titleAfter={titleAfter}
      icon="legal_agreement_alt"
      isLoading={isLoading}
      emptyDescription="No legal agreement templates added yet"
      batchActionsConfig={batchActionsConfig}
      localStorageKey={localStorageKey}
      {...rest}
    />
  )
}

LegalAgreementInternalTemplatesTable.defaultProps = {
  title: 'Legal Agreement Templates',
  localStorageKey: 'legal_agreement_internal_templates',
}

export default withSettings(LegalAgreementInternalTemplatesTable)
