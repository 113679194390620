import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { CLINICAL_MEASURES_SLUGS } from '@behavehealth/utils/constants'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'
import withSettings from '@behavehealth/hocs/withSettings'

import { ClinicalMeasuresDataTable } from '@behavehealth/constructs/ClinicalMeasures/ClinicalMeasuresDataTable'
import { Dropdown, DropdownItem, HelpTagIframe, Page, Grid } from '@behavehealth/components'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'clinical_measures',
  help: <HelpTagIframe id="clinical_measurements" />,
  marketingID: 'clinical_measurements',
}

const ClinicalMeasurements = () => {
  const match = useRouteMatch()
  const clientId = match.params?.resource_id

  const { isBehave, formSettings } = useSettings()

  const tableProps = useDataTable({
    name: ['client', clientId, 'data_forms'],
    endpoint: `/residents/${clientId}/data_forms`,
    params: { category: 'clinical_measurement' },
    updateDeleteEndpoint: '/data_forms',
    enabled: !!clientId,
    localStorageKey: 'client_clinical_measurements_v1',
  })

  const actions = (
    <Dropdown label="Add New…" buttonType="primary" glyph="add" position="bottom" permission="clinical_measurements.create">
      {formSettings?.clinical_measures_gad2 && (
        <DropdownItem
          label="GAD-2"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/gad2/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_gad7 && (
        <DropdownItem
          label="GAD-7 Anxiety"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/gad7/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_phq2 && (
        <DropdownItem
          label="PHQ-2"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/phq2/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_phq9 && (
        <DropdownItem
          label="PHQ-9 Depression"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/phq9/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_phq15 && (
        <DropdownItem
          label="PHQ-15 Somatic / Physical Symptoms"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/phq15/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_dast10 && (
        <DropdownItem
          label="DAST-10 Drug Abuse Screening Test"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/dast10/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_mdi && (
        <DropdownItem
          label="MDI Major Depression Inventory"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/mdi/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_bam && (
        <DropdownItem
          label="BAM Brief Addiction Monitor"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/bam/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_bam_iop && (
        <DropdownItem
          label="BAM – IOP"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/bam-iop/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_bam_r && (
        <DropdownItem
          label="BAM – R"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/bam-r/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_audit_c && (
        <DropdownItem
          label="AUDIT-C"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/audit-c/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_pclc && (
        <DropdownItem
          label="PCL-C PTSD CheckList Civilian Version"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/pclc/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_ace_score && (
        <DropdownItem
          label="ACE Score"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/ace-score/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_asrm && (
        <DropdownItem
          label="ASRM Altman Self-Rating Mania Scale"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/asrm/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_asi_lite && (
        <DropdownItem
          label="ASI The Addiction Severity Index (Lite)"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/asi-lite/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_pdss && (
        <DropdownItem
          label="PDSS Panic Disorder Severity Scale (Self Report)"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/pdss/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_barc10 && (
        <DropdownItem
          label="BARC-10 Brief Assessment of Recovery Capital"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/barc10/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_whoqol && (
        <DropdownItem
          label="The World Health Organization Quality of Life (WHOQOL)-BREF"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/whoqol/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_asi5 && (
        <DropdownItem
          label="ASI-5 Addiction Severity Index"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/asi5/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_dow && (
        <DropdownItem
          label="Dimensions of Wellness"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/dow/new`,
            parent: match,
          }}
        />
      )}
      {formSettings?.clinical_measures_tea && (
        <DropdownItem
          label="Treatment Effectiveness Assessment (TEA)"
          icon="clinical_measurements"
          link={{
            pathname: `${match.url}/tea/new`,
            parent: match,
          }}
        />
      )}
      {isBehave && (
        <>
          {formSettings?.clinical_measures_eat26 && (
            <DropdownItem
              label="Eating Attitudes Test (EAT-26)"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/eat26/new`,
                parent: match,
              }}
            />
          )}
          {formSettings?.clinical_measures_edeq6 && (
            <DropdownItem
              label="Eating Disorder Examination Questionnaire (EDE-Q 6.0)"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/edeq6/new`,
                parent: match,
              }}
            />
          )}
          {formSettings?.clinical_measures_scoff && (
            <DropdownItem
              label="SCOFF Questionnaire"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/scoff/new`,
                parent: match,
              }}
            />
          )}

          {/* New forms */}
          {formSettings?.clinical_measures_cls && (
            <DropdownItem
              label="The Casey Life Skills (CLS)"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/cls/new`,
                parent: match,
              }}
            />
          )}
          {/* {formSettings?.clinical_measures_proqol && (
            <DropdownItem
              label="Professional Quality Of Life Scale (PROQOL)"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/proqol/new`,
                parent: match,
              }}
            />
          )} */}
          {formSettings?.clinical_measures_mscsb && (
            <DropdownItem
              label="Mindful Self-Care Scale – Brief"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/mscsb/new`,
                parent: match,
              }}
            />
          )}
          {formSettings?.clinical_measures_phq9_teens && (
            <DropdownItem
              label="PHQ-9 Modified for Teens"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/phq9-teens/new`,
                parent: match,
              }}
            />
          )}
          {formSettings?.clinical_measures_cpss_v_sr && (
            <DropdownItem
              label="The Child PTSD Symptom Scale For DSM-V (CPSS-V SR)"
              icon="clinical_measurements"
              link={{
                pathname: `${match.url}/cpss-v-sr/new`,
                parent: match,
              }}
            />
          )}
        </>
      )}
    </Dropdown>
  )

  return (
    <Page breakpoint="0" actions={actions} {...pageConfig}>
      <Grid gap="1rem" columns="100%">
        <ClinicalMeasuresDataTable
          {...tableProps}
          hiddenColumnIds={['client']}
          to={({ id, subcategory }) => ({
            pathname: `${match.url}/${CLINICAL_MEASURES_SLUGS[subcategory]}/${id}`,
            parent: match,
          })}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'clinical_measurements.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          duplicateColumn={setDuplicateColumn({
            link: (data) => `${match.url}/${CLINICAL_MEASURES_SLUGS[data.subcategory]}/new`,
            parent: match,
            permission: 'clinical_measurements.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withSettings(withMarketing(ClinicalMeasurements, pageConfig)))
