import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { age, titleCase } from '@behavehealth/utils/functions'
import { CONFIG } from '@behavehealth/utils/globals'
import { STAFF_USER_ROLES } from '@behavehealth/utils/constants'
import { useGet, useUpdate } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'

import {
  Accordion,
  AddressSelectorInput,
  Alert,
  Checkbox,
  CheckboxGroup,
  Chotomate,
  DateOfBirthInput,
  DescriptionItem,
  DescriptionList,
  Divider,
  EmailInput,
  Form,
  FormSection,
  GoogleMeetInput,
  Grid,
  HelpTagIframe,
  Input,
  MessengerInput,
  MultiObjectSelector,
  ObjectSelector,
  Option,
  Page,
  Permission,
  PhoneInput,
  Select,
  SkypeInput,
  Status,
  Textarea,
  WhatsAppInput,
  ZoomInput,
} from '@behavehealth/components'

import SexSelect from '@behavehealth/components/Forms/elements/SexSelect'

import useStore from '@behavehealth/modules/store'

const GeneralInfo: React.FC = () => {
  const match: any = useRouteMatch()
  const { tenant, isManagement, isOwner, isBehave, user } = useSettings()

  const { data: employee }: any = useGet({
    name: ['employee', match.params?.resource_id],
    url: `/employees/${match.params?.resource_id}`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', match.params?.resource_id],
    url: `/employees/${match.params?.resource_id}`,
    invalidate: ['employees'],
  })

  const isSelf = user && employee && user?.id === employee?.id && user?.type === employee?.type

  const updateStore = useStore((state) => state.update)

  if (!employee) return null

  // const isBehave = user?.type === 'bh_employee'
  // const isOwner = ['owner', 'administrator'].includes(user?.position)
  // const canEditLoginDetails = isBehave || isOwner

  const isTrialing = tenant?.plan_status === 'trialing'

  return (
    <Page feature="general_info" help={<HelpTagIframe id="employee_general_info" />}>
      <Chotomate ready name="general_info" />

      {/* Personal Details */}
      <Accordion
        isOpen
        title="Personal Details"
        initialModel={employee}
        glyph="personal_details"
        permission="employees.general_info.personal_details.view"
        editPermission="employees.general_info.personal_details.edit"
        activateEditMode
        onSubmit={updateEmployee}
      >
        <Form>
          <FormSection>
            <Input label="Title" model="title" size={8} />

            <Input
              label="First Name"
              model="first_name"
              validations={{
                presence: {
                  message: "Please enter the staff's first name",
                },
              }}
            />

            <Input label="Middle Name" model="middle_name" />

            <Input
              label="Last Name"
              model="last_name"
              validations={{
                presence: {
                  message: "Please enter the staff's last name",
                },
              }}
            />

            <SexSelect label="Sex" model="sex" />

            <DateOfBirthInput label="Date of Birth" model="dob" smartDescription={(date) => `Age: ${age(date)} y/o`} />
          </FormSection>
        </Form>
      </Accordion>

      {/* Employment information */}
      <Accordion
        isOpen
        activateEditMode
        title="Employment Information"
        initialModel={employee}
        glyph="documents"
        permission="employees.general_info.employment_information.view"
        editPermission="employees.general_info.employment_information.edit"
        onSubmit={async (saveData: any) => {
          await updateEmployee(saveData)

          updateStore({ defaultSupervisor: saveData.default_supervisor })
        }}
      >
        <Form>
          {({ isEditable }: any) => (
            <FormSection layout="vertical">
              <Select isEditable={isEditable && (isBehave || isManagement)} label="Role" model="position">
                {CONFIG.active_employee_roles.map(({ text, value }) => {
                  if (value === 'owner' && !isBehave && !isOwner) return null
                  if (value === 'administrator' && !isBehave && !isManagement) return null

                  return <Option key={value} label={text} value={value} />
                })}
              </Select>

              {isBehave && (
                <Select allowEmpty label="User Type" model="behave_user_role">
                  {STAFF_USER_ROLES.map(({ name, value }) => (
                    <Option key={value} label={name} value={value} />
                  ))}
                </Select>
              )}

              <Permission featureFlag="staff_credentials" permission="credentials.view">
                <ObjectSelector
                  label="Primary Staff Credential"
                  type="employee.credentials"
                  model="primary_credential"
                  icon="staff_credentials"
                  dependentValue={employee?.id}
                  isDisabled={!employee?.id}
                  selectTitle={(o) => o.credential}
                  description="This will be displayed on the PDF exports"
                />
              </Permission>

              <MultiObjectSelector
                model="houses"
                label="Assigned Locations"
                blankLabel="Select Locations…"
                type="properties"
                icon="properties"
              />

              <ObjectSelector
                model="default_supervisor"
                label="Default Supervisor"
                type="clinical_supervisors"
                icon="employees"
                selectTitle={(data) => data?.name}
                selectDescription={(data) => titleCase(data?.position)}
              />

              <Divider className="!my-1 !mx-0" />

              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                <Checkbox
                  label="Setup as Supervisor"
                  description="Supervisors are required to sign generated forms like Intake Forms, Clinical Notes, Discharges, etc."
                  model="is_clinical_supervisor"
                />
              </CheckboxGroup>

              <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                <Checkbox
                  label="Setup as Healthcare Provider"
                  description="Providers are used to setup the Fee Schedule for the Provider's Specialty"
                  model="is_healthcare_provider"
                />
              </CheckboxGroup>

              {(isBehave || isOwner) && (
                <CheckboxGroup trueIcon="check" falseIcon="cross" falseStyle="none">
                  <Checkbox
                    label="Accounting Software Access"
                    description="Check this box To give access to your accounting software integration (QuickBooks) to this staff member"
                    model="has_accounting_access"
                  />
                </CheckboxGroup>
              )}
            </FormSection>
          )}
        </Form>
      </Accordion>

      {employee?.is_healthcare_provider && (
        <Accordion
          glyph="insurance_information"
          initialModel={employee}
          title="Healthcare Details"
          permission={true}
          activateEditMode
          onSubmit={updateEmployee}
        >
          <Form>
            <FormSection labelWidth="180px">
              <Select allowEmpty label="Bill Type" model="bill_type">
                <Option label="Individual" value="individual" />
                <Option label="Group" value="group" />
              </Select>

              <Grid gap="1rem" columns="repeat(auto-fit, minmax(220px, 1fr))">
                <Input label="NPI" model="npi" />
                <Input label="Taxonomy" model="taxonomy" />

                <Input label="Specialty License ID" model="specialty_identifier" />
                <Input label="State License ID" model="state_identifier" />

                <Input label="BlueCross ID" model="bluecross_identifier" />
                <Input label="Tricare/Champus ID" model="tricare_identifier" />
              </Grid>
            </FormSection>
          </Form>
        </Accordion>
      )}

      {/* Contact Details */}
      <Accordion
        isOpen
        glyph="contact_details"
        initialModel={employee}
        title="Contact Details"
        activateEditMode={!isTrialing}
        permission="employees.general_info.contact_details.view"
        editPermission="employees.general_info.contact_details.edit"
        onSubmit={updateEmployee}
      >
        <Form>
          <FormSection labelWidth="200px" maxWidth="700px">
            <PhoneInput
              horizontal
              label="Cell Number"
              model="phone_no"
              description="Please note that this is different to the Login Cell Number (see below)"
            />

            <Input horizontal glyph="phone_ext" label="Phone No Extension" model="phone_no_ext" />

            <PhoneInput
              isLocked
              horizontal
              description="Non-Editable"
              label={
                <>
                  <Status color="blue" label="Login" className="!inline-block !py-[3px] !px-0" /> Cell Number
                </>
              }
              model="login_phone_no"
            />

            <Divider className="!m-0" />

            <EmailInput
              horizontal
              label="Email Address"
              model="email"
              description="Please note that this is different to the Login Email (see below)"
              validations={{
                presence: {
                  message: "Please enter the staff's email address",
                },
              }}
            />

            <EmailInput
              isLocked
              horizontal
              description="Non-Editable"
              label={
                <>
                  <Status color="blue" label="Login" css={{ display: 'inline-block', padding: '3px 0' }} /> Email Address
                </>
              }
              model="login_email"
            />

            <Permission newFeatureFlag="zoom_gmeet">
              <Divider className="!m-0" />

              <WhatsAppInput horizontal label="Whatsapp" model="whatsapp" />
              <MessengerInput horizontal label="Facebook ID" model="facebook" />
              <SkypeInput horizontal label="Skype ID" model="skype" />
              <ZoomInput horizontal label="Zoom URL" model="zoom" />
              <GoogleMeetInput horizontal label="Google Meet URL" model="google_meet" />
            </Permission>

            <Grid gap={8}>
              <CheckboxGroup label="Consent to Contact" trueIcon="check" falseIcon="cross" falseStyle="faded">
                <Checkbox
                  testKey="consent_checkbox"
                  label="Staff has consented to be contacted via SMS or Email"
                  model="is_allowed_to_contact"
                />
              </CheckboxGroup>
            </Grid>
          </FormSection>
        </Form>
      </Accordion>

      {/* Sensitive Information */}
      <Accordion
        title="Sensitive Information"
        initialModel={employee}
        glyph="sensitive_information"
        permission="employees.general_info.sensitive_data.view"
        editPermission="employees.general_info.sensitive_data.edit"
        activateEditMode
        onSubmit={updateEmployee}
      >
        <Form>
          <FormSection layout="vertical">
            <Input isNested label="Social Security Number" model="encrypted_data.ssn" />
            {/* <Input isNested label="Employer Identification Number" model="encrypted_data.ein" /> */}
          </FormSection>
        </Form>
      </Accordion>

      {/* Home Address */}
      <Accordion
        title="Home Address"
        initialModel={employee}
        glyph="home_address"
        permission="employees.general_info.home_address.view"
        editPermission="employees.general_info.home_address.edit"
      >
        <FormSection labelWidth="150px">
          <AddressSelectorInput
            actionLabel="Update Home Address"
            permission="employees.general_info.home_address.edit"
            value={employee?.encrypted_data?.address}
            afterAddressSelect={(address) => updateEmployee({ encrypted_data_attributes: { address: address } })}
          />
        </FormSection>
      </Accordion>

      {/* Client Portal Share */}
      <Accordion
        glyph="portal"
        initialModel={employee}
        title="Client Portal Details"
        featureFlag="client_portal"
        newFeatureFlag="client_portal"
        featureFlagV2="client_portal"
        activateEditMode
        onSubmit={updateEmployee}
        permission="employees.general_info.client_portal_details.view"
        editPermission="employees.general_info.client_portal_details.edit"
      >
        <Form>
          <FormSection labelWidth="150px">
            <Textarea useQuickText model="portal_notes" label="Portal Notes" minRows={4} maxWidth="100%" />

            <CheckboxGroup label="Access" trueIcon="check" falseIcon="cross" falseStyle="none">
              <Checkbox label="Share details below with the Client Portal?" model="is_shared_with_portal" />

              <Alert type="neutral" className="!my-8 !mx-0">
                <DescriptionList>
                  <DescriptionItem title="Title" description={employee?.title} />
                  <DescriptionItem title="Name" description={employee?.name} />
                  <DescriptionItem title="Gender" description={titleCase(employee?.sex)} />

                  <DescriptionItem title="Role" description={titleCase(employee?.position)} />
                  <DescriptionItem title="Is a Supervisor?" description={employee?.is_clinical_supervisor ? 'Yes' : 'No'} />

                  <DescriptionItem title="Phone Number" description={employee?.phone_no} />
                  <DescriptionItem title="Email Address" description={employee?.email} />

                  <DescriptionItem title="Notes" description={employee?.portal_notes} />
                </DescriptionList>
              </Alert>
            </CheckboxGroup>
          </FormSection>
        </Form>
      </Accordion>

      {/* Additional Information */}
      <Accordion
        glyph="additional_information"
        initialModel={employee}
        title="Additional Information"
        permission="employees.general_info.other_information.view"
        editPermission="employees.general_info.other_information.edit"
        activateEditMode
        onSubmit={updateEmployee}
      >
        <Form>
          <Textarea useQuickText isNested model="encrypted_data.notes" label="Additional Info" minRows={4} maxWidth="100%" />
        </Form>
      </Accordion>

      <Accordion
        activateEditMode
        initialModel={{ behave_ai_staff_prompt: employee.behave_ai_staff_prompt || '' }}
        icon="behave_ai"
        title="Behave AI Staff Description"
        featureFlagV2="behave_ai"
        permission="employees.general_info.personal_details.view"
        editPermission={isBehave || isManagement || isSelf}
        onSubmit={updateEmployee}
      >
        <Form>
          <FormSection maxWidth="100%">
            <Textarea
              model="behave_ai_staff_prompt"
              label="Behave AI Staff Description"
              tooltip="Only Owners, Administrators and this Staff Member can edit the Behave AI Staff Description"
            />
          </FormSection>
        </Form>
      </Accordion>
    </Page>
  )
}

export default withPageError(GeneralInfo)
