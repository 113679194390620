import React from 'react'

import Overlay from '../../../components/Overlay'

export const ResultOverlay = (props: any) => {
  const { title, onClose, children } = props

  return (
    <Overlay showBackdrop closeOnBackdrop maxWidth={70} onClose={onClose}>
      <Overlay.Header title={title} icon="progress_reviews" />

      <Overlay.Content className="p-4">
        <div className="grid gap-4 ">{children}</div>
      </Overlay.Content>
    </Overlay>
  )
}
