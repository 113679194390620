import React from 'react'

import { useSettings } from '../../hooks/useSettings'
import withSettings from '../../hocs/withSettings'
import { useTreatmentPlanPermissions } from '../../constructs/TreatmentPlans/useTreatmentPlanPermissions'

import NavGroup from '../NavGroup'
import NavItem from '../NavItem'

type Props = {
  id: string
  user?: any
}

const ClientNavItems = ({ id }: Props) => {
  const { isBehave, isSelfServiceBilling, isManagement } = useSettings()

  const basePath = `/clients/${id}`

  const { viewSomeTreatmentPlans } = useTreatmentPlanPermissions()

  return (
    <>
      <NavGroup label="General">
        <NavItem showForCommunity feature="general_info" label="General Info" to={`${basePath}/general-info`} />
        <NavItem
          showForCommunity
          showMarketing
          feature="todos"
          label="To-Do"
          to={`${basePath}/todo`}
          permission="todos.view"
          featureFlagV2="todos"
        />
        <NavItem
          showMarketing
          feature="location_checkin"
          label="Location Tracking"
          to={`${basePath}/location-checkin`}
          permission="location_checkin.view"
          featureFlagV2="location_gps_check_in"
        />
        <NavItem
          showForCommunity
          showMarketing
          label="Calendar"
          to={`${basePath}/calendar`}
          feature="calendar"
          permission="events.view"
          featureFlagV2="calendar"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="files"
          label="Files"
          to={`${basePath}/files`}
          permission="clients.files.view"
          featureFlagV2="files"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="data_exports"
          label="Data Exports"
          to={`${basePath}/data-exports`}
          permission="clients.actions.export"
          featureFlagV2="data_export"
        />
        <NavItem
          feature="activity_timeline"
          label="Activity Timeline"
          to={`${basePath}/activity-timeline`}
          featureFlagV2="activity_timeline"
          permission="activity_timeline.view"
        />
        <NavItem
          glyph="portal"
          label="Client Portal"
          to={`${basePath}/client-portal`}
          featureFlagV2="client_portal"
          permission="clients.actions.invite_to_client_portal"
        />
        <NavItem
          showForCommunity
          feature="form_submissions"
          label="Form Inbox"
          to={`${basePath}/form-submissions`}
          featureFlagV2="form_submissions"
          permission="form_submissions.view"
        />
      </NavGroup>

      <NavGroup label="Client Journey">
        <NavItem feature="referrals" label="Referrals" to={`${basePath}/referrals`} permission={isBehave} />
        <NavItem
          showMarketing
          feature="client_journey"
          label="Client Timeline"
          to={`${basePath}/client-journey`}
          permission="client_journey.view"
          featureFlagV2="client_journey"
        />
        <NavItem showForCommunity feature="treatment_episodes" label="Treatment Episodes" to={`${basePath}/treatment-episodes`} />
        <NavItem
          showMarketing
          feature="service_episodes"
          label="Service Episodes"
          to={`${basePath}/service-episodes`}
          permission="service_episodes.view"
          featureFlagV2="service_episodes"
        />
        <NavItem
          showMarketing
          feature="programs"
          label="Program Assignments"
          to={`${basePath}/programs`}
          permission="programs.view"
          featureFlagV2="programs"
        />
        <NavItem
          showForCommunity
          feature="occupancies"
          label="Bed Assignments"
          to={`${basePath}/occupancies`}
          permission="bed_management.view"
          featureFlagV2="bed_management"
        />
      </NavGroup>

      <NavGroup label="Care Coordination">
        <NavItem
          showMarketing
          showForCommunity
          feature="applications"
          label="Applications"
          to={`${basePath}/applications`}
          permission="applications.view"
          featureFlagV2="admissions"
        />
        <NavItem
          showMarketing
          feature="agreements"
          label="Agreements"
          to={`${basePath}/agreements`}
          permission="agreements.view"
          featureFlagV2="client_agreements"
        />

        <NavItem
          showMarketing
          showForCommunity
          feature="discharges"
          label="Discharge Summaries"
          to={`${basePath}/discharges`}
          permission="discharges.view"
          featureFlagV2="discharges"
        />
      </NavGroup>

      <NavGroup label="Contacts">
        <NavItem
          showMarketing
          feature="contacts"
          label="Contacts"
          to={`${basePath}/contacts`}
          permission="contacts.view"
          featureFlagV2="contacts"
        />
        {isBehave && (
          <NavItem
            feature="form_submissions"
            label="Contacts Form Inbox"
            to={`${basePath}/contacts-form-submissions`}
            // permission="TODO"
            // featureFlagV2="TODO"
          />
        )}
        <NavItem
          showMarketing
          feature="communications"
          label="Communications"
          to={`${basePath}/communications`}
          permission="communications.view"
          featureFlagV2="communication_logs"
        />
        <NavItem
          showForCommunity
          showMarketing
          feature="care_team"
          label="Care Team"
          to={`${basePath}/connections`}
          permission="connections.view"
          featureFlagV2="care_team"
        />
      </NavGroup>

      <NavGroup label="Financials">
        <NavItem
          showMarketing
          feature="financials"
          label="Financials"
          to={`${basePath}/financials`}
          permission="ledger.view"
          featureFlagV2="financials"
        />
        <NavItem
          showMarketing
          feature="accounting"
          label="Invoices"
          to={`${basePath}/invoices`}
          permission={isBehave}
          // permission="invoices.view"
          // featureFlagV2="invoices"
        />
        {/* <NavItem
          isDev
          feature="client_cash"
          
          label="Client Cash"
          to={`${basePath}/client-cash`}
          permission={IS_DEMO}
        /> */}
      </NavGroup>

      <NavGroup label="Insurance">
        <NavItem
          showMarketing
          feature="insurance_policies"
          label="Policies & VOBs"
          to={`${basePath}/insurance`}
          permission="insurance_policies.view"
          featureFlagV2="insurance_policies"
        />

        <NavItem
          showMarketing
          feature="insurance_authorizations"
          label="Authorizations"
          to={`${basePath}/authorizations`}
          permission={isBehave ? true : isSelfServiceBilling ? 'insurance_authorizations.view' : false}
          featureFlagV2="insurance_authorizations"
        />
        <NavItem
          showMarketing
          feature="full_vobs"
          icon="insurance"
          label="VOBs"
          to={`${basePath}/vobs`}
          permission={isBehave ? true : isSelfServiceBilling ? 'full_vobs.view' : false}
          featureFlagV2="full_vobs"
        />
        {/* <NavItem
          color="green"
          icon="financials"
          label="RCM"
          to={`${basePath}/rcm`}
          permission={isBehave ? true : isSelfServiceBilling ? 'rcm.view' : false}
          featureFlagV2={isBehave ? true : isSelfServiceBilling ? 'rcm' : false}
        /> */}
      </NavGroup>

      <NavGroup label="Archived Insurance">
        <NavItem
          showMarketing
          feature="full_vobs"
          icon="insurance"
          label="VOBs"
          to={`${basePath}/archived-vobs`}
          permission={isBehave ? true : isSelfServiceBilling ? 'full_vobs.view' : false}
          featureFlagV2="full_vobs"
        />
      </NavGroup>

      <NavGroup label="Notes">
        <NavItem
          showMarketing
          feature="safety_checks"
          label="Safety Checks"
          to={`${basePath}/safety-checks`}
          permission="safety_checks.view"
          featureFlagV2="safety_checks"
        />
        <NavItem showMarketing feature="rounds" label="Rounds" to={`${basePath}/rounds`} permission="rounds.view" featureFlagV2="rounds" />
        <NavItem
          showMarketing
          feature="shift_notes"
          label="Shift Notes"
          to={`${basePath}/shift-notes`}
          permission="shift_notes.view"
          featureFlagV2="shift_notes"
        />
        <NavItem
          showMarketing
          feature="recovery_coaching"
          label="Recovery Notes"
          to={`${basePath}/recovery-coaching`}
          permission="recovery_coaching.view"
          featureFlagV2="recovery_coaching"
        />
        <NavItem
          showMarketing
          feature="custom_notes"
          label="Custom Notes"
          to={`${basePath}/custom-notes`}
          permission="client_custom_notes.view"
          featureFlagV2="client_custom_notes"
        />
        <NavItem
          showMarketing
          feature="peer_notes"
          label="Peer Notes"
          to={`${basePath}/peer-notes`}
          permission="peer_notes.view"
          featureFlagV2="peer_notes"
        />
        <NavItem
          showMarketing
          feature="daily_notes"
          label="Daily Notes"
          to={`${basePath}/daily-notes`}
          permission="daily_notes.view"
          featureFlagV2="daily_notes"
        />
        <NavItem
          showMarketing
          feature="grievance_notes"
          label="Grievance Notes"
          to={`${basePath}/grievance-notes`}
          permission={isBehave || isManagement}
          featureFlagV2="grievance_notes"
        />
      </NavGroup>

      <NavGroup label="Clinical">
        <NavItem
          showMarketing
          feature="diagnoses"
          label="Diagnoses"
          to={`${basePath}/diagnoses`}
          permission="diagnoses.view"
          featureFlagV2="diagnoses"
        />
        <NavItem
          showMarketing
          feature="clinical_assessments"
          label="Clinical Assessments"
          to={`${basePath}/clinical-assessments`}
          permission="clinical_assessments.view"
          featureFlagV2="clinical_assessments"
        />

        <NavItem
          isBeta
          showMarketing
          feature="treatment_plans"
          label="Treatment Plans"
          to={`${basePath}/treatment-plans`}
          featureFlagV2="treatment_plans"
          permission={viewSomeTreatmentPlans}
        />

        <NavItem
          showMarketing
          feature="recovery_capital_forms"
          label="Recovery Capital Forms"
          to={`${basePath}/recovery-capital-forms`}
          permission={isBehave ? true : 'recovery_capital_forms.view'}
          featureFlagV2={isBehave ? true : 'recovery_capital_forms'}
        />

        <NavItem
          showMarketing
          feature="progress_reviews"
          label="Progress Reviews"
          to={`${basePath}/progress-reviews`}
          permission="progress_reviews.view"
          featureFlagV2="progress_reviews"
        />
        <NavItem
          showMarketing
          feature="clinical_notes"
          label="Clinical Notes"
          to={`${basePath}/clinical-notes`}
          permission="clinical_notes.view"
          featureFlagV2="clinical_notes"
        />
        <NavItem
          showMarketing
          feature="clinical_measurements"
          label="Clinical Measures"
          to={`${basePath}/clinical-measurements`}
          permission="clinical_measurements.view"
          featureFlagV2="clinical_measures"
        />
        <NavItem
          showMarketing
          feature="outcome_measures"
          label="Outcome Measures"
          to={`${basePath}/outcome-measures`}
          permission="outcome_measures.view"
          featureFlagV2="outcome_measures"
        />
      </NavGroup>

      <NavGroup label="Clinical Archived">
        <NavItem
          showMarketing
          feature="treatment_plans"
          label="Treatment Plans"
          to={`${basePath}/archived-treatment-plans`}
          permission="treatment_plans.view"
          featureFlagV2="treatment_plans"
        />
      </NavGroup>

      <NavGroup label="Medical">
        <NavItem feature="allergies" label="Allergies" to={`${basePath}/allergies`} featureFlagV2="allergies" />
        <NavItem
          showMarketing
          feature="test_results"
          label="Test Results"
          to={`${basePath}/test-results`}
          permission="test_results"
          featureFlagV2="test_results"
        />
        <NavItem
          showMarketing
          feature="medications"
          label="Medications"
          to={`${basePath}/medications`}
          permission="medications.view"
          featureFlagV2="medications"
          // notifications={client?.rcopia?.notifications?.total}
          // showNotifications={tenant?.feature_flags?.erx}
        />
        <NavItem
          showMarketing
          feature="medical_assessments"
          label="Medical Assessments"
          to={`${basePath}/medical-assessments`}
          featureFlagV2="medical_assessments"
          permission="medical_assessments.view"
        />
        <NavItem
          showMarketing
          feature="physician_orders"
          label="Physician Orders"
          to={`${basePath}/physician-orders`}
          permission="physician_orders.view"
          featureFlagV2="physician_orders"
        />
        <NavItem
          showMarketing
          feature="nursing"
          label="Nursing"
          to={`${basePath}/nursing`}
          featureFlagV2="nursing"
          permission="nursing.view"
        />
        <NavItem
          feature="progress_notes"
          label="Progress Notes"
          to={`${basePath}/progress-notes`}
          featureFlagV2="progress_notes"
          permission="progress_notes.view"
        />
      </NavGroup>
    </>
  )
}

export default withSettings(ClientNavItems)
