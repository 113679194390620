import React from 'react'
import { useRouteMatch } from 'react-router'

import { amount, usDate, isWebGLAvailable } from '@behavehealth/utils/functions'
import { request } from '@behavehealth/modules/axios'
import { useGet } from '@behavehealth/hooks/useNewAPI'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import useStore from '@behavehealth/modules/store'
import withPermissions from '@behavehealth/hocs/withPermissions'
import withSettings from '@behavehealth/hocs/withSettings'

import {
  Button,
  Card,
  CardContent,
  CardHeader,
  CardLink,
  CardSubtitle,
  CardTitle,
  Chotomate,
  ConfettiFireworks,
  Divider,
  Glyph,
  Grid,
  Header,
  HelpTagIframe,
  Icon,
  Loader,
  MessageBoard,
  P,
  Page,
  Permission,
  Timeline,
  Tooltip,
  Tabs,
} from '@behavehealth/components'

import ButtonGroup from '@behavehealth/components/ButtonGroup'
import Switch from '@behavehealth/components/Forms/Switch'

import BookmarksGrid from '@behavehealth/components/Bookmarks/BookmarksGrid'
import ERXCard from '@behavehealth/components/Cards/ERXCard'
import { create } from '@behavehealth/modules/api/requests'

import { ClearSampleDataBanner } from '../../components/ClearSampleDataBanner'
import { ClientsMessageBoardDataTable } from '@behavehealth/constructs/MessageBoard/ClientsMessageBoardDataTable'
import { MessageBoardDataTable } from '@behavehealth/constructs/MessageBoard/MessageBoardDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { usePermissions } from '@behavehealth/hooks/usePermissions'

type Props = {
  to?: string
  icon: string
  glyph: string
  title: string
  subtitle: string
  titleAfter?: React.ReactNode
}

const DashboardLinkCard = withPermissions((props: Props) => {
  const { to, icon, glyph, title, subtitle, titleAfter } = props

  const { allowed: reportsAllowed } = usePermissions({ featureFlag: 'live_reports', permission: 'reports.view' })

  const isReportsLink = to?.startsWith?.('/reports/')

  return (
    <CardLink
      to={isReportsLink ? (reportsAllowed ? to : null) : to}
      showChevron
      graphic={(icon && <Icon icon={icon} />) || (glyph && <Glyph glyph={glyph} />)}
    >
      <CardTitle title={title} after={titleAfter} />

      {isReportsLink ? reportsAllowed ? <CardSubtitle subtitle={subtitle} /> : null : <CardSubtitle subtitle={subtitle} />}
    </CardLink>
  )
})

const DashboardMessageBoard = () => {
  const match = useRouteMatch()

  const [messageType, setMessageType] = React.useState('ehr')
  const [showExpired, setShowExpired] = React.useState(false)

  return (
    <div className="grid">
      <Header
        icon="care_level_4"
        title="Message Board"
        className="!px-0 !py-3"
        aside={
          <>
            <Switch
              isCompact
              horizontal
              onUpdate={({ value }) => setShowExpired(value)}
              label="Show Expired Messages"
              labelWidth="auto"
              size={100}
              withHover={false}
            />

            <Button
              label="Add Message"
              type="primary"
              glyph="add"
              link={{
                pathname: `${match.url}/messages/new`,
                parent: match,
                data: {
                  message_type: messageType,
                },
              }}
              permission="messages.create"
            />
          </>
        }
      />

      <Card>
        <Tabs activeTab={messageType} onChange={setMessageType}>
          <Tabs.List className="!pl-4">
            <Tabs.Item label="Internal" name="ehr" />
            <Tabs.Item label="Client Internal" name="client_internal" />
            <Tabs.Item label="Client Portal" name="portal" />
          </Tabs.List>
        </Tabs>

        {messageType === 'ehr' ? (
          <EhrMessages showExpired={showExpired} />
        ) : messageType === 'client_internal' ? (
          <ClientInternalMessages showExpired={showExpired} />
        ) : messageType === 'portal' ? (
          <PortalMessages showExpired={showExpired} />
        ) : null}
      </Card>
    </div>
  )
}

const EhrMessages = ({ showExpired }: any) => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['messages'],
    endpoint: `/messages`,
    params: { message_type: 'ehr', show_expired: !!showExpired },
    keepPreviousData: false,
    localStorageKey: `messages_ehr_v1`,
  })

  return (
    <MessageBoardDataTable
      to={(message: any) => `${match.url}/messages/${message.id}`}
      title="Internal Messages"
      asCard={false}
      {...tableProps}
    />
  )
}

const ClientInternalMessages = ({ showExpired }: any) => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['messages'],
    endpoint: `/messages`,
    params: { message_type: 'client_internal', show_expired: !!showExpired },
    keepPreviousData: false,
    localStorageKey: `messages_client_internal_v1`,
  })

  return (
    <ClientsMessageBoardDataTable
      to={(message: any) => `${match.url}/messages/${message.id}`}
      title="Internal Client Messages"
      asCard={false}
      {...tableProps}
    />
  )
}

const PortalMessages = ({ showExpired }: any) => {
  const match = useRouteMatch()

  const tableProps: any = useDataTable({
    name: ['messages'],
    endpoint: `/messages`,
    params: { message_type: 'portal', show_expired: !!showExpired },
    keepPreviousData: false,
    localStorageKey: `messages_portal_v1`,
  })

  return (
    <MessageBoardDataTable
      to={(message: any) => `${match.url}/messages/${message.id}`}
      title="Client Portal Messages"
      asCard={false}
      {...tableProps}
    />
  )
}

const Dashboard = () => {
  const queryParams = { category: 'dashboard' }
  const queryKey = ['stats', queryParams]

  const { data: stats, isLoading: statsLoading }: any = useGet({
    name: queryKey,
    url: `/stats`,
    params: queryParams,
  })

  const user = useStore((state) => state.user)
  const tenant = useStore((state) => state.tenant)

  const canDoConfetti = isWebGLAvailable()
  const hadFirstLogin = localStorage.getItem('bh.had_first_login')
  const [celebrations, setCelebrations] = React.useState(hadFirstLogin ? 0 : 1)

  const showERXFeatures = tenant?.feature_flags?.erx === true

  const isOwner = user?.position === 'owner'
  const isAdministrator = user?.position === 'administrator'
  const isTrialing = tenant?.plan_status === 'trialing'

  const [erxNotifications, setErxNotifications] = React.useState(tenant?.rcopia?.notifications)
  const [erxLoading, setErxLoading] = React.useState(false)

  const syncERXNotifications = async () => {
    setErxLoading(true)

    try {
      const result = await create('/apps/rcopia/sync_notifications')
      setErxNotifications(result.rcopia.notifications)

      setErxLoading(false)
    } catch (error) {
      console.error(error)
      setErxLoading(false)
    }
  }

  React.useEffect(() => {
    localStorage.setItem('bh.had_first_login', true)
  }, [])

  return (
    <Page
      feature="dashboard"
      plan="lite"
      breakpoint="0"
      flex="3rem"
      isLoading={statsLoading}
      help={<HelpTagIframe id="dashboard" />}
      actions={
        <>
          {isTrialing && canDoConfetti && (
            <Button
              color="red"
              type="link"
              label="Celebrate"
              onClick={() => {
                setCelebrations((celebrations) => celebrations + 1)
              }}
            />
          )}

          {isTrialing && <Button glyph="star" color="green" type="primary" label="Ready to Subscribe?" link="/settings/billing/products" />}

          {(isOwner || isAdministrator) && (
            <Button label="View Subscription" type="default" glyph="star" color="orange" link="/settings/billing/products" />
          )}
        </>
      }
    >
      <Grid gap="1rem">
        <Chotomate name="dashboard" ready={!statsLoading} />

        {canDoConfetti && celebrations > 0 && <ConfettiFireworks runs={celebrations} />}

        <Grid gap={12}>
          {/* <H1>
            Hello {user.first_name}! Welcome to {tenant.name}'s account dashboard.
          </H1> */}
          {isTrialing && (
            <P>
              Your trial started on <strong>{usDate(tenant.trial_started_at)}</strong> and it will end on{' '}
              <strong>{usDate(tenant.trial_ended_at)}</strong>.
            </P>
          )}
        </Grid>

        <Permission featureFlagV2="bookmarks">
          <div>
            <Header
              icon="lists"
              title="Bookmarks"
              className="!pt-0 !px-0 !py-3"
              aside={<Button label="Manage Bookmarks" glyph="settings" type="link" link="/settings/bookmarks-manager" />}
              help={<HelpTagIframe id="bookmarks" />}
            />
            <BookmarksGrid />
          </div>
        </Permission>

        {tenant?.has_seed_data && <ClearSampleDataBanner />}

        {isTrialing && <Divider />}

        <Permission showForCommunity permission="messages.view" featureFlagV2="message_board">
          <DashboardMessageBoard />
          {/* <MessageBoard showTabs /> */}
        </Permission>

        <Grid gap="1rem">
          {/* ERX STATS */}
          {showERXFeatures && (
            <div>
              <Header
                glyph="pill_bottle"
                title="eRX Notifications"
                className="!px-0 !py-3"
                aside={
                  <Button
                    size={200}
                    label="Sync Notifications"
                    color="red"
                    glyph="reset"
                    isLoading={erxLoading}
                    onClick={syncERXNotifications}
                  />
                }
              />
              <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
                <ERXCard limpMode startupScreen="message" title="Prescription Refill" value={erxNotifications?.refill || 0} />
                <ERXCard limpMode startupScreen="message" title="Prescription Change" value={erxNotifications?.rxchange || 0} />
                <ERXCard limpMode startupScreen="report" title="Pending Prescription" value={erxNotifications?.rx_pending || 0} />
                <ERXCard
                  limpMode
                  startupScreen="report"
                  title="Prescriptions Need Signing"
                  value={erxNotifications?.rx_need_signing || 0}
                />
              </Grid>
            </div>
          )}

          {/* DEFAULT STATS */}
          <div>
            <Header icon="dashboard" title="Stats" className="!px-0 !py-3" help={<HelpTagIframe id="dashboard_stats" />} />
            <Grid columns="repeat(auto-fit, minmax(310px, 1fr))" gap={8}>
              <DashboardLinkCard
                showForCommunity
                icon="intake_forms"
                to="/admissions/applicants"
                title={`${stats?.leads} Applicants`}
                subtitle="View Applicants"
                featureFlagV2="admissions"
              />

              <DashboardLinkCard
                showForCommunity
                glyph="tick_circle"
                to="/admissions/intake"
                title={`${stats?.intakes} Accepted`}
                subtitle="View Accepted"
                featureFlagV2="admissions"
              />

              <DashboardLinkCard
                showForCommunity
                glyph="circle_error"
                to="/admissions/declined"
                title={`${stats?.declined_admissions} Declined`}
                subtitle="View Declined"
                featureFlagV2="admissions"
              />

              <DashboardLinkCard
                showForCommunity
                icon="clients"
                to="/clients"
                title={`${stats?.residents} Clients`}
                subtitle="View Clients"
                featureFlagV2="clients"
              />

              <DashboardLinkCard
                showForCommunity
                icon="alumni_relations"
                to="/alumni"
                title={`${stats?.alumni} Past Client`}
                subtitle="View Past Client"
                featureFlagV2="alumni"
              />

              <DashboardLinkCard
                showForCommunity
                icon="employees"
                to={`/employees/active`}
                title={`${stats?.active_employees} Active Staff`}
                subtitle="View Staff"
                featureFlagV2="staff"
              />

              <DashboardLinkCard
                showForCommunity
                icon="employees"
                to={`/employees/archived`}
                title={`${stats?.archived_employees} Archived Staff`}
                subtitle="View Staff"
                featureFlagV2="staff"
              />

              <DashboardLinkCard
                showForCommunity
                icon="properties"
                to="/locations"
                title={`${stats?.properties} Locations`}
                subtitle="View Locations"
                permission="properties.view"
                featureFlagV2="properties"
              />

              <DashboardLinkCard
                showForCommunity
                icon="bed_management"
                to="/bed-management"
                title={`${stats?.beds - stats?.occupied_beds - stats?.reserved_beds} Empty Today / ${stats?.beds} Beds`}
                subtitle="Manage Beds"
                permission="properties.view"
                featureFlagV2="bed_management"
              />

              <DashboardLinkCard
                showForCommunity
                icon="bed_management"
                to="/bed-management"
                title={`${stats?.occupied_beds} Occupied Today / ${stats?.beds} Beds`}
                subtitle="Manage Beds"
                permission="properties.view"
                featureFlagV2="bed_management"
              />

              <DashboardLinkCard
                showForCommunity
                icon="bed_management"
                to="/bed-management"
                title={`${stats?.reserved_beds} Reserved Today / ${stats?.beds} Beds`}
                subtitle="Manage Beds"
                permission="properties.view"
                featureFlagV2="bed_management"
              />

              <DashboardLinkCard
                showForCommunity
                icon="organizations"
                to="/community/organizations/orgs"
                title={`${stats?.organizations} Organizations`}
                subtitle="View Organizations"
                permission="organizations.view"
                featureFlagV2="organizations"
              />

              <DashboardLinkCard
                showForCommunity
                icon="legal_agreement_alt"
                to="/reports/agreements"
                title={`${stats?.agreements} Agreements`}
                subtitle="View Agreements"
                permission="agreements.view"
                featureFlagV2="client_agreements"
              />

              <DashboardLinkCard
                showForCommunity
                icon="custom_notes"
                to="/reports/custom-notes"
                title={`${stats?.custom_notes} Custom Notes`}
                subtitle="View Custom Notes"
                permission="client_custom_notes.view"
                featureFlagV2="client_custom_notes"
              />

              <DashboardLinkCard
                showForCommunity
                icon="web_form"
                to="/form-submissions/inbox"
                title={`${stats?.form_submissions} Form Submissions`}
                subtitle="View Form Submissions"
                permission="form_submissions.view"
                featureFlagV2="form_submissions"
              />
            </Grid>
          </div>

          {/* CLINICAL STATS */}
          <div>
            <Header icon="treatment_plans" title="Clinical Stats" className="!px-0 !py-3" help={<HelpTagIframe id="clinical_stats" />} />
            <Grid columns="repeat(auto-fit, minmax(310px, 1fr))" gap={8}>
              <DashboardLinkCard
                icon="diagnosis"
                to="/reports/diagnoses"
                title={`${stats?.clinical?.diagnoses} Diagnoses`}
                subtitle="View Diagnosis Reports"
                featureFlagV2="diagnoses"
              />

              <DashboardLinkCard
                icon="clinical_assessments"
                to="/reports/clinical-measures"
                title={`${stats?.clinical?.clinical_assessments} Clinical Assessments`}
                subtitle="View Clinical Assessment Reports"
                featureFlagV2="clinical_assessments"
              />

              <DashboardLinkCard
                icon="treatment_plans"
                to="/reports/treatment-plans"
                title={`${stats?.clinical?.treatment_plans} Treatment Plans`}
                subtitle="View Treatment Plan Reports"
                featureFlagV2="treatment_plans"
              />

              <DashboardLinkCard
                icon="progress_reviews"
                to="/reports/progress-reviews"
                title={`${stats?.clinical?.progress_reviews} Progress Reviews`}
                subtitle="View Progress Review Reports"
                featureFlagV2="progress_reviews"
              />

              <DashboardLinkCard
                icon="clinical_notes"
                to="/reports/clinical-notes"
                title={`${stats?.clinical?.clinical_notes} Clinical Notes`}
                subtitle="View Clinical Note Reports"
                featureFlagV2="clinical_notes"
              />

              <DashboardLinkCard
                icon="clinical_measurements"
                to="/reports/clinical-measures"
                title={`${stats?.clinical?.clinical_measures} Clinical Measures`}
                subtitle="View Clinical Measure Reports"
                featureFlagV2="clinical_measures"
              />

              <DashboardLinkCard
                icon="outcome_measures"
                to="/reports/clinical-measures"
                title={`${stats?.clinical?.outcome_measures} Outcome Measures`}
                subtitle="View Outcome Measure Reports"
                featureFlagV2="outcome_measures"
              />
            </Grid>
          </div>

          {/* MEDICAL STATS */}
          <div>
            <Header icon="test_results" title="Medical Stats" className="!px-0 !py-3" help={<HelpTagIframe id="medical_stats" />} />
            <Grid columns="repeat(auto-fit, minmax(310px, 1fr))" gap={8}>
              <DashboardLinkCard
                showForCommunity
                icon="treatment_data"
                to="/reports/allergies"
                title={`${stats?.medical?.allergies} Allergies`}
                subtitle="View Allergy Reports"
                featureFlagV2="allergies"
              />
              <DashboardLinkCard
                showForCommunity
                icon="test_results"
                to="/reports/breathalyzer-tests"
                title={`${stats?.medical?.test_results} Test Results`}
                subtitle="View Test Result Reports"
                featureFlagV2="test_results"
              />
              <DashboardLinkCard
                showForCommunity
                icon="medications"
                to="/reports/medications"
                title={`${stats?.medical?.medications} Meds`}
                subtitle="View Med Reports"
                featureFlagV2="medications"
              />
              <DashboardLinkCard
                icon="medical_assessments"
                to="/reports/medical-assessments"
                title={`${stats?.medical?.medical_assessments} Medical Assessments`}
                subtitle="View Medical Assessment Reports"
                featureFlagV2="medical_assessments"
              />
              <DashboardLinkCard
                icon="physician_orders"
                to="/reports/physicians-orders"
                title={`${stats?.medical?.physician_orders} Physician Orders`}
                subtitle="View Physician Order Reports"
                featureFlagV2="physician_orders"
              />
              <DashboardLinkCard
                icon="nursing"
                to="/reports/nursing"
                title={`${stats?.medical?.nursing} Nursing`}
                subtitle="View Nursing Reports"
                featureFlagV2="nursing"
              />
              <DashboardLinkCard
                icon="progress_notes"
                to="/reports/progress-notes"
                title={`${stats?.medical?.nursing} Progress Notes`}
                subtitle="View Progress Note Reports"
                featureFlagV2="progress_notes"
              />
            </Grid>
          </div>

          {/* WEEKLY STATS */}
          <div>
            <Header icon="dashboard" title="Weekly Stats" className="!px-0 !py-3" help={<HelpTagIframe id="dashboard_weekly_stats" />} />
            <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
              <DashboardLinkCard
                icon="housing_notes"
                to="/reports/shift-notes"
                title={`${stats?.weekly_shift_notes} Shift Notes`}
                subtitle="View Shift Notes"
                titleAfter={<Tooltip glyph="question" position="top" content="Number of Shift Notes created in the last 7 days" />}
                permission="shift_notes.view"
                featureFlagV2="shift_notes"
              />

              <DashboardLinkCard
                icon="communications"
                to="/reports/clients-communications"
                title={`${stats?.weekly_communications} Communications`}
                subtitle="View Communications"
                titleAfter={<Tooltip glyph="question" position="top" content="Number of Communications sent within the last 7 days" />}
                permission="communications.view"
                featureFlagV2="communication_logs"
              />

              <DashboardLinkCard
                icon="recovery_navigation"
                to="/reports/location-checkins"
                title={`${stats?.weekly_location_checks} Location Check-Ins`}
                subtitle="View Location Check-Ins"
                titleAfter={<Tooltip glyph="question" position="top" content="Number of Location Check-Ins within the last 7 days" />}
                permission="location_checkin.view"
                featureFlagV2="location_gps_check_in"
              />

              <DashboardLinkCard
                showForCommunity
                icon="test_results"
                to="/reports/ua-tests"
                title={`${stats?.weekly_ua_tests} UA Tests`}
                subtitle="View UA Tests"
                titleAfter={<Tooltip glyph="question" position="top" content="Number of UA Tests collected within the last 7 days" />}
                permission="test_results.vital_signs.view"
                featureFlagV2="ua_tests"
              />

              <DashboardLinkCard
                showForCommunity
                icon="test_results"
                to="/reports/breathalyzer-tests"
                title={`${stats?.weekly_breathalyzer_tests} Breathalyzer Tests`}
                subtitle="View Breathalyzer Tests"
                titleAfter={
                  <Tooltip glyph="question" position="top" content="Number of Breathalyzer Tests collected within the last 7 days" />
                }
                permission="test_results.breathalyzer_tests.view"
                featureFlagV2="breathalyzer_tests"
              />

              <DashboardLinkCard
                icon="test_results"
                to="/reports/vital-signs"
                title={`${stats?.weekly_vital_signs} Vital Signs`}
                subtitle="View Vital Signs"
                titleAfter={<Tooltip glyph="question" position="top" content="Number of Vital Signs collected within the last 7 days" />}
                permission="test_results.vital_signs.view"
                featureFlagV2="vital_signs"
              />

              <DashboardLinkCard
                icon="test_results"
                to="/reports/communicable-disease-tests"
                title={`${stats?.weekly_communicable_disease_tests} Disease Tests`}
                subtitle="View Disease Tests"
                titleAfter={
                  <Tooltip
                    glyph="question"
                    position="top"
                    content="Number of Communicable Disease Tests collected within the last 7 days"
                  />
                }
                permission="test_results.communicable_disease_tests.view"
                featureFlagV2="communicable_disease_tests"
              />

              <DashboardLinkCard
                showForCommunity
                icon="legal_agreement_alt"
                to="/reports/agreements"
                title={`${stats?.weekly_agreements} Agreements`}
                subtitle="View Agreements"
                permission="agreements.view"
                featureFlagV2="client_agreements"
              />

              <DashboardLinkCard
                showForCommunity
                icon="custom_notes"
                to="/reports/custom-notes"
                title={`${stats?.weekly_custom_notes} Custom Notes`}
                subtitle="View Custom Notes"
                permission="client_custom_notes.view"
                featureFlagV2="client_custom_notes"
              />

              <DashboardLinkCard
                showForCommunity
                icon="web_form"
                to="/form-submissions/inbox"
                title={`${stats?.weekly_form_submissions} Form Submissions`}
                subtitle="View Form Submissions"
                permission="form_submissions.view"
                featureFlagV2="form_submissions"
              />
            </Grid>
          </div>

          {stats?.financial_stats_last_30_days && (
            <Permission permission="ledger.view" featureFlagV2="financials">
              <FinancialStats initialData={stats.financial_stats_last_30_days} />
            </Permission>
          )}

          {/* ACTIVITY TIMELINE */}
          <Permission featureFlagV2="activity_timeline" permission="activity_timeline.view">
            <div>
              <Header icon="lists" title="Activity Timeline" className="!px-0 !py-3" help={<HelpTagIframe id="activity_timeline" />} />

              <Card>
                <CardHeader graphic={<Icon icon="lists" />}>
                  <CardTitle title="Activities" />
                </CardHeader>

                <CardContent>
                  <Timeline url="activities" />
                </CardContent>
              </Card>
            </div>
          </Permission>
        </Grid>
      </Grid>
    </Page>
  )
}

const PERIODS = {
  '30': { value: '30', label: '30d', description: '30 days' },
  '60': { value: '60', label: '60d', description: '60 days' },
  '90': { value: '90', label: '90d', description: '90 days' },
  all: { value: 'all', label: 'All Time', description: 'all time' },
}

const FinancialStats = ({ initialData }: any) => {
  const { isBehave, user } = useSettings()

  const isManagement = ['owner', 'administrator'].includes(user?.position)

  const [period, setPeriod] = React.useState('30')

  const queryParams = { category: 'financial', last: period }
  const queryKey = ['stats', queryParams]

  const { data, isLoading, isRefetching }: any = useGet({
    name: queryKey,
    url: `/stats`,
    params: queryParams,
    options: { enabled: period !== '30' },
  })

  const stats = React.useMemo(() => {
    if (period === '30' || !data) return initialData

    return data
  }, [data, period])

  const description = period === 'all' ? 'all time' : `last ${PERIODS?.[period]?.description}`
  const futureDescription = period === 'all' ? 'all time' : `within next ${PERIODS?.[period]?.description}`

  const tooltipDescription = period === 'all' ? 'from all time' : `from the last ${PERIODS?.[period]?.description}`
  const tooltipFutureDescription = period === 'all' ? 'from all time' : `for the next ${PERIODS?.[period]?.description}`

  return (
    <div>
      <Header
        icon="financials"
        title="Financials"
        titleAside={(isLoading || isRefetching) && <Loader size={18} />}
        className="!px-0 !py-3"
        help={<HelpTagIframe id="dashboard_financials" />}
        aside={
          (isBehave || isManagement) && (
            <ButtonGroup>
              {Object.values(PERIODS).map(({ value, label }) => (
                <Button
                  key={value}
                  label={label}
                  size={200}
                  color="gray"
                  onClick={() => {
                    setPeriod(value)
                  }}
                  type={period === value ? 'primary' : 'default'}
                />
              ))}
            </ButtonGroup>
          )
        }
      />

      <Grid columns="repeat(auto-fit, minmax(280px, 1fr))" gap={8}>
        <DashboardLinkCard
          icon="financials"
          title="Charges Entered"
          to="/reports/charges"
          subtitle={
            <>
              <b>${amount(stats?.charges)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Charges ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Future Planned Charges"
          to="/reports/charges"
          subtitle={
            <>
              <b>${amount(stats?.future_charges)}</b> {futureDescription}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Future Planned Charges ${tooltipFutureDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Payments Collected"
          to="/reports/payments"
          subtitle={
            <>
              <b>${amount(stats?.payments)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Payments ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Payments Processing"
          to="/reports/payments"
          subtitle={
            <>
              <b>${amount(stats?.processing_payments)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Payments Processing ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Failed Payments"
          to="/reports/payments"
          subtitle={
            <>
              <b>${amount(stats?.failed_payments)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Failed Payments ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Refunds Issued"
          to="/reports/refunds"
          subtitle={
            <>
              <b>${amount(stats?.refunds)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Refunds ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Write-Offs Issued"
          to="/reports/write-offs"
          subtitle={
            <>
              <b>${amount(stats?.write_offs)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Write-Offs ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />

        <DashboardLinkCard
          icon="financials"
          title="Credits Issued"
          to="/reports/credits"
          subtitle={
            <>
              <b>${amount(stats?.credits)}</b> {description}
            </>
          }
          titleAfter={<Tooltip glyph="question" position="top" content={`SUM of the Ledger Credits ${tooltipDescription}`} />}
          permission="ledger.view"
          featureFlagV2="financials"
        />
      </Grid>
    </div>
  )
}

export default withPageError(withSettings(Dashboard))
