// Organization
export const ORGANIZATION_DETAILS = {
  organization_name: {
    id: 'organization_name',
    name: 'Facility Name',
    model: 'organization_name',
    tooltip: 'The official registered name of the facility',
  },
  about: {
    id: 'about',
    name: 'About',
    model: 'organization.about',
    tooltip: 'A general overview of the facility and its services',
  },
  mission_statement: {
    id: 'mission_statement',
    name: 'Mission Statement',
    model: 'organization.mission_statement',
    tooltip: "The facility's official mission statement",
  },
  year_founded: {
    id: 'year_founded',
    name: 'Year Founded',
    model: 'organization.year_founded',
    tooltip: 'The year the facility began operations',
    filterType: 'number',
  },
  facility_type: {
    id: 'facility_type',
    name: 'Facility Type',
    model: 'organization.facility_type',
    tooltip: 'The main categories of treatment and services provided',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      substance_use: 'Substance Use',
      mental_health: 'Mental Health',
      health_care_centers: 'Health Care Centers',
      buprenorphine_practitioners: 'Buprenorphine Practitioners',
      opioid_treatment_programs: 'Opioid Treatment Programs',
      treatment_center: 'Treatment Center',
      recovery_housing: 'Recovery Housing',
      transitional_housing: 'Transitional Housing',
      recovery_community: 'Recovery Community',
    },
  },
  service_availability: {
    id: 'service_availability',
    name: 'Service Availability',
    model: 'organization.service_availability',
    tooltip: 'The ways services can be accessed',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      available_in_person: 'Available in-person',
      available_online: 'Available online',
      virtual_services: 'Virtual Services',
    },
  },
  required_activities: {
    id: 'required_activities',
    name: 'Required Activities',
    model: 'organization.required_activities',
    tooltip: 'Activities that residents must participate in',
    inputType: 'checkbox',
    options: {
      mandatory_drug_testing: 'Mandatory drug testing',
      mandatory_meetings: 'Mandatory meetings',
      house_meetings: 'House meetings',
      chores: 'Chores',
    },
  },
  description: {
    id: 'description',
    name: 'Description',
    model: 'organization.description',
    tooltip: 'A detailed description of how the facility operates',
  },
  operating_hours: {
    id: 'operating_hours',
    name: 'Operating Hours',
    model: 'organization.operating_hours',
    tooltip: "The facility's standard hours of operation",
  },
  emergency_contact_hours: {
    id: 'emergency_contact_hours',
    name: 'Emergency Contact Hours',
    model: 'organization.emergency_contact_hours',
    tooltip: 'Hours when emergency contacts are available',
  },
  primary_email: {
    id: 'primary_email',
    name: 'Primary Email',
    model: 'organization.primary_email',
    tooltip: '',
    inputType: 'email',
  },
  primary_phone_no: {
    id: 'primary_phone_no',
    name: 'Primary Phone No',
    model: 'organization.primary_phone_no',
    tooltip: '',
    inputType: 'phone',
  },
  primary_phone_no_ext: {
    id: 'primary_phone_no_ext',
    name: 'Primary Phone No Ext',
    model: 'organization.primary_phone_no_ext',
    tooltip: '',
  },
  primary_fax_no: {
    id: 'primary_fax_no',
    name: 'Primary Fax No',
    model: 'organization.primary_fax_no',
    tooltip: '',
    inputType: 'phone',
  },
  website: {
    id: 'website',
    name: 'Website',
    model: 'organization.website',
    tooltip: '',
    inputType: 'website',
  },
  admissions_contact_name: {
    id: 'admissions_contact_name',
    name: 'Admissions Contact Name',
    model: 'organization.admissions_contact_name',
    tooltip: '',
  },
  admissions_email: {
    id: 'admissions_email',
    name: 'Admissions Email',
    model: 'organization.admissions_email',
    tooltip: '',
    inputType: 'email',
  },
  admissions_phone_no: {
    id: 'admissions_phone_no',
    name: 'Admissions Phone No',
    model: 'organization.admissions_phone_no',
    tooltip: '',
    inputType: 'phone',
  },
  marketing_contact_name: {
    id: 'marketing_contact_name',
    name: 'Marketing Contact Name',
    model: 'organization.marketing_contact_name',
    tooltip: '',
  },
  marketing_email: {
    id: 'marketing_email',
    name: 'Marketing Email',
    model: 'organization.marketing_email',
    tooltip: '',
    inputType: 'email',
  },
  marketing_phone_no: {
    id: 'marketing_phone_no',
    name: 'Marketing Phone No',
    model: 'organization.marketing_phone_no',
    tooltip: '',
    inputType: 'phone',
  },
  emergency_contact_name: {
    id: 'emergency_contact_name',
    name: 'Emergency Contact Name',
    model: 'organization.emergency_contact_name',
    tooltip: '',
  },
  emergency_email: {
    id: 'emergency_email',
    name: 'Emergency Email',
    model: 'organization.emergency_email',
    tooltip: '',
    inputType: 'email',
  },
  emergency_phone_no: {
    id: 'emergency_phone_no',
    name: 'Emergency Phone No',
    model: 'organization.emergency_phone_no',
    tooltip: '',
    inputType: 'phone',
  },
  main_address: {
    id: 'main_address',
    name: 'Main Address',
    model: 'organization.main_address',
    tooltip: '',
    inputType: 'address',
  },
  mailing_address: {
    id: 'mailing_address',
    name: 'Mailing Address',
    model: 'organization.mailing_address',
    tooltip: '',
    inputType: 'address',
  },
  updated_at: {
    id: 'updated_at',
    name: 'Updated At',
    model: 'organization.updated_at',
    tooltip: '',
    inputType: 'date_time',
  },
}

// Location
export const LOCATION_DETAILS = {
  name: {
    id: 'name',
    name: 'Name',
    model: 'location.name',
  },
  email: {
    id: 'email',
    name: 'Email',
    model: 'location.email',
  },
  phone_no: {
    id: 'phone_no',
    name: 'Phone No',
    model: 'location.phone_no',
    inputType: 'phone',
  },
  hours_of_operation: {
    id: 'hours_of_operation',
    name: 'Hours Of Operation',
    model: 'location.hours_of_operation',
  },
  address: {
    id: 'address',
    name: 'Address',
    model: 'location.address',
    inputType: 'address',
  },
  admissions_contact_name: {
    id: 'admissions_contact_name',
    name: 'Admissions Contact Name',
    model: 'location.admissions_contact_name',
  },
  admissions_contact_phone_no: {
    id: 'admissions_contact_phone_no',
    name: 'Admissions Contact Phone No',
    model: 'location.admissions_contact_phone_no',
    inputType: 'phone',
  },
  admissions_contact_email: {
    id: 'admissions_contact_email',
    name: 'Admissions Contact Email',
    model: 'location.admissions_contact_email',
    inputType: 'email',
  },
  languages_served: {
    id: 'languages_served',
    name: 'Languages Served',
    model: 'location.languages_served',
    tooltip: 'Languages in which services are provided',
    filterType: 'checkbox',
    inputType: 'checkbox',
    options: {
      arabic: 'Arabic',
      asl: 'ASL',
      bengali: 'Bengali',
      cantonese: 'Cantonese',
      chinese: 'Chinese',
      croatian: 'Croatian',
      english: 'English',
      farsi: 'Farsi',
      french: 'French',
      german: 'German',
      greek: 'Greek',
      hebrew: 'Hebrew',
      hindi: 'Hindi',
      hungarian: 'Hungarian',
      italian: 'Italian',
      korean: 'Korean',
      lithuanian: 'Lithuanian',
      mandarin: 'Mandarin',
      polish: 'Polish',
      portuguese: 'Portuguese',
      romanian: 'Romanian',
      russian: 'Russian',
      serbian: 'Serbian',
      spanish: 'Spanish',
      tagalog: 'Tagalog',
      turkish: 'Turkish',
      urdu: 'Urdu',
      vietnamese: 'Vietnamese',
      yiddish: 'Yiddish',
    },
  },
}

export const LOCATION_GALLERY = {
  gallery: {
    id: 'gallery',
    name: 'Gallery',
    model: 'location.gallery',
  },
}

export const LOCATION_CERTIFICATIONS = {
  narr_level_of_support: {
    id: 'narr_level_of_support',
    name: 'NARR Level of Support',
    tooltip: "Facility's certification level as per the National Alliance for Recovery Residences (NARR) standards.",
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      level_1: 'Level 1',
      level_2: 'Level 2',
      level_3: 'Level 3',
      level_4: 'Level 4',
    },
    model: 'location.narr_level_of_support',
  },
  asam_level_of_care: {
    id: 'asam_level_of_care',
    name: 'ASAM Level of Care',
    tooltip: 'Levels of care provided by the facility, based on the American Society of Addiction Medicine (ASAM) criteria.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      medically_managed_intensive_inpatient_detoxification: 'Level 4-D Medically Managed Intensive Inpatient Detoxification',
      medically_managed_intensive_inpatient_services: 'Level 4 Medically Managed Intensive Inpatient Services',
      medically_monitored_inpatient_detoxification: 'Level 3.7-D Medically Monitored Inpatient Detoxification',
      medically_monitored_high_intensity_inpatient_services: 'Level 3.7 Medically Monitored High-Intensity Inpatient Services',
      clinically_managed_medium_intensity_residential_services: 'Level 3.5 Clinically Managed Medium-Intensity Residential Services',
      clinically_managed_population_specific_high_intensity_residential_services:
        'Level 3.3 Clinically Managed Population-Specific High-Intensity Residential Services',
      clinically_managed_residential_detoxification: 'Level 3.2-D Clinically Managed Residential Detoxification',
      clinically_managed_low_intensity_residential_services: 'Level 3.1 Clinically Managed Low-Intensity Residential Services',
      residential_inpatient_detoxification: 'Level 3-D Residential/Inpatient Detoxification',
      recovery_residence: 'Level RR Recovery Residence',
      partial_hospitalization_services: 'Level 2.5 Partial Hospitalization Services',
      intensive_outpatient_services: 'Level 2.1 Intensive Outpatient Services',
      ambulatory_detoxification_with_extended_on_site_monitoring: 'Level 2-D Ambulatory Detoxification With Extended On-Site Monitoring',
      ambulatory_detoxification_without_extended_on_site_monitoring:
        'Level 1-D Ambulatory Detoxification Without Extended On-Site Monitoring',
      outpatient_services: 'Level 1 Outpatient Services',
      early_intervention: 'Level 0.5 Early Intervention',
    },
    model: 'location.asam_level_of_care',
  },
  new_asam_narr_levels: {
    id: 'new_asam_narr_levels',
    name: 'New ASAM/NARR Levels',
    tooltip: "Facility's classification under the updated ASAM/NARR levels.",
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      type_p: 'Type P',
      type_m: 'Type M',
      type_s: 'Type S',
      type_c: 'Type C',
    },
    model: 'location.new_asam_narr_levels',
  },
  locus_level_of_care: {
    id: 'locus_level_of_care',
    name: 'LOCUS Level of Care',
    tooltip: 'Level of Care Utilization System (LOCUS) level applicable to the facility.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      level_0_basic_services: 'Level 0 - Basic Services',
      level_1_recovery_maintenance_and_health_management: 'Level 1 - Recovery Maintenance and Health Management',
      level_2_low_intensity_community_based_services: 'Level 2 - Low Intensity Community-Based Services',
      level_3_high_intensity_community_based_services: 'Level 3 - High Intensity Community-Based Services',
      level_4_medically_monitored_non_residential_services: 'Level 4 - Medically Monitored Non-Residential Services',
      level_5_medically_monitored_residential_services: 'Level 5 - Medically Monitored Residential Services',
      level_6_medically_managed_residential_services: 'Level 6 - Medically Managed Residential Services',
    },
    model: 'location.locus_level_of_care',
  },
  calocus_level_of_care: {
    id: 'calocus_level_of_care',
    name: 'CALOCUS Level of Care',
    tooltip: 'Child and Adolescent Level of Care Utilization System (CALOCUS) level applicable to the facility.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      level_0_basic_services: 'Level 0 - Basic Services',
      level_1_recovery_maintenance_and_health_management: 'Level 1 - Recovery Maintenance and Health Management',
      level_2_outpatient_services: 'Level 2 - Outpatient Services',
      level_3_intensive_outpatient_services: 'Level 3 - Intensive Outpatient Services',
      level_4_intensive_integrated_services_without_24_hour_psychiatric_monitoring:
        'Level 4 - Intensive Integrated Services Without 24-Hour Psychiatric Monitoring',
      level_5_non_secure: 'Level 5 - Non-Secure',
      '24_hour_services_with_psychiatric_monitoring': '24-Hour Services With Psychiatric Monitoring',
      level_6_secure: 'Level 6 - Secure',
      '24_hour_services_with_psychiatric_management': '24-Hour Services With Psychiatric Management',
    },
    model: 'location.calocus_level_of_care',
  },
  state_affiliate_certification: {
    id: 'state_affiliate_certification',
    name: 'State Affiliate Certification',
    tooltip: "Specifies the facility's certification status with the relevant state affiliate.",
    model: 'location.state_affiliate_certification',
  },
  certification_status: {
    id: 'certification_status',
    name: 'Certification Status',
    tooltip: "Current status of the facility's certification.",
    options: {
      active: 'Active',
      pending: 'Pending',
      provisional: 'Provisional',
      conditional: 'Conditional',
      inactive: 'Inactive',
      suspended: 'Suspended',
      revoked: 'Revoked',
      expired: 'Expired',
    },
    model: 'location.certification_status',
  },
  licenses_accreditations_certifications: {
    id: 'licenses_accreditations_certifications',
    name: 'Licenses, Accreditations, & Certifications',
    tooltip: 'The licenses, accreditations, and certifications held by the facility.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      carf: 'CARF',
      joint_commission: 'Joint Commission',
      narr: 'NARR',
      narr_state_affiliate: 'NARR State Affiliate',
      bhso: 'BHSO',
      oxford_houses: 'Oxford Houses',
      state_substance_use_treatment_agency: 'State substance use treatment agency',
      state_mental_health_department: 'State mental health department',
      state_department_of_health: 'State department of health',
      coa: 'COA',
      hfap: 'HFAP',
      hospital_licensing_authority: 'Hospital licensing authority',
      ncqa: 'NCQA',
      federally_qualified_health_center: 'Federally Qualified Health Center',
      samhsa_otp_certification: 'SAMHSA OTP Certification',
      dea: 'DEA',
    },
    model: 'location.licenses_accreditations_certifications',
  },
  updated_at: {
    id: 'updated_at',
    name: 'Last Updated',
    tooltip: 'The most recent date certification information was updated',
    model: 'location.updated_at',
    inputType: 'date_time',
  },
}

export const LOCATION_DEMOGRAPHICS = {
  age_groups: {
    id: 'age_groups',
    name: 'Age Groups',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      infants_toddlers_0_5: 'Infants/Toddlers (0-5)',
      children_6_12: 'Children (6-12)',
      adolescents_teens_13_17: 'Adolescents/Teens (13-17)',
      young_adults_18_24: 'Young Adults (18-24)',
      adults_25_64: 'Adults (25-64)',
      seniors_elderly_65: 'Seniors/Elderly (65+)',
    },
    model: 'location.age_groups',
  },
  cultural_ethnic: {
    id: 'cultural_ethnic',
    name: 'Cultural/Ethnic',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      alaska_native_american_indian_indigenous_peoples: 'Alaska Native/American Indian/Indigenous Peoples',
      african_american_black: 'African American/Black',
      asian_pacific_islander: 'Asian/Pacific Islander',
      hispanic_latino: 'Hispanic/Latino',
      middle_eastern_north_african: 'Middle Eastern/North African',
      multiracial: 'Multiracial',
      american_indian_alaska_native: 'American Indian/Alaska Native',
      asian: 'Asian',
      asian_indian: 'Asian Indian',
      cambodian: 'Cambodian',
      chinese: 'Chinese',
      filipino: 'Filipino',
      japanese: 'Japanese',
      korean: 'Korean',
      native_hawaiian_pacific_islander: 'Native Hawaiian/Pacific Islander',
      vietnamese: 'Vietnamese',
      white_caucasian: 'White/Caucasian',
      caribbean: 'Caribbean',
      eastern_european: 'Eastern European',
      western_european: 'Western European',
      south_asian: 'South Asian',
      southeast_asian: 'Southeast Asian',
      african: 'African',
    },
    model: 'location.cultural_ethnic',
  },
  family_status: {
    id: 'family_status',
    name: 'Family Status',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      couples_with_children: 'Couples with Children',
      couples_without_children: 'Couples without Children',
      men_with_children: 'Men with Children',
      women_with_children: 'Women with Children',
    },
    model: 'location.family_status',
  },
  gender_identity: {
    id: 'gender_identity',
    name: 'Gender/Identity',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      men_only: 'Men',
      women_only: 'Women',
      gender_neutral: 'Gender Neutral',
      transgender_men: 'Transgender Men',
      transgender_women: 'Transgender Women',
      non_binary: 'Non-Binary',
      intersex: 'Intersex',
      lgbtqia_friendly: 'LGBTQIA+ Friendly',
    },
    model: 'location.gender_identity',
  },
  legal_status: {
    id: 'legal_status',
    name: 'Legal Status',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      criminal_justice_involved: 'Criminal Justice Involved',
      community_corrections: 'Community Corrections',
      department_of_corrections: 'Department of Corrections',
      felons_on_probation_parole: 'Felons on probation/parole',
      re_entry_population: 'Re-entry population',
      registered_violent_offenders: 'Registered violent offenders',
      registered_sex_offenders: 'Registered sex offenders',
    },
    model: 'location.legal_status',
  },
  special_needs_medical: {
    id: 'special_needs_medical',
    name: 'Special Needs & Medical',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      blind_visually_impaired: 'Blind & Visually Impaired',
      deaf_or_hard_of_hearing: 'Deaf or Hard of Hearing',
      developmental_disabilities: 'Developmental Disabilities',
      autism: 'Autism',
      traumatic_brain_injury: 'Traumatic Brain Injury',
      hiv_aids: 'HIV/AIDS',
      immuno_disorders: 'Immuno-disorders',
      eating_disorders: 'Eating Disorders',
      co_occurring_mental_and_substance_use_disorders: 'Co-occurring Mental and Substance Use Disorders',
    },
    model: 'location.special_needs_medical',
  },
  other_special_populations: {
    id: 'other_special_populations',
    name: 'Other Special Populations',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      homeless_housing_insecure: 'Homeless & housing insecure',
      pregnant_individuals: 'Pregnant Individuals',
      human_trafficking_survivors: 'Human Trafficking Survivors',
      domestic_violence_survivors: 'Domestic Violence Survivors',
      sexual_assault_survivors: 'Sexual Assault Survivors',
      rural_populations: 'Rural Populations',
      urban_populations: 'Urban Populations',
    },
    model: 'location.other_special_populations',
  },
  professional_status: {
    id: 'professional_status',
    name: 'Professional Status',
    tooltip: '',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      aviation_professionals: 'Aviation Professionals',
      healthcare_professionals: 'Healthcare Professionals',
      first_responders: 'First Responders',
      military_and_veterans: 'Military and Veterans',
      military_family_members: 'Military Family Members',
    },
    model: 'location.professional_status',
  },
}

export const LOCATION_FACILITY_DETAILS = {
  total_beds: {
    id: 'total_beds',
    name: 'Total Beds',
    tooltip: 'The number of beds available',
    model: 'location.total_beds',
  },
  total_bathrooms: {
    id: 'total_bathrooms',
    name: 'Total Bathrooms',
    tooltip: 'The number of bathrooms in the facility',
    model: 'location.total_bathrooms',
  },
  occupancy_status: {
    id: 'occupancy_status',
    name: 'Occupancy Status',
    tooltip: 'Current availability for new residents',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      no_beds_available: 'No Beds Available',
      beds_available: 'Beds Available',
    },
    model: 'location.occupancy_status',
  },
  waitlist_available: {
    id: 'waitlist_available',
    name: 'Waitlist Available',
    tooltip: 'Whether a waitlist exists for admission',
    model: 'location.waitlist_available',
  },
  estimated_wait_time: {
    id: 'estimated_wait_time',
    name: 'Estimated Wait Time',
    tooltip: 'Typical time spent on waitlist before admission',
    model: 'location.estimated_wait_time',
  },
  accessibility_features: {
    id: 'accessibility_features',
    name: 'Accessibility Features',
    tooltip: 'Features that accommodate disabilities',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      wheelchair_accessible_entrance: 'Wheelchair accessible entrance',
      wheelchair_accessible_entrance_and_pathways: 'Wheelchair accessible entrance and pathways',
      wheelchair_accessible_bedroom_s: 'Wheelchair accessible bedroom(s)',
      wheelchair_accessible_bathroom_s: 'Wheelchair accessible bathroom(s)',
      ramps_with_non_slip_surfaces: 'Ramps with non-slip surfaces',
      handrails_for_all_entrances: 'Handrails for all entrances',
      stair_lift_or_elevator: 'Stair lift or elevator',
      hearing_accessible: 'Hearing accessible',
      visual_accessible: 'Visual accessible',
      other_accessibility_features: 'Other accessibility features',
    },
    model: 'location.accessibility_features',
  },
  security_measures: {
    id: 'security_measures',
    name: 'Security Measures',
    tooltip: 'Safety and security measures in place',
    options: {
      security_cameras: 'Security cameras',
      keypad_entry: 'Keypad entry',
      on_site_security_personnel: 'On-site security personnel',
      gated_community: 'Gated community',
      visitor_sign_in_policy: 'Visitor sign-in policy',
      id_badges: 'ID badges',
      regular_safety_inspections: 'Regular safety inspections',
      secure_medication_storage: 'Secure medication storage',
      emergency_call_system: 'Emergency call system',
      panic_buttons: 'Panic buttons',
      well_lit_exterior: 'Well-lit exterior',
      security_alarms: 'Security alarms',
      window_locks: 'Window locks',
      peepholes_on_doors: 'Peepholes on doors',
      controlled_access_areas: 'Controlled access areas',
    },
    model: 'location.security_measures',
  },
  building_type: {
    id: 'building_type',
    name: 'Building Type',
    tooltip: 'The category of building structure',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      single_family_home: 'Single Family Home',
      apartment_complex: 'Apartment Complex',
      multi_unit_complex: 'Multi-Unit Complex',
      commercial_building: 'Commercial Building',
      mixed_use: 'Mixed Use',
    },
    model: 'location.building_type',
  },
  recreation_wellness_facilities: {
    id: 'recreation_wellness_facilities',
    name: 'Recreation & Wellness Facilities',
    tooltip: 'Facilities for fitness, relaxation, and outdoor activities.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      fitness_center: 'Fitness Center',
      swimming_pool: 'Swimming Pool',
      basketball_court: 'Basketball Court',
      walking_trails: 'Walking Trails',
      game_room: 'Game Room',
      yoga_studio: 'Yoga Studio',
      meditation_garden: 'Meditation Garden',
      sports_equipment: 'Sports Equipment',
      recreation_room: 'Recreation Room',
      exercise_equipment: 'Exercise Equipment',
      surfing: 'Surfing',
      hot_tub: 'Hot Tub',
      massage: 'Massage',
      reflexology: 'Reflexology',
      spa: 'Spa',
      group_adventures: 'Group Adventures',
      fire_pit: 'Fire Pit',
      bbq_grill: 'BBQ Grill',
      waterfront: 'Waterfront',
    },
    model: 'location.recreation_wellness_facilities',
  },
  creative_entertainment_spaces: {
    id: 'creative_entertainment_spaces',
    name: 'Creative & Entertainment Spaces',
    tooltip: 'Spaces for artistic expression and leisure activities.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      arts_crafts: 'Arts & Crafts',
      music_room: 'Music Room',
      theater_room: 'Theater Room',
      creative_writing: 'Creative Writing',
      movie_theater: 'Movie Theater',
      library: 'Library',
      board_games: 'Board Games',
      books: 'Books',
    },
    model: 'location.creative_entertainment_spaces',
  },
  living_accommodations_essentials: {
    id: 'living_accommodations_essentials',
    name: 'Living Accommodations & Essentials',
    tooltip: 'Comfortable and essential amenities for daily living.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      furnished_rooms: 'Furnished Rooms',
      bed_linens: 'Bed Linens',
      crib: 'Crib',
      private_bathrooms: 'Private Bathrooms',
      shared_bathrooms: 'Shared Bathrooms',
      personal_storage: 'Personal Storage',
      patio_or_balcony: 'Patio or Balcony',
      air_conditioning: 'Air Conditioning',
      heating: 'Heating',
      in_room_tv: 'In-room TV',
      outdoor_furniture: 'Outdoor Furniture',
      onsite_washer_and_dryer: 'Onsite washer and dryer',
    },
    model: 'location.living_accommodations_essentials',
  },
  family_child_friendly_amenities: {
    id: 'family_child_friendly_amenities',
    name: 'Family & Child-Friendly Amenities',
    tooltip: 'Child-friendly accommodations for family needs.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      baby_bath: 'Baby Bath',
      baby_monitor: 'Baby Monitor',
      baby_safety_gates: 'Baby Safety Gates',
      changing_table: 'Changing Table',
      children_s_toys: 'Children’s Toys',
      high_chair: 'High Chair',
    },
    model: 'location.family_child_friendly_amenities',
  },
  technology_connectivity: {
    id: 'technology_connectivity',
    name: 'Technology & Connectivity',
    tooltip: 'Access to technology and connectivity services.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      computers: 'Computers',
      wifi: 'Wifi',
      cell_phones_permitted: 'Cell Phones Permitted',
    },
    model: 'location.technology_connectivity',
  },
  health_safety_support_services: {
    id: 'health_safety_support_services',
    name: 'Health, Safety & Support Services',
    tooltip: 'Resources and support for health and well-being.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      naloxone_narcan: 'Naloxone/Narcan',
      narcan_administration: 'Narcan Administration',
      meals_provided: 'Meals Provided',
      financial_management: 'Financial Management',
      outside_support_groups: 'Outside Support Groups',
      carbon_monoxide_alarm: 'Carbon Monoxide Alarm',
      allows_overnight_guests: 'Allows Overnight Guests',
      transportation_assistance: 'Transportation Assistance',
      fire_detector: 'Fire Detector',
      fire_extinguisher: 'Fire Extinguisher',
      defibrillator: 'Defibrillator ',
    },
    model: 'location.health_safety_support_services',
  },
  community_gathering_spaces: {
    id: 'community_gathering_spaces',
    name: 'Community & Gathering Spaces',
    tooltip: 'Areas designed for socializing, dining, and gathering.',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      communal_kitchen: 'Communal Kitchen',
      dining_area: 'Dining Area',
      recreation_room: 'Recreation Room',
      backyard: 'Backyard',
      free_parking: 'Free Parking',
      elevator: 'Elevator',
      private_chef: 'Private Chef',
    },
    model: 'location.community_gathering_spaces',
  },
  year_built: {
    id: 'year_built',
    name: 'Year Built',
    tooltip: 'The year the building was constructed',
    model: 'location.year_built',
  },
  last_renovation_date: {
    id: 'last_renovation_date',
    name: 'Last Renovation Date',
    tooltip: 'When the facility was last renovated',
    model: 'location.last_renovation_date',
  },
}

export const LOCATION_FINANCIALS = {
  payment_schedule: {
    id: 'payment_schedule',
    name: 'Payment Schedule',
    tooltip: 'Available payment frequency options',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      weekly_payments: 'Weekly payments',
      bi_weekly_payments: 'Bi-weekly payments',
      monthly_payments: 'Monthly payments',
      advance_payment_required: 'Advance payment required',
    },
    model: 'location.payment_schedule',
  },
  payment_methods: {
    id: 'payment_methods',
    name: 'Payment Methods',
    tooltip: 'Accepted forms of payment',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      cash: 'Cash',
      check: 'Check',
      credit_card_visa: 'Credit Card (Visa)',
      credit_card_mastercard: 'Credit Card (Mastercard)',
      credit_card_american_express: 'Credit Card (American Express)',
      credit_card_discover: 'Credit Card (Discover)',
      debit_card: 'Debit Card',
      health_savings_account_hsa: 'Health Savings Account (HSA)',
      flexible_spending_account_fsa: 'Flexible Spending Account (FSA)',
      electronic_funds_transfer_eft: 'Electronic Funds Transfer (EFT)',
      pay_pal: 'PayPal',
      venmo: 'Venmo',
      apple_pay: 'Apple Pay',
      google_pay: 'Google Pay',
      money_order: 'Money Order',
      cryptocurrency: 'Cryptocurrency',
    },
    model: 'location.payment_methods',
  },
  accepted_insurance: {
    id: 'accepted_insurance',
    name: 'Insurance Accepted',
    tooltip: 'Insurance plans accepted for payment',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      '1199_seiu': '1199SEIU',
      aetna: 'Aetna',
      affinity_health_plan: 'Affinity Health Plan',
      ambetter: 'Ambetter',
      anthem: 'Anthem',
      aps_healthcare: 'APS Healthcare',
      bhs_behavioral_health_systems: 'BHS | Behavioral Health Systems',
      blue_cross: 'Blue Cross',
      blue_shield: 'Blue Shield',
      carelon_behavioral_health: 'Carelon Behavioral Health',
      cdphp: 'CDPHP',
      champva: 'CHAMPVA',
      children_s_health_insurance_program_chip: "Children's Health Insurance Program (CHIP)",
      choice_care_network: 'ChoiceCare Network',
      cigna_and_evernorth: 'Cigna and Evernorth',
      com_psych: 'ComPsych',
      coventry: 'Coventry',
      emblem_health: 'EmblemHealth',
      empire_blue_cross_blue_shield: 'Empire Blue Cross Blue Shield',
      excellus_blue_cross_blue_shield: 'Excellus BlueCross BlueShield',
      fidelis: 'Fidelis',
      first_health: 'First Health',
      government_employees_health_association_geha: 'Government Employees Health Association (GEHA)',
      group_health_incorporated_ghi: 'Group Health Incorporated (GHI)',
      guardian: 'Guardian',
      harvard_pilgrim: 'Harvard Pilgrim',
      health_net: 'Health Net',
      health_first: 'HealthFirst',
      highmark: 'Highmark',
      hip_health_plan_of_new_york: 'HIP | Health Plan of New York',
      horizon_blue_cross_and_blue_shield: 'Horizon Blue Cross and Blue Shield',
      humana: 'Humana',
      kaiser_permanente: 'Kaiser Permanente',
      magellan: 'Magellan',
      magna_care: 'MagnaCare',
      managed_health_network_mhn: 'Managed Health Network (MHN)',
      medicaid: 'Medicaid',
      medicare: 'Medicare',
      meritain_health: 'Meritain Health',
      metro_plus_health_plan: 'MetroPlus Health Plan',
      military_one_source: 'Military OneSource',
      molina_healthcare: 'Molina Healthcare',
      multi_plan: 'MultiPlan',
      multi_plan_private_healthcare_systems_phcs: 'MultiPlan Private Healthcare Systems (PHCS)',
      mvp_health_care: 'MVP Health Care',
      new_directions_lucet: 'New Directions | Lucet',
      nyship_ny_state_empire_plan: 'NYSHIP | NY State Empire Plan',
      optum: 'Optum',
      oscar_health: 'Oscar Health',
      oxford: 'Oxford',
      psych_care: 'PsychCare',
      tricare: 'TRICARE',
      tri_west: 'TriWest',
      united_medical_resources_umr: 'United Medical Resources (UMR)',
      united_healthcare_uhc_ubh: 'UnitedHealthcare UHC | UBH',
      va_community_care_network_ccn: 'VA Community Care Network (CCN)',
      well_care: 'WellCare',
      wellpoint_amerigroup: 'Wellpoint | Amerigroup',
    },
    model: 'location.accepted_insurance',
  },
  deposit_amount: {
    id: 'deposit_amount',
    name: 'Deposit Amount',
    tooltip: 'The required security deposit',
    filterType: 'amount',
    model: 'location.deposit_amount',
  },
  deposit_due_date: {
    id: 'deposit_due_date',
    name: 'Deposit Due Date',
    tooltip: 'When the deposit must be paid',
    model: 'location.deposit_due_date',
  },
  additional_fees: {
    id: 'additional_fees',
    name: 'Additional Fees',
    tooltip: 'Other costs beyond base fees',
    model: 'location.additional_fees',
  },
  total_move_in_cost: {
    id: 'total_move_in_cost',
    name: 'Total Move-in Cost',
    tooltip: 'The total cost to begin residency',
    filterType: 'amount',
    model: 'location.total_move_in_cost',
  },
  monthly_cost_estimate: {
    id: 'monthly_cost_estimate',
    name: 'Monthly Cost Estimate',
    tooltip: 'The typical monthly cost for services',
    filterType: 'amount',
    model: 'location.monthly_cost_estimate',
  },
  financial_assistance_types: {
    id: 'financial_assistance_types',
    name: 'Financial Assistance Types',
    tooltip: 'Available financial aid options',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      sliding_scale: 'Sliding scale',
      scholarships: 'Scholarships',
      payment_plans: 'Payment plans',
      vouchers: 'Vouchers',
      federal_funding: 'Federal funding',
      state_funding: 'State funding',
      block_grants: 'Block grants',
    },
    model: 'location.financial_assistance_types',
  },
  financial_assistance_details: {
    id: 'financial_assistance_details',
    name: 'Financial Assistance Details',
    tooltip: 'Information about financial aid programs',
    model: 'location.financial_assistance_details',
  },
}

export const LOCATION_POLICIES = {
  tobacco_use_policy: {
    id: 'tobacco_use_policy',
    name: 'Tobacco Use Policy',
    tooltip: 'Rules regarding tobacco use',
    filterType: 'radio',
    options: {
      not_permitted: 'Not permitted',
      permitted_in_designated_area: 'Permitted in designated area',
      permitted_without_restriction: 'Permitted without restriction',
    },
    model: 'location.tobacco_use_policy',
  },
  marijuana_use_policy: {
    id: 'marijuana_use_policy',
    name: 'Marijuana Use Policy',
    tooltip: 'Rules regarding marijuana use',
    filterType: 'radio',
    options: {
      not_permitted: 'Not permitted',
      permitted_with_prescription: 'Permitted with prescription',
      permitted_without_restriction: 'Permitted without restriction',
    },
    model: 'location.marijuana_use_policy',
  },
  minimum_abstinence_requirement: {
    id: 'minimum_abstinence_requirement',
    name: 'Minimum Abstinence Requirement',
    tooltip: 'Required time clean before admission',
    model: 'location.minimum_abstinence_requirement',
  },
  mat_policy_type: {
    id: 'mat_policy_type',
    name: 'MAT Policy Type',
    tooltip: "The facility's approach to medication-assisted treatment",
    options: {
      mat_friendly: 'MAT friendly',
      not_mat_friendly: 'Not MAT friendly',
      case_by_case_basis: 'Case-by-case basis',
    },
    model: 'location.mat_policy_type',
  },
  opioid_treatment_medications: {
    id: 'opioid_treatment_medications',
    name: 'Opioid Treatment Medications',
    tooltip: 'Medications used for opioid treatment that are allowed at this location',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      methadone_allowed: 'Methadone Allowed',
      methadone_offered: 'Methadone Offered',
      buprenorphine_allowed: 'Buprenorphine Allowed',
      buprenorphine_offered: 'Buprenorphine Offered',
      naltrexone_allowed: 'Naltrexone Allowed',
      naltrexone_offered: 'Naltrexone Offered',
      suboxone_allowed: 'Suboxone Allowed',
      suboxone_offered: 'Suboxone Offered',
      subutex_allowed: 'Subutex Allowed',
      subutex_offered: 'Subutex Offered',
      vivitrol_offered: 'Vivitrol Offered',
      vivitrol_allowed: 'Vivitrol Allowed',
    },
    model: 'location.opioid_treatment_medications',
  },
  alcohol_treatment_medications: {
    id: 'alcohol_treatment_medications',
    name: 'Alcohol Treatment Medications',
    tooltip: 'Medications used for alcohol treatment that are allowed at this location',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      acamprosate_allowed: 'Acamprosate Allowed',
      acamprosate_offered: 'Acamprosate Offered',
      naltrexone_allowed: 'Naltrexone Allowed',
      naltrexone_offered: 'Naltrexone Offered',
      disulfiram_allowed: 'Disulfiram Allowed',
      disulfiram_offered: 'Disulfiram Offered',
    },
    model: 'location.alcohol_treatment_medications',
  },
  house_rules: {
    id: 'house_rules',
    name: 'House Rules',
    tooltip: 'Basic rules residents must follow',
    options: {
      curfew_required: 'Curfew required',
      chores_required: 'Chores required',
      house_meetings_required: 'House meetings required',
    },
    model: 'location.house_rules',
  },
  employment_requirements: {
    id: 'employment_requirements',
    name: 'Employment Requirements',
    tooltip: 'Rules about resident employment',
    model: 'location.employment_requirements',
  },
  technology_use_policy: {
    id: 'technology_use_policy',
    name: 'Technology Use Policy',
    tooltip: 'Rules for using electronic devices',
    model: 'location.technology_use_policy',
  },
  visitor_policy: {
    id: 'visitor_policy',
    name: 'Visitor Policy',
    tooltip: 'Rules about visiting hours',
    filterType: 'radio',
    options: {
      not_allowed: 'Not allowed',
      restricted_hours: 'Restricted hours',
      unrestricted: 'Unrestricted',
    },
    model: 'location.visitor_policy',
  },
  overnight_guest_policy: {
    id: 'overnight_guest_policy',
    name: 'Overnight Guest Policy',
    tooltip: 'Whether overnight guests are permitted',
    filterType: 'radio',
    options: {
      not_allowed: 'Not allowed',
      restricted_days: 'Restricted days',
      unrestricted: 'Unrestricted',
    },
    model: 'location.overnight_guest_policy',
  },
  vehicle_policy: {
    id: 'vehicle_policy',
    name: 'Vehicle Policy',
    tooltip: 'Rules about resident vehicles',
    filterType: 'radio',
    options: {
      not_allowed: 'Not allowed',
      allowed_with_restrictions: 'Allowed with restrictions',
      allowed_without_restrictions: 'Allowed without restrictions',
    },
    model: 'location.vehicle_policy',
  },
  criminal_background_check_required: {
    id: 'criminal_background_check_required',
    name: 'Criminal Background Check Required',
    tooltip: 'Whether background checks are mandatory',
    filterType: 'radio',
    options: {
      true: 'Yes',
      false: 'No',
    },
    model: 'location.criminal_background_check_required',
  },
  criminal_history_policy: {
    id: 'criminal_history_policy',
    name: 'Criminal History Policy',
    tooltip: 'Criminal history restrictions',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      arson_not_accepted: 'Arson not accepted',
      home_incarceration_not_accepted: 'Home incarceration not accepted',
      battery_not_accepted: 'Battery not accepted',
    },
    model: 'location.criminal_history_policy',
  },
  sex_offender_policy: {
    id: 'sex_offender_policy',
    name: 'Sex Offender Policy',
    tooltip: 'Policy on admitting sex offenders',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      not_accepted: 'Not accepted',
      accepted: 'Accepted',
      accepted_with_conditions: 'Accepted with conditions',
    },
    model: 'location.sex_offender_policy',
  },
  sex_offender_conditions: {
    id: 'sex_offender_conditions',
    name: 'Sex Offender Conditions',
    tooltip: 'Requirements for sex offenders',
    model: 'location.sex_offender_conditions',
  },
  violent_offender_policy: {
    id: 'violent_offender_policy',
    name: 'Violent Offender Policy',
    tooltip: 'Policy on admitting violent offenders',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      not_accepted: 'Not accepted',
      accepted_with_conditions: 'Accepted with conditions',
      accepted: 'Accepted',
    },
    model: 'location.violent_offender_policy',
  },
  violent_offender_conditions: {
    id: 'violent_offender_conditions',
    name: 'Violent Offender Conditions',
    tooltip: 'Requirements for violent offenders',
    model: 'location.violent_offender_conditions',
  },
  covid_19_precautions: {
    id: 'covid_19_precautions',
    name: 'COVID-19 Precautions',
    tooltip: 'Safety measures for COVID-19',
    model: 'location.covid_19_precautions',
  },
  medication_storage_policy: {
    id: 'medication_storage_policy',
    name: 'Medication Storage Policy',
    tooltip: 'Rules for storing medications',
    model: 'location.medication_storage_policy',
  },
  contraband_policy: {
    id: 'contraband_policy',
    name: 'Contraband Policy',
    tooltip: 'Items not allowed in the facility',
    model: 'location.contraband_policy',
  },
}

export const LOCATION_TREATMENT = {
  on_site_services: {
    id: 'on_site_services',
    name: 'On-site Services',
    tooltip: 'Services provided at the facility',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      cognitive_behavioral_therapy: 'Cognitive Behavioral Therapy',
      dialectical_behavior_therapy: 'Dialectical Behavior Therapy',
      motivational_interviewing: 'Motivational Interviewing',
      '12_step_facilitation': '12-Step Facilitation',
      smart_recovery: 'SMART Recovery',
      trauma_informed_care: 'Trauma-Informed Care',
      family_systems_therapy: 'Family Systems Therapy',
      group_therapy: 'Group Therapy',
      individual_therapy: 'Individual Therapy',
      medication_assisted_treatment: 'Medication-Assisted Treatment',
      faith_based_treatment: 'Faith-Based Treatment',
      holistic_therapy: 'Holistic Therapy',
      individual_counseling: 'Individual Counseling',
      life_skills_training: 'Life Skills Training',
      medication_management: 'Medication Management',
      peer_support: 'Peer Support',
      recovery_meetings: 'Recovery Meetings',
      '12_step_meetings': '12-Step Meetings',
      smart_recovery_meetings: 'SMART Recovery Meetings',
      yoga_meditation: 'Yoga/Meditation',
      art_therapy: 'Art Therapy',
      music_therapy: 'Music Therapy',
      equine_therapy: 'Equine Therapy',
      fitness_classes: 'Fitness Classes',
      nutritional_counseling: 'Nutritional Counseling',
      financial_planning: 'Financial Planning',
      relapse_prevention: 'Relapse Prevention',
      anger_management: 'Anger Management',
      stress_management: 'Stress Management',
      parenting_classes: 'Parenting Classes',
    },
    model: 'location.on_site_services',
  },
  off_site_services: {
    id: 'off_site_services',
    name: 'Off-site Services',
    tooltip: 'Services arranged at other locations',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      outpatient_treatment: 'Outpatient treatment',
      intensive_outpatient_program_iop: 'Intensive outpatient program (IOP)',
      partial_hospitalization_program_php: 'Partial hospitalization program (PHP)',
      job_training: 'Job training',
      education_support: 'Education support',
      ged_preparation: 'GED preparation',
      college_application_assistance: 'College application assistance',
      vocational_rehabilitation: 'Vocational rehabilitation',
      legal_aid: 'Legal aid',
      healthcare_services: 'Healthcare services',
      dental_services: 'Dental services',
      psychiatric_services: 'Psychiatric services',
      family_therapy: 'Family therapy',
      couples_counseling: 'Couples counseling',
    },
    model: 'location.off_site_services',
  },
  referral_network: {
    id: 'referral_network',
    name: 'Referral Network',
    tooltip: 'External services available through referral',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      medical_care: 'Medical care',
      mental_health_services: 'Mental health services',
      legal_services: 'Legal services',
      dental_care: 'Dental care',
      vision_care: 'Vision care',
      substance_use_disorder_treatment: 'Substance use disorder treatment',
      detoxification_services: 'Detoxification services',
      inpatient_treatment: 'Inpatient treatment',
      sober_living_homes: 'Sober living homes',
      housing_assistance: 'Housing assistance',
      food_assistance: 'Food assistance',
      childcare_services: 'Childcare services',
      domestic_violence_support: 'Domestic violence support',
      sexual_assault_support: 'Sexual assault support',
      lgbtqia_specific_services: 'LGBTQIA+ specific services',
      veterans_services: 'Veterans services',
      social_services: 'Social services',
    },
    model: 'location.referral_network',
  },
  support_programs: {
    id: 'support_programs',
    name: 'Support Programs',
    tooltip: 'Available support programs',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      recovery_meetings: 'Recovery meetings',
      life_skills: 'Life skills',
      education: 'Education',
      vocational: 'Vocational',
      family_support: 'Family support',
    },
    model: 'location.support_programs',
  },
  transportation_options: {
    id: 'transportation_options',
    name: 'Transportation Options',
    tooltip: 'Ways residents can travel',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      free_parking_for_own_vehicle: 'Free parking for own vehicle',
      near_public_transportation: 'Near public transportation',
      personal_vehicles_allowed: 'Personal vehicles allowed',
      in_house_transportation_service: 'In-house transportation Service',
      taxi_rideshare_assistance: 'Taxi/rideshare assistance',
    },
    model: 'location.transportation_options',
  },
  educational_programs: {
    id: 'educational_programs',
    name: 'Educational Programs',
    tooltip: 'Available educational programs',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      ged_preparation: 'GED Preparation',
      college_readiness: 'College Readiness',
      computer_skills: 'Computer Skills',
      financial_literacy: 'Financial Literacy',
      life_skills: 'Life Skills',
      study_skills: 'Study Skills',
      career_development: 'Career Development',
      job_readiness: 'Job Readiness',
    },
    model: 'location.educational_programs',
  },
  service_format: {
    id: 'service_format',
    name: 'Service Format',
    tooltip: 'The available formats for receiving services',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      individual: 'Individual',
      couple: 'Couple',
      family: 'Family',
      group: 'Group',
    },
    model: 'location.service_format',
  },
  clinical_staff_types: {
    id: 'clinical_staff_types',
    name: 'Clinical Staff Types',
    tooltip: 'Types of professionals on staff',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      social_worker: 'Social Worker',
      certified_alcohol_drug_counselor: 'Certified Alcohol Drug Counselor',
      medical_practitioner: 'Medical Practitioner',
      nurse: 'Nurse',
      psychiatrist: 'Psychiatrist',
      behavioral_health_clinician: 'Behavioral Health Clinician',
      case_manager: 'Case Manager',
      peer_support_specialist: 'Peer Support Specialist',
    },
    model: 'location.clinical_staff_types',
  },
  staff_credentials: {
    id: 'staff_credentials',
    name: 'Staff Credentials',
    tooltip: 'Professional qualifications of staff',
    model: 'location.staff_credentials',
  },
  cultural_competency_training: {
    id: 'cultural_competency_training',
    name: 'Cultural Competency Training',
    tooltip: 'Whether staff receives cultural training',
    model: 'location.cultural_competency_training',
    inputType: 'boolean',
  },
  staff_specializations: {
    id: 'staff_specializations',
    name: 'Staff Specializations',
    tooltip: 'Areas of staff expertise',
    model: 'location.staff_specializations',
  },
  substances_treated: {
    id: 'substances_treated',
    name: 'Substances Treated',
    tooltip: 'Substances addressed in treatment',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      alcohol: 'Alcohol',
      anti_convulsants: 'Anti-Convulsants',
      benzodiazepines: 'Benzodiazepines',
      cannabis: 'Cannabis',
      cocaine: 'Cocaine',
      dextromethorphan: 'Dextromethorphan',
      hallucinogens: 'Hallucinogens',
      heroin: 'Heroin',
      inhalants: 'Inhalants',
      methamphetamine: 'Methamphetamine',
      non_substance_addictions: 'Non-Substance Addictions',
      opioids: 'Opioids',
      sedatives: 'Sedatives',
      pcp: 'PCP',
      stimulants: 'Stimulants',
      tobacco: 'Tobacco',
      prescription_medications: 'Prescription Medications',
      synthetic_drugs: 'Synthetic Drugs',
      designer_drugs: 'Designer Drugs',
      bath_salts: 'Bath Salts',
      kratom: 'Kratom',
      khat: 'Khat',
      salvia: 'Salvia',
      research_chemicals: 'Research Chemicals',
      performance_enhancing_drugs: 'Performance Enhancing Drugs',
      over_the_counter_medications: 'Over-the-Counter Medications',
    },
    model: 'location.substances_treated',
  },
  recommended_stay_length: {
    id: 'recommended_stay_length',
    name: 'Recommended Stay Length',
    tooltip: 'Suggested duration of treatment',
    model: 'location.recommended_stay_length',
  },
  maximum_stay_length: {
    id: 'maximum_stay_length',
    name: 'Maximum Stay Length',
    tooltip: 'Longest allowed stay',
    model: 'location.maximum_stay_length',
  },
  medical_conditions_accepted: {
    id: 'medical_conditions_accepted',
    name: 'Medical Conditions Accepted',
    tooltip: 'Medical conditions that can be accommodated',
    options: {
      suicidal_with_attempt: 'Suicidal with attempt',
      overdose: 'Overdose',
      active_psychosis: 'Active Psychosis',
      chronic_medical_conditions_requiring_monitoring: 'Chronic medical conditions requiring monitoring',
      seizure_disorders: 'Seizure Disorders',
      epilipsy_disorder: 'Epilipsy Disorder',
      respiratory_conditions: 'Respiratory Conditions',
      cardiac_conditions: 'Cardiac Conditions',
      infectious_diseases: 'Infectious Diseases',
      severe_liver_disease: 'Severe Liver Disease',
      severe_kidney_disease: 'Severe Kidney Disease',
      acute_pancreatitis: 'Acute Pancreatitis',
      severe_withdrawal_syndromes: 'Severe Withdrawal Syndromes',
      severe_mental_health_crisis: 'Severe Mental Health Crisis',
      diabetes: 'Diabetes',
      hypertension: 'Hypertension',
      traumatic_injuries: 'Traumatic Injuries',
      active_cancer_treatment_needs: 'Active Cancer Treatment Needs',
      pregnanacy_with_high_risk_complications: 'Pregnanacy with High-Risk Complications ',
    },
    model: 'location.medical_conditions_accepted',
    inputType: 'checkbox',
  },
  success_metrics: {
    id: 'success_metrics',
    name: 'Success Metrics',
    tooltip: 'Ways success is measured',
    options: {
      completion_rate: 'Completion Rate',
      average_length_of_stay: 'Average Length of Stay',
      post_program_follow_up: 'Post-Program Follow-up',
    },
    model: 'location.success_metrics',
    inputType: 'checkbox',
  },
  transitional_services: {
    id: 'transitional_services',
    name: 'Transitional Services',
    tooltip: 'Services for transition period',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      aftercare_planning: 'Aftercare planning',
      discharge_planning: 'Discharge planning',
      recovery_coaching: 'Recovery coaching',
      alumni_programs: 'Past Client programs',
      relapse_prevention_planning: 'Relapse prevention planning',
      continuing_care: 'Continuing care',
    },
    model: 'location.transitional_services',
  },
  success_rate: {
    id: 'success_rate',
    name: 'Success Rate',
    tooltip: 'Percentage of successful outcomes',
    model: 'location.success_rate',
  },
  average_length_of_stay: {
    id: 'average_length_of_stay',
    name: 'Average Length of Stay',
    tooltip: 'Typical duration of residency',
    model: 'location.average_length_of_stay',
  },
}

export const LOCATION_MARKETING = {
  facility_brochures: {
    id: 'facility_brochures',
    name: 'Facility Brochure',
    tooltip: 'Uploaded marketing materials about the facility',
    model: 'location.facility_brochures',
  },
  faq_documents: {
    id: 'faq_documents',
    name: 'FAQ Document',
    tooltip: 'Document containing frequently asked questions and answers',
    model: 'location.faq_documents',
  },
  virtual_tour: {
    id: 'virtual_tour',
    name: 'Virtual Tour',
    tooltip: 'Link to online facility tour',
    model: 'location.virtual_tour',
    inputType: 'website',
  },
  reviews_integration: {
    id: 'reviews_integration',
    name: 'Reviews Integration',
    tooltip: 'Link to online review platform',
    model: 'location.reviews_integration',
    inputType: 'website',
  },
}

export const LOCATION_TREATMENT_DETAILS = {
  abstinence_definition: {
    id: 'abstinence_definition',
    name: 'Abstinence Definition',
    tooltip: 'States how the facility defines abstinence',
    model: 'location.abstinence_definition',
  },
  treatment_intensity: {
    id: 'treatment_intensity',
    name: 'Treatment Intensity',
    tooltip: 'Indicates hours of treatment provided per week',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      low_intensity_5_hours_week: 'Low intensity (<5 hours/week)',
      medium_intensity_5_10_hours_week: 'Medium intensity (5-10 hours/week)',
      high_intensity_10_hours_week: 'High intensity (>10 hours/week)',
      variable_intensity: 'Variable intensity',
    },
    model: 'location.treatment_intensity',
  },
  treatment_phases: {
    id: 'treatment_phases',
    name: 'Treatment Phases',
    tooltip: 'Lists the stages of the treatment program',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      phase_1_stabilization: 'Phase 1 - Stabilization',
      phase_2_early_recovery: 'Phase 2 - Early Recovery',
      phase_3_maintenance: 'Phase 3 - Maintenance',
      phase_4_advanced_recovery: 'Phase 4 - Advanced Recovery',
    },
    model: 'location.treatment_phases',
  },
  phase_requirements: {
    id: 'phase_requirements',
    name: 'Phase Requirements',
    tooltip: 'States what residents must complete in each phase',
    model: 'location.phase_requirements',
  },
  phase_privileges: {
    id: 'phase_privileges',
    name: 'Phase Privileges',
    tooltip: 'Lists benefits earned in each treatment phase',
    model: 'location.phase_privileges',
  },
  advancement_criteria: {
    id: 'advancement_criteria',
    name: 'Advancement Criteria',
    tooltip: 'States requirements for moving between phases',
    model: 'location.advancement_criteria',
  },
}

export const LOCATION_DOCUMENTATION = {
  intake_requirements: {
    id: 'intake_requirements',
    name: 'Intake Requirements',
    tooltip: 'Lists steps required for admission',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      medical_clearance: 'Medical clearance',
      psychiatric_evaluation: 'Psychiatric evaluation',
      detox_completion: 'Detox completion',
      background_check: 'Background check',
      financial_verification: 'Financial verification',
      insurance_verification: 'Insurance verification',
      reference_check: 'Reference check',
      tb_test: 'TB test',
      covid_19_test: 'COVID-19 test',
      physical_examination: 'Physical examination',
      government_id: 'Government ID',
      insurance_card: 'Insurance card',
      medical_records: 'Medical records',
      criminal_background_check: 'Criminal background check',
      income_verification: 'Income verification',
      reference_letters: 'Reference letters',
      treatment_history: 'Treatment history',
      vaccination_records: 'Vaccination records',
      proof_of_income_employment: 'Proof of income/employment',
      court_documentation: 'Court documentation',
      previous_treatment_records: 'Previous treatment records',
      physical_exam_results: 'Physical exam results',
      medication_list: 'Medication list',
      emergency_contact_information: 'Emergency contact information',
    },
    model: 'location.intake_requirements',
  },
  exclusion_criteria: {
    id: 'exclusion_criteria',
    name: 'Exclusion Criteria',
    tooltip: 'States reasons admission may be denied',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      active_psychosis: 'Active psychosis',
      acute_medical_conditions: 'Acute medical conditions',
      specific_criminal_history: 'Specific criminal history',
      high_risk_behaviors: 'High risk behaviors',
      medical_conditions_beyond_capability: 'Medical conditions beyond capability',
      active_withdrawal_requiring_medical_supervision: 'Active withdrawal requiring medical supervision',
    },
    model: 'location.exclusion_criteria',
  },
  waitlist_process: {
    id: 'waitlist_process',
    name: 'Waitlist Process',
    tooltip: 'Describes process when facility is full',
    model: 'location.waitlist_process',
  },
  assessment_process: {
    id: 'assessment_process',
    name: 'Assessment Process',
    tooltip: 'Describes how residents are evaluated',
    model: 'location.assessment_process',
  },
  progress_tracking: {
    id: 'progress_tracking',
    name: 'Progress Tracking',
    tooltip: 'Lists methods for monitoring resident progress',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      daily_progress_notes: 'Daily progress notes',
      weekly_summaries: 'Weekly summaries',
      monthly_evaluations: 'Monthly evaluations',
      treatment_plan_reviews: 'Treatment plan reviews',
      outcome_measurements: 'Outcome measurements',
      drug_screening_results: 'Drug screening results',
    },
    model: 'location.progress_tracking',
  },
  discharge_process: {
    id: 'discharge_process',
    name: 'Discharge Process',
    tooltip: 'Lists steps in the discharge process',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      discharge_planning: 'Discharge planning',
      aftercare_plan: 'Aftercare plan',
      referral_coordination: 'Referral coordination',
      medication_management_plan: 'Medication management plan',
      follow_up_schedule: 'Follow-up schedule',
      emergency_contacts: 'Emergency contacts',
    },
    model: 'location.discharge_process',
  },
  required_forms: {
    id: 'required_forms',
    name: 'Required Forms',
    tooltip: 'Lists forms that must be completed',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      admission_agreement: 'Admission Agreement',
      financial_agreement: 'Financial Agreement',
      hipaa_forms: 'HIPAA Forms',
      release_of_information: 'Release of Information',
      rights_and_responsibilities: 'Rights and Responsibilities',
      treatment_contract: 'Treatment Contract',
      emergency_contact_form: 'Emergency Contact Form',
      medical_history_form: 'Medical History Form',
    },
    model: 'location.required_forms',
  },
}

export const LOCATION_MEDICATION_MANAGEMENT = {
  medication_storage: {
    id: 'medication_storage',
    name: 'Medication Storage',
    tooltip: 'Lists methods for securing medications',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      locked_cabinet: 'Locked cabinet',
      medication_room: 'Medication room',
      staff_administered: 'Staff administered',
      self_administered_with_supervision: 'Self administered with supervision',
      electronic_medication_tracking: 'Electronic medication tracking',
      biometric_access_control: 'Biometric access control',
      temperature_controlled_storage: 'Temperature-controlled storage',
      controlled_substance_double_lock: 'Controlled substance double-lock',
      medication_disposal_system: 'Medication disposal system',
    },
    model: 'location.medication_storage',
  },
  medication_administration_times: {
    id: 'medication_administration_times',
    name: 'Medication Administration Times',
    tooltip: 'States when medications are given',
    model: 'location.medication_administration_times',
  },
  medication_policies: {
    id: 'medication_policies',
    name: 'Medication Policies',
    tooltip: 'Lists rules about medication handling',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      over_the_counter_allowed: 'Over-the-counter allowed',
      prescription_verification_required: 'Prescription verification required',
      medication_counts_required: 'Medication counts required',
      medication_reviews_scheduled: 'Medication reviews scheduled',
      outside_pharmacy_relationships: 'Outside pharmacy relationships',
      medication_reconciliation_process: 'Medication reconciliation process',
      prn_medication_protocol: 'PRN medication protocol',
      medication_error_reporting: 'Medication error reporting',
    },
    model: 'location.medication_policies',
  },
  medical_staff_coverage: {
    id: 'medical_staff_coverage',
    name: 'Medical Staff Coverage',
    tooltip: 'Indicates availability of medical personnel',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      '24_7_medical_staff': '24/7 medical staff',
      limited_medical_staff_hours: 'Limited medical staff hours',
      on_call_medical_staff: 'On-call medical staff',
      no_medical_staff: 'No medical staff',
    },
    model: 'location.medical_staff_coverage',
  },
}

export const LOCATION_STAFF_DEVELOPMENT = {
  required_training: {
    id: 'required_training',
    name: 'Required Training',
    tooltip: 'Lists mandatory staff training',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      cpr_first_aid: 'CPR/First Aid',
      crisis_intervention: 'Crisis intervention',
      cultural_competency: 'Cultural competency',
      hipaa: 'HIPAA',
      ethics: 'Ethics',
      clinical_documentation: 'Clinical documentation',
      evidence_based_practices: 'Evidence-based practices',
      trauma_informed_care: 'Trauma-informed care',
      motivational_interviewing: 'Motivational interviewing',
    },
    model: 'location.required_training',
  },
  ongoing_education: {
    id: 'ongoing_education',
    name: 'Ongoing Education',
    tooltip: 'Lists available staff education',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      continuing_education_credits: 'Continuing education credits',
      in_service_training: 'In-service training',
      external_training: 'External training',
      professional_development: 'Professional development',
      clinical_supervision_hours: 'Clinical supervision hours',
    },
    model: 'location.ongoing_education',
  },
  staff_support: {
    id: 'staff_support',
    name: 'Staff Support',
    tooltip: 'Lists resources available to staff',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      clinical_supervision: 'Clinical supervision',
      peer_supervision: 'Peer supervision',
      staff_wellness_program: 'Staff wellness program',
      employee_assistance_program: 'Employee assistance program',
      professional_liability_coverage: 'Professional liability coverage',
    },
    model: 'location.staff_support',
  },
  staff_qualifications: {
    id: 'staff_qualifications',
    name: 'Staff Qualifications',
    tooltip: 'Lists required staff credentials',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      licensed_clinical_social_worker: 'Licensed Clinical Social Worker',
      licensed_professional_counselor: 'Licensed Professional Counselor',
      certified_addiction_counselor: 'Certified Addiction Counselor',
      registered_nurse: 'Registered Nurse',
      licensed_practical_nurse: 'Licensed Practical Nurse',
      psychiatrist: 'Psychiatrist',
      medical_doctor: 'Medical Doctor',
      peer_support_specialist: 'Peer Support Specialist',
      recovery_coach: 'Recovery Coach',
    },
    model: 'location.staff_qualifications',
  },
  staff_background_checks: {
    id: 'staff_background_checks',
    name: 'Staff Background Checks',
    tooltip: 'Lists required staff screening',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      criminal_background_check: 'Criminal background check',
      child_abuse_clearance: 'Child abuse clearance',
      elder_abuse_clearance: 'Elder abuse clearance',
      drug_screening: 'Drug screening',
      professional_license_verification: 'Professional license verification',
      reference_check: 'Reference check',
    },
    model: 'location.staff_background_checks',
  },
}

// Programs
export const PROGRAM_OPERATIONS = {
  name: {
    id: 'name',
    name: 'Name',
    model: 'name',
  },
  email: {
    id: 'email',
    name: 'Email',
    model: 'email',
    inputType: 'email',
  },
  phone_no: {
    id: 'phone_no',
    name: 'Phone',
    model: 'phone_no',
    inputType: 'phone',
  },
  gender: {
    id: 'gender',
    name: 'Genders/Identities Served',
    model: 'gender',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      men_only: 'Men',
      women_only: 'Women',
      gender_neutral: 'Gender Neutral',
      transgender_men: 'Transgender Men',
      transgender_women: 'Transgender Women',
      non_binary: 'Non-Binary',
      intersex: 'Intersex',
      lgbtqia_friendly: 'LGBTQIA+ Friendly',
    },
  },
  address: {
    id: 'address',
    name: 'Address',
    model: 'address',
    inputType: 'address',
  },
  description: {
    id: 'description',
    name: 'Description',
    model: 'description',
  },
  daily_schedule_details: {
    id: 'daily_schedule_details',
    name: 'Daily Schedule Details',
    tooltip: 'Describes typical daily activities and timing',
    model: 'daily_schedule_details',
  },
  meal_service_type: {
    id: 'meal_service_type',
    name: 'Meal Service Type',
    tooltip: 'Indicates how meals are provided and dietary options',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      all_meals_provided: 'All meals provided',
      some_meals_provided: 'Some meals provided',
      no_meals_provided: 'No meals provided',
      special_dietary_accommodations: 'Special dietary accommodations',
      dietary_restrictions_accommodated: 'Dietary restrictions accommodated',
      kosher_meals_available: 'Kosher meals available',
      halal_meals_available: 'Halal meals available',
      vegetarian_vegan_options: 'Vegetarian/Vegan options',
      food_allergies_accommodated: 'Food allergies accommodated',
    },
    model: 'meal_service_type',
  },
  technology_policy: {
    id: 'technology_policy',
    name: 'Technology Policy',
    tooltip: 'States rules regarding electronic device use',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      cell_phones_allowed: 'Cell phones allowed',
      internet_access: 'Internet access',
      personal_computers_allowed: 'Personal computers allowed',
      restricted_device_hours: 'Restricted device hours',
      monitored_usage: 'Monitored usage',
      no_personal_devices: 'No personal devices',
    },
    model: 'technology_policy',
  },
  hours_of_operation: {
    id: 'hours_of_operation',
    name: 'Hours of Operation',
    tooltip: 'Lists when facility services are available',
    model: 'hours_of_operation',
  },
  staff_coverage_schedule: {
    id: 'staff_coverage_schedule',
    name: 'Staff Coverage Schedule',
    tooltip: 'Details when staff members are on duty',
    model: 'staff_coverage_schedule',
  },
  weekend_operations: {
    id: 'weekend_operations',
    name: 'Weekend Operations',
    tooltip: 'Indicates level of weekend services',
    inputType: 'radio',
    filterType: 'checkbox',
    options: {
      full_service: 'Full service',
      limited_service: 'Limited service',
      emergency_only: 'Emergency only',
      closed: 'Closed',
    },
    model: 'weekend_operations',
  },
  holiday_operations: {
    id: 'holiday_operations',
    name: 'Holiday Operations',
    tooltip: 'Indicates level of holiday services',
    inputType: 'radio',
    filterType: 'checkbox',
    options: {
      full_service: 'Full service',
      limited_service: 'Limited service',
      emergency_only: 'Emergency only',
      closed: 'Closed',
    },
    model: 'holiday_operations',
  },
  facility_operation_type: {
    id: 'facility_operation_type',
    name: 'Facility Operation Type',
    tooltip: 'Indicates the type of organization operating the facility',
    inputType: 'checkbox',
    filterType: 'checkbox',
    options: {
      community_government: 'Community Government',
      county_government: 'County Government',
      federal_government: 'Federal Government',
      indian_health_services: 'Indian Health Services',
      local_government: 'Local Government',
      private_for_profit: 'Private For-Profit',
      private_non_profit: 'Private Non-Profit',
      state_government: 'State Government',
      tribal_government: 'Tribal Government',
      veterans_affairs: 'Veterans Affairs',
    },
    model: 'facility_operation_type',
  },
}
