import React from 'react'
import compact from 'lodash/compact'

import { DATA_FORM_FILTERS } from '../Filters/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Button from '../../components/Button'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

export const RecoveryNotesDataTable = (props: any) => {
  const { to, duplicateFormLink, onDuplicateFormClick, ...rest } = props

  const columns = React.useMemo(
    () =>
      compact([
        {
          title: 'Name',
          model: 'name',
          width: 260,
          disableHide: true,
          formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
        },
        {
          title: 'Client',
          model: 'client',
          formatValue: ({ value }) => {
            if (!value) return null

            return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/recovery-coaching`} />
          },
        },
        {
          width: 200,
          model: 'status',
          title: 'Status',
          formatValue: ({ value }: any) => <DataFormStatus status={value} />,
        },
        {
          width: 200,
          model: 'created_at',
          title: 'Date Added',
          type: 'date_time',
        },
        {
          width: 200,
          model: 'updated_at',
          title: 'Last Updated',
          type: 'date_time',
        },
        {
          width: 180,
          model: 'author',
          title: 'Added By',
          type: 'profile',
        },
        (duplicateFormLink || onDuplicateFormClick) && {
          width: 180,
          id: 'actions',
          title: 'Actions',
          hoverExpand: false,
          disableSort: true,
          formatValue: ({ data }: any) => (
            <Button
              label="Duplicate"
              glyph="add"
              size={100}
              link={duplicateFormLink?.(data)}
              onClick={onDuplicateFormClick ? () => onDuplicateFormClick(data) : undefined}
              permission="recovery_coaching.create"
            />
          ),
        },
      ]),
    [to, duplicateFormLink],
  )

  return (
    <DataTable
      asCard
      title="Recovery Note Forms"
      icon="recovery_coaching"
      testKey="recovery_coaching_data_table"
      columns={columns}
      filtersConfig={DATA_FORM_FILTERS}
      {...rest}
    />
  )
}
