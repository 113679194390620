import React from 'react'

import { DataTable } from '../../../DataTable/DataTable'
import { MainCell } from '../../../DataTable/cells/MainCell'
import { useDataTable } from '../../../DataTable/useDataTable'

import { DEFAULT_FILTERS } from '../../../../constructs/Filters/constants'
import DiagnosisStatus, { STATUSES } from '../../../Statuses/DiagnosisStatus'
import DiagnosisCategoryStatus, { STATUSES as CATEGORY_STATUSES } from '../../../Statuses/DiagnosisCategoryStatus'

const SOURCE = {
  erx: 'eRX',
  internal: 'Custom',
}

export const ClientDiagnosesTable = ({ onSelect, dependentValue, includeColumns, ...rest }: any) => {
  const tableProps = useDataTable({
    name: ['selector-client-diagnoses', dependentValue],
    endpoint: `/residents/${dependentValue}/diagnoses`,
    localStorageKey: 'selector_client_diagnoses_v1',
  })

  const defaultColumns = React.useMemo(
    () => [
      {
        title: 'Code',
        id: 'code',
        model: 'code',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.code} onClick={onSelect ? () => onSelect(data) : undefined} />,
      },
      {
        title: 'Status',
        model: 'status',
        width: 100,
        formatValue: ({ value }: any) => <DiagnosisStatus status={value} />,
      },
      {
        title: 'Category',
        model: 'category',
        width: 130,
        formatValue: ({ value }: any) => <DiagnosisCategoryStatus status={value} />,
      },
      {
        title: 'Source',
        model: 'source',
        width: 130,
        formatValue: ({ value }: any) => SOURCE?.[value] || null,
      },
      {
        title: 'Description',
        model: 'description',
        width: 200,
      },
      {
        title: 'From Date',
        model: 'from',
        type: 'date',
      },
      {
        title: 'Until Date',
        model: 'until',
        type: 'date',
      },
      {
        title: 'Notes',
        model: 'notes',
      },
    ],
    [],
  )

  const columns = React.useMemo(() => {
    if (!includeColumns) return defaultColumns

    return defaultColumns.filter((column) => {
      return includeColumns.includes(column.id) || includeColumns.includes(column.model)
    })
  }, [includeColumns])

  return <DataTable {...tableProps} {...rest} icon="diagnosis" title="Diagnoses" columns={columns} filtersConfig={FILTERS_CONFIG} />
}

const FILTERS_CONFIG = {
  code: {
    label: 'code',
    type: 'string',
  },
  status: {
    label: 'Status',
    type: 'multi_select',
    condition: 'in',
    options: Object.entries(STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  category: {
    label: 'Category',
    type: 'multi_select',
    condition: 'in',
    options: Object.entries(CATEGORY_STATUSES).map(([value, { label }]) => ({ value, label })),
  },
  source: {
    label: 'Source',
    type: 'string',
  },
  description: {
    label: 'Description',
    type: 'string',
  },
  notes: {
    label: 'Notes',
    type: 'string',
  },
  from: {
    label: 'From Date',
    type: 'date_time',
    glyph: 'date',
  },
  until: {
    label: 'Until Date',
    type: 'date_time',
    glyph: 'date',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
