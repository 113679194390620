import React from 'react'
import { useHistory } from 'react-router-dom'
import size from 'lodash/size'

import { countWord } from '../../../utils/functions'
import { useCreate } from '../../../hooks/useNewAPI'
import { useDataTable } from '../../DataTable/useDataTable'
import { DataTable } from '../../DataTable/DataTable'
import { MainCell } from '../../DataTable/cells/MainCell'

import Button from '../../Button'
import Overlay from '../../Overlay'
import Section from '../../Section'

import TemplateLegalTooltip from '../TemplateLegalTooltip'
import { ImportTemplatesTabs } from '../../../constructs/Templates/ImportTemplatesTabs'

const COLLECTION_TYPE: any = {
  insurance: 'Insurance',
  cash: 'Cash',
}

const UNIT_TYPE: any = {
  per_diem: 'Per Diem',
  per_session: 'Per Session',
  per_item: 'Per Item',
  per_hour: 'Per Hour',
  per_minutes: 'Per Minutes',
}

const InsuranceServiceTemplatesImportOverlay = () => {
  const history = useHistory()

  const [selectedRows, setSelectedRows]: any = React.useState([])

  const tableProps = useDataTable({
    name: ['insurance_services_templates'],
    endpoint: '/internal_templates',
    params: {
      category: 'insurance',
      subcategory: 'insurance_service',
    },
    localStorageKey: 'insurance_services_templates_v1',
  })

  const createMutation = useCreate({
    name: ['import-service'],
    url: '/internal_templates/import',
    invalidate: 'services',
  })

  const selectedRowsCount = size(selectedRows)
  const hasSelectedRows = selectedRowsCount >= 1

  const onImport = async () => {
    try {
      const ids = selectedRows.map((o: any) => o.id)

      await createMutation.mutateAsync({
        ids: ids,
        category: 'insurance',
        subcategory: 'insurance_service',
      })

      history.goBack()
    } catch (errors) {
      console.debug(errors)
    }
  }

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value} />
        },
      },
      {
        title: 'Description',
        model: 'data.description',
      },
      {
        title: 'Collection Type',
        model: 'data.collection_type',
        formatValue: ({ value }: any) => {
          return COLLECTION_TYPE[value] || null
        },
      },
      {
        title: 'Unit',
        model: 'data.unit',
        formatValue: ({ value }: any) => {
          return UNIT_TYPE[value] || null
        },
      },
      {
        title: 'Amount',
        model: 'data.amount',
      },
      {
        title: 'Internal Notes',
        model: 'data.internal_notes',
      },
    ],
    [],
  )

  return (
    <Overlay showBackdrop position="center" maxWidth={97}>
      <Overlay.Header title="Import Insurance Service" icon="insurance" titleAside={<TemplateLegalTooltip />} />

      <Overlay.Content>
        <Section>
          <DataTable
            {...tableProps}
            asCard
            canBatchSelect
            title="Insurance Services"
            icon="insurance"
            className="!mt-4"
            columns={columns}
            onRowSelectionUpdate={setSelectedRows}
          />
        </Section>
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label={hasSelectedRows ? `Import ${countWord('Services', selectedRowsCount)}` : 'Select Services to Import'}
          isDisabled={!hasSelectedRows}
          type="primary"
          color="green"
          isLoading={createMutation.isLoading}
          onClick={onImport}
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export default InsuranceServiceTemplatesImportOverlay
