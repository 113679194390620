import React from 'react'
import { Link, NavLink } from 'react-router-dom-v5-compat'

import Button from '@behavehealth/components/Button'
import Flex from '@behavehealth/components/Flex'
import Icon from '@behavehealth/components/Icon'
import PageSection from '@behavehealth/components/PageSection/PageSection'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { DataTable } from '@behavehealth/components/DataTable/DataTable'
import { MainCell } from '@behavehealth/components/DataTable/cells/MainCell'
import { setDuplicateColumnV6 } from '@behavehealth/components/DataTable/functions'

export const TreatmentPlanBookObjectives = ({ category }: any) => {
  const permissionBase = `${category}_treatment_book`

  const tableProps = useDataTable({
    name: ['treatment-book-objectives'],
    endpoint: `/treatment_book_objectives`,
    params: { category },
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 120,
        formatValue: ({ data, value }: any) => <MainCell as={NavLink} id={data.id} value={value} to={`${data?.id}`} />,
      },
      {
        title: 'Description',
        model: 'description',
        width: 180,
      },
      {
        title: 'Date Created',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Last Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      setDuplicateColumnV6({
        link: 'new',
        permission: `${permissionBase}.create`,
      }),
    ],
    [],
  )

  return (
    <PageSection>
      <PageSection.Header
        after={
          <Button
            as={Link}
            label="Add Objective"
            type="primary"
            glyph="add"
            size={200}
            link={`new`}
            permission={`${permissionBase}.create`}
          />
        }
      >
        <Flex centerY gap="0.5rem">
          <Icon icon="treatment_plans" size={22} />
          <PageSection.Title title="Objectives" />
        </Flex>
      </PageSection.Header>

      <PageSection.Content>
        <DataTable {...tableProps} asCard title="Treatment Book Objectives" icon="treatment_plans" columns={columns} />
      </PageSection.Content>
    </PageSection>
  )
}
