import React from 'react'
import { NavLink, Navigate, Route, Routes } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Page, Tabs, TabList, Tab, Status, HelpTagIframe } from '@behavehealth/components'
import { ProtectedRouteV6 } from '@behavehealth/components/ProtectedRouteV6'
import { withPageError } from '@behavehealth/hocs/withPageError'

import { EHRInvoiceOverlay } from '@behavehealth/constructs/InternalBilling/EHRInvoiceOverlay'

import { SubscriptionsManager } from './pages/subscriptions_manager'
import ActiveSubscriptions from './pages/active_subscriptions'
import InvoicesPayments from './pages/invoices_payments'

const ACCOUNT_TYPES = {
  healthcare_facility: 'Healthcare Facility',
  healthcare_practice: 'Healthcare Practice',
  support_facility: 'Support Facility',
  support_practice: 'Support Practice',
  provider_affiliate: 'Provider Partner',
}

const Billing = () => {
  const tenant = useSelector((state) => state.me.tenant)
  const user = useSelector((state: any) => state.me.user)

  const isBehave = user?.type === 'bh_employee'

  return (
    <Page
      icon="certificate"
      title="Billing"
      titleAside={<Status label={ACCOUNT_TYPES[tenant?.category]} color="blue" />}
      help={<HelpTagIframe id="settings_billing" />}
    >
      <Tabs>
        <TabList className="mb-4 -mt-4">
          <Tab as={NavLink} label="Quotes & Subscriptions" to={`active-subscriptions`} />
          <Tab as={NavLink} label="Invoices & Payments" to={`invoices-payments`} />
          {isBehave && <Tab as={NavLink} label="Subscriptions Manager" to={`subscriptions-manager`} glyph="behave_health" />}
        </TabList>
      </Tabs>

      <Routes>
        <Route index element={<Navigate to={'active-subscriptions'} replace />} />

        <Route path="active-subscriptions/*" element={<ActiveSubscriptions />} />
        <Route path="invoices-payments/*" element={<InvoicesPayments />} />
        <Route
          path="/subscriptions-manager/*"
          element={
            <ProtectedRouteV6 permission={isBehave}>
              <SubscriptionsManager />
            </ProtectedRouteV6>
          }
        />
      </Routes>

      <AnimatedRoutes>
        <Route path="invoices-payments/invoices/:id" element={<EHRInvoiceOverlay useV6Router />} />
      </AnimatedRoutes>
    </Page>
  )
}

export default withPageError(Billing)
