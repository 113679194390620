import { tint } from 'polished'
import clsx from 'clsx'

import { COLORS } from '../../../theme'
import { RED, GREEN } from '../constants'

import Glyph from '../../../components/Glyph'
import Tooltip from '../../../components/Tooltip'

export const Pill = (props: any) => {
  const { label, color, glyph, type, tooltip } = props

  const bg = color === 'green' ? tint(0.88, GREEN) : color === 'red' ? tint(0.8, RED) : tint(0.9, COLORS.gray)

  if (type === 'positive') {
    return (
      <Tooltip show={!!tooltip} content={tooltip} color={GREEN}>
        <div
          className="flex items-center flex-nowrap px-2 py-0.5 pr-4 rounded-[5px] font-[500]"
          style={{ backgroundColor: tint(0.88, GREEN) }}
        >
          <Glyph glyph="check" size="1em" className="mr-1.5" color={GREEN} />
          {label}
        </div>
      </Tooltip>
    )
  }

  if (type === 'negative') {
    return (
      <Tooltip show={!!tooltip} content={tooltip} color={RED}>
        <div
          className="flex items-center flex-nowrap px-2 py-0.5 pr-4 rounded-[5px] font-[500] text-text-strongly-muted"
          style={{
            backgroundColor: 'white',
            boxShadow: `inset 0 0 0 1px ${tint(0.88, RED)}`,
          }}
        >
          <Glyph glyph="cross" size="1em" className="mr-1.5" color={tint(0.24, RED)} />
          {label}
        </div>
      </Tooltip>
    )
  }

  return (
    <Tooltip show={!!tooltip} content={tooltip}>
      <div
        className={clsx('flex items-center flex-nowrap px-2 py-0.5 pr-4 rounded-[5px] font-[500]')}
        style={{
          backgroundColor: bg,
        }}
      >
        {glyph && (
          <Glyph glyph={glyph} size="1em" className="mr-1.5" color={color === 'green' ? GREEN : color === 'red' ? RED : COLORS.gray} />
        )}
        {label}
      </div>
    </Tooltip>
  )
}
