import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { useGet } from '@behavehealth/hooks/useNewAPI'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, Grid, Page, HelpTagIframe } from '@behavehealth/components'
import { ProgramOccupanciesDataTable } from '@behavehealth/constructs/Programs/ProgramOccupanciesDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

const pageConfig = {
  feature: 'programs',
  help: <HelpTagIframe id="client_programs" />,
  marketingID: 'client_programs',
}

const Programs: React.FC<any> = () => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  const tableProps = useDataTable({
    name: ['client', resource_id, 'occupancies'],
    endpoint: `/residents/${resource_id}/occupancies`,
    enabled: !!resource_id,
    params: { category: 'program_management' },
    localStorageKey: 'client_bed_occupancies_v1',
  })

  const actions = (
    <Button
      label="Add Program Assignment"
      type="primary"
      glyph="add"
      link={{
        pathname: `${match.url}/new`,
        parent: match,
        data: { reference: client },
      }}
    />
  )

  return (
    <Page title="Program Assignments" actions={actions} {...pageConfig}>
      <Grid gap="1rem">
        <ProgramOccupanciesDataTable
          {...tableProps}
          to={(row: any) => `${match.url}/${row.id}`}
          duplicateColumn={setDuplicateColumn({
            link: `${match.url}/new`,
            parent: match,
            formatData: (data: any) => ({ reference: client, ...data }),
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(Programs, pageConfig))
