import React from 'react'

import {
  Accordion,
  Button,
  Chotomate,
  Form,
  FormSection,
  Glyph,
  Grid,
  HelpTagIframe,
  Option,
  Page,
  PageSection,
  Permission,
  Radio,
  RadioGroup,
  Select,
  SignaturePad,
  SummonOverlay,
} from '@behavehealth/components'

import { apiUpdate } from '@behavehealth/modules/api'
import UpdatePIN from '@behavehealth/components/Overlays/actions/UpdatePIN'
import { withPageError } from '@behavehealth/hocs/withPageError'

import useStore from '@behavehealth/modules/store'

const YourSettings = () => {
  const record = useStore((state: any) => state.newRecord)
  const update = useStore((state: any) => state.update)

  let pinDescription = ''
  if (record?.signature) {
    pinDescription = record?.is_pin_set
      ? 'Your PIN is set and ready to be used to sign-off forms'
      : 'Set up your PIN to sign off records the fastest way possible'
  } else {
    pinDescription = 'Please add your signature above before setting up your PIN'
  }

  return (
    <Page breakpoint={3} title="Your Settings" feature="your_settings" help={<HelpTagIframe id="settings_your_settings" />}>
      <Grid gap="1rem">
        <Chotomate name="your_profile" />

        <div>
          <Accordion
            isOpen
            key={record?.updated_at}
            initialModel={record}
            title="Personal Details"
            description="Set your personal details & set your PIN"
            permission={true}
            activateEditMode
            onSubmit={async (data: any) => {
              const res = await apiUpdate({ url: '/me', params: data, reducer: 'me' })

              if (res?.data?.new_record) {
                update({ newRecord: res?.data?.new_record })
              }
            }}
            featureFlagV2="pin_based_signature"
          >
            <Form>
              <Grid gap={16}>
                <Permission newFeatureFlag="signatures" featureFlagV2="pin_based_signature">
                  <FormSection layout="vertical">
                    <SignaturePad showHeader={false} showSubtitle={false} showAccept={false} label="Your Signature" model="signature" />
                  </FormSection>

                  <PageSection>
                    <PageSection.Header
                      graphic={<Glyph glyph="lock" />}
                      after={
                        <>
                          {!record?.is_pin_set && record?.signature && (
                            <SummonOverlay overlay={<UpdatePIN title="Create PIN" saveButton="Save PIN" />}>
                              <Button testKey="set_pin_button" label="Set Up My PIN…" type="primary" />
                            </SummonOverlay>
                          )}

                          {record?.is_pin_set && (
                            <SummonOverlay overlay={<UpdatePIN />}>
                              <Button testKey="set_pin_button" label="Update PIN…" type="default" />
                            </SummonOverlay>
                          )}
                        </>
                      }
                    >
                      <PageSection.Title title="PIN Setup" />
                      <PageSection.Subtitle subtitle={pinDescription} />
                    </PageSection.Header>
                  </PageSection>
                </Permission>
              </Grid>
            </Form>
          </Accordion>

          <Accordion
            activateEditMode
            initialModel={record}
            title="Clinical Notes Type"
            description="Set your preference for DAP or SOAP clinical notes"
            featureFlag="clinical_notes"
            onSubmit={(data) => apiUpdate({ url: '/me', params: data, reducer: 'me' })}
            newFeatureFlag="ehr"
            featureFlagV2="clinical_notes"
          >
            <Form>
              <FormSection layout="vertical" labelWidth={150}>
                <Select model="preferred_clinical_note_format" label="Clinical Notes Type" defaultValue="dap">
                  <Option label="DAP" value="dap" />
                  <Option label="SOAP" value="soap" />
                  <Option label="Individual Assessment" value="individual_assessment" />
                </Select>
              </FormSection>
            </Form>
          </Accordion>

          <Accordion
            activateEditMode
            initialModel={record}
            title="Maps Style"
            description="Set your preferred maps theme"
            onSubmit={(data) => apiUpdate({ url: '/me', params: { prefs: Object.assign({}, record.prefs, data.prefs) }, reducer: 'me' })}
          >
            <Form>
              <FormSection layout="vertical" labelWidth={150}>
                <RadioGroup model="prefs.map_style" label="Maps Theme" layout="vertical-dense">
                  <Radio label="Streets" value="streets" />
                  <Radio label="Light" value="light" />
                </RadioGroup>
              </FormSection>
            </Form>
          </Accordion>
        </div>
      </Grid>
    </Page>
  )
}

export default withPageError(YourSettings)
