import React from 'react'
import { NavLink } from 'react-router-dom-v5-compat'

import { DataTable } from '../../components/DataTable/DataTable'
import { DEFAULT_FILTERS } from '../Filters/constants'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'

export const CompanyCredentialsDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Certification',
        model: 'certification',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={data.id} as={NavLink} to={to ? to(data) : undefined} value={value} />,
      },
      {
        title: 'Credential',
        model: 'credential',
        formatValue: ({ value }: any) => <Status small color="blue" label={value} />,
      },
      {
        title: 'Level',
        model: 'level',
        type: 'title',
      },
      {
        title: 'Organization',
        model: 'organization',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return (
    <DataTable asCard title="Company Credentials" icon="staff_credentials" columns={columns} filtersConfig={FILTERS_CONFIG} {...rest} />
  )
}

const FILTERS_CONFIG = {
  certification: {
    label: 'Certification',
    type: 'string',
  },
  credential: {
    label: 'Credential',
    type: 'string',
  },
  level: {
    label: 'Level',
    type: 'string',
  },
  organization: {
    label: 'Organization',
    type: 'string',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
}
