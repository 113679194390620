import React from 'react'
import { useParams, useRouteMatch } from 'react-router-dom'

import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { Button, HelpTagIframe, Page, Grid } from '@behavehealth/components'
import { ClientContactsDataTable } from '@behavehealth/constructs/Contacts/ClientContactsDataTable'
import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { setDuplicateColumn } from '@behavehealth/components/DataTable/functions'

type Props = {
  canCreate: boolean
}

const pageConfig = {
  feature: 'contacts',
  title: 'Contacts',
}

const RootContacts: React.FC<Props> = ({ canCreate = true }) => {
  const match = useRouteMatch()
  const { resource_id }: any = useParams()

  const tableProps = useDataTable({
    name: ['client', resource_id, 'contacts'],
    endpoint: `/residents/${resource_id}/contacts`,
    enabled: !!resource_id,
    localStorageKey: 'client_contacts_v1',
  })

  return (
    <Page
      feature="contacts"
      plan="lite"
      help={<HelpTagIframe id="client_contacts_and_communications" />}
      actions={
        <>{canCreate && <Button label="Add Contact" glyph="add" type="primary" link={`${match.url}/new`} permission="contacts.create" />}</>
      }
    >
      <Grid gap="1rem" columns="100%">
        <ClientContactsDataTable
          {...tableProps}
          to={(row) => ({
            pathname: `${match.url}/${row.id}`,
            parent: match,
          })}
          headerLinksConfig={[
            {
              type: 'report',
              to: '/reports/clients-contacts',
            },
          ]}
          batchActionsConfig={[
            {
              type: 'delete',
              permission: 'contacts.delete',
              action: async ({ ids }: any) => {
                await tableProps.deleteRecords(ids.join(','))
              },
            },
          ]}
          duplicateColumn={setDuplicateColumn({
            link: `${match.url}/new`,
            parent: match,
            permission: 'contacts.create',
          })}
        />
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(RootContacts, pageConfig))
