import React from 'react'
import size from 'lodash/size'

import { apiUpdate, apiGet } from '../../../modules/api'
import { titleCase } from '../../../utils/functions'

import Alert from '../../../components/Alert'
import Card from '../../../components/Card'
import Checkbox from '../../../components/Forms/Checkbox'
import Form from '../../../components/Forms/Form'
import FormSection from '../../../components/Forms/FormSection'
import GridTable from '../../../components/GridTable'
import State from '../../../components/State'
import Workflow from '../../../components/Workflow/Workflow'

import { ProfileCellInner } from '../../../components/DataTable/cells/ProfileCell'

const AccessLists = ({ client, match, setStatus }: any) => {
  const { goNext }: any = React.useContext(Workflow.Context)

  const form = React.useRef()
  const [valid, setValid] = React.useState(false)
  const [requestLoading, setRequestLoading] = React.useState(false)
  const { resource_id } = match.params
  const { isRequired }: any = React.useContext(Workflow.PanelContext)

  const [allEmployees, setAllEmployees]: any = React.useState([])
  const [currentEmployeeIDs, setCurrentEmployeeIDs]: any = React.useState([])

  const [loading, setLoading] = React.useState(true)
  const isEmpty = size(allEmployees) === 0

  React.useEffect(() => {
    const request = async () => {
      try {
        const _allEmployees = await apiGet({
          url: `/employees?status=active&access_level=restricted`,
        })

        const _currentEmployees = await apiGet({
          url: `/residents/${resource_id}/access_employees`,
        })

        const _currentEmployeeIDs: any = []

        if (size(_currentEmployees.data.data) >= 1) {
          for (const employee of _currentEmployees.data.data) {
            _currentEmployeeIDs.push(employee.id)
          }
        }

        setAllEmployees(_allEmployees.data.data)
        setCurrentEmployeeIDs(_currentEmployeeIDs)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    request()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onContinue = async () => {
    setRequestLoading(true)

    const model = form.current.getFormValue()
    const employeeIDs = model.access_employee_ids

    if (size(employeeIDs) === 0) {
      setRequestLoading(false)
      goNext()
    }

    const result: any = []

    for (const id in employeeIDs) {
      if (!employeeIDs[id]) continue

      result.push(id)
    }

    await apiUpdate({
      name: 'access_employees',
      url: `/residents/${resource_id}`,
      params: { access_employee_ids: result },
    })

    setRequestLoading(false)
    setStatus('completed')
    goNext()
  }

  const onSkip = () => {
    setStatus('skipped')
    goNext()
  }

  if (loading) return <State isLoading />

  if (isEmpty) {
    return (
      <FormSection maxWidth="100%">
        <Alert contrast glyph="info">
          No Staff members have restricted Client access. This step can be skipped.
        </Alert>

        {isRequired ? (
          <Workflow.ContinueButton
            label="Mark as Complete & Continue →"
            onClick={() => {
              setStatus('completed')
              goNext()
            }}
            isLoading={requestLoading}
          />
        ) : (
          <Workflow.SkipButton onClick={onSkip} />
        )}
      </FormSection>
    )
  }

  return (
    <Form isCompact useLocalModel getForm={form} onValidationUpdate={setValid}>
      <FormSection maxWidth="100%">
        <Alert small contrast glyph="info">
          <div>
            The following Staff members <b>have restricted access to Clients</b> (owners are excluded from this list, as they have full
            access to all clients by default.)
          </div>
        </Alert>

        <Alert small contrast glyph="warning" type="warning">
          Select which Staff members <b>should have access to this Client's profile.</b>
        </Alert>

        <Card className="">
          <GridTable templateColumns="220px 195px 160px 200px 200px">
            <GridTable.Header>
              <GridTable.Cell>Staff</GridTable.Cell>
              <GridTable.Cell>Actions</GridTable.Cell>
              <GridTable.Cell>Role</GridTable.Cell>
              <GridTable.Cell>Assigned Locations</GridTable.Cell>
              <GridTable.Cell>Assigned Programs</GridTable.Cell>
            </GridTable.Header>

            {allEmployees.map((employee: any) => {
              if (employee.position === 'owner') return null

              return (
                <GridTable.Row key={employee.id}>
                  <GridTable.Cell centerY className="!font-[600]">
                    <ProfileCellInner value={employee} />
                  </GridTable.Cell>

                  <GridTable.Cell centerY>
                    <Checkbox
                      label="Include in Access List"
                      model={`access_employee_ids.${employee.id}`}
                      defaultChecked={currentEmployeeIDs.includes(employee.id)}
                      className="!w-full"
                    />
                  </GridTable.Cell>

                  <GridTable.Cell centerY>{titleCase(employee.position)}</GridTable.Cell>

                  <GridTable.Cell centerY>
                    {size(employee.houses) === 0 ? (
                      <div className="italic text-text-strongly-muted">No assigned locations</div>
                    ) : (
                      <div className="grid gap-2">
                        {employee.houses.map((property: any) => (
                          <ProfileCellInner key={property.id} value={property} />
                        ))}
                      </div>
                    )}
                  </GridTable.Cell>

                  <GridTable.Cell centerY>
                    {size(employee.programs) === 0 ? (
                      <div className="italic text-text-strongly-muted">No assigned programs</div>
                    ) : (
                      <div className="grid gap-2">
                        {employee.programs.map((property: any) => (
                          <ProfileCellInner key={property.id} value={property} />
                        ))}
                      </div>
                    )}
                  </GridTable.Cell>
                </GridTable.Row>
              )
            })}
          </GridTable>
        </Card>

        <Workflow.Buttons>
          <Workflow.ContinueButton
            label="Update Access Lists & Continue →"
            onClick={onContinue}
            isDisabled={!valid}
            isLoading={requestLoading}
          />

          {isRequired ? (
            <Workflow.SkipButton
              label="Continue Without Any Changes →"
              color="green"
              onClick={() => {
                setStatus('completed')
                goNext()
              }}
            />
          ) : (
            <Workflow.SkipButton onClick={onSkip} />
          )}
        </Workflow.Buttons>
      </FormSection>
    </Form>
  )
}

export default AccessLists
