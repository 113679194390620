import React from 'react'
import { v4 as uuid } from 'uuid'
import clsx from 'clsx'
import get from 'lodash/get'
import produce from 'immer'
import remove from 'lodash/remove'
import size from 'lodash/size'

import { DEFAULT_EMPTY_VALUE } from '../../../../utils/constants'
import { plural } from '../../../../utils/functions'
import { validate } from '../../validators'
import { withFormContext } from '../../context'

import Button from '../../../Button'
import Field from '../../../Forms/Field'
import Grid from '../../../Grid'
import Overlay from '../../../Overlay'
import SelectorBase from '../SelectorBase'
import SelectorInput from '../SelectorInput'
import SummonOverlay from '../../../SummonOverlay'
import Value from '../../../Value'

import MultiObjectItem from './MultiObjectItem'

import { LINKS } from '../SelectorBase'
import { TABLES_CONFIG } from '../tables'

class MultiOverlaySelector extends SelectorBase {
  constructor(props) {
    super(props)

    let errors = []
    let vs = props.validations

    let object = props.value || props.form?.getInitialInputFieldValue(props.model)
    if (JSON.stringify(object) === '{}') object = [] // if the object is empty, force it to array

    // if the initial model is required, flag the objects as such
    if (props.initialModelRequired && object) {
      object = produce(object, (draft: any) => {
        for (let i = 0; i < draft.length; i++) if (draft[i]) draft[i].isRequired = true
      })
    }

    if (vs) errors = validate(object, vs)

    this.state = {
      type: 'OBJECT',
      id: `${props.model}-${uuid()}`,
      model: props.model,
      object: object,
      value: object,
      apiData: props.apiData ? props.apiData : [],
      isLoading: false,
      isRelations: props.isRelations,
      includeObject: props.includeObject,
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
      isPristine: true,
      isDirty: false,
      isTouched: false,
      isUntouched: true,
      isValidations: props.validations,
      isRequired: props.validations && props.validations.hasOwnProperty('presence'),
      errors: [],
      reset: this.onReset,
      validate: this.onValidate,
      highlight: this.onHighlight,
      scrollIntoView: this.scrollIntoView,
      //
      selectedRows: [],
    }

    this.initialData = {
      object: object,
      value: object,
      apiData: props.apiData ? props.apiData : [],
      isOpen: props.isOpen,
      isValid: errors.length ? false : true,
      isInvalid: errors.length ? true : false,
    }

    this.updateType = 'DATA'
    this.referenceRef = null
    this.fieldRef = null

    this.eventsQueue = []
    this.processedEventsQueue = []
  }

  /*
    CUSTOM FUNCTIONS
  */
  processChangeValue = (items: any) => {
    if (this.props.isDisabled) return

    const newState = produce(this.state, (draft: any) => {
      draft.object = items
      draft.value = items
      draft.isOpen = false

      draft.isDirty = true
      draft.isPristine = false
    })

    this.setState({
      object: newState.object,
      value: newState.value,
      isOpen: newState.isOpen,
      isDirty: newState.isDirty,
      isPristine: newState.isPristine,
    })

    return newState
  }

  onClear = () => {
    this.changeValue(null)
  }

  addSelectedRows = () => {
    const { value, selectedRows }: any = this.state

    if (!selectedRows) return

    const valueIds = value?.map?.((item: any) => item?.id) || []
    const newRows = [...(value || [])]

    for (const row of selectedRows) {
      if (!valueIds.includes(row?.id)) newRows.push(row)
    }

    this.changeValue(newRows)
    this.props.onSelect?.(newRows)
  }

  /*
    RENDER
  */
  renderEdit = () => {
    const { icon, isDisabled } = this.props

    return (
      <SelectorInput
        icon={icon}
        onOpen={this.open}
        onClear={() => {
          this.changeValue(null)
          if (this.props.onClear) this.props.onClear(this.state)
        }}
        isDisabled={isDisabled}
      />
    )
  }

  renderSelected = () => {
    const { showAvatars, selectTitle, selectDescription, selectGraphic, selectedDescriptionsVisible } = this.props
    const { object } = this.state

    return (
      size(object) > 0 && (
        <div className="!mt-1">
          {object.map((item: any) => (
            <MultiObjectItem
              key={item?.id}
              label={selectTitle?.(item) || item?.name}
              description={selectedDescriptionsVisible && selectDescription?.(item)}
              graphic={selectGraphic?.(item)}
              avatar={showAvatars ? item?.avatar || '' : item?.avatar}
              onClear={() => {
                const newState = produce(this.state, (draft: any) => {
                  remove(draft.object, { id: item?.id })
                  if (draft.apiData && draft.apiData[item?.id]) draft.apiData[item?.id].checked = false
                })

                this.changeValue(newState.object)
              }}
              isDisabled={item?.isRequired}
            />
          ))}
        </div>
      )
    )
  }

  renderReadOnly = () => {
    const { object } = this.state
    const { showAvatars, selectTitle, selectDescription, selectGraphic, disableLink, selectedDescriptionsVisible, setLink } = this.props

    if (object && object.length > 0) {
      return (
        <Grid gap={10}>
          {object.map((item: any) => {
            const graphic = selectGraphic?.(item)

            return (
              <Value
                avatarMagnify
                key={item.id}
                value={
                  <>
                    {selectTitle?.(item) || item?.name}
                    {selectedDescriptionsVisible && selectDescription?.(item)}
                  </>
                }
                avatarInitials={selectTitle?.(item) || item?.name}
                graphic={selectGraphic?.(item)}
                icon={!graphic && this.props.icon}
                avatar={showAvatars ? item.avatar || '' : item.avatar}
                avatarMagnifyScale={2.5}
                link={!disableLink && setLink ? setLink?.(item) : LINKS[this.props.type]?.(item)}
              />
            )
          })}
        </Grid>
      )
    } else return DEFAULT_EMPTY_VALUE
  }

  renderSelector = () => {
    const config = get(TABLES_CONFIG, this.props.type)

    if (!config?.component) return null

    const Table = config.component

    return (
      <Overlay showBackdrop stopPropagation position="center" maxWidth={100}>
        <Overlay.Header title={`Select ${plural(config?.title) || 'record'}…`} icon={config?.icon} />

        <Overlay.Content>
          <div className="p-4">
            <Table
              asCard
              canBatchSelect
              useMainCellSelect
              icon={config?.icon}
              tenant={this.props.tenant}
              dependentValue={this.props.dependentValue}
              onRowSelectionUpdate={(rows: any) => {
                this.setState({ selectedRows: rows })
              }}
              options={this.props.options}
              includeColumns={this.props.includeColumns}
              hiddenColumnIds={this.props.hiddenColumnIds}
              excludeRowIds={this.props.excludeRowIds}
            />
          </div>
        </Overlay.Content>

        <Overlay.Footer>
          <Button label="Add Selected" type="primary" color="green" onClick={this.addSelectedRows} />
        </Overlay.Footer>
      </Overlay>
    )
  }

  render() {
    const { isEditable, label, labelWidth, layout, showLabel, maxWidth, className, hide, description, withHover, isCompact, testKey } =
      this.props
    const { id, isOpen, isRequired, isValid, isLoading, isHighlighted, errors, value } = this.state

    if (hide) return null

    const classNames = clsx(className, 'ObjectSelector', { 'is-open': isOpen })

    const hasValue = size(value) > 0

    return (
      <Field
        getRef={(ref) => (this.fieldRef = ref)}
        className={classNames}
        errors={errors}
        id={id}
        isEditable={isEditable}
        isLoading={isLoading}
        isRequired={isRequired}
        isValid={isValid}
        isHighlighted={isHighlighted}
        label={label}
        showLabel={showLabel}
        labelWidth={labelWidth}
        layout={layout}
        maxWidth={maxWidth}
        description={description}
        withHover={withHover}
        testKey={testKey}
        isCompact={isCompact}
        labelAfter={this.props.labelAfter}
      >
        {isEditable ? (
          <>
            <SummonOverlay isOpen={isOpen} overlay={this.renderSelector()} onClose={this.close}>
              {this.renderEdit()}
            </SummonOverlay>

            {this.renderSelected()}

            {hasValue && (
              <div className="flex justify-end">
                <Button
                  glyph="close"
                  testKey="clear_all_button"
                  label="Clear All"
                  type="minimal"
                  color="red"
                  display="inline-flex"
                  size={200}
                  onClick={this.onClear}
                />
              </div>
            )}
          </>
        ) : (
          this.renderReadOnly()
        )}
      </Field>
    )
  }
}

MultiOverlaySelector.defaultProps = {
  showAs: 'inline',
  showAvatars: true,
  prefetch: false,
  searchKeys: ['name', 'description'],
  isEditable: true,
  isDisabled: false,
  initialModelRequired: false,
  layout: 'grid',
  isOpen: false,
  includeObject: false,
  isRelations: true,
  isPolymorphic: false,
  isNested: false,
  validateOn: 'blur-change',
}

export default withFormContext(MultiOverlaySelector)
