import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import withMarketing from '@behavehealth/hocs/withMarketing'
import useStore from '@behavehealth/modules/store'

import { Accordion, Alert, Button, Form, FormSection, Grid, Link, Page, SummonOverlay } from '@behavehealth/components'

import { useGet, useCreate, useUpdate, useDelete } from '@behavehealth/hooks/useNewAPI'
import { usDateTime, nicePhoneNo } from '@behavehealth/utils/functions'

import Notifications from '@behavehealth/modules/notifications'

import Flex from '@behavehealth/components/Flex'
import Avatar from '@behavehealth/components/Avatar'
import Card from '@behavehealth/components/Card'
import CardTitle from '@behavehealth/components/CardTitle'
import CardHeader from '@behavehealth/components/CardHeader'
import CardSubtitle from '@behavehealth/components/CardSubtitle'
import ConfirmDialog from '@behavehealth/components/Dialogs/ConfirmDialog'
import Loader from '@behavehealth/components/Loader'
import Divider from '@behavehealth/components/Divider'
import EmailInput from '@behavehealth/components/Forms/EmailInput'
import PhoneInput from '@behavehealth/components/Forms/PhoneInput'

import { Text } from '@behavehealth/components/Typography'

const pageConfig = {
  title: 'Client Portal',
  glyph: 'portal',
}

const STATUSES = {
  not_invited: 'not_invited',
  pending: 'pending',
  accepted: 'accepted',
  declined: 'declined',
  expired: 'expired',
  cancelled: 'cancelled',
}

const getLabelsForDetails = (details: any) => {
  let label = []

  if (!!details.email) label.push(`email (${details.email})`)
  if (!!details.phone_no) label.push(`cell number (${details.phone_no})`)

  return label.join(' and ')
}

export const Invites = ({ employee, user }: any) => {
  const match = useRouteMatch()
  const timezone = useStore((state) => state.tenant?.timezone)

  const invite = employee?.current_invite
  const inviteStatus = invite?.status || STATUSES.not_invited

  const { mutateAsync: revokeRecord, isLoading: isRevoking }: any = useCreate({
    name: ['employee', employee?.id, 'revoke'],
    url: `/employees/${employee?.id}/revoke_invites`,
    invalidate: 'employee',
    invalidateKeys: ['employee', 'employees', 'invites', 'invite'],
  })

  const { mutateAsync: asyncResend, isLoading: isResending }: any = useCreate({
    name: ['employee', employee?.current_invite?.id, 'resend'],
    url: `/invites/${employee?.current_invite?.id}/reprocess`,
    invalidate: 'employee',
    invalidateKeys: ['employee', 'employees', 'invites', 'invite'],
  })

  const resendInvite = async () => {
    try {
      await asyncResend()

      Notifications.send('New Invite Sent', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  const revokeInvite = async () => {
    try {
      await revokeRecord({ status: 'cancelled' })

      Notifications.send('Invite Revoked', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  if (user?.status === 'active' && inviteStatus === STATUSES.not_invited) {
    return (
      <Grid gap="0.5rem">
        <Alert type="positive" glyph="check">
          {employee?.first_name} has been invited to the EHR
        </Alert>

        <Alert type="positive" glyph="check">
          {employee?.first_name} has access to <Link href="https://myaccount.behavehealth.com">MyAccount</Link> and this EHR
        </Alert>
      </Grid>
    )
  }

  return (
    <>
      {inviteStatus === STATUSES.not_invited && (
        <>
          <Alert type="neutral" glyph="info">
            {employee?.first_name} is not invited to the EHR
          </Alert>

          <Button
            type="primary"
            color="blue"
            label={`Re-Invite ${employee?.first_name} to the EHR`}
            link={`${match.url}/invite-to-ehr`}
            glyph="portal"
            featureFlagV2="client_portal"
            permission="employees.actions.invite"
          />
        </>
      )}

      {inviteStatus === STATUSES.cancelled && (
        <Grid gap="0.5rem">
          <Alert type="neutral" glyph="info">
            {employee?.first_name}'s EHR Invite has been Revoked
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button label="Re-send Invite…" glyph="reset" color="blue" isLoading={isResending} permission="employees.actions.invite" />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Remove all Invites?`}
              message={`Are you sure you want to remove all invites?`}
              onYes={async () => {
                await revokeInvite()
              }}
            >
              <Button color="red" glyph="decline" label="Remove All Invites…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.pending && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {employee?.first_name} has been invited via {getLabelsForDetails(invite?.sent_to_details)} at{' '}
            <strong>{usDateTime(invite?.sent_at, timezone)}</strong>.{' '}
            <Link to={`/user-invites/staff/${employee?.current_invite.id}`}>View Invite</Link>
          </Alert>

          <Alert type="neutral" glyph="info">
            <strong>Next Step:</strong> {employee?.first_name} must accept the Invite by{' '}
            <strong>{usDateTime(invite?.expired_at, timezone)}</strong>, or a new Invite has to be sent.
          </Alert>

          <Divider />

          <Alert type="default" glyph="info">
            If you would like to change the <strong>{getLabelsForDetails(invite?.sent_to_details)}</strong> you used for the Invite, please
            "Clear Invites" below and then send a new invite.
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button
                label="Re-send this Invite…"
                glyph="reset"
                color="blue"
                isLoading={isResending}
                permission="employees.actions.invite"
              />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Clear Invites?`}
              message={`This is useful if you would like to use a different record to invite. Are you sure you want to clear all Invites for ${employee.first_name}?`}
              onYes={async () => {
                await revokeInvite()
              }}
            >
              <Button color="red" glyph="decline" label="Clear Invites…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.expired && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {employee?.first_name} has been invited via {getLabelsForDetails(invite?.sent_to_details)} at{' '}
            <strong>{usDateTime(invite?.sent_at, timezone)}</strong>.
          </Alert>

          <Alert type="neutral" glyph="time">
            The Invite expired on {usDateTime(invite?.expired_at, timezone)}
          </Alert>

          <Grid horizontal gap="0.5rem">
            <ConfirmDialog
              title="Re-send Invite?"
              message="Are you sure you want to re-send the Invite?"
              yesColor="green"
              onYes={resendInvite}
            >
              <Button label="Re-send Invite…" glyph="reset" color="blue" isLoading={isResending} permission="employees.actions.invite" />
            </ConfirmDialog>

            <ConfirmDialog
              glyph="behave_health"
              title={`Revoke Portal Invite?`}
              message={`Are you sure you want to revoke this invite?`}
              onYes={async () => {
                await revokeInvite()
              }}
            >
              <Button color="red" glyph="decline" label="Revoke Invite…" isLoading={isRevoking} />
            </ConfirmDialog>
          </Grid>
        </Grid>
      )}

      {inviteStatus === STATUSES.accepted && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {employee?.first_name} has been invited to the EHR on <strong>{usDateTime(invite?.sent_at, timezone)}</strong>
          </Alert>

          <Alert type="positive" glyph="check">
            {employee?.first_name} set up their account & accepted the Invite on{' '}
            <strong>{usDateTime(invite?.accepted_at, timezone)}</strong>
          </Alert>
        </Grid>
      )}

      {inviteStatus === STATUSES.declined && (
        <Grid gap="0.5rem">
          <Alert type="positive" glyph="check">
            {employee?.first_name} has been invited to the EHR on <strong>{usDateTime(invite?.sent_at, timezone)}</strong>
          </Alert>

          <Alert type="positive" glyph="cross">
            {employee?.first_name} set up their account but declined the Invite on{' '}
            <strong>{usDateTime(invite?.accepted_at, timezone)}</strong>
          </Alert>

          <Alert type="positive">Decline reason: {invite?.declined_reason || 'No reason provided'}</Alert>
        </Grid>
      )}
    </>
  )
}

export const UserAccess = ({ employee, user }: any) => {
  const { mutateAsync: removeUserAccess, isLoading: isRemoving }: any = useDelete({
    name: ['employee', employee?.id, 'user_access'],
    url: `/employees/${employee?.id}/user_access`,
    invalidate: 'employee',
    invalidateKeys: ['employee', 'employees', 'invites', 'invite'],
  })

  const removeUser = async () => {
    try {
      await removeUserAccess()

      Notifications.send('User removed successfully from Staff', 'positive')
    } catch (error) {
      console.debug(error)
    }
  }

  const isAdministrator = ['owner', 'administrator'].includes(user?.position)

  if (!user)
    return (
      <Alert type="neutral" glyph="info">
        {employee?.first_name} has not registered for their MyAccount yet
      </Alert>
    )

  if (user?.status === 'blocked') {
    return (
      <Grid gap="1rem">
        <Alert glyph="warning" type="negative">
          {employee?.first_name}'s access to the EHR has been revoked
        </Alert>
      </Grid>
    )
  }

  if (user?.status === 'active') {
    return (
      <Grid gap="1rem">
        <Alert type="positive" glyph="check">
          {employee?.first_name} has successfully registered and has access to{' '}
          <Link href="https://myaccount.behavehealth.com">MyAccount</Link> and this EHR, with the following Login Details:
        </Alert>

        <Card baseline={'70px'} className="!p-1">
          <CardHeader contentGap="0.5rem" graphic={<Avatar magnify size={48} initials={user.name} />}>
            <CardTitle title="User Details" />
            <CardSubtitle
              subtitle={
                <Grid gap="0.5rem">
                  <Text
                    glyph="email"
                    label="Email Address: "
                    muted={false}
                    description={user.email || "Not set - can't login with Email"}
                  />
                  <Text
                    glyph="phone"
                    label="Phone Number: "
                    muted={false}
                    description={user.phone_no || "Not set - can't login with Cell Number"}
                  />
                  <Text glyph="not_verified_person" label="ID: " muted={false} description={user.external_id} />
                </Grid>
              }
            />
          </CardHeader>
        </Card>

        <Grid vertical gap="0.5rem">
          <Flex gap="1rem" stretchChildrenX>
            {user?.can_do_magic_link && (
              <Button
                label="Send Magic Login"
                emoji="🔮"
                color="pink"
                link={{
                  pathname: `${location.pathname}/magic-login`,
                  parent: location.pathname,
                }}
              />
            )}

            {user?.can_set_password && (
              <Button
                label="Update Password"
                emoji="🔓"
                type="default"
                color="green"
                permission={isAdministrator}
                link={{
                  pathname: `${location.pathname}/update-password`,
                  parent: location.pathname,
                }}
              />
            )}
          </Flex>

          <Divider className="!my-1 !mx-0" />

          <Alert type="default" glyph="info">
            If Staff does not have access to the login credentials (Email [{user.email}] or Cell Number [{nicePhoneNo(user.phone_no)}]), use
            the button below to <strong>Reset Login Credentials</strong> and then send a new Invite.
          </Alert>

          <ConfirmDialog
            glyph="behave_health"
            title={`Remove User Account from ${employee.first_name}?`}
            message="
              Step 1. After Resetting the Login Credentials update the User Details.
              Step 2. Re-Invite with the new details.
              Step 3. Confirm they have access.
            "
            onYes={removeUser}
          >
            <Button
              type="primary"
              color="red"
              glyph="decline"
              label={`Reset Login Credentials…`}
              isLoading={isRemoving}
              permission={isAdministrator}
            />
          </ConfirmDialog>
        </Grid>
      </Grid>
    )
  }

  return (
    <Alert type="neutral" glyph="info">
      {employee?.first_name} has not registered for their MyAccount yet
    </Alert>
  )
}

const AccountAccess = () => {
  const match = useRouteMatch()

  const { data: employee }: any = useGet({
    name: ['employee', match.params.resource_id],
    url: `/employees/${match.params.resource_id}`,
  })

  const { data: user, isLoading: isUserLoading }: any = useGet({
    name: ['employee', match.params.resource_id, 'user'],
    url: `/employees/${match.params.resource_id}/user`,
  })

  const { mutateAsync: updateEmployee }: any = useUpdate({
    name: ['employee', match.params.resource_id],
    url: `/employees/${match.params.resource_id}`,
    invalidate: ['employee', 'employees'],
  })

  if (!employee) return null

  return (
    <Page title="Login Info" glyph="portal">
      <Grid gap="0.5rem">
        <Accordion
          isOpen
          activateEditMode
          initialModel={employee}
          title="Step 1 – General Info / Contact Details"
          glyph="contact_details"
          permission="employees.general_info.personal_details.view"
          editPermission="employees.general_info.personal_details.edit"
          onSubmit={updateEmployee}
        >
          <Form>
            <FormSection labelWidth="200px" maxWidth="700px">
              <EmailInput horizontal label="Email Address" model="email" />
              <PhoneInput horizontal label="Cell Number" model="phone_no" />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion isOpen initialModel={employee} glyph="portal" title="Step 2 – Invite Details">
          <Form>
            <FormSection maxWidth="550px">
              <Invites employee={employee} user={user} />
            </FormSection>
          </Form>
        </Accordion>

        <Accordion isOpen initialModel={employee} glyph="lock" title="Step 3 – Registration / User Account">
          <Form>
            <FormSection maxWidth="550px">
              {isUserLoading ? (
                <Flex centerX centerY>
                  <Loader />
                </Flex>
              ) : (
                <UserAccess employee={employee} user={user} />
              )}
            </FormSection>
          </Form>
        </Accordion>
      </Grid>
    </Page>
  )
}

export default withMarketing(AccountAccess, pageConfig)
