export const CIRCLE_HELP_PAGES: any = {
  active_plans_bridge: {
    name: 'Active Plans – Bridge',
    slug: 'active-plans-bridge',
  },
  active_plans_chat: {
    name: 'Active Plans – Chat',
    slug: 'active-plans-chat',
  },
  active_plans_ehr: {
    name: 'Active Plans – Ehr',
    slug: 'active-plans-ehr',
  },
  active_plans_healthcare_account_setup: {
    name: 'Active Plans – Healthcare Account Setup',
    slug: 'active-plans-healthcare-account-setup',
  },
  active_plans_healthcare_main_plan: {
    name: 'Active Plans – Healthcare Main Plan',
    slug: 'active-plans-healthcare-main-plan',
  },
  active_plans_rcm_consumer: {
    name: 'Active Plans – Rcm Consumer',
    slug: 'active-plans-rcm-consumer',
  },
  active_plans_rcm_insurance: {
    name: 'Active Plans – Rcm Insurance',
    slug: 'active-plans-rcm-insurance',
  },
  active_plans_support_account_setup: {
    name: 'Active Plans – Support Account Setup',
    slug: 'active-plans-support-account-setup',
  },
  active_plans_support_main_plan: {
    name: 'Support Main Plan',
    slug: 'active-plans-support-main-plan',
  },
  active_plans_training: {
    name: 'Active Plans – Training',
    slug: 'active-plans-training',
  },
  admissions: {
    name: 'Admissions',
    slug: 'admissions',
  },
  admissions_accepted: {
    name: 'Admissions – Accepted',
    slug: 'admissions-accepted',
  },
  admissions_applicants: {
    name: 'Admissions – Applicants',
    slug: 'admissions-applicants',
  },
  admissions_declined: {
    name: 'Admissions – Declined',
    slug: 'admissions-declined',
  },
  agreements: {
    name: 'Agreements',
    slug: 'agreements',
  },
  alumni: {
    name: 'Past Client',
    slug: 'alumni',
  },
  bed_management: {
    name: 'Bed Management',
    slug: 'bed-management',
  },
  bed_management_calendar: {
    name: 'Bed Management Calendar',
    slug: 'bed-management-calendar',
  },
  bed_management_room_management: {
    name: 'Bed Management Room Management',
    slug: 'bed-management-room-management',
  },
  bridge: {
    name: 'Bridge',
    slug: 'bridge',
  },
  client_applications: {
    name: 'Client Applications',
    slug: 'client-applications',
  },
  client_care_team: {
    name: 'Client Care Team',
    slug: 'client-care-team',
  },
  client_contacts_and_communications: {
    name: 'Client Contacts And Communications',
    slug: 'client-contacts-and-communications',
  },
  client_dashboard: {
    name: 'Client Dashboard',
    slug: 'client-dashboard',
  },
  client_financials: {
    name: 'Client Financials',
    slug: 'client-financials',
  },
  client_general_info: {
    name: 'Client General Info',
    slug: 'client-general-info',
  },
  client_programs: {
    name: 'Client Programs',
    slug: 'client-programs',
  },
  clients: {
    name: 'Clients',
    slug: 'clients',
  },
  clients_chat: {
    name: 'Clients Chat',
    slug: 'clients-chat',
  },
  clinical_assessments: {
    name: 'Clinical Assessments',
    slug: 'clinical-assessments',
  },
  clinical_assessments_biopsychosocial_assessment: {
    name: 'Clinical Assessments – Biopsychosocial Assessment',
    slug: 'clinical-assessments-biopsychosocial-assessment',
  },
  clinical_assessments_prescreen: {
    name: 'Clinical Assessments – Pre-Screen',
    slug: 'clinical-assessments-prescreen',
  },
  clinical_assessments_suicide_assessment: {
    name: 'Clinical Assessments – Suicide Assessment',
    slug: 'clinical-assessments-suicide-assessment',
  },
  clinical_notes: {
    name: 'Clinical Notes',
    slug: 'clinical-notes',
  },
  clinical_notes_case_management: {
    name: 'Clinical Notes – Case Management',
    slug: 'clinical-notes-case-management',
  },
  clinical_notes_family_session: {
    name: 'Clinical Notes – Family Session',
    slug: 'clinical-notes-family-session',
  },
  clinical_notes_group: {
    name: 'Clinical Notes – Group Note',
    slug: 'clinical-notes-group',
  },
  clinical_notes_individual: {
    name: 'Clinical Notes – Individual Note',
    slug: 'clinical-notes-individual',
  },
  company_chat: {
    name: 'Staff Chat',
    slug: 'company-chat',
  },
  company_chat_message_blasts: {
    name: 'Staff Chat – Message Blasts',
    slug: 'company-chat-message-blasts',
  },
  company_files: {
    name: 'Company Files',
    slug: 'company-files',
  },
  company_todos: {
    name: 'Company Todos',
    slug: 'company-todos',
  },
  dashboard: {
    name: 'Dashboard',
    slug: 'dashboard',
  },
  discharges: {
    name: 'Discharges',
    slug: 'discharges',
  },
  employee_general_info: {
    name: 'Staff General Info',
    slug: 'employee_general_info',
  },
  employees: {
    name: 'Staff',
    slug: 'employees',
  },
  files: {
    name: 'Files',
    slug: 'files',
  },
  insurance_and_vob: {
    name: 'Insurance And Vob',
    slug: 'insurance-and-vob',
  },
  intake_forms: {
    name: 'Intake Forms',
    slug: 'intake-forms',
  },
  location_check_in: {
    name: 'Location Check In',
    slug: 'location-check-in',
  },
  medical_assessments: {
    name: 'Medical Assessments',
    slug: 'medical-assessments',
  },
  medical_assessments_history_and_physical: {
    name: 'Medical Assessments – History and Physical',
    slug: 'medical-assessments-history-and-physical',
  },
  medical_assessments_history_and_physical_follow_up: {
    name: 'Medical Assessments – History and Physical Follow Up',
    slug: 'medical-assessments-history-and-physical-follow-up',
  },
  medical_assessments_psychiatric_assessment: {
    name: 'Medical Assessments – Psychiatric Assessment',
    slug: 'medical-assessments-psychiatric-assessment',
  },
  medication_list_and_logs: {
    name: 'Medication List And Logs',
    slug: 'medication-list-and-logs',
  },
  message_board_client_portal: {
    name: 'Message Board – Client Portal',
    slug: 'message-board-client-portal',
  },
  message_board_internal: {
    name: 'Message Board – Internal',
    slug: 'message-board-internal',
  },
  nursing: {
    name: 'Nursing',
    slug: 'nursing',
  },
  nursing_assessment: {
    name: 'Nursing Assessment',
    slug: 'nursing-assessment',
  },
  nursing_assessment_advance_directive_power_of_attorney: {
    name: 'Nursing Assessment – Advance Directive/Power of Attorney',
    slug: 'nursing-assessment-advance-directive-power-of-attorney',
  },
  nursing_assessment_ciwa_symptoms: {
    name: 'Nursing Assessment – CIWA Symptoms',
    slug: 'nursing-assessment-ciwa-symptoms',
  },
  nursing_assessment_coordination_of_care: {
    name: 'Nursing Assessment – Coordination of Care',
    slug: 'nursing-assessment-coordination-of-care',
  },
  nursing_assessment_cows_symptoms: {
    name: 'Nursing Assessment – COWS Symptoms',
    slug: 'nursing-assessment-cows-symptoms',
  },
  nursing_assessment_current_diagnosis: {
    name: 'Nursing Assessment – Current Diagnosis',
    slug: 'nursing-assessment-current-diagnosis',
  },
  nursing_assessment_medical_evaluation: {
    name: 'Nursing Assessment – Medical Evaluation',
    slug: 'nursing-assessment-medical-evaluation',
  },
  nursing_assessment_mental_status_exam: {
    name: 'Nursing Assessment – Mental Status Exam',
    slug: 'nursing-assessment-mental-status-exam',
  },
  nursing_assessment_past_medical_history: {
    name: 'Nursing Assessment – Past Medical History',
    slug: 'nursing-assessment-past-medical-history',
  },
  nursing_assessment_prosthesis_appliance_inventory: {
    name: 'Nursing Assessment – Prosthesis / Appliance Inventory',
    slug: 'nursing-assessment-prosthesis-appliance-inventory',
  },
  nursing_assessment_summary: {
    name: 'Nursing Assessment – Summary',
    slug: 'nursing-assessment-summary',
  },
  nursing_assessment_test_results: {
    name: 'Nursing Assessment – Test Results',
    slug: 'nursing-assessment-test-results',
  },
  nursing_assessment_treatment_history: {
    name: 'Nursing Assessment – Treatment History',
    slug: 'nursing-assessment-treatment-history',
  },
  nursing_assessment_vital_signs: {
    name: 'Nursing Assessment – Vital Signs',
    slug: 'nursing-assessment-vital-signs',
  },
  nursing_ciwa: {
    name: 'Nursing CIWA',
    slug: 'nursing-ciwa',
  },
  nursing_ciwa_symptoms: {
    name: 'Nursing CIWA – Symptoms',
    slug: 'nursing-ciwa-symptoms',
  },
  nursing_ciwa_vital_signs: {
    name: 'Nursing CIWA – Vital Signs',
    slug: 'nursing-ciwa-vital-signs',
  },
  nursing_cows: {
    name: 'Nursing COWS',
    slug: 'nursing-cows',
  },
  nursing_cows_symptoms: {
    name: 'Nursing COWS – Symptoms',
    slug: 'nursing-cows-symptoms',
  },
  nursing_cows_vital_signs: {
    name: 'Nursing COWS – Vital Signs',
    slug: 'nursing-cows-vital-signs',
  },
  nursing_fall_risk_assessment: {
    name: 'Nursing Fall Risk Assessment',
    slug: 'nursing-fall-risk-assessment',
  },
  nursing_progress_note: {
    name: 'Nursing Progress Note',
    slug: 'nursing-progress-note',
  },
  nursing_reassessment: {
    name: 'Nursing Re-assessment',
    slug: 'nursing-reassessment',
  },
  nursing_reassessment_medical_evaluation: {
    name: 'Nursing Re-assessment – Medical Evaluation',
    slug: 'nursing-reassessment-medical-evaluation',
  },
  nursing_reassessment_mental_status_exam: {
    name: 'Nursing Re-assessment – Mental Status Exam',
    slug: 'nursing-reassessment-mental-status-exam',
  },
  nursing_reassessment_summary: {
    name: 'Nursing Re-assessment – Summary',
    slug: 'nursing-reassessment-summary',
  },
  nursing_reassessment_vital_signs: {
    name: 'Nursing Re-assessment – Vital Signs',
    slug: 'nursing-reassessment-vital-signs',
  },
  office_general_info: {
    name: 'Office General Info',
    slug: 'office-general-info',
  },
  offices: {
    name: 'Offices',
    slug: 'offices',
  },
  organization_contacts_and_communications: {
    name: 'Organization Contacts And Communications',
    slug: 'organization-contacts-and-communications',
  },
  organization_general_info: {
    name: 'Organization General Info',
    slug: 'organization-general-info',
  },
  organizations: {
    name: 'Organizations',
    slug: 'organizations',
  },
  payer_address_book: {
    name: 'Payer Address Book',
    slug: 'payer-address-book',
  },
  payer_authorizations: {
    name: 'Payer Authorizations',
    slug: 'payer-authorizations',
  },
  payer_benefits: {
    name: 'Payer Benefits',
    slug: 'payer-benefits',
  },
  payer_clients: {
    name: 'Payer Clients',
    slug: 'payer-clients',
  },
  payer_contacts: {
    name: 'Payer Contacts',
    slug: 'payer-contacts',
  },
  payer_faxes_and_voicemails: {
    name: 'Payer Faxes And Voicemails',
    slug: 'payer-faxes-and-voicemails',
  },
  payer_files: {
    name: 'Payer Files',
    slug: 'payer-files',
  },
  payer_general_info: {
    name: 'Payer General Info',
    slug: 'payer-general-info',
  },
  payer_phone_book: {
    name: 'Payer Phone Book',
    slug: 'payer-phone-book',
  },
  payer_rules: {
    name: 'Payer Rules',
    slug: 'payer-rules',
  },
  payers: {
    name: 'Payers',
    slug: 'payers',
  },
  physician_orders: {
    name: 'Physician Orders',
    slug: 'physician-orders',
  },
  programs: {
    name: 'Programs',
    slug: 'programs',
  },
  progress_review_assignments_and_appointments: {
    name: 'Assignments & Appointments',
    slug: 'progress-review-assignments-and-appointments',
  },
  progress_review_bio_medical: {
    name: 'Bio-Medical',
    slug: 'progress-review-bio-medical',
  },
  progress_review_current_mental_status: {
    name: 'Current Mental Status',
    slug: 'progress-review-current-mental-status',
  },
  progress_review_general_info: {
    name: 'General Info',
    slug: 'progress-review-general-info',
  },
  progress_review_medications: {
    name: 'Medications',
    slug: 'progress-review-medications',
  },
  progress_review_motivation_for_change: {
    name: 'Motivation for Change',
    slug: 'progress-review-motivation-for-change',
  },
  progress_review_recovery_environment: {
    name: 'Recovery Environment',
    slug: 'progress-review-recovery-environment',
  },
  progress_review_relapse_potential: {
    name: 'Relapse Potential',
    slug: 'progress-review-relapse-potential',
  },
  progress_review_suicide_and_safety_risk: {
    name: 'Suicide & Safety Risk',
    slug: 'progress-review-suicide-and-safety-risk',
  },
  progress_review_summary: {
    name: 'Summary',
    slug: 'progress-review-summary',
  },
  progress_review_withdrawal_post_acute_symptoms: {
    name: 'Withdrawal / Post-Acute Symptoms',
    slug: 'progress-review-withdrawal-post-acute-symptoms',
  },
  progress_reviews: {
    name: 'Progress Reviews',
    slug: 'progress-reviews',
  },
  properties: {
    name: 'Locations',
    slug: 'properties',
  },
  property_general_info: {
    name: 'Location General Info',
    slug: 'property-general-info',
  },
  rcm_reports: {
    name: 'Rcm Reports',
    slug: 'rcm-reports',
  },
  recovery_coaching: {
    name: 'Recovery Coaching',
    slug: 'recovery-coaching',
  },
  reports: {
    name: 'Reports',
    slug: 'reports',
  },
  settings_active_plans: {
    name: 'Settings Active Plans',
    slug: 'settings-active-plans',
  },
  settings_agreement_templates: {
    name: 'Settings Agreement Templates',
    slug: 'settings-agreement-templates',
  },
  settings_application_form: {
    name: 'Settings Application Form',
    slug: 'settings-application-form',
  },
  settings_billing_settings: {
    name: 'Settings Billing Settings',
    slug: 'settings-billing-settings',
  },
  settings_company_settings: {
    name: 'Settings Company Settings',
    slug: 'settings-company-settings',
  },
  settings_legal_agreement: {
    name: 'Settings Legal Agreement',
    slug: 'settings-legal-agreement',
  },
  settings_payments: {
    name: 'Settings Payments',
    slug: 'settings-payments',
  },
  settings_permissions: {
    name: 'Settings Permissions',
    slug: 'settings-permissions',
  },
  settings_permission_templates: {
    name: 'Settings Permission Templates',
    slug: 'settings-permission-templates',
  },
  settings_services_book: {
    name: 'Settings Services Book',
    slug: 'settings-services-book',
  },
  settings_your_settings: {
    name: 'Settings Your Settings',
    slug: 'settings-your-settings',
  },
  shift_notes: {
    name: 'Shift Notes',
    slug: 'shift-notes',
  },
  test_results: {
    name: 'Test Results',
    slug: 'test-results',
  },
  test_results_breathalyzer_test: {
    name: 'Test Results – Breathalyzer Test',
    slug: 'test-results-breathalyzer-test',
  },
  test_results_communicable_disease_test: {
    name: 'Test Results – Communicable Disease Test',
    slug: 'test-results-communicable-disease-test',
  },
  test_results_ua_test: {
    name: 'Test Results – UA Test',
    slug: 'test-results-ua-test',
  },
  test_results_vital_signs: {
    name: 'Test Results – Vital Signs',
    slug: 'test-results-vital-signs',
  },
  todos: {
    name: 'Todos',
    slug: 'todos',
  },
  treatment_episodes: {
    name: 'Treatment Episodes',
    slug: 'treatment-episodes',
  },
  treatment_plans: {
    name: 'Treatment Plans',
    slug: 'treatment-plans',
  },
  custom_notes: {
    name: 'Custom Notes',
    slug: 'custom-notes',
  },
  staff_ip_restrictions: {
    name: 'Staff Ip Restrictions',
    slug: 'staff-ip-restrictions',
  },
  client_profile: {
    name: 'Client Profile',
    slug: 'client-profile',
  },
  permission_templates: {
    name: 'Permission Templates',
    slug: 'permission-templates',
  },
  diagnoses: {
    name: 'Diagnoses',
    slug: 'diagnoses',
  },
  locations_sublocations: {
    name: 'Locations',
    slug: 'locations',
  },
  locations_floors: {
    name: 'Floors',
    slug: 'floors',
  },
}
