import React from 'react'

import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'

import { SERVICE_CATEGORIES } from '../../utils/constants'
import { RECURRING_DAYS, RECURRING_FREQUENCIES } from '../../utils/recurrence'

import { niceAmount, usDate, isPast, DT } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import Chotomate from '../../components/Chotomate'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Grid from '../../components/Grid'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Status from '../../components/Status'

import AmountInput from '../../components/Forms/AmountInput'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import ContextShow from '../../components/Forms/ContextShow'
import DateInput from '../../components/Forms/DateInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import OverlaySelector from '../../components/Forms/Selectors/OverlaySelector/OverlaySelector'
import Radio from '../../components/Forms/Radio'
import RadioGroup from '../../components/Forms/RadioGroup'
import Select from '../../components/Forms/Select'
import Textarea from '../../components/Forms/Textarea'

import { niceOccurrenceText } from '../Financials/utils'
import PreviewRecurringChargesSection from '../Financials/components/PreviewRecurringChargesSection'

const FREQUENCIES = {
  weekly: 'recurring_weekly_due_day',
  biweekly: 'recurring_biweekly_due_day',
  monthly: 'recurring_monthly_due_day',
}

const RootOrganizationChargeBuilderOverlay = (props: any) => {
  const { close, form, record, initialModel, isInvalid, isSaving, onValidationUpdate, save, isOverlayLoading } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions/charge_builder',
    invalidate: 'financial-transactions',
    options: props,
    parentType: 'organization',
    parentRequest: '/organizations',
  })

  const [formData, setFormData] = React.useState(initialModel)
  const [service, setService] = React.useState(null)
  const [useCustomFrequency, setUseCustomFrequency] = React.useState(false)
  const [recurringDates, setRecurringDates] = React.useState([])

  const { timezone } = useSettings()

  const employees = service?.employees
  const organizations = service?.organizations
  const isRecurring = service?.is_recurring
  const hasNoCharges = recurringDates?.length === 0

  const hasEmployees = size(employees) > 0
  const hasOrganizations = size(organizations) > 0

  if (isOverlayLoading || size(record) === 0) return <OverlayLoader />

  return (
    <Overlay onClose={close} showBackdrop isDirty>
      <Overlay.Header icon="financials" title="Add Charges" />

      <Overlay.Content>
        <Chotomate ready name="charge_builder_overlay" />
        {record?.financial_plan ? (
          <Form
            useFullModel
            getForm={form}
            timezone={timezone}
            onValidationUpdate={onValidationUpdate}
            onUpdate={setFormData}
            linked={{
              category: 'charge',
              financial_plan_id: record?.financial_plan?.id,
              end_in_months: 1,
            }}
          >
            {/* General */}
            <Section>
              <FormSection>
                <ObjectSelector
                  model="service"
                  label="Organization Service"
                  type="organization_services"
                  sortBy={['order', 'created_at']}
                  icon={ICONS.financials}
                  selectTitle={(data) => data.name}
                  selectDescription={(data) => (
                    <Flex gap={6} alignItems="center">
                      {SERVICE_CATEGORIES[data.category] && <Status label={SERVICE_CATEGORIES[data.category].label} color="green" />}
                      <span>
                        {niceAmount(data.amount)}{' '}
                        {niceOccurrenceText(data?.is_recurring, data?.recurring_frequency, data?.[FREQUENCIES[data?.recurring_frequency]])}
                      </span>
                    </Flex>
                  )}
                  validations={{
                    presence: {
                      message: 'Please select a service',
                    },
                  }}
                  emptyActions={
                    <Button
                      label="Manage Services Book"
                      link="/settings/organization-services"
                      glyph="settings"
                      featureFlag="organization_services"
                      permission="settings.services_book.view"
                    />
                  }
                  onUpdate={(model: any) => {
                    setService(model.object)
                  }}
                />

                {!service && <Alert glyph="info">Select a Service above to start</Alert>}

                {service && (
                  <>
                    {!formData?.is_custom_amount && (
                      <AmountInput
                        isEditable={false}
                        label="Amount"
                        suffix={niceOccurrenceText(
                          service?.is_recurring,
                          useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                          useCustomFrequency
                            ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                            : service?.[FREQUENCIES[service?.recurring_frequency]],
                        )}
                        value={service?.amount}
                      />
                    )}

                    {formData?.is_custom_amount && (
                      <AmountInput
                        debounce={300}
                        label="Custom Amount"
                        model="amount"
                        suffix={niceOccurrenceText(
                          service?.is_recurring,
                          useCustomFrequency ? formData?.recurring_frequency : service?.recurring_frequency,
                          useCustomFrequency
                            ? formData?.[FREQUENCIES[formData?.recurring_frequency]]
                            : service?.[FREQUENCIES[service?.recurring_frequency]],
                        )}
                        defaultValue={service?.amount}
                        validations={{
                          presence: {
                            message: 'Please enter an amount',
                          },
                          numericality: {
                            greaterThan: 0,
                            message: 'Please enter an amount',
                          },
                        }}
                      />
                    )}

                    <Flex gap="1rem" stretchChildrenX>
                      <Checkbox label="Set Custom Amount" model="is_custom_amount" />
                      {isRecurring && (
                        <Checkbox
                          label="Set Custom Frequency"
                          model="use_custom_frequency"
                          onUpdate={(model: any) => {
                            setUseCustomFrequency(model.value)
                          }}
                        />
                      )}
                    </Flex>

                    {useCustomFrequency && (
                      <>
                        <RadioGroup
                          label="Repeating Frequency"
                          model="recurring_frequency"
                          layout="vertical-dense"
                          defaultValue={service?.recurring_frequency || 'monthly'}
                          validations={{
                            presence: {
                              message: 'Please select a billing frequency',
                            },
                          }}
                        >
                          {Object.keys(RECURRING_FREQUENCIES).map((key) => (
                            <Radio key={key} label={RECURRING_FREQUENCIES[key].label} value={key} />
                          ))}
                        </RadioGroup>

                        <ContextShow when="recurring_frequency" is="weekly">
                          <Select
                            asNumber
                            label="Weekly Due Day"
                            model="recurring_weekly_due_day"
                            defaultValue={service?.recurring_weekly_due_day || 0}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.weekly.map((key, idx) => (
                              <Option key={idx} label={`Every ${key}`} value={idx} />
                            ))}
                          </Select>
                        </ContextShow>

                        <ContextShow when="recurring_frequency" is="biweekly">
                          <Select
                            asNumber
                            label="Biweekly Due Day"
                            model="recurring_biweekly_due_day"
                            defaultValue={service?.recurring_biweekly_due_day || 0}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.biweekly.map((key, idx) => (
                              <Option key={idx} label={`Every other ${key}`} value={idx} />
                            ))}
                          </Select>
                        </ContextShow>

                        <ContextShow when="recurring_frequency" is="monthly">
                          <Select
                            asNumber
                            label="Monthly Due Day"
                            model="recurring_monthly_due_day"
                            defaultValue={service?.recurring_monthly_due_day || 1}
                            validations={{
                              presence: {
                                message: 'Please select a due day',
                              },
                            }}
                          >
                            {RECURRING_DAYS.monthly.map((key, idx) => (
                              <Option key={idx} label={key} value={idx + 1} />
                            ))}
                          </Select>
                        </ContextShow>
                      </>
                    )}

                    {!isRecurring ? (
                      <>
                        <DateInput label="Charge Date" model="transactioned_at" defaultToNow />
                      </>
                    ) : (
                      <FormSection>
                        <DateInput
                          defaultToNow
                          label="Starting From"
                          model="transactioned_at"
                          validations={{
                            presence: {
                              message: 'Please add a start date',
                            },
                          }}
                        />
                      </FormSection>
                    )}

                    {hasEmployees && (
                      <OverlaySelector
                        model="employees"
                        label="Staff Member"
                        type="employees.active"
                        apiData={employees}
                        icon={ICONS.employees}
                        selectTitle={(data: any) => data.name}
                        selectDescription={(data: any) => startCase(data.position)}
                      />
                    )}

                    {hasOrganizations && (
                      <ObjectSelector
                        model="organizations"
                        label="Organization"
                        type="organizations"
                        apiData={organizations}
                        icon={ICONS.organizations}
                        selectTitle={(data: any) => data.name}
                        selectDescription={() => null}
                      />
                    )}
                  </>
                )}
              </FormSection>
            </Section>

            {service && (
              <>
                <Section>
                  <Grid gap={8}>
                    <Textarea useQuickText label="Notes" model="notes" />
                  </Grid>
                </Section>

                <Divider />

                <Section title="Automatic Collection">
                  <FormSection maxWidth="100%">
                    <Grid gap={8}>
                      <CheckboxGroup>
                        <Checkbox
                          label="Use Automatic Payment Collection"
                          model="should_auto_charge"
                          description="Attempt to collect from the Payment Method selected below"
                        />
                      </CheckboxGroup>

                      {formData?.should_auto_charge && (
                        <>
                          {isRecurring ? (
                            <>
                              {isPast(formData.transactioned_at, timezone) ? (
                                <Alert type="negative" glyph="info">
                                  We'll attempt to capture all Back-Dated Charges on Save
                                </Alert>
                              ) : (
                                <Alert type="default" glyph="info">
                                  We'll attempt to capture Planned Charges starting from{' '}
                                  <b>{usDate(formData.transactioned_at, timezone)}</b>
                                </Alert>
                              )}
                            </>
                          ) : (
                            <>
                              {isPast(formData.transactioned_at, timezone) ? (
                                <Alert type="negative" glyph="info">
                                  <strong>Charge Date</strong> is Today or in the Past. We'll attempt to capture Payment on Save
                                </Alert>
                              ) : (
                                <Alert type="default" glyph="info">
                                  We'll attempt to capture Payment on <b>{usDate(formData.transactioned_at, timezone)}</b>
                                </Alert>
                              )}
                            </>
                          )}
                        </>
                      )}

                      <ContextShow when="should_auto_charge" is={true}>
                        <ObjectSelector
                          icon="billing"
                          type="organization.payment_methods"
                          label="Payment Method to Charge"
                          model="payment_method"
                          dependentValue={record?.id}
                          selectTitle={(data: any) => data?.name}
                          selectDescription={(data: any) => data?.reference?.name}
                          validations={{
                            presence: {
                              message: 'Please select a Payment Method to use',
                            },
                          }}
                        />

                        <Alert type="warning" glyph="info">
                          Any captured payments must be refunded through Stripe
                        </Alert>
                      </ContextShow>
                    </Grid>
                  </FormSection>
                </Section>

                <Divider />

                {/* Auto-repeat */}
                {isRecurring && (
                  <>
                    <PreviewRecurringChargesSection
                      usingCustomFrequency={useCustomFrequency}
                      from={formData?.transactioned_at}
                      endIn={formData?.end_in_months}
                      serviceID={service?.id}
                      timezone={timezone}
                      amount={formData?.is_custom_amount ? formData?.amount : service?.amount}
                      onUpdate={(dates: any) => setRecurringDates(dates)}
                      onClear={() => setRecurringDates([])}
                    />

                    <Divider />
                  </>
                )}
              </>
            )}
          </Form>
        ) : (
          <Alert type="negative" className="!m-4">
            The data required to create <strong>Charges</strong> could not be loaded from our servers. To try again, please close this
            Overlay and refresh. If this problem persists, please get in touch with Support.
          </Alert>
        )}
      </Overlay.Content>

      <Overlay.Footer>
        <Button
          label="Save Charges"
          glyph="check"
          type="primary"
          color="green"
          flex="100 1 auto"
          onClick={save}
          isLoading={isSaving}
          isDisabled={isInvalid || (isRecurring && hasNoCharges)}
          permission="ledger.create"
        />
      </Overlay.Footer>
    </Overlay>
  )
}

export const AuthorityChargeBuilderOverlay = withOverlayError(RootOrganizationChargeBuilderOverlay)
