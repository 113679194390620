import React from 'react'
import { useRouteMatch } from 'react-router-dom'

import { fullname, titleCase } from '../../utils/functions'
import { RELATIONSHIP_TO_CLIENT_CODE } from '../../components/RCM/constants'
import { useDataTable } from '../../components/DataTable/useDataTable'

import { DataTable } from '../../components/DataTable/DataTable'
import { InsurancePolicyOverlay } from './InsurancePolicyOverlay'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Status from '../../components/Status'
import SummonOverlay from '../../components/SummonOverlay'
import Permission from '../../components/Permission'

const RootInlineInsurancePoliciesDataTable = (props) => {
  const match = useRouteMatch()
  const { resource_id }: any = match.params

  const [openInsuranceId, setOpenInsuranceId] = React.useState(null)

  const clientId = props.clientId || resource_id

  const tableProps = useDataTable({
    name: ['client', clientId, 'insurance_policies'],
    endpoint: `/residents/${clientId}/insurance_policies`,
    updateDeleteEndpoint: '/insurance_policies',
    enabled: !!clientId,
    localStorageKey: 'inline_insurance_policies_v1',
    pageSize: 10,
  })

  const columns = React.useMemo(
    () => [
      {
        title: 'Insurance Payer',
        model: 'insurance_local_payer',
        width: 300,
        disableHide: true,
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} value={value?.name} onClick={() => setOpenInsuranceId(data.id)} />
        },
      },
      {
        title: 'Status',
        model: 'status',
        width: 100,
        disableSort: true,
        formatValue: ({ value }: any) => (
          <Status label={value === 'active' ? 'Active' : 'Archived'} color={value === 'active' ? 'green' : 'grey'} />
        ),
      },
      {
        title: 'Type',
        model: 'category',
        width: 120,
        disableSort: true,
        formatValue: ({ value }: any) => titleCase(value),
      },
      {
        title: 'Plan Name',
        model: 'plan',
        width: 160,
        disableSort: true,
      },
      {
        title: 'Member ID',
        model: 'member_id',
        width: 160,
        disableSort: true,
      },
      {
        title: 'Group ID',
        model: 'group_id',
        width: 160,
        disableSort: true,
      },
      {
        title: 'Policy Holder',
        model: 'is_client_policy_holder',
        disableSort: true,
        formatValue: ({ data, value }: any) => {
          if (value === true) return 'Client'

          if (!data?.policy_holder) return null

          return `${fullname(data.policy_holder)} (${RELATIONSHIP_TO_CLIENT_CODE[data?.policy_holder?.relationship_to_customer] || ''})`
        },
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
        disableSort: true,
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
        disableSort: true,
      },
    ],
    [],
  )

  if (!clientId) return null

  return (
    <>
      <DataTable asCard title="Insurance Policies" icon="insurance" columns={columns} {...tableProps} />

      <SummonOverlay
        key={`open-${openInsuranceId}`}
        isOpen={!!openInsuranceId}
        onClose={() => setOpenInsuranceId(null)}
        overlay={<InsurancePolicyOverlay dataID={openInsuranceId} />}
      />
    </>
  )
}

export const InlineInsurancePoliciesDataTable = (props) => {
  return (
    <Permission permission="insurance_policies.view">
      <RootInlineInsurancePoliciesDataTable {...props} />
    </Permission>
  )
}
