import React from 'react'

import { amount, usDate, daysToWords } from '../../utils/functions'
import { DEFAULT_FILTERS } from '../Filters/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { PAYMENT_METHODS } from '../../utils/constants'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'
import { useSettings } from '../../hooks/useSettings'

import Glyph from '../../components/Glyph'
import Status from '../../components/Status'

export const PaymentsDataTable = (props: any) => {
  const { to, isSelectable, duplicateColumn, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={isSelectable ? to?.(data) : undefined} value={value} />
        },
      },
      {
        title: 'Amount',
        model: 'amount',
        hoverExpand: false,
        formatValue: ({ value }: any) => {
          return <Status color="green" label={`+$${amount(value)}`} />
        },
      },
      {
        title: 'Payed With',
        model: 'payed_with',
        formatValue: ({ value }: any) => {
          if (!PAYMENT_METHODS?.[value]) return null

          return (
            <div>
              <Glyph glyph={PAYMENT_METHODS[value]?.glyph} /> {PAYMENT_METHODS[value]?.label}
            </div>
          )
        },
      },
      {
        title: 'Payment Method',
        model: 'payment_method',
        formatValue: ({ value }: any) => value?.name || null,
      },
      {
        title: 'Payment Date',
        model: 'transactioned_at',
        width: 200,
        formatValue: ({ value }: any) => {
          return (
            <div>
              <span>{usDate(value, timezone)} </span>
              <span className="opacity-60 text-[0.9em]">({daysToWords(value, timezone)})</span>
            </div>
          )
        },
      },
      {
        title: 'Payer',
        model: 'reference',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <ProfileCellInner avatar={value?.avatar || ''} value={value?.name} />
        },
      },
      {
        title: 'Payment Reference',
        model: 'payment_reference',
      },
      {
        title: 'Transaction ID',
        model: 'uuid',
        width: 320,
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Client Location',
        model: 'client_location',
        type: 'profile',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to, isSelectable, timezone],
  )

  const filtersConfig = React.useMemo(() => {
    if (!props.hiddenColumnIds) return FILTERS_CONFIG

    const res: any = {}

    for (const key in FILTERS_CONFIG) {
      if (props.hiddenColumnIds?.includes?.(key)) continue
      res[key] = FILTERS_CONFIG[key]
    }

    return res
  }, [props.hiddenColumnIds])

  return (
    <DataTable
      asCard
      title="Payments"
      testKey="payments_data_table"
      icon="financials"
      columns={columns}
      filtersConfig={filtersConfig}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: { label: 'Name', type: 'string' },
  amount: { label: 'Amount', type: 'number' },
  payed_with: {
    label: 'Payed With',
    type: 'multi_select',
    options: Object.keys(PAYMENT_METHODS).map((key) => ({
      value: key,
      label: PAYMENT_METHODS[key].label,
      glyph: PAYMENT_METHODS[key].glyph,
    })),
  },
  // payment_method: {},
  transactioned_at: { label: 'Payment Date', type: 'date', glyph: 'date' },
  // reference: {},
  payment_reference: { label: 'Payment Reference', type: 'string' },
  uuid: { label: 'Transaction ID', type: 'string' },
  notes: { label: 'Notes', type: 'string' },
  client_location: {
    ...DEFAULT_FILTERS.house,
    label: 'Client Location',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
