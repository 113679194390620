import React from 'react'

import { amount, usDate, daysToWords } from '../../utils/functions'
import { DEFAULT_FILTERS } from '../Filters/constants'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { ProfileCellInner } from '../../components/DataTable/cells/ProfileCell'
import { useSettings } from '../../hooks/useSettings'

import Status from '../../components/Status'

export const CreditsDataTable = (props: any) => {
  const { to, isSelectable, duplicateColumn, ...rest } = props

  const { timezone } = useSettings()

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 300,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          return <MainCell id={data.id} to={isSelectable ? to?.(data) : undefined} value={value} />
        },
      },
      {
        title: 'Amount',
        model: 'amount',
        hoverExpand: false,
        formatValue: ({ value }: any) => {
          return <Status color="orange" label={`+$${amount(value)}`} />
        },
      },
      {
        title: 'Credit Date',
        model: 'transactioned_at',
        width: 200,
        formatValue: ({ value }: any) => {
          return (
            <div>
              <span>{usDate(value, timezone)} </span>
              <span className="opacity-60 text-[0.9em]">({daysToWords(value, timezone)})</span>
            </div>
          )
        },
      },
      {
        title: 'Payer',
        model: 'reference',
        formatValue: ({ value }: any) => {
          if (!value) return null

          return <ProfileCellInner avatar={value?.avatar || ''} value={value?.name} />
        },
      },
      {
        title: 'Notes',
        model: 'notes',
      },
      {
        title: 'Date Added',
        model: 'created_at',
        type: 'date_time',
      },
      {
        title: 'Date Updated',
        model: 'updated_at',
        type: 'date_time',
      },
      {
        title: 'Client Location',
        model: 'client_location',
        type: 'profile',
      },
      {
        title: 'Added By',
        model: 'author',
        type: 'profile',
        disableSort: true,
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to, isSelectable, timezone],
  )

  const filtersConfig = React.useMemo(() => {
    if (!props.hiddenColumnIds) return FILTERS_CONFIG

    const res: any = {}

    for (const key in FILTERS_CONFIG) {
      if (props.hiddenColumnIds?.includes?.(key)) continue
      res[key] = FILTERS_CONFIG[key]
    }

    return res
  }, [props.hiddenColumnIds])

  return (
    <DataTable
      asCard
      title="Credits"
      testKey="credits_data_table"
      icon="financials"
      columns={columns}
      filtersConfig={filtersConfig}
      {...rest}
    />
  )
}

const FILTERS_CONFIG = {
  name: { label: 'Name', type: 'string' },
  amount: { label: 'Amount', type: 'number' },
  transactioned_at: { label: 'Credit Date', type: 'date', glyph: 'date' },
  // reference: {},
  notes: { label: 'Notes', type: 'string' },
  client_location: {
    ...DEFAULT_FILTERS.house,
    label: 'Client Location',
  },
  created_at: DEFAULT_FILTERS.created_at,
  updated_at: DEFAULT_FILTERS.updated_at,
  author: DEFAULT_FILTERS.author,
}
