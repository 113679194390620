import React from 'react'
import { connect } from 'react-redux'

import { withFormContext } from '../Forms/context'

import Button from '../Button'
import SummonOverlay from '../SummonOverlay'
import DiagnosisCard from '../Cards/Diagnosis'
import { DiagnosisOverlay } from '../../constructs/Diagnoses/DiagnosisOverlay'

import { SmartContentBase, defaultMapDispatchToProps, defaultMapStateToProps } from './SmartContentBase'

import { apiGet } from '../../modules/api'

class Diagnoses extends SmartContentBase {
  getData = (id) => apiGet({ name: 'diagnoses', url: `/residents/${id}/diagnoses` })

  renderElement = (element, disabled = false) => (
    <SummonOverlay
      key={element.id}
      overlay={
        <DiagnosisOverlay
          type="summon"
          dataID={element.id}
          initialData={element}
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
        />
      }
      isDisabled={disabled}
    >
      <DiagnosisCard showActions={false} diagnosis={element} />
    </SummonOverlay>
  )

  renderTrigger = () => (
    <SummonOverlay
      overlay={
        <DiagnosisOverlay
          type="summon"
          dataID="new"
          initialData={{ reference: this.props.client }}
          onSaveSuccessful={this.fetchData}
          onDeleteSuccessful={this.fetchData}
        />
      }
    >
      <Button type="default" glyph="add" label="Add Diagnosis" permission="diagnoses.create" />
    </SummonOverlay>
  )
}

const mapDispatchToProps = (dispatch) => defaultMapDispatchToProps(dispatch, 'diagnoses')
const mapStateToProps = (state) => defaultMapStateToProps(state, 'diagnoses')

export default withFormContext(connect(mapStateToProps, mapDispatchToProps)(Diagnoses))
