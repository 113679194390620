import React from 'react'
import { useParams } from 'react-router-dom'

import { useGet } from '../../hooks/useNewAPI'
import size from 'lodash/size'
import startCase from 'lodash/startCase'

import { ICONS } from '../../theme'
import { useOverlay } from '../../hooks/useOverlay'
import { useSettings } from '../../hooks/useSettings'
import { withOverlayError } from '../../hocs/withOverlayError'
import { niceAmount } from '../../utils/functions'

import Alert from '../../components/Alert'
import Button from '../../components/Button'
import DeleteDialog from '../../components/Dialogs/DeleteDialog'
import Divider from '../../components/Divider'
import Flex from '../../components/Flex'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'

import AmountInput from '../../components/Forms/AmountInput'
import Attachments from '../../components/Forms/Attachments'
import ChargeStatus from '../../components/Statuses/ChargeStatus'
import Checkbox from '../../components/Forms/Checkbox'
import CheckboxGroup from '../../components/Forms/CheckboxGroup'
import DateInput from '../../components/Forms/DateInput'
import DateTimeInput from '../../components/Forms/DateTimeInput'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Input from '../../components/Forms/Input'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Textarea from '../../components/Forms/Textarea'

import ChargePayersSection from './components/ChargePayersSection'

const RootFeeOverlay = (props: any) => {
  const {
    cancel,
    close,
    data,
    deleteRecord,
    edit,
    form,
    initialModel,
    isDeleting,
    isEditable,
    isInvalid,
    isNew,
    isOverlayLoading,
    isSaving,
    onValidationUpdate,
    save,
    updateAsync,
  } = useOverlay({
    name: 'financial-transactions',
    endpoint: '/financial_transactions',
    invalidate: 'financial-transactions',
    options: props,
  })

  const [formData, setFormData] = React.useState(initialModel)
  const { timezone } = useSettings()
  const { resource_id }: any = useParams()

  const { data: client }: any = useGet({
    name: ['client', resource_id],
    url: `/residents/${resource_id}`,
  })

  if (isOverlayLoading || !client) {
    return <OverlayLoader position="right" />
  }

  const toggleCancel = async () => {
    await updateAsync({ status: initialModel.status === 'charged' ? 'cancelled' : 'charged' })
    close()
  }

  return (
    <Overlay onClose={close} showBackdrop={isNew || isEditable} isDirty={isEditable}>
      <Overlay.Header icon="financials" title="Fee" />

      <Overlay.Content>
        <Form
          getForm={form}
          initialModel={initialModel}
          isEditable={isEditable}
          timezone={timezone}
          onValidationUpdate={onValidationUpdate}
          onUpdate={setFormData}
        >
          <Section>
            <FormSection>
              <Input
                label="Name"
                model="name"
                validations={{
                  presence: {
                    message: 'Please enter a charge name',
                  },
                }}
              />

              <AmountInput isEditable={false} label="Amount" model="amount" />

              <DateInput isEditable={false} label="Generated On" model="transactioned_at" />

              <Textarea isEditable={false} label="Notes" model="notes" />
            </FormSection>
          </Section>
        </Form>
      </Overlay.Content>

      <Overlay.Footer>
        {isEditable ? (
          <>
            <Button
              label="Save"
              glyph="check"
              type="primary"
              color="green"
              flex="100 1 auto"
              onClick={save}
              isLoading={isSaving}
              isDisabled={isInvalid}
              permission="ledger.create"
            />
            <Button label="Cancel" glyph="cross" type="default" onClick={cancel} isDisabled={isSaving} />
          </>
        ) : (
          <>
            <Button label="Edit" glyph="edit" type="default" onClick={edit} flex="100 1 auto" permission="ledger.edit" />

            {initialModel.status === 'cancelled' && (
              <Button
                label="Re-apply Fee"
                glyph="circle_add"
                type="default"
                color="green"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {initialModel.status === 'charged' && (
              <Button
                label="Cancel Fee"
                glyph="circle_error"
                type="default"
                color="red"
                onClick={toggleCancel}
                permission="ledger.edit"
                isLoading={isSaving}
              />
            )}

            {(initialModel.status === 'future' || initialModel.status === 'cancelled') && (
              <DeleteDialog
                title="Delete Fee?"
                message="Are you sure you want to delete this fee? This action cannot be undone."
                onYes={deleteRecord}
              >
                <Button
                  label="Delete"
                  type="default"
                  glyph="delete"
                  color="red"
                  fullWidth
                  isLoading={isDeleting}
                  permission="ledger.delete"
                />
              </DeleteDialog>
            )}
          </>
        )}
      </Overlay.Footer>
    </Overlay>
  )
}

export const FeeOverlay = withOverlayError(RootFeeOverlay)
