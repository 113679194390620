import React from 'react'

import Alert from '../../../components/Alert'
import CardTreeItem from '../../../components/CardTreeItem'
import Checkbox from '../../../components/Forms/Checkbox'
import CheckboxGroup from '../../../components/Forms/CheckboxGroup'
import FormSection from '../../../components/Forms/FormSection'
import Textarea from '../../../components/Forms/Textarea'
import ContextShow from '../../../components/Forms/ContextShow'

export const TreatmentPlanSignatureSettings = ({ prefix }: any) => {
  const model = `${prefix ? `${prefix}.` : ''}settings`

  const [requiresClientUpdate, setRequiresClientUpdate] = React.useState(false)
  const [requiresStaffUpdate, setRequiresStaffUpdate] = React.useState(false)
  const [requiresContactUpdate, setRequiresContactUpdate] = React.useState(false)
  const [requiresSupervisorUpdate, setRequiresSupervisorUpdate] = React.useState(false)

  const requiresUpdateSignatures = requiresClientUpdate || requiresStaffUpdate || requiresSupervisorUpdate || requiresContactUpdate

  const updateSignees = React.useMemo(() => {
    const result: any[] = []

    if (requiresClientUpdate) result.push('Client')
    if (requiresStaffUpdate) result.push('Staff')
    if (requiresSupervisorUpdate) result.push('Supervisor')
    if (requiresContactUpdate) result.push('Guardian')

    return result
  }, [requiresClientUpdate, requiresStaffUpdate, requiresSupervisorUpdate, requiresContactUpdate])

  return (
    <>
      <CardTreeItem isOpen title="Signatures">
        <FormSection className="pt-3 pb-5 pr-4">
          <CheckboxGroup
            label="Signatures Required for Initial Sign-Off:"
            layout="vertical-dense"
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          >
            <Checkbox label="Require Client Signature" model={`${model}.initial_signatures_required.client`} />
            <Checkbox label="Require Guardians Signatures" model={`${model}.initial_signatures_required.contacts`} />
            <Checkbox label="Require Staff Members Signatures" model={`${model}.initial_signatures_required.staff`} />
            <Checkbox label="Require Supervisors Signatures" model={`${model}.initial_signatures_required.supervisors`} />
          </CheckboxGroup>

          <CheckboxGroup
            label="Signatures Required After All Plan Updates:"
            layout="vertical-dense"
            trueIcon="check"
            falseIcon="empty_checkbox"
            falseStyle="faded"
          >
            <Checkbox
              label="Require Client Signature"
              model={`${model}.updates_signatures_required.client`}
              onUpdate={({ value }: any) => {
                setRequiresClientUpdate(value)
              }}
            />
            <Checkbox
              label="Require Guardians Signatures"
              model={`${model}.updates_signatures_required.contacts`}
              onUpdate={({ value }: any) => {
                setRequiresContactUpdate(value)
              }}
            />
            <Checkbox
              label="Require Staff Members Signatures"
              model={`${model}.updates_signatures_required.staff`}
              onUpdate={({ value }: any) => {
                setRequiresStaffUpdate(value)
              }}
            />
            <Checkbox
              label="Require Supervisors Signatures"
              model={`${model}.updates_signatures_required.supervisors`}
              onUpdate={({ value }: any) => {
                setRequiresSupervisorUpdate(value)
              }}
            />
          </CheckboxGroup>

          {requiresUpdateSignatures && (
            <Alert small contrast glyph="info">
              <span className="font-[600]">Please note:</span>
              When a treatment plan's problems, goals, objectives or interventions are updated, the update signatures for{' '}
              {updateSignees.join(', ')} will be removed and the treatment plan will require new signatures.
            </Alert>
          )}
        </FormSection>
      </CardTreeItem>

      <CardTreeItem isOpen title="Legal Copy">
        <FormSection className="pt-3 pb-5 pr-4">
          <Alert small contrast glyph="info">
            The text below will appear on the signature dialog for each signee type.
          </Alert>

          <Textarea label="Client Signatures Legal Copy" model={`${model}.signatures_legal_copy.client`} />
          <Textarea label="Guardians Signatures Legal Copy" model={`${model}.signatures_legal_copy.contacts`} />
          <Textarea label="Staff Signatures Legal Copy" model={`${model}.signatures_legal_copy.staff`} />
          <Textarea label="Supervisors Signatures Legal Copy" model={`${model}.signatures_legal_copy.supervisors`} />
        </FormSection>
      </CardTreeItem>
    </>
  )
}
