import React from 'react'

import CheckboxGroup from '../CheckboxGroup'
import Checkbox from '../Checkbox'

const RaceCheckboxGroup = ({ label = 'Race', model = 'new_race', isRequired, validateOn = 'blur-change' }: any) => (
  <CheckboxGroup
    allowEmpty
    label={label}
    layout="vertical-dense"
    trueIcon="check"
    falseStyle="hidden"
    validations={
      isRequired && {
        presence: {
          message: 'Please select a Race',
        },
      }
    }
  >
    <Checkbox label="American Indian / Alaska Native" model={`${model}.american_indian_alaska_native`} />
    <Checkbox label="Asian" model={`${model}.asian`} />
    <Checkbox label="Black / African American" model={`${model}.black_african_american`} />
    <Checkbox label="Native Hawaiian / Pacific Islander" model={`${model}.native_hawaiian_pacific_islander`} />
    <Checkbox label="White" model={`${model}.white`} />
    <Checkbox label="Some Other Race" model={`${model}.some_other_race`} />
    <Checkbox label="Prefer Not to Answer" model={`${model}.prefer_not_to_say`} />
  </CheckboxGroup>
)

export default RaceCheckboxGroup
