import React from 'react'

import Button from '../../../components/Button'
import Card from '../../../components/Card'
import CardTitle from '../../../components/CardTitle'
import SummonOverlay from '../../../components/SummonOverlay'

import { ResultOverlay } from './ResultOverlay'

export const ResultCard = (props: any) => {
  const { title, score, overlay, overlayContent, color, children, maxScore, animationDelay = 0 } = props

  const percent = score && maxScore ? (score * 100) / maxScore : 0

  return (
    <Card
      className="grid gap-1 grid-cols-[100%] overflow-hidden p-4 pt-2 opacity-0 scale-x-[0.95] scale-y-[0.95] animate-scaleFadeIn"
      style={{ animationDelay: `${animationDelay}ms` }}
    >
      <h3>{title}</h3>

      <div className="flex flex-nowrap items-baseline">
        {score && (
          <CardTitle
            title={parseFloat(score)}
            color={color ? color : percent < 33 ? 'red' : percent < 66 ? 'yellow' : 'green'}
            className="!text-[1.8rem] !font-[700]"
          />
        )}

        {maxScore && (
          <>
            <div className="mx-1 text-text-muted opacity-30 font-[400] text-[1.5rem]">/</div>
            <div className="text-text-muted opacity-70 font-[500] text-[1.2rem]">{maxScore}</div>
          </>
        )}
      </div>

      {children}

      {(overlay || overlayContent) && (
        <SummonOverlay overlay={overlay || <ResultOverlay title={title}>{overlayContent}</ResultOverlay>}>
          <Button label="View Historic Data" glyph="chart" size={200} />
        </SummonOverlay>
      )}
    </Card>
  )
}
