import React from 'react'
import { Route } from 'react-router-dom-v5-compat'
import { useSelector } from 'react-redux'

import { useDataTable } from '@behavehealth/components/DataTable/useDataTable'
import { useSettings } from '@behavehealth/hooks/useSettings'
import { withPageError } from '@behavehealth/hocs/withPageError'
import withMarketing from '@behavehealth/hocs/withMarketing'

import { OtherRecordFileOverlay } from '@behavehealth/constructs/OnboardingFiles/OtherRecordFileOverlay'
import { AnimatedRoutes } from '@behavehealth/components/AnimatedRoutes'
import { Button, Page, Grid, Uploader, DnDUploader, Card, CardContent, Alert, TipAlert } from '@behavehealth/components'
import { FilesDataTable } from '@behavehealth/constructs/Files/FilesDataTable'
import { Tenant } from '@behavehealth/declarations'

const pageConfig = {
  feature: 'files',
  icon: 'files',
  title: 'Onboarding Other Backup Records',
}

const OtherBackupRecords = () => {
  return (
    <>
      <OtherBackupRecordsIndex />

      <AnimatedRoutes>
        <Route path=":id" element={<OtherRecordFileOverlay useV6Router />} />
      </AnimatedRoutes>
    </>
  )
}

const OtherBackupRecordsIndex: React.FC = () => {
  const tenant: Tenant = useSelector((state: any) => state.me.tenant)

  const { isBehave, isManagement } = useSettings()

  const tableProps = useDataTable({
    name: ['facilities', tenant.id, 'files'],
    endpoint: `/facilities/${tenant.id}/documents`,
    updateDeleteEndpoint: '/documents',
    enabled: !!tenant.id,
    params: {
      category: 'onboarding_other_record',
    },
    localStorageKey: 'files_v1',
  })

  return (
    <Page
      breakpoint="0"
      actions={
        <Uploader category="onboarding_other_record" resource={tenant} queryKey={tableProps.queryKey}>
          <Button type="primary" glyph="upload" label="Upload Files" />
        </Uploader>
      }
      {...pageConfig}
    >
      <Grid gap="1rem">
        <TipAlert contrast type="warning" localStorageKey="settings_onboarding_other_record_files">
          <div className="grid gap-2 py-1">
            <div>Behave recommends you export other records (not Client/Patient records) from your previous software as well.</div>
            <div>These might be Staff Profiles, Location Profiles, or other types of documents.</div>
            <div>Temporarily store all your other exported PDF records for backup here.</div>
            <div>In the future your team can refile these other PDF Record Backups wherever you need them to be.</div>
          </div>
        </TipAlert>

        <Card>
          <FilesDataTable
            {...tableProps}
            title="Backup Files"
            batchActionsConfig={[
              {
                type: 'delete',
                permission: isBehave || isManagement,
                action: async ({ ids }: any) => {
                  await tableProps.deleteRecords(ids.join(','))
                },
              },
            ]}
          />

          <CardContent>
            <DnDUploader
              isEditable
              category="onboarding_other_record"
              resource={tenant}
              isDisabled={false}
              queryKey={tableProps.queryKey}
            />
          </CardContent>
        </Card>
      </Grid>
    </Page>
  )
}

export default withPageError(withMarketing(OtherBackupRecords, pageConfig))
