export const OUTCOME_MEASURE_ADMISSION_LEVEL_OF_CARE_TITLES = {
  inpatient_hospital_or_medical_detox: 'Inpatient Hospital or Medical Detox',
  residential: 'Residential',
  partial_hospitalization: 'Partial Hospitalization',
  intensive_outpatient: 'Intensive Outpatient',
  outpatient: 'Outpatient',
  sober_living_recovery_residence: 'Sober Living/Recovery Residence',
  recovery_support: 'Recovery Support (e.g., recovery coaching)',
  other_type_of_program: 'Other type of program',
}

export const OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES = {
  full_time: 'Full Time',
  part_time: 'Part Time',
  unemployed_seeking_employment: 'Unemployed - seeking employment',
  unemployed_not_seeking_employment: 'Unemployed - not seeking employment (retired, on disability, other source of income, etc…)',
}

export const OUTCOME_MEASURE_HOUSING_STATUS_TITLES = {
  homeless_unstable_housing: 'Homeless/unstable housing - (shelter, street, outdoors, temporarily staying with friends)',
  institution_controlled_environment: 'Institution/controlled environment - (hospital, nursing home, jail/prison, residential treatment)',
  stable_housing:
    'Stable housing - (Own or rent apartment, room or house, stable housing with friends/family, dormitory or college residence, sober living home, other)',
}

export const OUTCOME_MEASURE_CURRENT_MEDICATIONS_TITLES = {
  none: 'None',
  acamprosate_campral: 'Acamprosate/Campral',
  buprenorphine_buprenorphine_subutex_suboxone_film: 'Buprenorphine (Subutex/Suboxone Film)',
  buprenorphine_sublocade_injection: 'Buprenorphine (Sublocade injection)',
  disulfiram_antabuse: 'Disulfiram/Antabuse',
  naltrexone_re_via_pill: 'Naltrexone (ReVia Pill)',
  naltrexone_vivitrol_injection: 'Naltrexone (Vivitrol Injection)',
  methadone: 'Methadone',
  co_occurring_disorder_medications: 'Medications for co-occurring disorder (e.g., anxiety or depression)',
  medication_not_listed: 'Medication not listed',
  other: 'Other',
}

export const OUTCOME_MEASURE_LIFE_QUALITY_TITLES = {
  very_poor: 'Very poor',
  poor: 'Poor',
  neither_poor_nor_good: 'Neither poor nor good',
  good: 'Good',
  very_good: 'Very good',
}

export const OUTCOME_MEASURE_NICOTINE_USE_FREQUENCY_TITLES = {
  daily: 'Daily',
  weekly: 'Weekly',
  less_than_weekly: 'Less than weekly',
  not_at_all: 'Not at all',
}

export const OUTCOME_MEASURE_MONTHLY_SERVICE_TITLES = {
  none: 'None',
  detox: 'Detox',
  residential: 'Residential',
  partial_hospitalization: 'Partial Hospitalization (≥ 20 hrs of services weekly)',
  intensive_outpatient: 'Intensive Outpatient (9-20 hrs of services weekly)',
  outpatient: 'Outpatient (< 9 hrs of services weekly)',
  sober_living_recovery_residence: 'Sober Living/Recovery Residence',
  recovery_support: 'Recovery Support (e.g., recovery coaching)',
}

export const OUTCOME_MEASURE_ETHNICITY_TITLES = {
  asian: 'Asian',
  american_indian_or_alaska_native: 'American Indian or Alaska Native',
  black_or_african_american: 'Black or African American',
  hispanic_or_latino: 'Hispanic or Latino',
  native_hawaiian_or_other_pacific_islander: 'Native Hawaiian or Other Pacific Islander',
  white: 'White',
}

export const OUTCOME_MEASURE_GENDER_TITLES = {
  male: 'Male',
  female: 'Female',
  non_binary_third_gender: 'Non-binary/third gender',
  prefer_to_self_describe: 'Prefer to self-describe:',
  prefer_not_to_say: 'Prefer not to answer',
}

export const OUTCOME_MEASURE_DISCHARGE_TITLES = {
  completed: 'Completed (discharged to home or transferred to a lower level of care at another facility)',
  ama: 'AMA (left against medical advice or discontinued care)',
  administrative: 'Administrative (discharged/transferred due to difficulty conforming to community rules (e.g., fighting))',
  medical: 'Medical (discharged/transferred due to medical condition outside the scope of current care environment)',
  psych: 'Psych (discharged/transferred due to psychiatric condition outside the scope of current care environment)',
  other: 'Other (any other type of discharge)',
}

export const OUTCOME_MEASURE_DISCHARGE_LEVEL_OF_CARE_TITLES = {
  inpatient_hospital_or_medical_detox: 'Inpatient Hospital or Medical Detox',
  residential: 'Residential',
  partial_hospitalization: 'Partial Hospitalization',
  intensive_outpatient: 'Intensive Outpatient',
  outpatient: 'Outpatient',
  sober_living_recovery_residence: 'Sober Living/Recovery Residence and Recovery Support (e.g., recovery coaching)',
  recovery_support: 'Recovery Support (e.g., recovery coaching)',
  other_type_of_program: 'Other type of program',
}

export const OUTCOME_MEASURE_PAYMENT_TYPE_TITLES = {
  self_pay: 'Self-Pay',
  private_insurance: 'Private Insurance',
  combination_of_self_pay_and_insurance: 'Combination of Self-Pay and Insurance',
  medicare_or_other_public_funding: 'Medicare or Other public funding',
}

export const OUTCOME_MEASURE_DISORDER_CATEGORY_TITLES = {
  alcohol: 'Alcohol',
  cannabis: 'Cannabis',
  hallucinogen: 'Hallucinogen',
  inhalant: 'Inhalant',
  opioid: 'Opioid',
  sedative_hypnotic_anxiolytic: 'Sedative/Hypnotic/Anxiolytic',
  stimulant: 'Stimulant',
  tobacco: 'Tobacco',
  other: 'Other',
}

export const OUTCOME_MEASURE_MAT_MEDICATION_TITLES = {
  none: 'None',
  acamprosate_campral: 'Acamprosate/Campral',
  buprenorphine_sublocade_injection: 'Buprenorphine (Sublocade Injection)',
  buprenorphine_subutex_suboxone_film: 'Buprenorphine (Subutex/Suboxone film)',
  disulfiram_antabuse: 'Disulfiram/Antabuse',
  naltrexone_re_via_pill: 'Naltrexone (ReVia Pill)',
  naltrexone_vivitrol_injection: 'Naltrexone (Vivitrol Injection)',
  methadone: 'Methadone',
  co_occurring_disorder_medications: 'Medications for co-occurring disorder (e.g. anxiety or depression)',
  medication_not_listed: 'Medication not listed',
  other: 'Other',
}

export const OUTCOME_MEASURE_MENTAL_HEALTH_DIAGNOSIS_TITLES = {
  none: 'None',
  anxiety_disorder: 'Anxiety Disorder',
  bipolar_disorder: 'Bipolar Disorder',
  depressive_disorder: 'Depressive Disorder',
  disruptive_impulse_control_conduct_disorder: 'Disruptive/Impulse-Control/Conduct Disorder',
  dissociative_disorder: 'Dissociative Disorder',
  eating_disorder: 'Eating Disorder',
  neurocognitive_disorder: 'Neurocognitive Disorder',
  neurodevelopmental_disorder: 'Neurodevelopmental Disorder (e.g., ADHD, ASD, Intellectual Disability, Learning Disorder)',
  obsessive_compulsive_and_related_disorder: 'Obsessive Compulsive and Related Disorder',
  trauma_and_stressor_related_disorder: 'Trauma and Stressor Related Disorder (e.g., PTSD)',
  schizophrenia_spectrum_and_other_psychotic_disorder: 'Schizophrenia Spectrum and Other Psychotic Disorder',
  paraphilic_disorder: 'Paraphilic Disorder',
  personality_disorder: 'Personality Disorder',
  sexual_dysfunction: 'Sexual Dysfunction',
  sleep_disorder: 'Sleep Disorder',
  somatic_symptom_and_related_disorder: 'Somatic Symptom and Related Disorder',
  other_disorder: 'Other Disorder',
}
