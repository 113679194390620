import React from 'react'

import { DATA_FORM_FILTERS } from '../Filters/constants'
import { DataTable } from '../../components/DataTable/DataTable'
import { getClientLink } from '@behavehealth/utils/functions'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import DataFormStatus from '../../components/Statuses/DataFormStatus'

export const OutcomeMeasuresDataTable = (props: any) => {
  const { to, duplicateColumn, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} to={to?.(data)} />,
      },
      {
        title: 'Client',
        model: 'client',
        formatValue: ({ value }) => {
          if (!value) return null

          return <MainCell value={value?.name} avatar={value?.avatar || ''} to={`${getClientLink(value)}/outcome-measures`} />
        },
      },
      {
        width: 200,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => <DataFormStatus status={value} />,
      },
      {
        width: 200,
        model: 'created_at',
        title: 'Date Added',
        type: 'date_time',
      },
      {
        width: 200,
        model: 'updated_at',
        title: 'Last Updated',
        type: 'date_time',
      },
      {
        width: 180,
        model: 'author',
        title: 'Added By',
        type: 'profile',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ],
    [to],
  )

  return <DataTable asCard title="Outcome Measures" icon="outcome_measures" columns={columns} filtersConfig={DATA_FORM_FILTERS} {...rest} />
}
