import React from 'react'

import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import { FILTERS } from '../Filters/config'
import { useSettings } from '../../hooks/useSettings'
import ReportRefetchButton from '@behavehealth/components/Buttons/ReportRefetchButton'

export const MedicationLogsReportDataTable = (props: any) => {
  const { to } = props
  const { tenant, isBehave } = useSettings()

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Medication',
        id: 'med',
        model: 'data.med',
        width: 260,
        disableHide: true,
        formatValue: ({ data, value }: any) => <MainCell id={value.id} value={value.name} to={to?.(data.data)} />,
      },
      {
        width: 180,
        id: 'resident',
        model: 'data.resident.name',
        title: 'Client',
        type: 'profile',
      },
      {
        width: 160,
        id: 'administered_at',
        model: 'data.administered_at',
        title: 'Administered At',
        type: 'date_time',
      },
      {
        width: 200,
        id: 'medication_time',
        model: 'data.medication_time',
        title: 'Medication Time',
      },
      {
        width: 180,
        id: 'dosage',
        model: 'data.dosage',
        title: 'Dosage',
      },
      {
        width: 180,
        id: 'unit',
        model: 'data.unit',
        title: 'Unit',
      },
      {
        width: 180,
        id: 'remaining_units',
        model: 'data.remaining_units',
        title: 'Remaining Units',
      },
      {
        title: 'Prescription #',
        id: 'prescription_no',
        model: 'data.prescription_no',
      },
      {
        width: 200,
        id: 'author',
        model: 'data.author.name',
        title: 'Logged By',
        type: 'profile',
      },
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Medication Logs"
      icon="med_pass"
      columns={columns}
      filtersConfig={FILTERS.live_reports.medication_logs}
      headerAfter={
        <ReportRefetchButton
          forceShow={true}
          category="medication_logs"
          invalidate={props.queryKey}
          refetchUrl={'/live_reports/update_live_report?category=medication_logs'}
        />
      }
      {...props}
    />
  )
}
