import React from 'react'
import { DateTime } from 'luxon'

import { COLORS } from '../../theme'
import Chart from '../Chart'

export const AlumniChart = (props: any) => {
  const days: any = []

  const series: any = [
    {
      name: 'Discharged',
      type: 'bar',
      stack: 'total',
      color: COLORS.red,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: [],
    },
    {
      name: 'Readmitted',
      type: 'bar',
      stack: 'total',
      color: COLORS.green,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: [],
    },
    {
      name: 'Total Past Client',
      type: 'bar',
      stack: 'total',
      color: COLORS.gold,
      label: {
        show: true,
      },
      emphasis: {
        focus: 'series',
      },
      data: [],
    },
  ]

  const setData = () => {
    for (let i = 0; i < props.timeframe; i++) {
      const day = DateTime.fromISO('2021-01-01').plus({ days: i }).toISODate()
      days.push(day)

      const discharged = Math.floor(Math.random() * 100)
      const readmitted = Math.floor(Math.random() * 100)
      const total = discharged + readmitted + Math.floor(Math.random() * 100)

      series[0].data.push(discharged)
      series[1].data.push(readmitted)
      series[2].data.push(total)
    }
  }

  setData()

  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['Discharged', 'Readmitted', 'Total Past Client'],
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    xAxis: {
      type: 'category',
      data: days,
    },
    yAxis: {
      type: 'value',
    },
    series,
  }

  return <Chart option={option} {...props} />
}
