import React from 'react'

import { apiDelete } from '../../modules/api'
import { getClientLink } from '../../utils/functions'
import { DataTable } from '../../components/DataTable/DataTable'
import { MainCell } from '../../components/DataTable/cells/MainCell'

import Button from '../../components/Button'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import Status from '../../components/Status'
import Text from '../../components/Typography/Text'

export const StaffAccessListAuditDataTable = (props: any) => {
  const { to, timezone, ...rest } = props

  const columns = React.useMemo(
    () => [
      {
        title: 'Access List Clients',
        id: '__in_staff_programs_or_locations',
        model: 'name',
        width: 320,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          if (!data.in_access_list) {
            return null
          }

          return <MainCell id={data.id} as={props.mainLinkAs} to={getClientLink(data)} value={data.name} avatar={data?.avatar || ''} />
        },
      },
      {
        title: 'Staff Programs/Locations Clients',
        id: '__in_access_list',
        model: 'name',
        width: 320,
        disableHide: true,
        formatValue: ({ data, value }: any) => {
          if (!data.in_staff_programs_or_locations) {
            return null
          }

          return <MainCell id={data.id} as={props.mainLinkAs} to={getClientLink(data)} value={data.name} avatar={data?.avatar || ''} />
        },
      },
      {
        title: `Audit Result`,
        id: '_audit_result',
        width: 320,
        formatValue: ({ data }: any) => {
          if (data.in_access_list && data.in_staff_programs_or_locations) {
            return <Status glyph="tick_circle" label="Valid" small color="green" />
          }

          if (data.in_access_list && !data.in_staff_programs_or_locations) {
            return <Text glyph="circle_error">No Staff Program/Location Assignment</Text>
          }

          if (!data.in_access_list && data.in_staff_programs_or_locations) {
            return <Text glyph="circle_error">No Staff Access List Assignment</Text>
          }

          return null
        },
      },
      {
        title: 'Recommendation',
        id: '_recommendation',
        disableSort: true,
        hoverExpand: false,
        width: 200,
        formatValue: ({ data }: any) => {
          const showAssign = !data.in_access_list && data.in_staff_programs_or_locations
          const showRemove = data.in_access_list && !data.in_staff_programs_or_locations

          if (showAssign) {
            return (
              <ConfirmDialog
                title="Assign client?"
                message={`Assign ${data.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
                yesLabel="Assign"
                noLabel="Cancel"
                yesColor="green"
                glyph="tick_circle"
                onYes={() => {
                  // apiDelete({ name: 'access_controls', url: `/access_controls/${data?.id}` })
                  // rest?.refetch?.()
                }}
              >
                <Button label="Assign Client" size={100} type="default" color="blue" glyph="add" />
              </ConfirmDialog>
            )
          }

          if (showRemove) {
            return (
              <ConfirmDialog
                title="Remove client?"
                message={`Remove ${data.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
                yesLabel="Remove"
                noLabel="Cancel"
                yesColor="red"
                glyph="delete"
                onYes={() => {
                  apiDelete({ name: 'access_controls', url: `/access_controls/${data?.id}` })
                  rest?.refetch?.()
                }}
              >
                <Button label="Remove Client" size={100} type="default" color="red" glyph="cross" />
              </ConfirmDialog>
            )
          }

          return null
        },
      },
      {
        title: `Client's Location`,
        model: 'house',
        width: 320,
        type: 'profile',
      },
      {
        title: `Client's Programs`,
        model: 'programs',
        width: 320,
        type: 'profiles',
      },

      // {
      //   title: 'Assign Clients',
      //   id: 'assign_actions',
      //   disableSort: true,
      //   hoverExpand: false,
      //   formatValue: ({ data }: any) => {
      //     const show = !data.in_access_list && data.in_staff_programs_or_locations

      //     if (!show) return null

      //     return (
      //       <ConfirmDialog
      //         title="Assign client?"
      //         message={`Assign ${data.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
      //         yesLabel="Assign"
      //         noLabel="Cancel"
      //         yesColor="red"
      //         glyph="delete"
      //         onYes={() => {
      //           apiDelete({ name: 'access_controls', url: `/access_controls/${data?.id}` })
      //           rest?.refetch?.()
      //         }}
      //       >
      //         <Button label="Assign" size={100} type="default" color="blue" glyph="add" />
      //       </ConfirmDialog>
      //     )
      //   },
      // },
      // {
      //   title: 'Remove Clients',
      //   id: 'remove_actions',
      //   disableSort: true,
      //   hoverExpand: false,
      //   formatValue: ({ data }: any) => {
      //     const show = data.in_access_list && !data.in_staff_programs_or_locations

      //     if (!show) return null

      //     return (
      //       <ConfirmDialog
      //         title="Remove client?"
      //         message={`Remove ${data.resource?.name} from the access list? This staff member will not be able to access their profile anymore.`}
      //         yesLabel="Remove"
      //         noLabel="Cancel"
      //         yesColor="red"
      //         glyph="delete"
      //         onYes={() => {
      //           apiDelete({ name: 'access_controls', url: `/access_controls/${data?.id}` })
      //           rest?.refetch?.()
      //         }}
      //       >
      //         <Button label="Remove" size={100} type="default" color="red" glyph="delete" />
      //       </ConfirmDialog>
      //     )
      //   },
      // },
    ],
    [to, timezone],
  )

  return <DataTable asCard title="Audit Issues" icon="access_lists" columns={columns} {...rest} />
}
