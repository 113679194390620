import React from 'react'
import { connect } from 'react-redux'
import get from 'lodash/get'
import isEqual from 'react-fast-compare'

import withScrollContext from '@behavehealth/hocs/withScrollContext'

import { COLORS, MEDIA_QUERY } from '@behavehealth/theme'
import { FACILITY_INFORMATION } from '@behavehealth/data/bridge'

import { InsuranceCompanies } from '../../utils/constants'

import {
  Accordion,
  Alert,
  AmountInput,
  Attachments,
  BridgeProfileStatus,
  Button,
  Checkbox,
  CheckboxGroup,
  ConfirmDialog,
  ContextShow,
  Form,
  FormSection,
  Grid,
  Heading,
  Input,
  Li,
  Link,
  MultiObjectSelector,
  Nav,
  Option,
  Overlay,
  P,
  Page,
  PageGrid,
  Radio,
  RadioGroup,
  ScrollMenu,
  ScrollView,
  Section,
  Select,
  Textarea,
  Ul,
} from '@behavehealth/components'

import Team from './Team'
import Properties from './Properties'
import ProgramHighlights from './ProgramHighlights'

import { apiCreate, apiUpdate } from '@behavehealth/modules/api'

class BridgeProfile extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: props.bridge_data,
      origin: { ...props.bridge_data },
      $editable: props.bridge_status === null,
      $new: props.bridge_status === null,
      isValid: false,
      isInvalid: true,
    }

    this.form = React.createRef()
  }

  /*
    LIFECYCLE
  */
  shouldComponentUpdate = (nextProps, nextState) => {
    if (!isEqual(nextProps.bridge_data, this.props.bridge_data)) return true
    if (nextProps.bridge_status !== this.props.bridge_status) return true
    if (nextProps.loading !== this.props.loading) return true
    if (nextState.$editable !== this.state.$editable) return true
    if (nextState.isValid !== this.state.isValid) return true

    return false
  }

  /*
    CUSTOM FUNCTIONS
  */
  edit = () => {
    this.setState({ $editable: true })
  }

  cancel = () => {
    this.form.current.resetForm()
    this.setState({ $editable: false })
  }

  close = () => {
    if (this.props.location.parent) {
      this.props.history.push(this.props.location.parent.url)
    } else {
      const path = this.props.location.pathname
      this.props.history.push(path.substr(0, path.lastIndexOf('/')))
    }
  }

  save = async () => {
    await apiUpdate({
      name: 'tenant',
      url: '/me/tenant',
      params: {
        bridge_data: this.form.current.getFormValue(),
        bridge_status: this.props.bridge_status || 'draft',
      },
      reducer: 'me',
    })

    this.setState({ $editable: false })
  }

  submitForReview = async () => {
    await apiUpdate({
      name: 'tenant',
      url: '/me/tenant',
      params: { bridge_status: 'pending' },
      reducer: 'me',
    })
  }

  submitForPublish = async () => {
    await apiCreate({ url: '/facilities/current/publish_bridge' })
  }

  submitUpdate = async () => {
    await apiUpdate({
      name: 'tenant',
      url: '/me/tenant',
      params: { bridge_data: this.form.current.getFormValue() },
      reducer: 'me',
    })

    this.setState({ $editable: false })
    this.close()
  }

  /*
    RENDER
  */
  render() {
    const { bridge_data, bridge_status, loading } = this.props
    const { $editable, isInvalid, $new } = this.state

    const isBehave = this.props.user.type === 'bh_employee'

    const paddingStyles = {
      padding: 0,

      [`${MEDIA_QUERY[1]}`]: {
        padding: '1.25rem 1.5rem',
      },
    }

    return (
      <Overlay position="center" showBackdrop={true} maxWidth={70}>
        <Overlay.Header icon="bridge" title="Bridge Profile Application" titleAside={<BridgeProfileStatus status={bridge_status} />} />

        <ScrollView>
          <PageGrid scroll breakpoint={3}>
            <Nav
              top="0"
              breakpoint={3}
              title={' '}
              headingSize={300}
              desktopProps={{ title: null }}
              className="!bg-white shadow-right-hard-1 mq1024:max-w-[300px] [&_header]:!shadow-none [&_header]:!shadow-transparent [&_header]:!border-transparent"
            >
              <div className="opacity-80 text-[0.9rem] uppercase font-[700] tracking-[1px] mb-1">Table of Contents</div>
              <ScrollMenu />
            </Nav>

            <ScrollPage background={COLORS.lightBackground} useWrapper={false}>
              <Form
                useFullModel
                deregisterOnUnmount={false}
                isEditable
                getForm={this.form}
                initialModel={bridge_data}
                defaultDebounce={300}
                onValidationUpdate={(valid) => this.setState({ isValid: valid, isInvalid: !valid })}
              >
                <Grid gap={8} css={paddingStyles}>
                  {/* ABOUT BEHAVE|BRIDGE */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'about',
                      name: 'About BEHAVE|BRIDGE',
                      icon: 'general_info',
                    }}
                  >
                    <Accordion isSticky title="About BEHAVE|BRIDGE" icon="general_info" appearance="card" permission={true} isOpen>
                      <P fontWeight={600}>
                        BEHAVE|BRIDGE was designed to provide patients, families, and referring professionals with the full picture of an
                        organization.
                      </P>

                      <Ul>
                        <Li>We built this comprehensive registration tool to allow you to showcase your facility and unique program.</Li>
                        <Li>The facilities that take the time to invest in their profile will perform better.</Li>
                        <Li>The more information you provide the better we can match the right clients to your facility.</Li>
                      </Ul>
                    </Accordion>
                  </Section>

                  {/* ORGANIZATION INFORMATION */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'organization_information',
                      name: 'Organization Information',
                      icon: 'organizations',
                    }}
                  >
                    <Accordion isSticky title="Organization Information" icon="organizations" appearance="card" permission={true} isOpen>
                      <FormSection layout="vertical" maxWidth={600}>
                        <Attachments isInline label="Logo" model="logo" />

                        <Input
                          label="Organization Name"
                          model="name"
                          description="This is the brand name we will use for your BEHAVE|BRIDGE profile page"
                          validations={{
                            presence: {
                              message: 'Please enter an organization name',
                            },
                          }}
                        />

                        <Input label="Short Description" model="shortDescription" />

                        <Textarea label="Full Description" model="description" />

                        <Input
                          label="Tax ID / EIN #"
                          model="taxIdEin"
                          validations={{
                            presence: {
                              message: 'Please enter a Tax ID or EIN #',
                            },
                          }}
                          description="We need to verify your business in order to publish your profile"
                        />

                        <Alert>
                          If you're looking to set up more than one BEHAVE|BRIDGE profile, please send an email to{' '}
                          <a href="mailto:ceo@behavehealth.com">ceo@behavehealth.com</a>.
                        </Alert>

                        <Heading is="h2">Organization Address</Heading>
                        <Input label="Address Line 1" model="address.line1" />
                        <Input label="Address Line 2" model="address.line2" />
                        <Input label="City" model="address.city" />
                        <Input label="County" model="address.county" />
                        <Input label="State" model="address.state" />
                        <Input label="Zip Code" model="address.zipCode" size={8} />
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* TREATMENT FACILITY DETAILS */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'treatment_facility_details',
                      name: 'Treatment Facility Details',
                      icon: 'test_results',
                    }}
                  >
                    <Accordion isSticky title="Treatment Facility Details" icon="test_results" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        {/* Operated By */}
                        <DynamicRadioGroup
                          label="Is this facility operated by…"
                          object={FACILITY_INFORMATION.operatedBy}
                          model="facilityInformation.operatedBy"
                        />

                        {/* Federal Government Agency */}
                        <ContextShow when="facilityInformation.operatedBy" is="Federal_government">
                          <DynamicRadioGroup
                            label="Which Federal Government Agency?"
                            object={FACILITY_INFORMATION.federalGovernmentAgency}
                            model="facilityInformation.federalGovernmentAgency"
                          />
                        </ContextShow>

                        {/* Organization Type */}
                        <ContextShow when="facilityInformation.operatedBy" not={null}>
                          <CheckboxGroup label="Organization Type" layout="vertical-dense" labelDescription="(Mark all that apply)">
                            <Checkbox label="Treatment Center" model="isTreatmentCenter" description="Outpatient, Residential, & Detox" />
                            <Checkbox
                              label="Housing Program"
                              model="isSoberLiving"
                              description="Sober Living, Transitional Housing, Halfway House, etc."
                            />
                          </CheckboxGroup>
                        </ContextShow>

                        {/* Service Settings Offered */}
                        <ContextShow orMode when={['isTreatmentCenter', 'isSoberLiving']} is={true}>
                          <CheckboxGroup label="Service Settings Offered" layout="vertical-dense">
                            <ContextShow when="isTreatmentCenter" is={true}>
                              <Checkbox label="Hospital Inpatient" model="facilityInformation.serviceSettings.Hospital_inpatient" />
                              <Checkbox label="Residential (Non-hospital)" model="facilityInformation.serviceSettings.Residential" />
                              <Checkbox label="Outpatient (PHP, IOP, OP)" model="facilityInformation.serviceSettings.Outpatient" />
                            </ContextShow>
                            <ContextShow when="isSoberLiving" is={true}>
                              <Checkbox
                                label="Housing (Sober Living, Halfway House, Transitional Housing etc.)"
                                model="facilityInformation.serviceSettings.Housing"
                                defaultChecked
                                isDisabled
                              />
                            </ContextShow>
                          </CheckboxGroup>
                        </ContextShow>

                        {/* Hospital Inpatient */}
                        <ContextShow when="facilityInformation.serviceSettings.Hospital_inpatient" is={true}>
                          <H2>Hospital Inpatient Levels of Care</H2>

                          <AsamAlert />

                          <CheckboxGroup
                            label="What levels of care do you offer within the Hospital Inpatient service setting?"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Hospital Inpatient Detoxification"
                              description="Similar to ASAM Levels IV-D and III.7-D, medically managed or monitored inpatient detoxification"
                              model="facilityInformation.hospitalInpatientLevelsOfCare.Hospital_inpatient_detoxification"
                            />
                            <Checkbox
                              label="Hospital Inpatient Treatment"
                              description="Similar to ASAM Levels IV and III.7, medically managed or monitored intensive inpatient treatment"
                              model="facilityInformation.hospitalInpatientLevelsOfCare.Hospital_inpatient_treatment"
                            />
                          </CheckboxGroup>

                          <Input
                            type="number"
                            label="Hospital Inpatient Capacity"
                            model="facilityInformation.hospitalInpatientCapacity"
                            size={8}
                            validations={{
                              presence: {
                                message: 'Please enter the Hospital Inpatient Capacity',
                              },
                            }}
                          />

                          <Input
                            label="Hospital Inpatient Facility NPI #"
                            model="facilityInformation.hospitalInpatientNpi"
                            validations={{
                              presence: {
                                message: 'Please enter the Hospital Inpatient Facility NPI #',
                              },
                            }}
                          />
                        </ContextShow>

                        {/* Residential */}
                        <ContextShow when="facilityInformation.serviceSettings.Residential" is={true}>
                          <H2>Residential Levels of Care</H2>

                          <AsamAlert />

                          <CheckboxGroup
                            label="What levels of care do you offer within the Residential service setting?"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Residential Detoxification"
                              description="Similar to ASAM Level III.2-D, clinically managed residential detoxification or social detoxification"
                              model="facilityInformation.residentialLevelsOfCare.Residential_detoxification"
                            />
                            <Checkbox
                              label="Residential Short-Term Treatment"
                              description="Similar to ASAM Level III.5, clinically managed high-intensity residential treatment, typically 30 days or less"
                              model="facilityInformation.residentialLevelsOfCare.Residential_short_term_treatment"
                            />
                            <Checkbox
                              label="Residential Long-Term Treatment"
                              description="Similar to ASAM Levels III.3 and III.1, clinically managed medium- or low-intensity residential treatment, typically more than 30 days"
                              model="facilityInformation.residentialLevelsOfCare.Residential_long_term_treatment"
                            />
                          </CheckboxGroup>

                          <Input
                            type="number"
                            label="Residential Capacity"
                            model="facilityInformation.residentialCapacity"
                            size={8}
                            validations={{
                              presence: {
                                message: 'Please enter the Residential Capacity',
                              },
                            }}
                          />

                          <Input
                            label="Residential Facility NPI #"
                            model="facilityInformation.residentialNpi"
                            validations={{
                              presence: {
                                message: 'Please enter the Residential Facility NPI #',
                              },
                            }}
                          />
                        </ContextShow>

                        {/* Outpatient */}
                        <ContextShow when="facilityInformation.serviceSettings.Outpatient" is={true}>
                          <H2>Outpatient Levels of Care</H2>

                          <AsamAlert />

                          <CheckboxGroup
                            label="What levels of care do you offer within the Outpatient service setting?"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Outpatient Detoxification"
                              description="Similar to ASAM Levels I-D and II-D, ambulatory detoxification"
                              model="facilityInformation.outpatientLevelsOfCare.Outpatient_detoxification"
                            />
                            <Checkbox
                              label="Outpatient Day Treatment or Partial Hospitalization"
                              description="Similar to ASAM Level II.5, 20 or more hours per week"
                              model="facilityInformation.outpatientLevelsOfCare.Outpatient_day_treatment"
                            />
                            <Checkbox
                              label="Intensive Outpatient Treatment (IOP)"
                              description="Similar to ASAM Level II.1, 9 or more hours per week"
                              model="facilityInformation.outpatientLevelsOfCare.Intensive_outpatient_treatment"
                            />
                            <Checkbox
                              label="Regular Outpatient Treatment (OP)"
                              description="Similar to ASAM Level I, outpatient treatment, non-intensive"
                              model="facilityInformation.outpatientLevelsOfCare.Regular_outpatient_treatment"
                            />
                            <Checkbox
                              label="Medication-Assisted Treatment (MAT)"
                              description="Methadone / buprenorphine maintenance or naltrexone treatment"
                              model="facilityInformation.outpatientLevelsOfCare.Medication_assisted_treatment"
                            />
                          </CheckboxGroup>

                          <Input
                            type="number"
                            label="Outpatient Capacity"
                            model="facilityInformation.outpatientCapacity"
                            size={8}
                            validations={{
                              presence: {
                                message: 'Please enter the Outpatient Capacity',
                              },
                            }}
                          />

                          <Input
                            label="Outpatient Facility NPI #"
                            model="facilityInformation.outpatientNpi"
                            validations={{
                              presence: {
                                message: 'Please enter the Outpatient Facility NPI #',
                              },
                            }}
                          />
                        </ContextShow>

                        {/* Housing */}
                        <ContextShow when="isSoberLiving" is={true}>
                          <H2>Housing Levels Of Support</H2>

                          <Alert glyph="info">
                            Visit the{' '}
                            <Link href="https://narronline.org/affiliate-services/standards-and-certification-program/" target="_blank">
                              National Alliance of Recovery Residences Website
                            </Link>{' '}
                            for more information on the levels of support.
                          </Alert>

                          <CheckboxGroup
                            label="What levels of support (NARR) do you offer at your sober living, halfway house, and/or transitional housing programs?"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Level 4"
                              description="Service Provider"
                              model="facilityInformation.housingLevelsOfSupport.Level_4"
                            />
                            <Checkbox label="Level 3" description="Supervised" model="facilityInformation.housingLevelsOfSupport.Level_3" />
                            <Checkbox label="Level 2" description="Monitored" model="facilityInformation.housingLevelsOfSupport.Level_2" />
                            <Checkbox label="Level 1" description="Peer Run" model="facilityInformation.housingLevelsOfSupport.Level_1" />
                          </CheckboxGroup>

                          <Input
                            type="number"
                            label="Housing Capacity"
                            model="facilityInformation.housingCapacity"
                            size={8}
                            validations={{
                              presence: {
                                message: 'Please enter the Housing Capacity',
                              },
                            }}
                          />

                          <Input
                            label="Housing Licence #"
                            model="facilityInformation.housingLicence"
                            description="Some states offer housing program licences"
                          />
                        </ContextShow>

                        <ContextShow when="isTreatmentCenter" is={true}>
                          {/* Licensure Status */}
                          <DynamicRadioGroup
                            label="Licensure Status"
                            object={FACILITY_INFORMATION.licensureStatus}
                            model="facilityInformation.licensureStatus"
                            description="Select the choice that best describes the current treatment center status"
                          />

                          {/* Licensure Authority */}
                          <ContextShow when="facilityInformation.licensureStatus" within={['Active', 'Inactive', 'Pending']}>
                            <DynamicCheckboxGroup
                              label="Licensure Authority"
                              object={FACILITY_INFORMATION.licensureAuthority}
                              model="facilityInformation.licensureAuthority"
                            />
                          </ContextShow>

                          {/* Accreditation Status */}
                          <ContextShow when="facilityInformation.licensureStatus" within={['Active', 'Inactive']}>
                            <DynamicRadioGroup
                              label="Accreditation Status"
                              object={FACILITY_INFORMATION.accreditationStatus}
                              model="facilityInformation.accreditationStatus"
                            />
                          </ContextShow>

                          {/* Accreditation Organizations */}
                          <ContextShow when="accreditation_status" within={['currently_accredited', 'accreditation_in_progress']}>
                            <DynamicCheckboxGroup
                              label="Please select the accreditation organization(s)"
                              object={FACILITY_INFORMATION.accreditationOrganizations}
                              model="facilityInformation.accreditationOrganizations"
                            />
                          </ContextShow>
                        </ContextShow>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* CLINICAL & MEDICAL SERVICES */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'clinical_medical_services',
                      name: 'Clinical & Medical Services',
                      icon: 'clinical_notes',
                    }}
                  >
                    <Accordion isSticky title="Clinical & Medical Services" icon="clinical_notes" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <DynamicRadioGroup
                          label="What is the primary focus of this facility?"
                          object={FACILITY_INFORMATION.primaryFocus}
                          model="facilityInformation.primaryFocus"
                        />

                        <DynamicCheckboxGroup
                          label="Which of the following substance abuse services are offered by this facility?"
                          object={FACILITY_INFORMATION.substanceAbuseServices}
                          model="facilityInformation.substanceAbuseServices"
                        />

                        <DynamicCheckboxGroup
                          label="To which of the following clients does this facility, at this location, offer mental health treatment services?"
                          labelDescription="(Mark all that apply)"
                          description="Mental health treatment services include interventions such as therapy or psychotropic medication that treat a person’s mental health problem or condition, reduce symptoms, and improve behavioral functioning and outcomes."
                          object={FACILITY_INFORMATION.mentalHealthServicesClientType}
                          model="facilityInformation.mentalHealthServicesClientType"
                        />

                        <ContextShow when="facilityInformation.substanceAbuseServices.Detoxification" is={true}>
                          <DynamicCheckboxGroup
                            label="What substances does this facility detoxify clients from?"
                            labelDescription="(Mark all that apply)"
                            object={FACILITY_INFORMATION.detoxifiedSubstances}
                            model="facilityInformation.detoxifiedSubstances"
                          />

                          <YesNoRadio
                            label="Does this facility routinely use medications during detoxification?"
                            model="facilityInformation.isUsingDetoxMedsRoutinely"
                          />
                        </ContextShow>

                        <ContextShow when="facilityInformation.substanceAbuseServices.Substance_abuse_treatment" is={true}>
                          <YesNoRadio
                            label="Is this facility a jail, prison, or other organization that provides treatment exclusively for incarcerated persons or juvenile detainees?"
                            model="facilityInformation.isJail"
                          />

                          <ContextShow when="facilityInformation.isJail" is={false}>
                            <YesNoRadio
                              label="Is this facility a solo practice, meaning, an office with only one independent practitioner or counselor?"
                              model="facilityInformation.isSoloPractice"
                            />
                          </ContextShow>
                        </ContextShow>

                        <DynamicCheckboxGroup
                          label="Assessment and Pre-Treatment Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.assessmentPreTreatmentServices}
                          model="facilityInformation.assessmentPreTreatmentServices"
                        />

                        <DynamicCheckboxGroup
                          label="Testing"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.testing}
                          model="facilityInformation.testing"
                          description="Include tests performed at this location, even if the specimen is sent to an outside source for chemical analysis."
                          tooltip="Include tests performed at this location, even if the specimen is sent to an outside source for chemical analysis."
                        />

                        <DynamicCheckboxGroup
                          label="Medical Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.medicalServices}
                          model="facilityInformation.medicalServices"
                        />

                        <DynamicCheckboxGroup
                          label="Transitional Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.transitionalServices}
                          model="facilityInformation.transitionalServices"
                        />

                        <DynamicCheckboxGroup
                          label="Recovery Support Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.recoverySupportServices}
                          model="facilityInformation.recoverySupportServices"
                        />

                        <DynamicCheckboxGroup
                          label="Education and Counseling Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.educationCounselingServices}
                          model="facilityInformation.educationCounselingServices"
                        />

                        <CheckboxGroup
                          label="Clinical / Therapeutic Approaches"
                          labelDescription="(Mark all that apply)"
                          layout="vertical-dense"
                        >
                          <Checkbox
                            label="Substance abuse counseling"
                            model="facilityInformation.clinicalTherapeuticApproaches.Substance_abuse_counseling"
                            tooltip={tooltips.substance_abuse_counseling}
                          />
                          <Checkbox
                            label="12-step facilitation"
                            model="facilityInformation.clinicalTherapeuticApproaches.Twelve_step_facilitation"
                            tooltip={tooltips.twelve_step_facilitation}
                          />
                          <Checkbox
                            label="Brief intervention"
                            model="facilityInformation.clinicalTherapeuticApproaches.Brief_intervention"
                            tooltip={tooltips.brief_intervention}
                          />
                          <Checkbox
                            label="Cognitive behavioral therapy"
                            model="facilityInformation.clinicalTherapeuticApproaches.Cognitive_behavioral_therapy"
                            tooltip={tooltips.cbt}
                          />
                          <Checkbox
                            label="Dialectical behavior therapy"
                            model="facilityInformation.clinicalTherapeuticApproaches.Dialectical_behavior_therapy"
                            tooltip={tooltips.dbt}
                          />
                          <Checkbox
                            label="Contingency management/motivational incentives"
                            model="facilityInformation.clinicalTherapeuticApproaches.Contingency_management_motivational_incentives"
                            tooltip={tooltips.contingency_management}
                          />
                          <Checkbox
                            label="Motivational interviewing"
                            model="facilityInformation.clinicalTherapeuticApproaches.Motivational_interviewing"
                            tooltip={tooltips.motivational_interviewing}
                          />
                          <Checkbox
                            label="Trauma-related counseling"
                            model="facilityInformation.clinicalTherapeuticApproaches.Trauma_related_counseling"
                            tooltip={tooltips.trauma_related_counseling}
                          />
                          <Checkbox
                            label="Anger management"
                            model="facilityInformation.clinicalTherapeuticApproaches.Anger_management"
                            tooltip={tooltips.anger_management}
                          />
                          <Checkbox
                            label="Matrix Model"
                            model="facilityInformation.clinicalTherapeuticApproaches.Matrix_model"
                            tooltip={tooltips.matrix_model}
                          />
                          <Checkbox
                            label="Community reinforcement plus vouchers"
                            model="facilityInformation.clinicalTherapeuticApproaches.Community_reinforcement_plus_vouchers"
                            tooltip={tooltips.community_reinforcement_plus_vouchers}
                          />
                          <Checkbox
                            label="Rational emotive behavioral therapy (REBT)"
                            model="facilityInformation.clinicalTherapeuticApproaches.Rational_emotive_behavioral_therapy"
                            tooltip={tooltips.rebt}
                          />
                          <Checkbox
                            label="Relapse prevention"
                            model="facilityInformation.clinicalTherapeuticApproaches.Relapse_prevention"
                            tooltip={tooltips.relapse_prevention}
                          />
                          <Checkbox
                            label="Telemedicine/telehealth (including Internet, Web, mobile, and desktop programs)"
                            model="facilityInformation.clinicalTherapeuticApproaches.Telemedicine_telehealth"
                            tooltip={tooltips.telemedicine}
                          />
                          <Checkbox
                            label="We do not use any of these clinical/therapeutic approaches"
                            model="facilityInformation.clinicalTherapeuticApproaches.None"
                          />
                        </CheckboxGroup>

                        <DynamicCheckboxGroup
                          label="Ancillary Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.ancillaryServices}
                          model="facilityInformation.ancillaryServices"
                        />

                        <DynamicCheckboxGroup
                          label="Other Services"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.otherServices}
                          model="facilityInformation.otherServices"
                        />

                        <DynamicCheckboxGroup
                          label="Pharmacotherapies"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.pharmacotherapies}
                          model="facilityInformation.pharmacotherapies"
                        />

                        <DynamicCheckboxGroup
                          label="Additional Therapeutic Pathways"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.additionalTherapeuticPathways}
                          model="facilityInformation.additionalTherapeuticPathways"
                        />

                        <DynamicCheckboxGroup
                          label="Program Lengths Available"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.programLengthsAvailable}
                          model="facilityInformation.programLengthsAvailable"
                        />

                        <DynamicCheckboxGroup
                          label="Faith Based and Other Programs"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.faithBasedProgramming}
                          model="facilityInformation.faithBasedProgramming"
                        />

                        <DynamicCheckboxGroup
                          label="Support Group Meetings Available Locally"
                          object={FACILITY_INFORMATION.localSupportGroupMeetings}
                          model="facilityInformation.localSupportGroupMeetings"
                        />
                      </FormSection>

                      <Heading is="h2" className="!mt-4 !mx-0 !mb-2">
                        Opioid Use Disorder Treatment
                      </Heading>

                      <FormSection layout="vertical" maxWidth={600}>
                        <YesNoRadio
                          label="Is this facility treating opioid use disorder?"
                          model="facilityInformation.isTreatingOpioidUseDisorder"
                        />

                        <ContextShow when="facilityInformation.isTreatingOpioidUseDisorder" is={true}>
                          <FormSection layout="vertical" maxWidth={600}>
                            <DynamicCheckboxGroup
                              label="How does this facility treat opioid use disorder?"
                              labelDescription="(Mark all that apply)"
                              description="Medication assisted treatment (MAT) includes the use of methadone, buprenorphine and/or naltrexone for the treatment of opioid use disorder."
                              object={FACILITY_INFORMATION.opioidTreatmentMethods}
                              model="facilityInformation.opioidTreatmentMethods"
                            />
                            <ContextShow
                              orMode
                              when={[
                                'facilityInformation.opioidTreatmentMethods.Using_prescribed_mat',
                                'facilityInformation.opioidTreatmentMethods.Administers_naltrexone',
                                'facilityInformation.opioidTreatmentMethods.Prescribes_buprenorphine',
                                'facilityInformation.opioidTreatmentMethods.Federally_certified_otp',
                              ]}
                              is={true}
                            >
                              <ContextShow when="facilityInformation.opioidTreatmentMethods.Using_prescribed_mat" is={true}>
                                <DynamicCheckboxGroup
                                  label="For those clients using MAT, but whose medications originate from or are prescribed by another entity, from where do these clients obtain their medications?"
                                  object={FACILITY_INFORMATION.matClientsMedicationSource}
                                  model="facilityInformation.matOudClientsMedicationSource"
                                />
                              </ContextShow>
                            </ContextShow>

                            <YesNoRadio
                              label="Does this facility serve only opioid use disorder clients?"
                              model="facilityInformation.isServingOnlyOudClients"
                            />

                            <DynamicCheckboxGroup
                              label="Which of the following medication services does this program provide?"
                              labelDescription="(Mark all that apply)"
                              object={FACILITY_INFORMATION.medicationServices}
                              model="facilityInformation.medicationServices"
                            />
                          </FormSection>
                        </ContextShow>
                      </FormSection>

                      <Heading is="h2" className="!mt-4 !mx-0 !mb-2">
                        Alcohol Use Disorder Treatment
                      </Heading>

                      <FormSection layout="vertical" maxWidth={600}>
                        <YesNoRadio
                          label="Is this facility treating alcohol use disorder?"
                          model="facilityInformation.isTreatingAlcoholUseDisorder"
                        />

                        <ContextShow when="facilityInformation.isTreatingAlcoholUseDisorder" is={true}>
                          <DynamicCheckboxGroup
                            label="How does this facility treat alcohol use disorder?"
                            labelDescription="(Mark all that apply)"
                            description="These medications have been approved by the FDA to treat alcohol use disorder: naltrexone, acamprosate, and disulfiram."
                            object={FACILITY_INFORMATION.alcoholTreatmentMethods}
                            model="facilityInformation.alcoholTreatmentMethods"
                          />

                          <ContextShow when="facilityInformation.alcoholTreatmentMethods.Using_prescribed_mat" is={true}>
                            <DynamicCheckboxGroup
                              label="For those clients using MAT for alcohol use disorder, but whose medications originate from or are prescribed by another entity, from where do these clients obtain their medications?"
                              object={FACILITY_INFORMATION.matClientsMedicationSource}
                              model="facilityInformation.matAudClientsMedicationSource"
                            />
                          </ContextShow>

                          <RadioGroup
                            label="Does this facility serve only alcohol use disorder clients?"
                            model="facilityInformation.isServingOnlyAudClients"
                            layout="horizontal-dense"
                          >
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </ContextShow>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* CLIENT POPULATION SERVED */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'client_population_served',
                      name: 'Client Population Served',
                      icon: 'clients',
                    }}
                  >
                    <Accordion isSticky title="Client Population Served" icon="clients" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <DynamicRadioGroup
                          label="TYPE OF CLIENT - Individuals seeking substance abuse treatment can vary by age, gender or other characteristics. Which categories of individuals listed below are served by this facility?"
                          object={FACILITY_INFORMATION.genderAccepted}
                          model="facilityInformation.genderAccepted"
                        />

                        <ContextShow when="facilityInformation.genderAccepted" within={['Female', 'Female_male']}>
                          <RadioGroup
                            label="Female Client - Minimum Age"
                            model="facilityInformation.hasFemaleMinAge"
                            layout="vertical-dense"
                            description="Indicate only the highest or lowest age the facility would accept. Do not indicate the highest or lowest age currently receiving services in the facility."
                          >
                            <Radio label="No we do NOT have a minimum age for female clients" value={false} />
                            <Radio label="Yes we have a minimum age for female clients" value={true} />
                          </RadioGroup>

                          <ContextShow when="facilityInformation.hasFemaleMinAge" is={true}>
                            <AgeInput label="What is the minimum age for female clients?" model="facilityInformation.femaleMinAge" />
                          </ContextShow>

                          <RadioGroup
                            label="Female Client - Maximum Age"
                            model="facilityInformation.hasFemaleMaxAge"
                            layout="vertical-dense"
                            description="Indicate only the highest or lowest age the facility would accept. Do not indicate the highest or lowest age currently receiving services in the facility."
                          >
                            <Radio label="No we do NOT have a maximum age for female clients" value={false} />
                            <Radio label="Yes we have a maximum age for female clients" value={true} />
                          </RadioGroup>

                          <ContextShow when="facilityInformation.hasFemaleMaxAge" is={true}>
                            <AgeInput label="What is the maximum age for female clients?" model="facilityInformation.femaleMaxAge" />
                          </ContextShow>
                        </ContextShow>

                        <ContextShow when="facilityInformation.genderAccepted" within={['Male', 'Female_male']}>
                          <RadioGroup
                            label="Male Client - Minimum Age"
                            model="facilityInformation.hasMaleMinAge"
                            layout="vertical-dense"
                            description="Indicate only the highest or lowest age the facility would accept. Do not indicate the highest or lowest age currently receiving services in the facility."
                          >
                            <Radio label="No we do NOT have a minimum age for male clients" value={false} />
                            <Radio label="Yes we have a minimum age for male clients" value={true} />
                          </RadioGroup>

                          <ContextShow when="facilityInformation.hasMaleMinAge" is={true}>
                            <AgeInput label="What is the minimum age for male clients?" model="facilityInformation.maleMinAge" />
                          </ContextShow>

                          <RadioGroup
                            label="Male Client - Maximum Age"
                            model="facilityInformation.hasMaleMaxAge"
                            layout="vertical-dense"
                            description="Indicate only the highest or lowest age the facility would accept. Do not indicate the highest or lowest age currently receiving services in the facility."
                          >
                            <Radio label="No we do NOT have a maximum age for male clients" value={false} />
                            <Radio label="Yes we have a maximum age for male clients" value={true} />
                          </RadioGroup>

                          <ContextShow when="facilityInformation.hasMaleMaxAge" is={true}>
                            <AgeInput label="What is the maximum age for male clients?" model="facilityInformation.maleMaxAge" />
                          </ContextShow>
                        </ContextShow>

                        <DynamicCheckboxGroup
                          label="Which client categories does this facility offer a substance abuse treatment program or group specifically tailored for clients in that category?"
                          description="If this facility treats clients in any of these categories but does not have a specifically tailored program or group for them, do not mark the box for that category."
                          tooltip="If this facility treats clients in any of these categories but does not have a specifically tailored program or group for them, do not mark the box for that category."
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.clientCategoriesAccepted}
                          model="facilityInformation.clientCategoriesAccepted"
                        />
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* LANGUAGE DETAILS */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'language_details',
                      name: 'Language Details',
                      icon: 'gps_check_in_out',
                    }}
                  >
                    <Accordion isSticky title="Language Details" icon="gps_check_in_out" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <RadioGroup
                          label="Does this facility provide substance abuse treatment services in sign language at this location for the deaf and hard of hearing (for example, American Sign Language, Signed English, or Cued Speech)?"
                          model="facilityInformation.hasSignLanguages"
                          layout="horizontal-dense"
                          description="Mark 'yes' if either a staff counselor or an on-call interpreter provides this service."
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        <RadioGroup
                          label="Does this facility provide substance abuse treatment services in a language other than English at this location?"
                          model="facilityInformation.hasOtherLanguages"
                          layout="horizontal-dense"
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        <ContextShow when="facilityInformation.hasOtherLanguages" is={true}>
                          <CheckboxGroup
                            label="At this facility, who provides substance abuse treatment services in a language other than English?"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Staff counselor who speaks a language other than English"
                              model="facilityInformation.hasStaffCounselor"
                            />
                            <Checkbox
                              label="On-call interpreter (in person or by phone) brought in when needed"
                              model="facilityInformation.hasOnCallInterpreter"
                            />
                          </CheckboxGroup>

                          <ContextShow when="facilityInformation.hasStaffCounselor" is={true}>
                            <DynamicCheckboxGroup
                              label="In what other languages do staff counselors provide substance abuse treatment at this facility?"
                              labelDescription="(Mark all that apply)"
                              object={FACILITY_INFORMATION.staffCounselorLanguages}
                              model="facilityInformation.staffCounselorLanguages"
                              description="Do not count languages provided only by on-call interpreters"
                            />

                            <DynamicCheckboxGroup
                              label="American Indian or Alaska Native"
                              labelDescription="(Mark all that apply)"
                              object={FACILITY_INFORMATION.staffCounselorNativeLanguages}
                              model="facilityInformation.staffCounselorNativeLanguages"
                              description="Do not count languages provided only by on-call interpreters"
                            />
                          </ContextShow>
                        </ContextShow>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* FINANCIAL DETAILS */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'financial_details',
                      name: 'Financial Details',
                      icon: 'financials',
                    }}
                  >
                    <Accordion isSticky title="Financial Details" icon="financials" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <DynamicCheckboxGroup
                          label="Which of the following types of client payments or insurance are accepted by this facility for substance abuse treatment?"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.clientPaymentMethods}
                          model="facilityInformation.clientPaymentMethods"
                        />

                        {/* Cash */}
                        <ContextShow when="facilityInformation.clientPaymentMethods.Cash_or_self_payment" is={true}>
                          <AmountInput label="Cash Pay Price" model="facilityInformation.cashPayPrice" />

                          <DynamicRadioGroup
                            label="Cash Pay Interval"
                            object={FACILITY_INFORMATION.cashPayInterval}
                            model="facilityInformation.cashPayInterval"
                          />
                        </ContextShow>

                        {/* In Network Insurance */}
                        <ContextShow when="facilityInformation.clientPaymentMethods.In_network_private_insurance" is={true}>
                          <MultiObjectSelector
                            isRelations={false}
                            model="facilityInformation.inNetworkInsuranceCompanies"
                            label="Insurance Companies"
                            apiData={InsuranceCompanies}
                            validations={{
                              presence: {
                                message: 'Please select positive test results',
                              },
                            }}
                          />
                        </ContextShow>

                        {/* Sliding Fee Scale */}
                        <RadioGroup
                          label="Does this facility use a sliding fee scale?"
                          model="facilityInformation.hasSlidingFeeScale"
                          layout="horizontal-dense"
                          description="Sliding fee scales are based on income and other factors."
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        {/* Free Treatment or Minimal Payment */}
                        <RadioGroup
                          label="Does this facility offer treatment at no charge or minimal payment (for example, $1) to clients who cannot afford to pay?"
                          model="facilityInformation.hasFreeTreatmentOrMinimalPayment"
                          layout="horizontal-dense"
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        {/* Funding or Grants */}
                        <RadioGroup
                          label="Does this facility receive any funding or grants from the Federal Government, or state, county or local governments, to support its substance abuse treatment programs?"
                          model="facilityInformation.doesReceiveFundingOrGrants"
                          layout="horizontal"
                          description="Do not include Medicare, Medicaid, or federal military insurance."
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* AMENITIES & INFRASTRUCTURE */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'amenities_infrastructure',
                      name: 'Amenities & Infrastructure',
                      icon: 'patient_outcome',
                    }}
                  >
                    <Accordion isSticky title="Amenities & Infrastructure" icon="patient_outcome" appearance="card" permission={true}>
                      <Grid columns="repeat(auto-fit, minmax(320px, 1fr)" gap={1}>
                        <DynamicCheckboxGroup
                          label="Fitness"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.fitness}
                          model="facilityInformation.fitness"
                        />

                        <DynamicCheckboxGroup
                          label="Premium Amenities"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.premiumAmenities}
                          model="facilityInformation.premiumAmenities"
                        />

                        <DynamicCheckboxGroup
                          label="Facility Infrastructure"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.infrastructure}
                          model="facilityInformation.infrastructure"
                        />

                        <DynamicCheckboxGroup
                          label="Transportation"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.transportation}
                          model="facilityInformation.transportation"
                        />

                        <DynamicCheckboxGroup
                          label="Recreation"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.recreation}
                          model="facilityInformation.recreation"
                        />

                        <DynamicCheckboxGroup
                          label="Accommodations"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.accommodations}
                          model="facilityInformation.accommodations"
                        />

                        <DynamicRadioGroup
                          label="Which of the following statements BEST describes this facility’s smoking policy for clients?"
                          object={FACILITY_INFORMATION.smokingPolicy}
                          model="facilityInformation.smokingPolicy"
                        />

                        <DynamicRadioGroup
                          label="Describe the location of your facility"
                          object={FACILITY_INFORMATION.locationType}
                          model="facilityInformation.locationType"
                        />

                        <DynamicCheckboxGroup
                          label="Natural environment of your facility"
                          labelDescription="(Mark all that apply)"
                          object={FACILITY_INFORMATION.naturalEnvironment}
                          model="facilityInformation.naturalEnvironment"
                        />
                      </Grid>
                    </Accordion>
                  </Section>

                  {/* TREATMENT PROGRAM DETAILS */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'treatment_program_details',
                      name: 'Treatment Program Details',
                      icon: 'housing_shifts',
                    }}
                  >
                    <Accordion isSticky title="Treatment Program Details" icon="housing_shifts" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <RadioGroup
                          label="Do you want us to showcase your program schedule?"
                          model="internal.should_showcase_program_schedule"
                          layout="horizontal-dense"
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        <ContextShow when="internal.should_showcase_program_schedule" is={true}>
                          <Attachments isInline label="Upload Program Schedule" model="facilityInformation.programSchedulePhoto" />
                        </ContextShow>

                        <RadioGroup
                          label="Do you want to highlight specific parts of your program?"
                          model="internal.should_highlight_specific_program_parts"
                          layout="horizontal-dense"
                          description="Example: Outdoor Program"
                        >
                          <Radio label="Yes" value={true} />
                          <Radio label="No" value={false} />
                        </RadioGroup>

                        <ContextShow when="internal.should_highlight_specific_program_parts" is={true}>
                          <ProgramHighlights model="program_highlights" />
                        </ContextShow>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* WEB PRESENCE */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'web_presence',
                      name: 'Web Presence',
                      icon: 'mobile',
                    }}
                  >
                    <Accordion isSticky title="Web Presence" icon="mobile" appearance="card" permission={true}>
                      <FormSection layout="vertical" maxWidth={600}>
                        <CheckboxGroup label="Online Web Presence" layout="vertical-dense">
                          <Checkbox label="Website" model="web_presence.website" />
                          <Checkbox label="Facebook" model="web_presence.facebook" />
                          <Checkbox label="LinkedIn" model="web_presence.linkedin" />
                          <Checkbox label="Twitter" model="web_presence.twitter" />
                          <Checkbox label="Instagram" model="web_presence.instagram" />
                          <Checkbox label="Youtube" model="web_presence.youtube" />
                          <Checkbox label="Pinterest" model="web_presence.pinterest" />
                        </CheckboxGroup>

                        <ContextShow when="web_presence.website" is={true}>
                          <Input label="Organization Website Link" model="contactMethod.website" />
                        </ContextShow>

                        <ContextShow when="web_presence.facebook" is={true}>
                          <Input label="Organization Facebook Link" model="socialMedia.facebook" />
                        </ContextShow>

                        <ContextShow when="web_presence.linkedin" is={true}>
                          <Input label="Organization LinkedIn Link" model="socialMedia.linkedin" />
                        </ContextShow>

                        <ContextShow when="web_presence.twitter" is={true}>
                          <Input label="Organization Twitter Link" model="socialMedia.twitter" />
                        </ContextShow>

                        <ContextShow when="web_presence.instagram" is={true}>
                          <Input label="Organization Instagram Link" model="socialMedia.instagram" />
                        </ContextShow>

                        <ContextShow when="web_presence.youtube" is={true}>
                          <Input label="Organization YouTube Channel Link" model="socialMedia.youtube" />
                        </ContextShow>

                        <ContextShow when="web_presence.pinterest" is={true}>
                          <Input label="Organization Pinterest Link" model="socialMedia.pinterest" />
                        </ContextShow>

                        <ContextShow
                          orMode
                          when={[
                            'web_presence.website',
                            'web_presence.facebook',
                            'web_presence.linkedin',
                            'web_presence.twitter',
                            'web_presence.instagram',
                            'web_presence.youtube',
                            'web_presence.pinterest',
                          ]}
                          is={true}
                        >
                          <Alert type="default" glyph="info" className="!mt-[1em]">
                            <Heading is="h3">Please complete this action list to claim and unlock your free BRIDGE account.</Heading>
                          </Alert>

                          <ContextShow when="web_presence.website" is={true}>
                            <Input
                              label="Create button or link from your website to your BEHAVE|BRIDGE page"
                              model="website_verify_link"
                              description="Place the link to the webpage so we can verify it is working."
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.facebook" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization FACEBOOK page"
                              model="facebook_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://www.facebook.com/behavehealth/" target="_blank">
                                      https://www.facebook.com/behavehealth/
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.linkedin" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization LINKEDIN page"
                              model="linkedin_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://www.linkedin.com/company/behavehealth/" target="_blank">
                                      https://www.linkedin.com/company/behavehealth/
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.twitter" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization TWITTER page"
                              model="twitter_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://twitter.com/behavehealth" target="_blank">
                                      https://twitter.com/behavehealth
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.instagram" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization INSTAGRAM page"
                              model="instagram_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://instagram.com/behavehealth" target="_blank">
                                      https://instagram.com/behavehealth
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.youtube" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization YOUTUBE page"
                              model="youtube_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://www.youtube.com/channel/UCzAqIOXcbBntApChF3G2OwA" target="_blank">
                                      https://www.youtube.com/channel/UCzAqIOXcbBntApChF3G2OwA
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>

                          <ContextShow when="web_presence.pinterest" is={true}>
                            <Input
                              label="Share your BEHAVE|BRIDGE page on your organization PINTEREST page"
                              model="pinterest_verify_link"
                              description={
                                <>
                                  <div>Make sure this is a link to your page. We verify every page!</div>
                                  <div>
                                    <strong>Example: </strong>
                                    <Link href="https://pinterest.com/behavehealth" target="_blank">
                                      https://pinterest.com/behavehealth
                                    </Link>
                                  </div>
                                </>
                              }
                            />
                          </ContextShow>
                        </ContextShow>
                      </FormSection>
                    </Accordion>
                  </Section>

                  {/* PROPERTIES / OFFICES */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'properties_offices',
                      name: 'Properties / Offices',
                      icon: 'properties',
                    }}
                  >
                    <Accordion isSticky title="Properties/Offices" icon="properties" appearance="card" permission={true}>
                      <Properties model="properties" />
                    </Accordion>
                  </Section>

                  {/* TEAM */}
                  <Section className="!m-0" scrollview={{ id: 'team', name: 'Team', icon: 'employees' }}>
                    <Accordion isSticky title="Team" icon="employees" appearance="card" permission={true}>
                      <Team model="team_members" />
                    </Accordion>
                  </Section>

                  {/* FACILITY OPERATION SURVEY */}
                  <Section
                    className="!m-0"
                    scrollview={{
                      id: 'facility_operation_survey',
                      name: 'Facility Operation Survey',
                      icon: 'patient_progress_measure',
                    }}
                  >
                    <Accordion
                      isSticky
                      title="Facility Operation Survey"
                      icon="patient_progress_measure"
                      appearance="card"
                      permission={true}
                      isOpen
                    >
                      <FormSection layout="vertical" maxWidth={600}>
                        <Alert type="positive" glyph="info">
                          Based on this survey, you will learn how your facility compares to other organizations across the industry. This
                          will NOT be published on your BEHAVE|BRIDGE profile.
                        </Alert>

                        <H3>EHR</H3>
                        <CheckboxGroup
                          label="Current Software Solution / Features"
                          labelDescription="(Mark all that apply)"
                          layout="vertical-dense"
                        >
                          <Checkbox label="Electronic Health Records (EHR)" model="internal.current_software.ehr" />
                          <Checkbox label="Electronic Medication Prescriptions (eRX)" model="internal.current_software.erx" />
                          <Checkbox label="Patient & Staff Appointment Reminders" model="internal.current_software.appointment_reminders" />
                          <Checkbox label="Patient & Staff Task Tracking" model="internal.current_software.task_tracking" />
                          <Checkbox label="Telehealth Delivery" model="internal.current_software.telehealth_delivery" />
                          <Checkbox label="None" model="internal.current_software.none" />
                        </CheckboxGroup>

                        <ContextShow when="internal.current_software.ehr" is={true}>
                          <Input
                            label="What Electronic Health Records (EHR) vendor do you use?"
                            model="internal.current_ehr.name"
                            description="Please enter the name of your Electronic Health Records vendor"
                          />

                          <Select allowEmpty label="How do you feel about your current EHR?" model="internal.current_ehr.satisfaction">
                            <Option label="Very Satisfied" value="very_satisfied" />
                            <Option label="Satisfied" value="satisfied" />
                            <Option label="Neutral" value="neutral" />
                            <Option label="Unsatisfied" value="unsatisfied" />
                            <Option label="Very Unsatisfied" value="very_unsatisfied" />
                          </Select>
                        </ContextShow>

                        <H3>Insurance</H3>
                        <ContextShow
                          orMode
                          when={[
                            'facilityInformation.clientPaymentMethods.Out_of_network_private_insurance',
                            'facilityInformation.clientPaymentMethods.In_network_private_insurance',
                          ]}
                          is={true}
                        >
                          <RadioGroup
                            label="How do you handle private health insurance billing at your organization?"
                            model="internal.insurance_billing_handling"
                            layout="vertical-dense"
                            description="Please enter the name of your insurance billing service. Enter 'in-house' if you handle this internally. PLEASE NOTE THIS WILL NOT BE PUBLISHED"
                          >
                            <Radio label="In-House Billing Team" value="in_house_billing_team" />
                            <Radio label="3rd Party Billing Company" value="third_party_billing_company" />
                          </RadioGroup>

                          <ContextShow when="internal.insurance_billing_handling" is="third_party_billing_company">
                            <Input
                              label="What Insurance Billing Service Vendor do you use?"
                              model="internal.third_party_billing_company.name"
                              description="Please enter the name of your insurance billing service vendor"
                            />

                            <Select
                              label="How do you feel about the quality of service from your 3rd party billing vendor?"
                              model="internal.third_party_billing_company.satisfaction"
                            >
                              <Option label="Very Satisfied" value="very_satisfied" />
                              <Option label="Satisfied" value="satisfied" />
                              <Option label="Neutral" value="neutral" />
                              <Option label="Unsatisfied" value="unsatisfied" />
                              <Option label="Very Unsatisfied" value="very_unsatisfied" />
                            </Select>
                          </ContextShow>

                          <CheckboxGroup
                            label="Current Insurance & Financial Solutions"
                            labelDescription="(Mark all that apply)"
                            layout="vertical-dense"
                          >
                            <Checkbox
                              label="Instant Electronic Verification of Benefits (eVOB)"
                              model="internal.insurance_financial_solutions.evob"
                            />
                            <Checkbox
                              label="Completed Phone Call Verification Process"
                              model="internal.insurance_financial_solutions.phone_verification_process"
                            />
                            <Checkbox
                              label="Online Patient Responsibility Collections"
                              model="internal.insurance_financial_solutions.online_patient_responsibility_collections"
                            />
                            <Checkbox
                              label="Insurance Billing Data Analytics"
                              model="internal.insurance_financial_solutions.insurance_billing_data_analytics"
                            />
                            <Checkbox label="None" model="internal.insurance_financial_solutions.none" />
                          </CheckboxGroup>

                          <ContextShow when="internal.insurance_financial_solutions.evob" is={true}>
                            <Input
                              label="What Instant Electronic Verification of Benefits (eVOB) solution do you use?"
                              model="internal.evob.name"
                            />
                          </ContextShow>
                        </ContextShow>

                        <ContextShow
                          when={[
                            'facilityInformation.clientPaymentMethods.Out_of_network_private_insurance',
                            'facilityInformation.clientPaymentMethods.In_network_private_insurance',
                          ]}
                          not={true}
                        >
                          <RadioGroup
                            label="Since you do not currently take private insurance, are you interested in starting if it was easy?"
                            model="internal.is_interested_in_accepting_insurance"
                            layout="vertical-dense"
                          >
                            <Radio label="Yes, tell me more" value="yes" />
                            <Radio label="Not yet, but maybe someday" value="unknown" />
                            <Radio label="Not interested" value="no" />
                          </RadioGroup>
                        </ContextShow>

                        <RadioGroup
                          label="Are you interested in our integrated private insurance billing service?"
                          model="internal.is_interested_in_behave_billing_services"
                          layout="vertical-dense"
                        >
                          <Radio label="Yes, tell me more" value="yes" />
                          <Radio label="Not yet, but maybe someday" value="unknown" />
                          <Radio label="Not interested" value="no" />
                        </RadioGroup>

                        <H3>Outcomes</H3>
                        <CheckboxGroup
                          label="Do you track patient progress & outcome data using best practice measures?"
                          labelDescription="(Mark all that apply)"
                          layout="vertical-dense"
                        >
                          <Checkbox label="Patient Progress Data" model="internal.progress_measure.patient_progress_data" />
                          <Checkbox label="Patient Outcome Data" model="internal.progress_measure.patient_outcome_data" />
                          <Checkbox label="Program Improvement Data" model="internal.progress_measure.program_improvement_data" />
                          <Checkbox label="None" model="internal.progress_measure.none" />
                        </CheckboxGroup>

                        <ContextShow
                          orMode
                          when={[
                            'internal.progress_measure.patient_progress_data',
                            'internal.progress_measure.patient_outcome_data',
                            'internal.progress_measure.program_improvement_data',
                          ]}
                          is={true}
                        >
                          <Input label="What vendor do you use to track patient data?" model="internal.current_outcomes_vendor.name" />
                        </ContextShow>

                        <ContextShow when={'internal.progress_measure.none'} is={true}>
                          <RadioGroup
                            label="Would you like to learn more about BehaveHealth's integrated outcome analytics?"
                            model="internal.is_interested_in_outcome_analytics"
                            layout="horizontal-dense"
                          >
                            <Radio label="Yes" value={true} />
                            <Radio label="No" value={false} />
                          </RadioGroup>
                        </ContextShow>

                        <H3>Engagement</H3>
                        <CheckboxGroup
                          label="Current engagement solutions"
                          labelDescription="(Mark all that apply)"
                          layout="vertical-dense"
                        >
                          <Checkbox label="Patient Portal" model="internal.current_engagement_solutions.patient_portal" />
                          <Checkbox label="Family Portal" model="internal.current_engagement_solutions.family_portal" />
                          <Checkbox label="Past Client Portal" model="internal.current_engagement_solutions.alumni_portal" />
                          <Checkbox label="Other Engagement Tools" model="internal.current_engagement_solutions.other_engagement_tools" />
                          <Checkbox label="None" model="internal.current_engagement_solutions.none" />
                        </CheckboxGroup>

                        <ContextShow
                          orMode
                          when={[
                            'internal.current_engagement_solutions.patient_portal',
                            'internal.current_engagement_solutions.family_portal',
                            'internal.current_engagement_solutions.alumni_portal',
                            'internal.current_engagement_solutions.other_engagement_tools',
                          ]}
                          is={true}
                        >
                          <Input
                            label="What engagement software solution do you use?"
                            model="internal.current_ehr.name"
                            description="Please enter the name of your engagement solution"
                          />
                        </ContextShow>

                        <H3>Marketing</H3>
                        <CheckboxGroup label="Current marketing solutions" labelDescription="(Mark all that apply)" layout="vertical-dense">
                          <Checkbox label="Marketing Consultant" model="internal.current_marketing_solutions.marketing_consultant" />
                          <Checkbox
                            label="Directory Listings (Other than the BEHAVE|BRIDGE)"
                            model="internal.current_marketing_solutions.directory_listings"
                          />
                          <Checkbox label="Google Ads" model="internal.current_marketing_solutions.google_ads" />
                          <Checkbox label="Facebook Ads" model="internal.current_marketing_solutions.facebook_ads" />
                          <Checkbox label="Tv Ads" model="internal.current_marketing_solutions.tv_ads" />
                          <Checkbox label="Radio Ads" model="internal.current_marketing_solutions.radio_ads" />
                          <Checkbox label="Print Ads" model="internal.current_marketing_solutions.print_ads" />
                          <Checkbox label="None" model="internal.current_marketing_solutions.none" />
                          <Checkbox label="Other" model="internal.current_marketing_solutions.other" />
                        </CheckboxGroup>

                        <ContextShow when="internal.current_marketing_solutions.other" is={true}>
                          <Input label="Other" model="internal.current_marketing_solutions.other_value" />
                        </ContextShow>

                        <ContextShow when="internal.current_marketing_solutions.marketing_consultant" is={true}>
                          <Input label="What marketing consultant do you use?" model="internal.current_marketing_consultant" />
                        </ContextShow>

                        <ContextShow when="internal.current_marketing_solutions.directory_listings" is={true}>
                          <Input label="What directories do you use?" model="internal.current_directories" />
                        </ContextShow>

                        <H3>Labs</H3>
                        <CheckboxGroup
                          label="Current Lab Testing Solutions"
                          labelDescription="(Mark all that apply)"
                          layout="vertical-dense"
                        >
                          <Checkbox
                            label="External Toxicology Laboratory"
                            model="internal.lab_testing_solutions.external_toxicology_laboratory"
                          />
                          <Checkbox
                            label="Electronic Laboratory Orders"
                            model="internal.lab_testing_solutions.electronic_laboratory_orders"
                          />
                          <Checkbox
                            label="Electronic Laboratory Results"
                            model="internal.lab_testing_solutions.electronic_laboratory_results"
                          />
                          <Checkbox label="None" model="internal.lab_testing_solutions.none" />
                        </CheckboxGroup>

                        <ContextShow
                          orMode
                          when={[
                            'internal.lab_testing_solutions.external_toxicology_laboratory',
                            'internal.lab_testing_solutions.electronic_laboratory_orders',
                            'internal.lab_testing_solutions.electronic_laboratory_results',
                          ]}
                          is={true}
                        >
                          <Input label="Which lab do you use?" model="internal.current_lab" />
                        </ContextShow>

                        <H3>Workflow Analysis</H3>
                        <div>
                          <P className="!font-[700]">
                            For each of the following activities, please indicate if staff members routinely use computer or electronic
                            resources, paper only, or a combination of both to accomplish their work.
                          </P>

                          <P className="italic !mt-2 !mx-0 !mb-0">
                            NOTE: Electronic resources include tools such as electronic health records and web portals. Please consider
                            e-fax, pdf, or scanned documents as paper documents.
                          </P>
                        </div>

                        <RadioGroup label="Intake" model="internal.work_type.intake" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Scheduling appointments"
                          model="internal.work_type.scheduling_appointments"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Assessment" model="internal.work_type.assessment" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Treatment plan" model="internal.work_type.treatment_plan" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Client progress monitoring"
                          model="internal.work_type.client_progress_monitoring"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Discharge" model="internal.work_type.discharge" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Referrals" model="internal.work_type.referrals" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Order/receive lab results"
                          model="internal.work_type.order_receive_lab_results"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Billing" model="internal.work_type.billing" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup label="Outcomes management" model="internal.work_type.outcomes_management" layout="vertical-dense">
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Medication prescribing/dispensing"
                          model="internal.work_type.medication_prescribing_dispensing"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Store and maintain client health and/or treatment records"
                          model="internal.work_type.store_maintain_client_health_treatment_records"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Send client health and/or treatment information to providers or sources outside your organization"
                          model="internal.work_type.send_client_health_treatment_information_externally"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>

                        <RadioGroup
                          label="Receive client health and/or treatment information from providers or sources outside your organization"
                          model="internal.work_type.receive_client_health_treatment_information_externally"
                          layout="vertical-dense"
                        >
                          <Radio label="Computer/Electronic Only" value="electronic" />
                          <Radio label="Paper Only" value="paper" />
                          <Radio label="Both Electronic and Paper" value="electronic_and_paper" />
                          <Radio label="We do not do this activity" value="none" />
                        </RadioGroup>
                      </FormSection>
                    </Accordion>
                  </Section>
                </Grid>
              </Form>
            </ScrollPage>
          </PageGrid>
        </ScrollView>

        <Overlay.Footer>
          {$editable && (
            <>
              {bridge_status !== 'published' && (
                <>
                  <Button
                    label={$new ? 'Save as Draft' : 'Save Changes'}
                    glyph="check"
                    type="primary"
                    color="green"
                    onClick={this.save}
                    isLoading={loading}
                    isDisabled={loading || isInvalid}
                  />
                  {!$new && <Button label="Cancel" glyph="cross" type="default" isDisabled={loading} onClick={this.cancel} />}
                </>
              )}

              {bridge_status === 'published' && (
                <>
                  <Button
                    glyph="behave_health"
                    label={`Submit Update…`}
                    type="primary"
                    color="green"
                    onClick={this.submitUpdate}
                    isLoading={loading}
                    isDisabled={loading}
                  />
                  <Button label="Cancel" glyph="cross" type="default" isDisabled={loading} onClick={this.cancel} />
                </>
              )}
            </>
          )}

          {!$editable && (
            <>
              {bridge_status === null ||
                (bridge_status === 'draft' && (
                  <>
                    <Button glyph="edit" label={`Edit Profile`} type="default" isDisabled={loading} onClick={this.edit} flex="100 1 auto" />
                    <ConfirmDialog
                      glyph="behave_health"
                      title={`Submit data for Review?`}
                      message={`You won't be able to edit this information anymore until we review it.`}
                      onYes={this.submitForReview}
                    >
                      <Button
                        glyph="behave_health"
                        label={`Submit for Review…`}
                        type="primary"
                        color="green"
                        isDisabled={loading}
                        flex="100 1 auto"
                      />
                    </ConfirmDialog>
                  </>
                ))}

              {bridge_status === 'pending' && isBehave && (
                <>
                  <Button glyph="edit" label={`Edit Profile`} type="default" isDisabled={loading} onClick={this.edit} flex="100 1 auto" />
                  <ConfirmDialog
                    glyph="behave_health"
                    title={`Publish to Bridge?`}
                    message={`This will publish the current information to the Bridge. This is a one time process.`}
                    onYes={this.submitForPublish}
                  >
                    <Button
                      glyph="behave_health"
                      label={`Publish to Bridge…`}
                      type="primary"
                      color="green"
                      isDisabled={loading}
                      flex="100 1 auto"
                    />
                  </ConfirmDialog>
                </>
              )}

              {bridge_status === 'published' && (
                <>
                  <Button glyph="edit" label={`Edit Profile`} type="default" isDisabled={loading} onClick={this.edit} flex="100 1 auto" />
                </>
              )}
            </>
          )}
        </Overlay.Footer>
      </Overlay>
    )
  }
}

const tooltips = {
  fqhc: (
    <>
      <Heading is="h3">Federally Qualified Health Center (FQHC)</Heading>
      <P>An entity may qualify as a FQHC if it meets one of these requirements (CMS, 2017)</P>
      <Ul>
        <Li>
          Is receiving a grant under Section 330 of the Public Health Service (PHS) Act or is receiving funding from such a grant and meets
          other requirements;
        </Li>
        <Li>
          Is not receiving a grant under Section 330 of the PHS Act, but is determined by the Secretary of the Department of Health and
          Human Services (HHS) to meet the requirements for receiving such a grant (i.e., qualifies as a FQHC "look-alike") based on the
          recommendation of the Health Resources and Services Administration (HRSA);
        </Li>
        <Li>
          Was treated by the Secretary of the Department of HHS for purposes of Medicare Part B as a comprehensive Federally-funded health
          center as of January 1, 1990;
        </Li>
      </Ul>
      <P>
        Is operating as an outpatient health program or facility of a tribe or tribal organization under the Indian Self-Determination Act
        or as an urban Indian organization receiving funds under Title V of the Indian Health Care Improvement Act as of October 1, 1991.
      </P>
    </>
  ),
  asam: (
    <>
      <Heading is="h3">American Society of Addiction Medicine (ASAM) levels of care</Heading>
      <P>ASAM has developed widely used guidelines regarding levels of care. The ASAM levels of care are:</P>
      <Ul>
        <Li>Level 0.5-Early Intervention</Li>
        <Li>Level 1-Outpatient Services</Li>
        <Li>Level 2-Intensive Outpatient/Partial Hospitalization Services</Li>
        <Li>Level 3-Residential/Inpatient Services</Li>
        <Li>Level 4-Medically Managed Intensive Inpatient Services</Li>
      </Ul>
      <P>
        Within these broad levels of service is a range of specific levels of care. Some treatment facilities may be more familiar with the
        ASAM levels-of-care terminology than with the treatment categories used in the N-SSATS questionnaire. For that reason, each N-SSATS
        category described in the questionnaire is accompanied by a notation indicating the corresponding ASAM level of care.
      </P>
    </>
  ),
  outpatient: (
    <>
      <Heading is="h3">Outpatient</Heading>
      <P>Describes clients who receive treatment services without an overnight stay at a treatment facility or hospital.</P>
    </>
  ),
  otp: (
    <>
      <Heading is="h3">Opioid treatment program (OTP) certification</Heading>
      <P>
        The process by which the SAMHSA Center for Substance Abuse Treatment, Division of Pharmacologic Therapies determines that an OTP is
        qualified to provide opioid treatment under 42 CFR Part 8. For additional information on the OTP certification process, see{' '}
        <a href="http://www.samhsa.gov/medication-assisted-treatment/opioid-treatment-programs">
          http://www.samhsa.gov/medication-assisted-treatment/opioid-treatment-programs
        </a>
        .
      </P>
    </>
  ),
  substance_abuse_counseling: (
    <>
      <Heading is="h3">Substance abuse counseling</Heading>
      <P>
        A short-term treatment that has been generalized for a variety of disorders, including opiate drug dependence and cocaine abuse. The
        therapy includes supportive techniques, which encourage the client to discuss personal experiences, and expressive techniques, which
        enable the client to work through interpersonal relationship issues and gain greater self-understanding.
      </P>
    </>
  ),
  twelve_step_facilitation: (
    <>
      <Heading is="h3">12-step facilitation</Heading>
      <P>
        {' '}
        A 12-step program is a support group made up of people who share the same addiction. The “12 steps” refer to the steps recovering
        addicts must take to overcome their addiction as part of this program. Attendees at group meetings share their experiences,
        challenges, successes and failures, and provide peer support for each other.
      </P>
    </>
  ),
  brief_intervention: (
    <>
      <Heading is="h3">Brief intervention</Heading>
      <P>A short-term intervention, usually one to five sessions, for substance abusers who are not yet dependent.</P>
    </>
  ),
  cbt: (
    <>
      <Heading is="h3">Cognitive behavioral therapy (CBT)</Heading>
      <P>
        Cognitive behavioral therapy involves recognizing unhelpful patterns of thinking and reacting, and then modifying or replacing these
        with more realistic or helpful ones. The therapy can be conducted with individuals, families, or groups, and clients are generally
        expected to be active participants in their own therapy.
      </P>
    </>
  ),
  dbt: (
    <>
      <Heading is="h3">Dialectical behavior therapy (DBT)</Heading>
      <P>
        A cognitive behavioral treatment approach with two key characteristics: a behavioral, problem-solving focus blended with
        acceptance-based strategies, and an emphasis on dialectical processes. "Dialectical" refers to the issues involved in treating
        clients with multiple disorders and to the type of thought processes and behavioral styles used in the treatment strategies. DBT has
        five components: (1) capability enhancement (skills training); (2) motivational enhancement (individual behavioral treatment plans);
        (3) generalization (access to therapist outside clinical setting, homework, and inclusion of family in treatment); (4) structuring
        of the environment (programmatic emphasis on reinforcement of adaptive behaviors); and (5) capability and motivational enhancement
        of therapists (therapist team consultation group). DBT emphasizes balancing behavioral change, problem-solving, and emotional
        regulation with validation, mindfulness, and acceptance.
      </P>
    </>
  ),
  contingency_management: (
    <>
      <Heading is="h3">Contingency management/motivational incentives</Heading>
      <P>
        Often used in the treatment of drug and alcohol abuse, the approach employs a positive-reinforcement treatment method in which
        clients are given rewards for constructive actions taken toward their recovery.
      </P>
    </>
  ),
  motivational_interviewing: (
    <>
      <Heading is="h3">Motivational interviewing</Heading>
      <P>
        A counseling approach which acknowledges that many people experience ambivalence when deciding to make changes. Its aim is not to
        focus immediately on the action of changing, but to work to enhance motivation to change.
      </P>
    </>
  ),
  trauma_related_counseling: (
    <>
      <Heading is="h3">Trauma-related counseling</Heading>
      <P>
        Cognitive behavior techniques adapted for clients suffering from post traumatic stress disorder (PTSD) and other effects of abuse
        and trauma.
      </P>
    </>
  ),
  anger_management: (
    <>
      <Heading is="h3">Anger management</Heading>
      <P>
        Uses strategies to address the anger cycle, conflict resolution, assertiveness skills, and anger-control plans. The goal of anger
        management is to reduce both emotional feelings and the physiological arousal that anger causes.
      </P>
    </>
  ),
  matrix_model: (
    <>
      <Heading is="h3">Matrix Model</Heading>
      <P>
        Provides a framework for clients with substance use disorder to obtain the ability to cease drug use, stay in treatment, and
        participate in an educational program on addiction and relapse. Clients are provided with direction and support from a trained
        therapist and are introduced to self-help programs.
      </P>
    </>
  ),
  community_reinforcement_plus_vouchers: (
    <>
      <Heading is="h3">Community reinforcement plus vouchers</Heading>
      <P>
        An intensive outpatient therapy in which individuals focus on improving family relations, receive vocational training, and learn a
        variety of skills to minimize drug dependency. An incentive program (vouchers whereby individuals can earn points exchangeable for
        retail items) is used to encourage individuals to remain in treatment and be abstinent.
      </P>
    </>
  ),
  rebt: (
    <>
      <Heading is="h3">Rational emotive behavioral therapy (REBT)</Heading>
      <P>
        A therapeutic approach that places the focus on present issues, such as currently held attitudes, painful emotions, and maladaptive
        behaviors that can disrupt life. Treatment includes an REBT practitioner who personalizes a set of techniques for helping
        individuals examine their own thoughts, beliefs, and actions and replace those that are self-defeating with more life-enhancing
        alternatives.
      </P>
    </>
  ),
  relapse_prevention: (
    <>
      <Heading is="h3">Relapse prevention</Heading>
      <P>
        A cognitive behavioral therapy developed for the treatment of problem drinking and adapted later for cocaine addicts. Cognitive
        behavioral strategies are based on the theory that learning processes play a critical role in the development of maladaptive
        behavioral patterns. Individuals learn to identify and correct problematic behaviors. Relapse prevention encompasses several
        cognitive behavioral strategies that facilitate abstinence as well as provide help for people who experience relapse.
      </P>
    </>
  ),
  telemedicine: (
    <>
      <Heading is="h3">Telemedicine/telehealth (including internet, web, mobile, and desktop programs</Heading>
      <P>
        Computer or web-based interactive, structured, substance abuse treatment program to support the assessment, intervention, treatment,
        or continuing care of clients
      </P>
    </>
  ),
  tailored_program_group: (
    <>
      <Heading is="h3">Specifically tailored program or group</Heading>
      <P>
        A facility may offer a standard substance abuse program to all clients but, in addition, offer a specially designed program or group
        for specific types of clients. Although the treatment methods could be the same, specially designed programs or groups are
        exclusively for a specific type of client and discussions are particularly relevant to that type of client.
      </P>
    </>
  ),
  criminal_justice_clients: (
    <>
      <Heading is="h3">Criminal justice clients</Heading>
      <P>
        Clients who are involved in the criminal justice system. They include those who are awaiting trial, incarcerated, on probation, on
        parole, or mandated by the courts to receive treatment.
      </P>
    </>
  ),
  medicare: (
    <>
      <Heading is="h3">Medicare</Heading>
      <P>
        {' '}
        A joint federal and state program that helps with medical costs for some people with low incomes and limited resources. Medicaid
        programs vary from state to state.
      </P>
    </>
  ),
  medicaid: (
    <>
      <Heading is="h3" />
      <P>
        A joint federal and state program that helps with medical costs for some people with low incomes and limited resources. Medicaid
        programs vary from state to state.
      </P>
    </>
  ),
  itu: (
    <>
      <Heading is="h3">IHS/Tribal/Urban (ITU) funds</Heading>
      <P>
        Direct funds from the Indian Health Service. They consist of tribal funds through "638 contracts" (named after the public law under
        which they were authorized) and/or urban funds through federal Title 5 grants. These funds are considered part of the Indian health
        care system and can be used for programs that provide behavioral health services as well as for programs that provide other
        health-related services.
      </P>
    </>
  ),
  halfway_house: (
    <>
      <Heading is="h3">Transitional Housing or Halfway House</Heading>
      <P>
        Housing for individuals recovering from substance abuse that is designed to provide a drug- and alcohol-free living environment and
        appropriate support services to facilitate movement to independent living. Such housing includes transitional living, sober houses,
        sober living, recovery houses, and 3/4 houses.
      </P>
    </>
  ),
}

const ScrollPage = withScrollContext(Page)

const AsamAlert = () => {
  return (
    <Alert glyph="info">
      Learn more about the{' '}
      <Link href="https://www.asam.org/resources/the-asam-criteria/about" target="_blank">
        ASAM Levels of Care ➝
      </Link>
      .
    </Alert>
  )
}

const AgeInput = (props) => {
  return <Input type="number" size={6} suffix="y/o" {...props} />
}

const H3 = ({ children }) => {
  return (
    <Heading is="h3" className="!mt-2 !mx-0 !mb-0">
      {children}
    </Heading>
  )
}

const H2 = ({ children }) => {
  return (
    <Heading is="h2" className="!mt-3 !mx-0 !mb-0">
      {children}
    </Heading>
  )
}

const DynamicCheckboxGroup = ({ label, labelDescription, tooltip, object, model, description }) => {
  return (
    <CheckboxGroup
      label={label || object.name}
      layout="vertical-dense"
      maxWidth={600}
      description={description}
      labelDescription={labelDescription}
      tooltip={tooltip}
    >
      {Object.keys(object.items).map((item) => (
        <Checkbox key={item} label={object.items[item]} model={`${model}.${item}`} />
      ))}
    </CheckboxGroup>
  )
}

const DynamicRadioGroup = ({ label, labelDescription, tooltip, object, model, description }) => {
  return (
    <RadioGroup
      label={label || object.name}
      model={model}
      layout="vertical-dense"
      maxWidth={600}
      description={description}
      labelDescription={labelDescription}
      tooltip={tooltip}
    >
      {Object.keys(object.items).map((item) => (
        <Radio key={item} label={object.items[item]} value={item} />
      ))}
    </RadioGroup>
  )
}

const YesNoRadio = ({ label, model }) => {
  return (
    <RadioGroup label={label} model={model} layout="horizontal-dense">
      <Radio label="Yes" value={true} />
      <Radio label="No" value={false} />
    </RadioGroup>
  )
}

export const mapStateToProps = (state) => {
  return {
    bridge_status: get(state, `current.tenant.bridge_status`),
    bridge_data: get(state, `current.tenant.bridge_data`, {}),
    user: state.me.user,
    loading: state.data.loading,
  }
}

export default connect(mapStateToProps)(BridgeProfile)
