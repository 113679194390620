import { titleCase } from '../../utils/functions'
import { CONFIG } from '../../utils/globals'
import { COLOR_CODES } from '../../components/ColorPicker'

import {
  CLEARING_HOUSES,
  COMMUNICABLE_DISEASE_TESTS,
  DATA_FORMS,
  EVENT_CATEGORIES,
  FORM_SHARE_STATUSES,
  FORM_SUBMISSION_CATEGORIES,
  FORM_SUBMISSION_STATUSES,
  MEDICATION_TIMES,
  DISCHARGE_TYPES,
  UATests,
} from '../../utils/constants'

import {
  DATA_FORM_FILTERS,
  DEFAULT_FILTERS,
  PEOPLE_FILTERS,
  RESIDENTS_FILTERS,
  FILES_FILTERS,
  AGREEMENTS_FILTERS,
  INSURANCE_POLICY_FILTERS,
  INSURANCE_EVOB_FILTERS,
  INSURANCE_VOB_FILTERS,
  PROGRAM_LIST_FILTERS,
  OCCUPANCY_FILTERS,
  ALLERGY_FILTERS,
  CLINICAL_NOTE_FILTERS,
  CLIENT_CONNECTION_FILTERS,
  SHIFT_NOTE_FILTERS,
  SAFETY_CHECK_FILTERS,
  PEER_NOTE_FILTERS,
} from './constants'

import {
  OUTCOME_MEASURE_ADMISSION_LEVEL_OF_CARE_TITLES,
  OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES,
  OUTCOME_MEASURE_HOUSING_STATUS_TITLES,
  OUTCOME_MEASURE_CURRENT_MEDICATIONS_TITLES,
  OUTCOME_MEASURE_LIFE_QUALITY_TITLES,
  OUTCOME_MEASURE_NICOTINE_USE_FREQUENCY_TITLES,
  OUTCOME_MEASURE_MONTHLY_SERVICE_TITLES,
  OUTCOME_MEASURE_ETHNICITY_TITLES,
  OUTCOME_MEASURE_GENDER_TITLES,
  OUTCOME_MEASURE_DISCHARGE_TITLES,
  OUTCOME_MEASURE_DISCHARGE_LEVEL_OF_CARE_TITLES,
  OUTCOME_MEASURE_PAYMENT_TYPE_TITLES,
  OUTCOME_MEASURE_DISORDER_CATEGORY_TITLES,
  OUTCOME_MEASURE_MAT_MEDICATION_TITLES,
  OUTCOME_MEASURE_MENTAL_HEALTH_DIAGNOSIS_TITLES,
} from '../LiveReports/constants'

import { LOC } from '../../utils/constants'
import { AUTHORITY_FORMS } from '../Authority/forms'
import { AllClientsTable } from '../../components/Forms/Selectors/tables/AllClientsTable'
import { EmployeesTable } from '../../components/Forms/Selectors/tables/EmployeesTable'

export const FILTERS = {
  employees: {
    ...PEOPLE_FILTERS,
    title: {
      label: 'Title',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Archived', value: 'archived' },
      ],
    },
    houses: {
      label: 'Assigned Locations',
      type: 'multi_object',
      endpoint: '/houses',
      apiKey: 'houses',
      glyph: 'organizations',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    is_clinical_supervisor: {
      label: 'Is Supervisor',
      type: 'boolean',
    },
    is_healthcare_provider: {
      label: 'Is Healthcare Provider',
      type: 'boolean',
    },
    is_shared_with_portal: {
      label: 'Client Portal Sharing',
      type: 'boolean',
    },
    is_blocked: {
      label: 'Is Blocked',
      type: 'boolean',
    },
    behave_user_role: {
      label: 'User Type',
      type: 'multi_select',
      options: [
        { label: 'Account Owner User', value: 'owner' },
        { label: 'Billing User', value: 'billing' },
        { label: 'Clinical User', value: 'clinical' },
        { label: 'Operations User', value: 'coordinator' },
        { label: 'Management User', value: 'management' },
        { label: 'Medical User', value: 'medical' },
        { label: 'Part-Time Clinical User', value: 'part_time_clinical' },
        { label: 'Part-Time Medical User', value: 'part_time_medical' },
      ],
    },
    position: {
      label: 'Role',
      type: 'multi_select',
      options: CONFIG.active_employee_roles.map(({ text, value }) => ({ label: text, value: value })),
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  locations: {
    name: {
      label: 'Name',
      type: 'string',
    },
    phone_no: {
      label: 'Phone',
      type: 'string',
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: [
        { label: 'Housing', value: 'housing' },
        { label: 'Office', value: 'office' },
        { label: 'Mixed category', value: 'mixed_category' },
      ],
    },
    sex: {
      type: 'multi_select',
      label: 'Sex',
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Mixed', value: 'mixed' },
        { label: 'Empty', value: null },
      ],
    },
    is_shared_with_portal: {
      label: 'Client Portal Sharing',
      type: 'boolean',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  authority_forms: {
    name: {
      label: 'Name',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: [
        { label: 'Certification', value: 'certification' },
        { label: 'Inspection', value: 'inspection' },
        { label: 'Complaint', value: 'complaint' },
        { label: 'Issue', value: 'issue' },
        { label: 'Study', value: 'study' },
        { label: 'Survey', value: 'survey' },
      ],
    },
    subcategory: {
      label: 'Subcategory',
      type: 'multi_select',
      options: Object.values(AUTHORITY_FORMS).map((form: any) => ({ label: form.name, value: form.id })),
    },
    form_type: {
      label: 'Form Type',
      type: 'multi_select',
      options: [
        { label: 'NARR', value: 'narr' },
        { label: 'Empty', value: null },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  authority_form_templates: {
    name: {
      label: 'Name',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: [
        { label: 'Certification', value: 'certification' },
        { label: 'Inspection', value: 'inspection' },
        { label: 'Complaint', value: 'complaint' },
        { label: 'Issue', value: 'issue' },
        { label: 'Study', value: 'study' },
        { label: 'Survey', value: 'survey' },
      ],
    },
    subcategory: {
      label: 'Subcategory',
      type: 'multi_select',
      options: Object.values(AUTHORITY_FORMS).map((form: any) => ({ label: form.name, value: form.id })),
    },
    form_type: {
      label: 'Form Type',
      type: 'multi_select',
      options: [
        { label: 'NARR', value: 'narr' },
        { label: 'Empty', value: null },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  organizations: {
    name: {
      label: 'Name',
      type: 'string',
    },
    category_type: {
      label: 'Type',
      type: 'string',
    },
    website_url: {
      label: 'Website',
      type: 'string',
    },
    is_shared_with_portal: {
      label: 'Client Portal Sharing',
      type: 'boolean',
    },
    tags: DEFAULT_FILTERS.tags,
    category: {
      label: 'Category',
      type: 'multi_select',
      options: [
        { label: 'Vendor', value: 'vendor' },
        { label: 'Resource', value: 'resource' },
        { label: 'Provider', value: 'provider' },
      ],
    },
    contacts_count: {
      label: 'Number of Contacts',
      type: 'number',
    },
    email: PEOPLE_FILTERS.email,
    phone_no: PEOPLE_FILTERS.phone_no,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  programs: {
    name: {
      label: 'Name',
      type: 'string',
    },
    description: {
      label: 'Description',
      type: 'string',
    },
    phase_count: {
      label: 'Program List Count',
      type: 'number',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  clients: {
    ...PEOPLE_FILTERS,
    level_of_care: DEFAULT_FILTERS.level_of_care,
    client_portal_status: DEFAULT_FILTERS.client_portal_status,
    ...RESIDENTS_FILTERS,
  },

  applicants: {
    ...PEOPLE_FILTERS,
    ...RESIDENTS_FILTERS,
    priority: { label: 'Priority Number', type: 'number' },
    last_application_form_name: { label: 'Last Application Form Name', type: 'string' },
    last_application_date: { label: 'Last Application Date', type: 'date_time', glyph: 'date' },
    current_intake_application_review_status: {
      label: 'Last Application Review Status',
      type: 'multi_select',
      options: [
        { label: 'Not Reviewed', value: 'not_reviewed' },
        { label: 'Reviewed', value: 'reviewed' },
        { label: 'More Info Required', value: 'more_info_required' },
      ],
    },
  },

  intakes: {
    ...PEOPLE_FILTERS,
    ...RESIDENTS_FILTERS,
    last_application_date: { label: 'Last Application Date', type: 'date_time', glyph: 'date' },
    priority: { label: 'Priority Number', type: 'number' },
  },

  declined_intakes: {
    ...PEOPLE_FILTERS,
    ...RESIDENTS_FILTERS,
    last_application_date: { label: 'Last Application Date', type: 'date_time', glyph: 'date' },
  },

  alumni: {
    ...PEOPLE_FILTERS,
    level_of_care: DEFAULT_FILTERS.level_of_care,
    client_portal_status: DEFAULT_FILTERS.client_portal_status,
    ...RESIDENTS_FILTERS,
  },

  client_contacts: {
    reference: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    preferred_contact_method: {
      label: 'Preferred Contact Method',
      type: 'multi_select',
      options: [
        { label: 'Any', value: 'any' },
        { label: 'Phone', value: 'phone' },
        { label: 'Text', value: 'text' },
        { label: 'Email', value: 'email' },
      ],
    },
    ...PEOPLE_FILTERS,
    is_emergency: {
      label: 'Emergency Contact',
      type: 'boolean',
    },
    roi: {
      label: 'Release of Information Allowed',
      type: 'boolean',
    },
    relationship: {
      label: 'Relationship to Client',
      type: 'string',
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  portal_client_contacts: {
    name: {
      type: 'string',
      label: 'Full Name',
      glyph: 'user_neutral',
    },
    first_name: {
      type: 'string',
      label: 'First Name',
      glyph: 'user_neutral',
    },
    last_name: {
      type: 'string',
      label: 'Last Name',
      glyph: 'user_neutral',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    phone_no: {
      type: 'string',
      label: 'Phone',
      glyph: 'phone',
    },
    email: {
      type: 'string',
      label: 'Email',
      glyph: 'email',
    },
    dob: {
      type: 'date',
      label: 'Date of Birth',
      glyph: 'date',
    },
    relationship: {
      label: 'Relationship to Client',
      type: 'string',
    },
    is_emergency: {
      label: 'Emergency Contact',
      type: 'boolean',
    },
    roi: {
      label: 'Release of Information Allowed',
      type: 'boolean',
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    sex: {
      type: 'multi_select',
      label: 'Sex',
      options: [
        { label: 'Male', value: 'male' },
        { label: 'Female', value: 'female' },
        { label: 'Decline to respond', value: 'decline_to_respond' },
        { label: 'Other', value: 'other' },
        { label: 'Empty', value: null },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  bed_occupancies: {
    ...OCCUPANCY_FILTERS,
    status: {
      type: 'multi_select',
      label: 'Status',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Ended', value: 'ended' },
      ],
    },
    house: {
      label: 'Location',
      type: 'multi_object',
      endpoint: '/houses',
      apiKey: 'houses',
      selectTitle: (item: any) => item.name,
    },
    floor: {
      label: 'Floor',
      type: 'multi_object',
      endpoint: '/floors',
      apiKey: 'floors',
      selectTitle: (item: any) => item.name,
    },
    room: {
      label: 'Room',
      type: 'multi_object',
      endpoint: '/rooms',
      apiKey: 'rooms',
      selectTitle: (item: any) => item.name,
    },
    bed: {
      label: 'Bed',
      type: 'multi_object',
      endpoint: '/beds',
      apiKey: 'beds',
      selectTitle: (item: any) => item.name,
    },
  },
  program_occupancies: {
    ...OCCUPANCY_FILTERS,
    program: {
      label: 'Program',
      type: 'multi_object',
      endpoint: '/programs',
      apiKey: 'programs',
      selectTitle: (item: any) => item.name,
    },
    phase: {
      label: 'Phase',
      type: 'multi_object',
      endpoint: '/phases',
      apiKey: 'phases',
      selectTitle: (item: any) => item.name,
    },
    seat: {
      label: 'Seat',
      type: 'multi_object',
      endpoint: '/seats',
      apiKey: 'seats',
      selectTitle: (item: any) => item.name,
    },
  },

  organization_contacts: {
    reference: {
      label: 'Organiation',
      type: 'multi_object',
      endpoint: '/organizations',
      apiKey: 'organizations',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    status: {
      label: 'Status Method',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    ...PEOPLE_FILTERS,
    role: {
      label: 'Role',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  client_communications: {
    customer: {
      label: 'Contact For',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    category: {
      label: 'Contact Method',
      type: 'multi_select',
      options: [
        { label: 'Phone', value: 'phone' },
        { label: 'Text', value: 'text_message' },
        { label: 'Email', value: 'email' },
        { label: 'Meeting', value: 'meeting' },
        { label: 'Web Meeting', value: 'web' },
        { label: 'Other', value: 'other' },
      ],
    },
    reason: {
      label: 'Notes',
      type: 'string',
    },
    author: {
      ...DEFAULT_FILTERS.author,
      label: 'Contacted by',
    },
    contacted_at: {
      label: 'Date Contacted',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  organization_communications: {
    customer: {
      label: 'Contact For',
      type: 'multi_object',
      endpoint: '/organizations',
      apiKey: 'organizations',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    category: {
      label: 'Contact Method',
      type: 'multi_select',
      options: [
        { label: 'Phone', value: 'phone' },
        { label: 'Text', value: 'text_message' },
        { label: 'Email', value: 'email' },
        { label: 'Meeting', value: 'meeting' },
        { label: 'Web Meeting', value: 'web' },
        { label: 'Other', value: 'other' },
      ],
    },
    reason: {
      label: 'Notes',
      type: 'string',
    },
    author: {
      ...DEFAULT_FILTERS.author,
      label: 'Contacted by',
    },
    contacted_at: {
      label: 'Date Contacted',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  client_connections: CLIENT_CONNECTION_FILTERS,
  shift_notes: SHIFT_NOTE_FILTERS,
  ua_tests: {
    reference: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    positive_due_medication: {
      label: 'Positive due to Prescription',
      type: 'boolean',
    },
    positive_due_medication_notes: {
      label: 'Positive due to Prescription Notes',
      type: 'string',
    },
    test_results: {
      label: 'Positive Results',
      type: 'multi_select',
      options: UATests.map((test: any) => ({ label: test.name, value: test.name })),
    },
    other_positive_results: {
      label: 'Other Positive Results',
      type: 'string',
    },
    status: DEFAULT_FILTERS.test_result_status,
    result: DEFAULT_FILTERS.test_result,
    additional_info: DEFAULT_FILTERS.notes,
    author: DEFAULT_FILTERS.author,
    collected_at: DEFAULT_FILTERS.collected_at,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  breathalyzer_tests: {
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    status: DEFAULT_FILTERS.test_result_status,
    result: DEFAULT_FILTERS.test_result,
    notes: DEFAULT_FILTERS.notes,
    value: { label: 'Value', type: 'number' },
    author: DEFAULT_FILTERS.author,
    collected_at: DEFAULT_FILTERS.collected_at,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  vital_signs: {
    client: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    status: DEFAULT_FILTERS.test_result_status,
    assessment_type: {
      label: 'Assessment Type',
      type: 'multi_select',
      options: [
        { label: 'Normal', value: 'normal' },
        { label: 'Orthostatic', value: 'orthostatic' },
      ],
    },
    blood_pressure_systolic: {
      label: 'Blood Pressure Systolic',
      type: 'number',
    },
    blood_pressure_diastolic: {
      label: 'Blood Pressure Diastolic',
      type: 'number',
    },
    temperature: {
      label: 'Temperature',
      type: 'number',
    },
    oxygen_saturation: {
      label: 'O2 Sat',
      type: 'number',
    },
    pulse: {
      label: 'Pulse',
      type: 'number',
    },
    respiration: {
      label: 'Respiration',
      type: 'number',
    },
    pain_scale: {
      label: 'Pain Scale',
      type: 'number',
    },
    author: DEFAULT_FILTERS.author,
    notes: DEFAULT_FILTERS.notes,
    recorded_at: DEFAULT_FILTERS.collected_at,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  communicable_disease_tests: {
    client: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    status: DEFAULT_FILTERS.test_result_status,
    result: DEFAULT_FILTERS.test_result,
    quantitative_measure: {
      label: 'Quantitative Measure',
      type: 'string',
    },
    category: {
      label: 'Test Type',
      type: 'multi_select',
      options: Object.entries(COMMUNICABLE_DISEASE_TESTS).map(([value, label]) => ({ value, label })),
    },
    notes: DEFAULT_FILTERS.notes,
    author: DEFAULT_FILTERS.author,
    recorded_at: DEFAULT_FILTERS.collected_at,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  lab_tests: {
    client: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    name: DEFAULT_FILTERS.name,
    status: DEFAULT_FILTERS.test_result_status,
    result: DEFAULT_FILTERS.test_result,
    notes: DEFAULT_FILTERS.notes,
    author: DEFAULT_FILTERS.author,
    recorded_at: DEFAULT_FILTERS.collected_at,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  todos: {
    title: {
      label: 'Title',
      type: 'string',
    },
    priority: {
      label: 'Priority',
      type: 'multi_select',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Normal', value: 'normal' },
        { label: 'High', value: 'high' },
      ],
    },
    color: {
      label: 'Color',
      type: 'multi_select',
      options: Object.keys(COLOR_CODES).map((key) => ({ label: titleCase(key), value: key })),
    },
    employees: {
      label: 'Staff',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
      selectDescription: (item: any) => titleCase(item.position),
    },
    clients: {
      label: 'Clients',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    plain_public_description: {
      label: 'Public Description',
      type: 'string',
    },
    action_status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'To-Do', value: 'to_do' },
        { label: 'Due Today', value: 'due_today' },
        { label: 'Overdue', value: 'overdue' },
        { label: 'Done', value: 'done' },
      ],
    },
    start_time: {
      label: 'Due Date',
      type: 'date_time',
    },
    is_all_day: {
      label: 'Is All Day',
      type: 'boolean',
    },
    meeting_type: {
      label: 'Type',
      type: 'multi_select',
      options: [
        { label: 'One-to-One', value: 'one_to_one' },
        { label: 'Group Task', value: 'group_meeting' },
      ],
    },
    meeting_place: {
      label: 'Meeting Place',
      type: 'multi_select',
      options: [
        { label: 'None', value: 'none' },
        { label: 'Online', value: 'online' },
        { label: 'Location', value: 'property' },
        { label: 'Organization', value: 'organization' },
        { label: 'Other', value: 'other' },
      ],
    },
    subtask_no: {
      label: 'Tasks',
      type: 'number',
    },
    internal_notes: {
      label: 'Internal Notes',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  portal_todos: {
    title: {
      label: 'Title',
      type: 'string',
    },
    priority: {
      label: 'Priority',
      type: 'multi_select',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Normal', value: 'normal' },
        { label: 'High', value: 'high' },
      ],
    },
    color: {
      label: 'Color',
      type: 'multi_select',
      options: Object.keys(COLOR_CODES).map((key) => ({ label: titleCase(key), value: key })),
    },
    plain_public_description: {
      label: 'Public Description',
      type: 'string',
    },
    action_status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'To-Do', value: 'to_do' },
        { label: 'Due Today', value: 'due_today' },
        { label: 'Overdue', value: 'overdue' },
        { label: 'Done', value: 'done' },
      ],
    },
    start_time: {
      label: 'Due Date',
      type: 'date_time',
    },
    is_all_day: {
      label: 'Is All Day',
      type: 'boolean',
    },
    meeting_type: {
      label: 'Type',
      type: 'multi_select',
      options: [
        { label: 'One-to-One', value: 'one_to_one' },
        { label: 'Group Task', value: 'group_meeting' },
      ],
    },
    meeting_place: {
      label: 'Meeting Place',
      type: 'multi_select',
      options: [
        { label: 'None', value: 'none' },
        { label: 'Online', value: 'online' },
        { label: 'Location', value: 'property' },
        { label: 'Organization', value: 'organization' },
        { label: 'Other', value: 'other' },
      ],
    },
    subtask_no: {
      label: 'Tasks',
      type: 'number',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  events: {
    title: {
      label: 'Title',
      type: 'string',
    },
    priority: {
      label: 'Priority',
      type: 'multi_select',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Normal', value: 'normal' },
        { label: 'High', value: 'high' },
      ],
    },
    color: {
      label: 'Color',
      type: 'multi_select',
      options: Object.keys(COLOR_CODES).map((key) => ({ label: titleCase(key), value: key })),
    },
    public_description: {
      label: 'Public Description',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'cancelled' },
      ],
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: Object.entries(EVENT_CATEGORIES).map(([value, label]) => ({ value, label })),
    },
    started_at: {
      label: 'Event Date',
      type: 'date_time',
    },
    is_all_day: {
      label: 'Is All Day',
      type: 'boolean',
    },
    meeting_type: {
      label: 'Event Type',
      type: 'multi_select',
      options: [
        { label: 'One-to-One', value: 'one_to_one' },
        { label: 'Group Event', value: 'group_meeting' },
      ],
    },
    resident: {
      label: 'Client (One-to-One)',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    residents: {
      label: 'Clients (Group Events)',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    imported_houses: {
      label: 'Locations',
      type: 'multi_object',
      endpoint: '/houses',
      apiKey: 'properties',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    imported_programs: {
      label: 'Programs',
      type: 'multi_object',
      endpoint: '/programs',
      apiKey: 'programs',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    imported_phases: {
      label: 'Program Lists',
      type: 'multi_object',
      endpoint: '/phases',
      apiKey: 'phases',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    clients_import_type: {
      label: 'Attendees Import',
      type: 'multi_select',
      options: [
        { label: 'Automatic from Locations / Programs', value: 'dynamic' },
        { label: 'Manual from clients list', value: 'manual' },
      ],
    },
    programs_import_type: {
      label: 'Import From Program Lists',
      type: 'multi_select',
      options: [
        { label: 'Programs', value: 'programs' },
        { label: 'Program Lists', value: 'program_lists' },
      ],
    },
    meeting_place: {
      label: 'Meeting Place',
      type: 'multi_select',
      options: [
        { label: 'None', value: 'none' },
        { label: 'Online', value: 'online' },
        { label: 'Location', value: 'property' },
        { label: 'Organization', value: 'organization' },
        { label: 'Other', value: 'other' },
      ],
    },
    internal_notes: {
      label: 'Internal Notes',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  portal_events: {
    title: {
      label: 'Title',
      type: 'string',
    },
    priority: {
      label: 'Priority',
      type: 'multi_select',
      options: [
        { label: 'Low', value: 'low' },
        { label: 'Normal', value: 'normal' },
        { label: 'High', value: 'high' },
      ],
    },
    color: {
      label: 'Color',
      type: 'multi_select',
      options: Object.keys(COLOR_CODES).map((key) => ({ label: titleCase(key), value: key })),
    },
    public_description: {
      label: 'Public Description',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Completed', value: 'completed' },
        { label: 'Cancelled', value: 'cancelled' },
      ],
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: Object.entries(EVENT_CATEGORIES).map(([value, label]) => ({ value, label })),
    },
    started_at: {
      label: 'Event Date',
      type: 'date_time',
    },
    is_all_day: {
      label: 'Is All Day',
      type: 'boolean',
    },
    meeting_type: {
      label: 'Event Type',
      type: 'multi_select',
      options: [
        { label: 'One-to-One', value: 'one_to_one' },
        { label: 'Group Task', value: 'group_meeting' },
      ],
    },
    meeting_place: {
      label: 'Meeting Place',
      type: 'multi_select',
      options: [
        { label: 'None', value: 'none' },
        { label: 'Online', value: 'online' },
        { label: 'Location', value: 'property' },
        { label: 'Organization', value: 'organization' },
        { label: 'Other', value: 'other' },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  insurance_authorizations: {
    identifier: {
      label: 'Authorization #',
      type: 'string',
    },
    sessions: {
      label: 'Sessions Authorized',
      type: 'string',
    },
    used_sessions: {
      label: 'Sessions Used',
      type: 'string',
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    started_at: {
      label: 'Started On',
      type: 'date_time',
      glyph: 'date',
    },
    ended_at: {
      label: 'Ending On',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
    author: DEFAULT_FILTERS.author,
  },

  treatment_episodes: {
    title: {
      label: 'Title',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Pending', value: 'pending' },
        { label: 'Incoming', value: 'incoming' },
        { label: 'Waitlist', value: 'waitlist' },
        { label: 'No Show', value: 'no_show' },
        { label: 'Declined', value: 'declined' },
        { label: 'Admitted', value: 'admitted' },
        { label: 'Discharged', value: 'discharged' },
        { label: 'Closed', value: 'closed' },
      ],
    },
    insurance_authorization_count: {
      label: '# Authorizations',
      type: 'number',
    },
    accepted_at: {
      label: 'Accept Date',
      type: 'date_time',
      glyph: 'date',
    },
    estimated_arrival: {
      label: 'Est. Arrival Date',
      type: 'date_time',
      glyph: 'date',
    },
    admitted_at: {
      label: 'Admit Date',
      type: 'date_time',
      glyph: 'date',
    },
    estimated_discharge: {
      label: 'Est. Discharge Date',
      type: 'date_time',
      glyph: 'date',
    },
    declined_at: {
      label: 'Decline Date',
      type: 'date_time',
      glyph: 'date',
    },
    discharged_at: {
      label: 'Discharge Date',
      type: 'date_time',
      glyph: 'date',
    },
    incoming_notes: {
      label: 'Incoming Notes',
      type: 'string',
    },
    waitlist_notes: {
      label: 'Waitlist Notes',
      type: 'string',
    },
    no_show_notes: {
      label: 'No Show Notes',
      type: 'string',
    },
    discharge_notes: {
      label: 'Discharge Notes',
      type: 'string',
    },
    discharge_type: {
      label: 'Discharge Type',
      type: 'multi_select',
      options: Object.entries(DISCHARGE_TYPES).map(([value, label]) => ({ value, label })),
    },
    notes: {
      label: 'Additional Information',
      type: 'string',
    },
    accepted_by: {
      label: 'Accepted By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    admitted_by: {
      label: 'Admitted By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    declined_by: {
      label: 'Declined By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    discharged_by: {
      label: 'Discharged By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  data_exports: {
    name: {
      label: 'Name',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Processing', value: 'processing' },
        { label: 'Generated', value: 'generated' },
        { label: 'Issue', value: 'issue' },
      ],
    },
    created_at: {
      label: 'Requested At',
      type: 'date_time',
      glyph: 'date',
    },
    starting_at: {
      label: 'Start Date',
      type: 'date_time',
      glyph: 'date',
    },
    ending_at: {
      label: 'End At',
      type: 'date_time',
      glyph: 'date',
    },
    author: {
      label: 'Requested By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (data: any) => data.name,
    },
  },

  // Files, divided and duplicated by resource types
  files: {
    ...FILES_FILTERS,
  },

  client_files: {
    resource: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    ...FILES_FILTERS,
  },

  organization_files: {
    resource: {
      label: 'Organization',
      type: 'multi_object',
      endpoint: '/organizations',
      apiKey: 'organizations',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    ...FILES_FILTERS,
  },

  location_files: {
    resource: {
      label: 'Locations',
      type: 'multi_object',
      endpoint: '/all_locations',
      apiKey: 'locations',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    ...FILES_FILTERS,
  },

  staff_files: {
    resource: {
      label: 'Staff',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_group',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    ...FILES_FILTERS,
  },
  clinical_assessments: {
    subcategory: {
      label: 'Type',
      type: 'multi_select',
      options: Object.entries(DATA_FORMS.clinical_assessment).map(([value, label]) => ({ value, label })),
    },
    requested_updates: { label: 'Updates Requested', type: 'string' },
    ...DATA_FORM_FILTERS,
  },

  clinical_measurements: {
    subcategory: {
      label: 'Type',
      type: 'multi_select',
      options: Object.entries(DATA_FORMS.clinical_measurement).map(([value, label]) => ({ value, label })),
    },
    ...DATA_FORM_FILTERS,
  },

  medical_assessments: {
    subcategory: {
      label: 'Type',
      type: 'multi_select',
      options: Object.entries(DATA_FORMS.medical_assessment).map(([value, label]) => ({ value, label })),
    },
    requested_updates: { label: 'Updates Requested', type: 'string' },
    ...DATA_FORM_FILTERS,
  },

  recovery_notes: {
    // TODO
    ...DATA_FORM_FILTERS,
  },

  clinical_notes: CLINICAL_NOTE_FILTERS,
  peer_notes: PEER_NOTE_FILTERS,
  payment_methods: {
    author: {
      label: 'Added By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    profile: {
      label: 'Owner',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
    },
    name: {
      label: 'Name',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Processing', value: 'processing' },
        { label: 'Errored', value: 'errored' },
        { label: 'Active', value: 'active' },
        { label: 'Disabled', value: 'disabled' },
      ],
    },
    category: {
      label: 'Category',
      type: 'multi_select',
      options: [
        { label: 'Card', value: 'card' },
        { label: 'ACH', value: 'ach' },
      ],
    },
    reference_category: {
      label: 'Reference Category',
      type: 'multi_select',
      options: [
        { label: 'Client', value: 'client' },
        { label: 'Contact', value: 'contact' },
        { label: 'Organization', value: 'organization' },
      ],
    },
    notes: {
      label: 'Notes',
      type: 'notes',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  insurance_payment: {
    insurance_local_payer: {
      label: 'Payer',
      type: 'multi_object',
      endpoint: '/insurance_local_payers',
      apiKey: 'insurance_local_payers',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    primary_insurance: {
      label: 'Plan Name',
      type: 'multi_object',
      endpoint: '/insurance_policies',
      apiKey: 'insurance_policies',
      showAvatar: true,
      selectTitle: (item: any) => item.plan,
    },
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    insurance_claim: {
      label: 'Claim ID',
      type: 'string',
    },
    allowed_amount: {
      label: 'Allowed Amount',
      type: 'number',
    },
    paid_amount: {
      label: 'Paid Amount',
      type: 'number',
    },
    deductible_amount: {
      label: 'Deductible Amount',
      type: 'number',
    },
    copay_amount: {
      label: 'Copay Amount',
      type: 'number',
    },
    coinsurance_amount: {
      label: 'Coinsurance Amount',
      type: 'number',
    },
    not_payable_amount: {
      label: 'Not Payable Amount',
      type: 'number',
    },
    adjustment_amount: {
      label: 'Adjusted Amount',
      type: 'number',
    },
    billed_amount: {
      label: 'Billed Amount',
      type: 'number',
    },
    remaining_amount: {
      label: 'Balance Due Client',
      type: 'number',
    },
    days_from_dos_to_posted: {
      label: 'Date of Service to Posted',
      type: 'number',
    },
    days_from_dos_to_paid: {
      label: 'Date of Service to Paid',
      type: 'number',
    },
    days_from_billed_to_paid: {
      label: 'Initial Bill to Paid',
      type: 'number',
    },
    reference: {
      label: 'Payment Ref',
      type: 'string',
    },
    insurance_code: {
      label: 'Procedure Code',
      type: 'string',
    },
    description: {
      label: 'Charge Description',
      type: 'string',
    },
    service_date_start: {
      label: 'Date of Service',
      type: 'date_time',
      glyph: 'date',
    },

    initial_submission_at: {
      label: 'Initial Bill Date',
      type: 'date_time',
      glyph: 'date',
    },
    dated_at: {
      label: 'Payment Date',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  insurance_claim_service_line: {
    insurance_claim: {
      label: 'Claim ID',
      type: 'string',
    },
    total_amount: {
      label: 'Amount',
      type: 'number',
    },
    insurance_local_payer: {
      label: 'Payer',
      type: 'multi_object',
      endpoint: '/insurance_local_payers',
      apiKey: 'insurance_local_payers',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    primary_insurance: {
      label: 'Plan Name',
      type: 'multi_object',
      endpoint: '/insurance_policies',
      apiKey: 'insurance_policies',
      showAvatar: true,
      selectTitle: (item: any) => item.plan,
    },
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    service_date_start: {
      label: 'Date of Service',
      type: 'date_time',
      glyph: 'date',
    },
    last_submission_at: {
      label: 'Billed Date',
      type: 'date_time',
      glyph: 'date',
    },
    description: {
      label: 'Description',
      type: 'string',
    },
    procedure_code: {
      label: 'Procedure Code',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  insurance_claim: {
    insurance_local_payer: {
      label: 'Payer',
      type: 'multi_object',
      endpoint: '/insurance_local_payers',
      apiKey: 'insurance_local_payers',
      glyph: 'user_group',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    insurance_claim_service_lines: {
      label: 'Services Billed',
      type: 'multi_object',
      endpoint: '/insurance_codes',
      apiKey: 'insurance_codes',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    control_number: {
      label: 'Claim ID',
      type: 'string',
    },
    total_amount: {
      label: 'Billed Amount',
      type: 'number',
    },
    covering_from: {
      label: 'Claim From Date',
      type: 'date_time',
      glyph: 'date',
    },
    covering_until: {
      label: 'Claim To Date',
      type: 'date_time',
      glyph: 'date',
    },
    days_in_aging: {
      label: 'Days in Aging',
      type: 'number',
    },
    between_0_and_30_days: {
      label: '0-30 Days',
      type: 'number',
    },
    between_31_and_60_days: {
      label: '31-60 Days',
      type: 'number',
    },
    between_61_and_90_days: {
      label: '61-90 Days',
      type: 'number',
    },
    over_91_days: {
      label: '91+ Days',
      type: 'number',
    },
    status: {
      label: 'Internal Status',
      type: 'multi_select',
      options: [
        { label: 'Draft', value: 'draft' },
        { label: 'Validated', value: 'validated' },
        { label: 'Pending', value: 'pending' },
        { label: 'Issue', value: 'issue' },
        { label: 'Processed', value: 'processed' },
        { label: 'Finalized', value: 'finalized' },
        { label: 'Submission Error', value: 'error_submission' },
        { label: 'Validation Error', value: 'error_validation' },
        { label: 'Status Error', value: 'error_status' },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  insurance_claim_notes: {
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    control_number: {
      label: 'Claim ID',
      type: 'string',
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  insurance_claim_tasks: {
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'residents',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    assignee: {
      label: 'Assignee',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (data: any) => data.name,
    },
    control_number: {
      label: 'Claim ID',
      type: 'string',
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'To-Do', value: 'todo' },
        { label: 'Done', value: 'done' },
        { label: 'In-Progress', value: 'in_progress' },
        { label: 'On Hold', value: 'on_hold' },
        { label: 'Urgent', value: 'urgent' },
      ],
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  insurance_policies: INSURANCE_POLICY_FILTERS,

  insurance_evobs: INSURANCE_EVOB_FILTERS,

  insurance_vobs: INSURANCE_VOB_FILTERS,

  vobs: {
    created_at: DEFAULT_FILTERS.created_at,
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Draft', value: 'draft' },
        { label: 'Sent to Facility', value: 'signed_off' },
      ],
    },
    insurance_local_payer: {
      label: 'Insurance Payer',
      type: 'multi_object',
      endpoint: '/insurance_local_payers',
      apiKey: 'insurance_local_payers',
      showAvatar: true,
      selectTitle: (item: any) => item.name,
    },
    insurance_policy_plan: {
      label: 'Plan Name',
      type: 'string',
    },
    updated_at: DEFAULT_FILTERS.updated_at,
    author: {
      ...DEFAULT_FILTERS.author,
      polymorphic: false,
    },
  },

  evobs: {
    created_at: DEFAULT_FILTERS.created_at,
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Processing', value: 'processing' },
        { label: 'Issue', value: 'issue' },
        { label: 'Processed', value: 'processed' },
      ],
    },
    clearing_house: {
      label: 'Clearing House',
      type: 'multi_select',
      options: [
        { label: 'Clearing House 1 (CH)', value: 'change_health' },
        { label: 'Clearing House 2 (PV)', value: 'pverify' },
      ],
    },
    coverage_status: {
      label: 'Coverage Status',
      type: 'string',
    },
    date_of_service: {
      label: 'Date of Service',
      type: 'string',
    },
    payer_name: {
      label: 'Insurance Payer',
      type: 'string',
    },
  },

  full_vobs: {
    dated_at: {
      label: 'Date Added',
      type: 'date_time',
      glyph: 'date',
    },
    insurance_policy_insurance_name: {
      label: 'Insurance Payer',
      type: 'string',
    },
    insurance_policy_plan: {
      label: 'Plan Name',
      type: 'string',
    },
  },

  treatment_plans_archived: {
    name: DATA_FORM_FILTERS.name,
    status: DATA_FORM_FILTERS.status,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
    author: DEFAULT_FILTERS.author,
  },

  discharges: {
    ...DATA_FORM_FILTERS,
    admitted_at: {
      label: 'Admitted At',
      type: 'date_time',
      glyph: 'date',
    },
    discharged_at: {
      label: 'Discharged At',
      type: 'date_time',
      glyph: 'date',
    },
  },
  physician_orders: {
    ...DATA_FORM_FILTERS,
    requested_updates: { label: 'Updates Requested', type: 'string' },
  },
  progress_notes: {
    ...DATA_FORM_FILTERS,
    requested_updates: { label: 'Updates Requested', type: 'string' },
  },
  progress_reviews: DATA_FORM_FILTERS,
  treatment_plans: DATA_FORM_FILTERS,
  program_lists: PROGRAM_LIST_FILTERS,
  form_submissions: {
    name: {
      label: 'Name',
      type: 'string',
    },
    tags: DEFAULT_FILTERS.tags,
    status: {
      label: 'Status',
      type: 'multi_select',
      options: Object.entries(FORM_SUBMISSION_STATUSES).map(([value, label]) => ({ value, label })),
    },
    use_public_sharing: {
      label: 'Share Status',
      type: 'boolean',
      trueLabel: 'Public',
      falseLabel: 'Internal',
    },
    reference_category: {
      label: 'Category',
      type: 'multi_select',
      options: Object.entries(FORM_SUBMISSION_CATEGORIES).map(([value, label]) => ({ value, label })),
    },
    assignee: {
      type: 'multi_object',
      label: 'Assignee',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: true,
      selectTitle: (item: any) => item.name,
      selectDescription: (item: any) => titleCase(item.position),
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  agreements: AGREEMENTS_FILTERS,

  agreement_signees: {
    name: {
      label: 'Name',
      type: 'string',
    },
    signer_type: {
      label: 'Signee Type',
      type: 'string',
    },
    contracts_count: {
      label: 'Contracts Count',
      type: 'number',
    },
  },

  allergies: ALLERGY_FILTERS,

  data_form_settings: {
    name: {
      label: 'Name',
      type: 'string',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
      ],
    },
    client_tracks: DEFAULT_FILTERS.client_tracks,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  custom_notes: {
    name: DATA_FORM_FILTERS.name,
    author: DEFAULT_FILTERS.author,
    reference: {
      ...DATA_FORM_FILTERS.client,
      polymorphic: true,
    },
    supervisor: DATA_FORM_FILTERS.supervisor,
    tags: DEFAULT_FILTERS.tags,
    status: DATA_FORM_FILTERS.status,
    supervisor_signed_at: DATA_FORM_FILTERS.supervisor_signed_at,
    author_signed_at: DATA_FORM_FILTERS.author_signed_at,
    started_at: {
      label: 'Start Date and Time',
      type: 'date_time',
      glyph: 'date',
    },
    ended_at: {
      label: 'End Date and Time',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  meds: {
    name: {
      label: 'Name',
      type: 'string',
    },
    resident: {
      label: 'Client',
      type: 'multi_overlay',
      endpoint: '/residents',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
      icon: 'clients',
      table: <AllClientsTable defaultTab="current_clients" />,
    },
    medication_time: {
      label: 'Medication Time',
      type: 'multi_select',
      condition: 'in',
      options: Object.entries(MEDICATION_TIMES).map(([value, label]) => ({ value, label })),
    },
    default_quantity: {
      label: 'Standard Dose Quantity',
      type: 'string',
    },
    quantity: {
      label: 'Quantity',
      type: 'number',
    },
    status: {
      label: 'Status',
      type: 'multi_select',
      condition: 'in',
      options: [
        { label: 'Active', value: 'active' },
        { label: 'Inactive', value: 'inactive' },
        { label: 'Historical', value: 'historical' },
        { label: 'Refill', value: 'refill' },
        { label: 'Completed', value: 'completed' },
        { label: 'Discontinued', value: 'discontinued' },
      ],
    },
    started_at: {
      label: 'Started At',
      type: 'date_time',
      glyph: 'date',
    },
    ended_at: {
      label: 'Ended At',
      type: 'date_time',
      glyph: 'date',
    },
    dosage: {
      label: 'Dosage',
      type: 'number',
    },
    prescription_no: {
      label: 'Prescription #',
      type: 'string',
    },
    prescription_by: {
      label: 'Prescribing Physician',
      type: 'string',
    },
    refill: {
      label: 'Refill Notes',
      type: 'string',
    },
    notes: {
      label: 'Instructions / Notes',
      type: 'string',
    },
    unit: {
      label: 'Unit',
      type: 'string',
    },
    description: {
      label: 'Description',
      type: 'multi_select',
      condition: 'in',
      options: [
        { label: 'Medical', value: 'medical' },
        { label: 'Psychiatric', value: 'psychiatric' },
        { label: 'Taper', value: 'taper' },
        { label: 'Palliatives', value: 'palliatives' },
        { label: 'Mat', value: 'mat' },
        { label: 'Vaccine', value: 'vaccine' },
      ],
    },
    source: {
      label: 'Source',
      type: 'multi_select',
      condition: 'in',
      options: [
        { label: 'Internal', value: 'internal' },
        { label: 'Erx', value: 'erx' },
        { label: 'External During', value: 'external_during' },
        { label: 'External Prior', value: 'external_prior' },
        { label: 'Private Provider', value: 'private_provider' },
      ],
    },
    author: DEFAULT_FILTERS.author,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  medication_logs: {
    med_name: {
      label: 'Medication',
      type: 'string',
    },
    resident: {
      label: 'Client',
      type: 'multi_overlay',
      endpoint: '/residents',
      apiKey: 'clients',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
      icon: 'clients',
      table: <AllClientsTable defaultTab="current_clients" />,
    },
    administration_status: {
      label: 'Administration Status',
      type: 'multi_select',
      condition: 'in',
      options: [
        { label: 'Administered', value: 'administered' },
        { label: 'Observed', value: 'observed' },
        { label: 'Refused', value: 'refused' },
      ],
    },
    medication_time: {
      label: 'Medication Time',
      type: 'multi_select',
      condition: 'in',
      options: Object.entries(MEDICATION_TIMES).map(([value, label]) => ({ value, label })),
    },
    administered_at: {
      label: 'Administered / Refused At',
      type: 'date_time',
      glyph: 'date',
    },
    dosage: {
      label: 'Dosage',
      type: 'number',
    },
    unit: {
      label: 'Unit',
      type: 'string',
    },
    remaining_units: {
      label: 'Remaining Unit',
      type: 'number',
    },
    // med: {
    //   label: 'Name',
    //   type: 'multi_object',
    //   endpoint: '/meds',
    //   apiKey: 'meds',
    //   showAvatar: true,
    //   polymorphic: false,
    //   selectTitle: (data: any) => data.name,
    // },
    author: DEFAULT_FILTERS.author,
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  medication_destruction_logs: {
    // med: {
    //   label: 'Medication',
    //   type: 'multi_object',
    //   endpoint: '/meds',
    //   apiKey: 'meds',
    //   showAvatar: true,
    //   polymorphic: false,
    //   selectTitle: (data: any) => data.name,
    // },
    resident: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'clients',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    quantity: {
      label: 'Quantity',
      type: 'number',
    },
    prescription_no: {
      label: 'Prescription #',
      type: 'string',
    },
    pharmacy_name: {
      label: 'Pharmacy Name',
      type: 'string',
    },
    employee: {
      label: 'Destroyed By',
      type: 'multi_object',
      endpoint: '/employees',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    author: DEFAULT_FILTERS.author,
    destroyed_at: {
      label: 'Destroyed At',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },
  medication_incidents: {
    name: {
      label: 'Title',
      type: 'string',
    },
    client: {
      label: 'Client',
      type: 'multi_object',
      endpoint: '/residents',
      apiKey: 'employees',
      glyph: 'user_neutral',
      showAvatar: true,
      polymorphic: false,
      selectTitle: (data: any) => data.name,
    },
    // med: {
    //   label: 'Medication',
    //   type: 'multi_object',
    //   endpoint: '/meds',
    //   apiKey: 'meds',
    //   showAvatar: true,
    //   polymorphic: false,
    //   selectTitle: (data: any) => data.name,
    // },
    category: {
      label: 'Category',
      type: 'multi_select',
      condition: 'in',
      options: [
        { label: 'Error', value: 'error_log' },
        { label: 'Issue', value: 'issue_log' },
      ],
    },
    notes: {
      label: 'Notes',
      type: 'string',
    },
    author: {
      ...DEFAULT_FILTERS.author,
      label: 'Logged By',
    },
    logged_at: {
      label: 'Logged At',
      type: 'date_time',
      glyph: 'date',
    },
    created_at: DEFAULT_FILTERS.created_at,
    updated_at: DEFAULT_FILTERS.updated_at,
  },

  // New Live Report Filters

  live_reports: {
    applicants: {
      name: DEFAULT_FILTERS.name,
      is_billed_by_behave: RESIDENTS_FILTERS.is_billed_by_behave,
      intake_application_status: {
        label: 'Application Status',
        type: 'multi_select',
        options: [
          { label: 'Not Sent', value: 'lead' },
          { label: 'Sent', value: 'sent' },
          { label: 'Opened', value: 'opened' },
          { label: 'Partial Completion', value: 'partial_completion' },
          { label: 'Submitted', value: 'submitted' },
          { label: 'Accepted', value: 'accepted' },
          { label: 'Intake', value: 'intake' },
          { label: 'Declined', value: 'declined' },
          { label: 'Archived', value: 'archived' },
        ],
      },
      intake_application_source: {
        label: 'Application Source',
        type: 'multi_select',
        options: [
          { label: 'Online', value: 'online' },
          { label: 'Embed', value: 'embed' },
          { label: 'Form', value: 'form' },
          { label: 'App', value: 'app' },
          { label: 'Mobile', value: 'mobile' },
        ],
      },
      tags: DEFAULT_FILTERS.tags,
      color_code: DEFAULT_FILTERS.color_code,
      behave_id: {
        label: 'Behave ID',
        type: 'string',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      sex: PEOPLE_FILTERS.sex,
      referral_source: {
        label: 'Referral In',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out: {
        label: 'Referral Out',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_in_contact: {
        label: 'Referral In Contact',
        type: 'multi_object',
        endpoint: '/contacts',
        apiKey: 'contacts',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out_contact: {
        label: 'Referral Out Contact',
        type: 'multi_object',
        endpoint: '/contacts',
        apiKey: 'contacts',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_notes: {
        label: 'Referral Notes',
        type: 'string',
      },
      referral_out_notes: {
        label: 'Referral Out Notes',
        type: 'string',
      },
      intake_application_last_viewed_at: {
        label: 'Application Last Viewed',
        type: 'date_time',
        glyph: 'date',
      },
      intake_application_last_viewed_by: {
        label: 'Last Viewed By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      intake_application_review_status: {
        label: 'Review Status',
        type: 'multi_select',
        options: [
          { label: 'Not Reviewed', value: 'not_reviewed' },
          { label: 'Reviewed', value: 'reviewed' },
          { label: 'More Info Required', value: 'more_info_required' },
        ],
      },
      first_contact_at: {
        label: 'First Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_at: {
        label: 'Last Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_by: {
        label: 'Last Contact By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
        selectDescription: (item: any) => titleCase(item.position),
      },
      last_contact_reason: {
        label: 'Last Contact Notes',
        type: 'string',
      },
      intake_application_applied_at: {
        label: 'Applied At',
        type: 'date_time',
        glyph: 'date',
      },
      last_name: PEOPLE_FILTERS.last_name,
    },
    accepted: {
      name: DEFAULT_FILTERS.name,
      is_billed_by_behave: RESIDENTS_FILTERS.is_billed_by_behave,
      admission_status: {
        label: 'Treatment Episode Admission Status',
        type: 'multi_select',
        options: [
          { label: 'Pending', value: 'pending' },
          { label: 'Incoming', value: 'incoming' },
          { label: 'Waitlist', value: 'waitlist' },
          { label: 'Planned', value: 'planned' },
          { label: 'No Show', value: 'no_show' },
          { label: 'Declined', value: 'declined' },
          { label: 'Admitted', value: 'admitted' },
          { label: 'Discharged', value: 'discharged' },
          { label: 'Closed', value: 'closed' },
        ],
      },
      tags: DEFAULT_FILTERS.tags,
      color_code: DEFAULT_FILTERS.color_code,
      behave_id: {
        label: 'Behave ID',
        type: 'string',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      sex: PEOPLE_FILTERS.sex,
      referral_source: {
        label: 'Referral In',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out: {
        label: 'Referral Out',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_notes: {
        label: 'Referral Notes',
        type: 'string',
      },
      referral_out_notes: {
        label: 'Referral Out Notes',
        type: 'string',
      },
      admission_accepted_at: {
        label: 'Accepted At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_accepted_by: {
        label: 'Accepted By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      admission_estimated_arrival: {
        label: 'Estimated Arrival',
        type: 'date_time',
        glyph: 'date',
      },
      admission_estimated_discharge: {
        label: 'Estimated Discharge',
        type: 'date_time',
        glyph: 'date',
      },
      first_contact_at: {
        label: 'First Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_at: {
        label: 'Last Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_reason: {
        label: 'Last Contact Notes',
        type: 'string',
      },
      last_contact_by: {
        label: 'Last Contact By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
        selectDescription: (item: any) => titleCase(item.position),
      },
      intake_application_applied_at: {
        label: 'Applied At',
        type: 'date_time',
        glyph: 'date',
      },
      last_name: PEOPLE_FILTERS.last_name,
      last_ua_test_at: {
        label: 'Last UA Test',
        type: 'date_time',
        glyph: 'date',
      },
    },
    declined: {
      name: DEFAULT_FILTERS.name,
      is_billed_by_behave: RESIDENTS_FILTERS.is_billed_by_behave,
      tags: DEFAULT_FILTERS.tags,
      color_code: DEFAULT_FILTERS.color_code,
      behave_id: {
        label: 'Behave ID',
        type: 'string',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      sex: PEOPLE_FILTERS.sex,
      referral_source: {
        label: 'Referral In',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out: {
        label: 'Referral Out',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_notes: {
        label: 'Referral Notes',
        type: 'string',
      },
      referral_out_notes: {
        label: 'Referral Out Notes',
        type: 'string',
      },
      intake_application_last_viewed_at: {
        label: 'Application Last Viewed',
        type: 'date_time',
        glyph: 'date',
      },
      intake_application_last_viewed_by: {
        label: 'Last Viewed By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      intake_application_review_status: {
        label: 'Review Status',
        type: 'multi_select',
        options: [
          { label: 'Not Reviewed', value: 'not_reviewed' },
          { label: 'Reviewed', value: 'reviewed' },
          { label: 'More Info Required', value: 'more_info_required' },
        ],
      },
      admission_declined_at: {
        label: 'Declined At',
        type: 'date_time',
        glyph: 'date',
      },
      intake_application_declined_by: {
        label: 'Declined By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      admission_decline_reason: {
        label: 'Decline Reason',
        type: 'string',
      },
      first_contact_at: {
        label: 'First Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_at: {
        label: 'Last Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_by: {
        label: 'Last Contact By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
        selectDescription: (item: any) => titleCase(item.position),
      },
      intake_application_applied_at: {
        label: 'Applied At',
        type: 'date_time',
        glyph: 'date',
      },
      last_name: PEOPLE_FILTERS.last_name,
    },
    current_clients: {
      name: DEFAULT_FILTERS.name,
      is_billed_by_behave: RESIDENTS_FILTERS.is_billed_by_behave,
      tags: DEFAULT_FILTERS.tags,
      color_code: DEFAULT_FILTERS.color_code,
      current_house: {
        label: 'House',
        type: 'multi_object',
        endpoint: '/houses',
        apiKey: 'houses',
        glyph: 'organizations',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      current_room: {
        label: 'Room',
        type: 'multi_object',
        endpoint: '/rooms',
        apiKey: 'rooms',
        glyph: 'organizations',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
        selectDescription: (data: any) => data.place?.name,
      },
      behave_id: {
        label: 'Behave ID',
        type: 'string',
      },
      financial_charges_balance: {
        label: 'Total Charges',
        type: 'number',
      },
      financial_total_payments: {
        label: 'Total Payments',
        type: 'number',
      },
      financial_balance: {
        label: 'Balance',
        type: 'number',
      },
      financial_last_payment_amount: {
        label: 'Last Payment',
        type: 'number',
      },
      financial_last_payment_transactioned_at: {
        label: 'Last Payment Transactioned At',
        type: 'date_time',
        glyph: 'date',
      },
      financial_last_charge_transactioned_at: {
        label: 'Last Charge Transactioned At',
        type: 'date_time',
        glyph: 'date',
      },
      financial_status: {
        label: 'Financial Status',
        type: 'multi_select',
        options: [
          { label: 'Up To Date', value: 'up_to_date' },
          { label: 'Partially Paid', value: 'partially_paid' },
          { label: 'Due', value: 'due' },
          { label: 'Overdue', value: 'overdue' },
          { label: 'Debt Written Off', value: 'written_off' },
          { label: 'Other', value: 'other' },
        ],
      },
      has_auto_repeating_charges: {
        label: 'Has Auto-Repeating Charges?',
        type: 'boolean',
      },
      financial_data_filter: {
        label: 'Financial Data Filter',
        type: 'string',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      sex: PEOPLE_FILTERS.sex,
      dob: RESIDENTS_FILTERS.dob,
      level_of_care: DEFAULT_FILTERS.level_of_care,
      care_team_connections: {
        label: 'Connections',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      emergency_contacts: {
        label: 'Emergency Contacts',
        type: 'multi_object',
        endpoint: '/contacts',
        apiKey: 'contacts',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_source: {
        label: 'Referral In',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out: {
        label: 'Referral Out',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_notes: {
        label: 'Referral Notes',
        type: 'string',
      },
      referral_out_notes: {
        label: 'Referral Out Notes',
        type: 'string',
      },
      first_contact_at: {
        label: 'First Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_at: {
        label: 'Last Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_by: {
        label: 'Last Contact By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
        selectDescription: (item: any) => titleCase(item.position),
      },
      intake_application_applied_at: {
        label: 'Applied At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_accepted_at: {
        label: 'Accepted At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_admitted_at: {
        label: 'Admitted At',
        type: 'date_time',
        glyph: 'date',
      },
      length_of_stay_days: {
        label: 'Length Of Stay',
        type: 'number',
      },
      admission_admitted_by: {
        label: 'Admitted By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      admission_estimated_discharge: {
        label: 'Estimated Discharge',
        type: 'date_time',
        glyph: 'date',
      },
      estimated_length_of_stay_days: {
        label: 'Est. Length Of Stay',
        type: 'number',
      },
      sobriety_date: {
        label: 'Sobriety Date',
        type: 'date_time',
        glyph: 'date',
      },
      sober_for_days: RESIDENTS_FILTERS.sober_for_days,
      client_portal_status: DEFAULT_FILTERS.client_portal_status,
      last_name: PEOPLE_FILTERS.last_name,
      last_ua_test_at: {
        label: 'Last UA Test',
        type: 'date_time',
        glyph: 'date',
      },
      last_logged_in_at: {
        label: 'Last Logged in At',
        type: 'date_time',
        glyph: 'date',
      },
    },
    past_clients: {
      name: DEFAULT_FILTERS.name,
      is_billed_by_behave: RESIDENTS_FILTERS.is_billed_by_behave,
      tags: DEFAULT_FILTERS.tags,
      color_code: DEFAULT_FILTERS.color_code,
      current_house: {
        label: 'House',
        type: 'multi_object',
        endpoint: '/houses',
        apiKey: 'houses',
        glyph: 'organizations',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      current_room: {
        label: 'Room',
        type: 'multi_object',
        endpoint: '/rooms',
        apiKey: 'rooms',
        glyph: 'organizations',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
        selectDescription: (data: any) => data.place?.name,
      },
      behave_id: {
        label: 'Behave ID',
        type: 'string',
      },
      financial_charges_balance: {
        label: 'Total Charges',
        type: 'number',
      },
      financial_total_payments: {
        label: 'Total Payments',
        type: 'number',
      },
      financial_balance: {
        label: 'Balance',
        type: 'number',
      },
      financial_last_payment_amount: {
        label: 'Last Payment',
        type: 'number',
      },
      financial_last_payment_transactioned_at: {
        label: 'Last Payment Transactioned At',
        type: 'date_time',
        glyph: 'date',
      },
      financial_last_charge_transactioned_at: {
        label: 'Last Charge Transactioned At',
        type: 'date_time',
        glyph: 'date',
      },
      financial_status: {
        label: 'Financial Status',
        type: 'multi_select',
        options: [
          { label: 'Up To Date', value: 'up_to_date' },
          { label: 'Partially Paid', value: 'partially_paid' },
          { label: 'Due', value: 'due' },
          { label: 'Overdue', value: 'overdue' },
          { label: 'Debt Written Off', value: 'written_off' },
          { label: 'Other', value: 'other' },
        ],
      },
      financial_data_filter: {
        label: 'Financial Data Filter',
        type: 'string',
      },
      has_auto_repeating_charges: {
        label: 'Has Auto-Repeating Charges?',
        type: 'boolean',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      sex: PEOPLE_FILTERS.sex,
      dob: RESIDENTS_FILTERS.dob,
      level_of_care: DEFAULT_FILTERS.level_of_care,
      referral_source: {
        label: 'Referral In',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_out: {
        label: 'Referral Out',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      referral_notes: {
        label: 'Referral In Notes',
        type: 'string',
      },
      referral_out_notes: {
        label: 'Referral Out Notes',
        type: 'string',
      },
      first_contact_at: {
        label: 'First Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_at: {
        label: 'Last Contact At',
        type: 'date_time',
        glyph: 'date',
      },
      last_contact_by: {
        label: 'Last Contact By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
        selectDescription: (item: any) => titleCase(item.position),
      },
      intake_application_applied_at: {
        label: 'Applied At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_accepted_at: {
        label: 'Accepted At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_admitted_at: {
        label: 'Admitted At',
        type: 'date_time',
        glyph: 'date',
      },
      length_of_stay_days: {
        label: 'Length Of Stay',
        type: 'number',
      },
      estimated_length_of_stay_days: {
        label: 'Est. Length Of Stay',
        type: 'number',
      },
      admission_admitted_by: {
        label: 'Admitted By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      admission_discharged_at: {
        label: 'Discharged At',
        type: 'date_time',
        glyph: 'date',
      },
      admission_discharge_type: {
        label: 'Discharge Type',
        type: 'string',
      },
      aftercare_plan: {
        label: 'Aftercare Plan',
        type: 'string',
      },
      admission_return_eligibility: {
        label: 'Return Eligibility',
        type: 'multi_select',
        options: [
          { label: 'Eligible', value: 'eligible' },
          { label: 'Not Eligible', value: 'not_eligible' },
          { label: 'Pending Decision', value: 'pending_decision' },
        ],
      },
      admission_discharge_notes: {
        label: 'Discharge Notes',
        type: 'string',
      },
      sobriety_date: {
        label: 'Sobriety Date',
        type: 'date_time',
        glyph: 'date',
      },
      sober_for_days: RESIDENTS_FILTERS.sober_for_days,
      client_portal_status: DEFAULT_FILTERS.client_portal_status,
      last_name: PEOPLE_FILTERS.last_name,
      last_ua_test_at: {
        label: 'Last UA Test',
        type: 'date_time',
        glyph: 'date',
      },
    },

    treatment_episodes: {
      title: {
        label: 'Title',
        type: 'date_time',
        glyph: 'date',
      },
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Pending', value: 'pending' },
          { label: 'Incoming', value: 'incoming' },
          { label: 'Waitlist', value: 'waitlist' },
          { label: 'No Show', value: 'no_show' },
          { label: 'Declined', value: 'declined' },
          { label: 'Admitted', value: 'admitted' },
          { label: 'Discharged', value: 'discharged' },
          { label: 'Closed', value: 'closed' },
        ],
      },
      insurance_authorization_count: {
        label: '# Authorizations',
        type: 'number',
      },
      incoming_notes: {
        label: 'Incoming Notes',
        type: 'string',
      },
      waitlist_notes: {
        label: 'Waitlist Notes',
        type: 'string',
      },
      no_show_notes: {
        label: 'No Show Notes',
        type: 'string',
      },
      discharge_notes: {
        label: 'Discharge Notes',
        type: 'string',
      },
      discharge_type: {
        label: 'Discharge Type',
        type: 'multi_select',
        options: Object.entries(DISCHARGE_TYPES).map(([value, label]) => ({ value, label })),
      },
      notes: {
        label: 'Additional Information',
        type: 'string',
      },
      estimated_arrival: {
        label: 'Est. Arrival Date',
        type: 'date_time',
        glyph: 'date',
      },
      admitted_at: {
        label: 'Admit Date',
        type: 'date_time',
        glyph: 'date',
      },
      estimated_discharge: {
        label: 'Est. Discharge Date',
        type: 'date_time',
        glyph: 'date',
      },
      discharged_at: {
        label: 'Discharge Date',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Discharge Date',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Date Added',
        type: 'date_time',
        glyph: 'date',
      },
      customer: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      accepted_by: {
        label: 'Accepted By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      admitted_by: {
        label: 'Admitted By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      discharged_by: {
        label: 'Discharged By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    agreements: {
      name: DEFAULT_FILTERS.name,
      status: AGREEMENTS_FILTERS.status,
      review_cycle: AGREEMENTS_FILTERS.review_cycle,
      contract_count: AGREEMENTS_FILTERS.contract_count,
      dated_at: AGREEMENTS_FILTERS.dated_at,
      expired_at: AGREEMENTS_FILTERS.expired_at,
      reviewed_at: AGREEMENTS_FILTERS.reviewed_at,
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      cosigner: {
        label: 'Staff Signee',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    applications: {
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: '0. Application Not Sent', value: 'lead' },
          { label: '1. Application Sent', value: 'sent' },
          { label: '2. Application Opened', value: 'opened' },
          { label: '3. Partial Completion', value: 'partial_completion' },
          { label: '4. Application Submitted', value: 'submitted' },
          { label: 'Accepted For Intake', value: 'intake' },
          { label: 'Application Declined', value: 'declined' },
          { label: 'Application Archived', value: 'archived' },
        ],
      },
      applied_at: {
        label: 'Added At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Last Updated',
        type: 'date_time',
        glyph: 'date',
      },
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    employees: {
      ...PEOPLE_FILTERS,
      title: {
        label: 'Title',
        type: 'string',
      },
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Archived', value: 'archived' },
        ],
      },
      is_clinical_supervisor: {
        label: 'Is Supervisor',
        type: 'boolean',
      },
      is_healthcare_provider: {
        label: 'Is Healthcare Provider',
        type: 'boolean',
      },
      is_shared_with_portal: {
        label: 'Client Portal Sharing',
        type: 'boolean',
      },
      is_blocked: {
        label: 'Is Blocked',
        type: 'boolean',
      },
      behave_user_role: {
        label: 'User Type',
        type: 'multi_select',
        options: [
          { label: 'Account Owner User', value: 'owner' },
          { label: 'Billing User', value: 'billing' },
          { label: 'Clinical User', value: 'clinical' },
          { label: 'Operations User', value: 'coordinator' },
          { label: 'Management User', value: 'management' },
          { label: 'Medical User', value: 'medical' },
          { label: 'Part-Time Clinical User', value: 'part_time_clinical' },
          { label: 'Part-Time Medical User', value: 'part_time_medical' },
        ],
      },
      position: {
        label: 'Position',
        type: 'multi_select',
        options: CONFIG.active_employee_roles.map(({ text, value }) => ({ label: text, value: value })),
      },
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
    },
    clients_journey: {
      status: RESIDENTS_FILTERS.admission_status,
      created_at: {
        label: 'Applicant Create Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_evob_at: {
        label: '1st eVOB Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_vob_call_at: {
        label: '1st VOB Call Date',
        type: 'date_time',
        glyph: 'date',
      },
      accepted_at: {
        label: 'Accepted Date',
        type: 'date_time',
        glyph: 'date',
      },
      admission_admitted_at: {
        label: 'Admitted Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_clinical_assessment_at: {
        label: '1st Clinical Assessment Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_medical_assesment_at: {
        label: '1st Medical Assessment Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_clinical_record_at: {
        label: '1st Clinical Record Date',
        type: 'date_time',
        glyph: 'date',
      },
      first_medical_record_at: {
        label: '1st Medical Record Date',
        type: 'date_time',
        glyph: 'date',
      },
      last_clinical_record_at: {
        label: 'Last Clinical Record Date',
        type: 'date_time',
        glyph: 'date',
      },
      last_medical_record_at: {
        label: 'Last Medical Record Date',
        type: 'date_time',
        glyph: 'date',
      },
      client: {
        label: 'Client Name',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    timeliness: {
      documentation_type: {
        label: 'Type',
        type: 'multi_select',
        options: [
          { label: 'Clinical Note', value: 'clinical_note' },
          { label: 'Breathalyzer Test', value: 'breathalyzer_test' },
          { label: 'Communicable Disease', value: 'communicable_disease' },
          { label: 'Vital Sign', value: 'vital_sign' },
        ],
      },
      status: DATA_FORM_FILTERS.status,
      start_at: {
        label: 'Session Start',
        type: 'date_time',
        glyph: 'date',
      },
      author_create_date_time: {
        label: 'Author Create Date',
        type: 'date_time',
        glyph: 'date',
      },
      signed_off_date_time: {
        label: 'Signed Off Date',
        type: 'date_time',
        glyph: 'date',
      },
      author_signed_off_date_time: {
        label: 'Author Signed Off Date',
        type: 'date_time',
        glyph: 'date',
      },
      author_submit_date_time: {
        label: 'Author Submit Date',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Author',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      supervisor: {
        label: 'Supervisor',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    insurance_authorizations: {
      level: {
        label: 'Level',
        type: 'string',
      },
      sessions: {
        label: 'Sessions',
        type: 'string',
      },
      used_sessions: {
        label: 'Used Sessions',
        type: 'string',
      },
      notes: {
        label: 'Notes',
        type: 'string',
      },
      started_at: {
        label: 'Started At',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Ended At',
        type: 'date_time',
        glyph: 'date',
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    insurance_policies: INSURANCE_POLICY_FILTERS,
    insurance_evobs: INSURANCE_EVOB_FILTERS,
    insurance_vobs: INSURANCE_VOB_FILTERS,
    discharges: {
      ...DATA_FORM_FILTERS,
      length_of_stay: {
        label: 'Length of Stay',
        type: 'number',
      },
      relapse_status: {
        label: 'Relapse Status',
        type: 'multi_select',
        options: [
          { label: 'Relapsed', value: 'relapsed' },
          { label: 'Did not Relapse', value: 'not_relapsed' },
          { label: 'Unknown', value: 'unknown' },
        ],
      },
      discharge_type: {
        label: 'Discharge Type',
        type: 'multi_select',
        options: [
          { label: 'Successfully Completed', value: 'successfully_completed' },
          { label: 'Therapeutic Discharge', value: 'therapeutic_discharge' },
          { label: 'AMA', value: 'ama' },
          { label: 'MIA', value: 'mia' },
          { label: 'Administrative Discharge', value: 'administrative_discharge' },
          { label: 'Other', value: 'other' },
        ],
      },
      level_of_care: {
        label: 'Level Of Care',
        type: 'multi_select',
        options: Object.keys(LOC).map((level: any) => ({ label: LOC[level], value: level })),
      },
      placement_reason: {
        label: 'Placement Reason',
        type: 'string',
      },
      discharge_reason: {
        label: 'Discharge Reason',
        type: 'string',
      },
      discharge_summary: {
        label: 'Discharge Summary',
        type: 'string',
      },
      is_employed: {
        label: 'Currently Employed',
        type: 'boolean',
      },
    },
    program_lists: PROGRAM_LIST_FILTERS,
    bed_occupancies: {
      ...OCCUPANCY_FILTERS,
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        selectTitle: (item: any) => item.name,
      },
      house: {
        label: 'Location',
        type: 'multi_object',
        endpoint: '/houses',
        apiKey: 'houses',
        selectTitle: (item: any) => item.name,
      },
      floor: {
        label: 'Floor',
        type: 'multi_object',
        endpoint: '/floors',
        apiKey: 'floors',
        selectTitle: (item: any) => item.name,
      },
      room: {
        label: 'Room',
        type: 'multi_object',
        endpoint: '/rooms',
        apiKey: 'rooms',
        selectTitle: (item: any) => item.name,
      },
      bed: {
        label: 'Bed',
        type: 'multi_object',
        endpoint: '/beds',
        apiKey: 'beds',
        selectTitle: (item: any) => item.name,
      },
    },
    program_occupancies: {
      ...OCCUPANCY_FILTERS,
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        selectTitle: (item: any) => item.name,
      },
      program: {
        label: 'Program',
        type: 'multi_object',
        endpoint: '/programs',
        apiKey: 'programs',
        selectTitle: (item: any) => item.name,
      },
      phase: {
        label: 'Phase',
        type: 'multi_object',
        endpoint: '/phases',
        apiKey: 'phases',
        selectTitle: (item: any) => item.name,
      },
      seat: {
        label: 'Seat',
        type: 'multi_object',
        endpoint: '/seats',
        apiKey: 'seats',
        selectTitle: (item: any) => item.name,
      },
    },
    data_forms: DATA_FORM_FILTERS,
    ua_tests: {
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
      },
      current_location: {
        label: 'Current Location',
        type: 'multi_object',
        endpoint: '/houses',
        apiKey: 'locations',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: true,
        selectTitle: (item: any) => item.name,
      },
      positive_results_count: {
        label: 'Positive Result Count',
        type: 'number',
      },
      status: DEFAULT_FILTERS.test_result_status,
      result: DEFAULT_FILTERS.test_result,
      other_positive_results: {
        label: 'Other Positive Results',
        type: 'string',
      },
      positive_due_medication: {
        label: 'Positive due to prescription',
        type: 'boolean',
      },
      additional_info: DEFAULT_FILTERS.notes,
      author: DEFAULT_FILTERS.author,
      collected_at: DEFAULT_FILTERS.collected_at,
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
    },
    admit_survey: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: DATA_FORM_FILTERS.status,
      level_of_care: {
        label: 'Level Of Care',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_ADMISSION_LEVEL_OF_CARE_TITLES).map(([value, label]) => ({ label, value })),
      },
      employment_status: {
        label: 'Employment Status',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES).map(([value, label]) => ({ label, value })),
      },
      housing_status: {
        label: 'Housing Status',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_HOUSING_STATUS_TITLES).map(([value, label]) => ({ label, value })),
      },
      alcohol_days: {
        label: 'Prior Month Alcohol Use (Days)',
        type: 'number',
      },
      cannabis_days: {
        label: 'Prior Month Cannabis Use (Days)',
        type: 'number',
      },
      hallucinogens_days: {
        label: 'Prior Month Hallucinogens Use (Days)',
        type: 'number',
      },
      inhalants_days: {
        label: 'Prior Month Inhalants Use (Days)',
        type: 'number',
      },
      opioids_days: {
        label: 'Prior Month Opioids Use (Days)',
        type: 'number',
      },
      sedatives_hypnotics_anxiolytics_days: {
        label: 'Prior Month Sedatives Use (Days)',
        type: 'number',
      },
      stimulants_days: {
        label: 'Prior Month Stimulants Use (Days)',
        type: 'number',
      },
      tobacco_days: {
        label: 'Prior Month Tobacco Use (Days)',
        type: 'number',
      },
      other_substances: {
        label: 'Other Substance Use (Days)',
        type: 'number',
      },
      history_of_drug_use: {
        label: 'History Of Drug Use',
        type: 'boolean',
      },
      past_overdoses_number: {
        label: '# Past Alcohol Overdoses',
        type: 'number',
      },
      previous_sud_treatments_number: {
        label: '# Previous Sub Treatments',
        type: 'number',
      },
      dated_at: {
        label: 'Date and Time',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Session End Time',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Updated At',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    demographic_information_form: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: DATA_FORM_FILTERS.status,
      age_at_admission: {
        label: 'Age At Admission',
        type: 'number',
      },
      gender: {
        label: 'Gender',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_GENDER_TITLES).map(([value, label]) => ({ label, value })),
      },
      race_ethnicity: {
        label: 'Race / Ethnicity',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_ETHNICITY_TITLES).map(([value, label]) => ({ label, value })),
      },
      dated_at: {
        label: 'Date and Time',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Session End Time',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Updated At',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    progress_monitoring_survey: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: DATA_FORM_FILTERS.status,
      past_week_alcohol_drugs_use_days: {
        label: 'Past Week Alcohol Drug Use',
        type: 'number',
      },
      past_week_cravings_days: {
        label: 'Past Week Craving Days',
        type: 'number',
      },
      confidence_in_recovery_for_next_week: {
        label: 'Confidence In Recovery',
        type: 'number',
      },
      primary_caregiver_understanding_support: {
        label: 'Caregiver Support Confidence',
        type: 'number',
      },
      physical_pain: {
        label: 'Physical Pain',
        type: 'number',
      },
      past_week_psychological_or_emotional_problems: {
        label: 'Psychological Or Emotional Problems',
        type: 'number',
      },
      dated_at: {
        label: 'Date and Time',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Session End Time',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Updated At',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    outcomes_survey: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: DATA_FORM_FILTERS.status,
      employment_status: {
        label: 'Employment Status',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_EMPLOYMENT_STATUS_TITLES).map(([value, label]) => ({ label, value })),
      },
      prior_month_services: {
        label: 'Prior Month Services',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_MONTHLY_SERVICE_TITLES).map(([value, label]) => ({ label, value })),
      },
      prior_month_recovery_support_meetings: {
        label: 'Prior Month Recovery Support Meetings',
        type: 'string',
      },
      prior_month_substance_use_days: {
        label: 'Prior Month Substance Use',
        type: 'number',
      },
      current_medications: {
        label: 'Current Medications',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_CURRENT_MEDICATIONS_TITLES).map(([value, label]) => ({ label, value })),
      },
      prior_month_hospital_visits: {
        label: 'Prior Month Hospital Visits',
        type: 'number',
      },
      prior_month_law_enforcement_incidents: {
        label: 'Prior Month Law Enforcement Incidents',
        type: 'string',
      },
      did_relapse_since_discharge: {
        label: 'Relapse Since Discharge',
        type: 'boolean',
      },
      quality_of_life_rating: {
        label: 'Quality Of Life Rating',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_LIFE_QUALITY_TITLES).map(([value, label]) => ({ label, value })),
      },
      nicotine_use_frequency: {
        label: 'Nicotine use Frequency',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_NICOTINE_USE_FREQUENCY_TITLES).map(([value, label]) => ({ label, value })),
      },
      dated_at: {
        label: 'Date and Time',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Session End Time',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Updated At',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    discharge_survey: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: DATA_FORM_FILTERS.status,
      discharge_level_of_care: {
        label: 'Discharge Level Of Care',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_DISCHARGE_LEVEL_OF_CARE_TITLES).map(([value, label]) => ({ label, value })),
      },
      discharge_type: {
        label: 'Type Of Discharge',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_DISCHARGE_TITLES).map(([value, label]) => ({ label, value })),
      },
      inpatient_hospital_or_medical_detox: {
        label: 'Inpatient Hospital or Medical Detox',
        type: 'number',
      },
      residential: {
        label: 'Residential',
        type: 'number',
      },
      partial_hospitalization: {
        label: 'Partial Hospitalization',
        type: 'number',
      },
      intensive_outpatient: {
        label: 'Intensive Outpatient',
        type: 'number',
      },
      sober_living_recovery_residence: {
        label: 'Sober Living Recovery Residence',
        type: 'number',
      },
      recovery_support: {
        label: 'Recovery Support',
        type: 'number',
      },
      other: {
        label: 'Other',
        type: 'number',
      },
      payment_type: {
        label: 'Payment Type',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_PAYMENT_TYPE_TITLES).map(([value, label]) => ({ label, value })),
      },
      primary_diagnostic_substance_use_disorder_category: {
        label: 'Primary Diagnostic Substance Use Disorder Category',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_DISORDER_CATEGORY_TITLES).map(([value, label]) => ({ label, value })),
      },
      mat_medications_received: {
        label: 'MAT Medications Received',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_MAT_MEDICATION_TITLES).map(([value, label]) => ({ label, value })),
      },
      mental_health_diagnosis: {
        label: 'Mental Health Diagnosis',
        type: 'multi_select',
        options: Object.entries(OUTCOME_MEASURE_MENTAL_HEALTH_DIAGNOSIS_TITLES).map(([value, label]) => ({ label, value })),
      },
      did_use_telehealth: {
        label: 'Did Use Telehealth',
        type: 'boolean',
      },
      dated_at: {
        label: 'Date and Time',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Session End Time',
        type: 'date_time',
        glyph: 'date',
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      updated_at: {
        label: 'Updated At',
        type: 'date_time',
        glyph: 'date',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    meds: {
      name: {
        label: 'Medication Time',
        type: 'string',
      },
      status: {
        label: 'Status',
        type: 'multi_select',
        condition: 'in',
        options: [
          { label: 'Active', value: 'active' },
          { label: 'Inactive', value: 'inactive' },
          { label: 'Historical', value: 'historical' },
          { label: 'Refill', value: 'refill' },
          { label: 'Completed', value: 'completed' },
          { label: 'Discontinued', value: 'discontinued' },
        ],
      },
      started_at: {
        label: 'Started At',
        type: 'date_time',
        glyph: 'date',
      },
      ended_at: {
        label: 'Ended At',
        type: 'date_time',
        glyph: 'date',
      },
      prescription_no: {
        label: 'Medication Time',
        type: 'string',
      },
      medication_time: {
        label: 'Medication Time',
        type: 'string',
      },
      dosage: {
        label: 'Dosage',
        type: 'number',
      },
      refill: {
        label: 'Refill Notes',
        type: 'string',
      },
      unit: {
        label: 'Unit',
        type: 'number',
      },
      description: {
        label: 'Description',
        type: 'multi_select',
        condition: 'in',
        options: [
          { label: 'Medical', value: 'medical' },
          { label: 'Psychiatric', value: 'psychiatric' },
          { label: 'Taper', value: 'taper' },
          { label: 'Palliatives', value: 'palliatives' },
          { label: 'Mat', value: 'mat' },
          { label: 'Vaccine', value: 'vaccine' },
        ],
      },
      source: {
        label: 'Source',
        type: 'multi_select',
        condition: 'in',
        options: [
          { label: 'Internal', value: 'internal' },
          { label: 'Erx', value: 'erx' },
          { label: 'External During', value: 'external_during' },
          { label: 'External Prior', value: 'external_prior' },
          { label: 'Private Provider', value: 'private_provider' },
        ],
      },
      resident: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    medication_logs: {
      administered_at: {
        label: 'Administered',
        type: 'date_time',
        glyph: 'date',
      },
      medication_time: {
        label: 'Medication Time',
        type: 'string',
      },
      dosage: {
        label: 'Dosage',
        type: 'number',
      },
      unit: {
        label: 'Unit',
        type: 'number',
      },
      remaining_units: {
        label: 'Remaining Unit',
        type: 'number',
      },
      // med: {
      //   label: 'Name',
      //   type: 'multi_object',
      //   endpoint: '/meds',
      //   apiKey: 'meds',
      //   showAvatar: true,
      //   polymorphic: false,
      //   selectTitle: (data: any) => data.name,
      // },
      prescription_no: {
        label: 'Prescription #',
        type: 'string',
      },
      resident: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    allergies: {
      name: ALLERGY_FILTERS.name,
      criticality: ALLERGY_FILTERS.criticality,
      severity: ALLERGY_FILTERS.severity,
      source: ALLERGY_FILTERS.source,
      status: ALLERGY_FILTERS.status,
      category: ALLERGY_FILTERS.category,
      reaction_type: ALLERGY_FILTERS.reaction_type,
      started_on: ALLERGY_FILTERS.started_on,
      ended_on: ALLERGY_FILTERS.ended_on,
      created_at: ALLERGY_FILTERS.created_at,
      updated_at: ALLERGY_FILTERS.updated_at,
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      reference: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    payment_methods: {
      name: {
        label: 'Name',
        type: 'string',
      },
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Processing', value: 'processing' },
          { label: 'Errored', value: 'errored' },
          { label: 'Active', value: 'active' },
          { label: 'Disabled', value: 'disabled' },
        ],
      },
      category: {
        label: 'Category',
        type: 'multi_select',
        options: [
          { label: 'Card', value: 'card' },
          { label: 'ACH', value: 'ach' },
        ],
      },
      reference_category: {
        label: 'Reference Category',
        type: 'multi_select',
        options: [
          { label: 'Client', value: 'client' },
          { label: 'Contact', value: 'contact' },
          { label: 'Organization', value: 'organization' },
        ],
      },
      notes: {
        label: 'Notes',
        type: 'notes',
      },
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      profile: {
        label: 'Owner',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
    },
    financial_transactions: {
      name: {
        label: 'Name',
        type: 'string',
      },
      amount: {
        label: 'Amount',
        type: 'number',
      },
      total_amount: {
        label: 'Total Amount',
        type: 'number',
      },
      notes: {
        label: 'Notes',
        type: 'string',
      },
      payed_with: {
        label: 'Expected Payment',
        type: 'string',
      },
      payment_reference: {
        label: 'Payer',
        type: 'string',
      },
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Charged', value: 'charged' },
          { label: 'Future Charge', value: 'future' },
          { label: 'Cancelled', value: 'cancelled' },
        ],
      },
      service: {
        label: 'Category',
        type: 'multi_select',
        options: [
          { label: 'Custom', value: 'custom' },
          { label: 'Intake Fee', value: 'intake_fee' },
          { label: 'Security Deposit', value: 'security_deposit' },
          { label: 'Sobriety Deposit', value: 'sobriety_deposit' },
          { label: 'Rent', value: 'rent' },
          { label: 'Program Fee', value: 'program_fee' },
          { label: 'Admin Fee', value: 'admin_fee' },
          { label: 'Late Fee', value: 'late_fee' },
          { label: 'Recovery Coaching', value: 'recovery_coaching' },
          { label: 'Career Coaching', value: 'career_coaching' },
          { label: 'Life Coaching', value: 'life_coaching' },
        ],
      },
      transactioned_at: {
        label: 'Due Date / Payment Date',
        type: 'date_time',
        glyph: 'date',
      },
      payment_method: {
        label: 'Payment Method',
        type: 'multi_object',
        endpoint: '/payment_methods',
        apiKey: 'payment_methods',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      resident: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      client_location: {
        label: 'Client Location',
        type: 'multi_object',
        endpoint: '/houses',
        apiKey: 'locations',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
      // reference: {},
    },
    clinical_notes: CLINICAL_NOTE_FILTERS,
    peer_notes: PEER_NOTE_FILTERS,
    daily_notes: PEER_NOTE_FILTERS,
    auto_repeating_charges: {
      is_active: {
        label: 'Auto Repeating Charge',
        type: 'string',
      },
      status: {
        label: 'Client Status',
        type: 'multi_select',
        options: [
          { label: 'Applicant', value: 'lead' },
          { label: 'Intake', value: 'intake' },
          { label: 'Current', value: 'current' },
          { label: 'Past', value: 'past' },
          { label: 'Past Client', value: 'alumni' },
          { label: 'Declined', value: 'declined' },
        ],
      },
      automatic_payment_capture_enabled: {
        label: 'Auto-Capture Payments',
        type: 'string',
      },
      automatic_payment_service_name: {
        label: 'Service Name',
        type: 'string',
      },
      automatic_payment_service_amount: {
        label: 'Service Amount',
        type: 'number',
      },
      last_renewal_date: {
        label: 'First Transaction Date',
        type: 'date_time',
        glyph: 'date',
      },
      next_renewal_date: {
        label: 'Next Renewal Date',
        type: 'date_time',
        glyph: 'date',
      },
      charges_balance: {
        label: 'Charges Balance',
        type: 'number',
      },
      payments_balance: {
        label: 'Payments Balance',
        type: 'number',
      },
      current_balance: {
        label: 'Current Balance',
        type: 'number',
      },
      client: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
      created_at: DEFAULT_FILTERS.created_at,
      updated_at: DEFAULT_FILTERS.updated_at,
    },
    location_checks: {
      name: DEFAULT_FILTERS.name,
      notes: DEFAULT_FILTERS.notes,
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Checked-In', value: 'in' },
          { label: 'Checked-Out', value: 'out' },
          { label: 'Late Checkout', value: 'late' },
        ],
      },
      category: {
        label: 'Category',
        type: 'string',
      },
      checkin_at: {
        label: 'Checkin At',
        type: 'date_time',
        glyph: 'date',
      },
      checkout_at: {
        label: 'Checkout At',
        type: 'date_time',
        glyph: 'date',
      },
      duration: {
        label: 'Duration',
        type: 'number',
      },
      resident: {
        label: 'Client',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    client_connections: {
      category: CLIENT_CONNECTION_FILTERS.category,
      subcategory: CLIENT_CONNECTION_FILTERS.subcategory,
      notes: CLIENT_CONNECTION_FILTERS.notes,
      created_at: CLIENT_CONNECTION_FILTERS.created_at,
      updated_at: CLIENT_CONNECTION_FILTERS.updated_at,
      reference: {
        label: 'Client Name',
        type: 'multi_overlay',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
        table: <AllClientsTable defaultTab="current_clients" />,
      },
      to: {
        label: 'Connection Name',
        type: 'multi_overlay',
        endpoint: '/employees',
        apiKey: 'residents',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
        table: <EmployeesTable />,
      },
    },
    referral: {
      notes: DEFAULT_FILTERS.notes,
      reference: {
        label: 'Client Name',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      organization: {
        label: 'Referral Organization',
        type: 'multi_object',
        endpoint: '/organizations',
        apiKey: 'organizations',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      admitted_at: {
        label: 'Admitted At',
        type: 'date_time',
        glyph: 'date',
      },
      discharged_at: {
        label: 'Discharged At',
        type: 'date_time',
        glyph: 'date',
      },
    },
    shift_notes: SHIFT_NOTE_FILTERS,
    safety_checks: SAFETY_CHECK_FILTERS,
    custom_note_templates: {
      name: DEFAULT_FILTERS.name,
      variant: {
        label: 'Template Variant',
        type: 'string',
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
    notifications: {
      template: {
        label: 'Template',
        type: 'string',
      },
      status: {
        label: 'Status',
        type: 'string',
      },
      phone_no: {
        label: 'Phone No',
        type: 'string',
      },
      email: {
        label: 'Email',
        type: 'string',
      },
      send_sms: {
        label: 'Medication Time',
        type: 'string',
      },
      sent_sms_at: {
        label: 'Sent SMS At',
        type: 'date_time',
        glyph: 'date',
      },
      send_email: {
        label: 'Send Email',
        type: 'string',
      },
      sent_email_at: {
        label: 'Sent Email At',
        type: 'date_time',
        glyph: 'date',
      },
      sms_message: {
        label: 'Message',
        type: 'string',
      },
    },
    data_exports: {
      name: DEFAULT_FILTERS.name,
      status: {
        label: 'Status',
        type: 'multi_select',
        options: [
          { label: 'Processing', value: 'processing' },
          { label: 'Generated', value: 'generated' },
          { label: 'Issue', value: 'issue' },
        ],
      },
      created_at: {
        label: 'Created At',
        type: 'date_time',
        glyph: 'date',
      },
      starting_at: {
        label: 'Starting At',
        type: 'date_time',
        glyph: 'date',
      },
      ending_at: {
        label: 'Ending At',
        type: 'date_time',
        glyph: 'date',
      },
      customer: {
        label: 'Client Name',
        type: 'multi_object',
        endpoint: '/residents',
        apiKey: 'residents',
        glyph: 'user_group',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (item: any) => item.name,
      },
      author: {
        label: 'Added By',
        type: 'multi_object',
        endpoint: '/employees',
        apiKey: 'employees',
        glyph: 'user_neutral',
        showAvatar: true,
        polymorphic: false,
        selectTitle: (data: any) => data.name,
      },
    },
  },

  insurance_local_payers: {
    name: DEFAULT_FILTERS.name,
    tags: DEFAULT_FILTERS.tags,
    network_status: {
      label: 'Network Status',
      type: 'multi_select',
      options: [
        { label: 'In Network', value: 'in_network' },
        { label: 'Out of Network', value: 'out_of_network' },
      ],
    },
    enrolled_status: {
      label: 'Enrollment Status',
      type: 'multi_select',
      options: [
        { label: 'Enrolled', value: 'is_enrolled' },
        { label: 'Not Enrolled', value: 'not_enrolled' },
      ],
    },
  },

  insurance_global_payers: {
    name: DEFAULT_FILTERS.name,
    clearing_house: {
      label: 'Clearing House',
      type: 'multi_select',
      options: Object.entries(CLEARING_HOUSES).map(([value, label]) => ({ value, label })),
    },
  },
}

export const REPORTS_FILTERS = {
  // alumni: {
  //   ...FILTERS.alumni,
  // },
  // applicants: {
  //   ...FILTERS.applicants,
  // },
  // breathalyzer_tests: {
  //   ...FILTERS.breathalyzer_tests,
  // },
  // client_communications: {
  //   ...FILTERS.client_communications,
  // },
  // client_connections: {
  //   ...FILTERS.client_connections,
  // },
  // client_contacts: {
  //   ...FILTERS.client_contacts,
  // },
  // clients: {
  //   ...FILTERS.clients,
  // },
  // clinical_assessments: {
  //   ...FILTERS.clinical_assessments,
  // },
  // clinical_measurements: {
  //   ...FILTERS.clinical_measurements,
  // },
  // communicable_disease_tests: {
  //   ...FILTERS.communicable_disease_tests,
  // },
  // declined_intakes: {
  //   ...FILTERS.declined_intakes,
  // },
  // employees: {
  //   ...FILTERS.employees,
  // },
  // events: {
  //   ...FILTERS.events,
  // },
  // intakes: {
  //   ...FILTERS.intakes,
  // },
  // lab_tests: {
  //   ...FILTERS.lab_tests,
  // },
  // medical_assessments: {
  //   ...FILTERS.medical_assessments,
  // },
  // organization_communications: {
  //   ...FILTERS.organization_communications,
  // },
  // organization_contacts: {
  //   ...FILTERS.organization_contacts,
  // },
  // physician_orders: {
  //   ...FILTERS.physician_orders,
  // },
  // progress_reviews: {
  //   ...FILTERS.progress_reviews,
  // },
  // shift_notes: {
  //   ...FILTERS.shift_notes,
  // },
  // todos: {
  //   ...FILTERS.todos,
  // },
  // treatment_plans: {
  //   ...FILTERS.treatment_plans,
  // },
  // ua_tests: {
  //   ...FILTERS.ua_tests,
  // },
  // vital_signs: {
  //   ...FILTERS.vital_signs,
  // },
}
