import React from 'react'

import { useOverlay } from '../../hooks/useOverlay'
import { withOverlayError } from '../../hocs/withOverlayError'

import Divider from '../../components/Divider'
import Overlay from '../../components/Overlay'
import OverlayLoader from '../../components/OverlayLoader'
import Section from '../../components/Section'
import Timeline from '../../components/Timeline/Timeline'

import { ClaimMD } from './components/ClaimMD'
import ChangeHealthcare from './components/ChangeHealthcare'
import PVerify from './components/PVerify'

const RootEVOBOverlay = (props: any) => {
  const { close, initialModel, isLoading, isNew, isOverlayLoading } = useOverlay({
    name: 'insurance_evobs',
    endpoint: '/insurance_evobs',
    invalidate: 'insurance_evobs',
    options: props,
  })

  if (isOverlayLoading) {
    return <OverlayLoader position="right" />
  }

  if (initialModel.clearing_house === 'claim_md') {
    return (
      <Overlay maxWidth={80} closeOnBackdrop={props.closeOnBackdrop} showBackdrop={props.showBackdrop} onClose={close}>
        <Overlay.Header icon="financials" title="eVOB" />

        <ClaimMD
          data={initialModel}
          contentAfter={
            !isNew && (
              <>
                <Divider />

                <Section headingType="h2" title="Timeline">
                  <Timeline isLoadingRecord={isLoading} recordID={initialModel.id} recordType={initialModel.type} />
                </Section>
              </>
            )
          }
        />
      </Overlay>
    )
  }

  return (
    <Overlay
      maxWidth={initialModel.clearing_house === 'change_health' ? 64 : 48}
      closeOnBackdrop={props.closeOnBackdrop}
      showBackdrop={props.showBackdrop}
      onClose={close}
    >
      <Overlay.Header icon="financials" title="eVOB" />

      <Overlay.Content>
        {initialModel.clearing_house === 'change_health' && <ChangeHealthcare data={initialModel} />}
        {initialModel.clearing_house === 'pverify' && <PVerify data={initialModel} />}

        {!isNew && (
          <>
            <Divider />

            <Section headingType="h2" title="Timeline">
              <Timeline isLoadingRecord={isLoading} recordID={initialModel.id} recordType={initialModel.type} />
            </Section>
          </>
        )}
      </Overlay.Content>
    </Overlay>
  )
}

export const EVOBOverlay = withOverlayError(RootEVOBOverlay)
