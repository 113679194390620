import React from 'react'

import { invalidateQueries, useUpdate } from '../../hooks/useNewAPI'
import TagsSelector from '../../components/TagsSelector'

export const FileTagSelector = ({ data }: any) => {
  const id = data?.id

  const { mutateAsync, isLoading }: any = useUpdate({
    name: ['files', id],
    url: `/documents/${id}`,
    invalidate: ['files'],
    onSuccess: () => {
      invalidateQueries(null, 'file')
      invalidateQueries(null, 'files')
    },
  })

  if (!id) return null

  return (
    <>
      <TagsSelector
        buttonLabel="File Tags"
        tags={data?.tags}
        onSave={(tagIDs: any) => mutateAsync({ tag_ids: tagIDs })}
        isSaving={isLoading}
        smartCategories="files"
        // buttonClassName="order-first"
      />
    </>
  )
}
