import React from 'react'
import startCase from 'lodash/startCase'

import { DataTable } from '../../components/DataTable/DataTable'
import { FILTERS } from '../Filters/config'
import { MainCell } from '../../components/DataTable/cells/MainCell'
import { NotApplicableCell } from '../../components/DataTable/cells/NotApplicableCell'

import Status from '../../components/Status'

export const ClientContactsDataTable = (props: any) => {
  const { to, duplicateColumn } = props

  const columns = React.useMemo(() => {
    return [
      {
        title: 'Name',
        model: 'name',
        width: 260,
        disableHide: true,
        formatValue: ({ data }: any) => <MainCell id={data.id} value={data.name} avatar={data.avatar || ''} to={to?.(data)} />,
      },
      {
        width: 130,
        model: 'status',
        title: 'Status',
        formatValue: ({ value }: any) => {
          if (value == 'active') return <Status small color="green" label="Active" />
          else if (value == 'inactive') return <Status small color="red" label="Inactive" />
          return <NotApplicableCell />
        },
      },
      {
        width: 130,
        model: 'dob',
        title: 'Date of Birth',
        type: 'date',
      },
      {
        width: 130,
        model: 'relationship',
        title: 'Relationship',
        type: 'title',
      },
      {
        width: 150,
        model: 'is_emergency',
        title: 'Emergency Contact',
        formatValue: ({ value }: any) => {
          if (!value) return 'Non-emergency'
          return <Status small color="yellow" label="Emergency Contact" />
        },
      },
      {
        width: 180,
        model: 'roi',
        title: 'Release of Information',
        formatValue: ({ value }: any) => {
          if (!value) return 'Not allowed'
          return <Status small color="green" label="R.O.I. Allowed" />
        },
      },
      {
        width: 180,
        model: 'preferred_contact_method',
        title: 'Preferred Contact Method',
        type: 'title',
      },
      {
        width: 100,
        model: 'sex',
        title: 'Sex',
        formatValue: ({ value }: any) => startCase(value),
      },
      {
        width: 160,
        model: 'phone_no',
        title: 'Cell Phone',
        type: 'phone',
      },
      {
        width: 240,
        model: 'email',
        title: 'Email',
        type: 'email',
      },
      {
        width: 260,
        model: 'notes',
        title: 'Notes',
      },
      ...(duplicateColumn ? [duplicateColumn] : []),
    ]
  }, [to])

  return (
    <DataTable
      asCard
      title="Client Contacts"
      testKey="client_contacts_data_table"
      icon="contacts"
      columns={columns}
      filtersConfig={FILTERS.client_contacts}
      {...props}
    />
  )
}
