import React from 'react'

import { COLORS, GLYPHS } from '@behavehealth/theme'
import { useSettings } from '@behavehealth/hooks'
import useCopyToClipboard from '@behavehealth/hooks/useCopyToClipboard'

import { Button, Card, CardContent, CardHeader, Flex, Grid, PageLayout, PageSection, Text } from '@behavehealth/components'

const BUTTON_STYLE = `border-radius: 300px; padding: 0.75em 1.5em; font-size: 1rem; font-weight: 600; font-style: normal; text-transform: uppercase; letter-spacing: 1px; color: #fff; background-color: #2964fd; border-color: #2964fd; display: inline-flex; align-items: center; flex-wrap: nowrap; width: auto; height: auto; text-align: center; text-decoration: none; cursor: pointer; appearance: none; line-height: normal; margin: 0.5rem; font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;`

const BUTTON_GLYPH = `<svg viewBox="0 0 30 30" style="width: 1.5em; height: 1.5em; margin-right: 1em; fill: #fff;" xmlns="http://www.w3.org/2000/svg" width="20" height="20" ><path d="${GLYPHS.behave_health.path}" /></svg>`

const getButtonCode = (subdomain: string) => `<div style="display: flex; align-items: center; justify-content: center; margin: -0.5rem;">
  <a href="https://myaccount.behavehealth.com/login?utm_source=login_button&utm_tenant=${subdomain}" style="${BUTTON_STYLE}" target="_blank">${BUTTON_GLYPH} Staff Login</a>
  <a href="https://myaccount.behavehealth.com/login?utm_source=login_button&utm_tenant=${subdomain}" style="${BUTTON_STYLE}" target="_blank">${BUTTON_GLYPH} Client Login</a>
</div>
`

export const LoginButtons = () => {
  const { tenant } = useSettings()

  const code = getButtonCode(tenant.subdomain)

  const { copy, didCopy } = useCopyToClipboard({
    text: code,
    notification: 'Login buttons copied!',
    dismissAfter: 2000,
  })

  return (
    <PageLayout>
      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Widget Description" />
        </PageSection.Header>

        <PageSection.Content>
          <Card>
            <CardHeader className="!px-2 !py-[0.65rem]">
              <Grid gap="1rem">
                <Text
                  nowrap
                  glyph="tick"
                  description="Embed the login buttons directly on your website to allow staff and clients to log in to the Behave Health app."
                />
              </Grid>
            </CardHeader>
          </Card>
        </PageSection.Content>
      </PageSection>

      <PageSection>
        <PageSection.Header>
          <PageSection.Title title="Usage Instructions" />
          <PageSection.Subtitle subtitle="Copy the HTML code below and paste it into your website to embed the login buttons." />
        </PageSection.Header>

        <PageSection.Content>
          <Card>
            <CardHeader>
              <div className="flex justify-center my-4">
                <div dangerouslySetInnerHTML={{ __html: code }} />
              </div>

              <pre
                css={{
                  width: '100%',
                  wordBreak: 'break-all',
                  whiteSpace: 'pre-wrap',
                  color: COLORS.textMuted,
                  background: '#EEF0F7',
                  border: `1px solid ${COLORS.divider}`,
                  borderRadius: 5,
                  padding: 10,
                  margin: 0,
                }}
              >
                {code}
              </pre>
            </CardHeader>

            <CardContent>
              <Flex gap="1rem">
                <Button
                  glyph={didCopy ? 'check' : 'copy'}
                  label={didCopy ? 'Code Copied!' : 'Copy Code'}
                  onClick={copy}
                  type={didCopy ? 'default' : 'primary'}
                />
              </Flex>
            </CardContent>
          </Card>
        </PageSection.Content>
      </PageSection>
    </PageLayout>
  )
}
