import { Link } from 'react-router-dom-v5-compat'
import produce from 'immer'

import { DuplicateButton } from '../Buttons/DuplicateButton'

type DuplicateArgs = {
  link: string | null | ((data: any) => string | null)
  parent?: any
  permission?: string | boolean
  formatData?: (data: any) => any
}

export const setDuplicateColumn = (args: DuplicateArgs) => {
  const { permission, formatData, link, parent } = args

  return {
    width: 130,
    title: 'Actions',
    id: '_actions',
    model: 'id',
    hoverExpand: false,
    disableSort: true,
    formatValue: (row: any) => {
      const data = produce(row.data, (draft: any) => {
        if (draft?.id) delete draft.id
        if (draft?.status) delete draft.status
      })

      const formattedLink = typeof link === 'function' ? link(data) : link

      if (!formattedLink) return null

      return (
        <DuplicateButton
          label="Duplicate"
          glyph="add"
          size={100}
          permission={permission}
          link={{
            pathname: formattedLink,
            data: formatData ? formatData(data) : data,
            parent,
          }}
        />
      )
    },
  }
}

type DuplicateArgsV6 = {
  link?: string | ((data: any) => string)
  permission?: string | boolean
  formatData?: (data: any) => any
}

export const setDuplicateColumnV6 = (args: DuplicateArgsV6) => {
  const { permission, formatData, link = 'new' } = args

  return {
    width: 130,
    title: 'Actions',
    id: '_actions',
    model: 'id',
    hoverExpand: false,
    disableSort: true,
    formatValue: (row: any) => {
      const data = produce(row.data, (draft: any) => {
        if (draft?.id) delete draft.id
        if (draft?.status) delete draft.status
      })

      return (
        <DuplicateButton
          as={Link}
          label="Duplicate"
          glyph="add"
          size={100}
          permission={permission}
          link={typeof link === 'function' ? link(data) : link}
          state={{ data: formatData ? formatData(data) : data }}
        />
      )
    },
  }
}
