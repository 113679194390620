import React from 'react'

import { COLORS } from '../../theme'

import SmartStatus from '../../components/SmartStatus'

export const STATUS_GROUPS = {
  joining_community: {
    label: 'Joining the Behave Community',
    color: 'blue',
    infoTooltip: `Organization has been manually added. They must join the Behave Community before anything else`,
  },
  part_of_community: {
    label: 'Connecting with Members',
    color: 'yellow',
    glyphColor: COLORS.orange,
    infoTooltip: `Organization is a Member of Behave Community. They are waiting to be connected with other Members`,
  },
  is_partnered: {
    label: 'Managing Partnerships',
    color: 'green',
    infoTooltip: `Organization is within a Partnership`,
  },
  is_archived: {
    label: 'Archived',
    color: 'gray',
    infoTooltip: `Organization has been archived`,
  },
}

export const OrganizationStatusGroupStatus = ({ ...rest }) => {
  return <SmartStatus statuses={STATUS_GROUPS} {...rest} />
}
