import { tint } from 'polished'
import { COLORS } from '../../theme'

export const GREEN = tint(0.02, COLORS.mintGreen)
export const ORANGE = tint(0.45, COLORS.orange)
export const RED = tint(0.1, COLORS.red)

export const SUBSTANCES = [
  {
    name: 'Alcohol',
    model: 'alcohol',
    units: 'units/day',
  },
  {
    name: 'Heroin',
    model: 'heroin',
    units: 'g/day',
  },
  {
    name: 'Crack Cocaine',
    model: 'crack_cocaine',
    units: 'g/day',
  },
  {
    name: 'Cocaine Powder',
    model: 'cocaine_powder',
    units: 'g/day',
  },
  {
    name: 'Amphetamines',
    model: 'amphetamines',
    units: 'g/day',
  },
  {
    name: 'Cannabis',
    model: 'cannabis',
    units: 'spliff/day',
  },
  {
    name: 'Fentanyl (Prescribed) ',
    model: 'fentanyl_prescribed ',
    units: 'g/day',
  },
  {
    name: 'Fentanyl (Street) ',
    model: 'fentanyl_street ',
    units: 'g/day',
  },
  {
    name: 'Methadone (Prescribed) ',
    model: 'methadone_prescribed ',
    units: 'mg/day',
  },
  {
    name: 'Methadone (Street) ',
    model: 'methadone_street ',
    units: 'mg/day',
  },
  {
    name: 'Methamphetamine (Street)',
    model: 'methamphetamine_street',
    units: 'g/day',
  },
  {
    name: 'NPS (Synthetics)',
    model: 'nps_synthetics',
    units: 'tabs/day',
  },
  {
    name: 'Opioid Pain Medications (Prescribed)',
    model: 'opioid_pain_medications_prescribed',
    units: 'mg/day',
  },
  {
    name: 'Opioid Pain Medications (Street)',
    model: 'opioid_pain_medications_street',
    units: 'mg/day',
  },
  {
    name: 'Buprenorphine (prescribed) (Subutex)',
    model: 'buprenorphine_prescribed',
    units: 'mg/day',
  },
  {
    name: 'Buprenorphine (street) (Subutex)',
    model: 'buprenorphine_street',
    units: 'mg/day',
  },
  {
    name: 'Benzos (Prescribed)',
    model: 'benzos_prescribed',
    units: 'mg/day',
    specify: true,
  },
  {
    name: 'Benzos (Street)',
    model: 'benzos_street',
    units: 'mg/day',
    specify: true,
  },
  {
    name: 'Tobacco',
    model: 'tobacco',
    units: 'cigarettes/day',
  },
  {
    name: 'Other Problem Substance?',
    model: 'other',
    units: 'g/day',
    specify: true,
  },
]

export const PERSONAL_RECOVERY_CAPITAL = [
  {
    label: 'Having a sense of purpose in life is important to my recovery journey',
    shortLabel: 'Purpose Drives Recovery',
    model: 'answers.personal_recovery_domain.purpose_in_life',
    shortModel: 'purpose_in_life',
  },
  {
    label: 'I am able to concentrate when I need to',
    shortLabel: 'Focus When Needed',
    model: 'answers.personal_recovery_domain.able_to_concentrate',
    shortModel: 'able_to_concentrate',
  },
  {
    label: 'I am coping with the stresses in my life',
    shortLabel: 'Managing Life Stresses',
    model: 'answers.personal_recovery_domain.coping_with_stress',
    shortModel: 'coping_with_stress',
  },
  {
    label: 'I am free from worries about money',
    shortLabel: 'Financial Stability',
    model: 'answers.personal_recovery_domain.free_of_money_worries',
    shortModel: 'free_of_money_worries',
  },
  {
    label: 'I am happy dealing with a range of professional people',
    shortLabel: 'Works Well Professionally',
    model: 'answers.personal_recovery_domain.can_deal_with_professional_people',
    shortModel: 'can_deal_with_professional_people',
  },
  {
    label: 'I am making good progress on my recovery journey',
    shortLabel: 'Recovery Progressing',
    model: 'answers.personal_recovery_domain.making_good_progress_on_my_recovery',
    shortModel: 'making_good_progress_on_my_recovery',
  },
  {
    label: 'I cope well with everyday tasks',
    shortLabel: 'Handles Daily Tasks',
    model: 'answers.personal_recovery_domain.cope_well_with_everyday_tasks',
    shortModel: 'cope_well_with_everyday_tasks',
  },
  {
    label: 'I do not let other people down',
    shortLabel: 'Reliable Person',
    model: 'answers.personal_recovery_domain.i_dont_let_people_down',
    shortModel: 'i_dont_let_people_down',
  },
  {
    label: 'I am happy with my appearance',
    shortLabel: 'Confident Appearance',
    model: 'answers.personal_recovery_domain.happy_with_my_appearance',
    shortModel: 'happy_with_my_appearance',
  },
  {
    label: 'I engage in activities and events that support my recovery',
    shortLabel: 'Active Recovery Support',
    model: 'answers.personal_recovery_domain.engage_in_activities_and_event_for_my_recovery',
    shortModel: 'engage_in_activities_and_event_for_my_recovery',
  },
  {
    label: 'I eat regularly and have a balanced diet',
    shortLabel: 'Healthy Eating Habits',
    model: 'answers.personal_recovery_domain.eat_regularly_and_have_a_balanced_diet',
    shortModel: 'eat_regularly_and_have_a_balanced_diet',
  },
  {
    label: 'I feel physically well enough to work',
    shortLabel: 'Physically Capable',
    model: 'answers.personal_recovery_domain.feel_physically_well_for_work',
    shortModel: 'feel_physically_well_for_work',
  },
  {
    label: 'I have enough energy to complete the tasks I set myself',
    shortLabel: 'Sustained Energy',
    model: 'answers.personal_recovery_domain.enough_energy_to_complete_tasks',
    shortModel: 'enough_energy_to_complete_tasks',
  },
  {
    label: 'I have no problems with transportation',
    shortLabel: 'Transportation Secure',
    model: 'answers.personal_recovery_domain.no_transportation_problems',
    shortModel: 'no_transportation_problems',
  },
  {
    label: 'I have the personal resources I need to make decisions about my future',
    shortLabel: 'Plans For Future',
    model: 'answers.personal_recovery_domain.have_personal_resources',
    shortModel: 'have_personal_resources',
  },
  {
    label: 'I have the privacy I need',
    shortLabel: 'Has Needed Privacy',
    model: 'answers.personal_recovery_domain.have_privacy',
    shortModel: 'have_privacy',
  },
  {
    label: 'I look after my health',
    shortLabel: 'Health Conscious',
    model: 'answers.personal_recovery_domain.look_after_health',
    shortModel: 'look_after_health',
  },
  {
    label: 'I make sure I do nothing that hurts or damages other people',
    shortLabel: 'Avoids Harming Others',
    model: 'answers.personal_recovery_domain.not_hurting_others',
    shortModel: 'not_hurting_others',
  },
  {
    label: 'I meet all my obligations promptly (things you have made a commitment to do)',
    shortLabel: 'Meets Commitments',
    model: 'answers.personal_recovery_domain.meeting_my_obligations',
    shortModel: 'meeting_my_obligations',
  },
  {
    label: 'I sleep well most nights',
    shortLabel: 'Sleeps Well',
    model: 'answers.personal_recovery_domain.sleep_well',
    shortModel: 'sleep_well',
  },
  {
    label: 'I take full responsibility for my actions',
    shortLabel: 'Owns Actions',
    model: 'answers.personal_recovery_domain.take_full_responsibility',
    shortModel: 'take_full_responsibility',
  },
  {
    label: 'In general I am happy with my life',
    shortLabel: 'Life Satisfaction',
    model: 'answers.personal_recovery_domain.happy_with_my_life',
    shortModel: 'happy_with_my_life',
  },
  {
    label: 'What happens to me in the future mostly depends on me',
    shortLabel: 'Controls Future',
    model: 'answers.personal_recovery_domain.future_depends_on_me',
    shortModel: 'future_depends_on_me',
  },
  {
    label: 'I have a network of people I can rely on to support my recovery',
    shortLabel: 'Strong Support Network',
    model: 'answers.personal_recovery_domain.have_network_for_support',
    shortModel: 'have_network_for_support',
  },
  {
    label: 'When I think of the future I feel optimistic',
    shortLabel: 'Future Optimism',
    model: 'answers.personal_recovery_domain.feeling_optimistic',
    shortModel: 'feeling_optimistic',
  },
]

export const SOCIAL_RECOVERY_CAPITAL = [
  {
    label: 'I am actively involved in leisure and sport activities',
    shortLabel: 'Active In Sports Leisure',
    model: 'answers.social_recovery_domain.actively_involved_in_leisure_and_sports',
    shortModel: 'actively_involved_in_leisure_and_sports',
  },
  {
    label: 'I am currently completely sober from drug use',
    shortLabel: 'Completely Drug Free',
    model: 'answers.social_recovery_domain.completely_sober_from_drug_use',
    shortModel: 'completely_sober_from_drug_use',
  },
  {
    label: 'I am actively engaged in efforts to improve myself (training, education and/or self-awareness)',
    shortLabel: 'Engaged In Self Improvement',
    model: 'answers.social_recovery_domain.actively_engaged_in_improving_myself',
    shortModel: 'actively_engaged_in_improving_myself',
  },
  {
    label: 'I am happy with my personal life',
    shortLabel: 'Content Personal Life',
    model: 'answers.social_recovery_domain.happy_with_personal_life',
    shortModel: 'happy_with_personal_life',
  },
  {
    label: 'I am proud of my home',
    shortLabel: 'Proud Of Home',
    model: 'answers.social_recovery_domain.proud_of_my_home',
    shortModel: 'proud_of_my_home',
  },
  {
    label: 'I am proud of the community I live in and feel a part of it – sense of belonging',
    shortLabel: 'Connected To Community',
    model: 'answers.social_recovery_domain.proud_of_the_community_i_live_in',
    shortModel: 'proud_of_the_community_i_live_in',
  },
  {
    label: 'I am satisfied with my involvement with my family',
    shortLabel: 'Strong Family Bonds',
    model: 'answers.social_recovery_domain.satisfied_with_my_involvement_with_my_family',
    shortModel: 'satisfied_with_my_involvement_with_my_family',
  },
  {
    label: 'I am free of threat or harm when I am at home',
    shortLabel: 'Safe Home Environment',
    model: 'answers.social_recovery_domain.free_of_threat_or_harm_at_home',
    shortModel: 'free_of_threat_or_harm_at_home',
  },
  {
    label: 'I engage in activities that I find enjoyable and fulfilling',
    shortLabel: 'Engaged In Fulfilling Acts',
    model: 'answers.social_recovery_domain.engaging_in_enjoyable_and_fulfilling_activities',
    shortModel: 'engaging_in_enjoyable_and_fulfilling_activities',
  },
  {
    label: 'I feel safe and protected where I live',
    shortLabel: 'Secure Living Space',
    model: 'answers.social_recovery_domain.feeling_safe_and_protected',
    shortModel: 'feeling_safe_and_protected',
  },
  {
    label: 'I feel that I am in control of my substance use',
    shortLabel: 'Controls Substance Use',
    model: 'answers.social_recovery_domain.in_control_of_my_substance_use',
    shortModel: 'in_control_of_my_substance_use',
  },
  {
    label: 'I feel that I am free to shape my own destiny',
    shortLabel: 'Free To Shape Destiny',
    model: 'answers.social_recovery_domain.free_to_shape_my_destiny',
    shortModel: 'free_to_shape_my_destiny',
  },
  {
    label: 'I get lots of support from friends',
    shortLabel: 'Friend Support Network',
    model: 'answers.social_recovery_domain.support_from_friends',
    shortModel: 'support_from_friends',
  },
  {
    label: 'I get the emotional help and support I need from my family',
    shortLabel: 'Family Emotional Support',
    model: 'answers.social_recovery_domain.support_from_family',
    shortModel: 'support_from_family',
  },
  {
    label: 'I have a special person that I can share my joys and sorrows with',
    shortLabel: 'Has Close Confidant',
    model: 'answers.social_recovery_domain.have_special_person_to_share_joys_and_sorrows',
    shortModel: 'have_special_person_to_share_joys_and_sorrows',
  },
  {
    label: 'I have access to opportunities for career development (job opportunities, volunteering or internships)',
    shortLabel: 'Career Growth Access',
    model: 'answers.social_recovery_domain.access_to_career_development',
    shortModel: 'access_to_career_development',
  },
  {
    label: 'I have had no lapses or relapses regarding substance abuse',
    shortLabel: 'No Substance Relapses',
    model: 'answers.social_recovery_domain.no_lapses_or_relapses',
    shortModel: 'no_lapses_or_relapses',
  },
  {
    label: 'I have had no recent periods of intoxication',
    shortLabel: 'No Recent Intoxication',
    model: 'answers.social_recovery_domain.no_recent_period_of_intoxication',
    shortModel: 'no_recent_period_of_intoxication',
  },
  {
    label: 'I regard my life as challenging and fulfilling without the need for using drugs or alcohol',
    shortLabel: 'Life Fulfilling Without Drugs',
    model: 'answers.social_recovery_domain.my_life_is_challenging_and_fulfilling',
    shortModel: 'my_life_is_challenging_and_fulfilling',
  },
  {
    label: 'It is important for me to contribute to society and or be involved in activities that contribute to my community',
    shortLabel: 'Community Contributor',
    model: 'answers.social_recovery_domain.important_to_contribute_to_society_and_community',
    shortModel: 'important_to_contribute_to_society_and_community',
  },
  {
    label: 'It is important for me to do what I can to help other people',
    shortLabel: 'Helps Others',
    model: 'answers.social_recovery_domain.important_to_help',
    shortModel: 'important_to_help',
  },
  {
    label: 'It is important for me that I make a contribution to society',
    shortLabel: 'Societal Impact',
    model: 'answers.social_recovery_domain.important_to_contribute_to_society',
    shortModel: 'important_to_contribute_to_society',
  },
  {
    label: 'My living space has helped to encourage my recovery journey',
    shortLabel: 'Recovery Supporting Home',
    model: 'answers.social_recovery_domain.living_space_helps_with_recovery',
    shortModel: 'living_space_helps_with_recovery',
  },
  {
    label: 'My personal identity does not revolve around drug use or drinking',
    shortLabel: 'Identity Beyond Substances',
    model: 'answers.social_recovery_domain.my_identity_doesnt_revolve_around_substance_use',
    shortModel: 'my_identity_doesnt_revolve_around_substance_use',
  },
  {
    label: 'There are more important things to me in life than using substances',
    shortLabel: 'Priorities Beyond Drugs',
    model: 'answers.social_recovery_domain.more_important_things_in_life_than_substance_use',
    shortModel: 'more_important_things_in_life_than_substance_use',
  },
]

export const RECOVERY_GROUPS = [
  {
    label: 'I attend recovery group meetings on a weekly basis or more frequently',
    shortLabel: 'Weekly Recovery Meetings',
    model: 'answers.recovery_groups_domain.group.recovery_group_meetings_attended_weekly',
    shortModel: 'recovery_group_meetings_attended_weekly',
  },
  {
    label: 'If I did not make a meeting at my group for two weeks, people would call to see if I was okay',
    shortLabel: 'Group Members Check In',
    model: 'answers.recovery_groups_domain.group.people_call_if_not_attending_meetings',
    shortModel: 'people_call_if_not_attending_meetings',
  },
  {
    label: 'I speak at recovery meetings',
    shortLabel: 'Speaks At Meetings',
    model: 'answers.recovery_groups_domain.group.speaking_at_recovery_meetings',
    shortModel: 'speaking_at_recovery_meetings',
  },
  {
    label: 'I perform service at recovery meetings',
    shortLabel: 'Service At Meetings',
    model: 'answers.recovery_groups_domain.group.perform_service_at_recovery_meetings',
    shortModel: 'perform_service_at_recovery_meetings',
  },
  {
    label: 'I carry a message of hope to others (and openly talk about my own recovery)',
    shortLabel: 'Shares Recovery Hope',
    model: 'answers.recovery_groups_domain.group.carry_a_message_of_hope',
    shortModel: 'carry_a_message_of_hope',
  },
  {
    label: 'I socialize before and / or after meetings',
    shortLabel: 'Meeting Socializing',
    model: 'answers.recovery_groups_domain.group.socializing_before_after_meetings',
    shortModel: 'socializing_before_after_meetings',
  },
  {
    label: 'I attend recovery social events',
    shortLabel: 'Recovery Events',
    model: 'answers.recovery_groups_domain.group.attending_recovery_social_events',
    shortModel: 'attending_recovery_social_events',
  },
  {
    label: 'I visit a recovery center',
    shortLabel: 'Center Visits',
    model: 'answers.recovery_groups_domain.group.visitting_a_recovery_center',
    shortModel: 'visitting_a_recovery_center',
  },
  {
    label: 'I read recovery supportive literature',
    shortLabel: 'Recovery Reading',
    model: 'answers.recovery_groups_domain.group.reading_recovery_literature',
    shortModel: 'reading_recovery_literature',
  },
  {
    label: 'I carry a recovery object (something that reminds me of my ongoing recovery)',
    shortLabel: 'Recovery Reminders',
    model: 'answers.recovery_groups_domain.group.carry_a_recovery_object',
    shortModel: 'carry_a_recovery_object',
  },
  {
    label: 'I have people from my recovery group who support my recovery',
    shortLabel: 'Group Support System',
    model: 'answers.recovery_groups_domain.group.group_members_support_my_recovery',
    shortModel: 'group_members_support_my_recovery',
  },
  {
    label: 'I use daily recovery rituals (things I do every day to support my recovery journey)',
    shortLabel: 'Daily Recovery Habits',
    model: 'answers.recovery_groups_domain.group.use_daily_recovery_rituals',
    shortModel: 'use_daily_recovery_rituals',
  },
  {
    label: 'I do voluntary service to help my recovery group',
    shortLabel: 'Group Service Work',
    model: 'answers.recovery_groups_domain.group.volunteer_service',
    shortModel: 'volunteer_service',
  },
  {
    label: 'I encourage others to attend my recovery group',
    shortLabel: 'Encourages Attendance',
    model: 'answers.recovery_groups_domain.group.encourage_others_to_attend_my_group',
    shortModel: 'encourage_others_to_attend_my_group',
  },
]

export const DIMENSIONS = {
  recovery_capital_index: {
    name: 'Recovery Capital Index',
    min: -100,
    max: 100,
  },
  quality_of_life: {
    name: 'Quality of Life and Satisfaction',
    min: 0,
    max: 100,
    answers: {
      psychological_health: 'How good is your psychological health?',
      physical_health: 'How good is your physical health?',
      overall_quality_of_life: 'How would you rate your overall quality of life?',
      quality_of_housing: 'How would you rate the quality of your housing?',
      support_network: 'How would you rate your support network?',
    },
  },
  barriers_to_recovery: {
    name: 'Barriers to Recovery',
    min: 0,
    max: 5,
    answers: {
      at_risk_of_eviction: 'At risk of eviction',
      having_acute_housing_problems: 'Having acute housing problems',
      problems_securing_housing_due_to_debt: 'Do you have problems securing housing because of bad credit or other debt issues?',
    },
  },
  services_and_needs: {
    name: 'Services Involvement and Needs',
    min: 0,
    max: 8,
    answers: {
      drug_treatment_services: 'Drug Treatment Services',
      alcohol_treatment_services: 'Alcohol Treatment Services',
      mental_health_services: 'Mental Health Services',
      housing_support: 'Housing Support',
      employment_services: 'Employment Services',
      primary_healthcare: 'Primary Healthcare Services',
      family_relationships: 'Family Relationships',
      other_help: 'Other Specialist Help Or Support',
    },
  },
  personal_capital: {
    name: 'Personal Recovery Capital',
    min: 0,
    max: 25,
    answers: PERSONAL_RECOVERY_CAPITAL.reduce((acc: any, item: any) => {
      acc[item.shortModel] = item.label
      return acc
    }, {}),
  },
  social_capital: {
    name: 'Social Recovery Capital',
    min: 0,
    max: 25,
    answers: SOCIAL_RECOVERY_CAPITAL.reduce((acc: any, item: any) => {
      acc[item.shortModel] = item.label
      return acc
    }, {}),
  },
  recovery_capital: {
    name: 'Recovery Capital',
    min: 0,
    max: 14,
    answers: RECOVERY_GROUPS.reduce((acc: any, item: any) => {
      acc[item.shortModel] = item.label
      return acc
    }, {}),
  },
  support: {
    name: 'Support',
    min: 0,
    max: 28,
    answers: {
      emotional_support_met: 'Do you get the emotional support you need from other people?',
      help_from_others_met: 'Do you get the help you need from other people?',
      resources_from_others_met: 'Do you get the resources you need from other people?',
      advice_from_others_met: 'Do you get the advice you need from other people?',
    },
  },
  commitment: {
    name: 'Commitment',
    min: 0,
    max: 30,
    answers: {
      sobriaty_importance: 'Staying sober/clean is the most important thing in my life',
      off_drugs_commitment: 'I am totally committed to staying off of alcohol/drugs',
      recovery_commitment: 'I will do whatever it takes to recover from my addiction',
      no_return_commitment: 'I never want to return to alcohol/drug use again',
      had_enough_commitment: 'I have had enough alcohol and drugs',
    },
  },
}

export const MAX_VALUES = {
  quality_of_life_score: 100,
  barriers_to_recovery_score: 5,
  services_and_needs_score: 8,
  personal_capital_score: 25,
  social_capital_score: 25,
  recovery_group_score: 14,
  other_support_score: 28,
  commitment_score: 30,
  negative_capital_summary_score: 100,
  positive_capital_summary_score: 100,
  recovery_capital_index_score: 100,
}

export const SERVICES = {
  drug_treatment_services: 'Drug Treatment Services',
  alcohol_treatment_services: 'Alcohol Treatment Services',
  mental_health_services: 'Mental Health Services',
  housing_support: 'Housing Support',
  employment_services: 'Employment Services',
  primary_healthcare: 'Primary Healthcare Services',
  family_relationships: 'Family Relationships',
}
