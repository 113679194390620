import React from 'react'

import CheckboxGroup from '../CheckboxGroup'
import Checkbox from '../Checkbox'

const NewEthnicityCheckboxGroup = ({
  label = 'Ethnicity',
  model = 'new_ethnicity',
  isRequired,
  validateOn = 'blur-change',
  falseStyle = 'hidden',
  falseIcon = 'cross',
}: any) => (
  <CheckboxGroup
    allowEmpty
    label={label}
    layout="vertical-dense"
    trueIcon="check"
    falseStyle={falseStyle}
    falseIcon={falseIcon}
    validations={
      isRequired && {
        presence: {
          message: 'Please select an Ethnicity',
        },
      }
    }
  >
    <Checkbox label="Hispanic / Latino" model={`${model}.hispanic_latino`} />
    <Checkbox label="Middle Eastern / North African" model={`${model}.middle_eastern_north_african`} />
    <Checkbox label="Jewish" model={`${model}.jewish`} />
    <Checkbox label="Arab" model={`${model}.arab`} />
    <Checkbox label="Caribbean" model={`${model}.caribbean`} />
    <Checkbox label="Eastern European" model={`${model}.eastern_european`} />
    <Checkbox label="Southeast Asian" model={`${model}.southeast_asian`} />
    <Checkbox label="Native / Indigenous" model={`${model}.native_indigenous`} />
    <Checkbox label="Some Other Ethnic Group" model={`${model}.some_other_ethnic_group`} />
    <Checkbox label="Prefer Not to Answer" model={`${model}.prefer_not_to_say`} />
  </CheckboxGroup>
)

export default NewEthnicityCheckboxGroup
