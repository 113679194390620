import React from 'react'
import { Link } from 'react-router-dom'
import { darken, tint } from 'polished'
import isUndefined from 'lodash/isUndefined'
import produce from 'immer'
import size from 'lodash/size'

import { COLORS, ICONS, INPUT_STYLES } from '../../theme'
import { CONFIG } from '../../utils/globals'
import { get } from '../../modules/api/requests'
import { nicePhoneNo, isDefined, usDateTime } from '../../utils/functions'
import { useCreate } from '../../hooks/useNewAPI'
import { usePermissions } from '../../hooks/usePermissions'
import { useSettings } from '../../hooks/useSettings'
import { validate } from '../../components/Forms/validators'
import { withOverlayError } from '../../hocs/withOverlayError'
import useDebounceValue from '../../hooks/useDebounceValue'

import Alert from '../../components/Alert'
import Avatar from '../../components/Avatar'
import Button from '../../components/Button'
import ButtonGroup from '@behavehealth/components/ButtonGroup'
import CardLink from '../../components/CardLink'
import CardSubtitle from '../../components/CardSubtitle'
import CardTitle from '../../components/CardTitle'
import EmailInput from '../../components/Forms/EmailInput'
import EmployeeStatus from '../../components/Statuses/EmployeeStatus'
import Flex from '../../components/Flex'
import Form from '../../components/Forms/Form'
import FormSection from '../../components/Forms/FormSection'
import Glyph from '../../components/Glyph'
import Grid from '../../components/Grid'
import HelpTagIframe from '../../components/Help/HelpTagIframe'
import Input from '../../components/Forms/Input'
import Label from '../../components/Label'

import Loader from '../../components/Loader'
import ObjectSelector from '../../components/Forms/Selectors/Object/ObjectSelector'
import Option from '../../components/Forms/Option'
import Overlay from '../../components/Overlay'
import PhoneInput from '../../components/Forms/PhoneInput'
import Section from '../../components/Section'
import Select from '../../components/Forms/Select'
import Status from '../../components/Status'
import Text from '../../components/Typography/Text'
import Textarea from '../../components/Forms/Textarea'

// const INVITES_APP_URL = process.env.BH_APP_INVITES_URL

import { MissingStaffInvitePermissionMessage } from '../Staff/InviteStaffMemberAction'

const RootNewEmployeeInviteOverlay = (props: any) => {
  const {
    data: invite,
    mutateAsync: sendInviteAsync,
    isLoading: isSendingInvite,
  } = useCreate({
    name: ['send-invite'],
    url: `/employees/invite`,
    invalidate: 'invites',
    invalidateKeys: props.invalidateKeys,
  })

  const { tenant, timezone, isBehave, isOwner } = useSettings()
  const { allowed } = usePermissions({ permission: 'employees.actions.invite' })

  const canInvite = allowed

  const form = React.useRef()
  const [isValid, setIsValid] = React.useState(false)

  const [data, setData]: any = React.useState(null)
  const [email, setEmail]: any = React.useState(props?.initialData?.email || '')
  const [phone, setPhone]: any = React.useState('')
  const [inviteType, setInviteType]: any = React.useState('email')

  const [didSelect, setDidSelect]: any = React.useState(false)
  const [didSendInvite, setDidSendInvite]: any = React.useState(false)
  const [fullName, setFullName]: any = React.useState(props?.initialData?.full_name || '')
  const [isSearching, setIsSearching]: any = React.useState(false)
  const [userId, setUserId]: any = React.useState(null)

  const firstName = React.useMemo(() => {
    return getFirstName(fullName)
  }, [fullName])

  const displaySearchValue = React.useMemo(() => {
    if (inviteType === 'email' && !!email) return email
    else if (inviteType === 'phone_no' && !!phone) return phone
  }, [inviteType, email, phone])

  const canSearch = React.useMemo(() => {
    if (inviteType === 'email') return isEmailValid(email)
    if (inviteType === 'phone_no') return isPhoneValid(phone)
  }, [inviteType, email, phone])

  const canContinue = React.useMemo(() => {
    if (isSearching || !data || data.has_pending_invite || data.is_staff) return false
    else return true
  }, [data, isSearching])

  const searchValue = React.useMemo(() => {
    if (!canSearch) return null

    if (inviteType === 'email') return email.trim()
    if (inviteType === 'phone_no') return formatPhone(phone)
  }, [canSearch, inviteType, email, phone])

  const searchValueDebounced = useDebounceValue(searchValue, 500)

  const outcomeLabel = INVITE_TYPE_OUTCOME?.[inviteType]

  const handleContinue = () => {
    if (isUndefined(data?.user_id)) return

    setDidSelect(true)
    setUserId(data.user_id)
  }

  const resetForm = (o: any) => {
    setData(null)
    setEmail('')
    setPhone('')
    setDidSelect(false)
    setDidSendInvite(false)
    setFullName('')
    setIsSearching(false)
    setUserId(null)
  }

  const sendInvite = async () => {
    const formData = form.current.getFormValue()

    const sentTo = produce({}, (draft: any) => {
      if (isDefined(userId)) draft.user_external_id = userId

      draft.sent_to_details = { full_name: fullName }

      if (inviteType === 'email') draft.sent_to_details.email = email.trim()
      if (inviteType === 'phone_no') draft.sent_to_details.phone_no = formatPhone(phone)
    })

    try {
      const result = await sendInviteAsync({
        ...formData,
        ...sentTo,
      })

      if (result?.data?.id) {
        setDidSendInvite(true)
      }
    } catch (error) {
      console.error(error)
      setDidSendInvite(false)
    }
  }

  const editSearchValue = () => {
    setDidSelect(false)
    setUserId(null)
  }

  // Search existing user
  React.useEffect(() => {
    if (!canSearch || !searchValueDebounced) {
      setIsSearching(false)
      setData(null)
      return
    }

    const searchAccount = async () => {
      try {
        const result: any = await get(`/employees/search?${inviteType}=${searchValueDebounced}`)

        setData(result?.data)
      } catch (error) {
        console.error(error)
      } finally {
        setIsSearching(false)
      }
    }

    setIsSearching(true)
    searchAccount()
  }, [searchValueDebounced, canSearch])

  // Show loading state before the debounced request is sent to improve perceived performance
  React.useEffect(() => {
    if (canSearch) setIsSearching(true)
  }, [searchValue, canSearch])

  return (
    <Overlay showBackdrop closeOnBackdrop={didSendInvite} position="center" maxWidth={38} onClose={props.onClose}>
      <Overlay.Header icon={ICONS.employees} title="Invite Staff Member" help={<HelpTagIframe id="add_staff_member" />} />

      <Overlay.Content>
        {!canInvite && (
          <div className="p-4">
            <Alert small contrast color="red">
              <MissingStaffInvitePermissionMessage />
            </Alert>
          </div>
        )}

        {canInvite && (
          <>
            {didSendInvite && (
              <InviteSuccess
                data={invite?.data}
                inviteType={inviteType}
                fullName={fullName}
                displaySearchValue={displaySearchValue}
                resetForm={resetForm}
              />
            )}

            {!didSendInvite && (
              <Form isEditable getForm={form} timezone={timezone} onValidationUpdate={setIsValid} initialModel={props.initialData}>
                <Section>
                  <FormSection>
                    {!didSelect && (
                      <>
                        <ButtonGroup stretchSelf>
                          <Button
                            label="Invite via Email"
                            color="gray"
                            glyph="email"
                            type={inviteType === 'email' ? 'primary' : 'default'}
                            onClick={() => setInviteType('email')}
                            size={200}
                          />
                          <Button
                            label="Invite via Phone"
                            color="gray"
                            glyph="cell_phone"
                            type={inviteType === 'phone_no' ? 'primary' : 'default'}
                            onClick={() => setInviteType('phone_no')}
                            size={200}
                          />
                        </ButtonGroup>

                        <div>
                          {inviteType === 'email' && (
                            <EmailInput
                              autoFocus
                              label={`Business Email`}
                              value={email}
                              glyph={null}
                              validateOn="blur"
                              maxWidth="100%"
                              onUpdate={({ value }) => {
                                setEmail(value)
                              }}
                              css={STYLES.input}
                            />
                          )}

                          {inviteType === 'phone_no' && (
                            <PhoneInput
                              autoFocus
                              label={`Business Cell Number`}
                              value={phone}
                              glyph={null}
                              validateOn="blur"
                              maxWidth="100%"
                              onUpdate={({ value }) => {
                                setPhone(value)
                              }}
                              css={STYLES.input}
                            />
                          )}

                          <div css={STYLES.inputDropdownWrapper}>
                            <div css={STYLES.inputDropdown}>
                              <UserSearchDropdown
                                canSearch={canSearch}
                                data={data}
                                displaySearchValue={displaySearchValue}
                                fullName={fullName}
                                inviteType={inviteType}
                                isLoading={isSearching}
                                onSelect={handleContinue}
                                searchValue={searchValue}
                              />
                            </div>
                          </div>

                          <Button
                            label="Continue →"
                            type="primary"
                            color="blue"
                            className="!mt-2"
                            isDisabled={!canContinue}
                            onClick={handleContinue}
                          />
                        </div>
                      </>
                    )}

                    {didSelect && (
                      <>
                        <div css={STYLES.select} className="is-readonly">
                          {userId ? (
                            <Avatar size={32} css={STYLES.selectGraphic} initials={displaySearchValue} />
                          ) : (
                            <Glyph css={STYLES.selectGraphic} glyph={INVITE_TYPE_GLYPHS[inviteType]} />
                          )}

                          <Grid>
                            <div css={STYLES.selectTitle}>{displaySearchValue}</div>
                            <div css={STYLES.selectDescription}>
                              {userId
                                ? `Account found in our system – a notification ${outcomeLabel} will be sent to join ${tenant.name}`
                                : `Account not found in our system – a verification ${outcomeLabel} will be sent to create an account and join ${tenant.name}`}
                            </div>
                          </Grid>

                          <div css={STYLES.selectEdit} onClick={editSearchValue}>
                            <Glyph css={STYLES.selectGraphic} glyph="edit" size={14} />
                            <div>Edit</div>
                          </div>
                        </div>

                        <div>
                          <Label isRequired isValid={!!firstName} label="Full Name" css={STYLES.inputLabel} />
                          <Input
                            autoFocus
                            hideAsterisk
                            label={null}
                            glyph="user_neutral"
                            value={fullName}
                            maxWidth="100%"
                            onUpdate={({ value }) => {
                              setFullName(value)
                            }}
                            validations={{
                              presence: {
                                message: "Please enter the staff member's full name",
                              },
                            }}
                          />
                        </div>

                        <Textarea
                          label="Invite Message"
                          model="message"
                          defaultValue={
                            userId
                              ? 'Please accept this invite to get access to our EHR'
                              : 'Please register to accept this invite and get access to our EHR'
                          }
                        />

                        <Select
                          fullWidth
                          allowEmpty
                          label="Staff Role"
                          model="settings.position"
                          validations={{
                            presence: {
                              message: 'Please select a staff role',
                            },
                          }}
                        >
                          {CONFIG.active_employee_roles.map(({ text, value }) => {
                            if (value === 'owner' && !isBehave && !isOwner) return null

                            return <Option key={value} label={text} value={value} />
                          })}
                        </Select>

                        <ObjectSelector
                          icon="permissions"
                          label="Apply Staff Permission Template"
                          type="permission_templates"
                          model="settings.permissions_template"
                          description="Inherit the permissions from the selected template. You can customize this under the Staff profile anytime"
                          selectTitle={(data: any) => data?.name}
                          selectDescription={() => null}
                          validations={{
                            presence: {
                              message: 'Please select a permission template',
                            },
                          }}
                        />

                        <Alert contrast type="negative" glyph="warning">
                          Ensure <Link to="/settings/permission-templates">Staff Permission Templates</Link> are set up correctly to prevent
                          unintended access, such as allowing Staff to invite new users, including Owners and Administrators
                        </Alert>
                      </>
                    )}
                  </FormSection>
                </Section>
              </Form>
            )}
          </>
        )}
      </Overlay.Content>

      {didSelect && !didSendInvite && (
        <Overlay.Footer>
          <Button
            label="Invite Staff Member"
            glyph="check"
            type="primary"
            color="green"
            onClick={sendInvite}
            isDisabled={!isValid || !firstName}
            isLoading={isSendingInvite}
          />
        </Overlay.Footer>
      )}
    </Overlay>
  )
}

const InviteSuccess = (props: any) => {
  const { data, inviteType, fullName, displaySearchValue, resetForm } = props

  const { tenant, timezone } = useSettings()

  if (!data) return null

  return (
    <Grid gap="1.25rem" justifyItems="center" alignItems="flex-start" css={STYLES.confirmation}>
      <Glyph glyph="tick_circle" size={28} />
      <h3>
        {fullName} has been invited to join {tenant.name}
      </h3>

      <Grid gap="1rem">
        <Flex centerY nowrap css={STYLES.glyphListItem}>
          <Glyph size={20} color={COLORS.text} glyph={INVITE_TYPE_GLYPHS[inviteType]} />
          <div>
            <b>Invite Sent To:</b> <span css={STYLES.monoType}>{displaySearchValue}</span>
          </div>
        </Flex>
      </Grid>

      <Button
        label="Invite Another Staff Member →"
        icon="employees"
        size={300}
        onClick={resetForm}
        css={{ justifySelf: 'stretch', marginTop: '0.5rem' }}
      />
    </Grid>
  )
}

const UserSearchDropdown = (props: any) => {
  const { canSearch, data, displaySearchValue, fullName, inviteType, isLoading, onSelect, searchValue } = props

  const { tenant, timezone } = useSettings()

  if (isLoading) {
    return (
      <div css={STYLES.loadingState}>
        <Loader />
      </div>
    )
  }

  if (inviteType === 'phone_no' && size(searchValue) > 10) {
    return (
      <div css={STYLES.emptyState}>
        <Glyph glyph="circle_error" size={20} />
        <div css={STYLES.emptyDescription}>
          Please enter a valid cell number
          <br /> without any extension code
        </div>
      </div>
    )
  }

  if (!isDefined(data) || !canSearch || !displaySearchValue) {
    return (
      <div css={STYLES.emptyState}>
        <Glyph glyph={inviteType === 'email' ? 'email' : 'cell_phone'} size={20} />
        <div css={STYLES.emptyDescription}>Type {inviteType === 'email' ? 'an email' : 'a cell number'} to start searching</div>
      </div>
    )
  }

  if (data.is_staff && !!data.staff) {
    return (
      <div>
        <Alert small type="negative" glyph="circle_error" className="!rounded-none">
          This staff member has already joined and cannot be invited again
        </Alert>

        <CardLink showChevron variant="variant-list" graphic={<Avatar src={data.staff.avatar} initials={data.staff.name} size={38} />}>
          <CardTitle css={STYLES.cardTitle} title={data.staff.name} />

          <Flex centerY gap="0.3rem">
            <Status small label={data.staff.position?.replace('_', ' ')} color="blue" />
            <EmployeeStatus small status={data.staff.status} />
          </Flex>
        </CardLink>
      </div>
    )
  }

  if (data.has_pending_invite && !!data.pending_invite?.id) {
    const inviteAvatar = data.pending_invite.user_external?.avatar || ''
    const inviteFullName = data.pending_invite.user_external?.name || data.pending_invite.sent_to_details?.full_name

    return (
      <div>
        <Alert small glyph="info" className="!rounded-none">
          This staff member has already been invited
        </Alert>

        <CardLink
          showChevron
          variant="variant-list"
          to={`/user-invites/staff/${data.pending_invite.id}`}
          graphic={<Avatar src={inviteAvatar} initials={inviteFullName} size={38} />}
        >
          <CardTitle css={STYLES.cardTitle} title={inviteFullName} />

          <Flex centerY gap="0.3rem">
            <Status small label="Pending Invite" color="orange" />
          </Flex>
        </CardLink>
      </div>
    )
  }

  return (
    <CardLink
      variant="variant-list"
      onClick={() => onSelect()}
      graphic={data.has_user ? <Avatar src={data.user_avatar} glyph="user_neutral" size={32} /> : <Glyph glyph="tick_circle" size={20} />}
    >
      <CardTitle css={STYLES.cardTitle} title={`${displaySearchValue} can be invited to join ${tenant.name}`} />
      <CardSubtitle css={STYLES.cardSubtitle} subtitle="Click continue to set up the invite" />
    </CardLink>
  )
}

const getFirstName = (fullName: string) => {
  if (!fullName) return null

  const names = fullName.trim().split(' ')

  if (size(names[1]) === 0) return null

  return names[0]
}

const isEmailValid = (email?: string) => {
  const errors = validate(email, EMAIL_VALIDATION)
  return size(email) > 0 && size(errors) === 0
}

const isPhoneValid = (phone?: string) => {
  const formattedPhone = formatPhone(phone)

  const errors = validate(formattedPhone, PHONE_VALIDATION)

  return size(formattedPhone) === 10 && size(errors) === 0
}

const formatPhone = (phone: string = '') => {
  if (!phone) return null

  return nicePhoneNo(phone).replace(/\D/g, '') || null
}

const INVITE_TYPE_GLYPHS = {
  phone_no: 'cell_phone',
  email: 'email',
}

const INVITE_TYPE_LABELS = {
  phone_no: 'cell number',
  email: 'email address',
}

const INVITE_TYPE_OUTCOME = {
  phone_no: 'text',
  email: 'email',
}

const STYLES = {
  cardTitle: {
    fontSize: '1rem',
    fontWeight: 600,
  },

  cardSubtitle: {
    fontSize: '0.92rem',
  },

  // Dropdown Item
  select: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    padding: '0.5rem',
    overflow: 'hidden',

    '&.is-selectable': {
      cursor: 'pointer',

      ':hover': {
        backgroundColor: tint(0.94, COLORS.vividBlue),
      },
    },

    '&.is-readonly': {
      cursor: 'auto',
      border: `1px solid ${COLORS.divider}`,
      borderRadius: 7,
    },
  },

  selectGraphic: {
    marginRight: '0.5rem',
  },

  selectTitle: {
    paddingRight: '0.75rem',
    fontWeight: 600,

    '.is-selectable &': {
      color: COLORS.blue,
    },
  },

  selectDescription: {
    paddingRight: '0.75rem',
    fontSize: '0.95rem',
    color: COLORS.textMuted,
  },

  selectEdit: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    padding: '0.5rem 1rem',
    color: COLORS.blue,
    fontWeight: 600,
    margin: '-0.5rem',
    marginLeft: 'auto',
    borderLeft: `1px solid ${COLORS.divider}`,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.white,
    },
  },

  // Loading State
  loadingState: {
    padding: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  // Empty State
  emptyState: {
    display: 'grid',
    gridGap: '0.5rem',
    textAlign: 'center',
    padding: '1.5rem 1rem',
    justifyItems: 'center',
    alignContent: 'center',
    minHeight: '100%',
  },

  emptyTitle: {
    fontSize: '1rem',
    fontWeight: 600,
  },

  emptyDescription: {
    fontWeight: 400,
    color: COLORS.textMuted,
  },

  // Input
  input: {
    position: 'relative',
    zIndex: 1,

    svg: {
      zIndex: 2,
    },

    input: {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,

      '&:focus': {
        borderRadius: INPUT_STYLES.borderRadius,
      },
    },
  },

  inputDropdownWrapper: {
    minHeight: 100,
  },

  inputDropdown: {
    ...INPUT_STYLES,
    borderTop: 'none',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    overflow: 'hidden',
    padding: 0,

    '&:hover': {
      cursor: 'auto',
    },
  },

  inputLink: {
    whiteSpace: 'nowrap',
    color: COLORS.blue,
    marginLeft: 'auto',
    fontSize: '0.9rem',
    cursor: 'pointer',
    fontWeight: 500,
    paddingLeft: '0.5rem',
  },

  inputLabel: {
    flex: '1 1 auto',
    marginBottom: '-0.5rem',
    position: 'relative',
    zIndex: 10,
  },

  inputLabelWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  inputDescription: {
    color: COLORS.textStronglyMuted,
    fontSize: '0.925rem',
    marginTop: '0.4rem',

    b: {
      color: COLORS.textMuted,
      fontWeight: 600,
    },
  },

  // Confirmation
  confirmation: {
    padding: '1.5rem 1rem',
  },

  monoTypeLarge: {
    fontSize: '1.05rem',
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    border: `1px solid ${COLORS.divider}`,
    padding: '0.4rem 0.8rem',
    color: COLORS.text,
    letterSpacing: 1,

    '&.is-success': {
      color: darken(0.05, COLORS.gray),
      borderColor: tint(0.6, COLORS.gray),
      background: tint(0.95, COLORS.gray),

      svg: {
        fill: COLORS.gray,
      },
    },
  },

  monoType: {
    fontFamily: 'monospace',
    fontWeight: 400,
    borderRadius: 5,
    background: COLORS.hover,
    border: `1px solid ${COLORS.divider}`,
    padding: '0.25rem 0.5rem',
    color: COLORS.text,
    letterSpacing: 1,

    '&.is-error': {
      color: COLORS.red,
      borderColor: COLORS.red,
      background: tint(0.94, COLORS.red),
    },
  },

  textSmall: {
    fontSize: '0.92rem',
    b: { fontWeight: 600 },
    svg: { marginRight: '0.35rem' },
    lineHeight: 1,
  },

  glyphListItem: {
    fontSize: '0.92rem',
    b: { fontWeight: 600 },
    svg: { marginRight: '0.35rem' },
    lineHeight: 1,
  },
}

const EMAIL_VALIDATION = {
  format: {
    pattern:
      /^([\s]+)?$|^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
    message: 'Please enter a valid email (e.g. name@email.com)',
  },
}

const PHONE_VALIDATION = {
  format: {
    pattern: /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/gm,
    message: 'Please enter a valid phone number (e.g. 555-555-5555)',
  },
}

export const NewEmployeeInviteOverlay = withOverlayError(RootNewEmployeeInviteOverlay)
