import React from 'react'

import { usDate } from '../../utils/functions'
import { useSettings } from '../../hooks'

import Dialog from '../../components/Dialog'
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog'
import DropdownItem from '../../components/DropdownItem'
import Card from '../../components/Card'
import CardHeader from '../../components/CardHeader'
import Grid from '../../components/Grid'
import Flex from '../../components/Flex'
import Glyph from '../../components/Glyph'
import Icon from '../../components/Icon'
import Status from '../../components/Status'

import { H5, Text } from '../../components/Typography'
import { ICONS } from '../../theme'
import { useUpdate } from '../../hooks/useNewAPI'

import PaymentMethodStatus from '../../components/Statuses/PaymentMethodStatus'

export const PaymentMethodCard = ({ paymentMethod, deletePaymentMethod, isDeleting }: any) => {
  const { timezone } = useSettings()

  const isDefault = !!paymentMethod?.is_default

  const { mutateAsync: makeDefault, isLoading: isMakingDefault } = useUpdate({
    name: ['internal_billing', 'payment_methods', paymentMethod?.id, 'default'],
    url: `/global_payment_methods/${paymentMethod?.id}/default`,
    invalidate: ['internal_billing', 'payment_methods'],
  })

  const actions = (
    <>
      {!isDefault && (
        <ConfirmDialog
          glyph="tick_circle"
          title="Set Default Payment Method?"
          message="Are you sure you want to set this payment method as default?"
          yesColor="green"
          yesLabel="Yes, Set as Default "
          onYes={async () => await makeDefault(paymentMethod?.id)}
          isLoading={isMakingDefault}
        >
          <DropdownItem label="Set as Default" glyph="tick_circle" color="green" />
        </ConfirmDialog>
      )}

      <Dialog
        glyph="delete"
        title="Delete Payment Method?"
        message="Are you sure you want to delete this payment method? This action cannot be undone."
        yesColor="red"
        yesLabel="Yes, Delete Payment Method"
        onYes={async () => await deletePaymentMethod(paymentMethod?.id)}
        isLoading={isDeleting}
      >
        <DropdownItem testKey="delete_dropdown_item" label="Delete" glyph="delete" color="red" />
      </Dialog>
    </>
  )

  if (paymentMethod.payment_method_type === 'card') {
    const cardIcon = ICONS[paymentMethod.service_metadata?.brand] || ICONS.billing
    const card = paymentMethod?.service_metadata

    return (
      <>
        <Card testKey="card_payment_method">
          <CardHeader className="h-full" graphic={<Icon icon={cardIcon} />} actions={actions}>
            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Flex centerY gap="0.5rem">
                  <H5>•••• {card.last4}</H5>
                  {isDefault && <Status label="Default" color="blue" />}
                </Flex>
                {paymentMethod.status !== 'active' && <PaymentMethodStatus status={paymentMethod.status} />}
              </Flex>

              {paymentMethod.status === 'processing' && <Text>We are currently processing your payment method</Text>}

              {paymentMethod.status === 'active' && (
                <Flex gap="0.5rem" className="!-mt-2">
                  <Text>
                    <b className="font-[600]">Date Added: </b> {usDate(paymentMethod.created_at, timezone)}
                  </Text>

                  <span>•</span>

                  <Text>
                    <b className="font-[600]">Expires: </b>
                    {card?.exp_month}/{card?.exp_year}
                  </Text>
                </Flex>
              )}
            </Grid>
          </CardHeader>
        </Card>
      </>
    )
  } else if (paymentMethod.payment_method_type === 'us_bank_account') {
    const bank = paymentMethod?.service_metadata

    return (
      <>
        <Card testKey="bank_payment_method">
          <CardHeader className="h-full" graphic={<Glyph glyph="bank" />} actions={actions}>
            <Grid gap="0.5rem">
              <Flex gap="0.5rem">
                <Flex centerY gap="0.5rem">
                  <H5>
                    {bank.bank_name} •••• {bank?.last4}
                  </H5>
                  {isDefault && <Status label="Default" color="blue" />}
                </Flex>
                {paymentMethod.status !== 'active' && <PaymentMethodStatus status={paymentMethod.status} />}

                {paymentMethod.status === 'active' && (
                  <Text>
                    <b className="font-[600]">Date Added: </b> {usDate(paymentMethod.created_at, timezone)}
                  </Text>
                )}
              </Flex>

              {paymentMethod.status === 'processing' && <Text>We are currently processing your payment method</Text>}
            </Grid>
          </CardHeader>
        </Card>
      </>
    )
  }
}
